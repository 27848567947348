<div [ngSwitch]="type">
  <div *ngSwitchCase="'explainer'">
    <div class="modal-header">
      <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
      <h4 class="modal-title">
        Attribution tracking for CallRail
      </h4>
    </div>
    <div class="modal-body">
      <div class="container">
        <h6>
          <strong>Attribution tracks CallRail phone calls</strong>
        </h6>
        <div class="row">
          <div class="col-1">
            <h1 style="margin-top:0;"><i class="fa-solid fa-tags medium"></i></h1>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <a *ngIf="learnMoreLink" href="{{learnMoreLink}}" class="btn btn-default pull-left" target="_blank">
        <i class="fa-solid fa-book blue"></i>
        Learn More
      </a>
      <button type="button" class="btn btn-success" (click)="continue()">That's amazing, let's connect CallRail</button>
    </div>
  </div>
  <div *ngSwitchCase="'connect'">
    <div class="modal-header">
      <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
      <h4 class="modal-title">
        CallRail Connection Setup
      </h4>
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div *ngIf="error" class="alert alert-danger alert-dismissible" role="alert">
              {{ error }}
            </div>
            <form [formGroup]="connectForm" autocomplete="false">
              <div class="form-group">
                <label for="CallrailApiKey">CallRail API Key</label>
                <input type="password" formControlName="apiKey" class="form-control">
              </div>

              <div class="text-center">
                <button class="btn btn-success" (click)="connectLogin()">
                  <span *ngIf="!waitingForResponse; else waiting" class='caps'>
                    Connect
                  </span>
                  <ng-template #waiting>
                    <i class="fa-solid fa-spinner fa-spin"></i>
                  </ng-template>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'finish'">
    <div class="modal-header">
      <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
      <h4 class="modal-title">
        CallRail Connection Complete
      </h4>
    </div>
    <div class="modal-body">
      <div class="container">
        <h6>
          <strong>We've started tracking your phone calls</strong>
        </h6>
        <div class="row">
          <div class="col-12">
            <p class="small">Call events usually tracked within 30 minutes after the call has ended.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="close()" class="btn btn-success">Finish</button>
    </div>
  </div>
</div>
