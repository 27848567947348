import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardFilterIsInVirtual',
})
export class DashboardFilterIsInVirtualPipe implements PipeTransform {
  transform(item, virtualFilter): any {
    if (item?.vf) return item.vf.includes(virtualFilter.id);
    else return false;
  }
}