import { Account } from '../../core/models/account.model';

export class SetAccount {
    static readonly type = '[account] SetAccount';
    constructor(public payload: Account) {}
}

export class SetRegistrationStep {
    static readonly type = '[account] SetRegistrationStep';
    constructor(public payload: string) {}
}

export class SetLastRegistrationStep {
    static readonly type = '[account] SetLastRegistrationStep';
    constructor(public payload: number) {}
}

export class ClearAccountState {
    static readonly type = '[account] ClearAccountState';
}