import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameForUser'
})
export class NameForUserPipe implements PipeTransform {

  transform(value: any ): any {
    if (value === null || typeof(value) === 'undefined') { return null; }
    if (value.name) { return value.name; }
    if (value.full_name) { return value.full_name; }
    if (value.first_name && value.last_name) { return value.first_name + ' ' + value.last_name; }
    if (value.email) { return value.email; }
    if (value.identifier) { return value.identifier; }
    if (value.id) { return 'Anonymous visitor ' + value.id; }
    return '';
  }
}
