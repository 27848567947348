import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthAndStateCheckGuard } from './guards/auth-and-state-check.guard';
import { HttpStatusInterceptor } from './interceptors/http-status.interceptor';
import { ProjectCurrencyPipe } from './pipes/project-currency.pipe';
import { DefaultNumberPipe } from './pipes/default-number.pipe';
import { IntegerPipe } from './pipes/integer.pipe';
import { OverviewResolver } from './services/overview/overview-resolver.service';
import { NumericDirective } from './directives/numberic.directive';
import { NameForUserPipe } from './pipes/name-for-user.pipe';
import { FormattedMomentPipe } from './pipes/formatted-moment.pipe';
import { Md5Pipe } from './pipes/md-five.pipe';
import { CommaLocationPipe } from './pipes/comma-location.pipe';
import { CommaSeparatedListPipe } from './pipes/comma-separated-list.pipe';
import { UrlPipe } from './pipes/url.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { FormattedTraitPipe } from './pipes/formatted-trait.pipe';
import { MonthPipe } from './pipes/month.pipe';
import { TakeLabelPipe } from './pipes/take-label.pipe';
import { OnboardingGuard } from './guards/onboarding.guard';
import { CustomTimeAgoPipe } from './pipes/time-ago.pipe';
import { DaysHoursFromSecondsPipe } from './pipes/days-hours-from-seconds.pipe';
import { IncludedInArrayPipe } from './pipes/array-includes.pipe';
import { CanExpandPipe } from './pipes/can-expand.pipe';
import { HasNoChildrenPipe } from './pipes/has-no-children.pipe';
import { MathRoundPipe } from './pipes/math-round.pipe';
import { GetValueByParamFromArrayPipe } from './pipes/get-by-param-from-array.pipe';
import { IsUndefinedPipe } from './pipes/is-undefined.pipe';
import { VarDirective } from './directives/ng-var.directive';
import { takeFromObjExceptPipe } from './pipes/take-from-obj-except.pipe';
import { IncludedInTextPipe } from './pipes/text-includes.pipe';
import { IsArrayPipe } from './pipes/is-array.pipe';
import { UppercasePipe } from './pipes/uppercase.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { DashboardDisableDNDPipe } from './pipes/disable-dnd.pipe';
import { SafeUrlPipe } from './pipes/safe.pipe';
import { IsShopifySegmentPipe } from './pipes/is-shopify-segment.pipe';
import { HoverClassDirective } from './directives/hover-class.directive';

@NgModule({
  declarations: [
    NumericDirective,
    VarDirective,
    HoverClassDirective,
    ProjectCurrencyPipe,
    DefaultNumberPipe,
    IntegerPipe,
    NameForUserPipe,
    FormattedMomentPipe,
    Md5Pipe,
    CommaLocationPipe,
    CommaSeparatedListPipe,
    UrlPipe,
    PercentagePipe,
    FormattedTraitPipe,
    MonthPipe,
    TakeLabelPipe,
    CustomTimeAgoPipe,
    DaysHoursFromSecondsPipe,
    IncludedInArrayPipe,
    CanExpandPipe,
    HasNoChildrenPipe,
    MathRoundPipe,
    GetValueByParamFromArrayPipe,
    IsUndefinedPipe,
    takeFromObjExceptPipe,
    IncludedInTextPipe,
    IsArrayPipe,
    UppercasePipe,
    PrettyJsonPipe,
    DashboardDisableDNDPipe,
    SafeUrlPipe,
    IsShopifySegmentPipe,
  ],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: HttpStatusInterceptor,
      multi: true
    },
    AuthAndStateCheckGuard,
    OnboardingGuard,
    OverviewResolver
  ],
  exports: [
    NumericDirective,
    VarDirective,
    HoverClassDirective,
    ProjectCurrencyPipe,
    DefaultNumberPipe,
    IntegerPipe,
    NameForUserPipe,
    FormattedMomentPipe,
    Md5Pipe,
    CommaLocationPipe,
    CommaSeparatedListPipe,
    UrlPipe,
    PercentagePipe,
    FormattedTraitPipe,
    MonthPipe,
    TakeLabelPipe,
    CustomTimeAgoPipe,
    DaysHoursFromSecondsPipe,
    IncludedInArrayPipe,
    CanExpandPipe,
    HasNoChildrenPipe,
    MathRoundPipe,
    GetValueByParamFromArrayPipe,
    IsUndefinedPipe,
    takeFromObjExceptPipe,
    IncludedInTextPipe,
    IsArrayPipe,
    UppercasePipe,
    PrettyJsonPipe,
    DashboardDisableDNDPipe,
    SafeUrlPipe,
    IsShopifySegmentPipe,
  ]
})
export class CoreModule { }
