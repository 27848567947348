import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { remove, toNumber } from 'lodash-es';
import { DatePipe } from '@angular/common';

import { AmountService } from 'src/app/core/services/amount/amount.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { ToastrService } from 'ngx-toastr';
import { SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CsvConfirmationComponent } from '../../components/csv-confirmation/csv-confirmation.component';
import { environment } from 'src/environments/environment';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Component({
  selector: 'app-amounts-sidebar',
  templateUrl: './amounts-sidebar.component.html',
  styleUrls: ['./amounts-sidebar.component.scss']
})
export class AmountsSidebarComponent implements OnChanges, OnDestroy {
  @Input() sidebar: SidebarStateModel;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;
  @Select(state => state.selected) selected$: Observable<SelectedStateModel>;
  DP: DatePipe = new DatePipe('en-US');

  assetPath = environment.assetPath;
  inProgress;
  amounts = [];
  isEmpty;
  filter;
  currency = '$';
  selected = {
    filterType: null,
    filterOrGroupId: null
  };
  isVirtual = false;
  project: Project;
  filterTree;
  selectedState;

  newSpend: {
    amount: number;
    dates: { start: string; end: string; };
  } = { amount: null, dates: null};

  uploadError;
  sub: Subscription;
  treeSub;

  constructor(
    private amountService: AmountService,
    private store: Store,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private FTWS: FilterTreeWorkerService) { }

  ngOnChanges() {
    this.sub?.unsubscribe();
    this.sub = combineLatest([this.project$, this.selected$]).subscribe(([project, selected]) => {
      this.project = project.project;
      this.filterTree = this.store.selectSnapshot(state => state.filterTree.filterTree);
      this.selectedState = selected;
      if (this.project?.currency_symbol) {
        this.currency = this.project.currency_symbol;
      }
      if (this.project && this.selectedState.chosenDates.start && this.selectedState.chosenDates.end) {
        if (this.filterTree){
          this.initFlow();
        } else {
          this.treeSub?.unsubscribe();
          this.treeSub = this.filterTree$.subscribe( (filterTree) => {
            if (filterTree.filterTree) {
              this.filterTree = filterTree.filterTree;
              this.initFlow();
            }
            this.treeSub?.unsubscribe();
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  initFlow() {
    this.inProgress = true;
    this.amounts = [];
    this.isVirtual = false;
    if (this.sidebar.options.filter || this.sidebar.options.virtual_filter) {
      if (this.sidebar.options.virtual_filter) {
        this.isVirtual = true;
      }
      this.selected.filterType = 'f';
      this.selected.filterOrGroupId = toNumber(this.sidebar.options.filter || this.sidebar.options.virtual_filter);
    } else if (this.sidebar.options.group) {
      this.selected.filterType = 'g';
      this.selected.filterOrGroupId = toNumber(this.sidebar.options.group);
    } else {
      this.selected.filterType = null;
      this.selected.filterOrGroupId = null;
    }
    if (this.selected.filterOrGroupId) {
      const filterTree = this.store.selectSnapshot(state => state.filterTree.filterTree);
      this.filter = this.FTWS.findInTree({node: {c: filterTree}, childrenKey: 'c', key: 'id', value: this.selected.filterOrGroupId, additionalKey: 't', additionalValue: this.selected.filterType});
    } else {
      this.filter = null;
    }

    this.inProgress = true;
    this.requestAmounts();
  }

  requestAmounts() {
    this.amountService.get({id: this.selected.filterOrGroupId, type: this.isVirtual ? 'v' : this.selected.filterType, periodStart: this.sidebar.options.periodStart, periodEnd: this.sidebar.options.periodEnd}).subscribe( (resp: Array<any>) => {
      this.amounts = resp;
      this.isEmpty = this.amounts.length === 0;
      this.inProgress = false;
    });
  }

  addNewSpend() {
    if (this.newSpend.amount == null) { return; }
    if (!this.newSpend.dates) {
      setTimeout(() =>
        this.toastr.warning(
          `You need to choose start & end dates to add spend.`,
          `status: ${status}`,
          {disableTimeOut: true, enableHtml: true}
        )
      );
      return;
    }
    const params = {
      start: this.newSpend.dates.start,
      end: this.newSpend.dates.end,
      filter: this.sidebar.options.filter,
      value: +(this.newSpend.amount * 100).toFixed(2),
      valueInDollars: Number(this.newSpend.amount)
    };
    this.amountService.addSpend(params).subscribe( resp => {
      this.amounts = this.amounts.concat(resp);
      this.isEmpty = this.amounts.length === 0;
    },
    error => {
      if (error.status === 422) {
        setTimeout(() =>
          this.toastr.warning(
            `You can not add different spend in the same date range`,
            `status: ${status}`,
            {disableTimeOut: true, enableHtml: true}
          )
        );
      }
    });
  }

  removeSpend(amount) {
    this.amountService.delete(amount, this.sidebar.options.filter).subscribe( resp => {
      this.amounts = remove(this.amounts, x => x !== amount);
      this.isEmpty = this.amounts.length === 0;
    });
  }

  amountChanged(amount) {
    if (amount.control.value.length > 0 ) {
      this.newSpend.amount = amount.control.value;
    } else {
      this.newSpend.amount = null;
    }
  }

  datesSelected(dates) {
    this.newSpend.dates = dates;
  }

  formatDate(amount) {
    let result;
    if (amount.type === 'amount') {
      result = amount.start.split('-').join('/');
      result = this.DP.transform(amount.start, 'MM/dd/yyyy');
    } else {
      result = `${this.DP.transform(amount.start, 'MM/dd/yyyy')} - ${this.DP.transform(amount.end, 'MM/dd/yyyy')}`;
    }
    return result;
  }

  onUpload(files: FileList) {
    this.amountService.uploadCSV(false, this.selected.filterOrGroupId, files, null).subscribe( resp => {
      const csvElem = document.getElementById('csvFile') as HTMLInputElement;
      csvElem.value = '';
      const modalRef = this.modalService.open(CsvConfirmationComponent, {windowClass: 'slideInDown'});
      modalRef.componentInstance.resp = resp;
      modalRef.result.then(result => {
        if (result === 'needAmountsRefresh') {
          this.requestAmounts();
        }
      });
    }, error => {
      this.uploadError = 'Failed to process your file.';
    });
  }

}
