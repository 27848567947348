import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { each } from 'lodash-es';

@Component({
  selector: 'app-comma-separated-list',
  templateUrl: './comma-separated-list.component.html',
  styleUrls: ['./comma-separated-list.component.scss']
})
export class CommaSeparatedListComponent implements OnChanges {
  @Input() items;
  @Input() length;
  @Output() load = new EventEmitter();


  listString = '';
  size;
  displayedCount = 0;

  constructor() { }

  ngOnChanges(): void {
    this.size = Object.keys(this.items).length;
    const stringLength = this.length || 80;

    let lastValue;
    each(this.items, (count, item) => {
      lastValue = item;

      if (this.listString.length < stringLength) {
        if (this.displayedCount > 0) {
          this.listString += ', ';
          this.listString += lastValue;
        } else {
          this.listString = lastValue;
        }
        this.displayedCount += 1;
      }
    });
  }

  loadVisit() {
    this.load.emit(true);
  }

}
