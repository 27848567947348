import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { indexOf, isEmpty, map, merge, uniqBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { findAllInTree, findInTree } from 'src/app/core/helpers/find-in-tree';
import { NextDay } from 'src/app/core/helpers/next-day';
import { ChannelBuilderService } from 'src/app/core/services/channel-builder/channel-builder.service';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { LocalSubscriptionService } from 'src/app/core/services/local-subscription/local-subscription.service';
import { ReportRefresherService } from 'src/app/core/services/report-refresher/report-refresher.service';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { AddFilter, DeleteChannel, DeleteFilter, MoveFiltersAndChannels, ScheduleTreeRefresh } from 'src/app/store/actions/filter-tree.actions';
import { SidebarSetName } from 'src/app/store/actions/only-simple.actions';
import { SetCurrentComponent } from 'src/app/store/actions/selected.actions';
import { CloseSidebar, OpenSidebar } from 'src/app/store/actions/sidebar.actions';
import { FilterTreeItem } from 'src/app/store/interfaces/filter-tree';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { SidebarState, SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { ConfirmDeleteModalComponent } from '../../shared/components/confirm-delete-modal/confirm-delete-modal.component';
import { NewChannelModalComponent } from '../../shared/components/new-channel-modal/new-channel-modal.component';
import { AddToPendingDataRefresh } from 'src/app/store/actions/base.actions';

@Component({
  selector: 'app-channel-builder',
  templateUrl: './channel-builder.component.html',
  styleUrls: ['./channel-builder.component.scss']
})
export class ChannelBuilderComponent implements OnInit, OnDestroy {
  @Select(state => state.filterTree.filterTree) filterTree$: Observable<Array<FilterTreeItem>>;
  @Select(SidebarState) sidebar$: Observable<SidebarStateModel>;
  @Select(state => state.base.refresher) refresher$: Observable<any>;
  @Select(state => state.selected) selected$: Observable<any>;

  page = 'manage-traffic';
  section = 'params';
  key;
  values = [];
  originalParams = [];
  params = [];
  paramKeys: Array<any> = [];
  currentValues: any = {};
  lastAdded;
  busy = false;
  filters;
  paramKeyModel;
  hideSelect = true;
  selectedSource = null;
  sourceList = [{ name: 'All Traffic', id: null, type: null, special: null }];
  sidebarOpened = false;
  filtersCreating = [];
  filtersCreated = [];
  sidebarWasClosed = false;
  addedItems = [];
  componentPath = ComponentNames.dashboardBuilder;
  scrollerHeight = 0;
  selected = [];
  selectedIndexes = [];
  addingToChannel = false;
  whichChannel;

  paramsAdded = [];
  referrersAdded = [];
  pathsAdded = [];

  selectedFilters = [];
  selectedGroups = [];

  refreshedAt;

  warningHidden = false;

  moving = false;
  movingToChannel;

  toggleAllShouldSelect = true;

  directions = {
    last_direction: null,
    urlParamsDirection: 'neutral',
    visitsDirection: 'neutral'
  };

  resetSelectedInGroupSelector: Subject<any> = new Subject<any>();

  combineSub;
  refresherSub;
  selectedSub;
  paramSub;
  sidebarSub;

  filterTree;

  addingInProgress;

  constructor(
    private builderService: ChannelBuilderService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private reportRefresher: ReportRefresherService,
    private urlChecker: UrlCheckerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private localSubscription: LocalSubscriptionService,
    private FTWS: FilterTreeWorkerService) { }

  ngOnInit(): void {
    this.store.dispatch(new SetCurrentComponent({ currentComponent: this.componentPath}));

    this.paramsAdded = [];
    this.referrersAdded = [];
    this.pathsAdded = [];

    this.refresherSub = this.refresher$.subscribe( resp => {
      if (resp && resp.generated_at) {
        this.refreshedAt = resp.generated_at;
      } else {
        this.refreshedAt = null;
      }
    });

    this.selectedSub = this.selected$.subscribe(x => this.dropSelected());

    this.paramSub = this.route.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, this.componentPath);
    });

    this.combineSub = combineLatest([this.route.queryParams, this.filterTree$]).subscribe( ([params, filterTree]) => {
      if (filterTree == null || typeof(filterTree) == 'undefined') {
        this.selectedSource = null;
        this.sourceList = [{ name: 'All Traffic', id: null, type: null, special: null }];
        return;
      }
      this.filterTree = filterTree;
      if (params.page == 'manage-channels') {
        this.page = 'manage-channels';
        this.store.dispatch(new SidebarSetName(null));
        this.store.dispatch(new CloseSidebar());
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            page: 'manage-channels'
          }
        });
        return;
      } else {
        this.page = 'manage-traffic';
      }
      if (params.channelBuilderSidebarClosed) {
        this.sidebarWasClosed = true;
        const {channelBuilderSidebarClosed, values, value, ...everythingElse} = params;
        if (this.lastAdded) {
          const lastAddedKey = Object.keys(this.lastAdded);
          let removedLastAdded = JSON.parse(values);
          lastAddedKey.forEach( x => {
            delete removedLastAdded[x];
          });

          this.lastAdded = null;

          let queryParams: any = {
            ...everythingElse
          }

          if (!isEmpty(removedLastAdded)) queryParams.values = JSON.stringify(removedLastAdded);

          this.router.navigate([], {
            queryParams: queryParams
          });
        } else this.router.navigate([], {
          queryParams: everythingElse
        });

        return;
      }
      let typeWasChanged = false;
      if (params.type) {
        if (params.type != this.section) typeWasChanged = true;
        this.section = params.type;
      }
      if (filterTree) {
        this.filters = findAllInTree({c: filterTree}, 'c', 't', 'f');
        filterTree.forEach( x => {
          const source = {name: x.n, id: x.id, type: x.t == 'f' ? 'filter' : 'group', special: x.s};
          if (x.s) {
            const isAlreadyInList = this.sourceList.find( y => y.id == source.id && y.name == source.name && y.type == source.type && y.special == source.special);
            if (typeof(isAlreadyInList) == 'undefined') this.sourceList.push(source);
          }
        });
      }
      const isDirectInList = this.sourceList.find(y => y.special == 'direct');
      const isUnsegmentedInList = this.sourceList.find(y => y.special == 'unsegmented');
      const isCombinedInList = this.sourceList.find(y => y.name == 'Direct + Unsegmented');
      if (typeof(isDirectInList) != 'undefined' && typeof(isUnsegmentedInList) != 'undefined' && typeof(isCombinedInList) == 'undefined') {
        this.sourceList.push({name: 'Direct + Unsegmented', id: [isDirectInList.id, isUnsegmentedInList.id], type: 'filter', special: null});
      }
      if (typeof(params.filter) != 'undefined' || typeof(params.filter_group) != 'undefined') {
        if (typeof(params.filter) == "object") {
          const filterIdArray = params.filter.map( x => parseInt(x));
          this.selectedSource = {name: 'Direct + Unsegmented', id: filterIdArray, type: 'filter'};
        } else if (params.filter == 'all') {
          this.selectedSource = { name: 'All Traffic', id: null, type: null };
        } else {
          let id;
          let type;
          if (params.filter) {
            id = parseInt(params.filter);
            type = 'f';
          } else if (params.filter_group) {
            id = parseInt(params.filter_group);
            type = 'g';
          }
          let filter;
          if (filterTree) filter = this.FTWS.findInTree({ node: {c: filterTree}, childrenKey: 'c', key: 'id', value: id, additionalKey: 't', additionalValue: type});
          if (typeof(filter) != 'undefined') {
            const source = {name: filter.n, id: filter.id, type: filter.t == 'f' ? 'filter' : 'group', special: filter.s};
            const isAlreadyInList = this.sourceList.find( y => y.id == source.id && y.name == source.name && y.type == source.type);
            if (typeof(isAlreadyInList) == 'undefined') this.sourceList.push(source);
            this.selectedSource = source;
          }
        }
      } else {
        if (this.selectedSource == null || typeof(this.selectedSource) == 'undefined') {
          const UT = this.sourceList.find( x => x.special == 'unsegmented');
          if (typeof(UT) != 'undefined') this.selectedSource = UT;
        }
      }
      this.key = params.key;
      if (params.key) {
        this.paramKeyModel = { key: params.key }; ;
      }
      this.currentValues = params.values ? JSON.parse(params.values) : {};

      const {sidebar, ...everythingElse} = params;
      if (sidebar) {
        let options = { ...everythingElse};
        if (this.selectedSource?.id) {
          options = {
            ...options,
            filter: this.selectedSource?.id,
            filterType: this.selectedSource?.type
          }
        }
        this.store.dispatch(new OpenSidebar({
          openedSidebar: sidebar,
          options: options
        }));
        if (this.params.length == 0 || typeWasChanged) {
          if (params && typeof(params) != 'undefined') this.query(params);
        }
      } else {
        if (this.sidebarWasClosed) {
          this.sidebarWasClosed = false;
          return;
        }
        if (params && typeof(params) != 'undefined') {
          this.query(params);
        }
      }
    });

    this.sidebarSub = this.sidebar$.subscribe( state => {
      this.sidebarOpened = state.openedSidebar ? true : false;
    });
  }

  ngOnDestroy(): void {
    this.combineSub?.unsubscribe();
    this.refresherSub?.unsubscribe();
    this.selectedSub?.unsubscribe();
    this.paramSub?.unsubscribe();
    this.sidebarSub?.unsubscribe();
  }

  changeSort(name) {
    if (name == 'Url Params') {
      this.directions.last_direction = 'Url Params';
      if (this.directions.urlParamsDirection == 'Up') {
        this.directions.last_direction = null;
        this.directions.urlParamsDirection = 'neutral';
      } else if (this.directions.urlParamsDirection == 'neutral') {
        this.directions.urlParamsDirection = 'Down';
      } else if (this.directions.urlParamsDirection == 'Down') {
        this.directions.urlParamsDirection = 'Up';
      }
    } else if (name == 'Visits') {
      this.directions.last_direction = 'Visits';
      if (this.directions.visitsDirection == 'Up') {
        this.directions.last_direction = null;
        this.directions.visitsDirection = 'neutral';
      } else if (this.directions.visitsDirection == 'neutral') {
        this.directions.visitsDirection = 'Down';
      } else if (this.directions.visitsDirection == 'Down') {
        this.directions.visitsDirection = 'Up';
      }
    }
    this.sortParams();
  }

  sortParams() {
    if (this.directions.last_direction == null) this.params = [...this.originalParams];
    if (this.directions.last_direction == 'Url Params') {
      if (this.directions.urlParamsDirection == 'Up') {
        if (this.section == 'params') {
          this.params = [...this.params].sort((a,b) => (a.combo > b.combo ? 1 : -1));
        } else if (this.section == 'referrers') {
          this.params = [...this.params].sort((a,b) => (a.domain > b.domain ? 1 : -1));
        } else this.params = [...this.params].sort((a,b) => (a.path > b.path ? 1 : -1));
      } else if (this.directions.urlParamsDirection == 'Down') {
        if (this.section == 'params') {
          this.params = [...this.params].sort((a,b) => (b.combo > a.combo ? 1 : -1));
        } else if (this.section == 'referrers') {
          this.params = [...this.params].sort((a,b) => (b.domain > a.domain ? 1 : -1));
        } else this.params = [...this.params].sort((a,b) => (b.path > a.path ? 1 : -1));
      }
    } else if (this.directions.last_direction == 'Visits') {
      if (this.directions.visitsDirection == 'Up') {
        this.params = [...this.params].sort((a,b) => a.visits - b.visits);
      } else if (this.directions.visitsDirection == 'Down') {
        this.params = [...this.params].sort((a,b) => b.visits - a.visits);
      }
    }
  }

  onWinResize() {
    const el = document.getElementById('builder-table');
    if (el) {
      const viewportOffset = el.getBoundingClientRect();
      this.scrollerHeight = window.innerHeight - viewportOffset.top-2-50;
    }
  }

  query(force?) {
    if ((this.busy) && (!force)) { return; }
    this.busy = true;
    this.values = map(this.currentValues, function(value, key) {
        return { key: key, value: value };
    });

    const SelectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected );
    const StartDate = SelectedState.chosenDates.start;
    const EndDate = SelectedState.chosenDates.end;

    let queryParams: any = {
      start: StartDate,
      end: NextDay(EndDate, 1),
    };

    if (this.section == 'params') {
      queryParams.values = JSON.stringify({ params: this.values });
      if (this.key && typeof(this.key) != 'undefined') {
        queryParams.key = this.key;
      }
    }

    if (this.selectedSource?.id) {
      if (this.selectedSource?.type == 'filter') queryParams['filter'] = this.selectedSource?.id;
      else queryParams['filter_group_id'] = this.selectedSource?.id;
    }

    this.builderService.getParams(this.section, queryParams).subscribe((data: Array<any>) => {
      this.onWinResize();

      this.originalParams = data.map( (value, index) => {
        value.index = index;
        return value
      });
      this.params = this.originalParams;

      this.busy = false;
    });
    if (this.section == 'params') {
      this.builderService.getKeys(queryParams).subscribe((filtered_data: Array<any>) => {
        const defaultKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_keyword'];
        const paramKeys = uniqBy(filtered_data, param => param.key)
          .filter(param => indexOf(defaultKeys, param.key) == -1);

        const defaultParamKeys = map(defaultKeys, function(key) {
          return { key: key, grouping: 'Default' }
        });

        const any = { key: null, grouping: '', label: 'any' };

        this.paramKeys = [...[any], ...defaultParamKeys, ...paramKeys ];
      });
    }
  };

  changeStateKey() {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate([], { queryParams: { ...queryParams, key: this.paramKeyModel.key}});
  };

  openSelect(btn, select) {
    this.hideSelect = !this.hideSelect;
    select.open();
    setTimeout(() => { select.focus(); }, 100);
  }

  closeSelect() {
    this.hideSelect = !this.hideSelect;
    document.getElementsByClassName('btn-select')[0].classList.remove('hide');
  }

  selectSource(source) {
    this.selectedSource = source;
    let params: any = {...this.route.snapshot.queryParams};
    if (source.id) {
      if (source.type == 'filter') {
        params.filter = source.id;
      } else if (source.type == 'group') {
        params.filter_group = source.id;
      }
      this.router.navigate([], {queryParams: {
        ...params
      }});
    } else {
      this.router.navigate([], {queryParams: {
        ...params,
        filter: 'all'
      }});
    }
  }

  addParam(paramkey, paramValue) {
    var pair = {};
    pair[paramkey] = paramValue;
    var newValues = merge(this.currentValues, pair);
    const values = JSON.stringify(newValues);
    const {key, ...currentQueryParams} = this.route.snapshot.queryParams;

    const params: any = {
      ...currentQueryParams,
      values: values
    }

    this.paramKeyModel = null;

    this.selectedIndexes = [];
    this.selected = [];

    this.router.navigate([], {
      queryParams: params
    });
  };

  removeParam(param) {
    delete this.currentValues[param.key];

    if(isEmpty(this.currentValues))
    this.currentValues = undefined;

    let currentQueryParams = {...this.route.snapshot.queryParams};

    this.selectedIndexes = [];
    this.selected = [];

    this.router.navigate([], {queryParams: {
      ...currentQueryParams,
      values: JSON.stringify(this.currentValues)
    }});
  }

  openSidebar(param) {
    this.warningHidden = true;
    let params: any = {
      ...this.route.snapshot.queryParams,
      scope: this.section,
      sidebar: SidebarNames.dashboardBuilder,
    }
    if (this.selectedSource != null && this.selectedSource?.special != 'unsegmented') {
      if (this.selectedSource?.name == 'All Traffic') {
        params.filter = 'all'
      } else {
        if (this.selectedSource?.type == 'filter'){
          params.filter = this.selectedSource?.id;
        } else if (this.selectedSource?.type == 'group') {
          param.filter_group = this.selectedSource?.id;
        }
      }
    }
    if (this.section == 'params') {
      if (this.lastAdded) {
        const lastAddedKey = Object.keys(this.lastAdded);
        lastAddedKey.forEach( x => {
          delete this.currentValues[x];
        });
      }

      var pair = {};
      pair[param.key] = param.value;
      this.lastAdded = pair;
      var newValues = merge(this.currentValues, pair);
      const values = JSON.stringify(newValues);

      params.values = values;
    } else if (this.section == 'referrers') {
      params.value = param.domain
      params.type = 'referrers';
    } else {
      params.value = param.path
      params.type = 'paths';
    }
    this.router.navigate([], {
      queryParams: {
        ...params,
        scope: this.section,
        sidebar: SidebarNames.dashboardBuilder
      }
    });
  }

  addToDashboard(item, channel?) {
    const add = (channel?) => {
      item.adding = true;
      let params;
      if (this.section == 'params') {
        params = {
          name: item.combo,
          params: this.values.concat({ key: item.key, value: item.value })
        };
      } else if (this.section == 'referrers') {
        params = {
          name: item.domain,
          referrers: [{operator: 'equals', value: item.domain}]
        };
      } else if (this.section == 'paths') {
        params = {
          name: item.path,
          paths : [{operator: 'equals', value: item.path}],
          traffic_filter_type: 'advanced'
        };
      }

      this.filtersCreating.push(item);
      this.filterService.createNewFilter(params).subscribe( (x: any) => {
        this.filtersCreating = this.filtersCreating.filter( y => y != item);
        item.added = true;
        this.addedItems.push({name: params.name});
        item.adding = false;
        const newFilter: any = {
          id: x.id,
          name: x.name,
          it: x.type
        };
        this.store.dispatch([
          new AddFilter({filter: newFilter}),
          new AddToPendingDataRefresh({ id: x.id, type: 'f', movingTo: channel?.id})
        ]);
        if (typeof(channel) != 'undefined' && channel != null) {
          const node = {
            id: x.id,
            type: 'f',
            parent: {
              id: channel.id
            },
            index: 0
          };
          this.filterService.move(node).subscribe( () => {
            this.store.dispatch(new ScheduleTreeRefresh());
            this.reportRefresher.scheduleRefresh();
          })
        }
      });
    }
    if (typeof(channel) == 'undefined' || channel == null) {
      add();
    } else {
      add(channel);
    }
  };

  isInAddedItems(item) {
    let name;
    if (this.section == 'params') {
      name = item.combo;
    } else if (this.section == 'referrers') {
      name = item.domain;
    } else if (this.section == 'paths') {
      name = item.path
    }
    const found = this.addedItems.find(x => x.name == name );
    return typeof(found) != 'undefined';
  }

  toggleSelect(param) {
    if (this.isAddedAlready(param)) return;
    this.whichChannel = null;
    if (this.selectedIndexes.includes(param.index)) {
      this.selectedIndexes = this.selectedIndexes.filter(x => x != param.index);
      this.selected = this.selected.filter(x => x.index != param.index);
    } else {
      this.selectedIndexes.push(param.index);
      this.selected.push(param);
    }
  }

  toggleAll() {
    if (this.toggleAllShouldSelect) {
      this.selectedIndexes = [];
      this.params.forEach( (v,i) => {
        this.selectedIndexes.push(i);
        this.selected.push(v);
      });
    } else {
      this.selectedIndexes = [];
      this.selected = [];
    }
    this.toggleAllShouldSelect = !this.toggleAllShouldSelect;
  }

  startAddingToChannel() {
    if (this.selectedIndexes.length > 0 && this.selectedSource?.special == 'unsegmented') this.addingToChannel = true;
  }

  isSelected(param) {
    return this.selectedIndexes.includes(param.index);
  }

  selectChannel(event) {
    this.whichChannel = event;
  }

  addFilters(parentGroupid?) {
    if (this.whichChannel == null && !parentGroupid) return;
    const newFilters = this.selected.map( x => {
      let params;
      if (this.section == 'params') {
        params = {
          name: x.combo,
          params: this.values.concat({ key: x.key, value: x.value })
        };
      } else if (this.section == 'referrers') {
        params = {
          name: x.domain,
          referrers: [{operator: 'equals', value: x.domain}]
        };
      } else if (this.section == 'paths') {
        params = {
          name: x.path,
          paths : [{operator: 'equals', value: x.path}],
          traffic_filter_type: 'advanced'
        };
      }
      if (parentGroupid) params.parent_filter_group_id = parentGroupid;
      else if (this.whichChannel && this.whichChannel != 'on-top') params.parent_filter_group_id = this.whichChannel.id;
      return params;
    });
    this.addingInProgress = true;
    this.filterService.createNewFilter(newFilters).subscribe( (x: any) => {
      let resp = Object.prototype.toString.call(x) == '[object Array]' ? x : [x];
      resp = resp.map( r => {
        return {
          id: r.id,
          n: r.name,
          it: r.type
        }
      });
      let filters: any = {filters: resp};
      if (parentGroupid) filters.parentFilterGroupId = parentGroupid;
      else if (this.whichChannel && this.whichChannel != 'on-top') filters.parentFilterGroupId = this.whichChannel.id;

      let actions: any = [new AddFilter(filters)];
      
      if (filters.parentFilterGroupId) {
        const node = findInTree({node: {c: this.filterTree }, childrenKey: 'c', key: 'id', value: filters.parentFilterGroupId, additionalKey: 't', additionalValue: 'g', returnParent: true, updateLevel: true, withPath: this.filterTree, getPathIds: true});
        
        actions = [
          ...actions, 
          new AddToPendingDataRefresh({ ids: resp.map((x) => x.id), type: 'f',  case: 'creating-in-channel', movingTo: filters.parentFilterGroupId, pathArray: node.pathArray})
        ];
      }

      this.store.dispatch(actions).subscribe( () => {
        if (this.whichChannel && this.whichChannel != 'on-top') {
          setTimeout(() => {
            this.toastr.success(
              `Added filters under ${this.whichChannel.n}.`,
              'Success'
            );
          });
        }
        else {
          setTimeout(() => {
            this.toastr.success(
              'Added filters on top.',
              'Success'
            );
          });
        }
        this.dropSelected();
        switch(this.section) {
          case 'params':
            this.paramsAdded = [...this.paramsAdded, ...newFilters.map(x => x.name)];
            break;
          case 'referrers':
            this.referrersAdded = [...this.referrersAdded, ...newFilters.map(x => x.name)];
            break;
          case 'paths':
            this.pathsAdded = [...this.pathsAdded, ...newFilters.map(x => x.name)];
            break;
          default:
            return;
        }
      });
      this.addingInProgress = false;
    }, (error) => {
      this.addingInProgress = false;
      this.dropSelected();
    });
  }

  openNewChannelModal(isFromManageChannels?) {
    if ((this.whichChannel == null && !isFromManageChannels) || (isFromManageChannels && this.selectedGroups.length > 1)) return;
    const modalRef = this.modalService.open(NewChannelModalComponent, {windowClass: 'slideInDown'});
    modalRef.componentInstance.parentGroup = isFromManageChannels ? this.selectedGroups.length == 0 ? null : this.selectedGroups[0] : this.whichChannel == 'on-top' ? null : this.whichChannel;
    modalRef.componentInstance.justAddingChannel = true;
    modalRef.result.then(
      result => {
        if (isFromManageChannels) {
          setTimeout(() => {
            if (this.selectedGroups.length != 0) {
              this.toastr.success(
                `Added new channel under ${this.selectedGroups[0].n}.`,
                'Success'
              );
            }
            else {
              this.toastr.success(
                'Added new channel on top.',
                'Success'
              );
            }
            this.dropSelected(this.selectedGroups[0]);
            return;
          });
        } else if (result.result === 'createdGroup') {
          this.addFilters(result.groupId);
        }
      }
    );
  }

  dropSelected(passToReset?) {
    this.resetSelectedInGroupSelector.next(passToReset);
    this.addingToChannel = false;
    this.selected = [];
    this.selectedIndexes = [];
    this.whichChannel = null;
    this.selectedFilters = [];
    this.selectedGroups = [];
  }

  navigateTo(type, isPage?) {
    this.dropSelected();
    let queryParams = {...this.route.snapshot.queryParams};
    if (isPage) {
      queryParams.page = type;
    } else {
      queryParams.type = type;
    }
    this.router.navigate([],
      {
        queryParams: queryParams
      }
    );
  }

  isAddedAlready(param) {
    let isAdded = false;
    switch(this.section) {
      case 'params':
        isAdded = this.paramsAdded.includes(param.combo);
        break;
      case 'referrers':
        isAdded = this.referrersAdded.includes(param.domain);
        break;
      case 'paths':
        isAdded = this.pathsAdded.includes(param.path);
        break;
      default:
        return;
    }
    return isAdded;
  }

  hideWarning() {
    this.warningHidden = true;
    setTimeout(() => this.onWinResize(), 100);
  }

  showSelectedFilter(event) {
    const selectedFilter = event.item;
    this.localSubscription.showFilter(selectedFilter);
  }

  selectFiltersAndGroups(event) {
    this.selectedFilters = event.filters;
    this.selectedGroups = event.groups;
  }

  openConfirmDelete() {
    if (this.allTheSelected.length == 0) return;
    const modalRef = this.modalService.open(ConfirmDeleteModalComponent, {windowClass: 'slideInDown'});
    modalRef.componentInstance.filtersToDelete = this.selectedFilters;
    modalRef.componentInstance.groupsToDelete = this.selectedGroups;
    modalRef.result.then(
      result => {
        if (result.result == 'deletion-confirmed') {
          const filterIds = this.selectedFilters.map(x => x.id);
          const groupIds = this.selectedGroups.map(x => x.id);
          this.filterService.massDelete(filterIds, groupIds).subscribe( resp => {
            this.store.dispatch([
              new DeleteFilter({id: filterIds, multi: true}),
              new DeleteChannel({id: groupIds, multi: true})
            ]);
            this.dropSelected();
            setTimeout(() => {
              this.toastr.success(
                `Deleted Channels/Filters.`,
                'Success'
              );
            });
          });
        }
      }
    );
  }

  openMoving() {
    if (this.allTheSelected.length > 0) this.moving = true;
  }

  move() {
    if (this.movingToChannel == null) return;
    const filterIds = this.selectedFilters.map(x => x.id);
    const groupIds = this.selectedGroups.map(x => x.id);
    const destinationId = this.movingToChannel == 'on-top' ? 'root' : this.movingToChannel.id;
    this.filterService.massMove(destinationId, filterIds, groupIds).subscribe( x => {
      this.store.dispatch([
        new MoveFiltersAndChannels({
          filterIds: filterIds,
          groupIds: groupIds,
          destinationId: destinationId
        }),
        new AddToPendingDataRefresh({ids: filterIds, type: 'f', movingTo: destinationId}),
        new AddToPendingDataRefresh({ids: groupIds, type: 'g', movingTo: destinationId})
    ]);
      this.moving = false;
      setTimeout(() => {
        this.toastr.success(
          `Moved Channels/Filters.`,
          'Success'
        );
      });
    });
  }

  selectChannelForMoveTo(channel) {
    this.movingToChannel = channel;
  }

  get allTheSelected() {
    return [...this.selectedFilters, ...this.selectedGroups];
  }

}
