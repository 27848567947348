<ng-template #noChecked>
  <input type="radio">
</ng-template>

<div class="attr-card">
  <div *ngIf="showTitle" class="mb-4 title-no-bold">
    Attribution Type
  </div>
  <div class="row">
    <div class="mb-4" *ngIf="( 'forward' | isAttrType:attributionTypes) || !isProd" [ngClass]="{'col-lg-6': attributionTypes.length > 1 || !isProd, 'col-12': attributionTypes.length == 1 && isProd}">
      <div class="traffic__radio cursor-pointer" [ngClass]="{revenue__active: view?.attribution_type == 'forward'}" (click)="selectAttributionType('forward')">
        <label class="radio__label">
          <input *ngIf="view?.attribution_type == 'forward'; else noChecked" type="radio" checked="checked">
        </label>
        <h4>Marketer Spend View <i class="fa-solid fa-lock red me-1" *ngIf="!isProd && !( 'forward' | isAttrType:attributionTypes)"></i></h4>
        <p class="medium-dark">
          Select this option if you want to use forward looking attribution for your data (default).
        </p>
      </div>
    </div>
    <div *ngIf="( 'backward' | isAttrType:attributionTypes) || !isProd" [ngClass]="{'col-lg-6': attributionTypes.length > 1 || !isProd, 'col-12': attributionTypes.length == 1 && isProd}">
      <div class="traffic__radio cursor-pointer" [ngClass]="{revenue__active: view?.attribution_type == 'backward'}" (click)="selectAttributionType('backward')">
        <label class="radio__label">
          <input *ngIf="view?.attribution_type == 'backward'; else noChecked" type="radio" checked="checked">
        </label>
        <h4>Lookback Window <i class="fa-solid fa-lock red me-1" *ngIf="!isProd && !( 'backward' | isAttrType:attributionTypes)"></i></h4>
        <p class="medium-dark">
          Select this option if you want to use backward looking attribution for your data. Click to see your options.
        </p>
        <div class="dropdown" ngbDropdown>
          <span>Lookback window&nbsp;</span>
          <button #lookbackPeriodModelDropdown class="btn btn-default dropdown-toggle btn-select" type="button" id="dropdownMenuButton" [attr.data-boundary]="lookbackPeriodModelDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            {{ view?.lookback_window }}
          </button>
          <span>&nbsp;days</span>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
            <span *ngIf="project?.id == 2324" class="dropdown-item" (click)='selectPeriod(7)'>
              {{ 7 }}
            </span>
            <span class="dropdown-item" (click)='selectPeriod(30)'>
              30
            </span>
            <span class="dropdown-item" (click)='selectPeriod(90)'>
              90
            </span>
            <span *ngFor="let window of additionalLookbackWindows" class="dropdown-item" (click)='selectPeriod(window)'>
              {{ window }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

