import { Component, AfterViewInit, OnChanges, Input } from '@angular/core';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { Store } from '@ngxs/store';
import { range, find } from 'lodash-es';

import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';

@Component({
  selector: 'app-sidebar-bar-chart',
  templateUrl: './sidebar-bar-chart.component.html',
  styleUrls: ['./sidebar-bar-chart.component.scss']
})
export class SidebarBarChartComponent implements AfterViewInit, OnChanges {
  @Input() chartName;
  @Input() data: Array<{day?: number; count?: number; date?; revenue? }>;
  @Input() tooltipName;

  rendered = false;

  constructor(private store: Store, private tooltipService: TooltipService) { }

  ngOnChanges() {
    if (this.rendered) { return; }
    this.drawChart();
  }

  ngAfterViewInit() {
    this.rendered = true;
    this.store.select( state => state.selected.chosenDates.start).subscribe( () => {
      this.drawChart()
    });
  }

  drawChart() {
    d3.select(`#${this.chartName}`).select('svg').remove();

    if (!document.getElementById(this.chartName)) {
      return;
    }

    const startDate = this.store.selectSnapshot<SelectedStateModel>( state => state.selected).chosenDates.start;

    let dataModified = [];
    if (this.chartName == 'conversion-chart') {
      const dateRange = (this.data[this.data.length - 1].day || 0) + 1;
      const dateRangeArray = range(dateRange);
      const reducer = (accumulator, currentValue) => {
        let timelineCount = 0;
        const dayFromTimeline = find(this.data, day => day.day === currentValue);
        if (dayFromTimeline) {
          timelineCount = dayFromTimeline.count;
        }
        accumulator.push({ day: currentValue, count: timelineCount});
        return accumulator;
      };
      dataModified = dateRangeArray.reduce(reducer, []);
    } else {
      this.data.forEach( x => {
        dataModified.push({day: x.date, count: x.revenue});
      });
    }
    
    setTimeout(() => {
      const wrapperProperties = document.getElementById(this.chartName).getBoundingClientRect();
      const width = wrapperProperties.width;
      const height = wrapperProperties.height;
  
      const x = d3.scaleBand()
                    .domain(dataModified.map( d => this.chartName == 'conversion-chart' ? d.day.toString() : d.day))
                    .range([0, width])
                    .paddingInner(0.3)
                    .paddingOuter(0.3);
  
      const y = d3.scaleLinear()
                    .domain([1, d3.max(this.data, d => {
                      const val = this.chartName == 'conversion-chart' ? d.count + 1 : d.revenue;
                      return val;
                    })])
                    .range([height, 0]);
  
      const svg = d3.select(`#${this.chartName}`).append('svg')
                    .attr('width', width )
                    .attr('height', height);
  
      svg.selectAll('.bar')
          .data(dataModified)
            .enter().append('rect')
            .attr('class', 'bar')
            .attr('x', d => x(this.chartName == 'conversion-chart' ? d.day.toString() : d.day))
            .attr('width', x.bandwidth())
            .attr('y', d => y(this.chartName == 'conversion-chart' ? d.count + 1 : d.count))
            .attr('height', d => {
              const rectHeight = height - (y(this.chartName == 'conversion-chart' ? d.count + 1 : d.count));
              return rectHeight >= 0 ? rectHeight : 0;
            })
            .on('mouseover', (e, d: any) => {
              this.tooltipService.mouseOver.emit({ name: this.tooltipName, data: {data: d, element: e.srcElement}});
            })
            .on('mouseout', () => this.tooltipService.mouseOut.emit());
  
      svg.selectAll('.underline')
          .data(dataModified)
            .enter().append('rect')
            .attr('class', 'underline')
            .attr('x', d => x(this.chartName == 'conversion-chart' ? d.day.toString() : d.day))
            .attr('width', x.bandwidth())
            .attr('y', d => height-1)
            .attr('height', d => 1);;
    }, 500)
    
  }

}
