import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotOpenIntegration',
})
export class IsNotOpenIntegrationPipe implements PipeTransform {
  transform(integration, openIntegrations): any {
    return typeof(openIntegrations.find(x => x.key == integration.key)) == 'undefined';
  }
}
