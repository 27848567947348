import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngb-single-datepicker',
  templateUrl: './ngb-single-datepicker.component.html',
  styleUrls: ['./ngb-single-datepicker.component.scss']
})
export class NgbSingleDatepickerComponent implements OnChanges {
  model: NgbDateStruct;
  @Input() buttonText: string;
  @Input() initialValue: string;

  @Output() eventEmitter = new EventEmitter<any>();
  @Output() buttonClick = new EventEmitter<any>();

  constructor(public formatter: NgbDateParserFormatter) { }

  ngOnChanges(): void {
    this.model = NgbDate.from(this.formatter.parse(this.initialValue));
  }

  onDateSelection(e) {
    this.eventEmitter.emit(this.formatter.format(e));
  }

  onButtonClick() {
    this.buttonClick.emit(this.formatter.format(this.model));
  }
}
