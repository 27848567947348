import { State, Action, StateContext } from '@ngxs/store';

import {
  SidebarSetName,
} from '../actions/only-simple.actions';
import { Injectable } from '@angular/core';

export interface OnlySimpleStateModel {
  sidebarName: string;
}

@State<OnlySimpleStateModel>({
  name: 'onlySimple',
  defaults: {
    sidebarName: null
  }
})
@Injectable()
export class OnlySimpleState {

  @Action(SidebarSetName)
  SidebarSetName(ctx: StateContext<OnlySimpleStateModel>, { payload }: SidebarSetName) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      sidebarName: payload?.name ? payload?.name : null
    });
  }
}

