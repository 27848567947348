import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Select, Store } from '@ngxs/store';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetCurrency, SetCurrencySymbol } from 'src/app/store/actions/project.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss']
})
export class CurrencySelectorComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() showTitle = true;
  @Input() isInModal = false;

  isProd = environment.isProd;
  project: Project;
  currencySymbol: string;
  currency: string;
  currencyConversionEnabled: boolean;

  currencySymbols = ['$', '€', '£', '¥', '₹', '₽'];
  currencies = ['No conversion', 'USD', 'EUR', 'GBP', 'CAD', 'AUD'];

  constructor(private projectService: ProjectService, private store: Store) { }

  ngOnInit(): void {
    this.project$.subscribe(x => {
      this.currencyConversionEnabled = x.project?.options?.features?.currency_conversion;
      this.currencySymbol = x.project?.currency_symbol;
      this.currency = x.project?.currency;
      if (this.currency === '' || this.currency === null) {
        this.currency = this.currencies[0];
      }
    });
  }

  saveCurrencySymbol() {
    this.projectService.setAttribute('currency_symbol', this.currencySymbol).subscribe(() => {
      this.store.dispatch(new SetCurrencySymbol(this.currencySymbol));
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

  saveCurrency() {
    // Save currency as null if it's the first option
    const currencyToSave = this.currency === this.currencies[0] ? null : this.currency;

    this.projectService.setAttribute('currency', currencyToSave).subscribe(() => {
      this.store.dispatch(new SetCurrency(currencyToSave));
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }
}
