import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'integer'
})
export class IntegerPipe implements PipeTransform {
  DP: DecimalPipe = new DecimalPipe('en-US');

  transform(value: number ): any {
    if (value === null || typeof(value) === 'undefined') { return null; }
    return this.DP.transform(value, '1.0');
  }
}
