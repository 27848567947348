import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ViewsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getViews() {
    const Url = `${environment.clientApiUrl}/project_views`;

    return this.httpClient.get(Url);
  }

  createView(params) {
    const Url = `${environment.clientApiUrl}/project_views`;

    return this.httpClient.post(Url, params);
  }

  updateView(params, id) {
    const Url = `${environment.clientApiUrl}/project_views/${id}`;

    return this.httpClient.post(Url, params);
  }

  updateCurrentView(viewID) {
    const URL = `${environment.clientApiUrl}/project_views/set_current_view`;
    return this.httpClient.post(URL, {project_view_id: viewID});
  }

  deleteView(id) {
    const Url = `${environment.clientApiUrl}/project_views/${id}`;

    return this.httpClient.delete(Url);
  }

}
