import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AccessService } from 'src/app/core/services/access/access.service';
import { SetSettingSection } from 'src/app/store/actions/selected.actions';
import { Access } from '../../../../core/models/access.model';
import { Project } from '../../../../core/models/project.model';
import { Observable } from 'rxjs';
import { each, some } from 'lodash-es';
import {ProjectStateModel} from '../../../../store/states/project.state';
import {SettingsStateModel} from '../../../../store/states/settings.state';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

@Component({
  selector: 'app-weekly-email',
  templateUrl: './weekly-email.component.html',
  styleUrls: ['./weekly-email.component.scss']
})
export class WeeklyEmailComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.settings) settings$: Observable<SettingsStateModel>;

  project: Project;
  accesses: [Access];

  constructor(
    private accessService: AccessService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.refreshAccesses();

    this.settings$.subscribe( x => {
      this.accesses = x.accesses;
    })

    this.store.dispatch(new SetSettingSection(SettingsSections.weeklyEmails));
  }

  refreshAccesses(){
    this.accessService.get();
  }

  isNotificationsOn(access) {
    return access.weekly_notification;
  }

  allNotificationsOn() {
    return !some(this.accesses, (access) => !access.weekly_notification);
  }

  toggleWeeklyEmails(access: Access, on: boolean) {
    access.weekly_notification = on;
    this.accessService.save(access);
  }

  toggleAllWeeklyEmails(on: boolean) {
    each(this.accesses, (access) => this.toggleWeeklyEmails(access, on))
  }

}
