<div class="d-block">
  <div
  class="dash__row dash__row--all"
  [attr.id]="rowName == 'Unknown Source' ? 'unattributed-filter' : null"
  [class.breaked-down]="(itemBDs?.original && (rowName === 'All Traffic' ? allTrafficPathOpened : !(itemBDs?.hidden | dashboardBreakdownHidden))) || itemBDs?.waitingForBreakdown">
    <div class="item-name">
      <div class="dash__name">
        {{ rowName }}
        <span *ngIf="tooltip">
          <ng-template #tip>
            <div [innerHTML]="tooltip"></div>
          </ng-template>
          <small><i class="fa-solid fa-info-circle dash_tooltip" placement="right" [ngbTooltip]="tip" container="body" triggers="click:blur" tooltipClass="tooltip-on-top" autoClose="outside"></i></small>
        </span>
      </div>
      <div class="dash__name--sub">
        <span [innerHTML]="rowNameSub"></span>
        <a *ngIf="shouldBreakDown && !isTimeframe && !(special | includedInArray:dontShowPathsForSpecials)" (click)="breakDown()" class="ms-2 tool-button">
          <i *ngIf="rowName === 'All Traffic' ? !allTrafficPathOpened : (itemBDs?.hidden | dashboardBreakdownHidden); else breakDownArrowDown" class="fa-solid fa-arrow-down-wide-short" [class.disabled]="itemBDs?.waitingForBreakdown" placement="top" ngbTooltip="Show Landing Paths"></i>
          <ng-template #breakDownArrowDown>
            <span placement="top" ngbTooltip="Switch back to filters">
              <i class="fa-solid fa-arrow-up-wide-short"></i> | Landing Paths
            </span>
          </ng-template>
        </a>
      </div>
    </div>
    <ng-template #noTimeframe>
      <div class="flex-item">
        <div class="dash__data">
          {{ amounts === 0 ? '--' : (amounts | projectCurrency) }}
        </div>
        <div class="dash-progress">
          <div class="dash-progress-bar" [ngStyle]="showLines ? {'width': (amounts / (maxValues?.a || 0) * 100) + '%'} : null"></div>
        </div>
        <div class="dash__data--sub">
          {{ amounts === 0 || !amounts || conversions === 0 || !conversions ? '--' : ((amounts  / conversions) | projectCurrency) }}
        </div>
      </div>
      <div class="flex-item">
        <div class="dash__data">
          <a [attr.data-cy]="rowName === 'Unknown Source' ? 'unknown-source-visit' : 'fixed-row-visit'" (click)="openSidebar(sidebarNames.visits, {scope: 'visits', special: special, rowName: rowName})">
            {{ (visits || 0) | integer }}
          </a>
        </div>
        <div class="dash-progress">
          <div class="dash-progress-bar" [ngStyle]="showLines ? {'width': (visits / (maxValues?.v || 0) * 100) + '%'} : null"></div>
        </div>
        <div class="dash__data--sub">
          <span *ngIf="!isProd && clicks && clicks !== 0">({{ clicks | integer }})</span>
          {{ !visits || visits === 0 || !conversions || conversions === 0 ? '--' : (conversions / visits | percent: '1.0-2') }}
        </div>
      </div>
      <div class="flex-item">
        <div class="dash__data">
          <span *ngIf="overlaps" class="dash__overlap">
            (<a (click)="openSidebar(sidebarNames.overlaps, {})">{{ overlaps }}</a>)
          </span>
          <a (click)="openSidebar(sidebarNames.visits, {scope: 'conversions'})">
            <span [ngSwitch]="selectedAttributionModel?.name">
              <span  *ngSwitchCase="'first_touch'">
                {{ (conversions || 0) | integer }}
              </span>
              <span  *ngSwitchCase="'last_touch'">
                {{ (conversions || 0) | integer }}
              </span>
              <span *ngSwitchDefault>
                {{ (conversions || 0) | defaultNumber }}
              </span>
            </span>
          </a>
        </div>
        <div class="dash-progress">
          <div class="dash-progress-bar" [ngStyle]="showLines ? {'width': (conversions / (maxValues?.c || 0) * 100) + '%'} : null"></div>
        </div>
        <div class="dash__data--sub">
          {{ !revenue || revenue === 0 || !conversions || conversions === 0 ? '--' : ( (revenue / conversions) | projectCurrency: 'cents' : '1.2') }}
        </div>
      </div>
      <div class="flex-item">
        <div class="dash__data">
          <a (click)="openSidebar(sidebarNames.revenues, {})">
            {{ revenue | projectCurrency: 'cents' : '1.2'  }}
          </a>
        </div>
        <div class="dash-progress">
          <div class="dash-progress-bar" [ngStyle]="showLines ? {'width': (revenue / (maxValues?.r || 0) * 100) + '%'} : null"></div>
        </div>
        <div class="dash__data--sub">
          {{ revenue === 0 || amounts === 0 ? '' : ((revenue / amounts) | percent: '1.2') }}
          <span class="bold" [class.funds-red-dash]="(revenue-amounts) < 0" [class.funds-green]="(revenue-amounts) > 0"> {{ (revenue - amounts) | projectCurrency: 'cents' : '1.2' }}</span>
        </div>
      </div>
    </ng-template>
    <ng-template #timeframeRow>
      <app-timeframe-row
        [periodArray]="periodArray"
        [report]="timeframeReport"
        [selectedAttributionModel]="selectedAttributionModel"
        [tableRevenue]="tableRevenue"
        (OpenSidebar)="openTimeFrameSidebar($event)"
        [special]="special"
        [rowName]="rowName"
        [isFTP]="isFTP">
      </app-timeframe-row>
    </ng-template>
    <ng-container *ngTemplateOutlet="isTimeframe ? timeframeRow : noTimeframe"></ng-container>
  </div>
  <div *ngIf="itemBDs?.waitingForBreakdown" class="dash__row break-down-row">
    <div class="m-auto">
      <app-loader [position]="'relative'" [color]="'green'" [backgroundColor]="'#f6fcff'" [height]="'35px'"></app-loader>
    </div>
  </div>

  <div *ngIf="itemBDs?.original && (rowName === 'All Traffic' ? allTrafficPathOpened : !(itemBDs?.hidden | dashboardBreakdownHidden)) && !isTimeframe">
    @for (path of (itemBDs?.original | dashboardSortedBreakdowns:lastEmittedSort:sortTable); track path) {
      <div class="dash__row break-down-row">
        <div class="item-name breakdown">
          <div class="dash__name">
            {{ path.name || '/' }}
          </div>
          <div class="dash__name--sub">
            Path
          </div>
        </div>
        <div class="flex-item"></div>
        <div class="flex-item">
          <div class="dash__data">
            {{ path.object.visits ? (path.object.visits | integer) : 0 }}
          </div>
          <div class="dash-progress">
            <div class="dash-progress-bar" [ngStyle]="{'width': (path.object.visits / (maxValues?.v || 0) * 100) + '%'}"></div>
          </div>
          <div class="dash__data--sub">
            {{ path.object.visits === 0 || path.object.conversions === 0 ? '--' : ((path.object.conversions / path.object.visits) | percent: '1.0-2') }}
          </div>
        </div>
        <div class="flex-item">
          <div class="dash__data">
            <span [ngSwitch]="selectedAttributionModel?.name">
              <span  *ngSwitchCase="'first_touch'">
                {{ path.object.conversions ? (path.object.conversions | integer) : 0 }}
              </span>
              <span  *ngSwitchCase="'last_touch'">
                {{ path.object.conversions ?  (path.object.conversions | integer) : 0 }}
              </span>
              <span *ngSwitchDefault>
                {{ path.object.conversions ? (path.object.conversions | defaultNumber) : 0 }}
              </span>
            </span>
          </div>
          <div class="dash-progress">
            <div class="dash-progress-bar" [ngStyle]="{'width': (path.object.conversions * 100 / (maxValues?.c || 0)) + '%'}"></div>
          </div>
          <div class="dash__data--sub">
            {{ path.object.revenue ? ((path.object.revenue / path.object.conversions) | projectCurrency) : '--' }}
          </div>
        </div>
        <div class="flex-item">
          <div class="dash__data">
            {{ path.object.revenue ? (path.object.revenue | projectCurrency) : 0 }}
          </div>
          <div class="dash-progress">
            <div class="dash-progress-bar" [ngStyle]="{'width': (path.object.revenue / (maxValues?.r || 0) * 100) + '%'}"></div>
          </div>
          <div class="dash__data--sub">
            --
          </div>
        </div>
      </div>
    }
  </div>
</div>