import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project } from 'src/app/core/models/project.model';
import { ToggleFirstPurchaseOnly } from 'src/app/store/actions/base.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';

@Component({
  selector: 'app-first-purchase-only',
  templateUrl: './first-purchase-only.component.html',
  styleUrls: ['./first-purchase-only.component.scss']
})
export class FirstPurchaseOnlyComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.base.firstPurchaseOnly) firstPurchaseOnly$: Observable<boolean>;
  @Input() showTitle = true;
  @Input() isInModal = false;

  project: Project;
  firstPurchaseOnly = false;
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.project$.subscribe(x => this.project = x.project);
    this.firstPurchaseOnly$.subscribe( resp => this.firstPurchaseOnly = resp);
  }

  toggleFPO(e) {
    this.store.dispatch(new ToggleFirstPurchaseOnly).subscribe(() => {
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

}
