import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AttributionModels } from 'src/app/core/constants/attribution-models';
import { Project, View } from 'src/app/core/models/project.model';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Component({
  selector: 'view-selected-params',
  templateUrl: './selected-params.component.html',
  styleUrls: ['./selected-params.component.scss']
})
export class SelectedParamsComponent implements OnInit, OnChanges {
  @Input() isCreate = true;
  @Input() view: View;
  @Input() changedParams: Array<string>;
  @Input() isPreset: boolean = true;
  @Select(state => state.project) projectState$:Observable<ProjectStateModel>;
  @Select(state => state.selected) selectedState$:Observable<SelectedStateModel>;

  attributionModels = Object.fromEntries(AttributionModels.map(x => [x.name, x.label]));

  paramsToList;
  project: Project;
  chosenDates;

  sort = [
    'conversion_event',
    'attribution_model',
    'attribution_type',
    'traffic_option',
    'revenue_group_id',
    'daterange_preset',
    'aggregation_period',
    'disregard_existing_users',
    'property_key',
    'property_value',
  ]

  VIEW_DICT = {
    name: 'Name',
    conversion_event: 'Conversion Event',
    attribution_model: 'Attribution Model',
    attribution_type: 'Attribution Type',
    traffic_option: 'Traffic Option',
    cutoff_event: 'Cutoff Event',
    revenue_group_id: 'Revenue Group',
    daterange_preset: 'Date-range Preset',
    aggregation_period: 'Aggregation Period',
    disregard_existing_users: 'Disregard Existing Users',
    lookback_window: 'Lookback Window',
    property_key: 'Property Key',
    property_value: 'Property Value',
  };

  attrTypeDict = {
    forward: 'Marketer Spend View',
    backward: 'Lookback Window'
  }
  
  constructor(private FTWS: FilterTreeWorkerService) {}

  ngOnInit(): void {
    this.projectState$.subscribe(project => {
      this.project = project.project;
    });
    this.selectedState$.subscribe(selected => {
      this.chosenDates = selected.chosenDates;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let {id, name, ...PTL} = this.view;
    if (PTL.revenue_group_id === 0) {
      PTL.revenue_group_id = 'Conversion Event';
    } else if (PTL.revenue_group_id && this.project?.settings.revenue_groups) {
      PTL.revenue_group_id = this.project.settings.revenue_groups.find(rg => rg.id == PTL.revenue_group_id)?.name;
    }
    this.paramsToList = PTL;
    console.log(this.view);
  }

}
