import { remove, find, indexOf } from 'lodash-es';

const findInTree = ({node, childrenKey, key, value,  additionalKey = null, additionalValue = null, returnParent = null, updateLevel = null, topParent = null, withPath = null, getPathIds = false}) => {
  let found = null;
  if (additionalKey && additionalValue) {
    found = find(node[childrenKey], x => x[key] === value && x[additionalKey] === additionalValue);
  } else {
    found = find(node[childrenKey], x => x[key] === value);
  }
  if (typeof(found) === 'undefined') {
    for (const item of node[childrenKey]) {
      if (typeof(topParent) == 'undefined') {
        if (typeof(found) === 'undefined' && item[childrenKey] && item[childrenKey].length > 0) {
          found = findInTree({node: item, childrenKey, key, value, additionalKey, additionalValue, returnParent, updateLevel, withPath, getPathIds});
        }
      } else {
        let parent = topParent == true ? item : topParent;
        if (typeof(found) === 'undefined' && item[childrenKey] && item[childrenKey].length > 0) {
          found = findInTree({node:item, childrenKey, key, value, additionalKey, additionalValue, returnParent, updateLevel, topParent: parent, withPath, getPathIds});
        }
      }
    }
  } else if (returnParent && !found.parent) {
    if (updateLevel) {
      if (childrenKey == 'children') {
        found.level = node.id ? node.level + 1 : 0;
      } else {
        found.l = node.id ? node.l + 1 : 0;
      }
    }
    const index = indexOf(node[childrenKey], found);
    if (topParent && topParent != true) {
      found = {...found, topParent: topParent};
    }
    found = {...found, parent: node, index: index};
  }
  if (found && withPath) {
    found = {...found, pathArray: returnAllParents({tree: withPath, childrenKey, key, value, additionalKey, additionalValue, shouldReturnWholeChannels:getPathIds})};
  }
  return found;
};

const findInTreeV2 = (node, childrenKey, key, value,  additionalKey?, additionalValue?, returnParent?, updateLevel?, topParent?, withPath?) => {
  let found = null;
  if (additionalKey && additionalValue) {
    found = find(node[childrenKey], x => x[key] === value && x[additionalKey] === additionalValue);
  } else {
    found = find(node[childrenKey], x => x[key] === value);
  }
  if (found && node.pathArray) {
    found = {...found, pathArray: [...node.pathArray]};
  }
  if (found && topParent && topParent != true) {
    found = {...found, topParent: topParent};
  }
  if (typeof(found) === 'undefined') {
    for (let item of node[childrenKey]) {
      let parent;
      if (topParent) parent = topParent == true ? item : topParent;
      if (node.pathArray || withPath) {
        item = {...item, pathArray: withPath ? [item.n] : [...node.pathArray, item.n]};
      }
      if (typeof(found) === 'undefined' && item[childrenKey] && item[childrenKey].length > 0) {
        found = findInTreeV2(item, childrenKey, key, value, additionalKey, additionalValue, returnParent, updateLevel, parent);
      }
      if (found) break;
    }
  } else if (returnParent && !found.parent) {
    if (updateLevel) {
      if (childrenKey == 'children') {
        found.level = node.id ? node.level + 1 : 0;
      } else {
        found.l = node.id ? node.l + 1 : 0;
      }
    }
    const index = indexOf(node[childrenKey], found);
    found = {...found, parent: node, index: index};
  }
  return found;
};

const removeInTree = (node, childrenKey, key, value,  additionalKey?, additionalValue?, shouldReturn?) => {
  let found = null;
  let itemToReturn;
  let wasFound = false;
  if (additionalKey && additionalValue) {
    found = node[childrenKey]?.find(x => x[key] === value && x[additionalKey] === additionalValue);
  } else {
    found = node[childrenKey]?.find(x => x[key] === value);
  }
  if (found) {
    if (shouldReturn) itemToReturn = {...found};
    remove(node[childrenKey], x => {
      return x === found;
    });
    if (shouldReturn) return itemToReturn; else return true;
  } else {
    for (const item of node[childrenKey]) {
      if (typeof(found) === 'undefined' && item[childrenKey] && item[childrenKey].length > 0 && !wasFound) {
        if (shouldReturn) {
          itemToReturn = removeInTree(item, childrenKey, key, value, additionalKey, additionalValue, shouldReturn);
          if (itemToReturn) {
            wasFound = true;
          }
        } else {
          wasFound = removeInTree(item, childrenKey, key, value, additionalKey, additionalValue, shouldReturn);
        }
      }
    }
  }
  return itemToReturn;
};

const findAllInTree = (node, childrenKey, key, value, additionalKey?, additionalValue?, topParent?, withPath?, returnParent?) => {
  let array = [];
  const isVisible = typeof(node.visible) != 'undefined' ? node.visible : typeof(node.id) == 'undefined' ? true : false;
  node[childrenKey].forEach( x => {
    if (x[childrenKey]) {
      const parent = topParent == true ? x : topParent;
      if (node.path || withPath) {
        x = {...x, 
          path: {
            names: withPath ? [x.n] : [...node.path.names, x.n],
            ids: withPath ? [x.id] : [...node.path.ids, x.id]
          },
          visible: (typeof(node.e) == 'undefined'? true : node.e) && isVisible
        }
      }
      array = [...array, ...findAllInTree(x, childrenKey, key, value, false, false, parent, null, returnParent)];
    }
    const condition = additionalKey && additionalValue ? x[key] === value && x[additionalKey] === additionalValue : x[key] === value;
    if (condition) {
      if (node.path) {
        x = {...x, path: node.path};
      }
      if (topParent && topParent != true) {
        delete topParent.c;
        x = {...x, topParent: topParent};
      }
      if (returnParent) {
        delete node.c;
        x = {...x, parent: node};
      }
      x.visible = (typeof(node.e) != 'undefined' ? node.e : true) && isVisible;
      array.push(x);
    }
  });
  return array;
};

const findAllIds = (node, childrenKey, key, value, onlyVisible?) => {
  let array = [];
  const isVisible = typeof(node.visible) != 'undefined' ? node.visible : typeof(node.id) == 'undefined' ? true : false;
  node[childrenKey].forEach( x => {
    if (x[childrenKey] && x[childrenKey].length > 0) {
      x = {...x, 
        visible: (typeof(node.e) == 'undefined'? true : node.e) && isVisible
      }
      array = [...array, ...findAllIds(x, childrenKey, key, value, onlyVisible)];
    }
    if (x[key] === value) {
      if (onlyVisible) {
        x.visible = (typeof(node.e) != 'undefined' ? node.e : true) && isVisible;
        if (x.visible) array.push(x.id);
      } else {
        array.push(x.id);
      }
    }
  });
  return array;
};

const findTopNodeInTree = (tree: [], childrenKey, key, value,  additionalKey?, additionalValue?) => {
  let found = null;
  tree.forEach( x => {
    if (found) { return; }
    if (x[childrenKey]) {
      if (findInTree({node: x, childrenKey, key, value, additionalKey, additionalValue})) {
        found = x;
      }
    }
  });
  return found;
};

const returnAllParents = ({tree, childrenKey, key, value, additionalKey = null, additionalValue = null, nameArray = [], shouldReturnWholeChannels = false}) => {
  let alreadyFound = false;
  let array = nameArray;
  tree.forEach( (x: any) => {
    if (alreadyFound) return;
    if (x[childrenKey]) {
      if (findInTree({node: x, childrenKey, key, value, additionalKey, additionalValue})) {
        alreadyFound = true;
        if (shouldReturnWholeChannels) {
          array.push(x);
        } else array.push(x.n);
        array = returnAllParents({ tree: x.c, childrenKey, key, value, additionalKey, additionalValue, nameArray: array, shouldReturnWholeChannels});
      }
    }
  });
  return array;
}

const reduceTree = (arrayTree) => {
  arrayTree = {
    ...arrayTree,
    c: Object.fromEntries(arrayTree.c.map((item) => [item.id, item.t === 'g' && item.c?.length > 0 ? reduceTree(item) : item]))
  };
  return arrayTree;
};

export { findInTree, findInTreeV2, removeInTree, findAllInTree, findAllIds, findTopNodeInTree, returnAllParents, reduceTree };
