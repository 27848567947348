import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  mouseOver = new EventEmitter<any>();
  mouseOut = new EventEmitter<any>();

  constructor() { }
}
