import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { defaultPreset } from 'src/app/core/constants/overview';
import { BaseService } from 'src/app/core/services/base/base.service';

@Component({
  selector: 'app-preset-switcher-dropdown',
  templateUrl: './preset-switcher-dropdown.component.html',
  styleUrls: ['./preset-switcher-dropdown.component.scss']
})
export class PresetSwitcherDropdownComponent {
  presets;
  @Input() name;
  @Input() tooltipPosition = 'left';
  @Input() selectedPreset = defaultPreset;
  @Input() earliestDate;
  @Output() valueChange = new EventEmitter();

  constructor(private baseService: BaseService) {
    this.presets = baseService.getPresets();
  }

  selectPreset(preset) {
    this.selectedPreset = preset;
    this.valueChange.emit(preset);
  }

}
