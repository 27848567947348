import { State, Action, StateContext } from '@ngxs/store';

import { SetOverview, SetOverviewInProgress, SetOverviewReportsToNull, ClearOverview } from '../actions/overview.actions';
import { DateFormat } from '../../core/helpers/chart-date-formatter';
import { Injectable } from '@angular/core';
import { OverviewService } from 'src/app/core/services/overview/overview.service';
import { GraphItem, Report, SummaryReport } from '../interfaces/overview';

export interface OverviewStateModel {
    inProgress: boolean;
    report: {
      revenue: Report;
      revenue_per_sale: Report;
      roas: Report;
      roas_by_channel: Report;
      spend_by_channel: Report;
      summary: SummaryReport;
    };
}

@State<OverviewStateModel>({
    name: 'overview',
    defaults: {
      inProgress: false,
      report: null
    }
})
@Injectable()
export class OverviewState {
  constructor(private overviewService: OverviewService) {}

  @Action(SetOverview)
  SetOverview(ctx: StateContext<OverviewStateModel>, { payload }: SetOverview) {
    const getState = ctx.getState();

    const newReport = {
      revenue: { meta: { needRefresh: false }} as Report,
      revenue_per_sale: { meta: { needRefresh: false }} as Report,
      roas: { meta: { needRefresh: false }} as Report,
      roas_by_channel: { meta: { needRefresh: false }} as Report,
      spend_by_channel: { meta: { needRefresh: false }} as Report,
      summary: { meta: { needRefresh: false }} as SummaryReport
    };

    Object.keys(newReport).forEach(key => {
      if (Object.keys(payload.widgets).indexOf(key) === -1) {
        newReport[key] = getState.report[key];
      }
    });

    if (payload.widgets.revenue) {
      newReport.revenue.meta = {...payload.widgets.revenue.meta, ...newReport.revenue.meta};
      if (payload.widgets.revenue.report){
        newReport.revenue.list = payload.widgets.revenue.report.list.map( item => {
          return {
            label: DateFormat(item.period, payload.widgets.revenue.meta.period),
            amount: item.revenue
          };
        });
      }
    }

    if (payload.widgets.revenue_per_sale) {
      newReport.revenue_per_sale.meta = {...payload.widgets.revenue_per_sale.meta, ...newReport.revenue_per_sale.meta};
      if (payload.widgets.revenue_per_sale.report){
        newReport.revenue_per_sale.list = payload.widgets.revenue_per_sale.report.list.map( item => {
          return {
            label: DateFormat(item.period, payload.widgets.revenue_per_sale.meta.period),
            amount: item.revenue
          } as GraphItem;
        });
      }
    }

    if (payload.widgets.roas) {
      newReport.roas.meta = {...payload.widgets.roas.meta, ...newReport.roas.meta};
      if (payload.widgets.roas.report){
        newReport.roas.list = payload.widgets.roas.report.list.map( item => {
          return {
            label: DateFormat(item.period, payload.widgets.roas.meta.period),
            amount: item.roas
          } as GraphItem;
        });
      }
    }

    if (payload.widgets.roas_by_channel) {
      newReport.roas_by_channel.meta = {...payload.widgets.roas_by_channel.meta, ...newReport.roas_by_channel.meta};
      if (payload.widgets.roas_by_channel.report){
        newReport.roas_by_channel.list = payload.widgets.roas_by_channel.report.list.map( item => {
          return {
            label: item.channel,
            revenue: item.revenue,
            spend: item.spend,
            roas: item.roas
          } as GraphItem;
        });
      }
    }

    if (payload.widgets.spend_by_channel) {
      newReport.spend_by_channel.meta = {...payload.widgets.spend_by_channel.meta, ...newReport.spend_by_channel.meta};
      if (payload.widgets.spend_by_channel.report){
        newReport.spend_by_channel.list = payload.widgets.spend_by_channel.report.list.map( item => {
          return {
            label: item.channel,
            amount: item.spend
          } as GraphItem;
        });
      }
    }

    if (payload.widgets.summary) {
      newReport.summary.meta = {...payload.widgets.summary.meta, ...newReport.summary.meta};
      newReport.summary.report = payload.widgets.summary.report;
    }

    ctx.setState({
      inProgress: false,
      report: newReport
    });
  }

  @Action(SetOverviewInProgress)
  SetOverviewInProgress(ctx: StateContext<OverviewStateModel>) {
    const getState = ctx.getState();
    ctx.setState({...getState, inProgress: true});
  }

  @Action(SetOverviewReportsToNull)
  SetOverviewReportsToNull(ctx: StateContext<OverviewStateModel>, { payload }: SetOverviewReportsToNull) {
    const getState = ctx.getState();
    if (!getState.report) {
      ctx.setState({...getState, inProgress: false, report: null});
      return;
    }

    let keys: string[];
    if (payload) {
      keys = Array.isArray(payload) ? payload : [payload];
    } else {
      keys = Object.keys(getState.report);
    }

    keys.forEach(key => {
      const newReport = getState.report;
      newReport[key].meta.needRefresh = true;
      newReport[key].report = null;
      ctx.setState({...getState, inProgress: false, report: newReport});
    });
  }

  @Action(ClearOverview)
  ClearOverview(ctx: StateContext<OverviewStateModel>) {
    if (typeof(this.overviewService.subscription) !== 'undefined' && this.overviewService.subscription !== null) {
      this.overviewService.subscription.unsubscribe();
      this.overviewService.subscription = null;
    }
    ctx.setState({
      inProgress: false,
      report: null
    });
  }
}
