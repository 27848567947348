import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { BaseService } from '../base/base.service';
import { Subscription } from 'rxjs';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { SetTimePeriods, SetTPInProgress } from 'src/app/store/actions/time-periods.actions';
import { DataDownloaderService } from '../data-downloader/data-downloader.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TimePeriodService {
  subscription: Subscription;

  constructor(private store: Store, private baseService: BaseService, private toastr: ToastrService, private dataDownloader: DataDownloaderService) {}

  getTimePeriod() {
    this.unsubscribeAndCancelReportGeneration();

    let ReportingParams = this.baseService.getReportingParams;
    const TimePeriodParams = this.baseService.getTimePeriodParams(false);
    const Params = {...ReportingParams, ...TimePeriodParams};
    Params.attribution_type = 'backward';
    const selectedState: SelectedStateModel = this.store.selectSnapshot( state => state.selected);
    const projectState = this.store.selectSnapshot( state => state.project);

    let aggregationPeriod = (projectState.currentViewParams?.aggregation_period || 'Day').toLowerCase();
    
    const availablePeriods = this.baseService.getAggregationPeriods(selectedState, projectState).aggrPeriods;
    if (!availablePeriods.includes(aggregationPeriod)) {
      aggregationPeriod = availablePeriods[0];
    }

    Params.aggregate_by_period = aggregationPeriod;

    this.store.dispatch(new SetTPInProgress());
    this.subscription = this.dataDownloader.requestReport({...Params, report: 'time_period'}).subscribe( (resp: any) => {
      if (resp.meta.status === 'ready') {
        this.store.dispatch(new SetTimePeriods({
          meta: resp.meta,
          raw: resp.report?.other?.raw,
          report: {
            f: resp.report?.filters,
            g: resp.report?.groups,
            p: resp.report?.other.periods,
            s: resp.report?.other.summary,
          }
        }));
        this.baseService.trackReportLoaded('Timeframe', Params);
      } else {
        setTimeout(() =>
          this.toastr.error(
            'Failed to fetch data from the server.',
            `status: ${status}`,
            {disableTimeOut: true}
          )
        );
      }
      this.subscription = null;
    });
  }

  unsubscribeAndCancelReportGeneration() {
    if (this.subscription) {
      this.dataDownloader.cancelReportGeneration(['time_period']);
      this.subscription.unsubscribe();
      this.subscription = null;
      this.store.dispatch(new SetTPInProgress({ isFalse: true}));
    }
  }

}
