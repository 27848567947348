import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash-es';

@Pipe({name: 'percentage'})
export class PercentagePipe implements PipeTransform {
  transform(numerator: number, denominator: number): string {
    const percentage = (numerator / denominator) * 100;
    if (isNaN(percentage) || percentage === Infinity) {
      return '--';
    } else if (percentage === 0) {
      return '0%';
    } else {
      return round(percentage, 2) + '%';
    }
  }
}
