import { Component, Input, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { createPopper } from '@popperjs/core';
import { CurrencyPipe } from '@angular/common';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() margintop = 10;
  @Input() project;
  @Input() view;
  @ViewChild('tooltip') tooltip: ElementRef;
  activatedFor: string;
  data;
  CP: CurrencyPipe = new CurrencyPipe('en-US');
  class: string;
  isFlipped = false;
  selectedAttributionModel;

  constructor(private renderer: Renderer2, private tooltipService: TooltipService, private store: Store) { }

  ngOnInit() {
    this.tooltipService.mouseOver.subscribe( resp => this.onMouseOver(resp.name, resp.data));
    this.tooltipService.mouseOut.subscribe( () => this.onMouseOut());
    this.selectedAttributionModel =  this.store.selectSnapshot(state => state.selected)?.attributionModel;
  }

  onMouseOver(name, data) {
    this.activatedFor = name;
    this.data = data.data;

    const mouse = {x: 0, y: 0};
    let ref;
    if (data.element) {
      ref = data.element;
      this.class = 'd3-tip-sidebar';
    } else {
      this.class = 'd3-tip';
      ref = {
        getBoundingClientRect: () => ({
          top: mouse.y,
          right: mouse.x,
          bottom: mouse.y,
          left: mouse.x,
          width: 0,
          height: 0,
        }),
        clientWidth: 0,
        clientHeight: 0,
      };
    }

    const margintop = this.margintop;

    const popper = createPopper(ref, this.tooltip.nativeElement, {
      onFirstUpdate: state => {
        document.onmousemove = ({clientX, clientY }) => {
          mouse.x = clientX;
          mouse.y = clientY - margintop;
          popper.update();
        };
      },
      placement: 'top',
      modifiers: [{
        name: 'preventOverflow',
        options: {
          rootBoundary: "viewport"
        }
      }]
    });

    this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'block');
  }

  onMouseOut() {
    this.activatedFor = null;
    this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'none');
  }

}
