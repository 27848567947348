import { State, Action, StateContext, Store } from '@ngxs/store';

import {
  SetKnownProperties,
  SetKnownPropertyValues,
  SetEventPropertyKey,
  SetEventPropertyValue,
  ClearProperties, SetKeysAreLoading, SetValuesAreLoading
} from '../actions/properties.action';
import { ClearReport } from '../actions/report.actions';
import { Injectable } from '@angular/core';
import { RefreshSidebar } from '../actions/sidebar.actions';
import { UpdateViewProperty, UpdateViewPropertyValue } from '../actions/project.actions';
import { ClearPathReport } from '../actions/paths.actions';
import { ClearReports } from '../actions/selected.actions';

export interface PropertiesStateModel {
  keysAreLoading: boolean;
  valuesAreLoading: boolean;
  knownKeys?: string[];
  knownValues: string[];
  event_property_key: string;
  event_property_value: string;
  keysRequested: boolean;
}

@State<PropertiesStateModel>({
  name: 'properties',
  defaults: {
    keysAreLoading: false,
    valuesAreLoading: false,
    knownKeys: null,
    knownValues: [],
    event_property_key: null,
    event_property_value: null,
    keysRequested: false
  }
})
@Injectable()
export class PropertiesState {
  constructor(private store: Store) {}

  @Action(SetKnownProperties)
  SetKnownProperties(ctx: StateContext<PropertiesStateModel>, { payload }: SetKnownProperties) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      knownKeys: payload,
      keysRequested: true,
      keysAreLoading: false
    });
  }

  @Action(SetKnownPropertyValues)
  SetKnownPropertyValues(ctx: StateContext<PropertiesStateModel>, { payload }: SetKnownPropertyValues) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      knownValues: payload,
      valuesAreLoading: false
    });
  }

  @Action(SetKeysAreLoading)
  SetKeysAreLoading(ctx: StateContext<PropertiesStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      keysAreLoading: true
    });
  }

  @Action(SetValuesAreLoading)
  SetValuesAreLoading(ctx: StateContext<PropertiesStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      valuesAreLoading: true
    });
  }

  @Action(SetEventPropertyKey)
  setEventPropertyKey(ctx: StateContext<PropertiesStateModel>, { payload }: SetEventPropertyKey) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      event_property_key: payload?.key,
      event_property_value: null
    });
    if (payload?.shouldUpdateView) {
      this.store.dispatch([
        new UpdateViewProperty(payload?.key), new UpdateViewPropertyValue(null)
      ])
    }
    if (payload === null) {
      this.store.dispatch([ new ClearReports(), new ClearPathReport(), new RefreshSidebar()])
    }
  }

  @Action(SetEventPropertyValue)
  setEventPropertyValue(ctx: StateContext<PropertiesStateModel>, { payload }: SetEventPropertyValue) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      event_property_value: payload?.value
    });
    let actions = [new ClearReport(), new ClearPathReport(), new RefreshSidebar()];
    if (payload?.shouldUpdateView) { 
      actions = [...actions, new UpdateViewPropertyValue(payload?.value)]
    }
    this.store.dispatch(actions);
  }

  @Action(ClearProperties)
  ClearProperties(ctx: StateContext<PropertiesStateModel>) {
    ctx.setState({
      keysAreLoading: false,
      valuesAreLoading: false,
      knownKeys: null,
      knownValues: [],
      event_property_key: null,
      event_property_value: null,
      keysRequested: false
    });
  }
}
