import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Injectable({
  providedIn: 'root'
})
export class FilterErrorService {

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) { }

  get(Params, type) {
    let Url = `${environment.clientApiUrl}/v2/filter_errors`;

    Params.type = type;

    if (Params.oauth_id == null || typeof(Params.oauth_id) == 'undefined') {
      const selectedState: SelectedStateModel = this.store.selectSnapshot(x => x.selected);
      Params.start = selectedState.chosenDates.start;
      Params.end = selectedState.chosenDates.end;
    }

    return this.httpClient.get(Url, {params: Params});
  }

  clearErrors(Params, type) {
    Params.type = type == 'oauth' ? 'integration' : type;

    if (type != 'oauth') {
      const selectedState: SelectedStateModel = this.store.selectSnapshot(x => x.selected);
      Params.start = selectedState.chosenDates.start;
      Params.end = selectedState.chosenDates.end;
    }

    let Url = `${environment.clientApiUrl}/v2/filter_errors`;
    return this.httpClient.delete(Url, {params: Params});
  }
}
