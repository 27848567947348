<div class="{{class}}" #tooltip [ngSwitch]="activatedFor" [class.flipped]="isFlipped">
  <div *ngIf="activatedFor && isFlipped && activatedFor != 'disabled'"></div>

  <div *ngSwitchCase="'disabled'" class="disabled-tooltip black">
    {{ data.name }} is disabled{{ data.reason ? ' because '+ data.reason +'.' : '.' }}
  </div>

  <div *ngSwitchCase="'roas_by_channel'" class="tootltipWrapper">
    <div class="tooltip-header">
      {{ data.label }}
    </div>
    <div class="tooltip-body">
      <div class="tooltip-flex-row">
        <div class="row-name">
          Attributed Revenue
        </div>
        <div class="row-value graph-green">
          {{ data.revenue | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-flex-row pt-0">
        <div class="row-name graph-red">
          Advertising Spend
        </div>
        <div class="row-value red">
          {{ data.spend | mathRound | projectCurrency: 'dollars'}}
        </div>
      </div>
      <div class="tooltip-divider"></div>
      <div class="tooltip-flex-row">
        <div class="row-name roi">
          Return on Investment
        </div>
        <div class="row-value" [ngClass]="data.roas === 0 ? '' : data.roas > 0 ? 'graph-dark-green' : 'graph-dark-red'">
        {{ data.roas | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'spend_by_channel'" class="tootltipWrapper">
    <div class="tooltip-header">
      {{ data.label }}
    </div>
    <div class="tooltip-body">
      <div class="tooltip-flex-row">
        <div class="row-name graph-red">
          Advertising Spend
        </div>
        <div class="row-value red">
          {{ -data.amount | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'channel_performance_chart'" class="tootltipWrapper">
    <div class="tooltip-header">
      {{ data.label }}
    </div>
    <div class="tooltip-body">
      <div class="tooltip-flex-row">
        <div class="row-name">
          Attributed Revenue
        </div>
        <div class="row-value graph-green">
          {{ data.revenue | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-flex-row pt-0">
        <div class="row-name graph-red">
          Advertising Spend
        </div>
        <div class="row-value red">
          {{ data.spend | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-divider"></div>
      <div class="tooltip-flex-row">
        <div class="row-name roi">
          Return on Investment
        </div>
        <div class="row-value" [ngClass]="data.roas === 0 ? '' : data.roas > 0 ? 'graph-dark-green' : 'graph-dark-red'">
        {{ data.roas | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'conversion-chart'" class="tootltipWrapper">
    <div class="tooltip-header">
      <span>{{ data.count }} CONVERSIONS</span>
    </div>
    <div class="tooltip-body">
      <span class="conversion">{{ view?.conversion_event}}</span><br/>
      <span>after {{ data.day }} days</span>
    </div>
  </div>

  <div *ngSwitchCase="'revenue-sidebar-chart'" class="tootltipWrapper">
    <div class="tooltip-header">
      <span>{{ data.count }} USERS</span>
    </div>
    <div class="tooltip-body">
      <span>after {{ data.day }} days</span>
    </div>
  </div>

  <div *ngSwitchCase="'visits-sidebar-chart'" class="tootltipWrapper">
    <div class="tooltip-header">
      <span>{{ data.day | date: 'mediumDate' }}</span>
    </div>
    <div class="tooltip-body">
      <p *ngIf="data.data.visits > 0">Visits: {{ data.data.visits }}</p>
      <p *ngIf="data.data.excess_visits > 0">Excess Visits: {{ data.data.excess_visits }}</p>
      <p *ngIf="data.data.clicks > 0">Clicks: {{ data.data.clicks }}</p>
      <p *ngIf="data.data.excess_clicks > 0">Excess Clicks: {{ data.data.excess_clicks }}</p>
    </div>
  </div>

  <div *ngSwitchCase="'clicks-sidebar-chart'" class="tootltipWrapper">
    <div class="tooltip-header">
      <span>{{ data.count }} CLICKS</span>
    </div>
    <div class="tooltip-body">
      <span>{{ data.day | date: 'mediumDate' }}</span>
    </div>
  </div>

  <div *ngSwitchCase="'revenue-chart'" class="tootltipWrapper">
    <div class="tooltip-header">
      <span>{{ data.count | projectCurrency }}</span>
    </div>
    <div class="tooltip-body">
      <span>{{ data.day | date: 'mediumDate' }}</span>
    </div>
  </div>

  <div *ngSwitchCase="'cp-totals'"  class="tootltipWrapper">
    <div class="tooltip-header">
      {{ data.name }}
    </div>
    <div class="tooltip-body">
      <div class="tooltip-flex-row">
        <div class="row-name">
          Attributed Revenue
        </div>
        <div class="row-value graph-green">
          {{ data.revenue | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-flex-row pt-0">
        <div class="row-name graph-red">
          Advertising Spend
        </div>
        <div class="row-value red">
          {{ -data.spend | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-divider"></div>
      <div class="tooltip-flex-row">
        <div class="row-name roi">
          Return on Investment
        </div>
        <div [ngClass]="{'row-value' : true, 'graph-dark-green': data.roas > 0, 'graph-dark-red': data.roas < 0 }">
          {{ data.roas | mathRound | projectCurrency: 'dollars' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'comparison'" class="tootltipWrapper comparison">
    <div class="tooltip-header">
      {{ data.name }}
    </div>
    <div class="tooltip-body">
      {{ data.value }}
    </div>
  </div>

  <div *ngSwitchCase="'label'" class="tootltipWrapper comparison">
    <div class="tooltip-header">
      {{ data.name }}
    </div>
  </div>

  <div *ngSwitchCase="'multi-line'" class="tootltipWrapper">
    <div class="tooltip-header">
      {{ data.period }}
    </div>
    <div class="tooltip-body">
      <div class="tooltip-flex-row">
        <div class="row-name">
          First Time Customers
        </div>
        <div class="row-value green">
          {{ data.values | getValueByParamFromArray:"first_time_customers":'name':'value' | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-flex-row pt-0">
        <div class="row-name">
          Repeated Customers
        </div>
        <div class="row-value blue">
          {{ data.values | getValueByParamFromArray:"repeated_customers":'name':'value' | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-flex-row pt-0">
        <div class="row-name">
          Marketing Spend
        </div>
        <div class="row-value red">
        {{ data.values | getValueByParamFromArray:"marketing_spend":'name':'value' | projectCurrency: 'dollars' }}
        </div>
      </div>
      <div class="tooltip-flex-row pt-0">
        <div class="row-name">
          First Time Conversions
        </div>
        <div class="row-value orange" [ngSwitch]="selectedAttributionModel?.name">
          <span  *ngSwitchCase="'first_touch'">
            {{ data.values | getValueByParamFromArray:"first_time_conversions":'name':'value' | integer }}
          </span>
          <span  *ngSwitchCase="'last_touch'">
            {{ data.values | getValueByParamFromArray:"first_time_conversions":'name':'value' | integer }}
          </span>
          <span *ngSwitchDefault>
            {{ data.values | getValueByParamFromArray:"first_time_conversions":'name':'value' | defaultNumber }}
          </span>
        </div>
      </div>
      <div class="tooltip-flex-row pt-0">
        <div class="row-name">
          Repeated Conversions
        </div>
        <div class="row-value yellow">
        {{ data.values | getValueByParamFromArray:"repeated_conversions":'name':'value' | defaultNumber }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="activatedFor && !isFlipped && activatedFor != 'disabled'" class="arrow arrow-down margin-auto"></div>
</div>
