import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

import { ChannelPerformanceState, ChannelPerformanceStateModel } from 'src/app/store/states/channel-performance.state';
import { SetCurrentComponent } from 'src/app/store/actions/selected.actions';
import { DataRefresherService } from 'src/app/core/services/data-refresher/data-refresher.service';
import { UrlCheckerService} from 'src/app/core/services/url-checker/url-checker.service';
import {
  SetChannelPerformancePeriod,
  ClearCP,
  SetChannelPerformanceChannelSummaryToNull,
  SetTrafficModel,
  SetChannelPerformanceInProgress
} from 'src/app/store/actions/channel-performance.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { ChannelPerformanceService } from 'src/app/core/services/channel-performance/channel-performance.service';
import { SlideInOutAnimation } from 'src/app/core/animations/slide-in-out'
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { capitalize } from 'lodash-es';
import { SetAggregationPeriod } from 'src/app/store/actions/project.actions';
import { ComponentNames } from 'src/app/core/enums/component-names';

@Component({
  selector: 'app-channel-performance',
  templateUrl: './channel-performance.component.html',
  styleUrls: ['./channel-performance.component.scss'],
  animations: [SlideInOutAnimation]
})
export class ChannelPerformanceComponent implements OnInit, OnDestroy {
  @Select(ChannelPerformanceState) channelPerformance$: Observable<ChannelPerformanceStateModel>;
  @Select(state => state.channelPerformance.report) cpReport$: Observable<any>;
  @Select(state => state.filterTree) filterTree$: Observable<any>;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  componentPath = ComponentNames.channelPerformance;
  isLoading = true;
  summaryIsLoading = true;
  openChannelIsLoading = true;
  report;
  summary;
  prevSummary;
  prevSummaryParams;
  channelSummary;
  allChannels;
  channels; // accessible channels
  allVirtualFilters;
  virtualFilters; // accessible virtual groups
  channelPerformanceSubscription: Subscription;
  cpReportSub: Subscription;
  period;
  openedChannel;
  chosenChannel: number;
  activeVirtualFilter: any = null;
  isActiveVirtualFilterChanged: false;
  selectedScale = 'Linear';
  totals: Array<any>;
  totalsComparison: any;
  isTotalsEmpty = true;
  project;
  filterTree;

  projectSub;
  treeSub;
  qParamsSub;
  paramsSub;

  constructor(
    private store: Store,
    private dataRefresher: DataRefresherService,
    private route: ActivatedRoute,
    private router: Router,
    private cpService: ChannelPerformanceService,
    private urlChecker: UrlCheckerService) {}

  ngOnInit() {
    this.store.dispatch(new SetCurrentComponent({ currentComponent: this.componentPath}));

    this.projectSub = this.project$.subscribe(x => this.project = x.project);

    // Load all channels
    this.treeSub = this.filterTree$.subscribe( filterTree => {
      if (filterTree.filterTree) {
        this.filterTree = filterTree.filterTree;
        this.allChannels = filterTree.filterTree.filter(
          filterOrGroup => filterOrGroup.t === 'g'
        ).reduce( (accumulator, currentValue) => {
          accumulator[currentValue.id] = currentValue.n;
          return accumulator;
        }, {});
        this.virtualFilters = filterTree.filterTree.filter( x => x.t == 'f' && x.s == 'virtual');
      } else {
        this.allChannels = [];
        this.virtualFilters = [];
      }
      this.channels = this.allChannels;
    });

    // Navigating within component
    this.qParamsSub = this.route.queryParams.subscribe( params => {
      // reset summary on channel change
      this.openedChannel = null;
      this.store.dispatch(new SetChannelPerformanceChannelSummaryToNull());

      const Period = params.period || this.period;
      if (Period !== this.period) {
        this.store.dispatch( new SetAggregationPeriod(Period));
        this.dataRefresher.shouldComponentDataRefresh(this.componentPath, {urlParamsDifferent: true});
        return ;
      }

      if (params.virtual_filter) {
        const vFilter = this.virtualFilters.find(x => x.id == parseInt(params.virtual_filter));
        if (typeof(vFilter) != 'undefined') {
          this.setActiveVirtualFilter(vFilter);
          this.store.dispatch(new SetTrafficModel(capitalize(vFilter.n)));
          const channels = this.filterTree.filter(x => {
            return x.vf?.includes(vFilter.id) && x.t === 'g'
          });
          this.channels = channels.reduce( (accumulator, currentValue) => {
            accumulator[currentValue.filter_group_id] = this.channels[currentValue.filter_group_id];
            return accumulator;
          }, {});
        }
      } else {
        // Navigating to `All Traffic`, reset channels and groups
        this.channels = this.allChannels;
        this.setActiveVirtualFilter(null);
      }

      if (params.refresh) {
        this.dataRefresher.shouldComponentDataRefresh(this.componentPath, {urlParamsDifferent: true});
        // Remove `refresh` from query params
        this.router.navigate([], {
          queryParams: {
            refresh: null
          },
          queryParamsHandling: 'merge'
        });
        return ;
      }
    });

    this.cpReportSub = this.cpReport$.subscribe( report => {
      const inProgress = this.store.selectSnapshot( state => state.channelPerformance.inProgress);
      if (report == null && inProgress == false) {
        const additionalParams = this.activeVirtualFilter ? { virtual_filter: this.activeVirtualFilter.id } : null;
        this.store.dispatch(new SetChannelPerformanceInProgress());
        this.dataRefresher.shouldComponentDataRefresh(this.componentPath, additionalParams);
      }
    });

    this.channelPerformanceSubscription = this.channelPerformance$.subscribe( resp => {
      this.summaryIsLoading = resp.summaryIsLoading;
      this.openChannelIsLoading = resp.openChannelSummaryIsLoading;
      this.isLoading = resp.inProgress;
      this.report = resp.report;
      this.summary = resp.summary;
      this.prevSummary = resp.prevSummary;
      this.prevSummaryParams = resp.prevSummaryParams;
      this.channelSummary = resp.openChannelSummary;
      this.period = this.store.selectSnapshot<ProjectStateModel>( state => state.project).currentViewParams?.aggregation_period || 'Day';

      if (resp.totals && this.channels) {
        // filter channels that belongs to a virtual group / All traffic
        this.totals = resp.totals.filter(x => [0, 'other'].indexOf(x.id) === -1 && this.channels[x.id]);
        this.totals.forEach(x => {
          if (x.roas != 0 || x.revenue != 0 || x.spend != 0) {
            this.isTotalsEmpty = false;
          }
        });

        const queryParams = this.route.snapshot.queryParams;
        // load channel summary
        if (queryParams.channel) {
          const channelId = parseInt(queryParams.channel);
          const channelIdsArray = this.totals.map( x => x.id);
          if (
            channelIdsArray.includes(channelId)
            && (this.openedChannel !== channelId
            || !(this.channelSummary || this.openChannelIsLoading))
            ) {
            this.openedChannel = channelId;
            this.cpService.getSummary({ filter_group_id: channelId });
          }
        }
      }
    });

    this.paramsSub = this.route.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, this.componentPath);
    });
  }

  ngOnDestroy() {
    this.channelPerformanceSubscription?.unsubscribe();
    this.cpReportSub?.unsubscribe();
    this.projectSub?.unsubscribe();
    this.treeSub?.unsubscribe();
    this.qParamsSub?.unsubscribe();
    this.paramsSub?.unsubscribe();
    this.cpService.unsubscribeAndCancelReportGeneration();
  }

  channel_name(channelId) {
    return this.channels[channelId];
  }

  toCurrency(val) {
    const CP: CurrencyPipe = new CurrencyPipe('en-US');
    return CP.transform(val, '$', 'code', '1.0');
  }

  aggregationPeriodChanged(selectedPeriod) {
    this.store.dispatch(new SetChannelPerformancePeriod({ period: selectedPeriod}));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, period: selectedPeriod}
    });
  }

  selectScale(selectedScale) {
    this.selectedScale = selectedScale;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, scale: selectedScale}
    });
  }

  openChannel(id, isRedirect?) {
    if (!isRedirect) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {...this.route.snapshot.queryParams, channel: id}
      });
    }
  }

  singleReport(id) {
    let reportIndex = null;
    let chosenReport = this.report.find( x => x.id === id);
    if ( id !== 0) {
      if (typeof(chosenReport) != 'undefined') {
        reportIndex = this.report.indexOf(chosenReport);
      } else {
        reportIndex = null;
        chosenReport = {list: []};
      }
    }
    return { index: reportIndex, report: chosenReport };
  }

  isReportEmpty(list: Array<any>) {
    let isEmpty = true;
    list.forEach( x => {
      if (x.roas != 0 || x.revenue != 0 || x.spend != 0) {
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  openVirtualFilter(vFilter) {
    if (this.activeVirtualFilter === vFilter) { return; }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, virtual_filter: vFilter.id}
    });
  }

  goBackFromVirtualFilter() {
    if (!this.activeVirtualFilter) { return; }
    const {virtual_filter, ...withoutVirtualFilter} = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: withoutVirtualFilter
    });
  }

  goBack() {
    const {channel, ...withoutChannel} = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: withoutChannel
    });
  }

  setActiveVirtualFilter(value) {
    // reset both report and summary on virtual group / All traffic change
    if (this.activeVirtualFilter !== value) {
      this.activeVirtualFilter = value;
      this.store.dispatch(new ClearCP());
    }
  }
}
