import { State, Action, StateContext, Store } from '@ngxs/store';
import { SetReport, SetReportInProgress, ClearReport, SetReportNeedsToLoad, SetInitialLoading, PatchReport } from '../actions/report.actions';
import { Injectable } from '@angular/core';
import { ReportService } from 'src/app/core/services/report/report.service';
import { Project } from 'src/app/core/models/project.model';

export interface ReportStateModel {
  inProgress: boolean;
  initialLoading: boolean;
  meta: any;
  report: any;
  needsToLoad: boolean;
  paramsWereChanged: boolean;
}

@State<ReportStateModel>({
name: 'report',
defaults: {
    inProgress: false,
    initialLoading: true,
    meta: null,
    report: null,
    needsToLoad: false,
    paramsWereChanged: true
  }
})
@Injectable()
export class ReportState {

  constructor(private reportService: ReportService, private store: Store) {}

  @Action(SetReport)
  SetReport(ctx: StateContext<ReportStateModel>, { payload }: SetReport) {
    const metaWithoutVisits = payload.meta;
    delete metaWithoutVisits.visits;
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      inProgress: false,
      initialLoading: false,
      meta: metaWithoutVisits,
      report: payload.report,
      needsToLoad: false
    });
  }

  @Action(PatchReport)
  PatchReport(ctx: StateContext<ReportStateModel>, { payload }: PatchReport) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: {
        ...getState.report,
        filters: {
          ...getState.report.filters,
          ...payload.report.filters
        },
        groups: {
          ...getState.report.groups,
          ...payload.report.groups
        },
        other: {
          ...getState.report.other,
        }
      }
    });
  }

  @Action(SetReportInProgress)
  SetReportInProgress(ctx: StateContext<ReportStateModel>, {payload}: SetReportInProgress) {
    const getState = ctx.getState();
    ctx.setState({...getState, inProgress: payload?.isFalse ? false : true, paramsWereChanged: false});
  }

  @Action(SetReportNeedsToLoad)
  SetReportNeedsToLoad(ctx: StateContext<ReportStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      needsToLoad: true,
      meta: null,
      report: null
    });
  }

  @Action(SetInitialLoading)
  SetInitialLoading(ctx: StateContext<ReportStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      initialLoading: true
    });
  }

  @Action(ClearReport)
  ClearReport(ctx: StateContext<ReportStateModel>) {
    const getState = ctx.getState();
    let inProgress = getState.inProgress;
    if (typeof(this.reportService.subscription) !== 'undefined' && this.reportService.subscription !== null) {
      const project: Project = this.store.selectSnapshot(state => state.project.project);
      if (!project?.options?.features?.load_report_button) {
        this.reportService.subscription.unsubscribe();
        this.reportService.subscription = null;
        inProgress = false;
      }
    }
    ctx.setState({
      ...getState,
      meta: null,
      inProgress: inProgress,
      report: null,
      paramsWereChanged: true
    });
  }
}
