import { Component, OnChanges, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { toNumber } from 'lodash-es';
import { Select } from '@ngxs/store';

import { SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { ConversionService } from 'src/app/core/services/conversion/conversion.service';
import { Project } from 'src/app/core/models/project.model';
import { LocationService } from 'src/app/core/services/location/location.service';
import { SidebarStateService } from 'src/app/core/services/sidebar-state/sidebar-state.service';
import { BaseService } from 'src/app/core/services/base/base.service';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { Observable, Subscription } from 'rxjs';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';

@Component({
  selector: 'app-overlaps-sidebar',
  templateUrl: './overlaps-sidebar.component.html',
  styleUrls: ['./overlaps-sidebar.component.scss']
})
export class OverlapsSidebarComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() sidebar: SidebarStateModel;
  @Input() project: Project;
  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;

  overlaps = [];
  filter;
  inProgress;
  isEmpty;
  selected = {
    filterType: null,
    filterOrGroupId: null
  };
  isVirtual = false;
  IPLocations = {};
  filterTree;
  treeSub: Subscription;

  constructor(
    private conversionService: ConversionService,
    private locationService: LocationService,
    private sidebarState: SidebarStateService,
    private baseService: BaseService,
    private FTWS: FilterTreeWorkerService
  ) { }

  ngAfterViewInit() {
    document.getElementById('sidebar-content').scrollTop = this.sidebarState.scrollPosition;
  }

  ngOnChanges() {
    if (this.filterTree && this.project) {
      this.initFlow();
    } else {
      this.treeSub?.unsubscribe();
      this.treeSub = this.filterTree$.subscribe(filterTree => {
        if (!this.filterTree && filterTree.filterTree && this.project) {
          this.filterTree = filterTree.filterTree;
          this.initFlow();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.treeSub?.unsubscribe();
  }

  initFlow() {
    const oldState = this.sidebarState.sidebarState;
    const oldStateKeyArray = Object.keys(oldState);
    if (this.sidebarState.wentBack == true && oldStateKeyArray.length > 0) {
      for (let key of oldStateKeyArray) {
        this[key] = oldState[key];
      }
      this.sidebarState.setWentBack(false);
    } else {
      this.inProgress = true;
      this.overlaps = [];
      this.isVirtual = false;
      if (this.sidebar.options.filter || this.sidebar.options.virtual_filter) {
        this.selected.filterType = 'f';
        this.selected.filterOrGroupId = toNumber(this.sidebar.options.filter || this.sidebar.options.virtual_filter);
        if (this.sidebar.options.virtual_filter) {
          this.isVirtual = true;
        }
      } else {
        this.selected.filterType = 'g';
        this.selected.filterOrGroupId = toNumber(this.sidebar.options.group);
      }
      this.filter = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: this.selected.filterOrGroupId, additionalKey: 't', additionalValue: this.selected.filterType});

      this.conversionService.getOverlaps(this.selected.filterOrGroupId, this.isVirtual ? 'v' : this.selected.filterType).subscribe(
        (resp: Array<any>) => {
          this.overlaps = resp.filter( x => x.overlap.length > 0 && x.filter !== this.selected.filterOrGroupId).map( (x,i) => ({...x, id: i}));
          this.inProgress = false;
          this.isEmpty = this.overlaps.length === 0;
          this.locationService.requestBulk(resp, 50).subscribe( (resp: any) => {
            this.IPLocations = {
              ...this.IPLocations,
              ...resp
            };
            this.sidebarState.setState({
              openedSidebar: this.sidebar.openedSidebar,
              overlaps: this.overlaps,
              isEmpty: this.isEmpty,
              inProgress: this.inProgress,
              filter: this.filter,
              selected: this.selected,
              IPLocations: this.IPLocations
            });
          });
        }
      );
    }
  }

  showVisitor(visitorId) {
    this.baseService.showVisitor(visitorId);
  }

}
