import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RegulationService {
  apiUrl = environment.clientApiUrl;

  constructor(private httpClient: HttpClient) { }

  createGDPRdeletion(userIdArray, apiToken) {
    const Url = `${this.apiUrl}/regulations`;

    const params = {
      attributes: {
        values: userIdArray,
        name: "userId"
        },
      regulation_type: "Delete",
      token: apiToken
    };

    return this.httpClient.post(Url, params);
  }

}
