<div class="attr-card mt-4">
  <div class="disregard-existing-users-wrapper" [class.project-option-disabled]="disabled">
    <div *ngIf="disabled" class="tooltip-overlap" (mouseover)="showDisabledTooltip()" (mouseout)="hideDisabledTooltip()"></div>
    <div class="row mb-4">
      <div class="col-8">
        <p class="title-no-bold" *ngIf="showTitle">Disregard Existing Users</p>
      </div>
      <div class="col-4 d-flex align-items-center justify-content-end spacer">
        <app-toggle-switch
          *ngIf="view?.disregard_existing_users || dataStatus?.created_at"
          [isOn]="view?.disregard_existing_users != null"
          [disabled]="true"></app-toggle-switch>
      </div>
    </div>

    <p class="medium-dark">Select this option if you want to disregard existing users in your attribution models. This is a good option for subscription businesses that only want to see the the impact of their channels on new users.  Note that you need to have the <a href="https://docs.attributionapp.com/docs/identify#special-properties-for-identify" target="_blank">"createdAt"</a> date trait set for this setting to work properly (users identified without <i>"createdAt"</i> would be automatically discarded).</p>

    <ng-template #explanation>
      <p class="green"><i>This option becomes available once you start sending <em>createdAt</em> trait when identifying your users.</i></p>
    </ng-template>
    <div *ngIf="view?.disregard_existing_users || dataStatus?.created_at; else explanation">
      <app-ngb-single-datepicker
        [initialValue]="view?.disregard_existing_users"
        [buttonText]="'Clear'"
        (eventEmitter)="setDisregardExistingUsers($event)"
        (buttonClick)="clearDEU($event)"></app-ngb-single-datepicker>
    </div>
  </div>
</div>
