import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'canExpand',
})
export class CanExpandPipe implements PipeTransform {
  transform(item:any, parent?:any, isExceptionProject?:boolean): any {
    if (parent) {
      return (
        (
          item.t == 'g'
          || (item.s === 'virtual' && item.vc && item.vc.length > 0)
          )
        && !((['googleads', 'googleads2'].indexOf(parent.integration) > -1)
        && parent.it == 'Shopping Campaign'
        && item.it == 'Ad Group'
        && item.e != true 
        && !isExceptionProject)
      )
    } else {
      return (item.c && item.c.length > 0) || (item.vc && item.vc.length > 0);
    }
  }
}