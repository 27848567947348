export enum SettingsSections {
    overview = 'overview',
    billing = 'billing',
    notifications = 'notifications',
    password = 'password',
    paymentHistory = 'payment-history',
    invoice = 'invoice',
    access = 'access',
    instructions = 'instructions',
    liveDebug = 'live-debug',
    projectSettings = 'project-settings',
    weeklyEmails = 'weekly-emails',
    integrations = 'integrations',
    gdprRules = 'grpr-rules',
    eventsUploader = 'events-uploader',
    influencesUploader = 'influences-uploader',
    shopify = 'shopify',
    segment = 'segment',
};