import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdDestinationUrlsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  get(Params, extended = false) {
    const Url = extended ? `${environment.clientApiUrl}/v2/ad_destination_urls_extended` : `${environment.clientApiUrl}/v2/ad_destination_urls`;

    return this.httpClient.get(Url, {params: Params});
  }
}
