<div class="attribution-type-wrapper">
  <div class="dropdown" ngbDropdown>
    <button #revGroupDropdown class="btn btn-default dropdown-toggle btn-select" type="button" id="dropdownMenuButton" [attr.data-boundary]="revGroupDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
      <i class="fa-solid fa-dollar-sign fa--header"></i>
      {{ selectedRevenueGroup != null ? selectedRevenueGroup.name : 'All Revenue' }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
      <span class="dropdown-item" (click)="selectRevenueGroup(null)">
        <span class="font-italic blue">All Revenue</span>
      </span>
      <span class="dropdown-item" (click)="selectRevenueGroup({name: 'Conversion Event', type: 'conversion-event', id: 0})">
        <span class="font-italic blue">Conversion Event</span>
      </span>
      <span *ngFor="let group of revenueGroups" class="dropdown-item" (click)="selectRevenueGroup(group)">
        {{ group.name }}
      </span>
    </div>
  </div>
</div>