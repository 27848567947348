import { State, Action, StateContext } from '@ngxs/store';

import { Account } from '../../core/models/account.model';
import { SetAccount, ClearAccountState, SetRegistrationStep, SetLastRegistrationStep } from '../actions/account.actions';
import { Injectable } from '@angular/core';

@State<Account>({
  name: 'account',
  defaults: {
    id: null,
    email: null,
    name: null,
    partner: null,
    projects: [],
    signup_integration: null,
    subscription: null,
    shopify_store: null,
    registration_step: null,
    last_registration_step: null
  }
})
@Injectable()
export class AccountState {
    @Action(SetAccount)
    SetAccount(ctx: StateContext<Account>, { payload }: SetAccount) {
      ctx.setState({...payload});
    }

    @Action(SetRegistrationStep)
    SetRegistrationStep(ctx: StateContext<Account>, { payload }: SetRegistrationStep) {
      const getState = ctx.getState();
      ctx.setState({
        ...getState,
        registration_step: payload
      })
    }

    @Action(SetLastRegistrationStep)
    SetLastRegistrationStep(ctx: StateContext<Account>, { payload }: SetLastRegistrationStep) {
      const getState = ctx.getState();
      ctx.setState({
        ...getState,
        last_registration_step: payload
      })
    }

    @Action(ClearAccountState)
    ClearAccountState(ctx: StateContext<Account>) {
      ctx.setState({
        id: null,
        email: null,
        name: null,
        partner: null,
        projects: [],
        signup_integration: null,
        subscription: null,
        shopify_store: null,
        registration_step: null,
        last_registration_step: null
      });
    }
}