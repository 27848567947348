import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardCanNavigateToAdService',
})
export class DashboardCanNavigateToAdServicePipe implements PipeTransform {
  transform(oauthtype): any {
    return ['facebook4', 'msads', 'linkedin', 'linkedin2', 'linkedin4', 'googleads', 'googleads2'].includes(oauthtype);
  }
}
