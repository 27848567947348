import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'ftpReportForItem',
})
export class FTPReportForItemPipe implements PipeTransform {
  transform(item: any, report: any, periodId: string, tableRevenue: string, forceAbsolute?: boolean, isTimeframe?: boolean): any {
    const metric = tableRevenue == 'Absolute' || forceAbsolute ? 'abs' : 'rel';
    let result;
    if (isTimeframe) {
      result = report ? report[periodId]?.[metric] : {a: 0, v: 0, fv: 0, c: 0, r: 0, ro: 0, fr: 0, fc: 0};
    }
    else {
      result = report && item ? (item.t == 'g' ? report.g[item.id]?.[periodId]?.[metric] : report.f[item.id]?.[periodId]?.[metric]) : {a: 0, v: 0, fv: 0, c: 0, r: 0, ro: 0, fr: 0, fc: 0};
    }

    return result || {a: 0, v: 0, fv: 0, c: 0, r: 0, ro: 0, fr: 0, fc: 0};
  }
}