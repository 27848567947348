<div class="modal-header">
  <div class="modal-title">
    <h3 class="mb-0 mt-0">
      Project Settings
    </h3>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="modalAlertText" class="alert modal_alert alert-warning">
  <i class="fa-solid fa-triangle-exclamation"></i>
  {{ modalAlertText }}
</div>
<div class="modal-body modal-body--source modal-settings-bg">
  <div class="container">
    <div id="attribution-type">
      <app-lookback-option [isInModal]="true"></app-lookback-option>
    </div>

    <div id="conversion-and-revenue">
      <app-conversion-and-revenue
      [isInModal]="true"></app-conversion-and-revenue>
    </div>

    <div id="exclude-filters">
      <app-exclude-filters
      [isInModal]="true"></app-exclude-filters>
    </div>

    <div id="disregard-existing-users">
      <app-disregard-existing-users
        [isInModal]="true"
        [disabled]="project?.company_based_attribution"
        [reason]="'company based attribution'"></app-disregard-existing-users>
    </div>

    <div id="only-users-in-daterange" *ngIf="project?.options?.features?.include_only_users_registered_in_daterange || !isProd">
      <app-only-users-in-daterange [isInModal]="true" [disabled]="project?.company_based_attribution"></app-only-users-in-daterange>
    </div>

    <div id="company-based-attribution" *ngIf="project?.options?.features?.company_based_attribution || !isProd">
      <app-company-based-attribution [isInModal]="true" [disabled]="view?.disregard_existing_users != null || project?.include_only_users_registered_in_daterange"></app-company-based-attribution>
    </div>

    <div id="first-purchase-only" *ngIf="project?.options?.features?.first_purchase_only || !isProd">
      <app-first-purchase-only [isInModal]="true"></app-first-purchase-only>
    </div>

    <div id="position-based-attribution-ratios">
      <app-position-based-attribution-ratios [isInModal]="true"></app-position-based-attribution-ratios>
    </div>

    <div id="revenue-groups" *ngIf="project?.options?.features?.revenue_groups || !isProd">
      <app-revenue-groups></app-revenue-groups>
    </div>

    <div id="currency-selector">
      <app-currency-selector [isInModal]="true"></app-currency-selector>
    </div>

    <div id="timezone-selector">
      <app-timezone-selector [isInModal]="true"></app-timezone-selector>
    </div>
  </div>
</div>
