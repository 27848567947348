<div *ngIf="isManageChannels; else isGroupSelector" class="cb-table mt-3">
  <div class="cb-table__header d-flex align-items-center justify-content-center">
    Channels & Filters
  </div>
  <div
    dndDropzone
    (dndDrop)="onDrop($event, filterTree)">
    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: filterTree, isOnlyGroups: false, initial: true, paddingLeft: 15 }"></ng-container>
  </div>
</div>

<ng-template
  #recursiveList
  let-list
  let-paddingLeft="paddingLeft"
  let-isInitial="initial"
  let-isOnlyGroups="isOnlyGroups">

  <div *ngIf="!isOnlyGroups" dndPlaceholderRef class="dndPlaceholder dash__row dash__row--placeholder"></div>
  @for (item of (list | groupSelectorWithoutSpecials); track item) {
    <div
    class="group-selection__row"
    [ngClass]="{'top-row': isInitial, 'not-top': !isInitial}"
    [dndDraggable]="item"
    [dndDisableIf]="item | dashboardDisableDND"
    dndEffectAllowed="move"
    (dndStart)="onDragStart(item)"
    (dndCopied)="onDragged(item, list, 'copy')"
    (dndLinked)="onDragged(item, list, 'link')"
    (dndMoved)="onDragged(item, list, 'move')"
    (dndCanceled)="onDragged(item, list, 'none')"
    (dndEnd)="onDragEnd($event)">
      <div [attr.id]="'filter-' + item.t + '-' + item.id" class="row-inside" [ngClass]="{'parent': (isOnlyGroups ? onlyGroups(item.c)?.length > 0 : item.c?.length) && item.e, 'active': selectedGroup?.id == item.id }" [ngStyle]="{'padding-left.px': paddingLeft}">
        <div class="d-flex align-items-center flex-grow-1 clickable pt-3 pb-3 clickable" (click)="isOnlyGroups ? toggleGroup(item) : toggleSelect(item, $event)">
          <div class="d-flex align-items-center" *ngIf="!isOnlyGroups; else singleSelect">
            <div class="me-2">
              <i *ngIf="!(item | dashboardDisableDND)" dndHandle class="fa-solid fa-grip-vertical"></i>
            </div>
            <div class="me-3">
              <i *ngIf="isDisabled(item); else notDisabled" class="fa-solid fa-fw fa-check medium me-2"></i>
              <ng-template #notDisabled>
                <ng-template #alreadyAdded>
                  <i class="fa-solid fa-fw fa-check green me-2"></i>
                </ng-template>
                <i *ngIf="!isAddedAlready(item); else alreadyAdded" [ngClass]="{'fa-solid fa-square-check green': isAddedAlready(item), 'fa-regular fa-square medium': !isAddedAlready(item)}" class="fa-lg fa-fw"></i>
              </ng-template>
            </div>
          </div>
          <ng-template #singleSelect>
            <div>
              <i *ngIf="selectedGroup?.id == item.id; else notSelected" class="fa-regular fa-circle-dot fa-lg me-3 green"></i>
              <ng-template #notSelected>
                <i class="fa-regular fa-circle fa-lg me-3"></i>
              </ng-template>
            </div>
          </ng-template>
          <div>
            <ng-template #justName>{{ item.n }}</ng-template>
            <div *ngIf="editNameId == item.id && item.t == 'g'; else justName" class='form-inline' #nameEditForm>
              <div class="d-flex align-items-center">
                <input class="form-control me-2" type="text" [ngModel]="nameInput" #channelNameInput>
                <i class="fa-solid fa-check-circle icon-clickable avoid-row-click" (click)="renameChannel(channelNameInput, item.id)"></i>
              </div>
            </div>
            <div class="sub-name">
              <app-filter-icon
                [item]="item"
                [isRawFilter]="true"></app-filter-icon>
              <a *ngIf="!item.i && item.t == 'g'" class="fa-solid fa-pencil tool-button ms-2 avoid-row-click" (click)="toggleNameInput(item.id, item.n)" ngbTooltip="Change name"></a>
              <a class="tool-button ms-2" *ngIf="(!item.i || ['bing', 'msads', 'linkedin', 'linkedin2', 'quora'].includes(item.i)) && item.t == 'f'" ngbTooltip="Edit filter">
                <span *ngIf="editFilterWaiting !== item.id; else waitinEditModal">
                  <i class="icon-clickable fa-solid fa-gear avoid-row-click" (click)="navigateToEditModal(item)"></i>
                </span>
                <ng-template #waitinEditModal>
                  <i class="no-cursor fa-solid fa-spinner fa-spin"></i>
                </ng-template>
              </a>
            </div>
          </div>
        </div>
        <div class="pt-3 pb-3" *ngIf="isOnlyGroups ? onlyGroups(item.c)?.length > 0 : item.t == 'g'">
          <i *ngIf="item.e; else chevronDown" class="fa-solid fa-chevron-up fa-lg clickable darken-on-hover" (click)="item.e = !item.e"></i>
          <ng-template #chevronDown>
            <i class="fa-solid fa-chevron-down fa-lg clickable darken-on-hover" (click)="item.e = !item.e"></i>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="(isOnlyGroups ? onlyGroups(item.c).length > 0 : item.t == 'g') && item.e"
        class="zone-inside"
        dndDropzone
        (dndDrop)="onDrop($event, item.c)">

        <div *ngIf="item.t == 'g' && (!item.c || item.c.length == 0)" class="dash__row dash__row--child no-traffic dash__row--stripped">
          <div class="full-width text-center">
            No Traffic Sources
          </div>
        </div>

        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: isOnlyGroups ? onlyGroups(item.c) : item.c, isOnlyGroups: isOnlyGroups, paddingLeft: paddingLeft + 35 }"></ng-container>
      </div>
    </div>
  }
</ng-template>

<ng-template #isGroupSelector>
  <div class="group-selection__row top-row">
    <div class="row-inside clickable" [class.active]="selectedGroup == 'on-top'" (click)="toggleGroup('on-top')">
      <div class="pt-3 pb-3">
        <i *ngIf="selectedGroup == 'on-top'; else topNotSelected" class="fa-regular fa-circle-dot fa-lg me-3 green"></i>
        <ng-template #topNotSelected>
          <i class="fa-regular fa-circle fa-lg me-3"></i>
        </ng-template>
        Without a Channel
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: onlyGroups(filterTree), isOnlyGroups: true, initial: true, paddingLeft: 15 }"></ng-container>
</ng-template>
