<div class="modal-header">
  <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
  <h4 class="modal-title">
    Friendbuy Connection Setup
  </h4>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div *ngIf="error" class="alert alert-danger alert-dismissible" role="alert">
          {{ error }}
        </div>
        <form [formGroup]="connectForm" autocomplete="false">
          <div class="form-group">
            <label for="friendbuyUsername">Friendbuy Access Key</label>
            <input type="text" formControlName="accessKey" class='form-control'>
          </div>

          <div class="form-group">
            <label for="friendbuySecretKey">Friendbuy Secret Key</label>
            <input type="password" formControlName="secretKey" class="form-control" >
          </div>

          <div class="text-center">
            <button class="btn btn-success" (click)="connectLogin()">
              <span *ngIf="!waitingForResponse; else waiting" class='caps'>
                Connect
              </span>
              <ng-template #waiting>
                <i class="fa-solid fa-spinner fa-spin"></i>
              </ng-template>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>