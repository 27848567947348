import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project, View } from 'src/app/core/models/project.model';
import { SetAttributionType, SetLookbackWindow } from 'src/app/store/actions/project.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';

@Component({
  selector: 'app-attribution-type-selector',
  templateUrl: './attribution-type-selector.component.html',
  styleUrls: ['./attribution-type-selector.component.scss']
})
export class AttributionTypeSelectorComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  project: Project;
  view: View;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.project$.subscribe(x => {
      this.project = x.project;
      this.view = x.currentViewParams;
    });
  }

  selectAttributionType(type: string) {
    if (this.view?.attribution_type != type) {
      this.store.dispatch(new SetAttributionType(type)).subscribe(() => {
        this.store.dispatch(new ClearReports());
      });
    }
    if (type == 'backward' && this.view?.lookback_window == null) {
      this.selectPeriod(30);
    }
  }

  selectPeriod(period) {
    this.store.dispatch(new SetLookbackWindow(period)).subscribe(() => {
      this.store.dispatch(new ClearReports());
    });
  }

}
