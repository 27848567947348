import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Project } from 'src/app/core/models/project.model';
import { Observable } from 'rxjs';
import { ProjectStateModel } from '../../../../store/states/project.state';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

@Component({
  selector: 'app-shopify-dialog',
  templateUrl: './shopify-dialog.component.html',
  styleUrls: ['./shopify-dialog.component.scss']
})
export class ShopifyDialogComponent implements OnInit{
  @Input() type: string;
  @Output() onClose = new EventEmitter<any>();

  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  currentIdentifier = '';

  componentNames = ComponentNames;
  settingsSections = SettingsSections;

  constructor(private router: Router, private store: Store) { }

  ngOnInit() {
    this.project$.subscribe(x => {
      this.currentIdentifier = x.project?.identifier;
    });
  }

  goToSettings() {
    const project: Project = this.store.selectSnapshot(state => state.project.project);
    this.router.navigate([project.identifier, ComponentNames.settings, SettingsSections.instructions])
  }

  close() {
    this.onClose.emit('Closed');
  }
}
