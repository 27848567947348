import { Component, AfterViewInit, OnChanges, Input } from '@angular/core';
import * as d3 from 'd3';

import { DateTime } from 'luxon';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements AfterViewInit, OnChanges {
  @Input() chartName;
  @Input() chartHeight;
  @Input() data: any[];
  @Input() color;
  @Input() maxLabels = 10;
  rendered = false;

  constructor() { }

  ngOnChanges() {
    if (!this.rendered) { return; }
    return this.drawChart();
  }

  ngAfterViewInit() {
    this.rendered = true;
    return this.drawChart();
  }

  drawChart() {
    // remove prone graph if any
    d3.select(`#${this.chartName}`).select('svg').remove();

    d3.select(`#${this.chartName}`).style("height", this.chartHeight + 'px');

    if (!document.getElementById(this.chartName)) {
      return;
    }

    const wrapperProperties = document.getElementById(this.chartName).getBoundingClientRect();

    const margin = {top: 20, right: 0, bottom: 35, left: 80, fromLeftAxis: 10};
    const height = this.chartHeight - margin.bottom - margin.top;
    const width = wrapperProperties.width - margin.left - margin.right - margin.fromLeftAxis;

    const lineColor = this.color === 'green' ? '#60bf8d' : this.color === 'red' ? '#bd6d65' : 'grey';

    const barWidth = width / this.data.length / 2;
    const barGap = barWidth * 2;

    // reduce axis labels, include last label
    // const ratio = Math.round(this.data.length / (this.maxLabels - 1));

    const labels = this.data.length > 31 ?
      this.data.filter( (item, index) => {
        return DateTime.fromISO(item.label).day == 1;
      }).map( item => item.label) :
      this.data.filter( (item, index) => {
        return DateTime.fromISO(item.label).weekday == 1;
      }).map( item => item.label);

    const svg = d3.select(`#${this.chartName}`).append('svg')
      .attr('width', wrapperProperties.width)
      .attr('height', this.chartHeight);

    const x: any = d3.scaleOrdinal()
      .domain(this.data.map( item => item.label))
      .range(this.data.map( (_, i) => barGap * i));

    const max = d3.max(this.data, d => d.amount);

    const y = d3.scaleLinear()
      .domain([0, max])
      .rangeRound([height, margin.top]);

    const formatCurrency = d3.format('-$,.0f');

    const line: any = d3.line()
      .x((d: any) => x(d.label))
      .y((d: any) => y(d.amount));

    const xAxis = svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${margin.left + margin.fromLeftAxis - 20}, ${height + margin.top - 4})`)
      .call(
        d3.axisBottom(x)
          .tickSize(0)
          .tickValues(labels)
      )
      .call(gr => gr.select('.domain').remove())
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("transform", "rotate(-45)");

    svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .attr('class', 'y axis')
      .call(
        d3.axisLeft(y)
          .tickSize(-width)
          .tickPadding(10)
          .tickFormat(d => formatCurrency(d))
          .ticks(5, 's')
      )
      .call( gr => gr.select('.domain').remove())
      .selectAll('line')
        .attr('stroke', '#eeeeee');

    const g = svg.append('g')
    .attr('transform', `translate(${margin.left + margin.fromLeftAxis - 10}, 0)`);
      //.attr('transform', `translate(${margin.left + margin.fromLeftAxis - 10}, ${margin.top})`);

    g.append('path')
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('stroke', lineColor)
      .attr('stroke-width', '3px')
      .attr('d', line(this.data));

  }
}
