import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { Store, Select } from '@ngxs/store';

import { ReportState, ReportStateModel } from 'src/app/store/states/report.state';
import {  Observable, Subscription } from 'rxjs';
import { DataRefresherService } from 'src/app/core/services/data-refresher/data-refresher.service';
import { SetCurrentComponent, SetDatesChanged } from 'src/app/store/actions/selected.actions';
import { OpenSidebar } from 'src/app/store/actions/sidebar.actions';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { ToggleExpand, DeleteFilter, DeleteChannel, ShowChannelZeros, ReArrangeFilters, EditFilter, AddOrRemoveVirtualFilter, AddFilterChannel } from 'src/app/store/actions/filter-tree.actions';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewFilterModalComponent } from '../../shared/components/new-filter-modal/new-filter-modal.component';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { SidebarState, SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { find, has } from 'lodash-es';
import { Integration } from 'src/app/core/constants/integration';
import { FilterErrorsModalComponent } from '../../shared/components/filter-errors-modal/filter-errors-modal.component';
import { AdDestinationUrlsModalComponent } from '../../shared/components/ad-destination-urls-modal/ad-destination-urls-modal.component';
import { ReportRefresherService } from 'src/app/core/services/report-refresher/report-refresher.service';
import { findAllInTree, findAllIds, findInTree, removeInTree } from 'src/app/core/helpers/find-in-tree';
import { FilterTreeStateModel, TreeActions } from 'src/app/store/states/filter-tree.state';
import { BreakDownService } from 'src/app/core/services/break-down/break-down.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NewVirtualFilterModalComponent } from '../../shared/components/new-virtual-filter-modal/new-virtual-filter-modal.component';
import { CreateNewFilterResponse } from 'src/app/core/services/filter/filter-service.models';
import { cloneDeep, clone } from 'lodash-es';
import { Project, View } from 'src/app/core/models/project.model';
import { FirstTimePurchaseState } from 'src/app/store/index.state';
import { FirstTimePurchaseStateModel } from 'src/app/store/states/first-time-purchase.state';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { DataDownloaderService } from 'src/app/core/services/data-downloader/data-downloader.service';
import { PatchReport } from 'src/app/store/actions/report.actions';
import { HideChannel, HideFilter, UnhideFilterChannels } from 'src/app/store/actions/project.actions';
import {ErrorDialogService} from "../../../core/services/error-dialog/error-dialog.service";
import { ReportService } from 'src/app/core/services/report/report.service';
import { TimePeriodService } from 'src/app/core/services/time-period/time-period.service';
import { BaseService } from 'src/app/core/services/base/base.service';
import { LocalSubscriptionService } from 'src/app/core/services/local-subscription/local-subscription.service';
import {offset as offsetModifier} from '@popperjs/core';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { ExtendedAdDestinationUrlsModalComponent } from '../../shared/components/extended-ad-destination-urls-modal/extended-ad-destination-urls-modal.component';
import { PatchTimePeriods } from 'src/app/store/actions/time-periods.actions';
import { PatchFirstTimePurchase } from 'src/app/store/actions/first-time-purchase.actions';
import { AddToPendingDataRefresh, SetPendingRefreshMovingFrom } from 'src/app/store/actions/base.actions';
import { calculateBreakdowns } from './helpers/calculate-breakdowns';
import { Breakdown } from 'src/app/core/enums/breakdown.type';

import { saveAs } from 'file-saver';

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

const getReportForItem = (item, report) => {
  return report && item ? (item.t == 'g' ? report.groups[item.id] : report.filters[item.id]) : false;
}

@Component( {
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
} )
export class HomeComponent implements OnInit, OnDestroy  {
  @ViewChild('channelNameInput') set channelNameInputRef(channelInputRef: ElementRef) {
    if  (channelInputRef) {
      channelInputRef.nativeElement.focus();
    }
  }
  @Select(ReportState) report$: Observable<ReportStateModel>;
  @Select(state => state.filterTree) tree$: Observable<FilterTreeStateModel>;
  @Select(state => state.selected) selectedState$: Observable<SelectedStateModel>;
  @Select(state => state.selected.currentComponent) currentComponent$: Observable<String>;
  @Select(SidebarState) sidebar$: Observable<SidebarStateModel>;
  @Select(state => state.project) projectState$: Observable<ProjectStateModel>;
  @Select(state => state.timePeriods) timePeriods$: Observable<any>;
  @Select(FirstTimePurchaseState) FTPstate$: Observable<FirstTimePurchaseStateModel>;
  @Select(state => state.base.pendingDataRefresh) pendingDataRefresh$: Observable<any>;

  sidebarNames = SidebarNames;

  componentNames = ComponentNames;
  componentPath = ComponentNames.dashboard;
  settingsSections = SettingsSections;

  filterTree = null;
  originalNestableList;
  nestableList;
  specials;
  report: { filters: Array<any>; groups: Array<any>; other: {raw: any, all: any, max: any} };
  lastReportState: ReportStateModel;
  allTraffic;
  raw;
  editNameId: number = null;
  nameInput: string;
  showRaw = false;
  reportIsLoading = true;
  ftpIsLoading = true;
  timeframeIsLoading = true;
  isLoading = true;
  initialLoading = true;
  reportSubscription;
  timeframeReportSubscription;
  sidebar: any;
  contentStyle;
  mapping: any;
  dragDisabled = true;
  editFilterWaiting;
  waitingHideGroup;
  waitingHideFilter;
  selectedAttributionModel;
  lastEmittedSort = null;
  project: Project;
  view: View;
  expiredOauthChannels = [];
  emptyDialogType;
  emptyDialogClosed = false;
  reportIsEmpty = false;
  reportIsEmptyTimeout;
  emptyReportValues = { e: 0, v: 0, c: 0, o: 0, r: 0, a: 0 };
  dndEventData;
  ShowMessageUntilReportLoaded = false;
  isFirstLoad = true;
  withSpendNoVisits = [];
  unattributedPercent;
  exceptionProjects = [2098];
  isExceptionProject = false;
  filterErrors:any = {};
  virtualFilters = [];
  allChannels = [];
  allFilters = [];
  excludedFilters = [];
  isDemoProject = false;

  isProd = environment.isProd;

  incorrectSpendWorkaroundProjects = [2481];

  showTimeframe = false;
  isFTP: boolean = false;
  timeframeReport;
  periodsWithoutTotal = [];
  timeframeFTPReport;
  periodsWithoutTotalFTP = [];
  tableRevenue = 'Absolute';
  aggregationPeriod = 'day';
  dateDiff;
  checkTreeInterval;

  waitingExpandGroups = [];
  expandWaitingForResp = [];
  waitingExpandVirtualGroups = [];
  expandVirtualWaitingForResp = [];

  csvDownloading = false;

  showZerosArray: Array<number> = [];
  showVirtualZerosArray: Array<number> = [];

  lastReportMeta;
  lastTimeframeMeta;
  lastFTPMeta;

  shimmerActiveFor = {
    filters: [],
    groups: []
  };

  ordinals: any[] = [];
  
  pendingDataRefresState: any;

  expandSub: Subscription;
  unhideSub: Subscription;
  breakdownSub: Subscription;
  pendingDataRefreshSub: Subscription;
  initialDatesChanged = true;

  settingDropdown: NgbDropdown;
  dropdownItem;
  dropdownLevel;
  showAddToGroupItem;

  dataSub;
  treeSub;
  projectStateSub;
  filterTreeExpandedSub;
  ftpStateSub;
  selectedStateSub;
  sidebarSub;
  paramsSub;
  qParamsSub;

  breakdowns: {
    f: Breakdown[];
    g: Breakdown[];
    all: Breakdown;
    allTrafficTopOpened: boolean;
    allTrafficBottomOpened: boolean;
  } = {
    f: [],
    g: [],
    all: null,
    allTrafficBottomOpened: false,
    allTrafficTopOpened: false
  };

  shouldResetSort = false;

  constructor(
    private store: Store,
    private dataRefresher: DataRefresherService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService,
    private reportRefresher: ReportRefresherService,
    private breakDownService: BreakDownService,
    private toastr: ToastrService,
    private FTWS: FilterTreeWorkerService,
    private errorDialogService: ErrorDialogService,
    private dataDownloader: DataDownloaderService,
    private reportService: ReportService,
    private timePeriodsService: TimePeriodService,
    private baseService: BaseService,
    private localSubscriptions: LocalSubscriptionService) {}

  ngOnInit() {
    this.dataSub = this.route
      .data
      .subscribe(data => {
        const currentComponent = this.store.selectSnapshot(state => state.selected.currentComponent);
        if (data.firstTimePurchasers) {
          this.showTimeframe = true;
          this.isLoading = this.ftpIsLoading;
          this.isFTP = true;
          if (currentComponent != ComponentNames.firstTimePurchasers) this.store.dispatch(new SetCurrentComponent({ currentComponent: ComponentNames.firstTimePurchasers }));
          this.componentPath = ComponentNames.firstTimePurchasers;
        } else if (data.timeframe) {
          this.showTimeframe = true;
          this.isLoading = this.timeframeIsLoading;
          if (currentComponent != ComponentNames.timeframe) this.store.dispatch(new SetCurrentComponent({ currentComponent: ComponentNames.timeframe }));
          this.componentPath = ComponentNames.timeframe;
        } else {
          if (currentComponent != ComponentNames.dashboard) this.store.dispatch(new SetCurrentComponent({ currentComponent: ComponentNames.dashboard }));
          this.componentPath = ComponentNames.dashboard;
          this.isLoading = this.reportIsLoading;
          this.showTimeframe = false;
          this.isFTP = false;
        }

        const treeRefreshScheduled = this.store.selectSnapshot(state => state.filterTree.refreshScheduled);
        if (treeRefreshScheduled) {
          this.filterService.getFilterTree().subscribe(x => {
            this.flow();
          });
        } else {
          this.flow();
        }
      });
  }

  flow() {
    this.treeSub = this.tree$.subscribe( x => {
      if (x.lastAction == TreeActions.toggleExpand && this.nestableList) {
        return;
      }
      this.filterTree = x;
      this.ordinals = x.ordinals;
      this.generateNestableLists();
      this.setUnattributedPercent();
      this.identifyWithSpendNoVisits(this.filterTree?.filterTree);
    });

    this.pendingDataRefreshSub = this.pendingDataRefresh$.subscribe( x => {
      this.pendingDataRefresState = x;
    });

    this.projectStateSub = this.projectState$.subscribe( resp => {
      if (resp.project) {
        this.isDemoProject = this.project?.identifier === 'DEMO' || this.project?.options?.other?.demo === true;
        this.project = resp.project;
        this.view = resp.currentViewParams;
        this.isExceptionProject = this.exceptionProjects.includes(this.project.id);
        this.expiredOauthChannels = this.project.oauths.filter(x => x.expired_at !== null);
        this.excludedFilters = this.project.settings?.exclude_filters || [];
        this.checkIfDemoProject();

        this.aggregationPeriod = this.view?.aggregation_period || 'Day';

        if ((this.aggregationPeriod == 'day' || this.aggregationPeriod == 'week') && this.dateDiff > 200) {
          this.aggregationPeriod = 'month';
        } else if (this.aggregationPeriod == 'day' && this.dateDiff > 61) {
          this.aggregationPeriod = 'week';
        }
      }
    });

    this.filterTreeExpandedSub = this.filterService.filterTreeExpanded.subscribe( () => {
      this.generateNestableLists();
    });

    this.selectedStateSub = this.selectedState$.subscribe( selectedState => {
      this.selectedAttributionModel = selectedState.attributionModel;
      if (selectedState.datesChanged) {
        this.store.dispatch(new SetDatesChanged(false));
        if (selectedState?.chosenDates?.start && selectedState?.chosenDates?.end) {
          this.filterService.getErrors().subscribe( x => {
            this.filterErrors = x;
          });
        }
      }
    });

    this.ftpStateSub = this.FTPstate$.subscribe( FTPstate => {
      if (FTPstate.report) {
        this.timeframeFTPReport = FTPstate.report;
        this.periodsWithoutTotalFTP = FTPstate.report.p.slice(1);
        this.lastFTPMeta = FTPstate.meta;

        this.dataRefresher.shouldComponentDataRefresh(this.componentPath);
      }
      this.ftpIsLoading = FTPstate.inProgress;
      if (this.isFTP) {
        this.isLoading = this.ftpIsLoading;
        if (this.initialLoading) this.initialLoading = this.isLoading;
      }
    });

    this.timeframeReportSubscription = this.timePeriods$.subscribe( (timeframeState) => {
      if (this.showTimeframe) {
        this.lastTimeframeMeta = timeframeState.meta;
        if (timeframeState.report) {
          this.timeframeReport = {
            ...timeframeState.report,
            raw: timeframeState.raw
          };
          this.periodsWithoutTotal = timeframeState.report.p.slice(1);
        }
        
        this.timeframeIsLoading = timeframeState.inProgress;
        if (this.showTimeframe && !this.isFTP) {
          this.isLoading = this.timeframeIsLoading;
          if (this.initialLoading) this.initialLoading = this.isLoading;
        }

        this.dataRefresher.shouldComponentDataRefresh(this.componentPath);
      }
    });

    this.reportSubscription = this.report$.subscribe( (reportState) => {
      this.lastReportState = reportState;

      if (!reportState.report) {
        this.breakdowns = {
          f: [],
          g: [],
          all: null,
          allTrafficBottomOpened: false,
          allTrafficTopOpened: false
        };
      }
      
      this.reportIsLoading = reportState.inProgress;
      if (!this.showTimeframe) {
        this.isLoading = this.reportIsLoading;
        if (this.initialLoading) this.initialLoading = this.isLoading;
      }

      if (this.project?.options?.features?.load_report_button && ((!reportState.needsToLoad && reportState.report == null) || reportState.inProgress)
      ) {
        this.ShowMessageUntilReportLoaded = true;
        return;
      } else {
        this.ShowMessageUntilReportLoaded = false;
        this.isFirstLoad = false;
      }
      if (this.initialLoading) {
        if (this.checkTreeInterval) clearInterval(this.checkTreeInterval);
        this.checkTreeInterval = setInterval(() => {
          if (this.filterTree) {
            this.reportFlow();
            clearInterval(this.checkTreeInterval);
            this.checkTreeInterval = null;
          }
        }, 500);
      } else this.reportFlow();
    });

    this.sidebarSub = this.sidebar$.subscribe( state => {
      this.sidebar = state;
      if (this.sidebar.openedSidebar) {
        this.contentStyle = {
          'margin-right': '15px'
        };
      } else {
        this.contentStyle = null;
      }
    });

    this.paramsSub = this.route.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, this.componentPath);
    });

    this.qParamsSub = this.route.queryParams.subscribe(queryParams => {
      const {sidebar, ...everythingElse} = queryParams;
      if (sidebar) {
        this.store.dispatch(new OpenSidebar({
          openedSidebar: sidebar,
          options: { ...everythingElse }
        }));
      }

      if (typeof(queryParams.edit_filter_id) != 'undefined') {
        this.openEditModal({edit_filter_id: queryParams.edit_filter_id});
      } else if (typeof(queryParams.edit_virtual_filter_id) != 'undefined') {
        this.openEditVirtualModal({edit_virtual_filter_id: queryParams.edit_virtual_filter_id});
      } else if (
        typeof(queryParams.no_visit_filter_id) != 'undefined' ||
        typeof(queryParams.no_visit_filter_group_id) != 'undefined' ||
        typeof(queryParams.error_filter_id) != 'undefined' ||
        typeof(queryParams.error_filter_group_id) != 'undefined') {
          this.openIntegrationErrorsModal(queryParams);
      }
    });
  }

  ngOnDestroy() {
    this.reportSubscription?.unsubscribe();
    this.timeframeReportSubscription?.unsubscribe();
    this.treeSub?.unsubscribe();
    this.projectStateSub?.unsubscribe();
    this.filterTreeExpandedSub?.unsubscribe();
    this.ftpStateSub?.unsubscribe();
    this.selectedStateSub?.unsubscribe();
    this.sidebarSub?.unsubscribe();
    this.paramsSub?.unsubscribe();
    this.qParamsSub?.unsubscribe();
    this.breakdownSub?.unsubscribe();
    this.dataSub?.unsubscribe();
    this.pendingDataRefreshSub?.unsubscribe();
    this.breakdownSub = null;
    this.reportService.unsubscribeAndCancelReportGeneration();
    this.timePeriodsService.unsubscribeAndCancelReportGeneration();
  }

  private identifyWithSpendNoVisits(array) {
    if (array == null || typeof(array) == 'undefined') return;
    return array.map( (item: any) => {
      if (item.c) this.identifyWithSpendNoVisits(item.c);
      const report = this.getReportForItem(item);
      if (report && item.t == 'f' && report.e == 0 && report.a > 0) {
        const isNotAlreadyInSpendNoVisits = typeof(this.withSpendNoVisits.find(x => x.id == item.id)) == 'undefined';
        if (isNotAlreadyInSpendNoVisits) this.withSpendNoVisits.push(item);
      }
    });
  }

  reportFlow() {
    this.report = this.lastReportState.report;
    this.lastReportMeta = this.lastReportState.meta;

    if (this.reportIsEmptyTimeout) {
      clearTimeout(this.reportIsEmptyTimeout);
      this.reportIsEmptyTimeout = null;
    }

    if (this.lastReportState.meta && this.lastReportState.meta.is_empty) {
      this.reportIsEmpty = true;
      this.reportIsEmptyTimeout = setTimeout(() => {
        this.dataRefresher.shouldComponentDataRefresh(this.componentPath);
      }, 15 * 60 * 1000);

      if (!this.emptyDialogClosed) {
        this.showCompleteSetupDialog();
      }
    } else {
      this.emptyDialogType = null;
    }

    if (this.report != null) {
      this.mapping = this.lastReportState.meta.mapping;
      const selectedState = this.store.selectSnapshot<SelectedStateModel>(state => state.selected);
      if ((selectedState.chosenDates?.start && selectedState.chosenDates?.end) && (selectedState.datesChanged || this.initialDatesChanged)) {
        this.initialDatesChanged = false;
        this.store.dispatch(new SetDatesChanged(false));
        this.filterService.getErrors().subscribe( x => {
          this.filterErrors = x;
        });
      }

      this.setUnattributedPercent();
      this.identifyWithSpendNoVisits(this.filterTree?.filterTree);
    }
    this.dataRefresher.shouldComponentDataRefresh(this.componentPath);
  }

  generateNestableLists() {
    if (this.filterTree?.filterTree) {
      this.allChannels = findAllInTree({c: this.filterTree?.filterTree}, 'c', 't', 'g', null, null, null, true);
      this.allFilters = findAllInTree({c: this.filterTree?.filterTree}, 'c', 't', 'f', null, null, null, true);
      this.virtualFilters = this.filterTree?.filterTree.filter(x => x.s == 'virtual' && x.n != 'Paid Traffic');

      const list = this.withoutSpecials(cloneDeep(this.filterTree?.filterTree));
      this.specials = this.onlySpecials(this.filterTree?.filterTree);

      this.originalNestableList = Array(...list);
      if (this.lastEmittedSort) {
        this.sort(this.lastEmittedSort);
      } else {
        this.nestableList = list;
      }

      this.showZerosArray = clone(this.filterTree.showZeros);

      this.withSpendNoVisits = this.withSpendNoVisits.map(x => {
        const node = this.FTWS.findInTree({node: {c: this.nestableList }, childrenKey: 'c', key: 'id', value: x.id, additionalKey: 't', additionalValue: 'f', returnParent: true, updateLevel: false, topParent: true});
        return typeof(node) != 'undefined' && typeof(node.parent) != 'undefined' ? {...x, parentName: node.parent.name, parent_filter_group_id: node.parent.id, topParent: node.topParent} : x;
      });
    }
  }

  showZeros(item) {
    if (item.t === 'g') {
      this.showZerosArray.push(item.id);
      this.store.dispatch(new ShowChannelZeros(item.id));
    } else {
      this.showVirtualZerosArray.push(item.id);
      this.nestableList = this.nestableList.map( x => {
        if (x.id === item.id && item.t === 'f') {
          return {...x, showZeros: true};
        } else {
          return x;
        }
      });
    }
  }

  setUnattributedPercent() {
    if (this.report && this.specials) {
      const unattributed = this.specials.find(x => x.s == 'unattributed');
      if (typeof(unattributed) != 'undefined') {
        this.unattributedPercent = this.report?.filters[unattributed.id]?.r/this.report?.other?.all?.revenue;
      }
    }
  }

  showCompleteSetupDialog() {
    const partner = this.store.selectSnapshot( state => state.account)?.partner;
    const isShopify = partner?.toUpperCase() === 'shopify'.toUpperCase();
    const isBigCommerce = partner?.toUpperCase() === 'bigcommerce'.toUpperCase();
    this.emptyDialogType = isShopify ? 'Shopfy' : isBigCommerce ? 'BigCommerce' : 'complete-setup';
  }

  closeEmptyDialog() {
    this.emptyDialogType = null;
    this.emptyDialogClosed = true;
  }

  canExpand(item, parent) {
    return (
      item.t == 'g' &&
      !((['googleads', 'googleads2'].indexOf(parent.integration) > -1) &&
      parent.it == 'Shopping Campaign' &&
      item.it == 'Ad Group' &&
      item.e != true &&
      !this.isExceptionProject)
    )
  }

  reportParams() {
    return {
      reportType: this.showTimeframe ? (this.isFTP ? 'first_time_purchasers' : 'time_period') : 'dashboard',
      report: this.showTimeframe ? (this.isFTP ? this.timeframeFTPReport : this.timeframeReport) : this.report,
      filters: this.showTimeframe ? 'f' : 'filters',
      groups: this.showTimeframe ? 'f' : 'filters',
    }
  }

  patchAppropriateReport(resp) {
    let actions;
    if (this.showTimeframe) {
      if (this.isFTP) {
        actions = [
          new PatchFirstTimePurchase(resp)
        ];
      } else {
        actions = [
          new PatchTimePeriods(resp)
        ];
      }
    } else {
      actions = [
        new PatchReport(resp)
      ];
    }
    return actions;
  }

  toggleExpand(item) {
    const waitingExpandGroups = item.s === 'virtual' ? this.waitingExpandVirtualGroups : this.waitingExpandGroups;
    const expandWaitingForResp = item.s === 'virtual' ? this.expandVirtualWaitingForResp : this.expandWaitingForResp;

    const reportType = this.showTimeframe ? (this.isFTP ? 'first_time_purchasers' : 'time_period') : 'dashboard';
    const report = this.showTimeframe ? (this.isFTP ? this.timeframeFTPReport : this.timeframeReport) : this.report;
    const filters = this.showTimeframe ? 'f' : 'filters';
    const groups = this.showTimeframe ? 'g' : 'groups';

    const localExpand = () => {
      item.breakDownHidden = true;

      item.e = item.e ? false : true;

      if (item.s !== 'virtual') {
        if (item.e) {
          this.filterService.expand(item.id).subscribe();
        } else {
          this.filterService.collapse(item.id).subscribe();
        }

        this.store.dispatch(new ToggleExpand({id: item.id, isExpanded: item.e }));
      } else {
        this.store.dispatch(new ToggleExpand({id: item.id, isExpanded: item.e, isVirtual: true}));
      }
    }

    if (waitingExpandGroups.includes(item.id)) {
      if (item.s === 'virtual') {
        this.waitingExpandVirtualGroups = this.waitingExpandVirtualGroups.filter(x => x != item.id);
      } else {
        this.waitingExpandGroups = this.waitingExpandGroups.filter(x => x != item.id);
      }
      localExpand();
      return;
    }

    if (!this.reportIsEmpty && !item.e && !expandWaitingForResp.includes(item.id)) {
      const visibleFiltersBeneathWithoutReportData = (
                                                        item.s === 'virtual' ? 
                                                          item.vc.filter(vc => vc.t === 'f').map(vc => vc.id)
                                                          : findAllIds({c: item.c}, 'c', 't', 'f', true)
                                                      ).filter(x => {
                                                        return typeof(report[filters][x]) == 'undefined';
                                                      });
      const visibleGroupsBeneathWithoutReportData = (
                                                      item.s === 'virtual' ? 
                                                        item.vc.filter(vc => vc.t === 'g').map(vc => vc.id)
                                                        : findAllIds({c: item.c}, 'c', 't', 'g', true)
                                                    ).filter(x => {
                                                      return typeof(report[groups][x]) == 'undefined';
                                                    });
      if (visibleFiltersBeneathWithoutReportData.length != 0 || visibleGroupsBeneathWithoutReportData.length != 0) {
        if (item.s === 'virtual') {
          this.waitingExpandVirtualGroups = [...this.waitingExpandVirtualGroups, item.id];
          this.expandVirtualWaitingForResp = [...this.expandVirtualWaitingForResp, item.id]
        } else {
          this.waitingExpandGroups = [...this.waitingExpandGroups, item.id];
          this.expandWaitingForResp = [...this.expandWaitingForResp, item.id]
        }
        this.shimmerActiveFor.filters = visibleFiltersBeneathWithoutReportData;
        this.shimmerActiveFor.groups = visibleGroupsBeneathWithoutReportData;
        const params = {
          filter_ids: visibleFiltersBeneathWithoutReportData,
          filter_group_ids: visibleGroupsBeneathWithoutReportData,
        };

        let meta = this.lastReportMeta
        if (this.showTimeframe && this.isFTP) {
          meta = this.lastFTPMeta;
        } else if (this.showTimeframe) {
          meta = this.lastTimeframeMeta;
        }

        this.expandSub = this.dataDownloader.checkStatusAndDownload({meta: meta, reportType: reportType, filterAndGroupIds: params}).subscribe( resp => {
          if (item.s === 'virtual') {
            this.waitingExpandVirtualGroups = this.waitingExpandVirtualGroups.filter(x => x != item.id);
            this.expandVirtualWaitingForResp = this.expandVirtualWaitingForResp.filter(x => x != item.id);
          } else {
            this.waitingExpandGroups = this.waitingExpandGroups.filter(x => x != item.id);
            this.expandWaitingForResp = this.expandWaitingForResp.filter(x => x != item.id);
          }
          this.dataDownloader.clearCountDown();
          this.expandSub = null;
          if (resp.meta.request_new_report) {
            this.shimmerActiveFor = {filters: [], groups: []};
            setTimeout(() => {
              this.toastr.warning(
                `Report has expired. <a href="${this.baseService.currentPath}">Refresh</a> to generate new report.`,
                `status: ${status}`,
                {disableTimeOut: true, enableHtml: true}
              )
            });
            return;
          }
          this.store.dispatch(this.patchAppropriateReport(resp)).subscribe(() => {
            this.shimmerActiveFor = {filters: [], groups: []};
          });
        });
        localExpand();
      } else {
        localExpand();
      }
    } else {
      if (expandWaitingForResp.includes(item.id)) {
        if (item.s === 'virtual') {
          this.waitingExpandVirtualGroups = [...this.waitingExpandVirtualGroups, item.id];
        } else {
          this.waitingExpandGroups = [...this.waitingExpandGroups, item.id];
        }
      }
      localExpand();
    }
  }

  hideFilter(filterId) {
    this.store.dispatch(new HideFilter(filterId));
  }

  hideGroup(item) {
    const FiltersBeneath = findAllInTree({c: item.c}, 'c', 't', 'f', false, false, false, [], false).map(x => x.id);
    const GroupsBeneath = findAllInTree({c: item.c}, 'c', 't', 'g', false, false, false, [], false).map(x => x.id);
    this.store.dispatch(new HideChannel({filters: FiltersBeneath, groups: [item.id, ...GroupsBeneath]}));
  }

  unhideFilterChannels() {
    const filtersToRequest = this.view.hidden_filters.filter(x => {
      return typeof(this.report.filters[x]) == 'undefined';
    });
    const groupsToRequest = this.view.hidden_filter_groups.filter(x => {
      return typeof(this.report.groups[x]) == 'undefined';
    });
    if (filtersToRequest.length != 0 || groupsToRequest.length != 0) {
      this.shimmerActiveFor.filters = [...new Set([...this.shimmerActiveFor.filters, ...this.view.hidden_filters])];
      this.shimmerActiveFor.groups = [...new Set([...this.shimmerActiveFor.groups, ...this.view.hidden_filter_groups])];

      let meta = this.lastReportMeta
      if (this.showTimeframe && this.isFTP) {
        meta = this.lastFTPMeta;
      } else if (this.showTimeframe) {
        meta = this.lastTimeframeMeta;
      }

      this.unhideSub = this.dataDownloader.checkStatusAndDownload({meta: meta, reportType: this.showTimeframe ? 'time_period' : 'dashboard', filterAndGroupIds: {filter_ids: filtersToRequest, filter_group_ids: groupsToRequest}}).subscribe( resp => {
        this.dataDownloader.clearCountDown();
        this.unhideSub = null;
        if (resp.meta.request_new_report) {
          setTimeout(() => {
            this.toastr.warning(
              `Report has expired. <a href="${this.baseService.currentPath}">Refresh</a> to generate new report.`,
              `status: ${status}`,
              {disableTimeOut: true, enableHtml: true}
            )
          });
          return;
        }
        this.store.dispatch(new PatchReport(resp)).subscribe(() => {
          this.shimmerActiveFor = {filters: [], groups: []};
        });
      });
    }
    this.store.dispatch(new UnhideFilterChannels());
  }

  searchFilter(event) {
    const selectedFilter = event.item;
    const item = event.topParent;
    if (selectedFilter && typeof(item) == 'undefined') {
      this.localSubscriptions.showFilter(selectedFilter);
      return;
    }
    const visibleFiltersBeneathWithoutReportData = findAllIds({c: item?.c}, 'c', 't', 'f', false).filter(x => {
      return typeof(this.report.filters[x]) == 'undefined';
    });
    const visibleGroupsBeneathWithoutReportData = findAllIds({c: item?.c}, 'c', 't', 'g', false).filter(x => {
      return typeof(this.report.groups[x]) == 'undefined';
    });
    if (visibleFiltersBeneathWithoutReportData.length != 0 || visibleGroupsBeneathWithoutReportData.length != 0) {
      this.waitingExpandGroups = [...this.waitingExpandGroups, item.id];
      this.shimmerActiveFor.filters = visibleFiltersBeneathWithoutReportData;
      this.shimmerActiveFor.groups = visibleGroupsBeneathWithoutReportData;
      const params = {
        filter_ids: visibleFiltersBeneathWithoutReportData,
        filter_group_ids: visibleGroupsBeneathWithoutReportData,
      };

      let meta = this.lastReportMeta
      if (this.showTimeframe && this.isFTP) {
        meta = this.lastFTPMeta;
      } else if (this.showTimeframe) {
        meta = this.lastTimeframeMeta;
      }

      this.expandSub = this.dataDownloader.checkStatusAndDownload({meta: meta, reportType: this.showTimeframe ? 'time_period' : 'dashboard', filterAndGroupIds: params}).subscribe( resp => {
        this.waitingExpandGroups = this.waitingExpandGroups.filter(x => x != item.id);
        this.dataDownloader.clearCountDown();
        this.expandSub = null;
        if (resp.meta.request_new_report) {
          this.shimmerActiveFor = {filters: [], groups: []};
          setTimeout(() => {
            this.toastr.warning(
              `Report has expired. <a href="${this.baseService.currentPath}">Refresh</a> to generate new report.`,
              `status: ${status}`,
              {disableTimeOut: true, enableHtml: true}
            )
          });
          return;
        }
        this.store.dispatch(new PatchReport(resp)).subscribe(() => {
          this.shimmerActiveFor = {filters: [], groups: []};
        });
        this.localSubscriptions.showFilter(selectedFilter);
      });
    } else {
      this.localSubscriptions.showFilter(selectedFilter);
    }
  }

  showFilter(item) {
    this.localSubscriptions.showFilter({id: item.id, type: item.t});
  }

  toggleDragging() {
    this.dragDisabled = !this.dragDisabled;
    this.resetSort();
    this.nestableList = [...this.originalNestableList];
  }

  toggleTimeframe() {
    if (this.showTimeframe) {
      this.router.navigate([this.project?.identifier]);
    } else {
      this.router.navigate([this.project?.identifier, this.componentNames.timeframe]);
    }
  }

  toggleRaw() {
    this.showRaw = !this.showRaw;
  }

  downloadCSV() {
    if (this.csvDownloading) return;
    const meta = this.showTimeframe ? (this.isFTP ? this.lastFTPMeta : this.lastTimeframeMeta) : this.lastReportMeta;
    this.csvDownloading = true;
    this.dataDownloader.downloadCSV(meta.report_id).subscribe( resp => {
      this.csvDownloading = false;
      let filename = `${this.showTimeframe ? (this.isFTP ? 'FTP' : 'timeframe') : 'atb_dashboard'}_${meta.report_id.substr(meta.report_id.length - 6)}.csv`;
      const blob = new Blob([resp], {type: 'text/csv'});
      saveAs(blob, filename);
    });
  }

  toggleNameInput(channelId, name) {
    this.nameInput = name;
    this.editNameId = this.editNameId === channelId ? null : channelId;
  }

  openNewFilterModal() {
    const modalRef = this.modalService.open(NewFilterModalComponent, {windowClass: 'slideInDown'});
    modalRef.componentInstance.filterIntegration = null;
  }

  addNewChannel() {
    this.filterService.createNewChannel('New Channel').subscribe( (resp: any) => {
      this.store.dispatch(new AddFilterChannel({channel: resp})).subscribe(() => {
        this.toggleNameInput(resp.id, resp.name);
        this.reportRefresher.scheduleRefresh();
      });
    });
  }

  addNewGroup() {
    const modalRef = this.modalService.open(NewVirtualFilterModalComponent, {windowClass: 'slideInDown'});
    modalRef.componentInstance.allFilters = this.allFilters;
    modalRef.componentInstance.allChannels = this.allChannels;
    modalRef.result.then(result => {
      if (result.result === 'vFilterAdded') {
        this.reportRefresher.scheduleRefresh(true);
      }
    },
    e => console.log(e));
  }

  renameChannel(nameInput, channelId) {
    this.filterService.editChannel(channelId, nameInput.value);
    this.editNameId = null;
    this.renameFilterOrGroup('g', nameInput.value, channelId)
  }

  deleteChannel(channel) {
    if (channel.c?.length > 0) return;
    this.filterService.deleteChannel(channel.id);
    this.editNameId = null;
    this.removeFilterOrGroup('g', channel.id)
    this.reportRefresher.scheduleRefresh();
  }

  resetSort() {
    this.shouldResetSort = true;
  }

  resetShouldResetSort() {
    this.shouldResetSort = false;
  }

  sort(emitted) {
    if (emitted.sortDirection) {
      this.lastEmittedSort = emitted;
      this.nestableList = this.sortTable(this.originalNestableList, emitted.sortDirection, emitted.sortColumn);
    } else {
      this.lastEmittedSort = null;
      this.nestableList = [...this.originalNestableList];
    }
  }

  sortedBreakdowns(breakDowns) {
    if (this.lastEmittedSort) {
      return this.sortTable(breakDowns, this.lastEmittedSort.sortDirection, this.lastEmittedSort.sortColumn, true);
    } else {
      return breakDowns;
    }
  }

  breakDown(item, allTrafficRow?) {
    let itemWBDs: Breakdown = item.id && item.t ? this.breakdowns[item.t][item.id] : this.breakdowns.all;
    if (itemWBDs?.waitingForBreakdown) return;

    if ((typeof(itemWBDs?.hidden) != 'undefined' || itemWBDs?.hidden == null) && itemWBDs?.original) {
      
      if (allTrafficRow === 'top') {
        this.breakdowns.allTrafficTopOpened = !this.breakdowns.allTrafficTopOpened;
      } else if (allTrafficRow === 'bottom') {
        this.breakdowns.allTrafficBottomOpened = !this.breakdowns.allTrafficBottomOpened;
      }

      if (itemWBDs.hidden) {
        if (item.t == 'g' && item.e) {
          item.e = false;
        }
        itemWBDs.hidden = false;
      } else {
        itemWBDs.hidden = true;
      }
    } else {
      if (item.t == 'g' && item.e) {
        item.e = false;
      }
      if (item.id && item.t) {
        this.breakdowns[item.t][item.id] = { waitingForBreakdown: true };
        this.breakdowns = {
          ...this.breakdowns,
          [item.t]: {
            ...this.breakdowns[item.t],
            [item.id]: {
              waitingForBreakdown: true
            }
          }
        };
      } else {
        this.breakdowns = {
          ...this.breakdowns,
          all: {
            waitingForBreakdown: true
          }
        };
      }
      itemWBDs = item.id && item.t ? this.breakdowns[item.t][item.id] : this.breakdowns.all;
      this.breakdownSub = this.breakDownService.getBreakDown(item.id, item.s == 'virtual' ? 'v' : item.t).subscribe( (x) => {
        const paths = x.report?.other?.paths;
        itemWBDs.waitingForBreakdown = false;
        if (x != null && typeof(paths) != 'undefined' && Object.keys(paths).length > 0) {
          
          itemWBDs.original = calculateBreakdowns(paths, this.mapping);
          
          itemWBDs.hidden = false;
          if (allTrafficRow === 'top') {
            this.breakdowns.allTrafficTopOpened = true;
          } else if (allTrafficRow === 'bottom') {
            this.breakdowns.allTrafficBottomOpened = true;
          }
          
          this.breakdowns = {
            ...this.breakdowns,
            [item.t]: {
              ...this.breakdowns[item.t],
              [item.id]: itemWBDs
            }
          };
        }
        this.breakdownSub = null;
      }, e => {
        itemWBDs.waitingForBreakdown = false;
        setTimeout(() =>
          this.toastr.error('Failed', 'Failed to break down.', {disableTimeOut: true})
        );
        this.breakdownSub = null;
      });
    }
  }

  onDragStart(item: any) {
    const node = findInTree({node: {c: this.nestableList }, childrenKey: 'c', key: 'id', value: item.id, additionalKey: 't', additionalValue: item.t, withPath: this.nestableList, getPathIds: true});
    this.store.dispatch(
      new SetPendingRefreshMovingFrom({
        id: node.id,
        pathArray: node.pathArray,
      })
    );
  }

  onDragged( item: any, list: any[], effect: DropEffect ) {
    if ( effect === 'move' ) {
      const index = list.indexOf( item );
      list.splice( index, 1 );
    }
  }

  onDragEnd( event: DragEvent ) {
    const node = findInTree({node: {c: this.nestableList }, childrenKey: 'c', key: 'id', value: this.dndEventData.id, additionalKey: 't', additionalValue: this.dndEventData.t, returnParent: true, updateLevel: true, withPath: this.nestableList, getPathIds: true});
    this.filterService.move(node).subscribe( () => {
      this.store.dispatch([
        new ReArrangeFilters({tree: [...this.nestableList, ...this.specials]}),
        new AddToPendingDataRefresh({
          id: node.id,
          type: node.t,
          pathArray: node.pathArray,
          movingTo: node.parent.id,
          case: 'move'
        })
      ]);
      this.reportRefresher.scheduleRefresh();
    });
    if (node.parent?.id && node.zero == true) {
      this.showZerosArray.push(node.parent.id);
    }
    this.originalNestableList = [...this.nestableList];
  }

  onDrop( event: DndDropEvent, list?: any[] ) {
    this.dndEventData = {id: event.data.id, t: event.data.t};
    if ( list
      && (event.dropEffect === 'copy'
        || event.dropEffect === 'move') ) {

      let index = event.index;

      if ( typeof index === 'undefined' ) {

        index = list.length;
      }

      list.splice( index, 0, event.data );
    }
  }

  navigateToEditModal(item) {
    if (item.s == 'virtual') {
      const queryParamsToPass: any = {
        edit_virtual_filter_id: item.id
      };
      this.router.navigate([], {
        queryParams: queryParamsToPass
      });

    } else {
      const queryParamsToPass: any = {
        edit_filter_id: item.id
      };
      this.router.navigate([], {
        queryParams: queryParamsToPass
      });
    }
  }

  openEditModal(queryParams) {
    let item;
    if (queryParams.edit_filter_id) {
      item = this.FTWS.findInTree({node: {c: this.nestableList}, childrenKey: 'c', key: 'id', value: parseInt(queryParams.edit_filter_id), additionalKey: 't', additionalValue: 'f'});
    }
    if (item) {
      if (item.s == 'virtual') {
        this.removeEditIdFromParams();
        return;
      }
      this.editFilterWaiting = item.id;
      this.filterService.getFilter(item.id).subscribe( resp => {
        this.editFilterWaiting = null;
        const modalRef = this.modalService.open(NewFilterModalComponent);
        if (item) {
          modalRef.componentInstance.filterInfo = resp;
          modalRef.componentInstance.filterIntegration = item.i;
        };
        modalRef.result.then(() => {
          const {edit_filter_id, ...params} = this.route.snapshot.queryParams;
          setTimeout(() => {
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: params
            });
          }, 300);
        }, () => {
          const {edit_filter_id, ...params} = this.route.snapshot.queryParams;
          setTimeout(() => {
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: params
            });
          }, 300);
        });
      }, error => {
        this.editFilterWaiting = null;
      });
    }
  }

  openEditVirtualModal(queryParams) {
    let item;
    if (queryParams.edit_virtual_filter_id) {
      item = this.FTWS.findInTree({node: {c: this.nestableList}, childrenKey: 'c', key: 'id', value: parseInt(queryParams.edit_virtual_filter_id), additionalKey: 't', additionalValue: 'f'});
    }
    if (item) {
      if (item.n == 'Paid Traffic') return;
      if (item.s != 'virtual') {
        this.removeEditIdFromParams();
        return;
      }
      this.editFilterWaiting = item.id;
      this.filterService.getFilter(item.id).subscribe( resp => {
        this.editFilterWaiting = null;
        const modalRef = this.modalService.open(NewVirtualFilterModalComponent);
        modalRef.componentInstance.allFilters = this.allFilters;
        modalRef.componentInstance.allChannels = this.allChannels;
        if (item) {
          modalRef.componentInstance.vFilterInfo = resp;
        }
        modalRef.result.then(
          result => {
            if (result.result === 'vFilterDeleted') {
              this.removeFilterOrGroup('f', result.filterId);
              this.virtualFilters = this.virtualFilters.filter(x => x.id != result.filterId);
            } else if (result.result === 'vFilterEdited') {
              this.renameFilterOrGroup('f', result.filter.name, result.filter.id);
              this.reportRefresher.scheduleRefresh(true);
            }
            this.removeEditIdFromParams();
          },
          () => this.removeEditIdFromParams()
        );
      }, error => {
        this.editFilterWaiting = null;
      });
    }
  }

  removeEditIdFromParams() {
    const {edit_filter_id, edit_virtual_filter_id, ...otherParams} = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: otherParams
    });
  }

  navigateToIntegrationErrorModal(item, isNoVisits?) {
    const queryParamsToPass: any = {};
    if (isNoVisits) {
      if (item.t === 'f') {
        queryParamsToPass.no_visit_filter_id = item.id;
      } else if (item.t === 'g') {
        queryParamsToPass.no_visit_filter_group_id = item.id;
      }
    } else {
      if (item.t === 'f') {
        queryParamsToPass.error_filter_id = item.id;
      } else if (item.t === 'g') {
        queryParamsToPass.error_filter_group_id = item.id;
      }
    }
    this.router.navigate([], {
      queryParams: queryParamsToPass
    });
  }

  openIntegrationErrorsModal(queryParams) {
    const modalType = (queryParams.error_filter_id || queryParams.error_filter_group_id) ? 'integration' : 'spend_no_visit';
    const filter_id = queryParams.error_filter_id || queryParams.no_visit_filter_id;
    const filter_group_id = queryParams.error_filter_group_id || queryParams.no_visit_filter_group_id;
    if (filter_id || filter_group_id) {
      let item;
      if (filter_id) {
        item = this.FTWS.findInTree({node: {c: this.nestableList}, childrenKey: 'c', key: 'id', value: parseInt(filter_id), additionalKey: 't', additionalValue: 'f'});
      } else if (filter_group_id) {
        item = this.FTWS.findInTree({node: {c: this.nestableList}, childrenKey: 'c', key: 'id', value: parseInt(filter_group_id), additionalKey: 't', additionalValue: 'g'});
      }
      if (item) {
        const modalRef = this.modalService.open(FilterErrorsModalComponent, {windowClass: 'slideInDown', size: 'lg'});
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.type = modalType;
        modalRef.result.then((x)=>{
          this.removeErrorIdFromParams();
          if (x && typeof(x.item) != 'undefined') {
            this.navigateToIntegrationErrorModal(x.item, x.type == 'integration' ? false : true);
          } else if (x && x.result == 'Integration errors cleared') {
            this.filterService.getErrors().subscribe( x => {
              this.filterErrors = x;
            });
          }
        },()=>{this.removeErrorIdFromParams();});
      }
    }
  }

  removeErrorIdFromParams() {
    const {error_filter_id, error_filter_group_id, no_visit_filter_id, no_visit_filter_group_id, ...otherParams} = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: otherParams
    });
  }

  disableFilter(filterId) {
    if (confirm("'Are you sure you want to disable/archive this filter? It will stop capturing new visits and will release currently captured visits. Fillter will re-active if any new spend is detected from connected integration'") == true) {
      this.waitingHideFilter = filterId;
      this.filterService.disableFilter(filterId).subscribe( () => {
        this.waitingHideFilter = null;
        this.store.dispatch(new DeleteFilter({id: filterId}));
        this.removeFilterOrGroup('f', filterId);
      });
    }
  }

  disableGroup(groupId) {
    if (confirm("'Are you sure you want to disable/archive this channel? It will stop capturing new visits and will release currently captured visits. Fillter will re-active if any new spend is detected from connected integration'") == true) {
      this.waitingHideGroup = groupId;
      this.filterService.disableGroup(groupId).subscribe( () => {
        this.waitingHideGroup = null;
        this.store.dispatch(new DeleteChannel({id: groupId}));
        this.removeFilterOrGroup('g', groupId);
      });
    }
  }

  openSidebar(path, params) {
    const {id, type, special, ...newParams} = params;
    if (type === 'f') {
      if (special == 'virtual') {
        newParams.virtual_filter = id;
      } else newParams.filter = id;
    } else if ( type === 'g') {
      newParams.group = id;
    } else if ( type === 'raw') {
      if (path === 'revenues' || params.scope === 'conversions') {
        newParams.filter = 'raw';
      }
    }
    
    let queryParams = this.route.snapshot.queryParams;
    if (queryParams.start && queryParams.end) {
      queryParams = {start: queryParams.start, end: queryParams.end};
    } else {
      queryParams = {};
    }

    const mainPath = [this.project.identifier];
    if (this.showTimeframe) {
      mainPath.push(this.componentNames.timeframe);
    }

    this.router.navigate(mainPath, {
      queryParams: { sidebar: path, ...queryParams, ...newParams }
    });
  }

  openSidebarEmit(params, isRaw?) {
    if (isRaw) { params.params.type = 'raw'; }
    this.openSidebar(params.path, params.params);
  }

  withoutSpecials(array) {
    return array.filter( x => {
      return x.s === null || x.s == 'virtual';
    });
  }

  onlySpecials(array) {
    return array.filter( x => {
      return x.s !== null && x.s != 'virtual';
    });
  }

  integrationName(oauth) {
    return  find(Integration.get.names, (names) => {
      return has(names, oauth.type);
    })[oauth.type];
  }

  openAdDestinationUrlsModal(item) {
    const modalRef = this.modalService.open(AdDestinationUrlsModalComponent, {windowClass: 'slideInDown', size: 'lg'});
    modalRef.componentInstance.item = item;
    modalRef.result.then(null, result => {});
  }

  openExtendedAdDestinationUrlsModal(item) {
    const modalRef = this.modalService.open(ExtendedAdDestinationUrlsModalComponent, {windowClass: 'slideInDown', size: 'lg'});
    modalRef.componentInstance.item = item;
    modalRef.result.then(null, result => {});
  }

  isBreakDownHidden(item) {
    return typeof(item.breakDownHidden) == 'undefined' || item.breakDownHidden == true;
  }

  toggleFilterInVirtual(item, virtual) {
    let action: string;
    this.filterService.getFilter(virtual.id).subscribe( (resp: any) => {
      let addedItems;
      const addedFilters = this.allFilters.filter( x => {
        return typeof(resp.included_filters.find(y => y.id == x.id && x.t)) != 'undefined';
      });
      const addedChannels = this.allChannels.filter( x => {
        return typeof(resp.included_filter_groups.find(y => y.id == x.id)) != 'undefined';
      });
      addedItems = [...addedFilters, ...addedChannels];

      let parameters: any = {
        name: virtual.n,
        virtual: {
          filter_ids: addedItems.filter( x => x.t == 'f').map(x => x.id),
          filter_group_ids: addedItems.filter( x => x.t == 'g').map(x => x.id)
        }
      };

      if (this.filterIsInVirtual(item, virtual)) {
        action = 'remove';
        addedItems = addedItems.filter( x => !(x.id == item.id && x.t == item.t));
      } else {
        action = 'add';
        const itemToPush = {
          id: item.id,
          n: item.n,
          t: item.t
        };
        addedItems.push(itemToPush);
      }

      parameters.virtual.filter_ids = addedItems.filter( x => x.t == 'f').map(x => x.id),
      parameters.virtual.filter_group_ids = addedItems.filter( x => x.t == 'g').map(x => x.id)

      this.filterService.editFilter(resp.id, parameters).subscribe( (resp: CreateNewFilterResponse) => {
        this.store.dispatch(new EditFilter({n: resp.name, id: resp.id})).subscribe(() => {
          this.store.dispatch(
            new AddOrRemoveVirtualFilter({action: action, item: item, virtualFilterId: virtual.id })
          );
          if (action == 'add') {
            if (item.vf != null) {
              item.vf.push(virtual.id);
            } else {
              item.vf = [virtual.id];
            }
          } else {
            item.vf = item.vf.filter(x => x != virtual.id);
          }
          this.dropdownItem = null;
          setTimeout(() => {
            this.dropdownItem = item;
          }, 0);
          this.reportRefresher.scheduleRefresh(true);
        });
      });
    });
  }

  showSettingsDropdown(event, item, level, dropdown: NgbDropdown) {
    this.settingDropdown?.close();
    this.settingDropdown = dropdown;
    this.dropdownLevel = level;
    this.dropdownItem = item;
    this.settingDropdown.popperOptions = (options) => {
      options.modifiers !.push(offsetModifier, {
        name: 'offset',
        options: {
         offset: () => [event.x, event.y-150],
       },
     });
     return options;
    };
    this.settingDropdown.open();
  }

  sortTable(array, direction, column, isBreakDown?) {
    const sortDictionary = {
      amounts: this.itemAmount,
      costPerConversion: this.itemCostPerConversion,
      visits: this.itemVisits,
      conversionRate: this.itemConversionRate,
      conversions: this.itemConversions,
      revenuePerConversion: this.itemRevenuePerConversion,
      revenue: this.itemRevenue,
      profitOrLoss: this.itemProfitOrLoss,
    }

    let sorted;
    sorted = array.map( x => {
      if (typeof(x[column]) == 'undefined') x[column] = sortDictionary[column](x, this.report);
      if (x.c) {
        x.c = this.sortTable(x.c, direction, column);
      }
      return x;
    });

    sorted = sorted.sort( (a, b) => {
      if (direction === 'Down') {
        return isBreakDown ? (b.object[column] - a.object[column]) : (b[column] - a[column]);
      } else {
        return isBreakDown ? (a.object[column] - b.object[column]) : (a[column] - b[column]);
      }
    });
    return sorted;
  };

  private addEmptyFilterOrGroup(type, resp) {
     const item: any = {
      id: resp.id,
      n: resp.name,
      t: type,
      i: null,
      a: null,
      s: resp.special,
      l: 0,
      it: resp.t,
      c: []
    };

    if (type === 'Group') {
      item.e = resp.e;
    }

    this.nestableList.unshift(item);
    this.originalNestableList.unshift(item);
  }

  private renameFilterOrGroup(type, name, id) {
    const editedItem = this.FTWS.findInTree({node: {c: this.nestableList}, childrenKey: 'c', key: 'id', value: id, additionalKey: 't', additionalValue: type});
    if (editedItem) {
      editedItem.n = name;
    }
    const originalEditedItem = this.FTWS.findInTree({node: {c: this.originalNestableList}, childrenKey: 'c', key: 'id', value: id, additionalKey: 't', additionalValue: type});
    if (originalEditedItem) {
      originalEditedItem.n = name;
    }
  }

  private removeFilterOrGroup(type, id) {
    removeInTree({c: this.nestableList}, 'c', 'id', id, 't', type);
    removeInTree({c: this.originalNestableList}, 'c', 'id', id, 't', type);
  }

  isInSpendNoVisits(id) {
    return typeof(this.withSpendNoVisits.find(x => x.id == id)) != 'undefined';
  }

  filterIsInVirtual(item, virtualFilter) {
    if (item.vf != null) return item.vf.includes(virtualFilter.id);
    else return false;
  }

  getReportForItem(item) {
    let report;
    if (this.showTimeframe) {
      report = this.timeframeReport && item ? (item.t == 'g' ? this.timeframeReport.g[item.id] : this.timeframeReport.f[item.id]) : false;
    } else {
      report = this.report && item ? (item.t == 'g' ? this.report.groups[item.id] : this.report.filters[item.id]) : false;
    }
    return report;
  }

  itemAmount(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.a : 0;
  }

  itemConversions(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.c : 0;
  }

  itemClicks(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.k : 0;
  }

  itemImpressions(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.i : 0;
  }

  itemOverlaps(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.o : 0;
  }

  itemVisits(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.e : 0;
  }

  itemRevenue(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.r : 0;
  }

  itemCostPerConversion(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report && report.c != 0 ? report.a / report.c : 0;
  }

  itemConversionRate(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report && report.e != 0 ? report.c / report.e : 0;
  }

  itemRevenuePerConversion(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report && report.c != 0 ? report.r / report.c : 0;
  }

  itemProfitPercent(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report && report.a != 0 ? report.r / report.a : 0;
  }

  itemProfitOrLoss(item, isSort?) {
    const report = isSort? getReportForItem(item, isSort) : this.getReportForItem(item);
    return report ? report.r - report.a : 0;
  }

  private checkIfDemoProject() {
    this.isDemoProject = this.project.identifier === 'DEMO' || this.project.options.other?.demo === true;
  }

  openExternalAdService(item) {
    if (item.i.startsWith('googleads')) {
      const oauth = this.project.oauths.find(o => o.id === item.oi);
      if (!oauth) {
        this.errorDialogService.triggerToastr('Googleads oauth not found', 'Internal error');
        return;
      }

      if (!oauth.settings.ocid) {
        this.router.navigate(
          [this.project.identifier, ComponentNames.settings, SettingsSections.integrations, 'show', item.i],
          { queryParams: { showSettings: item.oi } }
        );
        return;
      }
    }

    const url = `${environment.clientApiUrl}/external/redirect_to_ad_manager/${item.i}/${item.t}/${item.id}`;
    window.open(url);
  }
}
