import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SlideInOutAnimationLTR } from 'src/app/core/animations/slide-in-out';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { Account } from 'src/app/core/models/account.model';
import { Project } from 'src/app/core/models/project.model';
import { SetCurrentComponent } from 'src/app/store/actions/selected.actions';
import { ProjectStateModel } from 'src/app/store/states/project.state';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [SlideInOutAnimationLTR]
})
export class SettingsComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.account) account$: Observable<Account>;

  project: Project;
  account: Account;

  constructor(
    private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new SetCurrentComponent({ currentComponent: ComponentNames.settings}));
    this.project$.subscribe((project) => this.project = project?.project);
    this.account$.subscribe((account) => this.account = account);
  }

  onActivate(event) {
    const appPane = document.querySelector('#app_pane') as HTMLElement;
    appPane.scroll(0,0);
  }

}
