import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupSelectorWithoutSpecials'
})
export class GroupSelectorWithoutSpecialsPipe implements PipeTransform {
  transform(array): any {
    return array?.filter( x => {
        return x.s === null || x.s == 'virtual';
    });
  }
}