<div class="attr-card mt-4">
  <div class="first-purchase-only">
    <div class="row">
      <div class="col-8">
        <p class="title-no-bold" *ngIf="showTitle" >First Purchase Only <i class="fa-solid fa-lock red me-1" *ngIf="!project?.options?.features?.first_purchase_only"></i></p>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <app-toggle-switch
          [isOn]="firstPurchaseOnly"
          (eventEmitter)="toggleFPO($event)"></app-toggle-switch>
      </div>
    </div>
  </div>
</div>
