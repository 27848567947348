import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyService } from 'src/app/core/services/property/property.service';
import { NewFilterModalComponent } from '../../../components/new-filter-modal/new-filter-modal.component';
import { Project } from 'src/app/core/models/project.model';

@Component({
  selector: 'app-event-breakdown',
  templateUrl: './event-breakdown.component.html',
  styleUrls: ['./event-breakdown.component.scss']
})

export class EventBreakdownComponent implements OnInit {
  @Input() events: Array<{name: string, value: number, percentage: number, value_type: string; properties?: any; expanded?: boolean; activeProperty?: number; loading?: boolean;}>; // value_type can be 'revenue' to format it
  @Input() title: string;
  @Input() filterId: number;
  @Input() project: Project;

  maxValue: number=-Infinity;

  hoveredPropertyIndex: number;

  constructor( private propertyService: PropertyService, private modalService: NgbModal,) { }

  ngOnInit() {
    this.events.forEach( x => {
      if (this.maxValue < x.value) this.maxValue = x.value;
    });
  }

  onEventExpand(event) {
    if (event.loading) return;
    if (event.expanded) {
      event.expanded = false;
      return;
    }
    event.loading = true;
    this.propertyService.getEventNameProperty(event.name, this.filterId).subscribe( resp => {
      event.properties = resp.map( x => ({name: x}));
      event.expanded = true;
      event.loading = false;
    });
  } 

  selectProperty(event, index) {
    event.properties.forEach( (x, i) => {
      if (index === i) {
        event.activeProperty = event.activeProperty === i ? null : i;
        if (!event.properties.loading) {
          event.properties[i].loading = true;
          this.propertyService.getEventNamePropertyValues(event, this.filterId, x.name).subscribe( resp => {
            event.properties[i].values = resp.map( x => ({name: x[0], value: x[1]}));
            event.properties[i].loading = false;
          });
        }
      } else x.active = false;
    });
  }

  addEventPropertyFilter(event, property, propValue) {
    const modalRef = this.modalService.open(NewFilterModalComponent, {windowClass: 'slideInDown'});
    modalRef.componentInstance.eventParams = {
      eventName: event,
      property: property,
      propValue: propValue,
    };
  }

}
