import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { catchError, combineLatestWith, map } from 'rxjs/operators';
import { SelectAccountComponent } from 'src/app/modules/settings/integrations/select-account/select-account.component';
import { JustSetProject } from 'src/app/store/actions/project.actions';
import { SetTwitterTokens } from 'src/app/store/actions/settings.actions';
import { environment } from 'src/environments/environment';
import { Integration, IntegrationTypes } from '../../constants/integration';
import { ComponentNames } from '../../enums/component-names';
import { Project } from '../../models/project.model';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { FilterService } from '../filter/filter.service';
import { ProjectService } from '../project/project.service';
import { IntegrationAuthService } from './integration-auth.service';
import { SettingsSections } from '../../enums/settings-sections';
import { CommonComponent } from 'src/app/modules/settings/integrations/explainers/common/common.component';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  integrations: any;
  config: any;

  constructor(
    private modalService: NgbModal,
    private httpClient: HttpClient,
    private auth: IntegrationAuthService,
    private router: Router,
    private store: Store,
    private errorDialogService: ErrorDialogService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private filterService: FilterService
  ) {
    let ads: any = {};
    Object.keys(Integration.get.names[IntegrationTypes.integration]).forEach(x => {
      ads[x] = {
        name: x,
        label: Integration.get.names[IntegrationTypes.integration][x],
      };
    });
    let connectors: any = {};
    Object.keys(Integration.get.names[IntegrationTypes.connector]).forEach(x => {
      connectors[x] = {
        name: x,
        label: Integration.get.names[IntegrationTypes.connector][x],
      };
    });
    let transformers: any = {};
    Object.keys(Integration.get.names[IntegrationTypes.transformer]).forEach(x => {
      transformers[x] = {
        name: x,
        label: Integration.get.names[IntegrationTypes.transformer][x],
      };
    });
    this.integrations = {
      ...ads,
      ...connectors,
      ...transformers
    };

    this.integrations = Integration.get.addConfig(this.integrations);
    this.integrations = Integration.get.addComponents(this.integrations);
  }

  start({integrationName, isOnboarding = null, skipExplainer = null, isTransformer = false}) {
    const step = skipExplainer ? 1 : 0;
    this.check(integrationName).subscribe({next: x => {
      const integration = this.integrations[integrationName];
      switch (integration.config.steps[step]) {
        case 'explainer':
          this.openExplainerOrConnect(integration, null, null, isOnboarding);
          break;
        case 'connect':
          if (isTransformer) {

          } else {
            this.openExplainerOrConnect(integration, step, 'connect', isOnboarding);
          }
          break;
        case 'auth':
          this.auth.authDefault(integration);
          break;
        case 'requestToken':
          this.requestToken(integration);
          break;
        default:
          break;
      }
    }, error: e => this.errorDialogService.integrationFailError(e, integrationName)});
  }

  check(integrationType) {
    const Url = environment.clientApiUrl + '/oauths/check';
    return this.httpClient.post(Url, {type: integrationType});
  }

  requestToken(integration) {
    const callbackUrl = `${window.location.origin}/integration-callback.html?integration=${integration.name}`;
    const Url = integration.config.additionalUrls.requestToken.url;

    return this.httpClient.post(Url, {callback_url: callbackUrl}).subscribe( (x: any) => {
      if (integration.name == 'twitter') {
        this.store.dispatch(new SetTwitterTokens({request_token: x.request_token, request_token_secret: x.request_token_secret}));
        window.location.href = x.authorize_url;
      }
    });
  }

  openExplainerOrConnect(integration, step?, type?, isOnboarding?) {
    const component = integration.component ? integration.component : CommonComponent;
    const modalRef = this.modalService.open(component, {size: 'explainer', windowClass: 'slideInDown', backdrop : 'static', keyboard : false});
    modalRef.componentInstance.integration = integration;
    modalRef.componentInstance.isOnboarding = isOnboarding;
    if (step) modalRef.componentInstance.step = step;
    if (type) modalRef.componentInstance.type = type;
    if (type == 'connect') {
      modalRef.result.then(result => {
        if (result == 'close') {
          this.removeParamsFromUrl(integration);
        };
      });
    }
  }

  continueAuth(params) {
    const thisStep = params.step ? parseInt(params.step) : 1;
    const integr = this.integrations[params.integration];
    const {integration, step, continueType, ...additionalParams } = params;
    switch (integr.config.steps[thisStep]) {
      case 'connect':
        if (integr.config.type == IntegrationTypes.transformer) {
          this.connectTransformer(integr);
        } else {
          this.openExplainerOrConnect(integr, thisStep, 'connect');
        }
        break;
      case 'requestToken':
        this.requestToken(integr);
        break;
      case 'token':
        break;
      case 'auth':
        this.auth.authDefault(integr, additionalParams);
        break;
      default:
        break;
    }
  }

  continue(params, step?, integr?) {
    let stepToGo = step ? step : params.step ? parseInt(params.step) : 0;
    const integration = integr ? integr : this.integrations[params.integration];
    switch(integration.config.continue[stepToGo]) {
      case 'check':
        this.check(integration.name).subscribe( x => {
          this.continue(params, stepToGo+1, integration);
        }, e => this.errorDialogService.integrationFailError(e, integration.name));
        break;
      case 'save':
        this.save(integration, params, stepToGo);
        break;
      case 'selectAccount':
        if (integration.oauth.accounts == null || typeof(integration.oauth.accounts) == 'undefined' || integration.oauth.accounts.length == 0) {
          this.continue(params, stepToGo+1, integration);
        } else {
          this.openSelectedAccountOrFinish(integration, stepToGo, 'select');
        }
        break;
      case 'finish':
        this.openSelectedAccountOrFinish(integration, stepToGo, 'finish');
        break;
      default:
        this.save(integration, params, stepToGo);
        break;
    }
  }

  save(integration, params, step?) {
    const Url = environment.clientApiUrl + integration.config.oauthUrl;
    if (integration.name == 'twitter') {
      const twitterTokens = this.store.selectSnapshot(state => state.settings.twitterTokens);
      params.request_token = twitterTokens.request_token;
      params.request_token_secret = twitterTokens.request_token_secret;
    }

    this.httpClient.post(Url, params).subscribe( (oauth: any) => {
      integration.oauth = oauth;
      this.integrations[integration.name] = integration;
      if (typeof(oauth.error) != 'undefined') {
        const isOnboarding = this.store.selectSnapshot(state => state.base.isOnboarding);
        const project = this.store.selectSnapshot(state => state.project.project);
        if (!isOnboarding) {
          this.router.navigate([project.identifier, ComponentNames.settings, SettingsSections.integrations, 'show', integration.name]);
          setTimeout(() =>
            this.toastr.warning(
              oauth.error,
              ``,
              {disableTimeOut: true, enableHtml: true}
            )
          );
        } else {
          this.projectService.getProjectInfo(true).subscribe(() => {
            setTimeout(() =>
              this.toastr.warning(
                oauth.error,
                ``,
                {disableTimeOut: true, enableHtml: true}
              )
            );
          });
        }
      } else {
        this.projectService.getProjectInfo(true).subscribe();
        this.continue(oauth, step+1, integration);
      }
    });
  }

  connectTransformer(integration) {
    const Url = `${environment.clientApiUrl}${integration.config.oauthUrl}`;
    return this.httpClient.post(Url, {type: integration.name}).pipe(
      catchError(e => {
        this.errorDialogService.integrationFailError(e, integration.name)
        return e;
      })
    ).subscribe((oauth: any) => {
      integration.oauth = oauth;
      this.integrations[integration.name] = integration;
      if (typeof(oauth.error) != 'undefined') {
        const isOnboarding = this.store.selectSnapshot(state => state.base.isOnboarding);
        const project = this.store.selectSnapshot(state => state.project.project);
        if (!isOnboarding) {
          this.router.navigate([project.identifier, ComponentNames.settings, SettingsSections.integrations, 'show', integration.name]);
          setTimeout(() =>
            this.toastr.warning(
              oauth.error,
              ``,
              {disableTimeOut: true, enableHtml: true}
            )
          );
        } else {
          this.projectService.getProjectInfo(true).subscribe(() => {
            setTimeout(() =>
              this.toastr.warning(
                oauth.error,
                ``,
                {disableTimeOut: true, enableHtml: true}
              )
            );
          });
        }
      } else {
        this.projectService.getProjectInfo(true).subscribe();
        this.openSelectedAccountOrFinish(integration, null, 'finish');
      }
    });
  }

  openSelectedAccountOrFinish(integration, step?, type?) {
    const isOnboarding = this.store.selectSnapshot(state => state.base.isOnboarding);
    const component = type == 'select' ? SelectAccountComponent : CommonComponent;
    const modalRef = this.modalService.open(component, {size: 'lg', windowClass: 'slideInDown', backdrop : 'static', keyboard : false});
    modalRef.componentInstance.integration = integration;
    modalRef.componentInstance.type = type == 'select' && isOnboarding ? 'onboarding' : type;
    modalRef.componentInstance.step = step;
    modalRef.result.then(result => {
      if (result.integration) {
        this.continue({integration: result.integration}, result.step);
      } else if (type == 'finish') {
        if (!isOnboarding) {
          const project : Project = this.store.selectSnapshot( state => state.project.project);
          var intName = integration.name == 'facebook4' ? 'facebook4' : integration.name;
          intName = integration.name == 'msads' ? 'msads' : intName;
          intName = integration.name == 'linkedin2' ? 'linkedin' : intName;
          this.router.navigate([project.identifier, ComponentNames.settings, SettingsSections.integrations, 'show', intName]);
          this.filterService.getFilterTree().subscribe();
        } else {
          this.projectService.getProjectInfo(true).subscribe();
        }
      };
    });
  }

  removeParamsFromUrl(integration) {
    const type = typeof(Integration.get.names[IntegrationTypes.integration][integration.name]) != 'undefined' ? 'ads'
                : typeof(Integration.get.names[IntegrationTypes.connector][integration.name]) != 'undefined' ? 'conversions'
                : 'transformers';
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { type: type}
    });
  }

  deleteOauth(oauth) {
    const Url = `${environment.clientApiUrl}/oauths/${oauth.id}`;

    let project: Project = this.store.selectSnapshot(state => state.project.project);

    return this.httpClient.delete(Url).pipe(map( x => {
      project.oauths = project.oauths.filter(x => x.id != oauth.id);
      this.store.dispatch(new JustSetProject(project));
      return x;
    }));
  }

  disconnectOauth(oauth) {
    const Url = `${environment.clientApiUrl}/oauths/${oauth.id}/disconnect`;

    return this.httpClient.post(Url, {}).pipe(map( x => {
      this.projectService.getProjectInfo(true).subscribe();
      return x;
    }));
  }

  getBigcommerceOrderStatuses(id) {
    const Url = `${environment.clientApiUrl}/integrations/bigcommerce/${id}/order_statuses`;

    return this.httpClient.get(Url);
  }

  updateOauth(oauthID, settings) {
    const Url = `${environment.clientApiUrl}/oauths/${oauthID}`;

    return this.httpClient.post(Url, {oauth_id: oauthID, settings: settings});
  }

  getFacebookForm(id) {
    const Url = `${environment.clientApiUrl}/integrations/facebook3/${id}/lead_forms`;

    return this.httpClient.get(Url);
  }

  enableFacebookForm(params, enable?) {
    const Url = `${environment.clientApiUrl}/integrations/facebook3/${params.id}/lead_forms/${enable ? 'enable' : 'disable'}`;

    return this.httpClient.post(Url, params);
  }

  setProjectView(oauthID, params) {
    const Url = `${environment.clientApiUrl}/integrations/segment_reverse/${oauthID}/set_project_view`;

    return this.httpClient.post(Url, params);
  }

  getSalesForceAdditionalFields(oauthId) {
    const OpportunityUrl = `${environment.clientApiUrl}/oauths/${oauthId}/salesforce/fields/Opportunity`;
    const LeadUrl = `${environment.clientApiUrl}/oauths/${oauthId}/salesforce/fields/Lead`;
    const ContactAdditionalFields= `${environment.clientApiUrl}/oauths/${oauthId}/salesforce/fields/Contact`;

    return this.httpClient.get(OpportunityUrl).pipe(
      combineLatestWith(this.httpClient.get(LeadUrl)),
      combineLatestWith(this.httpClient.get(ContactAdditionalFields)),
      map( ([[opportunity, lead], contact]) => {
        return {opportunity, lead, contact};
      })
    );
  }

  getHubspotFields(oauthId) {
    const url = `${environment.clientApiUrl}/oauths/${oauthId}/hubspot/fields/deals`;
    return this.httpClient.get(url);
  }

  getSettingsConfig(integrationName) {
    const Url = `${environment.clientApiUrl}/v2/oauths/schema/${integrationName}`;

    return this.httpClient.get(Url);
  }

  getPipedrivePersonAdditionalFields(oauthId) {
    const Url = `${environment.clientApiUrl}/oauths/${oauthId}/pipedrive/fields/person`;

    return this.httpClient.get(Url);
  }

  getJobLogs(oauthId){
    const Url = `${environment.clientApiUrl}/v2/oauths/${oauthId}/logs`;

    return this.httpClient.get(Url);
  }

}
