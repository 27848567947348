import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { Select, Store } from '@ngxs/store';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetTimezone } from 'src/app/store/actions/project.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';

import timezones from 'timezones-list';


@Component({
  selector: 'app-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss']
})
export class TimezoneSelectorComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() showTitle = true;
  @Input() isInModal = false;

  project: Project;
  timezones = timezones.map(x => x.tzCode);
  selectedTimezone;

  constructor(private projectService: ProjectService, private store: Store) { }

  ngOnInit(): void {
    this.project$.subscribe(x => {
      this.selectedTimezone = x.project?.timezone;
    });
  }

  groupTimezones(zone) {
    return (zone.match(/^([a-z]+)\//i)?.[1] || 'Other');
  };

  selectTimezone() {
    this.projectService.setAttribute('timezone', this.selectedTimezone).subscribe(() => {
      this.store.dispatch(new SetTimezone(this.selectedTimezone));
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

}
