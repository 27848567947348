<div class="modal-header">
  <h4 class="modal-title"> {{ type == 'integration' ? 'Integration errors' : 'No attributed revenue (visits)' }} for "{{ errorObjectName }}"</h4>
  <a href="https://docs.attributionapp.com/docs/common-facebook-errors#facebook-ad-errors" target="_blank"><i class="fa-solid fa-circle-question white" ngbTooltip="Learn More about permissions error" placement="left"></i></a>
</div>
<div class="modal-body">
  <app-loader *ngIf="integrationsAreLoading; else loaded" [position]="'relative'" [color]="'green'" [backgroundColor]="'white'">
  </app-loader>
  <ng-template #loaded>
    <ul *ngIf="!oauth" class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [class.active]="type == 'integration'" (click)="switchTabTo('integration')">Integration Errors</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="type == 'spend_no_visit'" (click)="switchTabTo('spend_no_visit')">No-Visit Errors</a>
      </li>
    </ul>
    <p *ngIf="type == 'spend_no_visit'">This channel/filter has marketing spend but no attributed revenue or visits. An error with the tracking integration or setup may be occurring. See our <a href="https://docs.attributionapp.com/docs/common-facebook-errors" target="_blank">FAQ document</a> for common issues or please contact us <a href="mailto:help@attributionapp.com">here</a>.</p>
    <table class="table break-all" style="margin-bottom: 0">
      <thead>
        <tr>
          <th>Message</th>
        </tr>
      </thead>
      <tbody *ngFor="let key of objectKeys(groupedErrors)">
        <tr (click)="toggleSelected(key)">
          <td class="app_sidebar_tr_link"><i class="fa-solid fa-chevron-right" [ngClass]="{'fa-chevron-down': isSelected(key), 'fa-chevron-right': !isSelected(key)}"></i>
            <strong> {{ groupedErrors[key].length }} &times;</strong> {{ key }}
          </td>
        </tr>

        <tr *ngFor="let error of (isSelected(key) ? groupedErrors[key] : [])" class="dim-background">
          <td>
            {{ error.name }}
            <i *ngIf="error.ads_manager_link" class="fa-solid fa-arrow-up-right-from-square muted" aria-hidden="true"></i>
            <a *ngIf="error.ads_manager_link" href="{{error.ads_manager_link}}" class='ads-manager-link' target="_blank">
              Ads manager
            </a>
            <span class="pull-right muted">{{ error.failed_at | formattedMoment: 'ff' }}</span>
            <br>
            <small class="medium-dark">{{ error.error_message }}</small>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>
<div class="modal-footer space-between">
  <a class="caps red" (click)="clearErrors()">Clear Errors</a>
  <button class="btn btn-success" (click)="activeModal.close('Close click')">Close</button>
</div>
