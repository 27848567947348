import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Project } from '../models/project.model';
import { ProjectService } from '../services/project/project.service';
import { AccountService } from '../services/account/account.service';
import { FilterService } from '../services/filter/filter.service';
import { Account } from '../models/account.model';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { SettingsStateModel } from 'src/app/store/states/settings.state';
import { SetCurrentComponent, SetSettingSection } from 'src/app/store/actions/selected.actions';
import { AuthService } from '../services/auth/auth.service';
import { SettingsSections } from '../enums/settings-sections';
import { ComponentNames } from '../enums/component-names';
import { isShopifySegment } from '../helpers/is-shopify-segment';

@Injectable()
export class AuthAndStateCheckGuard  {

    constructor(
      private store: Store,
      private router: Router,
      private projectService: ProjectService,
      private accountService: AccountService,
      private filterService: FilterService,
      private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const isAuthenticated = this.store.selectSnapshot(state => state.base.isAuthenticated) || this.authService.isAuthenticated;
      if (!isAuthenticated) {
        if (route.fragment) {
          const otp = route.fragment.split('otp_token=')[1];
          this.router.navigate([ComponentNames.login], { queryParams: { otp_token: otp}});
        } else {
          this.router.navigate([ComponentNames.login], { queryParams: { ...route.queryParams, returnUrl: routerState.url }});
        }
        return false;
      }

      const ProjectState = this.store.selectSnapshot<Project>( state => state.project.project);
      const AccountState = this.store.selectSnapshot<Account>( state => state.account);
      const FilterTreeState = this.store.selectSnapshot( state => state.filterTree.filterTree);
      const SelectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected);
      const SettingsState = this.store.selectSnapshot<SettingsStateModel>( state => state.settings);

      if ((route.routeConfig.path != ComponentNames.account && SelectedState?.settingSection != SettingsSections.billing) && AccountState?.partner == 'bigcommerce' && SettingsState?.subscription?.status == 'past_due' && typeof(SettingsState?.card?.last4) == 'undefined') {
        this.store.dispatch([new SetCurrentComponent({currentComponent: ComponentNames.account}), new SetSettingSection(SettingsSections.billing)]);
        this.router.navigate([ComponentNames.account, SettingsSections.billing]);
        return false;
      }
      
      if (isShopifySegment(AccountState, ProjectState) && route.routeConfig.path !== ':project_identifier/settings') {
        this.router.navigate([ProjectState.identifier, ComponentNames.settings, SettingsSections.overview]);
        return false;
      }

      if (ProjectState == null) {
        if (!this.projectService.inProgress) {
          this.projectService.getProjectInfo().subscribe();
        }
      }

      if (AccountState.id == null) {
        if (!this.accountService.inProgress) {
          this.accountService.getAccountInfo().subscribe();
        }
      }

      if (FilterTreeState == null) {
        if (!this.filterService.inProgress) {
          this.filterService.getFilterTree().subscribe();
        }
      }

      return true;
    }
}
