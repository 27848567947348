@if (style === 'old') {
  <div class="data__status">
    <div class="mb-4">
      <p class="title-no-bold">Data Collection Status:</p>
      <div class="small medium-dark">The check boxes below indicate that data has been tracked at least once in the past week</div>
    </div>
    <ul class="list-unstyled">
      <li *ngIf="dataStatus?.report_refreshed_at">
        <span class="report-refreshed-at">
          Report last refreshed {{
            dataStatus.report_refreshed_at
            | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000
          }}
        </span>
      </li>
      <li>
        <app-status-icon [status]="dataStatus?.pageview" [isLoaded]="usageIsLoaded"></app-status-icon>&nbsp;
        <a (click)="selectInstallationTab(0)">Pageviews collecting</a>
        <span *ngIf="dataStatus?.pageview" class="ms-2 time-ago" placement="top" ngbTooltip="{{dataStatus.pageview}}">{{ dateWithTimezone(dataStatus.pageview) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000 }}</span>
      </li>
      <li>
        <app-status-icon [status]="dataStatus?.identify" [isLoaded]="usageIsLoaded"></app-status-icon>&nbsp;
        <a (click)="selectInstallationTab(1)">Identifying visitors</a>
        <span *ngIf="dataStatus?.identify" class="ms-2 time-ago" placement="top" ngbTooltip="{{dataStatus.identify}}">{{ dateWithTimezone(dataStatus.identify) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000 }}</span>
      </li>
      <li>
        <ul class="list-unstyled">
          <li>
            <app-status-icon [status]="dataStatus?.user_id" [isLoaded]="usageIsLoaded"></app-status-icon>&nbsp;
            <a (click)="selectInstallationTab(1)"><em>userId</em> provided</a>
            <span *ngIf="dataStatus?.user_id" class="ms-2 time-ago" placement="top" ngbTooltip="{{dataStatus.user_id}}">{{ dateWithTimezone(dataStatus.user_id) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000 }}</span>
          </li>
          <li>
            <app-status-icon [status]="dataStatus?.created_at" [isLoaded]="usageIsLoaded"></app-status-icon>&nbsp;
            <a (click)="selectInstallationTab(1)">User <em>createdAt</em></a>
            <span *ngIf="dataStatus?.created_at" class="ms-2 time-ago" placement="top" ngbTooltip="{{dataStatus.created_at}}">{{ dateWithTimezone(dataStatus.created_at) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000 }}</span>
          </li>
          <li *ngIf="project?.company_based_attribution || dataStatus?.company">
            <app-status-icon [status]="dataStatus?.company" [isLoaded]="usageIsLoaded"></app-status-icon>&nbsp;
            <a (click)="selectInstallationTab(1)">Company tracking</a>
            <span *ngIf="dataStatus?.company" class="ms-2 time-ago" placement="top" ngbTooltip="{{dataStatus.company}}">{{ dateWithTimezone(dataStatus.company) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000 }}</span>
          </li>
        </ul>
      </li>
      <li>
        <app-status-icon [status]="dataStatus?.track" [isLoaded]="usageIsLoaded"></app-status-icon>&nbsp;
        <a (click)="selectInstallationTab(2)">Events tracking</a>
        <span *ngIf="dataStatus?.track" class="ms-2 time-ago" placement="top" ngbTooltip="{{dataStatus.track}}">{{ dateWithTimezone(dataStatus.track) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000 }}</span>
      </li>
      <li>
        <ul class="list-unstyled">
          <li>
            <app-status-icon [status]="dataStatus?.revenue" [isLoaded]="usageIsLoaded"></app-status-icon>&nbsp;
            <a (click)="selectInstallationTab(2)">Revenue tracking</a>
            <span *ngIf="dataStatus?.revenue" class="ms-2 time-ago" placement="top" ngbTooltip="{{dataStatus.revenue}}">{{ dateWithTimezone(dataStatus.revenue) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:2}, suffix: ' ago' } : 60000 }}</span>
          </li>
        </ul>
      </li>
      <li *ngIf="project?.first_visit_at">
        First visit tracked on
        <code>{{ project.first_visit_at | date }}</code>
      </li>
    </ul>
    <p>Check out Attribution's developer docs <a href="https://docs.attributionapp.com/" target="blank">here</a>.</p>
  </div>
} @else {
  <div class="d-flex h-100 flex-column justify-content-between">
    <div>
      <div class="row mb-4">
        <div class="col-6">
          <app-status-icon [status]="dataStatus?.pageview" [isLoaded]="usageIsLoaded" [style]="'v2'"></app-status-icon>&nbsp;
          <span>Pageviews collecting</span>
        </div>
        <div class="col-6 text-end">
          <span *ngIf="dataStatus?.pageview" class="medium-new" placement="top" ngbTooltip="{{dataStatus.pageview}}">{{ dateWithTimezone(dataStatus.pageview) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'], delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-6">
          <app-status-icon [status]="dataStatus?.identify" [isLoaded]="usageIsLoaded" [style]="'v2'"></app-status-icon>&nbsp;
          <span>Identifying visitors</span>
        </div>
        <div class="col-6 text-end">
          <span *ngIf="dataStatus?.identify" class="medium-new" placement="top" ngbTooltip="{{dataStatus.identify}}">{{ dateWithTimezone(dataStatus.identify) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
        </div>
      </div>
      <div class="ms-4">
        <div class="row mb-4">
          <div class="col-6">
            <app-status-icon [status]="dataStatus?.user_id" [isLoaded]="usageIsLoaded" [style]="'new'"></app-status-icon>&nbsp;
            <span><em>userId</em> provided</span>
          </div>
          <div class="col-6 text-end">
            <span *ngIf="dataStatus?.user_id" class="medium-new" placement="top" ngbTooltip="{{dataStatus.user_id}}">{{ dateWithTimezone(dataStatus.user_id) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-6 ">
            <app-status-icon [status]="dataStatus?.created_at" [isLoaded]="usageIsLoaded" [style]="'v2'"></app-status-icon>&nbsp;
            <span>User <em>createdAt</em></span>
          </div>
          <div class="col-6 text-end">
            <span *ngIf="dataStatus?.created_at" class="medium-new" placement="top" ngbTooltip="{{dataStatus.created_at}}">{{ dateWithTimezone(dataStatus.created_at) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
          </div>
        </div>
        @if (!isShopifySegment) {
          <div class="row mb-4">
            <div class="col-6">
              <app-status-icon [status]="dataStatus?.company" [isLoaded]="usageIsLoaded" [style]="'v2'"></app-status-icon>&nbsp;
              <span>Company tracking</span>
            </div>
            <div class="col-6 text-end">
              <span *ngIf="dataStatus?.company" class="medium-new" placement="top" ngbTooltip="{{dataStatus.company}}">{{ dateWithTimezone(dataStatus.company) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
            </div>
          </div>
        }
      </div>
      <div class="row mb-4">
        <div class="col-6">
          <app-status-icon [status]="dataStatus?.track" [isLoaded]="usageIsLoaded" [style]="'v2'"></app-status-icon>&nbsp;
          <span>Events tracking</span>
        </div>
        <div class="col-6 text-end">
          <span *ngIf="dataStatus?.track" class="medium-new" placement="top" ngbTooltip="{{dataStatus.track}}">{{ dateWithTimezone(dataStatus.track) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <app-status-icon [status]="dataStatus?.revenue" [isLoaded]="usageIsLoaded" [style]="'v2'"></app-status-icon>&nbsp;
          <span>Revenue tracking</span>
        </div>
        <div class="col-6 text-end">
          <span *ngIf="dataStatus?.revenue" class="medium-new" placement="top" ngbTooltip="{{dataStatus.revenue}}">{{ dateWithTimezone(dataStatus.revenue) | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
        </div>
      </div>
    </div>
    <div>
      @if (!isShopifySegment) {
        <div class="attr-card__footer">
          <div *ngIf="project?.first_visit_at" class="medium-new mb-2">
            <i class="fa-solid fa-fw fa-calendar-days me-2"></i>
            First visit tracked on
            {{ project.first_visit_at | date }}
          </div>
          <div *ngIf="dataStatus?.report_refreshed_at" class="medium-new">
            <i class="fa-solid fa-fw fa-clock-rotate-left me-2"></i>
            Report last refreshed {{
              dataStatus.report_refreshed_at
              | timeAgo: {humanizeOpts: {units: ['mo', 'd', 'h', 'm'],delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000
            }}
          </div>
        </div>
      }
    </div>
  </div>
}
