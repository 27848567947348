export const environment = {
  envName: 'production',
  isProd: true,
  production: true,
  clientApiUrl: 'https://api.attributionapp.com',
  reportingApiUrl: 'https://reporting-api.attributionapp.com',
  assetPath: '',
  oldDashboardUrl: 'https://dashboard.attributionapp.com/v1',
  rollbarAccessToken: '887a3d0b81b344a0949770c0e3e58427',
  segmentIoWriteKey: '9qytlu3b6x',
  geoHost: 'https://api.ipstack.com',
  geoAccessKey: '152e5f53c8c4c58f19c7cb4e140503d6',
  attributionJsPath: "attribution.js",
  scriptHost: "https://scripts.attributionapp.com/",
  trackingHost: "https://track.attributionapp.com",
  stripePublicKey: "pk_live_dzmy4BeK9IrO3sbbt2WhUdfO",
  StripeClientId: "ca_9ww5ftgThgIVRVxpSYyekMl2gHfAACjq",
  integrations: {
    adroll: {
      client_id: "Y3uWM08RKHiPtCFThkKDqotzzX9nONAu",
    },
    adwords: {
      client_id: "31121452857-v7fpilr7mvf734fp5dgbgt9eqclo44ce.apps.googleusercontent.com"
    },
    googleads: {
      client_id: "31121452857-v7fpilr7mvf734fp5dgbgt9eqclo44ce.apps.googleusercontent.com"
    },
    googleads2: {
      client_id: "31121452857-v7fpilr7mvf734fp5dgbgt9eqclo44ce.apps.googleusercontent.com"
    },
    bigcommerce: {
      client_id: "283bte73ivs9jxdeojav7myb16o0i4p"
    },
    bing: {
      client_id: "efecf74d-1431-42c8-a802-7d4a5e630ddc"
    },
    msads: {
      client_id: "efecf74d-1431-42c8-a802-7d4a5e630ddc"
    },
    facebook3: {
      client_id: "994578317235354"
    },
    facebook4: {
      client_id: "994578317235354"
    },
    facebook_leads: {
      client_id: "994578317235354"
    },
    getbase: {
      client_id: "68c2a5d7fa763cd36168cf82d4426567e31562763d2a7bfa89467ba93ee5ce83"
    },
    hubspot: {
      client_id: "ba014e2e-7fee-4ad0-9751-2d540beddaee"
    },
    linkedin: {
      client_id: "86wuol67xxjegv"
    },
    linkedin2: {
      client_id: "86wuol67xxjegv"
    },
    pinterest: {
      client_id: "1501494"
    },
    sage_accounting: {
      client_id: '0f7f7754-bcc1-411f-8d1f-e707b17a0233/127fe2bb-de3a-4293-b712-a080456fa573'
    },
    salesforce: {
      client_id: "3MVG9zlTNB8o8BA0HMcy.5nj73S6O8gHLidAGoGDcKmqSafFwCOtZUDqPKUTOHIwj2nwNkkjp_.NfqsFgXr8W"
    },
    stripe: {
      client_id: "ca_9ww5ftgThgIVRVxpSYyekMl2gHfAACjq"
    },
    quora: {
      client_id: "e097e74a212a4f04915060944be9857a"
    },
    pipedrive: {
      client_id: "224aaf3076a618f2"
    },
    shopify: {
      client_id: '9f65cc7736eed0a147f87dd22b6ad07d'
    },
    zoho: {
      client_id: "1000.15MUHTQEHY7TJFQPAZFO79Q1AYCTJH"
    },
  }
};
