import { Injectable } from '@angular/core';
import {webSocket} from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveDebugService {

  constructor() { }

  openAndListen(ws) {
    let endpoint = ws;
    if (environment.envName == 'development') endpoint = ws.replace('localhost', 'local.attributionapp.com');
    return webSocket(endpoint);
  }
}
