import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash-es';

@Component({
  selector: 'app-paths-aggregated-row',
  templateUrl: './paths-aggregated-row.component.html',
  styleUrls: ['../paths.component.scss', './paths-aggregated-row.component.scss']
})
export class PathsAggregatedRowComponent implements OnChanges {
  @Input() path: any;
  @Input() summary: any;
  @Input() labels: string;
  @Input() conversionEventName: string;
  @Input() touchpoints: [];
  @Output() onSelected = new EventEmitter();


  touchpointArray = [];

  ngOnChanges(changes: SimpleChanges) {
    const currentArrayIds = changes.path?.currentValue?.touchpoints?.map(x => x.id);
    const previousArrayIds = changes.path?.previousValue?.touchpoints?.map(x => x.id);
    if (!isEqual(currentArrayIds, previousArrayIds)) {
      this.touchpointArray = cloneDeep(this.path.touchpoints);
      this.touchpointArray.forEach( (y, i) => {
        const calculateSameInRow = (array, item, index) => {
          let sameInRow = 0;
          if (item.id != null && item.id == array[index+1]?.id) {
            sameInRow = 1 + calculateSameInRow(array, item, index+1);;
          }
          return sameInRow;
        };
        const same = calculateSameInRow(this.touchpointArray, y, i);
        this.touchpointArray.splice(i+1, same);
        if (same > 0) y.name = `${y.name} x${same+1}`;
      });
    }
  }

  toggleSelected(id) {
    this.onSelected.emit(id);
  }

}
