import { State, Action, StateContext, Store } from '@ngxs/store';

import { Project, View } from 'src/app/core/models/project.model';
import {
  SetProject,
  UpdateAttributionModel,
  UpdateConversionEvent,
  ClearProject,
  UpdateProjectInfo,
  SetTrafficOption,
  SetCutoffEvent,
  SetDisregardExistingUsers,
  SetIncludeOnlyUsersRegisteredInDaterange,
  SetCompanyBasedAttribution,
  SetPBR,
  SetCurrency,
  SetCurrencySymbol,
  SetTimezone,
  SetProjectName,
  SetAttributionType,
  SetLookbackWindow,
  SetProjectIsLoading,
  JustSetProject,
  ClearOauthErrors,
  SetProjectNextIdentifier,
  SetProjectOptions,
  SetTimeframeAttributionType,
  SetProjectSettings,
  SetAggregationPeriod,
  CreateNewProjectView,
  DeleteProjectView,
  UpdateProjectView,
  SetAttributesFromView,
  SetCurrentViewId,
  SetCurrentViewParams,
  SetViewRevenueGroupId,
  SetViewDaterange,
  HideChannel,
  HideFilter,
  UnhideFilterChannels,
  UpdateViewProperty,
  UpdateViewPropertyValue
} from '../actions/project.actions';
import { SetAttributionModel, SetOverviewSettings, CloseProperties, ClearAggrPeriodReports, SetChosenDates, SetRevenueGroupAndPreset, ToggleProperties } from '../actions/selected.actions';
import { AttributionModels } from 'src/app/core/constants/attribution-models';
import { SetRefreshState } from '../actions/base.actions';
import { SetEventPropertyKey, ClearProperties, SetEventPropertyValue } from '../actions/properties.action';
import { Injectable } from '@angular/core';
import { ClearPrevSummary } from '../actions/channel-performance.actions';
import { BaseService } from 'src/app/core/services/base/base.service';
import { ViewsService } from 'src/app/core/services/views/views.service';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { SubscriptionService } from 'src/app/core/services/subscription/subscription.service';
import { ProductService } from 'src/app/core/services/product/product.service';
import { CardService } from 'src/app/core/services/card/card.service';
import { AccessService } from 'src/app/core/services/access/access.service';
import { ActivatedRoute } from '@angular/router';
import { SelectedStateModel } from './selected.state';
import { ClearUsages } from '../actions/settings.actions';

export interface ProjectStateModel {
    project: Project;
    projectIsLoading: boolean;
    nextIdentifier: String;
    currentViewParams: View;
}

@State<ProjectStateModel>({
  name: 'project',
  defaults: {
    project: null,
    projectIsLoading: false,
    nextIdentifier: null,
    currentViewParams: null
  }
})
@Injectable()
export class ProjectState {
  constructor(
    private store: Store,
    private baseService: BaseService,
    private viewService: ViewsService,
    private filterService: FilterService,
    private subscriptionService: SubscriptionService,
    private productService: ProductService,
    private cardService: CardService,
    private accessService: AccessService,
    private route: ActivatedRoute) {}

  setProperties(view: View, shouldUpdateView?: boolean) {
    this.store.dispatch(new ClearProperties()).subscribe(() => {
      if (view.property_key) {
        this.store.dispatch(new SetEventPropertyKey({key: view.property_key, shouldUpdateView: shouldUpdateView})).subscribe(() => {
          if (view.property_value) {
            this.store.dispatch(new SetEventPropertyValue({value: view.property_value, shouldUpdateView: shouldUpdateView}));
          }
        });
      }
    });
  }

  @Action(SetProject)
  SetProject(ctx: StateContext<ProjectStateModel>, { payload }: SetProject) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      projectIsLoading: false,
      project: payload,
      nextIdentifier: null
    });

    this.store.dispatch([
      new CloseProperties(),
      new ClearProperties(),
      new SetOverviewSettings(payload.widgets),
      new SetRefreshState(payload.pending_refresh ? 'pending' : 'done'),
      new ClearPrevSummary(),
      new ClearUsages(),
    ]);

    const currentView: View = payload.views[payload.current_view_id] || payload.views?.['default'];

    if (currentView) {
      const AttributionModel = AttributionModels.find( x => {
        return x.name === currentView.attribution_model;
      });

      let dates;
      if (currentView.daterange_preset.includes(',')) {
        dates = {
          start: currentView.daterange_preset.split(',')[0],
          end: currentView.daterange_preset.split(',')[1]
        };
      } else {
        const presets = this.baseService.getPresets(true);
        dates = presets.find( x => x.label === currentView.daterange_preset) || presets[0];
      }

      this.store.dispatch(new SetCurrentViewParams(currentView)).subscribe( () => {
        let attributionTypes = ['forward'];
        const attr_types = payload?.options?.attribution_types;
        if (typeof(attr_types) != 'undefined' && attr_types && attr_types.length > 0) {
          attributionTypes = attr_types;
        } else {
          const isAttrType = payload?.options?.features?.attribution_type;
          if (isAttrType && typeof(isAttrType) != 'undefined') {
            if (isAttrType) {
              attributionTypes = ['forward', 'backward'];
            }
          }
        }

        const selectAttributionType = (type: string) => {
          if (currentView?.attribution_type != type) {
            this.store.dispatch(new SetAttributionType(type));
          }
          if (type == 'backward' &&   currentView.lookback_window == null) {
            this.store.dispatch(new SetLookbackWindow(30));
          }
        }

        if (currentView.attribution_type) {
          if (attributionTypes.includes('backward')) {
            selectAttributionType('backward');
          } else selectAttributionType('forward');
        }

        let revGroup = null;
        if (currentView?.revenue_group_id === 0) {
          revGroup = {name: 'Conversion Event', type: 'conversion-event', id: 0};
        } else {
          if (currentView?.revenue_group_id) {
            revGroup = payload.settings?.revenue_groups?.find( g => g.id === currentView?.revenue_group_id) || null;
          } else if (payload.settings?.default_revenue_group) {
            revGroup = payload.settings?.revenue_groups?.find(x => x.id == payload.settings?.default_revenue_group) || null;
          }
        }

        const actionArray: any = [
          new SetAttributionModel({ attributionModel: AttributionModel, fromProjectSettings: true}),
          new SetRevenueGroupAndPreset({revenueGroup: revGroup, preset: currentView.daterange_preset}),
        ];

        const DatesAreInQueryParams = this.route.snapshot.queryParams.start && this.route.snapshot.queryParams.end;
        if (!DatesAreInQueryParams) {
          actionArray.push(new SetChosenDates({
            start: dates.start,
            end: dates.end,
          }));
        }

        if (currentView.property_key) {
          this.setProperties(currentView);
          actionArray.push(new ToggleProperties());
        }

        this.store.dispatch(actionArray).subscribe(() => {
          this.filterService.getFilterTree().subscribe();
          this.subscriptionService.get();
          this.productService.get();
          this.cardService.get();
          this.accessService.get();
        });
      });
    }
  }

  @Action(SetAttributesFromView)
  SetAttributesFromView(ctx: StateContext<ProjectStateModel>, { payload }: SetAttributesFromView) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      currentViewParams: payload
    });
    let dates;
    if (payload.daterange_preset.includes(',')) {
      dates = {
        start: payload.daterange_preset.split(',')[0],
        end: payload.daterange_preset.split(',')[1]
      };
    } else {
      const presets = this.baseService.getPresets(true);
      dates = presets.find( x => x.label === payload.daterange_preset) || presets[0];
    }
    let revGroup;
    if (payload?.revenue_group_id === 0) {
      revGroup = {name: 'Conversion Event', type: 'conversion-event', id: 0};
    } else {
      revGroup = getState.project.settings?.revenue_groups?.find( g => g.id === payload?.revenue_group_id) || null;
    }
    const actionArray: any = [
      new SetRevenueGroupAndPreset({
        revenueGroup: revGroup,
        preset: payload.daterange_preset
      })
    ];
    const DatesAreInQueryParams = this.route.snapshot.queryParams.start && this.route.snapshot.queryParams.end;
    if (!DatesAreInQueryParams) {
      actionArray.push(new SetChosenDates({
        start: dates.start,
        end: dates.end,
      }));
    }
    this.store.dispatch(actionArray);
    const isPropertiesOpened = this.store.selectSnapshot<SelectedStateModel>(state => state.selected).isPropertiesOpened;
    if ((payload.property_key && !isPropertiesOpened) || (!payload.property_key && isPropertiesOpened)) {
      this.store.dispatch(new ToggleProperties()).subscribe(() => {
        this.setProperties(payload, true);
      });
    } else {
      this.setProperties(payload);
    }
  }

  @Action(SetCurrentViewParams)
  SetCurrentViewParams(ctx: StateContext<ProjectStateModel>, { payload }: SetCurrentViewParams) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      currentViewParams: payload
    });
  }

  @Action(CreateNewProjectView)
  CreateNewProjectView(ctx: StateContext<ProjectStateModel>, { payload }: CreateNewProjectView) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        views: {
          ...getState.project?.views,
          [payload.id]: payload
        }
      }
    });
  }

  @Action(UpdateProjectView)
  UpdateProjectView(ctx: StateContext<ProjectStateModel>, { payload }: UpdateProjectView) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        views: {
          ...getState.project?.views,
          [payload.id]: payload
        }
      }
    });
  }

  @Action(SetCurrentViewId)
  SetCurrentViewId(ctx: StateContext<ProjectStateModel>, { payload }: SetCurrentViewId) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        current_view_id: payload
      }
    });
  }

  @Action(DeleteProjectView)
  DeleteProjectView(ctx: StateContext<ProjectStateModel>, { payload }: DeleteProjectView) {
    const getState = ctx.getState();
    const {[payload]: _, ...views} = getState.project?.views;
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        views: views
      }
    });
  }

  @Action(JustSetProject)
  JustSetProject(ctx: StateContext<ProjectStateModel>, { payload }: JustSetProject) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: payload
    });
  }

  @Action(SetProjectIsLoading)
  SetProjectIsLoading(ctx: StateContext<ProjectStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      projectIsLoading: true
    })
  }

  @Action(UpdateProjectInfo)
  UpdateProjectInfo(ctx: StateContext<ProjectStateModel>, { payload }: UpdateProjectInfo) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: payload
    });
  }

  @Action(HideFilter)
  HideFilter(ctx: StateContext<ProjectStateModel>, { payload }: HideFilter) {
    const getState = ctx.getState();
    const newHiddenFilters = getState.currentViewParams.hidden_filters.includes(payload) ? getState.currentViewParams.hidden_filters : [...getState.currentViewParams.hidden_filters, payload];
    ctx.setState({
      ...getState,
      currentViewParams: {
        ...getState.currentViewParams,
        hidden_filters: newHiddenFilters
      }
    });
  }

  @Action(HideChannel)
  HideChannel(ctx: StateContext<ProjectStateModel>, { payload }: HideChannel) {
    const getState = ctx.getState();
    const newHiddenChannels = [...new Set([...payload.groups, ...getState.currentViewParams.hidden_filter_groups])];
    const newHiddenFilters = [...new Set([...payload.filters, ...getState.currentViewParams.hidden_filters])];
    ctx.setState({
      ...getState,
      currentViewParams: {
        ...getState.currentViewParams,
        hidden_filter_groups: newHiddenChannels,
        hidden_filters: newHiddenFilters
      }
    });
  }

  @Action(UnhideFilterChannels)
  UnhideFilterChannels(ctx: StateContext<ProjectStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      currentViewParams: {
        ...getState.currentViewParams,
        hidden_filter_groups: [],
        hidden_filters: []
      }
    });
  }

  @Action(UpdateAttributionModel)
  updateAttributionModel(ctx: StateContext<ProjectStateModel>, { payload }: UpdateAttributionModel) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        attribution_model: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          attribution_model: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          }
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          attribution_model: payload
        }
      });
    }
  }

  @Action(UpdateConversionEvent)
  updateConversionEvent(ctx: StateContext<ProjectStateModel>, { payload }: UpdateConversionEvent) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        conversion_event: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          conversion_event: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          }
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          conversion_event: payload
        }
      });
    }
    this.store.dispatch([
      new SetEventPropertyKey(null),
      new UpdateViewProperty(null),
      new UpdateViewPropertyValue(null)
    ]);
  }

  @Action(UpdateViewProperty)
  updateViewProperty(ctx: StateContext<ProjectStateModel>, { payload }: UpdateViewProperty) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      currentViewParams: {
        ...getState.currentViewParams,
        property_key: payload
      }
    });
  }

  @Action(UpdateViewPropertyValue)
  UpdateViewPropertyValue(ctx: StateContext<ProjectStateModel>, { payload }: UpdateViewPropertyValue) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      currentViewParams: {
        ...getState.currentViewParams,
        property_value: payload
      }
    });
  }

  @Action(SetTrafficOption)
  SetTrafficOption(ctx: StateContext<ProjectStateModel>, { payload }: SetTrafficOption) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        traffic_option: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          traffic_option: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          }
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          traffic_option: payload
        }
      });
    }
  }

  @Action(SetAggregationPeriod)
  SetAggregationPeriod(ctx: StateContext<ProjectStateModel>, { payload }: SetAggregationPeriod) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        aggregation_period: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          aggregation_period: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          }
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          aggregation_period: payload
        }
      });
    }
    this.store.dispatch(new ClearAggrPeriodReports());
  }

  @Action(SetCutoffEvent)
  SetCutoffEvent(ctx: StateContext<ProjectStateModel>, { payload }: SetCutoffEvent) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        cutoff_event: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          cutoff_event: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          },
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          cutoff_event: payload
        }
      });
    }
  }

  @Action(SetDisregardExistingUsers)
  SetDisregardExistingUsers(ctx: StateContext<ProjectStateModel>, { payload }: SetDisregardExistingUsers) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        disregard_existing_users: payload || null
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          disregard_existing_users: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          },
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          disregard_existing_users: payload
        }
      });
    }
  }

  @Action(SetAttributionType)
  SetAttributionType(ctx: StateContext<ProjectStateModel>, { payload }: SetAttributionType) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        attribution_type: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          attribution_type: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          },
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          attribution_type: payload
        }
      });
    }
  }

  @Action(SetLookbackWindow)
  SetLookbackWindow(ctx: StateContext<ProjectStateModel>, { payload }: SetLookbackWindow) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        lookback_window: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          lookback_window: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          },
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          lookback_window: payload
        }
      });
    }
  }

  @Action(SetViewRevenueGroupId)
  SetViewRevenueGroupId(ctx: StateContext<ProjectStateModel>, { payload }: SetViewRevenueGroupId) {
    const getState = ctx.getState();
    if (getState.project?.current_view_id === getState.project?.views?.['default']?.id) {
      const newDefaultView = {
        ...getState.project?.views?.['default'],
        revenue_group_id: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          revenue_group_id: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          },
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          revenue_group_id: payload
        }
      });
    }
  }

  @Action(SetViewDaterange)
  SetViewDaterange(ctx: StateContext<ProjectStateModel>, { payload }: SetViewDaterange) {
    const getState = ctx.getState();
    const defaultView = getState.project?.views?.['default'];
    if (getState.project?.current_view_id === defaultView?.id) {
      if (defaultView?.daterange_preset?.includes(',')) return;
      const newDefaultView = {
        ...defaultView,
        daterange_preset: payload
      };
      const {id, name, ...paramsToSend} = newDefaultView;
      this.viewService.updateView(paramsToSend, id).subscribe();
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          daterange_preset: payload
        },
        project: {
          ...getState.project,
          views: {
            ...getState.project?.views,
            default: newDefaultView
          },
        }
      });
    } else {
      ctx.setState({
        ...getState,
        currentViewParams: {
          ...getState.currentViewParams,
          daterange_preset: payload
        }
      });
    }
  }

  @Action(SetProjectName)
  SetProjectName(ctx: StateContext<ProjectStateModel>, { payload }: SetProjectName) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        name: payload
      }
    });
  }

  @Action(SetIncludeOnlyUsersRegisteredInDaterange)
  SetIncludeOnlyUsersRegisteredInDaterange(ctx: StateContext<ProjectStateModel>, { payload }: SetIncludeOnlyUsersRegisteredInDaterange) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        include_only_users_registered_in_daterange: payload
      }
    });
  }

  @Action(SetCompanyBasedAttribution)
  SetCompanyBasedAttribution(ctx: StateContext<ProjectStateModel>, { payload }: SetCompanyBasedAttribution) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        company_based_attribution: payload
      }
    });
  }

  @Action(SetPBR)
  SetPBR(ctx: StateContext<ProjectStateModel>, { payload }: SetPBR) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        position_based_ratios: payload
      }
    });
  }

  @Action(SetCurrencySymbol)
  SetCurrencySymbol(ctx: StateContext<ProjectStateModel>, { payload }: SetCurrencySymbol) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        currency_symbol: payload
      }
    });
  }

  @Action(SetCurrency)
  SetCurrency(ctx: StateContext<ProjectStateModel>, { payload }: SetCurrency) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        currency: payload
      }
    });
  }

  @Action(SetTimezone)
  SetTimezone(ctx: StateContext<ProjectStateModel>, { payload }: SetTimezone) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        timezone: payload
      }
    });
  }

  @Action(SetTimeframeAttributionType)
  SetTimeframeAttributionType(ctx: StateContext<ProjectStateModel>, { payload }: SetTimeframeAttributionType) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        timeframe_attribution_type: payload
      }
    });
  }

  @Action(SetProjectNextIdentifier)
  SetProjectNextIdentifier(ctx: StateContext<ProjectStateModel>, { payload }: SetProjectNextIdentifier) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      nextIdentifier: payload
    });
  }

  @Action(SetProjectOptions)
  SetProjectOptions(ctx: StateContext<ProjectStateModel>, { payload }: SetProjectOptions) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        options: payload
      }
    });
  }

  @Action(SetProjectSettings)
  SetProjectSettings(ctx: StateContext<ProjectStateModel>, { payload }: SetProjectSettings) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        settings: payload
      }
    });
  }

  @Action(ClearOauthErrors)
  ClearOauthErrors(ctx: StateContext<ProjectStateModel>, payload: ClearOauthErrors) {
    const getState = ctx.getState();

    const oauths = [...getState.project.oauths];
    const cleared = oauths.map( x => {
      if (x.id == payload.oauth_id) {
        x.errors_count = 0;
      };
      return x
    });

    ctx.setState({
      ...getState,
      project: {
        ...getState.project,
        oauths: cleared
      }
    });
  }

  @Action(ClearProject)
  ClearProject(ctx: StateContext<ProjectStateModel>) {
    ctx.setState({
      project: null,
      projectIsLoading: false,
      nextIdentifier: null,
      currentViewParams: null,
    });
  }
}
