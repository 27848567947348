import { Component, Input, OnChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding-title',
  templateUrl: './onboarding-title.component.html',
  styleUrls: ['./onboarding-title.component.scss']
})
export class OnboardingTitleComponent implements OnChanges{
  @Select( state => state.onboarding) onboarding$: Observable<any>;
  @Input() title: string;

  stepNumber = 1;
  outOf = 1;

  assetPath = environment.assetPath;
  sectionStyle = {};

  constructor() { }

  ngOnChanges() {
    this.onboarding$.subscribe( x => {
      if (x.flow && x.current_step) {
        this.stepNumber = x.flow[x.current_step].step_number;
      } else {
        this.stepNumber = 1;
      }
      this.outOf = x.max_steps;
      this.sectionStyle = {
        'width.%': this.stepNumber/this.outOf*100,
      };
    });
  }
}
