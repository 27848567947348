import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { ProjectService } from '../project/project.service';
import { Integration } from '../../constants/integration';

@Injectable({
  providedIn: 'root'
})
export class IntegrationAuthService {

  integrations: any;

  constructor(private httpClient: HttpClient, private errorDialogService: ErrorDialogService, private projectService: ProjectService) {}

  authDefault(integration, additionalParams?) {
    const url = Integration.get.buildUrl(integration, additionalParams);
    window.location.href = url;
  }

  connect(integration, params) {
    const connect = integration.config.additionalUrls.connect;
    const Url = connect.url;
    return this.httpClient.post(Url, params).pipe(
      catchError((e) => {
        this.errorDialogService.integrationFailError(e, integration.name);
        return e;
      })
    );
  }

  saveAccount(account, integration) {
    const Url = `${environment.clientApiUrl}${integration.config.oauthUrl}/${integration.oauth.id}`;
    return this.httpClient.post(Url, {name: account.name, identifier: account.identifier, timezone: account.timezone}).pipe(map(
      oauth => {
        this.projectService.getProjectInfo(true).subscribe();
        return integration;
      }, e => this.errorDialogService.integrationFailError(e, integration.name)
    ));
  }



}
