<h1 class="mt-0">
    Segment Connection
</h1>
<div class="attr-card max-w-800">
    <p class="em-1_3 mb-4">Fill in the segment write key to send all the events to segment.</p>
    <div class="form-group new-form">
        <div class="new-form__left">
            <label for="segment-write-key">Segment Write Key</label>
            <input type="text" class="form-control" id="segment-write-key" [(ngModel)]="segmentWriteKey"> 
            <i class="fa-solid fa-circle-xmark new-form__clear-input" (click)="segmentWriteKey = ''"></i>
        </div>
        <div class="new-form__right w-100px">
            <button class="btn btn-primary new-form__submit" (click)="saveSegmentWriteKey()">
                @if (writeKeySavePending) {
                    <i class="fa-solid fa-spinner fa-spin"></i>
                } @else {
                    Save
                }
            </button>
        </div>
    </div>
</div>
<div class="attr-card mt-5 max-w-800">
    <p class="em-1_3 mb-4">Configure Segment Destination.</p>
    <p class="mb-4"><b>Project ID:</b> {{ project?.identifier }}</p>
    <a href="https://app.segment.com/login" target="_blank" class="btn btn-new">
        Configure Segment Destination
        <i class="fa-solid fa-arrow-up-right-from-square ms-2"></i>
    </a>
</div>
<div class="attr-card mt-5 max-w-800">
    <div class="attr-card__header">
        <div class="title mb-4">How it works?</div>
        <img src="{{ assetPath }}/assets/images/segment-how-it-works.png" width="780" alt="Attribution" />
    </div>
</div>