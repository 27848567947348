import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const WhereNotToUseAuth = [
            environment.geoHost,
        ];
        let useAuth = !WhereNotToUseAuth.some( x => req.url.includes(x));
        let token = localStorage.getItem('token');
        if (req.url.includes('/regulations')) {
            token = req.body.token;
            delete req.body.token;
        }
        const request = token && useAuth ? req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) }) : req;
        return next.handle(request);
    }
}
