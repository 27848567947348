import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { PropertiesStateModel } from 'src/app/store/states/properties.state';
import { SetEventPropertyKey } from 'src/app/store/actions/properties.action';
import { UpdateViewProperty, UpdateViewPropertyValue } from 'src/app/store/actions/project.actions';
import { Observable } from 'rxjs';
import { Project, View } from 'src/app/core/models/project.model';
import { ProjectStateModel } from 'src/app/store/states/project.state';

@Component({
  selector: 'app-properties-selector',
  templateUrl: './properties-selector.component.html',
  styleUrls: ['./properties-selector.component.scss']
})
export class PropertiesSelectorComponent implements OnInit, OnChanges {
  @Input() properties: PropertiesStateModel;
  @Input() reportIsLoading: boolean;
  @Select(state => state.project) projectState$: Observable<ProjectStateModel>;

  hideSelect = true;
  knownProperties: string[];
  eventPropertyKey: string;
  buttonText: string;
  buttonEnabled: boolean;
  currentViewParams: View;
  project: Project;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.projectState$.subscribe((projectState) => {
      this.project = projectState.project;
      this.currentViewParams = projectState.currentViewParams;
    });
  }

  ngOnChanges() {
    if (this.properties.keysAreLoading) {
      this.knownProperties = [];
      this.buttonText = 'Loading...';
      return;
    } else {
      this.buttonText = null;
    }
    const properties = this.properties.knownKeys?.sort( (a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    if (properties && properties.length > 0) {
      this.knownProperties = properties;
      this.buttonText = 'Select one...';
      this.eventPropertyKey = this.properties.event_property_key;
    } else {
      this.knownProperties = [];
      this.buttonText = 'None available';
      this.eventPropertyKey = null;
    }
    this.buttonEnabled = this.knownProperties?.length > 0 && !this.reportIsLoading;
  }

  selectEventPropertyKey() {
    const shouldUpdateView = this.currentViewParams.id !== this.project.views.default.id;
    this.store.dispatch(new SetEventPropertyKey({key: this.eventPropertyKey, shouldUpdateView: shouldUpdateView}));
  }

  openSelect(btn, select) {
    this.hideSelect = !this.hideSelect;
    btn.classList.add('hide');
    select.open();
    setTimeout(() => { select.focus(); }, 100);
  }

  closeSelect() {
    this.hideSelect = !this.hideSelect;
    document.getElementsByClassName('properties-wrapper')[0].getElementsByClassName('btn-select')[0].classList.remove('hide');
  }
}
