import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PasswordService } from 'src/app/core/services/password/password.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RenameProjectComponent } from '../components/rename-project/rename-project.component';
import { map } from "lodash-es";
import { Integration } from 'src/app/core/constants/integration';
import { ToastrService } from 'ngx-toastr';
import Rollbar from 'rollbar';
import { RollbarService } from 'src/app/core/error-handler/rollbar-error-handler';
import { SegmentService } from 'ngx-segment-analytics';
import { Store } from '@ngxs/store';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SetCurrentComponent } from 'src/app/store/actions/selected.actions';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

declare var $: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  loginError: string;
  returnUrl: string;
  section = 'login';
  auxToken: string;
  clearUrl = false;
  passwordResetSuccess = false;
  waitingForResponse = false;
  errorMessage: string;
  waitingTokenResponse: string = null;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private passwordService: PasswordService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private segment: SegmentService,
    private store: Store,
    @Inject(RollbarService) private rollbar: Rollbar) { }

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  changePasswordForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  ngOnInit() {
    this.store.dispatch(new SetCurrentComponent({currentComponent: ComponentNames.login}));
    if (environment.envName === 'demo') {
      this.submit({demo: true});
    }
    this.route.queryParams.subscribe( queryParams => {
      if (queryParams.state === "microsoft-login") {
        // TODO Microsoft can return error=invalid_client&error_description=XXX need to show that in system error dialog
        this.waitForSsoResponse()
        this.authService.msLogin(queryParams.code).subscribe( (x: any) =>
          this.responseSuccess(x), e => this.responseError(e)
        );
      } else if (queryParams.state === 'google-login') {
        this.waitForSsoResponse()
        this.authService.googleLogin(queryParams.code).subscribe( (x: any) =>
          this.responseSuccess(x), e => this.responseError(e)
        );
      } else if (queryParams.section === 'forgot_password') {
        this.section = 'forgot_password';
        this.resetErrorMessage();
        this.focusEmailInput();
        this.clearUrl = true;
      } else if (queryParams.token && queryParams.section) {
        this.section = queryParams.section;
        this.auxToken = queryParams.token;
        this.clearUrl = true;
        if (queryParams.section === 'password_reset') {
          this.waitingTokenResponse = 'Verifing password reset token';
          this.passwordService.checkToken(this.section, this.auxToken).subscribe( (resp: any) => {
            this.finishCheckResetToken(resp);
          });
        } else if ( queryParams.section === 'accesses') {
          this.waitingTokenResponse = 'Verifing token';
          this.passwordService.checkToken(this.section, this.auxToken).subscribe( (resp: any) => {
            this.finishCheckAccessToken(resp);
          });
        }
      } else if (queryParams.otp_token) {
        this.clearUrl = true;
        this.authService.clearStorage();
        this.waitingTokenResponse = 'Autologon...';
        this.submit({otpTokenParam: queryParams.otp_token});
      } else {
        this.section = 'login';
        this.clearUrl = false;
      }
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }

  waitForSsoResponse(){
    this.waitingTokenResponse = 'Verifing SSO token';
    this.waitingForResponse = true;
  }

  responseError(e){
    this.waitingForResponse = false;
    this.waitingTokenResponse = null;
    this.loginError = e.error.message;
  }

  responseSuccess(x){
    this.waitingForResponse = false;
    this.waitingTokenResponse = null;
    this.submit({token: x.token});
  }

  microsoftLogin() {
    this.checkIfSekelton();
    var params = {
      "client_id": environment.integrations.bing.client_id,
      "scope": "User.Read email openid",
      "response_type": "code",
      "state": "microsoft-login",
      "response_mode": "query",
      "prompt": "select_account",
      "redirect_uri": `${location.origin}/integration-callback.html`
    }

    var uriParams = map(params, function (value, key) {
      return key + '=' + encodeURIComponent(value);
    }).join('&');

    window.location.href = `${Integration.get.config.msads.requestUrl}?${uriParams}`
  }

  googleLogin() {
    this.checkIfSekelton();
    var params = {
      "client_id": environment.integrations.googleads.client_id,
      "scope": "openid email",
      "response_type": "code",
      "state": "google-login",
      "access_type": "online",
      "prompt": "select_account",
      "redirect_uri": `${location.origin}/integration-callback.html?integration=googleads`
    }

    var uriParams = map(params, function (value, key) {
      return key + '=' + encodeURIComponent(value);
    }).join('&');

    window.location.href = `${Integration.get.config.googleads2.requestUrl}?${uriParams}`
  }

  checkIfSekelton() {
    if (environment.envName === 'skeleton') {
      this.toastr.error('Logging in is not possible for this environment');
      return;
    }
  }

  submit({demo = false, token = null, otpTokenParam = null}: any) {
    if (!demo && !token && !otpTokenParam) this.checkIfSekelton();
    let { otp_token, state, code, ...paramsWithoutOtp } = this.route.snapshot.queryParams;
    if (this.clearUrl) {
      paramsWithoutOtp = {};
    }
    const paramsToPass = demo ? { start: '2021-09-29', end: '2021-10-27'} : paramsWithoutOtp;

    if (this.waitingForResponse === false) {
      const email = this.loginForm.get('email').value;
      const password = this.loginForm.get('password').value;
      this.waitingForResponse = true;

      this.authService.login(email, password, demo, token, otpTokenParam).subscribe({ next: (respProject) => {
        if ((respProject.registration_step && respProject.registration_step != 'finished') && respProject.last_registration_step != 0) {
          this.router.navigate(['onboarding', respProject.registration_step]);
          return;
        }
        this.waitingTokenResponse = null;
        this.waitingForResponse = false;

        const redirect = () => {
          if (respProject.partner == 'shopify-segment' || respProject.owner?.partner == 'shopify-segment') {
            this.router.navigate([respProject?.identifier, ComponentNames.settings, SettingsSections.overview]);
          } else if (this.returnUrl && this.returnUrl !== '/') {
            this.router.navigateByUrl(this.returnUrl, /* Removed unsupported properties by Angular migration: queryParams. */ {});
          } else if (this.returnUrl) {
            this.router.navigateByUrl(respProject?.identifier);
          } else {
            this.router.navigate([respProject?.identifier], {
              queryParams: paramsToPass,
            });
          }
        }

        if (respProject.name.toUpperCase() == 'MY PROJECT') {
          const modalRef = this.modalService.open(RenameProjectComponent);
          modalRef.result.then(result => {
            redirect();
          }, e => {
            console.log(e);
            redirect();
          });
        } else redirect();

      }, error: (error) => {
        if (error.status === 422) {
          this.loginError = 'Incorrect email or password';
        } else if ( error.name === 'TimeoutError' || error.status === 0) {
          this.loginError = 'Could not connect to the server';
        }
        if (this.waitingTokenResponse == 'Autologon...') {
          this.waitingTokenResponse = null;
          this.loginError = 'Autologon Failed. Please login manually.';
          this.router.navigate([]);
        }
        this.waitingForResponse = false;
        this.segment.track('Authentication Failed', {email_input_field: email, reason: error});
        this.rollbar.debug(`Authentication Failed`, {error: error, email_input_field: email});
        throw error;
      }
    });
    }
  }

  submitResetPassword() {
    if (this.waitingForResponse === false) {
      this.waitingForResponse = true;
      this.passwordService.requestPasswordReset(this.forgotPasswordForm.get('email').value).subscribe( (resp: any) => {
        if (resp.success === true) {
          this.passwordResetSuccess = true;
          this.waitingForResponse = false;
        } else {
          this.showBadResetTokenMessage(resp.type);
        }
      });
    }
  }

  submitNewPassowrd() {
    if (this.waitingForResponse === false) {
      this.passwordService.changePassword(this.changePasswordForm.get('password').value, this.auxToken, this.section).subscribe( (resp: any) => {
        if (resp.success === true) {
          this.auxToken = null;
          this.submit({token: resp.token});
          this.resetErrorMessage();
        } else {
          this.showBadResetTokenMessage(resp.type);
        }
      });
    }
  }

  openForgotPassword() {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...queryParams, section: 'forgot_password'}
    });
  }

  backToLogin() {
    this.resetErrorMessage();
    this.passwordResetSuccess = false;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {}
    });
  }

  finishCheckResetToken(resp: any) {
    if (resp.success === false) {
      this.showBadResetTokenMessage(resp.type);
    } else {
      this.resetErrorMessage();
      this.focusSelector('.new-password-form-wrapper input[type=password]');
    }
  }

  finishCheckAccessToken(resp: any) {
    if (resp.success === false) {
      const errors = {
        NoMatchingRow: 'This link was already used.'
      };
      this.errorMessage = errors[resp.type];
    } else {
      this.resetErrorMessage();
      this.focusSelector('.new-password-form-wrapper input[type=password]');
    }
  }

  resetErrorMessage() {
    this.waitingTokenResponse = null;
    this.errorMessage = null;
  }

  focusEmailInput() {
    setTimeout(() => this.focusSelector('.forgot-password-form-wrapper input[type=email]'), 0);
  }

  showBadResetTokenMessage(type) {
    this.section = 'forgot_password';
    const errors = {
      NoMatchingRow: 'This link is malformed.',
      ResetAlreadyUsedError: 'This link was already used. Please reset your password again.',
      ExpiredResetError: 'This link is no longer active. Please reset your password again.',
      EmailNotFound: 'This email address is not found.'
    };
    this.errorMessage = errors[type];
  }

  focusSelector(selector: string) {
    (document.querySelector(selector) as HTMLElement).focus();
  }

}
