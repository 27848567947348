<div class="container-full">
  <h4 class="text-center mt-5 mb-0">
    {{ title }}
    <i class="fa-solid fa-lock red ms-2"></i>
  </h4>
  @if (title == 'Events Breakdown') {
    <div class="sidebar-subtitle mb-4">
      Events that caused unknown source
      <a href="https://docs.attributionapp.com/docs/default-channels#unknown-source-traffic-that-cannot-be-attributed-to-any-visit" target="_blank">
        <i class="fa-solid fa-info-circle medium clickable darker-hover"></i>
      </a>
    </div>
  }
  <div *ngFor="let event of events; let last = last">
    <div class="row mt-4 mb-1">
      <div data-cy="events-breakdown-event-name" class="col-9 align-middle relative clickable" (click)="onEventExpand(event)">
        <a>
          {{ event.name }}
          @if (event.loading) {
            <i class="fa-solid fa-spinner fa-spin ng-star-inserted blue"></i>
          } @else {
            @if (event.expanded) {
              <i class="fa-solid fa-chevron-down ng-star-inserted"></i>
            } @else {
              <i class="fa-solid fa-chevron-right ng-star-inserted"></i>
            }
          }
        </a>
        <div class="progress event-bar">
          <div class="progress-bar" [class.bar-red]="event.value < 0" role="progressbar" [ngStyle]="{'width': (event.value >= 0 ? (event.value/maxValue) : (event.value/maxValue)*-1)*100+'%'}" aria-valuenow="{{event.percentage}}" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-3">
        <small class="pull-right top-minus-1px">{{ event.value_type === 'revenue' ? (event.value | projectCurrency) : (event.value | integer) }}</small>
      </div>
    </div>
    @if (event.expanded) {
      <div data-cy="events-breakdown-properties-wrapper" class="row properties-wrapper mt-1 mb-2 small">
        @if (event.properties?.length > 0) {
          <div class="d-flex align-items-center flex-wrap">
            <div class="me-2 strong medium-dark">
              Property keys:
            </div>
            @for (property of event.properties; let idx = $index; track idx) {
              <div>
                <div class="filter--badge filter--badge__light me-1 pb-1 clickable" [class.filter--badge__active]="idx | isActiveEventProperty:event.activeProperty" (click)="selectProperty(event, idx)">
                  {{ property.name }}
                </div>
              </div>
            }
          </div>
          @if (event.activeProperty != null) {
            @if (event.properties[event.activeProperty].values?.length > 0) {
              @for (value of event.properties[event.activeProperty].values; let idx = $index; track value) {
                <div class="d-flex justify-content-between border-bottom-light py-2 prop" [class.hovered]="hoveredPropertyIndex == idx" (mouseenter)="hoveredPropertyIndex = idx" (mouseenter)="hoveredPropertyIndex = null">
                  <div>
                    {{ value.name }}
                    @if (project?.options?.features?.event_filters || !isProd) {
                      <span class="blue-link" (click)="addEventPropertyFilter(event.name, event.properties[event.activeProperty].name, value.name)">
                        <i class="fa-solid fa-plus ms-2 me-1"></i>
                        Create event filter
                      </span>
                    }
                  </div>
                  <div>
                    {{ value.value | integer }}
                  </div>
                </div>
              }
            } @else if (event.properties[event.activeProperty].loading) {
              <div class="d-flex justify-content-center m-3">
                <i class="fa-solid fa-spinner fa-spin ng-star-inserted blue"></i>
              </div>
            } @else {
              <div class="ms-3 mt-2">
                <span class="medium-dark">No values found for selected property.</span>
              </div>
            }
          }
        } @else {
          <span class="medium-dark">No properties found for selected event.</span>
        }
      </div>
    }
    <hr *ngIf="!last" class="m-0">
  </div>
</div>
