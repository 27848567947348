import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { DataDownloaderService } from '../data-downloader/data-downloader.service';

@Injectable({
  providedIn: 'root'
})
export class BreakDownService {
  constructor(
    private baseService: BaseService,
    private dataDownloader: DataDownloaderService
  ) {}

  getBreakDown(id?, type?) {
    // this.unsubscribeAndCancelReportGeneration();

    const Params: any = this.baseService.getReportingParams;

    if (id) {
      if (type == 'f') {
        Params.filter = id;
      } else if (type == 'v') {
        Params.virtual_filter = id;
      } else {
        Params.filter_group_id = id;
      }
    }

    return this.dataDownloader.requestReport({...Params, report: 'path_break_down'});
  }

  unsubscribeAndCancelReportGeneration() {
    this.dataDownloader.cancelReportGeneration(['path_break_down']);
  }
}
