<div class="modal-header">
  <h4 class="modal-title">
    <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
    Shopify Store URL
  </h4>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div *ngIf="error" class="alert alert-danger alert-dismissible" role="alert">
          <button (click)="clearError()" type="button" class="btn-close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          {{ error }}
        </div>

        <form [formGroup]="connectForm" autocomplete="false">
          <div class="form-group">
            <label for="shopifyUsername">Shopify Store URL</label>
            <div class="input-group input-group-lg">
              <input type="text" formControlName="shop" autofocus class="form-control" placeholder="Shopify Store Name">
              <span class="input-group-text">.myshopify.com</span>
            </div>
          </div>

          <div class="text-center mt-2">
            <button class="btn btn-success" (click)="connectLogin()">
              <span *ngIf="!waitingForResponse; else waiting" class='caps'>
                Connect
              </span>
              <ng-template #waiting>
                <i class="fa-solid fa-spinner fa-spin"></i>
              </ng-template>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
