import { Component, Input, OnInit } from '@angular/core';
import { reduce } from 'lodash-es';
import { returnAllParents } from 'src/app/core/helpers/find-in-tree';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { IntegrationsService } from 'src/app/core/services/integrations/integrations.service';

@Component({
  selector: 'app-manage-integration-modal',
  templateUrl: './manage-integration-modal.component.html',
  styleUrls: ['./manage-integration-modal.component.scss']
})
export class ManageIntegrationModalComponent implements OnInit {
  @Input() oauth;

  objects;
  tree: any = [];
  isLoading = false;

  constructor(private filterService: FilterService, private integrationsService: IntegrationsService) { }

  ngOnInit(): void {
  this.isLoading = true;
    this.filterService.getFilterTree({
      include_invisible: true,
      oauth_id: this.oauth.id
    }).subscribe( x => {
      this.tree = x;
      this.isLoading = false;
    });
  }

  itemHasChildren(item) {
    return typeof(item.c) != 'undefined' && item.c != null;
  }

  update(object) {
    this.updateVisiblity(object);
  }

  searchFormatter(selectedFilter) {
    const path = returnAllParents({ tree: this.tree, childrenKey: 'c', key: 'id', value: selectedFilter.id, additionalKey: 't', additionalValue: selectedFilter.type});
    if (path.length > 0) {
      return path.join(' > ');
    } else {
      return false;
    }
  }

  updateVisiblity(object) {
    const objectType = object.t;
    const objectVisible = object.v;
    if (objectType == 'g') {
      if (objectVisible) {
        this.filterService.disableGroup(object.id).subscribe( () => {
          object.v = !object.v;
        });
      } else {
        this.filterService.disableGroup(object.id, true).subscribe( () => {
          object.v = !object.v;
        });
      }
    } else {
      if (objectVisible) {
        this.filterService.disableFilter(object.id).subscribe( () => {
          object.v = !object.v;
        });
      } else {
        this.filterService.disableFilter(object.id, true).subscribe( () => {
          object.v = !object.v;
        });
      }
    }
  };

  enableFacebookForm(object) {
    const params = { id: object.oauth_id, form_id: object.id };

    this.integrationsService.enableFacebookForm(params, !object.enabled).subscribe(() => object.enabled = !object.enabled);
  };

  renderTree(data) {
    var func = (x) => {
      return reduce(x, function(result, item){
        result.push({id: item.id, name: item.n, level: item.l, visible: item.v, type: item.t});
        if (item.c){
          result.push.apply(result, func(item.c));
        }
        return result;
      }, []);
    };

    return func(data);
  };
}
