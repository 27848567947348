import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { SetSettingSection } from 'src/app/store/actions/selected.actions';
import { SettingsStateModel } from 'src/app/store/states/settings.state';
import { ProjectCurrencyPipe } from 'src/app/core/pipes/project-currency.pipe';
import { IntegerPipe } from 'src/app/core/pipes/integer.pipe';
import { ActivatedRoute } from '@angular/router';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { UsageService } from 'src/app/core/services/usage/usage.service';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SetUsageReport } from 'src/app/store/actions/settings.actions';

import Chart from 'chart.js/auto';
import { hoverLine } from 'src/app/modules/shared/charts/chart-js-plugins/hoverline';
import { Project } from 'src/app/core/models/project.model';
import { ProjectStateModel } from 'src/app/store/states/project.state';

import { DateTime, Interval } from 'luxon';
import annotation from 'chartjs-plugin-annotation';
import { Account } from 'src/app/core/models/account.model';
Chart.register(annotation);


@Component({
  selector: 'app-overview',
  templateUrl: './settings-overview.component.html',
  styleUrls: ['./settings-overview.component.scss'],
  providers: [ ProjectCurrencyPipe, IntegerPipe ]
})
export class SettingsOverviewComponent implements OnInit, OnDestroy {
  @Select(state => state.settings) settings$: Observable<SettingsStateModel>;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.account) account$: Observable<Account>;

  statusEvent: Event;
  project: Project;
  account: Account;

  spendVal = 0;
  spendProjectedVal = 0;
  spendDiffPct = 0;

  mtuVal = 0;
  mtuProjectedVal = 0;
  mtuDiffPct = 0;

  eventsVal = 0;
  eventsProjectedVal = 0;
  eventsDiffPct = 0;

  periodStart;

  spendTimeline = [];
  spendProjectedTimeline = [];

  mtuTimeline = [];
  mtuProjectedTimeline = [];

  eventsTimeline = [];
  eventsProjectedTimeline = [];

  chartDateLabels = [];

  chartView = 'actual';

  usageSub: Subscription;
  settingsSub: Subscription;
  routeSub: Subscription;

  spendChart: Chart;
  mtuChart: Chart;
  meChart: Chart;

  initalLoaded = false;
  usageRequestInProgress = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService,
    private usageService: UsageService) { }

  ngOnInit() {
    this.routeSub = this.route.parent.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, `${ComponentNames.settings}/${SettingsSections.instructions}`);
    });
    this.store.dispatch(new SetSettingSection(SettingsSections.overview));
    this.settingsSub = combineLatest([this.settings$, this.project$, this.account$]).subscribe(([settings, project, account]) => {
      const usageReport = settings?.usageReport;
      this.project = project.project;
      this.account = account;
      if (project?.project) {
        if (usageReport) {
          this.spendTimeline = usageReport.spend_timeline;
          this.spendProjectedTimeline = usageReport.spend_projected_timeline;

          this.mtuTimeline = usageReport.mtu_timeline;
          this.mtuProjectedTimeline = usageReport.mtu_projected_timeline;

          this.eventsTimeline = usageReport.events_timeline;
          this.eventsProjectedTimeline = usageReport.events_projected_timeline;

          this.chartDateLabels = Interval.fromDateTimes(DateTime.fromISO(usageReport.period_start), DateTime.fromISO(usageReport.period_end)).splitBy({days: 1}).map( item => item.start.toISODate());

          this.spendVal = usageReport.spend / 100;
          this.spendProjectedVal = usageReport.spend_projected / 100;
          this.spendDiffPct = usageReport.spend_diff_pct;

          this.mtuVal = usageReport.mtu;
          this.mtuProjectedVal = usageReport.mtu_projected;
          this.mtuDiffPct = usageReport.mtu_diff_pct;

          this.eventsVal = usageReport.events;
          this.eventsProjectedVal = usageReport.events_projected;
          this.eventsDiffPct = usageReport.events_diff_pct;

          this.periodStart = usageReport.period_start;

          if (this.project?.options?.general?.spend_limit == null) {
            this.drawMTUChart();
          } else {
            this.drawSpendChart();
          }
          
          this.drawEventsChart();
        } else if (this.initalLoaded && !this.usageRequestInProgress) {
          this.usageSub?.unsubscribe();
          this.usageRequestInProgress = true;
          this.usageSub = this.usageService.getReport().subscribe( resp => {
            this.store.dispatch(new SetUsageReport(resp));
            this.usageRequestInProgress = false;
          });
        }
      }
    });
    this.usageRequestInProgress = true;
    this.usageSub = this.usageService.getReport().subscribe( resp => {
      this.store.dispatch(new SetUsageReport(resp));
      this.initalLoaded = true;
      this.usageRequestInProgress = false;
    });;
  }

  drawSpendChart() {
    const SPENDctx = document.getElementById('spend-chart') as HTMLCanvasElement;

    let spendData =  this.chartDateLabels.map( x => {
      const found = this.spendTimeline.concat(this.spendProjectedTimeline).find( item => item.date === x);
      return found ? found.value / 100 : null
    });

    let sum = 0;
    if (this.chartView === 'incremental') {
      spendData = spendData.map( (x) => {
        if (x != null) return sum += x;
        else return null;
      });
    }

    const spendSize = this.spendTimeline.length;

    const spendDatasets: any = [
      {
        label: 'Tracked Spend',
        data: spendData,
        borderWidth: 3,
        tension: 0.3,
        fill: true,
        borderColor: 'rgb(16, 106, 235)',
        backgroundColor: 'rgba(16, 106, 235, 0.2)',
        segment: {
          borderDash:      ctx => { return ctx.p1DataIndex >= spendSize ? [15, 10] : undefined },
          borderColor:     ctx => { return ctx.p1DataIndex >= spendSize ? 'rgb(255,189,70)' : undefined },
          backgroundColor: ctx => { return ctx.p1DataIndex >= spendSize ? 'rgba(255,189,70, 0.2)' : undefined }
        }
      }
    ];

    this.spendChart?.destroy();

    let plugins: any = [hoverLine];
    
    let pluginOptions: any = {
      hoverLine: {
        firstLineSize: spendSize,
        secondLineColor: 'rgb(255,189,70)',
      },
      legend: {
        display: false
      },
      tooltip: {
        intersect: false,
        displayColors: false,
        callbacks: {
          title: (items) => {
            if (items[0].dataIndex >= spendSize) {
              return 'Projected '+items[0].label;
            } else {
              return 'Actual '+items[0].label;
            }
          }
        }
      }
    };

    if (
      this.project?.options?.general?.spend_limit
      && this.chartView === 'incremental'
      && sum > (this.project?.options?.general?.spend_limit*0.8)
    ) {
      pluginOptions.annotation = {
        annotations: {
          spendLimit: {
            type: 'line',
            yMin: this.project?.options?.general?.spend_limit,
            yMax: this.project?.options?.general?.spend_limit,
            borderColor: 'rgb(246, 56, 55)',
            borderWidth: 1,
            label: {
              content: 'Spend Limit',
              display: true,
              opacity: 0.5,
              position: 'start',
            }
          }
        }
      };
    }

    this.spendChart = new Chart(SPENDctx, {
      type: 'line',
      data: {
        labels: this.chartDateLabels.map( x => DateTime.fromISO(x).toFormat('d/M')),
        datasets: spendDatasets
      },
      plugins: plugins,
      options: {
        interaction: {
          mode: 'index',
          axis: 'x',
          intersect: false,
        },
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: pluginOptions,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value: number, index, ticks) {
                  if (value > 1000) {
                    return value / 1000 + "k"
                  }
                  else return value;
              }
           },
            beginAtZero: true
          },
          x: {
            grid: {
              display: false
            }
          }
        }
      }
    });
  }

  drawMTUChart() {
    const MTUctx = document.getElementById('mtu-chart') as HTMLCanvasElement;

    let mtuData =  this.chartDateLabels.map( x => {
      const found = this.mtuTimeline.concat(this.mtuProjectedTimeline).find( item => item.date === x);
      return found ? found.value : null
    });

    let sum = 0;
    if (this.chartView === 'incremental') {
      mtuData = mtuData.map( (x) => {
        if (x != null) return sum += x;
        else return null;
      });
    }

    const mtuSize = this.mtuTimeline.length;

    const mtuDatasets: any = [
      {
        label: 'Tracked Users',
        data: mtuData,
        borderWidth: 3,
        tension: 0.3,
        fill: true,
        borderColor: 'rgb(16, 106, 235)',
        backgroundColor: 'rgba(16, 106, 235, 0.2)',
        segment: {
          borderDash:      ctx => { return ctx.p1DataIndex >= mtuSize ? [15, 10] : undefined },
          borderColor:     ctx => { return ctx.p1DataIndex >= mtuSize ? 'rgb(255,189,70)' : undefined },
          backgroundColor: ctx => { return ctx.p1DataIndex >= mtuSize ? 'rgba(255,189,70, 0.2)' : undefined }
        }
      }
    ];

    this.mtuChart?.destroy();

    let plugins: any = [hoverLine];
    
    let pluginOptions: any = {
      hoverLine: {
        firstLineSize: mtuSize,
        secondLineColor: 'rgb(255,189,70)',
      },
      legend: {
        display: false
      },
      tooltip: {
        intersect: false,
        displayColors: false,
        callbacks: {
          title: (items) => {
            if (items[0].dataIndex >= mtuSize) {
              return 'Projected '+items[0].label;
            } else {
              return 'Actual '+items[0].label;
            }
          }
        }
      }
    };

    if (
      this.project?.options?.general?.mtu_limit
      && this.chartView === 'incremental'
      && sum > (this.project?.options?.general?.mtu_limit*0.8)
    ) {
      pluginOptions.annotation = {
        annotations: {
          mtuLimit: {
            type: 'line',
            yMin: this.project?.options?.general?.mtu_limit,
            yMax: this.project?.options?.general?.mtu_limit,
            borderColor: 'rgb(246, 56, 55)',
            borderWidth: 1,
            label: {
              content: 'MTU Limit',
              display: true,
              opacity: 0.5,
              position: 'start',
            }
          }
        }
      };
    }

    this.mtuChart = new Chart(MTUctx, {
      type: 'line',
      data: {
        labels: this.chartDateLabels.map( x => DateTime.fromISO(x).toFormat('d/M')),
        datasets: mtuDatasets
      },
      plugins: plugins,
      options: {
        interaction: {
          mode: 'index',
          axis: 'x',
          intersect: false,
        },
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: pluginOptions,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value: number, index, ticks) {
                  if (value > 1000) {
                    return value / 1000 + "k"
                  }
                  else return value;
              }
           },
            beginAtZero: true
          },
          x: {
            grid: {
              display: false
            }
          }
        }
      }
    });
  }

  drawEventsChart() {
    const MEctx = document.getElementById('me-chart') as HTMLCanvasElement;

    let meData = this.chartDateLabels.map( x => {
      const found = this.eventsTimeline.concat(this.eventsProjectedTimeline).find( item => item.date === x);
      return found ? found.value : null;
    });

    let sum = 0;
    if (this.chartView === 'incremental') {
      meData = meData.map( (x) => {
        if (x != null) return sum += x;
        else return null;
      });
    }

    const meSize = this.eventsTimeline.length;
    

    const meDatasets: any = [
      {
        label: 'Tracked Events',
        data: meData,
        borderWidth: 3,
        tension: 0.3,
        borderColor: 'rgb(16, 106, 235)',
        backgroundColor: 'rgba(16, 106, 235, 0.2)',
        fill: true,
        segment: {
          borderDash:      ctx => { return ctx.p1DataIndex >= meSize ? [15, 10] : undefined },
          borderColor:     ctx => { return ctx.p1DataIndex >= meSize ? 'rgb(255,189,70)' : undefined },
          backgroundColor: ctx => { return ctx.p1DataIndex >= meSize ? 'rgba(255,189,70, 0.2)' : undefined }
        }
      }
    ];

    this.meChart?.destroy();

    let plugins: any = [hoverLine];
    
    let pluginOptions: any = {
      hoverLine: {
        firstLineSize: meSize,
        secondLineColor: 'rgb(255,189,70)',
      },
      legend: {
        display: false
      },
      tooltip: {
        intersect: false,
        displayColors: false,
        callbacks: {
          title: (items) => {
            if (items[0].dataIndex >= meSize) {
              return 'Projected '+items[0].label;
            } else {
              return 'Actual '+items[0].label;
            }
          },
        }
      }
    };

    if (this.project?.options?.general?.event_limit
      && this.chartView === 'incremental'
      && sum > (this.project?.options?.general?.event_limit*0.8)
    ) {
      pluginOptions.annotation = {
        annotations: {
          meLimit: {
            type: 'line',
            yMin: this.project?.options?.general?.event_limit,
            yMax: this.project?.options?.general?.event_limit,
            borderColor: 'rgb(246, 56, 55)',
            borderWidth: 1,
            label: {
              content: 'Event Limit',
              display: true,
              opacity: 0.5,
              position: 'start',
            }
          }
        }
      };
    }

    this.meChart = new Chart(MEctx, {
      type: 'line',
      data: {
        labels: this.chartDateLabels.map( x => DateTime.fromISO(x).toFormat('d/M')),
        datasets: meDatasets
      },
      plugins: plugins,
      options: {
        interaction: {
          mode: 'index',
          axis: 'x',
          intersect: false,
        },
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: pluginOptions,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value: number, index, ticks) {
                  if (value > 1000) {
                    return value / 1000 + "k"
                  }
                  else return value;
              }
           },
            beginAtZero: true
          },
          x: {
            grid: {
              display: false
            }
          }
        }
      }
    });
  }

  setMTUview(view: string) {
    this.chartView = view;
    if (this.project?.options?.general?.spend_limit == null) {
      this.drawMTUChart();
    } else {
      this.drawSpendChart();
    }
    this.drawEventsChart();
  }

  ngOnDestroy(): void {
    this.usageSub?.unsubscribe();
    this.routeSub?.unsubscribe();
    this.settingsSub?.unsubscribe();
    this.mtuChart?.destroy();
    this.meChart?.destroy();
  }

}
