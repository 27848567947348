import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { Project, View } from 'src/app/core/models/project.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WidgetWrapperModalComponent } from '../widget-wrapper-modal/widget-wrapper-modal.component';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { ProjectStateModel } from 'src/app/store/states/project.state';

@Component({
  selector: 'app-project-options',
  templateUrl: './project-options.component.html',
  styleUrls: ['./project-options.component.scss']
})
export class ProjectOptionsComponent implements OnInit {
  @Select( state => state.project ) project$: Observable<ProjectStateModel>;
  @Select( state => state.base.firstPurchaseOnly ) firstPurchaseOnly$: Observable<boolean>;
  @Select( state => state.selected) selected$: Observable<SelectedStateModel>;

  project: Project;
  view: View;
  firstPurchaseOnly = false;
  revenueGroup: any;

  constructor(
    private store: Store,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    combineLatest([this.project$, this.selected$]).subscribe(([project, selected]) => {
      this.project = project.project;
      this.view = project.currentViewParams;
      this.revenueGroup = selected.revenueGroup;
    });
    this.firstPurchaseOnly$.subscribe( resp => this.firstPurchaseOnly = resp);
  }

  openSettingsModal(widgetName?) {
    const refreshReports = () => {
      if (this.store.selectSnapshot( state => state.settings.needReportRefresh)) {
        this.store.dispatch([new ClearReports(), new SetNeedReportRefresh(false)]);
      };
    }

    const modalRef = this.modalService.open(WidgetWrapperModalComponent, {size: 'xl', windowClass: 'slideInDown'});
    modalRef.componentInstance.modalType = widgetName;
    modalRef.result.then(() => refreshReports(), () => refreshReports());
  }

  get shouldShowLookbackOption() {
    if (this.project?.options.attribution_types == null || this.project?.options.attribution_types.length <= 1) {
      if (this.project?.options.features?.attribution_type != true) {
        return true;
      } else return false;
    } else return false;
  }

}
