import { Component, Input, OnInit } from '@angular/core';
import { IntegrationsService } from 'src/app/core/services/integrations/integrations.service';

@Component({
  selector: 'app-job-logs',
  templateUrl: './job-logs.component.html',
  styleUrl: './job-logs.component.scss'
})
export class JobLogsComponent implements OnInit {
  @Input() oauth;

  logs;

  loading = true;

  constructor(private integrationService: IntegrationsService) { }

  ngOnInit() {
    this.integrationService.getJobLogs(this.oauth.id).subscribe((logs) => {
      this.loading = false;
      this.logs = logs;
    });
  }
}
