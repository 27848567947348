import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash-es';

@Pipe({
  name: 'visitTimelineGetSize',
})
export class VisitTimelineGetSizePipe implements PipeTransform {
  transform(items): any {
    let sum = 0;
    (<any>Object).values(items).forEach(event => {
      sum += event;
    });
    return sum;
  }
}