<div class="modal-header">
  <div class="modal-title">
    <h3 class="mb-0 mt-0">{{ filterInfo ? 'Edit Filter' : 'Add Filter' }}</h3>
    <small>Filters are used to segment visitors based on params, referrers or paths that associated with their visit. <a href="https://docs.attributionapp.com/docs/channels-filters" target="_blank" class="blue text-underline h-dark-blue">Learn how to use them</a>.</small>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="filterIntegration" class="alert modal_alert alert-warning">
  <i class="fa-solid fa-triangle-exclamation"></i>
  This filter was created automatically when you connected with {{ filterIntegration | uppercase }}. If parameters below don't describe your campaign properly you can update them manually.
</div>
<div class="alert modal_alert alert-warning small" *ngIf="openSection === 'path'">
  Please consult Attribution Support before creating <strong>Path</strong> filters. These filters are designed to track hidden landing pages which are not directly accessible though your website, search engines and don't have any other trackable parameters. If your case is other than that - <u>please don't use the "Path" type filter</u>. If you want to see your landing paths performance use <strong>Show Landing Paths</strong> from channel menu.
</div>
<div class="modal-body modal-body--source">
  <nav class="navbar navbar-expand-lg">
    <ul class="navbar-nav me-auto">
      <li class="nav-item" [class.active]="openSection === 'param'">
        <a class="nav-link" (click)="switchTo('param')">
          <span class="nav_underline_wrap">
            <span class="nav_underline">URL PARAMS</span>
          </span>
        </a>
      </li>
      <li class="nav-item" [class.active]="openSection === 'referrer'">
        <a class="nav-link" (click)="switchTo('referrer')">
          <span class="nav_underline_wrap">
            <span class="nav_underline">REFERRER</span>
          </span>
        </a>
      </li>
      <li class="nav-item" [class.active]="openSection === 'organic'">
        <a class="nav-link" (click)="switchTo('organic')">
          <span class="nav_underline_wrap">
            <span class="nav_underline">ORGANIC</span>
          </span>
        </a>
      </li>
      <li class="nav-item" [class.active]="openSection === 'path'">
        <a class="nav-link" (click)="switchTo('path')">
          <span class="nav_underline_wrap">
            <span class="nav_underline">PATH</span>
          </span>
        </a>
      </li>
      @if (project?.options?.features?.event_filters || !isProd) {
        <li class="nav-item" [class.active]="openSection === 'event'">
          <a class="nav-link" (click)="switchTo('event')">
            <span class="nav_underline_wrap">
              <i class="fa-solid fa-lock red me-1" *ngIf="!project?.options?.features?.event_filters"></i>
              <span class="nav_underline">EVENT</span>
            </span>
          </a>
        </li>
      }
    </ul>
  </nav>
  <form [formGroup]="filterForm">
    <ng-template #pathRows
      let-shouldShowOR="shouldShowOR">
      <div class="row">
        <div class="col-4">
          <div class='input-row' formArrayName="formPathTypes" *ngFor="let path of filterForm.get('formPathTypes').controls; let i=index">
            <ng-select [class.mt-3]="i !== 0" [formControlName]="i" placeholder="Operator">
              <ng-option value="begins">Begins</ng-option>
              <ng-option value="equals">Equals</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-8">
          <div class='input-row' formArrayName="formPaths" *ngFor="let path of filterForm.get('formPaths').controls; let i=index">
            <div [class.mt-3]="i !== 0" class="input-group">
              <input class="form-control" type="text" [formControlName]="i" placeholder="/home" value="/" maxlength="100" (keypress)="path_omit_special_char($event)">
              <span *ngIf="filterForm.get('formPaths').controls.length > 1" class="input-group-text clickable" (click)="removeField('path', i)"><i class="fa-solid fa-circle-xmark blue"></i></span>
            </div>
            <div *ngIf="i === pathsArray.length-1 && shouldShowOR" class="row-add">
              <span class="caps">or </span><i class="fa-solid fa-lg fa-circle-plus blue clickable" (click)="addField('path')"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #pathFilteringToggle>
      <div class="d-flex clickable justify-content-start mt-5 bt-light" (click)="togglePathFiltering()">
        <div class="d-flex align-items-center">
          <i *ngIf="pathFilteringActive" class="fa-solid fa-square-check green me-2"></i>
          <i *ngIf="!pathFilteringActive" class="fa-regular fa-square medium me-2"></i>
          <span class="caps">Path Filtering</span>
          <span class="badge path-filtering-beta text-bg-warning ms-1">beta</span>
        </div>
      </div>
    </ng-template>
    <div class="filter_title">
      <label class="caps">Name</label>
      <input class="form-control" formControlName="name" autofocus placeholder="Untitled traffic source">
    </div>
    <div class="filter-params" [ngSwitch]="openSection">
      <div *ngSwitchCase="'param'">
        <div class='mt-4 mb-1 caps'>
          SOURCE LINK EQUALS:
        </div>
        <div class="row">
          <div class="col-4">
            <div class='input-row' formArrayName="formSourceTypes" *ngFor="let paramType of filterForm.get('formSourceTypes').controls; let i=index">
              <ng-select [class.mt-3]="i !== 0" [formControlName]="i" [addTag]="true" placeholder="Enter param key">
                <ng-option value="utm_source">utm_source</ng-option>
                <ng-option value="utm_medium">utm_medium</ng-option>
                <ng-option value="utm_term">utm_term</ng-option>
                <ng-option value="utm_content">utm_content</ng-option>
                <ng-option value="utm_campaign">utm_campaign</ng-option>
              </ng-select>
              <div *ngIf="i === paramsArray.length-1" class="row-add">
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class='input-row' formArrayName="formParams" *ngFor="let param of filterForm.get('formParams').controls; let i=index">
              <div [class.mt-3]="i !== 0" class="input-group">
                <input class="form-control" type="text" [formControlName]="i" placeholder="Enter parameter value">
                <span *ngIf="filterForm.get('formParams').controls.length > 1" class="input-group-text clickable" (click)="removeField('param', i)"><i class="fa-solid fa-circle-xmark blue"></i></span>
              </div>
              <div *ngIf="i === paramsArray.length-1 && filterForm.get('formParams').controls.length < 4" class="row-add">
                <span class="caps">and </span><i class="fa-solid fa-lg fa-circle-plus blue cursor-pointer" (click)="addField('param')"></i>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="pathFilteringToggle"></ng-container>
        <div *ngIf="pathFilteringActive" class="mt-4 position-relative">
          <ng-container *ngTemplateOutlet="pathRows; context: {shouldShowOR: true}"></ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'referrer'">
        <div class='mt-4 mb-1 caps'>
          REFERRING HOST
        </div>
        <div class="row">
          <div class="col-4">
            <div class='input-row' formArrayName="formReferrerTypes" *ngFor="let reffererType of filterForm.get('formReferrerTypes').controls; let i=index">
              <ng-select [class.mt-3]="i !== 0" [formControlName]="i" placeholder="Operator">
                <ng-option value="contains">Contains</ng-option>
                <ng-option value="equals">Equals</ng-option>
              </ng-select>
              <div *ngIf="i === referrersArray.length-1" class="row-add">
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class='input-row' formArrayName="formReferrers" *ngFor="let referrer of filterForm.get('formReferrers').controls; let i=index">
              <div [class.mt-3]="i !== 0" class="input-group">
                <input class="form-control" type="text" [formControlName]="i" placeholder="google" (keypress)="referrer_omit_special_char($event)">
                <span *ngIf="filterForm.get('formReferrers').controls.length > 1" class="input-group-text clickable" (click)="removeField('referrer', i)"><i class="fa-solid fa-circle-xmark blue"></i></span>
              </div>
              <div *ngIf="i === referrersArray.length-1" class="row-add">
                <span class="caps">or </span><i class="fa-solid fa-lg fa-circle-plus blue clickable" (click)="addField('referrer')"></i>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="pathFilteringToggle"></ng-container>
        <div *ngIf="pathFilteringActive" class="mt-4">
          <ng-container *ngTemplateOutlet="pathRows; context: {shouldShowOR: true}"></ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'path'">
        <div class='mt-4 mb-1 caps'>
          Path
        </div>
        <ng-container *ngTemplateOutlet="pathRows; context: {shouldShowOR: true}"></ng-container>
      </div>
      <div *ngSwitchCase="'organic'">
        <div class='mt-4 mb-1 caps'>
          REFERRING HOST
        </div>
        <div class="row">
          <div class="col-4">
            <div class='input-row' formArrayName="formReferrerTypes" *ngFor="let reffererType of filterForm.get('formReferrerTypes').controls; let i=index">
              <ng-select [class.mt-3]="i !== 0" placeholder="Operator" [formControlName]="i">
                <ng-option value="contains">Contains</ng-option>
                <ng-option value="equals">Equals</ng-option>
              </ng-select>
              <div *ngIf="i === referrersArray.length-1" class="row-add">
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class='input-row' formArrayName="formReferrers" *ngFor="let referrer of filterForm.get('formReferrers').controls; let i=index">
              <div [class.mt-3]="i !== 0" class="input-group">
                <input class="form-control" type="text" [formControlName]="i" placeholder="google">
                <span *ngIf="filterForm.get('formReferrers').controls.length > 1" class="input-group-text clickable" (click)="removeField('referrer',i)"><i class="fa-solid fa-circle-xmark blue"></i></span>
              </div>
              <div *ngIf="i === referrersArray.length-1" class="row-add">
                <span class="caps">or </span><i class="fa-solid fa-lg fa-circle-plus blue clickable" (click)="addField('referrer')"></i>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="pathFilteringToggle"></ng-container>
        <div *ngIf="pathFilteringActive" class="mt-4 position-relative">
          <ng-container *ngTemplateOutlet="pathRows; context: {shouldShowOR: true}"></ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'event'">
        <div class="mt-3">
          <label class="caps">Event Name</label>
          <input class="form-control" formControlName="eventName" placeholder="Closed Won">
        </div>
        <div class='mt-4 mb-1 caps'>
          Properties
        </div>
        <div class="row">
          <div class="col-4">
            <div class='input-row' formArrayName="formProperties" *ngFor="let paramType of filterForm.get('formProperties').controls; let i=index">
              <input class="form-control" type="text" [formControlName]="i" placeholder="Property Key" [class.mt-3]="i !== 0">
            </div>
          </div>
          <div class="col-8">
            <div class='input-row' formArrayName="formPropertyValues" *ngFor="let param of filterForm.get('formPropertyValues').controls; let i=index">
              <div [class.mt-3]="i !== 0" class="input-group">
                <input class="form-control" type="text" [formControlName]="i" placeholder="Property value">
                <span *ngIf="filterForm.get('formPropertyValues').controls.length > 0" class="input-group-text clickable" (click)="removeField('event', i)"><i class="fa-solid fa-circle-xmark blue"></i></span>
              </div>
            </div>
            <div *ngIf="filterForm.get('formPropertyValues').controls.length <= 3" class="text-right" [class.mt-3]="filterForm.get('formPropertyValues').controls.length > 0">
              <span class="caps">Add Property Field </span><i class="fa-solid fa-lg fa-circle-plus blue cursor-pointer" (click)="addField('event')"></i>
            </div>
          </div>
        </div>
        <div class="d-flex clickable justify-content-start mt-5 bt-light" (click)="toggleOnlyUnattributed()">
          <div class="d-flex align-items-center">
            <i *ngIf="onlyUnattributed" class="fa-solid fa-square-check green me-2"></i>
            <i *ngIf="!onlyUnattributed" class="fa-regular fa-square medium me-2"></i>
            <span>Only capture <b>Unknown Source</b> traffic</span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="mt-5" [ngSwitch]="openSection">
    <ng-container *ngSwitchCase="'param'">
      <div class="alert alert-info">
        <div class="red" *ngIf="pathFilteringActive && pathsArray.length > 0 && pathLacksSlash">
          Each of the path needs to start with '/'
        </div>
        <i class="fa-solid fa-code"></i>
        <small> https://yourwebsite.com/?
          <span *ngFor="let param of paramsArray; let i=index">
            <span *ngIf="i !== 0">&</span><strong>{{ formSourceTypes.controls[i].value ? formSourceTypes.controls[i].value.label? formSourceTypes.controls[i].value.label : formSourceTypes.controls[i].value : 'utm_medium'}}</strong>=<strong>{{ formParams.controls[i].value? formParams.controls[i].value : 'ppc'}}</strong>
          </span>
        </small>
        <span *ngIf="pathFilteringActive && pathsArray.length > 0">
          <span class="ms-1 me-1">and landing path</span>
          <span *ngFor="let path of pathsArray; let i=index">
            <span *ngIf="formPaths.controls[i].value !== null && formPaths.controls[i].value.length > 0">
              <span *ngIf="i !== 0 && isPreviusWithValue(formPaths.controls,i)"> OR </span>
              {{ formPathTypes.controls[i]?.value === 'begins' ? 'beginning with' : formPathTypes.controls[i]?.value === 'equals' ? 'equal to' : formPathTypes.controls[i]?.value == 'contains' ? 'containing' : null }}
              <b>{{ formPaths.controls[i].value? formPaths.controls[i].value : ''}}</b>
            </span>
          </span>
        </span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'referrer'">
      <div class="alert alert-info">
        <div class="red" *ngIf="pathFilteringActive && pathsArray.length > 0 && pathLacksSlash">
          Each of the path needs to start with '/'
        </div>
        <i class="fa-solid fa-earth-americas"></i> Includes anyone who came from referring host
        <span *ngFor="let referrer of referrersArray; let i=index">
          <span *ngIf="formReferrers.controls[i].value !== null && formReferrers.controls[i].value.length > 0">
            <span *ngIf="i !== 0 && isPreviusWithValue(formReferrers.controls,i)"> OR </span>
            {{ formReferrerTypes.controls[i]?.value === 'contains' ? 'containing' : formReferrerTypes.controls[i]?.value === 'equals' ? 'equal to' : '' }}
            <b>{{ formReferrers.controls[i].value? formReferrers.controls[i].value : ''}}</b>
          </span>
        </span>
        <span *ngIf="pathFilteringActive && pathsArray.length > 0">
          <span class="ms-1 me-1">and landing path</span>
          <span *ngFor="let path of pathsArray; let i=index">
            <span *ngIf="formPaths.controls[i].value !== null && formPaths.controls[i].value.length > 0">
              <span *ngIf="i !== 0 && isPreviusWithValue(formPaths.controls,i)"> OR </span>
              {{ formPathTypes.controls[i]?.value === 'begins' ? 'beginning with' : formPathTypes.controls[i]?.value === 'equals' ? 'equal to' : formPathTypes.controls[i]?.value == 'contains' ? 'containing' : null }}
              <b>{{ formPaths.controls[i].value? formPaths.controls[i].value : ''}}</b>
            </span>
          </span>
        </span>
      </div>
      <div class="alert alert-warning mt-3">
        REFERRING HOST must be minumum 5 character long and contain a dot . (special characters are prohibited)
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'organic'">
      <div class="alert alert-info">
        <div class="red" *ngIf="pathFilteringActive && pathsArray.length > 0 && pathLacksSlash">
          Each of the path needs to start with '/'
        </div>
        <i class="fa-solid fa-earth-americas"></i> Includes anyone who came from referring host
        <span *ngFor="let referrer of referrersArray; let i=index">
          <span *ngIf="formReferrers.controls[i].value !== null && formReferrers.controls[i].value.length > 0">
            <span *ngIf="i !== 0 && isPreviusWithValue(formReferrers.controls,i)"> OR </span>
            {{ formReferrerTypes.controls[i]?.value === 'contains' ? 'containing' : formReferrerTypes.controls[i]?.value === 'equals' ? 'equal to' : '' }}
            <b>{{ formReferrers.controls[i].value? formReferrers.controls[i].value : ''}}</b>
          </span>
        </span>
        <span *ngIf="pathFilteringActive && pathsArray.length > 0">
          <span class="ms-1 me-1">and landing path</span>
          <span *ngFor="let path of pathsArray; let i=index">
            <span *ngIf="formPaths.controls[i].value !== null && formPaths.controls[i].value.length > 0">
              <span *ngIf="i !== 0 && isPreviusWithValue(formPaths.controls,i)"> OR </span>
              {{ formPathTypes.controls[i]?.value === 'begins' ? 'beginning with' : formPathTypes.controls[i]?.value === 'equals' ? 'equal to' : formPathTypes.controls[i]?.value == 'contains' ? 'containing' : null }}
              <b>{{ formPaths.controls[i].value? formPaths.controls[i].value : ''}}</b>
            </span>
          </span>
        </span>
        <span class="ms-1 me-1">with no url params</span>
      </div>
      <div class="alert alert-warning mt-3">
        REFERRING HOST must be minumum 5 character long and contain a dot . (special characters are prohibited)
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'path'">
      <div class="alert alert-info">
        <div class="red" *ngIf="pathLacksSlash">
          Each of the path needs to start with '/'
        </div>
        <i class="fa-solid fa-earth-americas"></i> Includes anyone who visited url
        <span *ngFor="let path of pathsArray; let i=index">
          <span *ngIf="formPaths.controls[i].value !== null && formPaths.controls[i].value.length > 0">
            <span *ngIf="i !== 0 && isPreviusWithValue(formPaths.controls,i)"> OR </span>
            {{ formPathTypes.controls[i]?.value === 'begins' ? 'beginning with' : formPathTypes.controls[i]?.value === 'equals' ? 'equal to' : formPathTypes.controls[i]?.value == 'contains' ? 'containing' : null }}
            <b>{{ formPaths.controls[i].value? formPaths.controls[i].value : ''}}</b>
          </span>
        </span>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer" [class.space-between]="filterInfo">
  <a *ngIf="filterInfo">
    <div *ngIf="filterIntegration === null">
      <span data-cy="filter-delete" *ngIf="!deleteWaiting; else deleteWaitingTemplate" (click)="delete()">
        <i class="fa-solid fa-trash red"></i>
        <span class="caps red"> delete</span>
      </span>
      <ng-template #deleteWaitingTemplate>
        <i class="fa-solid fa-spinner fa-spin red"></i>
      </ng-template>
    </div>
  </a>
  <button type='submit' class="btn btn-success" [class.disabled]="!isValid" (click)="submit()">
    <span *ngIf="!waitingForResponse; else waiting">
      <span class="caps">Save</span>
      <i class="ms-2 fa-solid fa-check-circle"></i>
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>
