import { Pipe, PipeTransform } from '@angular/core';
import { twoArraysEqual } from 'src/app/core/helpers/other';

@Pipe({
  name: 'CheckIfViewParamsChanged',
})
export class CheckIfViewParamsChangedPipe implements PipeTransform {
  transform(currentParams, viewParams, chosenDates): any {
    let changed = false;
    let changedParams = [];
    const PARAMS_TO_CHECK = [
        'attribution_model',
        'attribution_type',
        'aggregation_period',
        'conversion_event',
        'revenue_group_id',
        'cutoff_event',
        'traffic_option',
        'disregard_existing_users',
        'lookback_window',
        'daterange_preset',
        'hidden_filters',
        'hidden_filter_groups',
        'property_key',
        'property_value'
    ];
    for (let param of PARAMS_TO_CHECK) {
      let didChange;
      if (Array.isArray(currentParams[param])) {
        didChange = !twoArraysEqual(currentParams[param], viewParams[param]);
      } else {
        didChange = currentParams[param] !== viewParams[param];
      }
      if (param == 'daterange_preset' && currentParams.daterange_preset.includes(',')) {
        didChange = currentParams.daterange_preset != chosenDates.start+','+chosenDates.end;
      }
      if (didChange) changedParams.push(param);
      if (!changed) changed = didChange;
    }
    return {changed: changed, changedParams: changedParams};
  }
}