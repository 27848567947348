import { Pipe, PipeTransform } from '@angular/core';
import { isPageview } from 'src/app/core/helpers/other';

@Pipe({
  name: 'isPageView',
})
export class isPageViewPipe implements PipeTransform {
  transform(text): any {
    return isPageview(text);
  }
}