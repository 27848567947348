<div class="events-csv-builder">
    <div class="events-csv-builder__header">
        <div class="me-5">
            <h2>CSV Header Generator</h2>
        </div>
    </div>
    <div class="events-csv-builder__body">
        <h4>Required Fields</h4>
        <div class="events-csv-builder__required-fields">
            <div class="events-csv-builder__item default row ms-0 me-0">
                <div class="col-sm-4">
                    <div class="mt-2">Call Type</div>
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                    <div ngbDropdown>
                        <button type="button" class="btn btn-default btn-select btn-sm text-capitalize" id="dropdownBasic1" ngbDropdownToggle>
                            {{ selectedType}}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="selectType('track')">Track</button>
                            <button ngbDropdownItem (click)="selectType('identify')">Identify</button>
                            <button ngbDropdownItem (click)="selectType('page')">Page</button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #nameForm>
                <div class="col-sm-8 d-flex align-items-center">
                    <input type="text" class="form-control form-control-lg" id="name" name="name" [(ngModel)]="nameInput" />
                </div>
            </ng-template>
            <div *ngIf="selectedType === 'track'" class="events-csv-builder__item default row ms-0 me-0">
                <div class="col-sm-4">
                  <div class="mt-2">Event Name</div>
                </div>
                <ng-container [ngTemplateOutlet]="nameForm"></ng-container>
            </div>
            <div class="events-csv-builder__item default row ms-0 me-0">
                <div class="col-sm-4">
                    <div class="d-inline-block me-2">Track visitor by</div>
                    <div ngbDropdown class="d-inline-block">
                        <button type="button" class="btn btn-default btn-select btn-sm" id="dropdownBasic1" ngbDropdownToggle>
                            {{ selectedIdType}}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="selectIdType('user_id')">user_id</button>
                            <button ngbDropdownItem (click)="selectIdType('anonymous_id')">anonymous_id</button>
                        </div>
                    </div>
                    <div class="small medium-dark" *ngIf="selectedIdType === 'anonymous_id'">
                        Each <b>anonymous_id</b> must be globally unique
                    </div>
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                    <input type="text" class="form-control form-control-lg" id="userId" name="userId" [(ngModel)]="userIdInput" />
                </div>
            </div>
            <div *ngIf="selectedType !== 'identify'" class="events-csv-builder__item default row ms-0 me-0">
                <div class="col-sm-4">
                    <div class="d-inline-block">
                        Time format&nbsp;
                    </div>
                    <div ngbDropdown class="d-inline-block">
                        <button type="button" class="btn btn-default btn-select btn-sm" id="dropdownBasic1" ngbDropdownToggle>
                            {{ isTimestamp ? 'Timestamp' : 'Date/Time/Timezone'}}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="isTimestamp = true;">Timestamp</button>
                            <button ngbDropdownItem (click)="isTimestamp = false">Date/Time/Timezone</button>
                        </div>
                    </div>
                    <div>
                      <div *ngIf="isTimestamp; else dateTimeTimezone" class="small mt-2 medium-dark">
                        <a href="https://en.wikipedia.org/wiki/ISO_8601" target="_blank">ISO8601</a>
                      </div>
                      <ng-template #dateTimeTimezone>
                          <div class="small mt-2 medium-dark">
                              <strong>Date</strong> - `YYYY-MM-DD`
                          </div>
                          <div class="small mt-2 medium-dark">
                              <strong>Time</strong> - `hh:mm:ss`
                          </div>
                          <div class="small mt-2 medium-dark">
                              <strong>Timezone</strong> - `<a href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones#List" target="_blank">America/New_York</a>`
                          </div>
                      </ng-template>
                  </div>

                </div>
                <div class="col-sm-8 d-flex flex-column">
                    <input *ngIf="isTimestamp; else dateTimeTimezoneInputs" type="text" class="form-control form-control-lg" id="timestamp" name="timestamp" [(ngModel)]="timestampInput" />
                    <ng-template #dateTimeTimezoneInputs>
                        <div class="small mt-2">
                            <input type="text" class="form-control form-control-lg" id="dateInput" name="dateInput" [(ngModel)]="dateInput" />
                        </div>
                        <div class="small mt-2">
                            <input type="text" class="form-control form-control-lg" id="timeInput" name="timeInput" [(ngModel)]="timeInput" />
                        </div>
                        <div class="small mt-2">
                            <input type="text" class="form-control form-control-lg" id="timezoneInput" name="timezoneInput" [(ngModel)]="timezoneInput" />
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="(selectedType === 'track' || selectedType === 'page') || selectedIdType !== 'user_id'">
            <h4>Optional Fields</h4>
            <div class="events-csv-builder__optional-fields">
                <div *ngIf="selectedType === 'page'" class="events-csv-builder__item default row ms-0 me-0">
                    <div class="col-sm-4 clickable" (click)="nameChecked = !nameChecked; nameInput = 'Landing A'">
                        <i *ngIf="nameChecked" class="fa-solid fa-circle-check me-2 green"></i>
                        <i *ngIf="!nameChecked" class="fa-regular fa-circle me-2 medium"></i>
                        Page name
                    </div>
                    <ng-container *ngIf="nameChecked" [ngTemplateOutlet]="nameForm"></ng-container>
                </div>
                <div *ngIf="selectedIdType !== 'user_id'" class="events-csv-builder__item default row ms-0 me-0">
                    <div class="col-sm-4 clickable" (click)="trackByEmailChecked = !trackByEmailChecked">
                        <i *ngIf="trackByEmailChecked" class="fa-solid fa-circle-check me-2 green"></i>
                        <i *ngIf="!trackByEmailChecked" class="fa-regular fa-circle me-2 medium"></i>
                        <i>Track by Email</i>
                        <div class="small medium-dark" *ngIf="trackByEmailChecked">
                            When enabled will try to find existing visitor by provided email and fallback to <b>anonymous_id</b> otherwise
                        </div>
                    </div>
                    <div class="col-sm-8 d-flex align-items-center">
                        <input *ngIf="trackByEmailChecked" type="text" class="form-control form-control-lg" id="emailInput" name="emailInput" [(ngModel)]="emailInput" />
                    </div>
                </div>
                <div *ngIf="selectedType === 'track' || selectedType === 'page'" class="events-csv-builder__item default row ms-0 me-0">
                    <div class="col-sm-4 clickable" (click)="messageIdChecked = !messageIdChecked">
                        <i *ngIf="messageIdChecked" class="fa-solid fa-circle-check me-2 green"></i>
                        <i *ngIf="!messageIdChecked" class="fa-regular fa-circle me-2 medium"></i>
                        <i>Message Id</i>
                        <div class="small medium-dark" *ngIf="messageIdChecked">
                            Must be globally unique for each event
                        </div>
                    </div>
                    <div class="col-sm-8 d-flex align-items-center">
                        <input *ngIf="messageIdChecked" type="text" class="form-control form-control-lg" id="messageIdInput" name="messageIdInput" [(ngModel)]="messageIdInput" />
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="selectedType === 'page' || selectedType === 'track'; else trackFields">
            <h4>Properties</h4>
            <div class="events-csv-builder__properties-fields">
                <div *ngIf="selectedType === 'page'" class="events-csv-builder__item default row ms-0 me-0">
                    <div class="col-sm-12">
                        <strong>url</strong> is required & <strong>referrer</strong> is strongly recommended
                    </div>
                </div>
                <form [formGroup]="propertiesForm">
                    <div class="events-csv-builder__item row ms-0 me-0" [class.no-border]="selectedType === 'page'" *ngFor="let item of properties; let i = index;">
                        <div class="col-sm-3">
                            <div class="events-csv-builder__item--fields">
                                <input (keyup)="inputChanged(item, $event)" class="form-control form-control-lg me-2" placeholder="Field Name" value="{{item.n}}">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <input class="form-control form-control-lg" type="text" [formControlName]="[i]" placeholder="Enter parameter value">
                        </div>
                        <div class="col-sm-1">
                            <i class="fa-solid fa-times red clickable mt-3" (click)="removeChild(i, properties, 'properties')" ngbTooltip="Remove"></i>
                        </div>
                    </div>
                    <div class="events-csv-builder__item ms-0 me-0 add-item default justify-content-center text-center" [class.no-border]="selectedType === 'page' && properties.length === 0" (click)="addProperty()" ngbTooltip="Add property field">
                        <i class="fa-solid fa-plus blue me-2"></i>add property
                    </div>
                </form>
            </div>
        </div>

        <ng-template #trackFields>
            <h4>Traits</h4>
            <div class="events-csv-builder__trait-fields">
                <div class="events-csv-builder__item default row ms-0 me-0">
                    <div class="col-sm-12">
                        <strong>email</strong> is required for conversion integrations to work properly
                    </div>
                </div>
                <form [formGroup]="traitsForm">
                    <div class="events-csv-builder__item row no-border ms-0 me-0" *ngFor="let item of traits; let i = index;">
                        <div class="col-sm-3">
                            <div class="events-csv-builder__item--fields">
                                <input (keyup)="inputChanged(item, $event)" class="form-control form-control-lg me-2" placeholder="Trait Name" value="{{item.n}}">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <input class="form-control form-control-lg" type="text" [formControlName]="[i]" placeholder="Trait value">
                        </div>
                        <div class="col-sm-1">
                            <i class="fa-solid fa-times red clickable mt-3" (click)="removeChild(i, traits, 'traits')" ngbTooltip="Remove"></i>
                        </div>
                    </div>
                    <div class="events-csv-builder__item row ms-0 me-0 add-item default justify-content-center text-center" [class.no-border]="traits.length === 0" (click)="addTrait()" ngbTooltip="Add trait field">
                        <i class="fa-solid fa-plus blue me-2"></i>add property
                    </div>
                </form>
            </div>
        </ng-template>


        <!-- <h4>Additional Fields</h4>
        <div class="events-csv-builder__additional-fields">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: csvArray, initial: true, parentIndex: null }"></ng-container>
            <ng-template
                #recursiveList
                let-list
                let-isInitial="initial"
                let-parentIndex="parentIndex">
                <div class="events-csv-builder__item" *ngFor="let item of list; let i = index;" [class.child]="!isInitial">
                    <div class="events-csv-builder__item--fields">
                        <input (keyup)="inputChanged(item, $event)" class="form-control me-2" placeholder="Field Name">
                        <i *ngIf="isInitial" class="fa-solid fa-plus blue clickable me-2" (click)="addChild(item)" ngbTooltip="Add child"></i>
                        <i class="fa-solid fa-times red clickable" (click)="removeChild(i, list)" ngbTooltip="Remove"></i>
                    </div>
                    <div class="d-inline-block full-width" *ngIf="item.c?.length > 0">
                        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item?.c, initial: false, parentIndex: i }"></ng-container>
                    </div>
                </div>
            </ng-template>

            <div class="events-csv-builder__item add-item default justify-content-center" (click)="addField()" ngbTooltip="Add additional field">
                <i class="fa-solid fa-plus blue"></i>
            </div>
        </div> -->

        <div class="btn btn-success full-width d-flex justify-content-center align-items-center mt-4" (click)="downloadCSVHeaders()">
            <i class="fa-solid fa-file-arrow ms-0 me-0-down blue me-3"></i> Download CSV Headers
        </div>
    </div>
    <div class="events-csv-builder__footer">
        <h2>CSV Uploader</h2>
        <div class="mb-3" *ngIf="validateError">
            <small class="red">Error: {{ validateError }}</small>
            <small class="red d-block" *ngIf="validateErrorLine">{{ validateErrorLine }}</small>
            <small class="red d-block" *ngIf="validateErrorLineNumber">{{ validateErrorLineNumber }}</small>
        </div>
        <div class="mt-3 mb-3" *ngIf="uploadError">
            <small class="red d-block">Error: {{ uploadError }}</small>
            <small class="red d-block" *ngIf="errorLine">{{ errorLine }}</small>
            <small class="red d-block" *ngIf="errorLineNumber">{{ errorLineNumber }}</small>
        </div>
        <div class="d-flex align-items-center">
            <div class="mt-2">
                <label for="selectFile">
                    <span class="btn btn-default cursor-pointer">
                        <i class="fa-solid fa-upload blue"></i>
                        Select File
                    </span>
                </label>
                <input data-file name="selectFile" type="file" id="selectFile" style="position: fixed; top: -100em;" (change)="onSelect($event.target.files)" />
            </div>
            <i class="fa-solid ms-3 me-3 fa-chevron-right medium-dark fa-lg"></i>
            <div class="btn btn-default cursor-pointer" [class.disabled]="!validateActive || validateInProgress" (click)="validate()">
                @if (validateInProgress) {
                    <i class="fa-solid fa-spinner fa-spin blue me-1"></i>
                    Validating
                } @else {
                    <i class="fa-solid fa-thumbs-up blue me-1"></i>
                    Validate
                }
            </div>
            <i class="fa-solid ms-3 me-3 fa-chevron-right medium-dark fa-lg"></i>
            <div class="btn btn-default cursor-pointer" [class.disabled]="!processActive || processingInProgress" (click)="process()">
                @if (processingInProgress) {
                    <i class="fa-solid fa-spinner fa-spin blue me-1"></i>
                    Processing
                } @else {
                    <i class="fa-solid fa-gears blue me-1"></i>
                    Process
                }
            </div>
        </div>
        <div>
            <p *ngIf="selectedFile" class="green">
                "{{ selectedFile.name }}" selected
            </p>
            <p *ngIf="afterValidateRecordsCounts" class="green">
                File is valid and contain {{ afterValidateRecordsCounts }} records
            </p>
            <p *ngIf="fileProcessed" class="green">
                File processed successfully
            </p>
        </div>
    </div>
</div>
