import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';

import { ConversionEventService } from 'src/app/core/services/conversion-event/conversion-event.service';
import { ConversionEventsState, ConversionEvents } from 'src/app/store/states/conversion-events.state';
import { ConversionEvent } from 'src/app/core/models/conversionEvent.model';
import { SetConversionEvent } from 'src/app/store/actions/selected.actions';
import { SelectedStateModel, SelectedState } from 'src/app/store/states/selected.state';
import { find } from 'lodash-es';
import { BaseStateModel } from 'src/app/store/states/base.state';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { View } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetProjectSettings } from 'src/app/store/actions/project.actions';

@Component({
  selector: 'app-conversion-event-selector',
  templateUrl: './conversion-event-selector.component.html',
  styleUrls: ['./conversion-event-selector.component.scss']
})
export class ConversionEventSelectorComponent implements OnInit, OnChanges {
  @Select(ConversionEventsState) conversionEventsState$: Observable<ConversionEvents>;
  @Select(state => state.base) baseState$: Observable<BaseStateModel>;
  @Select(SelectedState) selectedState$: Observable<SelectedStateModel>;
  @Select( state => state.project) project$: Observable<ProjectStateModel>;
  @Input() isCutoff? = false;
  @Input() disabled? = false;
  @Input() eventFromComparison: any = false;
  @Input() btnClass? = null;

  @Output() selectedCutoffEvent = new EventEmitter<any>();

  view: View;
  hideSelect = true;
  conversionEvents: Array<ConversionEvent>;
  selectedEvent: ConversionEvent;
  isAuthenticated: boolean;
  originalSelectedEvent: ConversionEvent;
  project;

  favorites = [];

  constructor(private eventService: ConversionEventService, private store: Store, private projectService: ProjectService) {}

  ngOnInit() {
    this.baseState$.subscribe(state => {
      this.isAuthenticated = state.isAuthenticated;
    });
    combineLatest([this.project$, this.conversionEventsState$]).subscribe(([project, conversionEvents]) => {
      this.project = project?.project;
      this.favorites = project?.project?.settings?.pinned_conversion_events || [];
      this.favorites.sort((a,b) => {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      });
      if (conversionEvents.events != null) {
        this.conversionEvents = conversionEvents.events.slice().sort( (a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });

        if (this.favorites.length > 0) {
          this.conversionEvents = [...this.favorites, {name: 'divider', revenue: false, disabled: true}, ...this.conversionEvents];
        }
      } else {
        this.eventService.getEventNames().subscribe();
      }
      this.view = project.currentViewParams;
      if (!this.isCutoff && !this.eventFromComparison) {
        const event = find(this.conversionEvents, y => y.name == this.view?.conversion_event);
        this.selectedEvent = event ? event : {name: this.view?.conversion_event, revenue: false};
      } else {
        if (this.eventFromComparison) {
          this.selectedEvent = this.eventFromComparison;
        } else {
          this.selectedEvent = find(this.conversionEvents, y => y.name == this.view?.cutoff_event);
        }
      }
      this.originalSelectedEvent = this.selectedEvent;
    });
  }

  ngOnChanges() {
    if (this.isCutoff) {
      this.selectedEvent = find(this.conversionEvents, y => y.name == this.view?.cutoff_event);
    }
    if (this.eventFromComparison) {
      this.selectedEvent = this.eventFromComparison;
    }
    this.originalSelectedEvent = this.selectedEvent;
  }

  selectEvent() {
    if (this.selectedEvent.name == 'divider') {
      this.selectedEvent = this.originalSelectedEvent;
      return;
    }
    if (!this.isCutoff && !this.eventFromComparison) {
      this.store.dispatch(new SetConversionEvent({local: false, event: this.selectedEvent}));
    } else {
      this.selectedCutoffEvent.emit(this.selectedEvent);
    }
  }

  openSelect(btn, select) {
    this.hideSelect = !this.hideSelect;
    select.open();
    setTimeout(() => { select.focus(); }, 100);
  }

  closeSelect() {
    this.hideSelect = !this.hideSelect;
    document.getElementsByClassName('btn-select')[0].classList.remove('hide');
  }

  isInFavorites(item) { 
    return typeof(this.favorites.find(f => f.name.toLowerCase() == item.name.toLowerCase() && f.revenue == item.revenue)) !== 'undefined';
  }

  toggleFavorite(item, $event) {
    $event.stopPropagation();
    let settings = this.project.settings;
    if (this.favorites.find(f => f.name.toLowerCase() == item.name.toLowerCase() && f.revenue == item.revenue)) {
      settings = {
        ...settings,
        pinned_conversion_events: this.favorites.filter(ce => !(item.name.toLowerCase() == ce.name.toLowerCase() && ce.revenue == item.revenue))
      }
    } else {
      settings = {
        ...settings,
        pinned_conversion_events: [...this.favorites, item]
      }
    }
    this.projectService.setAttribute('settings', settings).subscribe(() => {
      this.store.dispatch(new SetProjectSettings(settings));
    });
  }

}
