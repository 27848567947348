import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { CoreModule } from 'src/app/core/core.module';

import { ControlBarComponent } from './control-bar/control-bar.component';
import { ConversionEventSelectorComponent } from './components/conversion-event-selector/conversion-event-selector.component';
import { AttributionModelSelectorComponent } from './components/attribution-model-selector/attribution-model-selector.component';
import { FilterTreeSelectComponent } from './components/filter-tree-select/filter-tree-select.component';
import { AggregationPeriodSelectComponent } from './components/aggregation-period-select/aggregation-period-select.component';
import { ProjectOptionsComponent } from './components/project-options/project-options.component';
import { CpSummaryComponent } from './components/cp-summary/cp-summary.component';
import { AmountsSidebarComponent } from './sidebars/amounts-sidebar/amounts-sidebar.component';
import { NewProjectModalComponent } from './components/new-project-modal/new-project-modal.component';
import { NewFilterModalComponent } from './components/new-filter-modal/new-filter-modal.component';
import { CompleteSetupDialogComponent } from './components/complete-setup-dialog/complete-setup-dialog.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { ComboChartComponent } from './charts/combo-chart/combo-chart.component';
import { CpTotalsComponent } from './charts/cp-totals/cp-totals.component';
import { CpTotalsCompareComponent } from './components/cp-totals-compare/cp-totals-compare.component';
import { PresetSwitcherDropdownComponent } from './components/preset-switcher-dropdown/preset-switcher-dropdown.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { VisitsSidebarComponent } from './sidebars/visits-sidebar/visits-sidebar.component';
import { SidebarTitleComponent } from './components/sidebar-title/sidebar-title.component';
import { OverlapsSidebarComponent } from './sidebars/overlaps-sidebar/overlaps-sidebar.component';
import { RevenuesSidebarComponent } from './sidebars/revenues-sidebar/revenues-sidebar.component';
import { EventBreakdownComponent } from './sidebars/partials/event-breakdown/event-breakdown.component';
import { CompaniesSidebarComponent } from './sidebars/companies-sidebar/companies-sidebar.component';
import { UsersSidebarComponent } from './sidebars/users-sidebar/users-sidebar.component';
import { EmptySidebarComponent } from './components/empty-sidebar/empty-sidebar.component';
import { PropertiesSelectorComponent } from './components/properties-selector/properties-selector.component';
import { VisitorHistoryComponent } from './sidebars/visitor-history/visitor-history.component';
import { VisitTimelineComponent } from './sidebars/visitor-history/visit-timeline/visit-timeline.component';
import { PropertyValuesSelectorComponent } from './components/property-values-selector/property-values-selector.component';
import { CompanySidebarComponent } from './sidebars/company-sidebar/company-sidebar.component';
import { FilterIconComponent } from './components/filter-icon/filter-icon.component';
import { OauthRowComponent } from './components/oauth-row/oauth-row.component';
import { FilterErrorsModalComponent } from './components/filter-errors-modal/filter-errors-modal.component';
import { AdDestinationUrlsModalComponent } from './components/ad-destination-urls-modal/ad-destination-urls-modal.component';
import { RefreshButtonComponent } from './components/refresh-button/refresh-button.component';
import { CsvConfirmationComponent } from './components/csv-confirmation/csv-confirmation.component';
import { FilterBadgeComponent } from './components/filter-badge/filter-badge.component';
import { LoaderComponent } from './loader/loader.component';
import { ShopifyDialogComponent } from './components/shopify-dialog/shopify-dialog.component';
import { NgbDatepickerComponent } from './components/ngb-datepicker/ngb-datepicker.component';
import { CommaSeparatedListComponent } from './sidebars/visitor-history/visit-timeline/comma-separated-list/comma-separated-list.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { NgbSingleDatepickerComponent } from './components/ngb-single-datepicker/ngb-single-datepicker.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { AttributionTypeSelectorComponent } from './components/attribution-type-selector/attribution-type-selector.component';
import { CountUpModule } from 'ngx-countup';
import { FilterSearchComponent } from './components/filter-search/filter-search.component';
import { TimezoneSettingComponent } from './components/timezone-setting/timezone-setting.component';
import { OrderArrowComponent } from './components/order-arrow/order-arrow.component';
import { ChannelBuilderSidebarComponent } from './sidebars/channel-builder-sidebar/channel-builder-sidebar.component';
import { MultiLevelProgressbarComponent } from './components/multi-level-progressbar/multi-level-progressbar.component';
import { HorizontalChartComponent } from './charts/horizontal-chart/horizontal-chart.component';
import { RevenueGroupSelectorComponent } from './components/revenue-group-selector/revenue-group-selector.component';
import { RenameProjectComponent } from './components/rename-project/rename-project.component';
import { CpCompareVerticalComponent } from './components/cp-compare-vertical/cp-compare-vertical.component';
import { CpCompareVerticalChartComponent } from './charts/cp-compare-vertical-chart/cp-compare-vertical-chart.component';
import { NewVirtualFilterModalComponent } from './components/new-virtual-filter-modal/new-virtual-filter-modal.component';
import { GroupSelectorComponent } from './components/group-selector/group-selector.component';
import { NewChannelModalComponent } from './components/new-channel-modal/new-channel-modal.component';
import { DndModule } from 'ngx-drag-drop';
import { ConfirmDeleteModalComponent } from './components/confirm-delete-modal/confirm-delete-modal.component';
import { MultiLineChartComponent } from './charts/multi-line-chart/multi-line-chart.component';
import { SortComponent } from './components/sort/sort.component';
import { SankeyChartComponent } from './charts/sankey-chart/sankey-chart.component';
import { FilterSearchIsNotExcludedPipe } from './components/filter-search/pipes/is-not-excluded.pipe';
import { PeriodIsOkPipe } from './components/aggregation-period-select/period-is-active.pipe';
import { ViewSelectorComponent } from './components/view-selector/view-selector.component';
import { NewViewModalComponent } from './components/new-view-modal/new-view-modal.component';
import { SelectedParamsComponent } from './components/view-selector/selected-params/selected-params.component';
import { CheckIfViewParamsChangedPipe } from './components/view-selector/check-if-params-changed.pipe';
import { PulseLoaderComponent } from './components/pulse-loader/pulse-loader.component';
import { IsPresetPipe } from './components/view-selector/is-preset.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ControlbarShouldShowPipe } from './control-bar/pipes/should-show-views.pipe';
import { FiltersCsvUploadComponent } from './components/filters-csv-upload/filters-csv-upload.component';
import { isPageViewPipe } from './sidebars/visitor-history/visit-timeline/is-page-view.pipe';
import { SidebarBarChartNewComponent } from './charts/sidebar-bar-chart-new/sidebar-bar-chart-new.component';
import { SidebarBarChartComponent } from './charts/sidebar-bar-chart/sidebar-bar-chart.component';
import { ExtendedAdDestinationUrlsModalComponent } from './components/extended-ad-destination-urls-modal/extended-ad-destination-urls-modal.component';
import { ExtendedAdUrlsSumOfClicksPipe } from './components/extended-ad-destination-urls-modal/sum-of-clicks.pipe';
import { VisitTimelinePrependChannelNamePipe } from './sidebars/visitor-history/visit-timeline/prepend-channel-name.pipe';
import { VisitTimelineFilterTooltipPipe } from './sidebars/visitor-history/visit-timeline/filter-tooltip.pipe';
import { VisitTimelineVisitFiltersPipe } from './sidebars/visitor-history/visit-timeline/visit-filters.pipe';
import { VisitTimelineFilterTopGroupPipe } from './sidebars/visitor-history/visit-timeline/filter-top-group.pipe';
import { VisitTimelineGetSizePipe } from './sidebars/visitor-history/visit-timeline/get-size.pipe';
import { GroupSelectorWithoutSpecialsPipe } from './components/group-selector/without-specials.pipe';
import { FirstTimePurchaseComponent } from './components/first-time-purchase/first-time-purchase.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { IntegrationLogoComponent } from './components/integration-logo/integration-logo.component';
import { IsActiveEvnetPropertyPipe } from './sidebars/partials/event-breakdown/pipes/active-property.pipe';
import { IsProjectNewPipe } from './header/is-project-new.pipe';
import { IsInFavoritesPipe } from './components/conversion-event-selector/is-in-favorites.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    LoginModalComponent,
    ControlBarComponent,
    ConversionEventSelectorComponent,
    PropertiesSelectorComponent,
    PropertyValuesSelectorComponent,
    AttributionModelSelectorComponent,
    FilterTreeSelectComponent,
    AggregationPeriodSelectComponent,
    ProjectOptionsComponent,
    CpSummaryComponent,
    AmountsSidebarComponent,
    VisitsSidebarComponent,
    NewProjectModalComponent,
    NewFilterModalComponent,
    CompleteSetupDialogComponent,
    LineChartComponent,
    MultiLineChartComponent,
    BarChartComponent,
    ComboChartComponent,
    CpTotalsComponent,
    CpTotalsCompareComponent,
    PresetSwitcherDropdownComponent,
    TooltipComponent,
    VisitsSidebarComponent,
    SidebarTitleComponent,
    OverlapsSidebarComponent,
    RevenuesSidebarComponent,
    EventBreakdownComponent,
    CompaniesSidebarComponent,
    UsersSidebarComponent,
    EmptySidebarComponent,
    VisitorHistoryComponent,
    VisitTimelineComponent,
    CompanySidebarComponent,
    FilterIconComponent,
    OauthRowComponent,
    FilterErrorsModalComponent,
    AdDestinationUrlsModalComponent,
    ExtendedAdDestinationUrlsModalComponent,
    RefreshButtonComponent,
    FilterBadgeComponent,
    CsvConfirmationComponent,
    LoaderComponent,
    ShopifyDialogComponent,
    NgbDatepickerComponent,
    CommaSeparatedListComponent,
    StatusIconComponent,
    NgbSingleDatepickerComponent,
    ToggleSwitchComponent,
    AttributionTypeSelectorComponent,
    FilterSearchComponent,
    TimezoneSettingComponent,
    OrderArrowComponent,
    ChannelBuilderSidebarComponent,
    MultiLevelProgressbarComponent,
    HorizontalChartComponent,
    RevenueGroupSelectorComponent,
    RenameProjectComponent,
    CpCompareVerticalComponent,
    CpCompareVerticalChartComponent,
    NewVirtualFilterModalComponent,
    GroupSelectorComponent,
    NewChannelModalComponent,
    ConfirmDeleteModalComponent,
    SortComponent,
    SankeyChartComponent,
    FilterSearchIsNotExcludedPipe,
    PeriodIsOkPipe,
    ViewSelectorComponent,
    NewViewModalComponent,
    SelectedParamsComponent,
    CheckIfViewParamsChangedPipe,
    PulseLoaderComponent,
    IsPresetPipe,
    ControlbarShouldShowPipe,
    FiltersCsvUploadComponent,
    isPageViewPipe,
    SidebarBarChartComponent,
    SidebarBarChartNewComponent,
    ExtendedAdUrlsSumOfClicksPipe,
    VisitTimelinePrependChannelNamePipe,
    VisitTimelineFilterTooltipPipe,
    VisitTimelineVisitFiltersPipe,
    VisitTimelineFilterTopGroupPipe,
    VisitTimelineGetSizePipe,
    GroupSelectorWithoutSpecialsPipe,
    FirstTimePurchaseComponent,
    MultiSelectComponent,
    IntegrationLogoComponent,
    IsActiveEvnetPropertyPipe,
    IsProjectNewPipe,
    IsInFavoritesPipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    CoreModule,
    NgbModule,
    InfiniteScrollModule,
    CountUpModule,
    DndModule,
    ScrollingModule
  ],
  exports: [
    HeaderComponent,
    LoginModalComponent,
    ControlBarComponent,
    ConversionEventSelectorComponent,
    FilterTreeSelectComponent,
    AggregationPeriodSelectComponent,
    ProjectOptionsComponent,
    CpSummaryComponent,
    AmountsSidebarComponent,
    VisitsSidebarComponent,
    LineChartComponent,
    MultiLineChartComponent,
    BarChartComponent,
    ComboChartComponent,
    CpTotalsComponent,
    CpTotalsCompareComponent,
    PresetSwitcherDropdownComponent,
    TooltipComponent,
    SidebarTitleComponent,
    OverlapsSidebarComponent,
    RevenuesSidebarComponent,
    EventBreakdownComponent,
    CompaniesSidebarComponent,
    UsersSidebarComponent,
    EmptySidebarComponent,
    VisitorHistoryComponent,
    CompanySidebarComponent,
    FilterIconComponent,
    OauthRowComponent,
    FilterErrorsModalComponent,
    AdDestinationUrlsModalComponent,
    ExtendedAdDestinationUrlsModalComponent,
    RefreshButtonComponent,
    FilterBadgeComponent,
    CsvConfirmationComponent,
    LoaderComponent,
    StatusIconComponent,
    NgbSingleDatepickerComponent,
    ToggleSwitchComponent,
    FilterSearchComponent,
    TimezoneSettingComponent,
    OrderArrowComponent,
    ChannelBuilderSidebarComponent,
    MultiLevelProgressbarComponent,
    HorizontalChartComponent,
    RevenueGroupSelectorComponent,
    RenameProjectComponent,
    CpCompareVerticalComponent,
    CpCompareVerticalChartComponent,
    NewVirtualFilterModalComponent,
    NgbDatepickerComponent,
    CompleteSetupDialogComponent,
    GroupSelectorComponent,
    NewChannelModalComponent,
    ConfirmDeleteModalComponent,
    ShopifyDialogComponent,
    SortComponent,
    SankeyChartComponent,
    PeriodIsOkPipe,
    ViewSelectorComponent,
    NewViewModalComponent,
    SelectedParamsComponent,
    CheckIfViewParamsChangedPipe,
    PulseLoaderComponent,
    SidebarBarChartComponent,
    SidebarBarChartNewComponent,
    FiltersCsvUploadComponent,
    ExtendedAdUrlsSumOfClicksPipe,
    FirstTimePurchaseComponent,
    MultiSelectComponent,
    IntegrationLogoComponent,
    IsProjectNewPipe
  ],
  bootstrap: [
    NewProjectModalComponent,
    NewFilterModalComponent,
    FilterErrorsModalComponent,
    AdDestinationUrlsModalComponent,
    ExtendedAdDestinationUrlsModalComponent,
    CsvConfirmationComponent,
  ],
  providers: [
    CheckIfViewParamsChangedPipe
  ]
})
export class SharedModule { }
