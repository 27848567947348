export class OpenSidebar {
  static readonly type = '[sidebar] OpenSidebar';
  constructor(public payload: { openedSidebar: string, options: any }) {}
}

export class CloseSidebar {
  static readonly type = '[sidebar] CloseSidebar';
}

export class RefreshSidebar {
  static readonly type = '[sidebar] RefreshSidebar';
}
