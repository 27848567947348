import { Component, Output, EventEmitter, Input, OnInit, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { FilterService } from '../../../../core/services/filter/filter.service';
import { trim } from 'lodash-es';
import { LocalSubscriptionService } from 'src/app/core/services/local-subscription/local-subscription.service';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { Project } from 'src/app/core/models/project.model';
import { DateTime } from 'luxon';
import { IsNotFullPeriod } from 'src/app/core/helpers/other';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltersCsvUploadComponent } from 'src/app/modules/shared/components/filters-csv-upload/filters-csv-upload.component';

type Filter = {id: number, name: string};

@Component({
  selector: 'app-dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.scss']
})
export class DashHeaderComponent implements OnInit, OnChanges{
  @Select(state => state.project) projectState$: Observable<ProjectStateModel>;
  @Output() NewFilter = new EventEmitter();
  @Output() NewChannel = new EventEmitter();
  @Output() NewGroup = new EventEmitter();
  @Output() ToggleDrag = new EventEmitter();
  @Output() ToggleTimeframe = new EventEmitter();
  @Output() Sort = new EventEmitter();
  @Output() TableRevenueChanged = new EventEmitter();
  @Output() onSearchFilter = new EventEmitter();
  @Output() resetShouldResetSort = new EventEmitter();

  @Input() dragDisabled: boolean;
  @Input() sidebar: any;
  @Input() hasImpressions: boolean;
  @Input() hasClicks: boolean;
  @Input() withSpendNoVisits = [];
  @Input() totalAmounts;
  @Input() unattributedPercent;
  @Input() report;
  @Input() periodArray;
  @Input() isTimeframe;
  @Input() isFTP;
  @Input() tableRevenue;

  isProd = environment.isProd;

  sortHover: string;
  sortColumn: string;
  sortDirection: string;
  inSearchingState = false;
  q = '';
  foundFilter: Filter;
  noVisitsTotalAmount = 0;
  project: Project;
  tablePeriodsWithoutTotal = [];
  aggregationPeriod = 'Day';
  whitelistedProjectsForFiltersCsvUpload = [2646, 2700, 2687];
  isTimeframeOpen;

  @Input() shouldResetSort: boolean;

  constructor(
    private filterService: FilterService,
    private localSubscriptions: LocalSubscriptionService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.projectState$.subscribe(x => {
      this.project = x.project;
      this.isTimeframeOpen = this.project?.options?.reports?.timeframe;
      this.aggregationPeriod = x.currentViewParams?.aggregation_period || 'Day';
    });
  }

  ngOnChanges(changes) {
    if (this.shouldResetSort) {
      this.sortDirection = null;
      this.sortColumn = null;
      this.resetShouldResetSort.emit(true);
      return;
    }
    if (this.report && this.noVisitsTotalAmount == 0) {
      this.withSpendNoVisits.forEach(x => {
        if (this.report.filters[x.id].a) {
          this.noVisitsTotalAmount = this.noVisitsTotalAmount + this.report.filters[x.id].a;
        }
      });
    }
    if (this.isTimeframe && this.periodArray) {
      this.tablePeriodsWithoutTotal = this.fixLabels(this.periodArray);
    }
  }

  openNewFilterModal() {
    this.NewFilter.emit(true);
  }

  addNewChannel() {
    this.NewChannel.emit(true);
  }

  addNewGroup() {
    this.NewGroup.emit(true);
  }

  toggleDragging() {
    if (this.sidebar.openedSidebar === null) {
      this.ToggleDrag.emit(true);
    }
  }

  toggleTimeFrame() {
    this.ToggleTimeframe.emit(true);
  }

  sort(sortColumn) {
    const nextDirection = () => {
      switch (this.sortDirection) {
        case null:
          this.sortDirection = 'Down';
          break;
        case 'Down':
          this.sortDirection = 'Up';
          break;
        case 'Up':
          this.sortDirection = null;
          break;
      }
    };

    const checkSort = (column) => {
      if (this.sortColumn !== column) {
        this.sortDirection = 'Down';
      } else {
        nextDirection();
      }
      if (this.sortDirection !== null) {
        this.sortColumn = column;
      } else {
        this.sortColumn = null;
      }
    };

    if (this.dragDisabled) {
      checkSort(sortColumn);
      this.Sort.emit({ sortDirection: this.sortDirection, sortColumn: this.sortColumn});
    }
  }

  toggleSearchingState() {
    this.inSearchingState = !this.inSearchingState;
  }

  changeTableRevenue(type) {
    this.TableRevenueChanged.emit(type);
  }

  searchFormatter = (selectedFilter) => selectedFilter.name;

  findFilters = (text$: Observable<string>) => {
    text$.pipe(
      map(q => this.q = q)
    );
    return text$.pipe(
      filter(text => trim(text).length > 0),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(q => this.filterService.search(q))
    );
  }

  showSelectedFilter(event) {
    this.inSearchingState = false;
    this.q = '';
    this.onSearchFilter.emit(event);
  }

  scrollToUnattributed(){
    this.localSubscriptions.scrollToFilter('unattributed-filter');
  }

  get vGroupIsOpen() {
    if (this.project) {
      const openProjects = [2437];
      return openProjects.includes(this.project.id);
    } else return false;
  }

  private fixLabels(periods) {
    return periods.map( x => {
      return {
        ...x,
        label: this.periodLabel(x),
        isNotFullPeriod: this.isNotFullPeriod(x)
      }
    });
  }

  private periodLabel(period) {
    const aggregationPeriod = this.aggregationPeriod.toLowerCase();
    return aggregationPeriod == 'day' ? DateTime.fromISO(period.start).toLocaleString(DateTime.DATE_MED) : aggregationPeriod == 'week' ? `${DateTime.fromISO(period.start).toLocaleString(DateTime.DATE_SHORT)}-${DateTime.fromISO(period.end).toLocaleString(DateTime.DATE_SHORT)}` : period.label;
  }

  isNotFullPeriod(period) {
    return IsNotFullPeriod(period, this.aggregationPeriod);
  }

  openFiltersCsvUpload() {
    const modalRef = this.modalService.open(FiltersCsvUploadComponent, {windowClass: 'slideInDown'});
  }

}
