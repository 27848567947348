import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ErrorData } from '../../interceptors/http-status.interceptor';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { ComponentNames } from '../../enums/component-names';
import { SettingsSections } from '../../enums/settings-sections';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {

  constructor(private toastr: ToastrService, private router: Router, private authService: AuthService, private baseService: BaseService) { }

  checkHttpErrorResponse(data: ErrorData) {
    if (!data.requestUrl.includes(environment.clientApiUrl)  && !data.requestUrl.includes(environment.reportingApiUrl)) {
      return;
    }
    let message = data.reason;
    let status = data.status;
    let progressBar;

    if (data.error?.error?.skip_error_toast) {
      // Some errors are handled by the component itself, so we don't need to show a toast
      return;
    }

    switch (data.status) {
      case 0:
        message = 'Server error.';
        status = data.error.message;
        break;
      case 401:
        message = 'Authorization token is invalid, You have been redirected to the login page.';
        this.authService.logout();
        this.triggerToastr(status,message);
        this.router.navigate([ComponentNames.login]);
        break;
      case 402:
        this.router.navigate([ComponentNames.account, SettingsSections.billing]);
        this.triggerToastr("Credit Card Required", 'Failed to fetch data');
        return;
        break;
      case 404:
        message = `Could not find endpoint ${data.requestUrl}`;
        break;
      case 422:
        if (data.error?.error?.error === 'Filters not found.') {
          message = 'Your project is being created. Reloading in 10 seconds...';
          progressBar = 10000;
          setTimeout(() => {
            this.baseService.refreshAllData();
          }, 10001);
        } else {
          message = (data.error?.error?.error || data.error?.error?.message || data.error?.error) || 'Some Unknown error has occurred.';
        }
        break;
      case null:
        message = (data.error?.error?.error || data.error?.error?.message || data.error?.error);
        break;
      case 500:
        // Example of response with user-facing error:
        // {
        //   "error": {
        //     "message": "'cutoff_events' cannot be blank when 'traffic_option' includes cutoff",
        //     "class":"MissingCutoffEvent"
        //   }
        // }
        message = data.error?.error?.error || 'Internal server error.';
        status = '';
        break;
      default:
        message = (data.error?.error?.error || data.error?.error?.message || data.error?.error) || 'Some Unknown error has occurred.';
    }
    this.triggerToastr(status, message, progressBar);
  }

  triggerToastr(status, message, progressBar = null) {
    setTimeout(() => {
      if (progressBar) {
        this.toastr.error(status, message, {timeOut: progressBar, progressBar: true});
      } else {
        this.toastr.error(status, message, {disableTimeOut: true})
      }
    }
    );
  }

  integrationFailError(e, integrationName) {
    const status = e.status;
    const message = 'Error setting up your ' + integrationName + ' account: ' + e.error.message;
    this.triggerToastr(status, message);
  }

}
