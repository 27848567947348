import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { Project } from '../../models/project.model';
import { CohortStateModel } from 'src/app/store/states/cohort.state';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PropertiesStateModel } from 'src/app/store/states/properties.state';
import { SetCurrentComponent, SetSettingSection } from 'src/app/store/actions/selected.actions';
import { ActivatedRoute, Router } from '@angular/router';

import { DateTime } from 'luxon';
import { Account } from '../../models/account.model';
import { SegmentService } from 'ngx-segment-analytics';
import { environment } from 'src/environments/environment';
import { Logout } from 'src/app/store/actions/logout.action';
import { OverviewSettings } from 'src/app/store/interfaces/selected';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { AggregationPeriods } from '../../constants/aggregation-periods';
import { SettingsSections } from '../../enums/settings-sections';
import { ComponentNames } from '../../enums/component-names';
import { SidebarStateService } from '../sidebar-state/sidebar-state.service';
import { SidebarNames } from '../../enums/sidebar-names';
import { FilterTreeWorkerService } from '../filter-tree-worker/filter-tree-worker.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    private store: Store,
    private http: HttpClient,
    private router: Router,
    private segment: SegmentService,
    private sidebarState: SidebarStateService,
    private route: ActivatedRoute,
    private FTWS: FilterTreeWorkerService) { }

  get getReportingParams() {
    const SelectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected );
    const ProjectState = this.store.selectSnapshot<ProjectStateModel>( state => state.project);
    const PropertiesState = this.store.selectSnapshot<PropertiesStateModel>( state => state.properties);
    const FirstPurchaseOnly = this.store.selectSnapshot<boolean>( state => state.base.firstPurchaseOnly);
    const StartDate = SelectedState.chosenDates.start;
    const EndDate = SelectedState.chosenDates.end;
    const TrafficOption = ProjectState.currentViewParams?.traffic_option;
    const CompanyBasedAttribution = ProjectState.project?.company_based_attribution;
    const AttributionType = ProjectState.currentViewParams?.attribution_type;
    const LookbackWindow = ProjectState.currentViewParams?.lookback_window;
    const IncludeOnlyUsersRegisteredInDaterange = ProjectState.project?.include_only_users_registered_in_daterange;
    const DisregardExistingUsers = ProjectState.currentViewParams?.disregard_existing_users;
    const PositionBasedRatios = ProjectState.project?.position_based_ratios;
    const ConversionEventName = ProjectState.currentViewParams?.conversion_event;
    const EventPropertyKey = PropertiesState.event_property_key;
    const EventPropertyValue = PropertiesState.event_property_value;
    const AttributionModel = ProjectState.currentViewParams?.attribution_model;
    const CutoffEvents = ProjectState.currentViewParams?.cutoff_event;

    let Params: any = {
      company_based_attribution: CompanyBasedAttribution != null ? CompanyBasedAttribution.toString() : 'false',
      attribution_type: AttributionType != null ? AttributionType.toString() : 'forward',
      lookback_window: LookbackWindow != null ? LookbackWindow.toString() : '30',
      conversion_event_name: ConversionEventName ? ConversionEventName : '',
      event_property_key: EventPropertyKey ? EventPropertyKey : '',
      event_property_value: EventPropertyValue ? EventPropertyValue : '',
      disregard_existing_users: DisregardExistingUsers != null ? DisregardExistingUsers.toString() : 'false',
      end: EndDate,
      include_only_users_registered_in_daterange: IncludeOnlyUsersRegisteredInDaterange != null ?
      IncludeOnlyUsersRegisteredInDaterange.toString() : 'false',
      model: AttributionModel ? AttributionModel : 'linear',
      position_based_ratios: PositionBasedRatios !== null ? PositionBasedRatios : '40,20,40',
      start: StartDate,
      traffic_option: TrafficOption ? TrafficOption : 'include_all',
      cutoff_event: CutoffEvents ? CutoffEvents : '',
      first_purchase_only: FirstPurchaseOnly? FirstPurchaseOnly.toString() : 'false',
    };

    if (ProjectState.project?.settings?.exclude_filters_enabled && ProjectState.project?.settings?.exclude_filters?.length > 0) {
      Params['exclude_filters[]'] = ProjectState.project?.settings?.exclude_filters;
    }

    if (SelectedState.revenueGroup != null) {
      if (SelectedState.revenueGroup.type == 'include_only') {
        Params['include_revenue_events[]'] = SelectedState.revenueGroup.events;
      } else if (SelectedState.revenueGroup.type == 'exclude') {
        Params['exclude_revenue_events[]'] = SelectedState.revenueGroup.events;
      } else if (SelectedState.revenueGroup.type == 'conversion-event') {
        Params['include_revenue_events[]'] = [ConversionEventName ? ConversionEventName : ''];
      }
    }

    return Params;
  }

  get getCohortParams() {
    const CohortState = this.store.selectSnapshot<CohortStateModel>( state => state.cohort);
    const projectState: ProjectStateModel = this.store.selectSnapshot<ProjectStateModel>( state => state.project);
    const periodString = projectState.currentViewParams?.aggregation_period || 'Day';
    const params: any = { period: periodString.charAt(0).toUpperCase() + periodString.slice(1) };

    return this.paramsWithFilterForState(params, CohortState);
  }

  get getOverviewParams() {
    const selectedState = this.store.selectSnapshot<OverviewSettings>( state => state.selected.overviewSettings);
    const presets = this.getPresets();
    let result = {};

    Object.keys(selectedState).forEach(key => {
      let preset = presets?.find( e => e.label.toLowerCase() === selectedState[key].period.toLowerCase() );
      result[`${key}[start]`] = preset.start;
      result[`${key}[end]`] = preset.end;
    });
    return result;
  }

  get getChannelPerformanceParams() {
    const projectState: ProjectStateModel = this.store.selectSnapshot<ProjectStateModel>( state => state.project);
    const periodString = projectState.currentViewParams?.aggregation_period || 'Day';
    const Params: any = { period: periodString.charAt(0).toUpperCase() + periodString.slice(1) };

    return Params;
  }

  getTimePeriodParams(is_first_time?: boolean) {
    const ProjectState = this.store.selectSnapshot<ProjectStateModel>( state => state.project);
    const params: any = { aggregate_by_period: 'Month', attribution_type: ProjectState.currentViewParams?.attribution_type };
    if (is_first_time) params.first_purchasers = true;

    return params;
  }

  paramsWithFilterForState(params, state) {
    if (state.selectedFilter) {
      if ( state.selectedFilter.t === 'f') {
        const tree = this.store.selectSnapshot( state => state.filterTree.filterTree);
        const fullFilter = this.FTWS.findInTree({ node: {c: tree}, childrenKey: 'c', key: 'id', value: Number(state.selectedFilter.id), additionalKey: 't', additionalValue: 'f'});
        const paramName = fullFilter?.s === 'virtual' ? 'virtual_filter' : 'filter';
        params = {
          ...params,
          [paramName]: state.selectedFilter ? state.selectedFilter.id : ''
        };
      } else if ( state.selectedFilter.t === 'g') {
        params = {
          ...params,
          filter_group_id: state.selectedFilter ? state.selectedFilter.id : ''
        };
      }
    }

    return params;
  }

  getDateWithTimeZone(date, format?, fromFormat?) {
    const TimeZone = this.store.selectSnapshot<Project>( state => state.project.project)?.timezone || 'utc';
    let result;
    if (fromFormat) {
      switch (fromFormat) {
        case 'milis':
          result = format ? DateTime.fromMillis(date).toFormat(format) : DateTime.fromMillis(date);
          break;
        case 'sql':
          result = format ? DateTime.fromSQL(date, {zone: TimeZone}).toFormat(format) : DateTime.fromSQL(date, {zone: TimeZone});
          break;
        default:
        result = format ? DateTime.fromFormat(date, fromFormat, {zone: TimeZone}).toFormat(format) : DateTime.fromFormat(date, fromFormat, {zone: TimeZone});
      }
    } else {
      result = format ? DateTime.fromISO(date, {zone: TimeZone}).toFormat(format) : DateTime.fromISO(date, {zone: TimeZone});
    }
    return result;
  }

  getPresets(checkForRange?) {
    const project = this.store.selectSnapshot<Project>( state => state.project && state.project.project);
    const latestDate = project ? DateTime.fromFormat(project.latest_date, 'y-MM-dd') : DateTime.now();
    let presetArray = [
      {
        label: 'Last 30 days',
        start: latestDate.minus({ days: 30}).toFormat('y-MM-dd'),
        end: latestDate.minus({ day: 1}).toFormat('y-MM-dd')
      }, {
        label: 'Last month',
        start: latestDate.minus({ month: 1}).startOf('month').toFormat('y-MM-dd'),
        end: latestDate.minus({ month: 1}).endOf('month').toFormat('y-MM-dd')
      }, {
        label: 'Last 3 months',
        start: latestDate.minus({ months: 3}).startOf('month').toFormat('y-MM-dd'),
        end: latestDate.minus({ month: 1}).endOf('month').toFormat('y-MM-dd')
      }, {
        label: 'Last 6 months',
        start: latestDate.minus({ months: 6}).startOf('month').toFormat('y-MM-dd'),
        end: latestDate.minus({ month: 1}).endOf('month').toFormat('y-MM-dd')
      }, {
        label: 'Last year',
        start: latestDate.minus({ year: 1}).startOf('year').toFormat('y-MM-dd'),
        end: latestDate.minus({ year: 1}).endOf('year').toFormat('y-MM-dd')
      }
    ];
    if (checkForRange) {
      const earliestDate = DateTime.fromFormat(project.earliest_date, 'y-MM-dd');
      presetArray = presetArray.map( (x:any) => {
        x.outOfProjectRange = DateTime.fromFormat(x.start, 'y-MM-dd') < DateTime.fromFormat(project.earliest_date, 'y-MM-dd') || DateTime.fromFormat(x.end, 'y-MM-dd') > DateTime.fromFormat(project.latest_date, 'y-MM-dd');
        return x;
      });
      const firstPreset: any = presetArray[0];
      if (
          DateTime.fromFormat(firstPreset.start, 'y-MM-dd') < earliestDate
        ) {
        firstPreset.start = earliestDate.toFormat('y-MM-dd');
        firstPreset.label = 'Recent Days';
        firstPreset.outOfProjectRange = false;
      }
      presetArray.unshift({
        label: 'Yesterday',
        start: latestDate.minus({days: 1}).toFormat('y-MM-dd'),
        end: latestDate.minus({day: 1}).toFormat('y-MM-dd')
      });
    }
    return presetArray;
  }

  getVersionAndBranch() {
    const versionUrl = '/version.txt';
    return this.http.get(versionUrl, {responseType: 'text'}).pipe(
      map( resp => {
        const split = resp.split(',');
        return {
          version: split[0],
          branch: split[1],
          fullVersion: split[2]
        };
      })
    );
  }

  trackReportLoaded(type, params) {
    const account: Account = this.store.selectSnapshot( x => x.account);
    const project: Project = this.store.selectSnapshot( x => x.project.project);
    const presets = this.getPresets();
    let p: any = {
      report_type: type,
      attribution_model: params.model,
      attribution_type: params.attribution_type,
      project_id: project.id,
      project_name: project.name,
      loaded_by_owner: account.id === project.owner.id,
    };

    const isPreset = presets.find( x => x.start == params.start && x.end == params.end);
    if (isPreset) {
      p.date_preset = isPreset.label;
    } else {
      p.date_preset = 'Custom';
    }

    let p2;

    if (!p.loaded_by_owner) {
      p.owner_account_id = project.owner.id;
      p.owner_account_email = project.owner.email;

      p2 = {...p, member_account_id: account.id, member_account_email: account.email};
    }

    this.segment.track('Report Loaded', p);

    if (p2) {
      this.segment.track('Report Loaded by Member', p2);
    }
  }

  refreshAllData() {
    this.store.dispatch(new Logout());
    window.location.reload();
  }

  get currentPath() {
    let url = '';
    const project = this.store.selectSnapshot<Project>( state => state.project.project);
    if (project) { url += `/${project?.identifier}`; }
    const selectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected);
    if (selectedState.currentComponent) {url += `/${selectedState.currentComponent}`; }
    return url;
  }

  shouldLockToBilling(AccountState, SettingsState, SelectedState) {
    if ((SelectedState.currentComponent != ComponentNames.account && SelectedState.settingSection != SettingsSections.billing) &&AccountState.partner == 'bigcommerce' && SettingsState.subscription?.status == 'past_due' && typeof(SettingsState.card?.last4) == 'undefined') {
      this.store.dispatch([new SetCurrentComponent({currentComponent: ComponentNames.account}), new SetSettingSection(SettingsSections.billing)]);
      this.router.navigate([ComponentNames.account, SettingsSections.billing]);
    }
  }

  checkAndModifyErrorMessage(err) {
    let errorMessage = err.message;
    if (err.url && typeof(err.url) != 'undefined') {
      const reportingEndpoint = environment.reportingApiUrl;
      if (err.url.includes(reportingEndpoint)) {
        errorMessage = `Reporting API backend endpoint failed: ${err.url.replace(reportingEndpoint, "")}`;
      } else if(err.url.includes(environment.clientApiUrl)) {
        errorMessage = `Client API backend endpoint failed: ${err.url.replace(environment.clientApiUrl, "")}`;
      }
    }
    return errorMessage;
  }

  getAggregationPeriods(selectedState, projectState?) {
    let aggrPeriods = AggregationPeriods;
    const project = projectState || this.store.selectSnapshot( state => state.project.project);
    const customLimits = project.options?.custom_aggr_period_limits;
    const dayLimit = customLimits?.[selectedState.currentComponent]?.days || 100;
    const weekLimit = customLimits?.[selectedState.currentComponent]?.weeks || 55 * 7;

    let selectedPeriod = project.aggregation_period || 'day';
    if (selectedState.currentComponent == ComponentNames.cohort) {
      aggrPeriods = [...aggrPeriods, 'quarter'];
    } else if ([ComponentNames.firstTimePurchasers, ComponentNames.dashboard, ComponentNames.timeframe].includes(selectedState.currentComponent)) {
      const dateDiff = DateTime.fromFormat(selectedState.chosenDates.end, 'y-MM-dd').diff(DateTime.fromFormat(selectedState.chosenDates.start, 'y-MM-dd'), 'days').toObject().days;

      if ((selectedPeriod.toLowerCase() == 'day' || selectedPeriod.toLowerCase() == 'week') && dateDiff > weekLimit) {
        aggrPeriods = aggrPeriods.filter( x => !['day', 'week'].includes(x));
        selectedPeriod = 'month';
      } else if (selectedPeriod.toLowerCase() == 'day' && dateDiff > dayLimit) {
        selectedPeriod = 'week';
        aggrPeriods = aggrPeriods.filter( x => !['day'].includes(x));
      }
    }
    return {
      selectedPeriod,
      aggrPeriods
    };
  }


  showCompany(companyId) {
    this.sidebarState.setScrollPosition(document.getElementById('sidebar-content').scrollTop);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sidebar: SidebarNames.companies, id: companyId }
    });
  }

  showVisitor(visitorId) {
    this.sidebarState.setScrollPosition(document.getElementById('sidebar-content').scrollTop);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sidebar: SidebarNames.visitors, id: visitorId }
    });
  }

  isDefaultViewSelected() {
    const projectState = this.store.selectSnapshot<Project>( state => state.project.project);
    return projectState.current_view_id === projectState.views?.['default']?.id;
  }

}
