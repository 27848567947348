import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/dashboard/home/home.component';
import { LoginModalComponent } from './modules/shared/login-modal/login-modal.component';
import { AuthAndStateCheckGuard } from './core/guards/auth-and-state-check.guard';
import { CohortComponent } from './modules/dashboard/cohort/cohort.component';
import { ChannelPerformanceComponent } from './modules/dashboard/channel-performance/channel-performance.component';
import { OverviewComponent } from './modules/dashboard/overview/overview.component';
import { SignupComponent } from './modules/out-of-shell/signup/signup.component';
import { ShellComponent } from './modules/shell/shell.component';
import { PathsComponent } from './modules/dashboard/paths/paths.component';
import { OnboardingGuard } from './core/guards/onboarding.guard';
import { IntegrationsComponent } from './modules/settings/integrations/integrations.component';
import { ChannelBuilderComponent } from './modules/dashboard/channel-builder/channel-builder.component';
import { NotificationsComponent } from './modules/out-of-shell/notifications/notifications.component';
import { SendInstructionsComponent } from './modules/out-of-shell/onboarding/send-instructions/send-instructions.component';
import { ComponentNames } from './core/enums/component-names';
import { SettingsSections } from './core/enums/settings-sections';

const Routes: Routes = [
  { path: ComponentNames.signup, component: SignupComponent },
  { path: ComponentNames.notifications, component: NotificationsComponent },
  { path: ComponentNames.sendInstructions, component: SendInstructionsComponent},
  {
    path: ComponentNames.onboarding,
    loadChildren: () => import('./modules/out-of-shell/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [OnboardingGuard], // need to outComment to enable onboardinglock
    children: [
      { path: ComponentNames.login, component: LoginModalComponent},
      { path: `:project_identifier/${ComponentNames.cohort}`, component: CohortComponent, canActivate: [AuthAndStateCheckGuard] },
      { path: ComponentNames.cohort, component: CohortComponent, canActivate: [AuthAndStateCheckGuard] },
      { path: `:project_identifier/${ComponentNames.paths}`, component: PathsComponent, canActivate: [AuthAndStateCheckGuard] },
      { path: `:project_identifier/${ComponentNames.channelPerformance}`, component: ChannelPerformanceComponent, canActivate: [AuthAndStateCheckGuard] },
      { path: `:project_identifier/${ComponentNames.overview}`, component: OverviewComponent, canActivate: [AuthAndStateCheckGuard] },
      { path: `:project_identifier/${ComponentNames.firstTimePurchasers}`, component: HomeComponent, canActivate: [AuthAndStateCheckGuard], data: { firstTimePurchasers: true } },
      { path: `:project_identifier/${ComponentNames.timeframe}`, component: HomeComponent, canActivate: [AuthAndStateCheckGuard], data: { timeframe: true } },
      { path: `:project_identifier/${ComponentNames.dashboardBuilder}`, component: ChannelBuilderComponent, canActivate: [AuthAndStateCheckGuard] },
      {
        path: `:project_identifier/${ComponentNames.settings}`,
        loadChildren: () => import('./modules/settings/with-identifier/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthAndStateCheckGuard]
      },
      {
        path: `${ComponentNames.settings}`,
        loadChildren: () => import('./modules/settings/with-identifier/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthAndStateCheckGuard]
      },
      {
        path: ComponentNames.account,
        loadChildren: () => import('./modules/settings/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthAndStateCheckGuard]
      },
      { path: `${ComponentNames.settings}/${SettingsSections.integrations}`, component: IntegrationsComponent, canActivate: [AuthAndStateCheckGuard] },
      { path: ':project_identifier', component: HomeComponent, canActivate: [AuthAndStateCheckGuard] },
      { path: '', component: HomeComponent, canActivate: [AuthAndStateCheckGuard] },
    ]
  },
  { path: '**', component: ShellComponent, data: { notFound: true } },
];

@NgModule({
  imports: [RouterModule.forRoot(Routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
