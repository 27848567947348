import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Refresher } from 'src/app/store/interfaces/base';
import { Observable } from 'rxjs';
import { ReportRefresherService } from 'src/app/core/services/report-refresher/report-refresher.service';
import { ReportService } from 'src/app/core/services/report/report.service';
import { SetRefreshIsVirtual } from 'src/app/store/actions/base.actions';

@Component({
  selector: 'app-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButtonComponent implements OnInit {
  @Select(state => state.base.refresher) refresher$: Observable<Refresher>;

  refresher;

  constructor(
    private reportRefresher: ReportRefresherService,
    private reportService: ReportService,
    private store: Store
  ) { }

  ngOnInit() {
    this.refresher$.subscribe(resp => this.refresher = resp);
  }

  refresh() {
    this.reportRefresher.refreshReport().subscribe();
  }

  reload() {
    this.reportService.getReports();
    this.store.dispatch(new SetRefreshIsVirtual(false));
  }

}
