import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'hasNoChildren',
})
export class HasNoChildrenPipe implements PipeTransform {
  transform(item:any): any {
    if (item.t === 'g') {
      return !item.c || item.c.length == 0 ? true : false;
    } else {
      return !item.vc || item.vc.length == 0 ? true : false;
    }
  }
}