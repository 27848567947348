@if (integrationType === integrationTypes.transformer) {
    <b class="text-capitalize em-1_3">{{ label }}</b>
} @else if (!isTransformer && !(integrationName | includedInArray:nonDefaultLogos)) {
    <img class="integration_icon {{integrationName}}" src="/assets/images/integrations/full/{{integrationName}}.svg" alt="{{ integrationName }}">
} @else if (integrationName | includedInArray: ['csv_upload', 'influence_upload']) {
    <i class="fa-solid fa-file-csv fa-4x blue"></i>
} @else if (integrationName | includedInArray:['adwords', 'googleads', 'googleads2']) {
    <img class="integration_icon {{integrationName}}" src="/assets/images/integrations/full/google-ads.png" alt="{{ integrationName }}">
}  @else if (integrationName | includedInArray: ['getbase']) {
    <img class="integration_icon {{integrationName}}" src="/assets/images/integrations/full/zendesk-sell-logo.svg" alt="Logo">
}  @else if (integrationName | includedInArray: ['getbase']) {
    <img class="integration_icon {{integrationName}}" src="/assets/images/integrations/full/zendesk-sell-word.svg" alt="Zendesk Sell">
}  @else if (integrationName | includedInArray: ['segment_reverse', 'segment_reverse_events']) {
    <img class="integration_icon {{integrationName}}" src="/assets/images/integrations/full/segment.svg" alt="Segment Logo">
}  @else if (integrationName | includedInArray:['facebook4', 'facebook_leads']) {
    <img class="integration_icon facebook3" src="/assets/images/integrations/full/facebook3.svg" alt="Facebook Lead Ads">
}  @else if (integrationName | includedInArray:['linkedin2']) {
    <img class="integration_icon linkedin" src="/assets/images/integrations/full/linkedin.svg" alt="Facebook Lead Ads">
}  @else if ((integrationName | includedInArray:['bing', 'msads']) && isShow) {
    <img class="integration_icon show {{integrationName}}" src="/assets/images/integrations/full/microsoft-advertising.svg" alt="{{ integrationName }}">
}  @else if ((integrationName | includedInArray:['bing', 'msads']) && !isShow) {
    <img class="integration_icon {{integrationName}}" src="/assets/images/integrations/full/bing.svg" alt="{{ integrationName }}">
}  @else if (integrationName | includedInArray:['zuora']) {
    <img class="integration_icon {{integrationName}}" src="/assets/images/integrations/full/zuora.svg" alt="{{ integrationName }}">
} @else if (integrationName | includedInArray:['twitter']) {
    <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 1200 1227" fill="none">
        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="black"/>
    </svg>
}  @else if (isTransformer) {
    <i class="fa-solid fa-shuffle fa-4x blue"></i>
}

