import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { SetCohort, SetCohortInProgress } from 'src/app/store/actions/cohort.actions';
import { BaseService } from '../base/base.service';
import { Subscription } from 'rxjs';
import { DataDownloaderService } from '../data-downloader/data-downloader.service';

@Injectable({
  providedIn: 'root'
})
export class CohortService {
  subscription: Subscription;

  constructor( private store: Store, private baseService: BaseService, private toastr: ToastrService, private dataDownloader: DataDownloaderService) {}

  getCohort() {
    this.unsubscribeAndCancelReportGeneration();

    const ReportingParams = this.baseService.getReportingParams;
    const CohortParams = this.baseService.getCohortParams;

    this.store.dispatch(new SetCohortInProgress());

    const Params = {...ReportingParams, ...CohortParams};

    return this.subscription = this.dataDownloader.requestReport({...Params, report: 'cohort'}).subscribe( (resp: any) => {
      if (resp.meta.status === 'ready') {
        this.store.dispatch(new SetCohort({report: resp.report?.other?.cohort, meta: resp.meta}));
        this.baseService.trackReportLoaded('Cohort', Params);
      } else {
        setTimeout(() =>
          this.toastr.error(
            `Failed to fetch Cohort Data from the server. Please <a href="${this.baseService.currentPath}">reload</a>.`,
            `status: ${status}`,
            {disableTimeOut: true, enableHtml: true}
          )
        );
        throw new Error('Failed to load cohort report');
      }
      this.subscription = null;
    });
  }

  unsubscribeAndCancelReportGeneration() {
    if (this.subscription) {
      this.dataDownloader.cancelReportGeneration(['cohort']);
      this.subscription.unsubscribe();
      this.subscription = null;
      this.store.dispatch(new SetCohortInProgress({isFalse: true}));
    }
  }

}
