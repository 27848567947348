<div class="view-selector-wrapper">
  <div class="dropdown d-flex align-items-center" ngbDropdown placement="{{ currentComponent === 'paths' ? 'bottom-start' : 'bottom-end'}}">
    <button class="btn btn-default dropdown-toggle btn-select" [class.no-border-right-radius]="this.project?.current_view_id != views?.['default']?.id" type="button" id="dropdownMenuButton" ngbDropdownToggle>
      <i class="fa-solid fa-sliders fa--header"></i>
      {{ views?.[this.project?.current_view_id]?.name || 'Default'}}
    </button>
    <div class="opt-out-of-view" *ngIf="this.project?.current_view_id != views?.['default']?.id" (click)="selectView(views?.['default'])">
      <i class="fa-solid fa-xmark fa-lg"></i>
    </div>
    <div class="dropdown-menu" container="body" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
      <div class="view-list">
        <div *ngFor="let view of (views | takeFromObjExcept:'default') | keyvalue" class="view-item">
          <ng-template #viewTooltip>
            <p class="bold">{{ view.value.name }}</p>
            <view-selected-params [view]="view.value" [changedParams]="(currentViewParams | CheckIfViewParamsChanged:view.value:selectedState.chosenDates).changedParams"></view-selected-params>
          </ng-template>
          <div class="view-item__name" [class.bold]="view.key == project?.current_view_id" [ngbTooltip]="viewTooltip" container="body" tooltipClass="tooltip-w300">
            <ng-container [ngTemplateOutlet]='editNameId !== view.value.id ? name : showEditName' [ngTemplateOutletContext]="{view: view.value}"></ng-container>
          </div>
          <div class="view-item__actions">
            <div class="view-item__action" (click)="toggleNameInput(view.value)">
                <i class="fa-solid fa-pencil me-1" ngbTooltip="Rename"></i>
            </div>
            <div class="view-item__action" [class.medium]="(project.current_view_id !== view.value.id) || !(currentViewParams | CheckIfViewParamsChanged:view.value:selectedState.chosenDates).changed" (click)="saveView(view.value)">
                <i class="fa-solid fa-floppy-disk me-1" ngbTooltip="Save"></i>
            </div>
            <div class="view-item__action" [class.medium]="view.value.id == project?.current_view_id" (click)="deleteView(view.value)">
                <i class="fa-solid fa-trash me-1" ngbTooltip="Delete"></i>
            </div>
          </div>
        </div>
        <div class="view-item view-list__footer">
          <div class="view-item__action ms-0" (click)="openNewViewModal()">
            <i class="fa-solid fa-floppy-disk me-2"></i> Save current setting as a new view
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<small *ngIf="currentView && (currentViewParams | CheckIfViewParamsChanged:currentView:selectedState.chosenDates).changed" class="controllbar__hint bottom">
  <i class="fa-solid fa-triangle-exclamation red"></i>&nbsp;<span class="red">Unsaved Changes</span>
</small>

<ng-template #name let-view="view">
  <span class="clickable" (click)="selectView(view)"><i *ngIf="view.id === project?.current_view_id" class="fa-solid fa-circle-dot green me-3"></i><i *ngIf="view.id !== project?.current_view_id" class="fa-regular fa-circle medium me-3"></i>{{ view.name }}</span>
</ng-template>

<ng-template #showEditName let-view="view">
    <div class="d-flex align-items-center">
        <input class="form-control me-2 flex-grow-1" type="text" [ngModel]="nameInput" #viewNameInput>
        <i class="fa-solid fa-check-circle icon-clickable me-1 clickable clickable__blue" (click)="renameView(viewNameInput, view)"></i>
    </div>
</ng-template>