import { getKeyByValue } from "./get-key-by-value";

export const calculateBreakdowns = (paths, mapping) => {
    const breakDowns = [];

    Object.keys(paths).forEach( b => {
      const mapped: any = {};
      for (const [key, value] of Object.entries(paths[b])) {
        if (Object.values(mapping).indexOf(key) > -1) {
          mapped[getKeyByValue(mapping, key)] = value;
        }
      }
      mapped.amounts = mapped.amounts || 0;
      mapped.conversions = mapped.conversions || 0;
      mapped.revenue = mapped.revenue || 0;
      mapped.visits = mapped.visits || 0;
      mapped.overlap = mapped.overlap || 0;
      mapped.costPerConversion = mapped.conversions !== 0 ? mapped.amounts / mapped.conversions : 0;
      mapped.conversionRate = mapped.visits != 0 ? mapped.conversions / mapped.visits : 0;
      mapped.revenuePerConversion = mapped.conversions !== 0 ? mapped.revenue / mapped.conversions : 0;
      mapped.profitOrLoss = mapped.revenue - mapped.amounts;
      breakDowns.push({name: b, object: mapped});
    });

    return breakDowns;
}