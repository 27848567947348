import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cohortCalculateClassName'
})
export class CohortCalculateClassNamePipe implements PipeTransform {
  transform(metric: string, revenue: number, expenditure: number, heatmap: boolean ): any {
    let className = '';
    const amountToCheck = metric === 'ROAS' ? revenue - expenditure : metric === 'Revenue' ? revenue : revenue / expenditure;
    const percentage = metric === 'Percentage' ? amountToCheck - 1 : amountToCheck / expenditure;
    if (heatmap === false) {
      if (amountToCheck < 0) {
        className = 'profit--negative';
      } else if (amountToCheck > 0) {
        className = 'profit--positive';
      }
    } else {
      if (percentage < -0.80) {
        className = 'heatmap-red__100';
      } else if (percentage < -0.60) {
        className = 'heatmap-red__80';
      } else if (percentage < -0.40) {
        className = 'heatmap-red__60';
      } else if (percentage < -0.20) {
        className = 'heatmap-red__40';
      } else if (percentage < 0) {
        className = 'heatmap-red__20';
      } else if (percentage === 0 || isNaN(percentage)) {
        className = 'zero';
      } else if (percentage < 0.25) {
        className = 'heatmap-green__0';
      } else if (percentage < 0.5) {
        className = 'heatmap-green__25';
      } else if (percentage < 0.75) {
        className = 'heatmap-green__50';
      } else if (percentage < 1) {
        className = 'heatmap-green__75';
      } else if (percentage < 1.5) {
        className = 'heatmap-green__100';
      } else if (percentage < 2) {
        className = 'heatmap-green__150';
      } else if (percentage >= 2) {
        className = 'heatmap-green__200';
      }
    }

    return className;
  }
}