<ng-template #commonSorter>
  <div class="sort-row dash__row dash__row--parent padded-both">
    <div class="col--checkbox sub sortable-link" ngbTooltip="Unselect all" container="body" (click)="unselectAll()">
      Add to Graph
    </div>
    <div class="col--count count-header sub">
      <app-sort
        class="sortable-link"
        (mouseenter)="sortHover = 'count'"
        (mouseleave)="sortHover = null"
        (click)="sort('count')"
        [sortHover]="sortHover"
        [sortColumn]="sortColumn"
        [sort]="'count'"
        [sortDirection]="sortDirection"
        [text]="'Conversions'"></app-sort>
    </div>

    <div class="col--path">
      Converted Path
    </div>

    <div class="col--ttp sub">
      <app-sort
        class="sortable-link"
        (mouseenter)="sortHover = 'avg_time_to_conv'"
        (mouseleave)="sortHover = null"
        (click)="sort('avg_time_to_conv')"
        [sortHover]="sortHover"
        [sortColumn]="sortColumn"
        [sort]="'avg_time_to_conv'"
        [sortDirection]="sortDirection"
        [text]="'Time to Purchase'"></app-sort>
    </div>

    <div class="col--tr sub">
      <app-sort
        class="sortable-link"
        (mouseenter)="sortHover = 'total_revenue'"
        (mouseleave)="sortHover = null"
        (click)="sort('total_revenue')"
        [sortHover]="sortHover"
        [sortColumn]="sortColumn"
        [sort]="'total_revenue'"
        [sortDirection]="sortDirection"
        [text]="'Total Revenue'"></app-sort>
    </div>

    <div class="col--ar sub">
      <app-sort
        class="sortable-link"
        (mouseenter)="sortHover = 'avg_revenue'"
        (mouseleave)="sortHover = null"
        (click)="sort('avg_revenue')"
        [sortHover]="sortHover"
        [sortColumn]="sortColumn"
        [sort]="'avg_revenue'"
        [sortDirection]="sortDirection"
        [text]="'Avg. Revenue'"></app-sort>
    </div>

  </div>
</ng-template>

<ng-template #individualSorter>
  <div class="dash__row dash__row--parent">
    <div class="col--date sub">
      Date
    </div>
    <div class="col--visitor">
      Visitor
    </div>
    <div class="col--path">
      Converted Path
    </div>
  </div>
</ng-template>

<div class="paths-wrapper">
  <div *ngIf="isAggregated && sankeyLinks && nodes" class="pt-0" id="sankey-chart-wrapper">
    <h4>Customer Journey Paths</h4>
    <app-sankey-chart
    [links]="sankeyLinks"
    [chartHeight]="this.chartHeight"
    [nodes]="nodes"
    [maxLength]="sankeyMaxLength"></app-sankey-chart>
  </div>
  <div infiniteScroll
    [infiniteScrollContainer]="'.paths-wrapper'"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    [scrollWindow]="true"
    (scrolled)="onScroll()">
    <app-paths-header
      [(labels)]="labels"
      [isAggregated]="isAggregated"
      [isCombineRecurring]="isCombineRecurring"
      [filterType]="filterType"
      [filterOrGroup]="filterOrGroup"
      [paths]="paths"
      [count]="count"
      [project]="project"
      (downloadCSV)="downloadCSV()">
    </app-paths-header>
    <div *ngIf="!isAggregated" class="paths__table labels-{{ labels }}">
      <ng-container *ngTemplateOutlet="individualSorter"></ng-container>
      <app-paths-row
        [paths]="paths"
        [labels]="labels"
        [conversionEventName]="conversionEventName"
        [touchpoints]="allTouchPoints"
        [isSidebarOpen]="isSidebarOpen"
      ></app-paths-row>
      <div *ngIf="additionalInProgress">
        <app-loader
          *ngIf="additionalInProgress"
          [position]="'relative'"
          [color]="'green'"
          [height]="'30px'"
          [zIndex]="1"
          [backgroundColor]="'transparent'"></app-loader>
      </div>
    </div>
  
    <div *ngIf="isAggregated" class="paths__table labels-{{ labels }}">
      <div *ngIf="paths">
        <div class="dash__row dash__row--parent padded alternate" *ngIf="summary && count > 0">
          <p class="summary">There are {{ count }} unique paths with total of {{ summary.conversions }} conversions. <span *ngIf="count > summary.top_count">Top {{ summary.top_count }} unique paths stand for {{ (summary.conversions - summary.other_conversions) | percentage : summary.conversions }} of conversions.</span></p>
        </div>
        <ng-container *ngTemplateOutlet="commonSorter"></ng-container>
        @for (path of paths; track path) {
          <app-paths-aggregated-row
            class="dash__row dash__row--parent padded-both"
            [path]="path"
            [summary]="summary"
            [conversionEventName]="conversionEventName"
            [labels]="labels"
            (onSelected)="toggleSelect($event)"
          ></app-paths-aggregated-row>
        }
        <div class="dash__row dash__row--parent padded alternate" *ngIf="summary?.other_conversions > 0">
          <div class="col--count bold">
            <span class="percentage">{{ summary.other_conversions | percentage : summary.conversions }}</span>
              {{ summary.other_conversions }}
            <span class="muted times">&times;</span>
          </div>
  
          <div class="col--path">
            <div class="filter">
              <span class="filter--badge" [ngStyle]="{ 'background-color': 'black' }"><i>Other paths</i></span>
            </div>
            <span class="small muted"> &rsaquo;&rsaquo; </span><strong class="small">{{ conversionEventName }}</strong>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="inProgress" class="loader-sm">
      <app-loader
        *ngIf="inProgress"
        [position]="'relative'"
        [color]="'green'"
        [backgroundColor]="'transparent'"
        [height]="'30px'"
        [zIndex]="1"></app-loader>
    </div>
  </div>
</div>
