import { Component } from '@angular/core';
import { InfluenceService } from 'src/app/core/services/influence/influence.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-influences-uploader',
  templateUrl: './influences-uploader.component.html',
  styleUrls: ['./influences-uploader.component.scss']
})
export class InfluencesUploaderComponent {
  uploadSuccessful = false;
  uploadError;
  errorLine;
  errorLineNumber;
  uploadedSuccessfully = false;
  assetPath = environment.assetPath;
  status = null;
  requestId = null;

  constructor(
    private influenceService: InfluenceService,
    ) {}

  refreshStatus() {
    if (this.uploadSuccessful !== true) return;
    this.influenceService.getStatus().subscribe((resp: any) => {
      this.status = resp.status;
    });
  }

  onUpload(files: FileList) {
    const csvElem = document.getElementById('csvFile') as HTMLInputElement;
    this.influenceService.uploadCSV(files).subscribe({next: (resp: any) => {
      this.status = resp.status;
      this.requestId = resp.request_id;
    },
    complete: () => {
      csvElem.value = '';
      this.uploadError = null;
      this.errorLine = null;
      this.errorLineNumber = null;
      this.uploadSuccessful = true;
      this.uploadedSuccessfully = true;
    }, error: (error) => {
        csvElem.value = '';
        this.uploadError = error?.error?.error;
        if (error.error.error_line) this.errorLine = error.error.error_line;
        if (error.error.error_line_number) this.errorLineNumber = error.error.error_line_number;
      }
    });
  }
}
