<div class="cohorts-wrapper">
  <div class='cohort-filters'>
    <div class='cohort__header'>
      <div class='inline-block switch-wrapper'>
        <small class="controllbar__hint top text-nowrap">Source</small>
        <app-filter-search
          *ngIf="shouldShowSelect; else selectButton"
          (filterSelected)="filterChanged($event)"
          (close)="shouldShowSelect = false"></app-filter-search>
        <ng-template #selectButton>
          <button class="btn btn-default btn-select" (click)="shouldShowSelect = true">
            <i class="fa-solid fa-filter fa--header"></i>
            {{ filter ? (filter | cohortGetFilterName:filterTree:FTWS) : 'All Traffic' }}
            <span class="caret ms-1"></span>
          </button>
        </ng-template>
      </div>
      <span class='medium'> : </span>
      <div class="inline-block">
        <div class='switch-wrapper'>
          <small class="controllbar__hint top text-nowrap">Alignment</small>
          <div class="dropdown" ngbDropdown>
            <button class="btn btn-default dropdown-toggle btn-select" #alignDropdown type="button" id="dropdownMenuButton" [attr.data-boundary]="alignDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i class="fa-solid fa--header" [class.fa-align-left]="align == 'Left'" [class.fa-align-right]="align == 'Right'"></i>
              {{ align }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
              <span class="dropdown-item" (click)="selectAlignment('Left')">Left</span>
              <span class="dropdown-item" (click)="selectAlignment('Right')">Right</span>
            </div>
          </div>
        </div>
      </div>
      <span class='medium'> : </span>
      <div class="inline-block">
        <div class='switch-wrapper'>
          <small class="controllbar__hint top text-nowrap">Metric</small>
          <div class="dropdown" ngbDropdown>
            <button class="btn btn-default dropdown-toggle btn-select" type="button" #metricDropdown id="dropdownMenuButton" [attr.data-boundary]="metricDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i class="fa-solid fa-calculator fa--header"></i>
              {{ metric }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
              <span class="dropdown-item" (click)="selectMetric('ROAS')">ROAS</span>
              <span class="dropdown-item" (click)="selectMetric('Revenue')">Revenue</span>
              <span class="dropdown-item" (click)="selectMetric('Percentage')">Percentage</span>
            </div>
          </div>
        </div>
      </div>
      <span class='medium'> : </span>
      <div class="inline-block">
        <div class='switch-wrapper'>
          <small class="controllbar__hint top text-nowrap">Revenue</small>
          <div class="dropdown" ngbDropdown>
            <button *ngIf="metric == 'Revenue'" class="btn btn-default dropdown-toggle btn-select" type="button" #revenueDropdown  id="dropdownMenuButton" [attr.data-boundary]="revenueDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i class="fa-solid fa-dollar-sign fa--header"></i>
              {{ revenue }}
            </button>
            <button *ngIf="metric != 'Revenue'" class="btn btn-default btn-select disabled">
              <i class="fa-solid fa-dollar-sign fa--header"></i>
              Absolute
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
              <span class="dropdown-item" (click)="selectRevenue('Absolute')" [class.disabled]="metric != 'Revenue'">Absolute</span>
              <span class="dropdown-item" (click)="selectRevenue('Relative')" [class.disabled]="metric != 'Revenue'">Relative</span>
            </div>
          </div>
        </div>
      </div>
      <span class='medium'> : </span>
      <div class="inline-block">
        <div class='switch-wrapper'>
          <small class="controllbar__hint top text-nowrap">HeatMap</small>
          <div class="dropdown" ngbDropdown>
            <button class="btn btn-default dropdown-toggle btn-select" type="button" #heatmapDropdown  id="dropdownMenuButton" [attr.data-boundary]="heatmapDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i class="fa-solid fa-palette fa--header"></i>
              {{ heatmap == true? 'ON' : 'OFF' }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
              <span class="dropdown-item" (click)="selectHeatmap(false)">OFF</span>
              <span class="dropdown-item" (click)="selectHeatmap(true)">ON</span>
            </div>
          </div>
        </div>
      </div>
      <span class='medium'> : </span>
      <div class="inline-block">
        <div class='switch-wrapper'>
          <small class="controllbar__hint top text-nowrap">Denomination</small>
          <div class="dropdown" ngbDropdown>
            <button *ngIf="metric != 'Percentage'" class="btn btn-default dropdown-toggle btn-select" type="button" #denominationDropdown id="dropdownMenuButton" [attr.data-boundary]="denominationDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i class="fa-solid fa-calculator fa--header"></i>
              {{ denomination }}
            </button>
            <button *ngIf="metric == 'Percentage'" class="btn btn-default btn-select disabled">
              <i class="fa-solid fa-calculator fa--header"></i>
              {{ denomination }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
              <span class="dropdown-item" (click)="selectDenomination('Cents')">Cents</span>
              <span class="dropdown-item" (click)="selectDenomination('Dollars')">Dollars</span>
              <span class="dropdown-item" (click)="selectDenomination('Thousands')">Thousands</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='cohort-after-filters'>
    <app-loader *ngIf="cohortIsLoading; else loaded" [position]="'relative'" [color]="'green'">
    </app-loader>
    <ng-template #loaded>
      <cohort-content *ngIf="cohorts" #contentTemplate
        [cohorts]='cohorts'
        [cohortLength]='cohortLength'
        [align]='align'
        [metric]='metric'
        [periodsForRightAlign]='periodsForRightAlign'
        [heatmap]='heatmap'
        [revenue]='revenue'
        [period]='period'
        [denomination]='denomination'
      ></cohort-content>
    </ng-template>
  </div>
</div>
