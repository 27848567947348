import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { pickBy, identity, concat, last } from 'lodash-es';

import { SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { Project } from 'src/app/core/models/project.model';
import { CompanyService } from 'src/app/core/services/company/company.service';
import { Store } from '@ngxs/store';
import { SidebarSetName } from 'src/app/store/actions/only-simple.actions';
import { BaseService } from 'src/app/core/services/base/base.service';


@Component({
  selector: 'app-company-sidebar',
  templateUrl: './company-sidebar.component.html',
  styleUrls: ['./company-sidebar.component.scss']
})
export class CompanySidebarComponent implements OnChanges {
  @Input() sidebar: SidebarStateModel;
  @Input() project: Project;

  infoInProgress;
  inProgress;
  companyId;
  company;
  data = [];
  summary;
  count = 1; // needs to be more then 0 at the start
  canScroll = true;
  queryParams = {
    order: 'time DESC',
    since: null
  };

  constructor(
    private store: Store,
    private compayService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private baseService: BaseService
  ) { }

  ngOnChanges() {
    if (this.project) {
      this.inProgress = true;
      this.infoInProgress = true;
      this.company = null;
      this.data = [];
      this.companyId = parseInt(this.sidebar.options.id);
      this.infoInProgress = true;
      this.compayService.getCompanyInfo(this.companyId).subscribe(resp => {
        this.company = resp;
        this.infoInProgress = false;
        this.store.dispatch(new SidebarSetName({name: this.company.name ? this.company.name : this.company?.identifier}));
      });

      this.inProgress = true;
      this.requestAnew();
    }
  }

  requestAnew() {
    this.compayService.getHistory(this.companyId, pickBy(this.queryParams, identity)).subscribe(
      (resp: { data: Array<any>; count: number; summary: any; }) => {
        this.data = concat(this.data, resp.data);
        this.summary = resp.summary;
        if (resp.count) { this.count = resp.count; }
        if (resp.data.length > 0 ) {
          this.queryParams.since = last(resp.data).time;
        }
        this.inProgress = false;
        if (this.count && this.count > this.data.length) {
          this.canScroll = true;
        } else {
          this.canScroll = false;
        }
      }
    );
  }

  onScroll() {
    if (this.data.length >= this.count || this.inProgress) { return; }
    this.inProgress = true;
    this.requestAnew();
  }

  orderChanged(event) {
    this.queryParams.order = `time ${event.order}`;
    this.data = [];
    this.queryParams.since = null;
    this.requestAnew();
  }

  showVisitor(visitorId) {
    this.baseService.showVisitor(visitorId);
  }

  traits() {
    if (!this.company || !this.company.traits) { return []; }

    return Object.keys(this.company.traits).map(key => {
      return {
        key,
        value: this.company.traits[key]
      };
    });
  }

  isTime(key) {
    return ['createdAt', 'createdat', 'created_at'].includes(key);
  }

}
