import { Pipe, PipeTransform } from '@angular/core';
import { findTopNodeInTree } from 'src/app/core/helpers/find-in-tree';

@Pipe({
  name: 'visitTimelineFilterTopGroup',
})
export class VisitTimelineFilterTopGroupPipe implements PipeTransform {
  transform(filterId, filterTree): any {
    return findTopNodeInTree(filterTree, 'c', 'id', filterId);
  }
}