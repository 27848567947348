import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { mergeMap, delay } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { BaseService } from '../base/base.service';
import { SetOverviewInProgress, SetOverview } from 'src/app/store/actions/overview.actions';
import { GetWidgetsResponse } from './overview.model';
import { reportNames } from 'src/app/core/constants/overview';
import { OverviewStateModel } from 'src/app/store/states/overview.state';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  subscription: Subscription;
  overviewState;
  maxRetries = 15;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private toastr: ToastrService,
    private baseService: BaseService) { }

  /**
   *
   * @param requestedWidgets array of widget names to request
   * @param refreshStale refresh expired reports based on `generated_at`
   */

  getReports(requestedWidgets?: string[], refreshStale?: boolean, failCount = 0) {
    if (typeof(this.subscription) !== 'undefined' && this.subscription !== null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    const overviewParams = this.baseService.getOverviewParams;
    this.store.dispatch(new SetOverviewInProgress());
    const Url = environment.reportingApiUrl + '/v2/widgets';
    if (requestedWidgets.length > 0) {
      overviewParams['widgets[]'] = requestedWidgets;
    }

    this.overviewState = this.store.selectSnapshot<OverviewStateModel>( state => state.overview );

    this.subscription = this.httpClient.get(Url, { params: overviewParams, observe: 'response' })
      .pipe(delay(2000), mergeMap(x => this.extractData(x, requestedWidgets, refreshStale)))
      .subscribe( (resp: GetWidgetsResponse) => {
        if (resp) {
          this.store.dispatch(new SetOverview(resp));
        } else {
          if (this.maxRetries > failCount + 1) {
            this.getReports(requestedWidgets, refreshStale, failCount + 1);
          } else {
            setTimeout(() =>
              this.toastr.error(
                'Overview is refreshing now, please try again later.',
                'Overview timed out',
                {disableTimeOut: true}
              )
            );
          }
        }
      });
  }

  /**
   * Validates and returns response or null
   * @param resp widget reports response
   * @param requestedWidgets array of widget names in response
   * @param refreshStale validate reports by `generated_at`
   */
  extractData(resp, requestedWidgets, refreshStale){
    const respWidgets = requestedWidgets.length > 0 ? requestedWidgets : reportNames;
    const isReportValid = respWidgets.reduce((isWidgetReportValid, element) => {
      return isWidgetReportValid &&
        (
          resp.body["widgets"][element] &&
          ['ready', 'disabled'].indexOf(resp.body["widgets"][element]["meta"]["status"]) > -1 &&
          resp.body["widgets"][element]["report"] !== null &&
          (
            !refreshStale ||
            !this.overviewState.report ||
            !this.overviewState.report[element] ||
            (
              this.overviewState.report[element].meta.needRefresh &&
              this.baseService.getDateWithTimeZone(
                resp.body["widgets"][element]["meta"]["generated_at"]
              )
              >
              this.baseService.getDateWithTimeZone(
                this.overviewState.report[element].meta.generated_at
              )
            )
          )
        );
    }, true);

    return (isReportValid ? of(resp.body) : of(null));
  }

  setProjectAttribute(name: string, value: any) {
    const Url = environment.clientApiUrl + `/v2/project`;
    return this.httpClient.post(Url, { [name]: value });
  }

  setSettings(settings) {
    return this.setProjectAttribute('widgets', settings).subscribe(
      (resp: any) => {
        if (!resp.widgets) {
          setTimeout(() =>
            this.toastr.error(
              'Settings are refreshing now, please try again later.',
              `status: ${status}`,
              {disableTimeOut: true}
            )
          );
        }
      },
      error => {
        setTimeout(() =>
          this.toastr.error(
            `Failed to update settings. Please <a href="${this.baseService.currentPath}">reload</a>.`,
            `status: ${status}`,
            {disableTimeOut: true, enableHtml: true}
          )
        );
        throw new Error('Failed to load overview');
      }
    );
  }
}
