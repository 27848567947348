import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Project } from 'src/app/core/models/project.model';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { UsageService } from 'src/app/core/services/usage/usage.service';
import { BaseService } from 'src/app/core/services/base/base.service';
import { SetUsage } from 'src/app/store/actions/settings.actions';

import { DateTime } from 'luxon';

@Component({
  selector: 'app-data-collection-status',
  templateUrl: './data-collection-status.component.html',
  styleUrls: ['./data-collection-status.component.scss']
})
export class DataCollectionStatusComponent implements OnInit, OnDestroy {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.settings.usage) usage$: Observable<any>;

  @Output() eventClicked = new EventEmitter<Event>();
  @Input() style = 'old';
  @Input() isShopifySegment = false;

  projectSub: Subscription;
  usageReqSub: Subscription;
  usageSub: Subscription;

  project: Project;
  dataStatus;
  usageIsLoaded = false;
  initialLoaded = false;
  
  dateFormatFn = (x) => {
    const dif = DateTime.now().diff(x, ['months', 'days', 'hours', 'minutes']).toObject();
    const monthDiff = dif.months;
    const dayDiff = dif.days;
    const hourDiff = dif.hours;
    const minutesDiff = dif.minutes;
    let str;
    if (monthDiff > 0) {
      if (monthDiff == 1) {
        str = 'month';
      } else {
        str = `${monthDiff} months`;
      }
    } else if (dayDiff > 0) {
      if (dayDiff == 1) {
        str = 'day';
      } else {
        str = `${dayDiff} days`;
      }
    } else if (hourDiff > 0) {
      if (hourDiff == 1) {
        str = 'hour';
      } else {
        str = `${hourDiff} hours`;
      }
    } else if (minutesDiff > 0) {
      if (minutesDiff > 15) {
        str = `${minutesDiff} minutes`;
      } else {
        str = '15 minutes';
      }
    } else {
      str = '15 minutes';
    }

    return `Processed in the last ${str}`;
  };

  constructor(private usageService: UsageService, private baseService: BaseService, private store: Store) { }

  ngOnInit() {
    this.usageSub = this.usage$.subscribe( state => {
      if (state) {
        this.dataStatus = state;
        this.usageIsLoaded = true;
      } else if (this.initialLoaded) {
        this.usageIsLoaded = true;
        this.usageSub?.unsubscribe();
        this.usageReqSub = this.usageService.get().subscribe( (resp:any) => {
          this.store.dispatch(new SetUsage(resp));
          this.usageIsLoaded = true;
          this.dataStatus = resp;
        });
      }
    });
    this.usageReqSub = this.usageService.get().subscribe( (resp:any) => {
      this.store.dispatch(new SetUsage(resp));
      this.usageIsLoaded = true;
      this.initialLoaded = true;
      this.dataStatus = resp;
    });
    this.projectSub = this.project$.subscribe(state => this.project = state.project);
  }

  ngOnDestroy(): void {
    this.projectSub?.unsubscribe();
    this.usageSub?.unsubscribe();
    this.usageReqSub?.unsubscribe();
  }

  selectInstallationTab(tab) {
    this.eventClicked.emit(tab);
  }

  dateWithTimezone(x) {
    return this.baseService.getDateWithTimeZone(x, null, null);
  }

}
