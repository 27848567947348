import { ChosenDates, AttributionModel } from '../interfaces/selected';
import { ConversionEvent } from 'src/app/core/models/conversionEvent.model';

export class SetChosenDates {
    static readonly type = '[selected] SetChosenDates';
    constructor(public payload: ChosenDates) {}
}

export class SetDatesChanged {
    static readonly type = '[selected] SetDatesChanged';
    constructor(public payload: boolean) {}
}

export class SetAttributionModel {
    static readonly type = '[selected] SetAttributionModel';
    constructor(public payload: { attributionModel: AttributionModel, fromProjectSettings?: boolean }) {}
}

export class SetConversionEvent {
    static readonly type = '[selected] SetConversionEvent';
    constructor(public payload: { local: boolean, event: ConversionEvent }) {}
}

export class SetCurrentComponent {
    static readonly type = '[selected] SetCurrentComponent';
    constructor(public payload: { currentComponent: string }) {}
}

export class SetSettingSection {
    static readonly type = '[selected] SetSettingSection';
    constructor(public payload: string) {}
}

export class SetCpOpenChannelId {
    static readonly type = '[selected] SetCpOpenChannelId';
    constructor(public payload: { channelId: number }) {}
}

export class ClearSelected {
    static readonly type = '[selected] ClearSelected';
}

export class ClearReports {
    static readonly type = '[selected] ClearReports';
}

export class ClearAggrPeriodReports {
    static readonly type = '[selected] ClearAggrPeriodReports';
}

export class ClearReportsWithoutCPSummary {
    static readonly type = '[selected] ClearReportsWithoutCPSummary';
}

export class SetOverviewSettings {
    static readonly type = '[selected] SetOverviewSettings';
    constructor(public payload: object) {}
}

export class ResetOverviewSettings {
    static readonly type = '[selected] ResetOverviewSettings';
    constructor() {}
}

export class ToggleProperties {
    static readonly type = '[selected] ToggleProperties';
    constructor(public payload?: boolean) {}
}

export class CloseProperties {
    static readonly type = '[selected] CloseProperties';
}

export class SetRevenueGroup {
    static readonly type = '[selected] SetRevenueGroup';
    constructor(public payload: {revenueGroup: any}) {}
}

export class SetRevenueGroupAndPreset {
    static readonly type = '[selected] SetRevenueGroupAndPreset';
    constructor(public payload: {revenueGroup: any, preset: string}) {}
}

export class SetLastSelectedPreset {
    static readonly type = '[selected] SetLastSelectedPreset';
    constructor(public payload: string) {}
}