import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Project } from 'src/app/core/models/project.model';
import { IntegrationsService } from 'src/app/core/services/integrations/integrations.service';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-segment-settings',
  templateUrl: './segment-settings.component.html',
  styleUrl: './segment-settings.component.scss'
})
export class SegmentSettingsComponent {
  @Select(state => state.project) project$;
  integrationName = 'shopify';
  oauth: any;
  project: Project;
  settings = [];
  segmentWriteKey: string;
  writeKeySavePending = false;
  assetPath = environment.assetPath;

  constructor(private integrationsService: IntegrationsService, private projectService: ProjectService) {}

  ngOnInit(): void {
    this.project$.subscribe(project => {
      this.project = project.project;
      this.oauth = this.project?.oauths?.find(o => o.type === this.integrationName);
      if (!this.oauth) return;
      this.settings.push({
        name: "Track Gross Revenue on Order creation",
        type: 'boolean',
        field: 'track_gross_revenue_on_creation',
        value: this.oauth?.settings?.track_gross_revenue_on_creation
      });
      this.segmentWriteKey = this.oauth?.settings?.segment_write_key;
    });
  }

  saveSegmentWriteKey() {
    this.writeKeySavePending = true;
    this.integrationsService.updateOauth(this.oauth.id, {segment_write_key: this.segmentWriteKey}).subscribe({
      next: x => {
        this.oauth = x;
        this.writeKeySavePending = false;
        this.projectService.getProjectInfo(true).subscribe();
      },
      error: () => this.writeKeySavePending = false
    });
  }
}
