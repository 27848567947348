<div class="row">
    <div class="col-xl-6">
        <div class="attr-card h-100 d-flex flex-column">
            <div class="attr-card__header d-flex justify-content-between align-items-center mt-0 mb-0">
                <div>
                    <div class="title">
                        Data Collection Status
                    </div>
                </div>
                <div class="ms-5">
                    <a class="underlined-link em-1_15 text-nowrap" href="https://docs.attributionapp.com/docs/getting-started" target="_blank">Check developer docs</a>
                </div>
            </div>
            <div class="mt-4 attr-card attr-card__grey em-1_15 flex-grow-1">
                <app-data-collection-status
                    [style]="'v2'"
                    [isShopifySegment]="project | isShopifySegment:account"></app-data-collection-status>
            </div>
        </div>
    </div>
    <div class="col-xl-6">
        <div class="d-flex h-100 flex-column justify-content-between">
            <div class="attr-card flex-grow-1">
                @if (project?.options?.general?.spend_limit == null) {
                    <div class="attr-card__header mt-0 mb-0">
                        <div class="title">Monthly Tracked Users</div>
                        <div class="diff-wrapper">
                            <div class="diff-pct" [ngClass]="{ 'diff-red': mtuDiffPct && mtuDiffPct < 0, 'diff-green': mtuDiffPct && mtuDiffPct > 0}">
                                {{ (mtuDiffPct / 100) | percent: '1.2-2' }}
                            </div>
                            <div class="diff-from">
                                compared to previous period
                            </div>
                        </div>
                        <div class="row em-1_2 mt-3">
                            <div class="col-6 pe-1">
                                <div class="attr-card less-padding attr-card__grey">
                                    <div class="medium-new">Currently</div>
                                    <div class="metric-numbers bold mt-2">
                                        {{ mtuVal | integer}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 ps-1">
                                <div class="attr-card less-padding attr-card__grey">
                                    <div class="medium-new">Projected</div>
                                    <div class="metric-numbers bold mt-2">
                                        {{ mtuProjectedVal | integer}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                } @else {
                    <div class="attr-card__header mt-0 mb-0">
                        <div class="title">Tracked Spend</div>
                        <div class="diff-wrapper">
                            <div class="diff-pct" [ngClass]="{ 'diff-red': spendDiffPct && spendDiffPct < 0, 'diff-green': spendDiffPct && spendDiffPct > 0}">
                                {{ (spendDiffPct / 100) | percent: '1.2-2' }}
                            </div>
                            <div class="diff-from">
                                compared to previous period
                            </div>
                        </div>
                        <div class="row em-1_2 mt-3">
                            <div class="col-6 pe-1">
                                <div class="attr-card less-padding attr-card__grey">
                                    <div class="medium-new">Currently</div>
                                    <div class="metric-numbers bold mt-2">
                                        {{ spendVal | projectCurrency: 'dollars' }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 ps-1">
                                <div class="attr-card less-padding attr-card__grey">
                                    <div class="medium-new">Projected</div>
                                    <div class="metric-numbers bold mt-2">
                                        {{ spendProjectedVal | projectCurrency: 'dollars' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div class="attr-card mt-3 flex-grow-1">
                <div class="attr-card__header mt-0 mb-0">
                    <div class="title">Events</div>
                    <div class="diff-wrapper">
                        <div class="diff-pct" [ngClass]="{ 'diff-red': eventsDiffPct && eventsDiffPct < 0, 'diff-green': eventsDiffPct && eventsDiffPct > 0}">
                            {{ (eventsDiffPct / 100) | percent: '1.2-2'}}
                        </div>
                        <div class="diff-from">
                            compared to previous period
                        </div>
                    </div>
                    <div class="row em-1_2 mt-3">
                        <div class="col-6 pe-1">
                            <div class="attr-card less-padding attr-card__grey">
                                <div class="medium-new">Currently</div>
                                <div class="metric-numbers bold mt-2">
                                    {{ eventsVal | integer}}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 ps-1">
                            <div class="attr-card less-padding attr-card__grey">
                                <div class="medium-new">Projected</div>
                                <div class="metric-numbers bold mt-2">
                                    {{ eventsProjectedVal | integer}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="attr-card mt-4">
    <div class="row">
        <div class="col-6">
            <div class="attr-card__header">
                <div class="title">{{ project?.options?.general?.spend_limit == null ? 'Monthly Tracked Users' : 'Tracked Spend' }}</div>
            </div>
            <div class="card-body">
                <canvas id="{{ project?.options?.general?.spend_limit == null ? 'mtu-chart' : 'spend-chart' }}" height="340px"></canvas>
            </div>
        </div>
        <div class="col-6">
            <div class="attr-card__header d-flex justify-content-between align-items-center">
                <div class="title">Events</div>
                <div ngbDropdown class="d-inline-block me-3">
                    <button type="button" class="btn btn-new" id="dropdownBasic1" ngbDropdownToggle>
                        <span class="text-capitalize">{{ chartView }}</span>
                        <i class="fa-solid fa-angle-down ms-3"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="setMTUview('actual')">Actual</button>
                        <button ngbDropdownItem (click)="setMTUview('incremental')">Incremental</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <canvas id="me-chart" height="340px"></canvas>
            </div>
        </div>
    </div>
</div>
