import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { filter, findIndex } from 'lodash-es';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

import { Project } from 'src/app/core/models/project.model';
import { FilterTreeState, FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';
import { convertToTimline } from 'src/app/core/helpers/convert-to-timeline';
import { findAllInTree, findTopNodeInTree } from 'src/app/core/helpers/find-in-tree';
import { VisitorService } from 'src/app/core/services/visitor/visitor.service';
import { CompanyService } from 'src/app/core/services/company/company.service';
import { LocalSubscriptionService } from 'src/app/core/services/local-subscription/local-subscription.service';
import { Integration } from 'src/app/core/constants/integration';
import { Filter } from 'src/app/core/models/filter.model';
import { DatePipe } from '@angular/common';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-visit-timeline',
  templateUrl: './visit-timeline.component.html',
  styleUrls: ['./visit-timeline.component.scss']
})
export class VisitTimelineComponent implements OnChanges, OnInit {
  @Input()  visitorId;
  @Input()  companyId;
  @Input()  companyVisitors: any[];
  @Input()  summary;
  @Input()  data;
  @Input()  queryParams;
  @Input()  project: Project;
  @Input()  canScroll;
  @Output() orderChanged = new EventEmitter();
  @Output() scroll = new EventEmitter();

  @Select(FilterTreeState) filterTree$: Observable<FilterTreeStateModel>;

  timeline;
  order = 'up';
  filters;
  groups;
  selector;
  busy;
  filterTree;
  path: Array<Filter> = [];
  slicedPath: Array<Filter> = [];

  showMaxFilters = 5;

  constructor(
    private visitorService: VisitorService,
    private companyService: CompanyService,
    private localSubscriptions: LocalSubscriptionService,
    private FTWS: FilterTreeWorkerService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.filterTree$.subscribe( state => {
      if (state.filterTree) {
        this.filters = findAllInTree({c: state.filterTree}, 'c', 't', 'f');
        // this.groups = findAllInTree({c: state.filterTree}, 'c', 't', 'g');
      } else {
        this.filters = [];
      }
      this.filterTree = state.filterTree;
    });
  }

  ngOnChanges() {
    if ( this.data.length > 0) { this.busy = false; }
    this.selector = document.getElementById('sidebar-content');
    this.timeline = convertToTimline(this.data, this.project?.timezone);

    this.path = this.data.
      map(visit => {
        return {
          filter: this.filters.find(f => f.id === visit.filter),
          time: visit.time
        }
      }).
      map(filter_and_time => {
        const filter = filter_and_time.filter;
        const group = findTopNodeInTree(this.filterTree, 'c', 't', 'f', 'id', filter.id);
        return {
          id: filter.id,
          type: filter.t,
          name: filter.n,
          integration: group ? Integration.get.handles[group.n] : '',
          integrationType: null,
          time: filter_and_time.time,
          level: filter.l,
          colorIdx: this.idToColorIdx(filter.id)
        }
      });

    if (this.order === 'up') {
      this.path = this.path.reverse();
    }
    this.slicedPath = this.path.slice(-this.showMaxFilters);
  }

  showVisitor(visitorId) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sidebar: 'visitors', id: visitorId }
    });
  }

  changeOrder() {
    this.busy = true;
    if (this.order === 'up') {
      this.order = 'down';
      this.orderChanged.emit({order: 'ASC'});
    } else {
      this.order = 'up';
      this.orderChanged.emit({order: 'DESC'});
    }
  }

  onScroll() {
    if (!this.canScroll) { return; }
    this.scroll.emit(true);
  }

  visitFilters(visitFilter) {
    return filter(this.filters, {id: visitFilter});
  }

  moveToFilter(item) {
    const withParent = this.FTWS.findInTree({node: { c: this.filterTree}, childrenKey: 'c', key: 'id', value: item.id, additionalKey: 't', additionalValue: item.t, returnParent: true});
    this.localSubscriptions.showFilter({id: item.id, name: withParent.n, type: item.t, parent_filter_group_id: withParent.parent.id});
  }

  // visitGroups(filterId) {
  //   const filterParentGroups =  filter(this.groups, x => {
  //     if (x.c) {
  //       return x.c.map(c => c.id).includes(filterId);
  //     }
  //   });
  //   return filterParentGroups;
  // }

  filterTopGroup(filterId) {
    return findTopNodeInTree(this.filterTree, 'c', 'id', filterId)
  }

  loadVisit(visitId) {
    const idx = findIndex(this.timeline, { id: visitId });
    const visit = this.timeline[idx];
    if (visit.busy) {
      return;
    }

    if (visit.events) {
      visit.expanded = !visit.expanded;
    } else {
      visit.busy = true;
      this.busy = true;
      if (this.visitorId) {
        this.visitorService.getHistoryEvent(this.visitorId, visitId, this.queryParams).subscribe(
          (resp: {data: Array<any>}) => {
            visit.events = convertToTimline(resp.data, this.project?.timezone, 'time', 'event', false);
            visit.expanded = true;
            visit.busy = false;
            this.busy = false;
          }
        );
      } else {
        this.companyService.getHistoryEvent(this.companyId, visitId, this.queryParams).subscribe(
          (resp: {data: Array<any>}) => {
            visit.events = convertToTimline(resp.data, this.project?.timezone, 'time', 'event', false);
            visit.expanded = true;
            visit.busy = false;
            this.busy = false;
          }
        );
      }
    }
  }

  getSize(items) {
    let sum = 0;
    (<any>Object).values(items).forEach(event => {
      sum += event;
    });
    return sum;
  }

  idToColorIdx(idx: number): number {
    return idx % Integration.get.CSS_FILTER_COLOR_COUNT;
  }

  filterTooltip(filter: Filter): string {
    let res = '';
    const channel = this.filterTopGroup(filter.id);
    if (channel) {
      res += `${channel.n} · `;
    }
    res += new DatePipe('en').transform(filter.time);
    return res;
  }

  prependChannelName(filter: Filter): string {
    const channel = this.filterTopGroup(filter.id);
    if (!channel) {
      return filter.name;
    } else {
      return `${channel.n} - ${filter.name}`
    }
  }

}
