import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { Store } from '@ngxs/store';
import { cloneDeep, reduce } from 'lodash-es';
import { originalOrder } from 'src/app/core/helpers/key-value-original-order';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { SchemaService } from 'src/app/core/services/schema/schema.service';
import { NullableCheckboxComponent } from 'src/app/modules/shared/components/nullable-checkbox/nullable-checkbox.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-schema-view',
  standalone: true,
  imports: [RouterModule, CommonModule, NullableCheckboxComponent, SharedModule, FormsModule, NgSelectModule],
  providers: [SchemaService, FilterService],
  templateUrl: './schema-view.component.html',
  styleUrl: './schema-view.component.scss'
})
export class SchemaViewComponent implements OnInit {
  @Input() oauth;
  @Output() settingsChange = new EventEmitter();
  @Output() schemaEmitter = new EventEmitter();

  settings: any = {};
  envName = environment.envName;
  schema;
  originalOrderFn = originalOrder;

  constructor(
    private schemaService: SchemaService,
    private route: ActivatedRoute,
    private store: Store,
    private filterService: FilterService,
  ) {}

  ngOnInit() {
    if (!this.oauth) {
      this.route.queryParams.subscribe((qParams) => {
        const projectOauths = this.store.selectSnapshot((state) => state.project.project).oauths;
        if (projectOauths && projectOauths.find((x) => x.type === qParams.type)) {
          this.oauth = projectOauths.find((x) => x.type === qParams.type);
        } else {
          this.oauth = {
            type: qParams.type,
          };
        }
        this.flow();
      });
    } else {
      this.flow();
    }
    
  }

  flow() {
    this.settings = {};
    const oauth = cloneDeep(this.oauth);
    this.schemaService.getSettingsConfig(this.oauth.type).subscribe((x: any) => {
      this.schema = x;
      this.schemaEmitter.emit(x);
      let skipSettingsValue = false;
      for (const [key, value] of Object.entries(x.properties) as [string, any]) {
        const setting = {
          ...value,
          settingType: this.defineSettingType(value),
        };
        if (Array.isArray(value.type)) {
          if (value.type.includes('null')) {
            setting.type = value.type.filter((x) => x !== 'null')[0];
            setting.$nullable = true;
          }
        }
        if (value.enum) {
          setting.options_for_select = value.enum;
        } else if (value.items?.enum) {
          setting.options_for_select = value.items.enum;
        } else if (value.$options_for_select) {
          if (value.$options_for_select === 'CONVERSION_EVENTS') {
            let events = this.store.selectSnapshot((state) => state.conversionEvents.events);
            events.sort();
            setting.options_for_select = events.map((x) => x.name);
          } else if (value.$options_for_select === 'PROJECT_VIEWS') {
            const views = this.store.selectSnapshot((state) => state.project.project.views);
            const viewsArray = [];
            Object.keys(views).forEach((key) => {
              viewsArray.push({id: views[key].id, name: views[key].name});
            });
            setting.options_for_select = viewsArray;
          } else if (value.$options_for_select === 'TOP_LEVEL_FILTER_GROUPS') {
            const renderTree = (data) => {
              return reduce(data, function(result, item){
                result.push({id: item.id, name: item.n, level: item.l});
                /* Uncomment to include children groups */
                /*
                if (item.c){
                  result.push.apply(result, renderTree(item.c));
                }
                */
                return result;
              }, []);
            };
            this.filterService.getFilterTree({only_visible: true, only_groups: true}).subscribe( x => {
              setting.options_for_select = renderTree(x);
              const selectetFilterGroupId = this.oauth.settings.filter_group_id;
              if (selectetFilterGroupId) {
                const found = setting.options_for_select.find(x => x.id == selectetFilterGroupId);
                if (!found) {
                  setting.options_for_select.push({id: selectetFilterGroupId, name: `${selectetFilterGroupId}`});
                }
                setting.value = selectetFilterGroupId;
              }
              skipSettingsValue = true;
            });
          } else {
            this.schemaService.getOptionsForSelect(this.modifyOptionsForSelectUrl(value.$options_for_select)).subscribe({
              next: (options: any) => {
                if (value.type !== 'array') {
                  setting.options_for_select = options.fields.map((x) => x.key);
                } else {
                  setting.options_for_select = options.fields;
                }
              }
            });
          }
        }
        
        if (value.$production === false && environment.envName === 'production') {
          continue;
        }
        if (oauth.settings?.[key]) {
          if (!skipSettingsValue) {
            setting.value = oauth.settings[key];
          }
        } else if (value.default) {
          setting.value = value.default;
        } else {
          if (setting.type === 'boolean' && setting.$nullable != true) {
            setting.value = false;
          } else {
            setting.value = null;
          }
        }
        this.settings[key] = setting;
      }
    });
  }

  setSetting(setting, value) {
    if (this.settings[setting]?.value) {
      this.settings[setting].value = value;
    } else {
      this.settings[setting] = {value};
    }
    if (setting.$nullable && setting.value == "") {
      this.settings[setting].value = setting.default;
    }
    if (setting.type == 'number' && typeof setting.value == 'string') {
      this.settings[setting].value = parseFloat(setting.value);
    }
    const settingsValues: any = {};
    if (setting === 'filter_group_id') {
      this.settings[setting].value = value.id;
    }
    Object.keys(this.settings).forEach((key, index) => {
      settingsValues[key] = this.settings[key].value;
    });
    const nullRemoved = Object.fromEntries(Object.entries(settingsValues).filter(([_, v]) => v != null));
    this.settingsChange.emit(nullRemoved);
  }

  modifyOptionsForSelectUrl(url) {
    let modified = url;
    modified = modified.replace('{OAUTH_ID}', this.oauth.id);
    modified = modified.replace('{CLIENT_API}', environment.clientApiUrl);
    return modified;
  }

  defineSettingType(property) {
    if (
      property.enum
      || (property.type !== 'array' && property.$options_for_select)
    ) {
      return 'select';
    } else if (property.type === 'array') {
      return 'array' +'-' + property.items.type;
    } else {
      return property.type;
    }
  }

  setDestionationChannel(channel) {
    this.setSetting('filter_group_id', channel.id);
  }
}
