export enum ComponentNames {
    dashboard = '',
    cohort = 'cohort',
    firstTimePurchasers = 'first-time-purchasers',
    channelPerformance = 'channel-performance',
    paths = 'paths',
    dashboardBuilder = 'dashboard-builder',
    overview = 'overview',
    timeframe = 'timeframe',
    settings = 'settings',
    login = 'login',
    account = 'account',
    notifications = 'notifications',
    signup = 'signup',
    sendInstructions = 'install-instructions',
    onboarding = 'onboarding',
}