import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataCollectionStatusComponent } from './integration-instructions/data-collection-status/data-collection-status.component';
import { IntegrationInstructionsComponent } from './integration-instructions/integration-instructions.component';
import { SharedModule } from '../../shared/shared.module';
import { SnippetInstructionsComponent } from './integration-instructions/snippet-instructions/snippet-instructions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { ConversionAndRevenueComponent } from './project-settings/conversion-and-revenue/conversion-and-revenue.component';
import { DisregardExistingUsersComponent } from './project-settings/disregard-existing-users/disregard-existing-users.component';
import { CompanyBasedAttributionComponent } from './project-settings/company-based-attribution/company-based-attribution.component';
import { OnlyUsersInDaterangeComponent } from './project-settings/only-users-in-daterange/only-users-in-daterange.component';
import { PositionBasedAttributionRatiosComponent } from './project-settings/position-based-attribution-ratios/position-based-attribution-ratios.component';
import { CurrencySelectorComponent } from './project-settings/currency-selector/currency-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimezoneSelectorComponent } from './project-settings/timezone-selector/timezone-selector.component';
import { AccessComponent } from './access/access.component';
import { CoreModule } from 'src/app/core/core.module';

import { LookbackOptionComponent } from './project-settings/lookback-option/lookback-option.component';
import { FirstPurchaseOnlyComponent } from './project-settings/first-purchase-only/first-purchase-only.component';
import { SettingsSharedModule } from '../shared/settings-shared.module';
import { IntegrationsComponent } from '../integrations/integrations.component';
import { IntegrationShowComponent } from '../integrations/integration-show/integration-show.component';
import { WeeklyEmailComponent } from './weekly-email/weekly-email.component';
import { IntegrationSettingsComponent } from '../integrations/integration-settings/integration-settings.component';

import { ActivecampaignComponent } from '../integrations/explainers/activecampaign/activecampaign.component';
import { CallrailComponent } from '../integrations/explainers/callrail/callrail.component';
import { ShopifyComponent } from '../integrations/explainers/shopify/shopify.component';
import { SelectAccountComponent } from '../integrations/select-account/select-account.component';
import { FriendbuyComponent } from '../integrations/explainers/friendbuy/friendbuy.component';
import { ImpactComponent } from '../integrations/explainers/impact/impact.component';
import { SegmentReverseComponent } from '../integrations/explainers/segment_reverse/segment_reverse.component';
import { SegmentReverseEventsComponent } from '../integrations/explainers/segment_reverse_events/segment_reverse_events.component';
import { ManageIntegrationModalComponent } from '../integrations/manage-integration-modal/manage-integration-modal.component';
import { MarketoComponent } from '../integrations/explainers/marketo/marketo.component';
import { OutbrainComponent } from '../integrations/explainers/outbrain/outbrain.component';
import { RedditComponent } from '../integrations/explainers/reddit/reddit.component';
import { StackadaptComponent } from '../integrations/explainers/stackadapt/stackadapt.component';
import { KlaviyoComponent } from '../integrations/explainers/klaviyo/klaviyo.component';
import { IntegrationStatusIconComponent } from '../integrations/integration-status-icon/integration-status-icon.component';
import { RevenueGroupsComponent } from './project-settings/revenue-groups/revenue-groups.component';
import { LiveDebugComponent } from './live-debug/live-debug.component';
import { GdprRulesComponent } from './gdpr-rules/gdpr-rules.component';
import { ExcludeFiltersComponent } from './project-settings/exclude-filters/exclude-filters.component';
import { IsAttrTypePipe } from './project-settings/lookback-option/is-attr-type.pipe';
import { NgxJdenticonModule } from 'ngx-jdenticon';
import { LiveDebugCalculateTypePipe } from './live-debug/calculate-type.pipe';
import { LiveDebugGetUserIdPipe } from './live-debug/get-user-id.pipe';
import { LiveDebugTakePathPipe } from './live-debug/take-path.pipe';
import { EventsUploaderComponent } from './events-uploader/events-uploader.component';
import { OauthStatusPipe } from '../integrations/integration-show/pipes/oauth-status-pipe';
import { CanManageIntegrationPipe } from '../integrations/integration-show/pipes/can-manage-pipe';
import { InfluencesUploaderComponent } from './influences-uploader/influences-uploader.component';
import { IntegrationIsBetaPipe } from '../integrations/pipes/is-beta.pipe';
import { CommonComponent } from '../integrations/explainers/common/common.component';
import { SettingsOverviewComponent } from './overview/settings-overview.component';
import { ShopifySettingsComponent } from '../shopify-segment/shopify-settings/shopify-settings.component';
import { SegmentSettingsComponent } from '../shopify-segment/segment-settings/segment-settings.component';
import { OauthsByStatusPipe } from '../integrations/oauths-by-status.pipe';
import { SchemaViewComponent } from "../integrations/integration-settings/schema-view/schema-view.component";
import { JobLogsComponent } from '../integrations/job-logs/job-logs.component';
import { IsNotOpenIntegrationPipe } from '../integrations/pipes/is-not-open.pipe';


@NgModule({
  declarations: [
    SettingsComponent,
    DataCollectionStatusComponent,
    IntegrationInstructionsComponent,
    SettingsOverviewComponent,
    SnippetInstructionsComponent,
    ProjectSettingsComponent,
    ConversionAndRevenueComponent,
    DisregardExistingUsersComponent,
    CompanyBasedAttributionComponent,
    OnlyUsersInDaterangeComponent,
    PositionBasedAttributionRatiosComponent,
    CurrencySelectorComponent,
    TimezoneSelectorComponent,
    AccessComponent,
    LookbackOptionComponent,
    FirstPurchaseOnlyComponent,
    IntegrationsComponent,
    IntegrationShowComponent,
    JobLogsComponent,
    WeeklyEmailComponent,
    IntegrationSettingsComponent,
    ActivecampaignComponent,
    CallrailComponent,
    ShopifyComponent,
    SelectAccountComponent,
    FriendbuyComponent,
    ImpactComponent,
    KlaviyoComponent,
    RedditComponent,
    SegmentReverseComponent,
    SegmentReverseEventsComponent,
    StackadaptComponent,
    ManageIntegrationModalComponent,
    MarketoComponent,
    OutbrainComponent,
    IntegrationStatusIconComponent,
    RevenueGroupsComponent,
    LiveDebugComponent,
    GdprRulesComponent,
    ExcludeFiltersComponent,
    InfluencesUploaderComponent,
    IsAttrTypePipe,
    LiveDebugCalculateTypePipe,
    LiveDebugGetUserIdPipe,
    LiveDebugTakePathPipe,
    EventsUploaderComponent,
    OauthStatusPipe,
    CanManageIntegrationPipe,
    IntegrationIsBetaPipe,
    IsNotOpenIntegrationPipe,
    CommonComponent,
    ShopifySettingsComponent,
    SegmentSettingsComponent,
    OauthsByStatusPipe
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    NgSelectModule,
    CoreModule,
    SettingsSharedModule,
    FormsModule,
    NgxJdenticonModule,
    SchemaViewComponent
  ],
   exports: [
    ConversionAndRevenueComponent,
    CurrencySelectorComponent,
    TimezoneSelectorComponent,
    PositionBasedAttributionRatiosComponent,
    CompanyBasedAttributionComponent,
    DisregardExistingUsersComponent,
    OnlyUsersInDaterangeComponent,
    LookbackOptionComponent,
    FirstPurchaseOnlyComponent,
    IntegrationsComponent,
    IntegrationSettingsComponent,
    JobLogsComponent,
    ActivecampaignComponent,
    CallrailComponent,
    ShopifyComponent,
    SelectAccountComponent,
    FriendbuyComponent,
    ImpactComponent,
    SegmentReverseComponent,
    SegmentReverseEventsComponent,
    ManageIntegrationModalComponent,
    MarketoComponent,
    OutbrainComponent,
    StackadaptComponent,
    IntegrationStatusIconComponent,
    RevenueGroupsComponent,
    LiveDebugComponent,
    ExcludeFiltersComponent,
    IsAttrTypePipe,
    IntegrationIsBetaPipe,
    CommonComponent
   ]
})
export class SettingsModule { }
