import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cohortCalculateAmount'
})
export class CohortCalculateAmountPipe implements PipeTransform {
  transform(metric: string, revenue: number, expenditure: number, denomination: string ): any {
    let amount = metric === 'ROAS' ? revenue - expenditure : metric === 'Revenue' ? revenue : revenue / expenditure;
    if ( denomination === 'Cents' ) {
      amount = amount;
    } else if (denomination === 'Dollars') {
      amount = Math.round(amount);
    } else if ( denomination === 'Thousands') {
      amount = (Math.round(amount / 100) / 10);
    }

    return amount;
  }
}