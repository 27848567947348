import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { groupByParam } from '../../helpers/other';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  get(ip) {
    const Url = `${environment.geoHost}/${ip}`;

    return this.httpClient.get(
      Url,
      {
        params: {
          access_key: environment.geoAccessKey,
          fields: 'city,region_name,country_name'},
        observe: 'body'
      });
  }

  requestBulk(data, size) {
    const withIPandNoLocation = data.filter( x => !x.location && x.ip);
    const arrayOfArrays = [];
    for (var i=0; i < withIPandNoLocation.length; i+=size) {
        arrayOfArrays.push(withIPandNoLocation.slice(i,i+size));
    }
    let observableArray = [];
    arrayOfArrays.forEach( x => {
      observableArray.push(this.getBulk(x.map( y => y.ip)));
    });
    return forkJoin(observableArray).pipe(map(data => {
      return groupByParam(data.flat(1), 'ip')
    }));
  }

  getBulk(ipArray) {
    const Url = `${environment.geoHost}/${ipArray.join(',')}`;

    return this.httpClient.get(
      Url,
      {
        params: {
          access_key: environment.geoAccessKey,
          fields: 'ip,city,region_name,country_name'},
        observe: 'body'
      });
  }

  addLocationsWithIpForBatch(data) {
    const response = data;
    const ipArray = data.filter( x => {
      if (x.ip && !x.location) {
        return true;
      } else {
        return false;
      }
    }).map( x => x.ip);
    if (ipArray.length === 0) { return of(null); }
    return this.getBulk(ipArray).pipe( map(
      (resp: Array<any>) => {
        resp.forEach( x => {
          const {ip, ...everythingElse} = x;
          response.find(y => y.ip === ip).location = everythingElse;
        });
        return response;
      })
    );
  }
}
