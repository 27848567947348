import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { findAllInTree } from 'src/app/core/helpers/find-in-tree';
import { CreateNewFilterResponse } from 'src/app/core/services/filter/filter-service.models';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { ReportRefresherService } from 'src/app/core/services/report-refresher/report-refresher.service';
import { AddToPendingDataRefresh } from 'src/app/store/actions/base.actions';
import { AddFilter, DeleteFilter, EditFilter, EditVirtualFilter } from 'src/app/store/actions/filter-tree.actions';

@Component({
  selector: 'app-new-virtual-filter-modal',
  templateUrl: './new-virtual-filter-modal.component.html',
  styleUrls: ['./new-virtual-filter-modal.component.scss']
})
export class NewVirtualFilterModalComponent implements OnInit, OnChanges {
  @Input() vFilterInfo: any;
  @Input() allFilters = [];
  @Input() allChannels = [];

  deleteWaiting = false;
  waitingForResponse = false;
  name = '';
  addedItems = [];
  errorMessage;

  constructor(
    private store: Store,
    public activeModal: NgbActiveModal,
    private filterService: FilterService,
    private reportRefresher: ReportRefresherService
  ) { }

  ngOnInit(): void {
    this.seedFiltersAndChannels();
  }

  ngOnChanges(): void {
    this.seedFiltersAndChannels();
  }

  seedFiltersAndChannels() {
    if (this.vFilterInfo) {
      this.name = this.vFilterInfo.name;
      const addedFilters = this.allFilters.filter( x => {
        return typeof(this.vFilterInfo.included_filters.find(y => y.id == x.id && x.t)) != 'undefined';
      }).map( x => {
        const path = x.path.names.join(' > ');
        return {
          ...x,
          path: path === x.n ? null : path + ` > ${x.n}`
        }
      });
      const addedChannels = this.allChannels.filter( x => {
        return typeof(this.vFilterInfo.included_filter_groups.find(y => y.id == x.id)) != 'undefined';
      }).map( x => {
        const path = x.path.names.join(' > ');
        return {
          ...x,
          path: path === x.n ? null : path + ` > ${x.n}`
        }
      });
      this.addedItems = [...addedFilters, ...addedChannels];
    }
  }

  submit() {
    if (this.isValid) {
      this.errorMessage = null;
      const parameters: any = {
        name: this.name,
        virtual: {
          filter_ids: this.addedItems.filter( x => x.t == 'f').map(x => x.id),
          filter_group_ids: this.addedItems.filter( x => x.t == 'g').map(x => x.id)
        }
      };
      this.waitingForResponse = true;
      if (!this.vFilterInfo) {
        this.filterService.createNewFilter(parameters).subscribe( (resp: CreateNewFilterResponse) => {
          this.store.dispatch([
            new AddFilter({filter: {n: resp.name, id: resp.id, s: 'virtual', it: 'Group'}}),
            new AddToPendingDataRefresh({id: resp.id, type: 'f'})
          ]).subscribe(() => {
            this.reportRefresher.scheduleRefresh();
            this.store.dispatch(new EditVirtualFilter({virtualFilterId: resp.id, filters: parameters.virtual.filter_ids, groups: parameters.virtual.filter_group_ids})).subscribe(() => {
              this.waitingForResponse = false;
              this.activeModal.close({ result: 'vFilterAdded', filter: resp});
            });
          }, error => {
            this.waitingForResponse = false;
          });
        });
      } else {
        this.filterService.editFilter(this.vFilterInfo.id, parameters).subscribe( (resp: CreateNewFilterResponse) => {
          this.store.dispatch([
            new EditFilter({n: resp.name, id: resp.id}),
            new AddToPendingDataRefresh({id: resp.id, type: 'f'})
          ]).subscribe(() => {
            this.reportRefresher.scheduleRefresh();
            this.store.dispatch(new EditVirtualFilter({virtualFilterId: resp.id, filters: parameters.virtual.filter_ids, groups: parameters.virtual.filter_group_ids})).subscribe(() => {
              this.waitingForResponse = false;
              this.activeModal.close({result: 'vFilterEdited', filter: resp});
            });
          }, error => {
            this.waitingForResponse = false;
          });
        });
      }
    } else {
      this.errorMessage = 'Please enter the name for group and select at least one filter or channel.';
    }
  }

  delete() {
    this.deleteWaiting = true;
    this.filterService.deleteFilter(this.vFilterInfo.id).subscribe( () => {
      this.store.dispatch(new DeleteFilter({id: this.vFilterInfo.id})).subscribe(() => {
        this.deleteWaiting = false;
        this.activeModal.close({result: 'vFilterDeleted', filterId: this.vFilterInfo.id});
      }, error => {
        this.deleteWaiting = false;
      });
    });
  }


  removeItem(item) {
    this.addedItems = this.addedItems.filter( x => !(x.id == item.id && x.t == item.t));
  }

  get isValid() {
    return (this.addedItems.length > 0) && this.name.length > 0;
  }

  filterSelected(event) {
    const item = {
      id: event.id,
      n: event.n || event.name,
      t: event.t || event.type,
      path: typeof(event.path) != 'undefined' ? event.path : null
    };
    const isNotAlready = typeof(this.addedItems.find(x => x.id == item.id && x.t == item.t)) == 'undefined';
    if (isNotAlready) {
      this.addedItems.push(item);
    }
  }

}
