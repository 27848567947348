import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { SegmentService } from 'ngx-segment-analytics';

import { Account } from 'src/app/core/models/account.model';
import { AccountState } from 'src/app/store/states/account.state';
import { ProjectState, ProjectStateModel } from 'src/app/store/states/project.state';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { environment } from 'src/environments/environment';
import { SetChosenDates, SetSettingSection } from 'src/app/store/actions/selected.actions';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewProjectModalComponent } from '../components/new-project-modal/new-project-modal.component';
import { CheckAppVersion, SetAppVersion, SetIsAuthenticated } from 'src/app/store/actions/base.actions';
import { BaseService } from 'src/app/core/services/base/base.service';
import { SidebarSetName } from 'src/app/store/actions/only-simple.actions';
import { CloseSidebar } from 'src/app/store/actions/sidebar.actions';
import { AccountService } from 'src/app/core/services/account/account.service';
import { titleCase } from 'src/app/core/helpers/other';
import { map } from 'rxjs/operators';
import { BaseStateModel } from 'src/app/store/states/base.state';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { WorkerStateModel } from 'src/app/store/states/worker.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(AccountState) account$: Observable<Account>;
  @Select(ProjectState) project$: Observable<ProjectStateModel>;
  @Select(state => state.selected) selected$: Observable<SelectedStateModel>;
  @Select(state => state.base) base$: Observable<BaseStateModel>;

  isProd = environment.isProd;
  isDemo: boolean = environment.envName === 'demo';

  componentNames = ComponentNames;
  settingsSections = SettingsSections;
  sidebarNames = SidebarNames;

  currentComponent: string;
  dates: { start: string; end: string } = { start: null, end: null };
  assetPath = environment.assetPath;
  isPathsActive: boolean;
  isCompaniesActive: boolean;
  isCpActive: boolean;
  isOverviewActive: boolean;
  isFirstTimePurchasersActive: boolean;
  versionCheckInterval;
  settingSection: string = null;
  githubLink;
  shortVersion;
  branch;

  account: Account;
  project: Project;
  projectsList: Project[];
  currentIdentifier: string = '';
  userIsAuthenticated = false;
  jsHeapSize;

  projectIsSwitching = false;
  shopifyUpgradeUrl = 'https://www.attributionapp.com';

  constructor(
    private authService: AuthService,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private segment: SegmentService,
    private store: Store,
    private modalService: NgbModal,
    private baseService: BaseService,
    private accountService: AccountService,
    private ngZone: NgZone,
    private filterTreeWorkerService: FilterTreeWorkerService) {}

  ngOnInit() {
    const filterTree = this.store.selectSnapshot(state => state.filterTree.filterTree);
    const workerState = this.store.selectSnapshot<WorkerStateModel>(state => state.worker);
    if (!workerState.filterWorkerInProgress && filterTree && this.filterTreeWorkerService.filters == null) {
      this.filterTreeWorkerService.postToWorker(filterTree);
    }
    this.base$.subscribe( x => {
      if (x.fullVersion) this.githubLink = `https://github.com/attribution/attribution/commit/${x.fullVersion}`;
      this.shortVersion = x.appVersion;
      this.branch = x.branch;
      this.userIsAuthenticated = x.isAuthenticated;
      if (!this.userIsAuthenticated && this.authService.isAuthenticated) {
        this.store.dispatch(new SetIsAuthenticated(true));
      }
    });
    combineLatest([this.account$, this.project$]).pipe(
      map(([accountState, projectState]) => {
        this.account = accountState;
        this.project = projectState.project;
        this.shopifyUpgradeUrl = `${environment.clientApiUrl}/shopify/segment/pricing?shop=${this.project?.oauths?.find(x => x.type == 'shopify')?.identifier}&change_plan=true`
        return {
          account: accountState,
          project: projectState
        };
      })
    ).subscribe( resp => {
      if (resp.account.projects && resp.project.project) {
        this.projectsList = resp.account.projects.filter( x => x.id !== resp.project.project.id);
      }
      if (resp.project.project) {
        this.currentIdentifier = resp.project.project.identifier;
        // TODO shouldn't it be project model method?
        this.isCompaniesActive           = this.project.options?.features?.company_based_attribution;
        this.isPathsActive               = this.project.options?.reports?.paths;
        this.isCpActive                  = this.project.options?.reports?.channel_performance;
        this.isOverviewActive            = this.project.options?.reports?.overview;
        this.isFirstTimePurchasersActive = this.project.options?.reports?.first_time_purchasers;
      }
    });
    this.selected$.subscribe( resp => {
      if (
        (
          this.currentComponent != resp.currentComponent && resp.currentComponent != ComponentNames.settings)
          || (resp.currentComponent == this.componentNames.settings && this.settingSection != resp.settingSection)
          || (environment.envName === 'staging' && this.currentComponent === this.componentNames.login)
          ) {
        const Properties = {
          url: window.location.origin + window.location.pathname + window.location.search,
          path: window.location.pathname,
          search: window.location.search
        };
        const titleizedComponentName = resp.currentComponent?.length > 0 ? titleCase(resp.currentComponent) : 'Dashboard';
        this.segment.page(resp.currentComponent == this.componentNames.settings ? `${titleizedComponentName} - ${titleCase(resp.settingSection)}` : titleizedComponentName, null, Properties);
      }
      this.currentComponent = resp.currentComponent;
      this.settingSection = resp.settingSection;
      if (this.settingSection && this.currentComponent != this.componentNames.settings && this.currentComponent != this.componentNames.account) {
        this.store.dispatch(new SetSettingSection(null));
      }
      this.dates = resp.chosenDates;
    });
    if (environment.production && environment.segmentIoWriteKey !== '') {
      if (environment.envName === 'staging') {
        this.segment.load(environment.segmentIoWriteKey);
      } else {
        this.segment.load(environment.segmentIoWriteKey, {
          integrations: {
            All: false,
            Attribution: true,
            'Customer.io': true,
            'Segment.io': true
          }
        });
      }

      this.segment.addSourceMiddleware(({payload, next}) => {
        if (payload.obj.event === 'Report Loaded by Member')  {
          payload.obj.userId = payload.obj.properties.owner_account_id;
          payload.obj.anonymousId = `owner_account_id-${payload.obj.properties.owner_account_id}`;
          delete payload.obj.properties.owner_account_id;
          delete payload.obj.properties.owner_account_email;
        }
        next(payload);
      });
    }
    this.route.queryParams.subscribe( queryParams => {
      const selectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected);

      if (
        queryParams.start &&
        queryParams.end &&
        (queryParams.start !== selectedState.chosenDates.start || queryParams.end !== selectedState.chosenDates.end)
      ) {
        this.store.dispatch(new SetChosenDates({start: queryParams.start, end: queryParams.end}));
      }
    });

    if (this.userIsAuthenticated) {
      this.baseService.getVersionAndBranch().subscribe( resp => {
        this.store.dispatch(new SetAppVersion({version: resp.version, fullVersion: resp.fullVersion, branch: resp.branch}));
      });
    }
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Changes here will not propagate into your view.
        this.ngZone.run(() => {
          // Run inside the ngZone to trigger change detection.
          this.versionCheckInterval = setInterval(() => {
            if (this.userIsAuthenticated) {
              this.baseService.getVersionAndBranch().subscribe( resp => this.store.dispatch(new CheckAppVersion({version: resp.version, fullVersion: resp.fullVersion, branch: resp.branch})));
            }
          }, 10 * 60 * 1000);
        });
      }, 10 * 60 * 1000);
    });
  }

  setJSheap() {
    const perf: any = performance;
    this.jsHeapSize = perf.memory.usedJSHeapSize;
  }

  ngOnDestroy() {
    if (this.versionCheckInterval) {
      clearInterval(this.versionCheckInterval);
    }
  }

  logout() {
    this.authService.logout();
  }

  switchProject(projectId, projectIdentifier) {
    if (this.projectIsSwitching) return;
    // ToDo get all the other parameteres except project and add them to the url
    this.projectIsSwitching = true;
    this.projectService.selectProject(projectId, projectIdentifier).subscribe({next: () => {
      this.store.dispatch(new SidebarSetName({}));
      this.store.dispatch(new CloseSidebar());
      const routeArray = this.currentComponent == this.componentNames.account ? [] : [projectIdentifier];
      if (this.currentComponent.length > 0) routeArray.push(this.currentComponent);
      if (this.settingSection) routeArray.push(this.settingSection);
      this.projectIsSwitching = false;
      this.router.navigate(routeArray);
    }, error: () => this.projectIsSwitching = false });
  }

  openNewProjectModal() {
    const ownersArray = this.account.projects.map( x => {
      return x.owner;
    });
    const uniqueArray = getUniqueById(ownersArray);
    const modalRef = this.modalService.open(NewProjectModalComponent);
    modalRef.componentInstance.owners = uniqueArray;

    function getUniqueById(array) {
      const result = [];
      const map = new Map();
      for (const item of array) {
        if (!map.has(item.id)) {
          map.set(item.id, true);    // set any value to Map
          result.push({
              id: item.id,
              email: item.email
          });
        }
      }
      return result;
    }
  }

  refreshAllData() {
    this.baseService.refreshAllData();
  }

}
