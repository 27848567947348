
<h1 class="mt-0">Notifications</h1>

<div class="attr-card">
  <div class="alert alert-primary" role="alert">
    Each team member will receive report based of his last used "Saved View"
  </div>

  <p class="title-no-bold mb-4 mt-5">Send weekly emails to:</p>
  <div class="row">
    <div class="col-lg-6">
      <label><strong>Enable for all users</strong></label>
    </div>
    <div class="col-lg-6">
      <div class="switch">
        <app-toggle-switch
          [isOn]="allNotificationsOn()"
          (eventEmitter)="toggleAllWeeklyEmails(!allNotificationsOn())"
        ></app-toggle-switch>
      </div>
    </div>
  </div>

  <div *ngFor="let access of accesses">
    <div class="row mt-4">
      <div class="col-lg-6">
        <label>
          {{ access.email }}
        </label>
      </div>
      <div class="col-lg-6">
        <div class="switch">
          <app-toggle-switch
            [isOn]="isNotificationsOn(access)"
            (eventEmitter)="toggleWeeklyEmails(access, !access.weekly_notification)"
          ></app-toggle-switch>
        </div>
      </div>
    </div>
  </div>
</div>
