<div class="modal-header">
  <div class="modal-title">
    <h3 class="mb-0 mt-0">{{ vFilterInfo == null ? 'Add a New' : 'Edit' }} Group</h3>
    <small class="extra-small">Groups can be used to group filters and campaigns across different channels. Learn how to use them <a href="https://docs.attributionapp.com/docs/channels-filters" target="_blank">here</a>.</small>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="errorMessage" class="alert alert-danger full-width" role="alert">
  {{ errorMessage }}
</div>
<div class="modal-body modal-body--source pb-5">
  <div class="filter_title">
    <label class="caps">Name</label>
    <input class="form-control" type="name" id="name" name="name" [(ngModel)]="name" autofocus placeholder="Group Name">
  </div>
  <div class="filter-params">
    <div>
      <div class="section-title caps">
        Filters
      </div>
      <div class="position-relative">
        <app-filter-search
          (filterSelected)="filterSelected($event)"
          [isOnDashboard]="false"
          [isVFilterModal]="true"></app-filter-search>
      </div>
      <div class="added-row" [class.group]="item.t == 'g'" *ngFor="let item of addedItems">
        <i class="fa-solid fa-circle-xmark white cursor-pointer me-1" (click)="removeItem(item)"></i>
        <span *ngIf="item.path; else withoutPath" ngbTooltip="{{item.path}}">{{ item.n }}</span>
        <ng-template #withoutPath>
          {{ item.n }}
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" [class.space-between]="vFilterInfo">
  <a *ngIf="vFilterInfo">
    <div>
      <span *ngIf="!deleteWaiting; else deleteWaitingTemplate" (click)="delete()">
        <i class="fa-solid fa-trash red"></i>
        <span class="caps red"> delete</span>
      </span>
      <ng-template #deleteWaitingTemplate>
        <i class="fa-solid fa-spinner fa-spin red"></i>
      </ng-template>
    </div>
  </a>
  <button type='submit' class="btn btn-success" [class.disabled]="!isValid" (click)="submit()">
    <span *ngIf="!waitingForResponse; else waiting">
      <span class="caps">Save </span>
      <i class="fa-solid fa-check-circle"></i>
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>