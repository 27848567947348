import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import {
  SetFilterTree,
  RenameChannel,
  DeleteChannel, 
  ClearFilterTree} from 'src/app/store/actions/filter-tree.actions';
import { FilterTreeItem } from 'src/app/store/interfaces/filter-tree';
import { Observable, Subject } from 'rxjs';
import { ReportRefresherService } from '../report-refresher/report-refresher.service';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  inProgress;
  @Select(state => state.filterTree.filterTree) filterTree$: Observable<Array<FilterTreeItem>>;
  apiUrl = environment.clientApiUrl;
  public filterTreeExpanded: Subject<void> = new Subject<void>();

  constructor(private httpClient: HttpClient, private store: Store, private reportRefresher: ReportRefresherService) { }

  getFilterTree(params?) {
    const Url = this.apiUrl + '/v2/filters/tree';

    let Params;
    if (params) {
      Params = { params: params };
    }

    this.inProgress = true;
    if (!params) {
      this.store.dispatch(new ClearFilterTree());
    }
    return this.httpClient.get(Url, Params).pipe(
      map( resp => {
          this.inProgress = false;
          if (!params) {
            this.store.dispatch(new SetFilterTree(resp));
          }
          return resp;
        }, error => this.inProgress = false
      )
    );
  }

  getErrors() {
    const Url = this.apiUrl + '/v2/filter_errors/summary';
    const selectedState: SelectedStateModel = this.store.selectSnapshot(x => x.selected);
    if (selectedState?.chosenDates?.start && selectedState?.chosenDates?.end) {
      return this.httpClient.get(Url, { params: { start: selectedState.chosenDates.start, end: selectedState.chosenDates.end}});
    }
  }

  move(node) {
    let Url = `${this.apiUrl}/v2/arrangements/move_to`;
    let body: any = {};

    body.destination = typeof(node.parent?.id) === 'undefined' ? 'root' : node.parent.id;
    body.position = node.index;

    if (node.t === 'f') {
      Url = `${Url}?filter_id=${node.id}`;
    } else if (node.t === 'g') {
      Url = `${Url}?filter_group_id=${node.id}`;
    }

    return this.httpClient.post(Url, body);
  }

  massMove(destination, filterIds, groupIds) {
    const Url = `${this.apiUrl}/v2/arrangements/move_to`;

    return this.httpClient.post(Url, {
      destination: destination,
      filter_ids: filterIds,
      group_ids: groupIds
    });
  }

  collapse(groupId) {
    const Url = `${this.apiUrl}/v2/filter_groups/${groupId}/collapse`;
    return this.httpClient.post(Url, {id: groupId});
  }

  expand(groupId) {
    const Url = `${this.apiUrl}/v2/filter_groups/${groupId}/expand`;
    return this.httpClient.post(Url, {id: groupId});
  }

  createNewChannel(newChannelName, parentFilterGroupId?) {
    let params: any = {name: newChannelName};
    if (parentFilterGroupId) params.parent_filter_group_id = parentFilterGroupId;
    const Url = `${this.apiUrl}/v2/filter_groups`;
    return this.httpClient.post(Url, params);
  }

  editChannel(channelId, channelName) {
    const Url = `${this.apiUrl}/v2/filter_groups/${channelId}`;

    this.httpClient.post(Url, {id: channelId, name: channelName}).subscribe(
      () => this.store.dispatch(new RenameChannel({id: channelId, n: channelName}))
    );
  }

  deleteChannel(channelId) {
    const Url = `${this.apiUrl}/v2/filter_groups/${channelId}`;

    this.httpClient.delete(Url).subscribe(
      () => {
        this.store.dispatch(new DeleteChannel({id: channelId}));
        this.reportRefresher.scheduleRefresh();
      }
    );
  }

  massDelete(filterIds, groupIds) {
    const Url = `${this.apiUrl}/v2/filter_groups`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        filter_ids: filterIds,
        group_ids: groupIds,
      },
    };

    return this.httpClient.delete(Url, options);
  }

  createNewFilter(params) {
    const Url = `${this.apiUrl}/v2/filters`;

    return this.httpClient.post(Url, params);
  }

  getFilter(id) {
    const Url = `${this.apiUrl}/v2/filters/${id}`;

    return this.httpClient.get(Url);
  }

  editFilter(id, params) {
    const Url = `${this.apiUrl}/v2/filters/${id}`;

    return this.httpClient.post(Url, params);
  }

  deleteFilter(id) {
    const Url = `${this.apiUrl}/v2/filters/${id}`;

    return this.httpClient.delete(Url);
  }

  disableGroup(groupId, show?) {
    const Url = `${this.apiUrl}/v2/filter_groups/${groupId}/${show ? 'show' : 'hide'}`;
    const params = { id: groupId };
    return this.httpClient.post(Url, params);
  }

  disableFilter(filterId, show?) {
    const Url = `${this.apiUrl}/v2/filters/${filterId}/${show ? 'show' : 'hide'}`;
    const params = { id: filterId };
    return this.httpClient.post(Url, params);
  }

  search(q, withoutVirtuals?) {
    const Url = `${this.apiUrl}/v2/filters/search`;
    
    const params: any = {q};
    if (withoutVirtuals) params.without_virtual = true;

    return this.httpClient.get(Url, {params: params});
  }

  uploadCSV(files?) {
    const Url = `${environment.clientApiUrl}/v2/filters/upload_csv`;

    const fd = new FormData();
    if (files.length === 1) {
        fd.append('csv_file', files.item(0));
    } else {
        for (let file of files)
        for (let fileIndex in files) {
          fd.append('csv_file' + '_' + fileIndex, files[fileIndex]);
        }
    }

    return this.httpClient.post(Url, fd);
  }
}
