import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardDisableDND',
})
export class DashboardDisableDNDPipe implements PipeTransform {
  transform(item): any {
    let shouldDisableDND = false;
    if (item.t === 'f') {
        const filterShouldNotEqual = ['Spend Fixer'];
        const filterStartsWith = ['Keyword', 'Creative ID', 'Creative Name'];
        const filterEndsWith = ['Ad Group', 'Ad Set', 'Ad'];
        if (!item.it) return false;
        if (filterShouldNotEqual.includes(item.it)) {
            shouldDisableDND = true;
        } else if (
            filterStartsWith.some((startsWith) => item.it?.startsWith(startsWith))
        ) {
            shouldDisableDND = true;
        } else if (
            filterEndsWith.some((endsWith) => item.it?.endsWith(endsWith))
        ) {
            shouldDisableDND = true;
        }
    } else if (item.t === 'g') {
        const groupShouldNotEqual = ['Ad Set'];
        const groupEndsWith = ['Ad Group', 'Ad Set'];
        if (groupShouldNotEqual.includes(item.it)) {
            shouldDisableDND = true;
        } else if (
            groupEndsWith.some((endsWith) => item.it?.endsWith(endsWith))
        ) {
            shouldDisableDND = true;
        }
    }
    return shouldDisableDND;
  }
}
