import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/core/services/base/base.service';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filters-csv-upload',
  templateUrl: './filters-csv-upload.component.html',
  styleUrls: ['./filters-csv-upload.component.scss']
})
export class FiltersCsvUploadComponent {
  uploadSuccessful = false;
  uploadError;
  errorLine;
  errorLineNumber;
  uploadedSuccessfully = false;
  assetPath = environment.assetPath;

  constructor(
    public activeModal: NgbActiveModal, 
    private filterService: FilterService,
    private baseService: BaseService
    ) {}

  refresh() {
    if (this.uploadSuccessful !== true) return;
    this.baseService.refreshAllData();
  }

  onUpload(files: FileList) {
    const csvElem = document.getElementById('csvFile') as HTMLInputElement;
    this.filterService.uploadCSV(files).subscribe({complete: () => {
      csvElem.value = '';
      this.uploadError = null;
      this.errorLine = null;
      this.errorLineNumber = null;
      this.uploadSuccessful = true;
      this.uploadedSuccessfully = true;
    }, error: (error) => {
        csvElem.value = '';
        this.uploadError = error?.error?.error;
        if (error.error.error_line) this.errorLine = error.error.error_line;
        if (error.error.error_line_number) this.errorLineNumber = error.error.error_line_number;
      }
    });
  }
}
