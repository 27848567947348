import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';

import { Project } from 'src/app/core/models/project.model';

@Component({
  selector: 'app-empty-sidebar',
  templateUrl: './empty-sidebar.component.html',
  styleUrls: ['./empty-sidebar.component.scss']
})
export class EmptySidebarComponent implements OnInit {
  @Input() openedSidebar: string;

  currentIdentifier: string;
  componentNames = ComponentNames;

  sidebarNames = SidebarNames;

  constructor(
    private store: Store
  ) { }

  ngOnInit() {
    this.store.select(state => {
      const projectState: Project = state.project.project;
      if (projectState) { this.currentIdentifier = projectState?.identifier; }
    });
  }

}
