import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { SetProducts } from 'src/app/store/actions/settings.actions';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  get() {
    const Url = `${environment.clientApiUrl}/products`;

    return this.httpClient.get(Url).subscribe( (resp: Array<any>) => {
      this.store.dispatch(new SetProducts(resp));
    });
  }
}
