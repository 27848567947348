<ng-template #instructions>
  <div class="form-inline installation__selection" [class.full-width]="isInstallInstructions" *ngIf="chosenTab.key != 'shopify' && chosenTab.key != 'bigcommerce'">
    <div class="form-group">
      <label [class.control-label]="!isInstallInstructions">Choose how setup Attribution:</label>&nbsp;
      <div class="dropdown" ngbDropdown>
        <button class="btn btn-default dropdown-toggle padding-6-12" ngbDropdownToggle aria-expanded="false" id="integrationOptions">
          {{ chosenTab.label }}
        </button>
        <div class="dropdown-menu" aria-labelledby="integrationOptions" ngbDropdownMenu>
          <button *ngFor="let integrationType of noHiddenTypes" (click)="selectInstallationType(integrationType.key)" ngbDropdownItem>{{ integrationType.label }}</button>
        </div>
      </div>
    </div>
  </div>
  <div [ngSwitch]="chosenTab.key" class="installation__selection">
    <div *ngSwitchCase="'manual'">
      <ng-template #manualTabset>
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>1. Place Tracking Code</a>
            <ng-template ngbNavContent>
              <p>Place this tracking code on every page of your site, anywhere on the page:</p>
                <pre class="well">&lt;script type=&quot;text/javascript&quot;&gt;<br>{{ prepareSnippet(project?.options?.features?.snippet_v3 ? snippets.main.min.v3 : snippets.main.min.v2) }}<br>&lt;/script&gt;</pre>
                <p>This will load the script and track all pageviews.</p>
                <p>If you want to learn more, please visit the <a href="https://docs.attributionapp.com/docs/a-simple-attribution-install" target="_blank">documentation portal</a>.</p>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>2. Identify Users</a>
            <ng-template ngbNavContent>
              <p>You will use <strong>Attribution.identify</strong> to link a users actions and pageviews to a recognizable name or email address. You'll want to call Attribution.identify whenever a user signs up or logs in.</p>
                <p> We recommend using the user ID field from your database and passing the email address in as a trait, in case a user changes their email address later on. You can pass us as many customer traits as you'd like to save. Here is an example of an identify call:</p>
                <pre class="well">  {{'Attribution.identify(&quot;00812938&quot;, {
          name: &quot;Bob Slydell&quot;,
          email: &quot;bob@initech.co&quot;
        });'}}</pre>
                <p><strong>createdAt</strong> special trait. If you want to distinguish between new and existing customers in Attribution you <strong>must</strong> add the <em>createdAt</em> date only once when a new user registers. createdAt is expected to be date time (JavaScript date or ISO 8601 time format).</p>
                <p *ngIf="project?.company_based_attribution || dataStatus?.company"><strong>company</strong> special trait. If you want to track customer company you <strong>must</strong> add the <em>company</em> object with <em>id, name</em> properties.</p>
                <pre class="well">  Attribution.identify("00812938", {{'{'}}
          name: "Bob Slydell",
          email: "bob&#64;initech.co",
          createdAt: new Date(){{ project?.company_based_attribution || dataStatus?.company ? ',' : '' }} // Note: Only do this once!!! {{ project?.company_based_attribution || dataStatus?.company ?
          '
          company: {
            id: "001145",
            name: "Mariot"
          }' : '' }}
        &#125;);</pre>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>3. Place Conversion Code</a>
            <ng-template ngbNavContent>
              <p>You will use <strong>Attribution.track</strong> to record events. You should use this method to record each step of your conversion funnel. These events should describe the action taken in a way that would be recognizable to anyone in your company.</p>
                <p>Create a conversion event to drop on a thank you page:</p>
                <div class="form-inline">
                  <div class="input-group input-group-lg">
                    <div class="input-group-text">Event Name:</div>
                    <input type="text" #eventName class="form-control" (keyup)="setExampleEventName(eventName)" value="{{exampleEventName}}">
                  </div>
                </div>
                <pre class="well spacer">
        &lt;script type=&quot;text/javascript&quot;&gt;
          Attribution.track(&quot;{{ exampleEventName }}&quot;);
        &lt;/script&gt; </pre>
                <p>Attribution recognizes the <strong>revenue</strong> property on the <strong>track</strong> event and stores it in a special way. Here's an example of an event with the <strong>revenue</strong> property:</p>
                <pre class="well">
        Attribution.track(&quot;Completed Purchase&quot;, {{"{"}}
          revenue: &quot;79.99&quot;
        &#125;);</pre>
                <p>When an event happens over the phone or in a back-end billing system you will want to send them using our server side API. <strong>Track</strong> has the same behavior on the server as it has from the client. The only difference is that you must specify the <strong>user_id</strong> when <strong>track</strong> is called from the server. Use the ID field from your database, the same you would in an <strong>identify</strong> call.
                <pre class="well">
        curl https://track.attributionapp.com/track \
          -u {{ isInstallInstructions ? installProjectIdentifier : project?.identifier }}: \
          -d '{{'{'}}"user_id": "user_1234", "event": "Credit Card Charged", "properties": {{'{'}}"revenue": 14.99&#125; &#125;'</pre>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </ng-template>
      <ng-container *ngTemplateOutlet="manualTabset"></ng-container>
    </div>
    <div *ngSwitchCase="'segment'">
      <p>To integrate Segment, simply copy your Attribution Project ID and paste your project key into the Attribution window on the Segment Integrations page.</p>
      <p>Your Attribution Project ID is <strong>{{ isInstallInstructions ? installProjectIdentifier : project?.identifier }}</strong></p>
      <p>More info on <a href="https://segment.com/docs/integrations/attribution/" target="blank">Segment</a>.</p>
    </div>
    <div *ngSwitchCase="'shopify'">
      <div class="alert alert-info">
        <i class="fa-solid fa-info"></i>
        Snippet is already installed into your shop by our Connector App, you're all good!
        <br>
        The data should start coming sortly, you can connect your <a href="{{integrationAdsLink}}"><u>Ad Integrations</u></a> meanwhile.
      </div>
    </div>
    <div *ngSwitchCase="'bigcommerce'">
      <div class="alert alert-info">
        <i class="fa-solid fa-info"></i>
        Snippet is already installed into your shop by our Connector App, you're all good!
        <br>
        The data should start coming sortly, you can connect your <a href="{{integrationAdsLink}}"><u>Ad Integrations</u></a> meanwhile.
      </div>
    </div>
    <div *ngSwitchCase="'stripe'">
      <ul ngbNav #stripenav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>1. Place Tracking Code</a>
          <ng-template ngbNavContent>
            <p>Place this tracking code on every page of your site, anywhere on the page:</p>
            <pre class="well">&lt;script type=&quot;text/javascript&quot;&gt;<br>{{ prepareSnippet(project?.options?.features?.snippet_v3 ? snippets.main.min.v3 : snippets.main.min.v2) }}<br>&lt;/script&gt;</pre>
            <p>This will load the script and track all pageviews.</p>
            <p>If you want to learn more, please visit the <a href="https://docs.attributionapp.com/docs/a-simple-attribution-install" target="_blank">documentation portal</a>.</p>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>2. Identify Users</a>
          <ng-template ngbNavContent>
            <p>You will use <strong>Attribution.identify</strong> to link a users actions and pageviews to a recognizable name or email address. You'll want to call Attribution.identify whenever a user signs up or logs in.</p>
            <p> We recommend using the user ID field from your database and passing the email address in as a trait, in case a user changes their email address later on. You can pass us as many customer traits as you'd like to save. Here is an example of an identify call:</p>
            <pre class="well">  {{'Attribution.identify(&quot;00812938&quot;, {
      name: &quot;Bob Slydell&quot;,
      email: &quot;bob@initech.co&quot;
    });'}}</pre>
            <p><strong>createdAt</strong> special trait. If you want to distinguish between new and existing customers in Attribution you <strong>must</strong> add the <em>createdAt</em> date only once when a new user registers. createdAt is expected to be date time (JavaScript date or ISO 8601 time format).</p>
            <p *ngIf="project?.company_based_attribution || dataStatus?.company"><strong>company</strong> special trait. If you want to track customer company you <strong>must</strong> add the <em>company</em> object with <em>id, name</em> properties.</p>
            <pre class="well">  Attribution.identify("00812938", {{'{'}}
      name: "Bob Slydell",
      email: "bob&#64;initech.co",
      createdAt: new Date(){{ project?.company_based_attribution || dataStatus?.company ? ',' : '' }} // Note: Only do this once!!! {{ project?.company_based_attribution || dataStatus?.company ?
      '
      company: {
        id: "001145",
        name: "Mariot"
      }' : '' }}
    &#125;);</pre>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink>3. Place Conversion Code</a>
          <ng-template ngbNavContent>
            <div *ngIf="isStripeOauth; else noStripeAuth">
              <div class="alert alert-success">
                All done! You have already <a class="alert-link" [routerLink]="[componentNames.settings, settingsSections.integrations, 'show', 'stripe']">connected Stripe</a>.
              </div>
            </div>
            <ng-template #noStripeAuth>
              <p>Stripe integration allows you to track revenue events automatically when they happen in Stripe so you don't need to fire events manually. <a [routerLink]="['/', isInstallInstructions ? installProjectIdentifier : project?.identifier, componentNames.settings, settingsSections.integrations]" [queryParams]="{type: 'conversions'}">Connect Stripe now</a>.</p>
            </ng-template>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="stripenav"></div>
    </div>
    <div *ngSwitchCase="'segment'">
      <ng-container *ngTemplateOutlet="manualTabset"></ng-container>
    </div>
  </div>
</ng-template>


<div *ngIf="isInstallInstructions; else notStandalone" class="oos-wrapper">
  <div class="container">
    <div class="oos-title">
      <img src="{{ assetPath }}/assets/images/mark.svg" width="65" alt="Attribution" />
      <h1>Attribution</h1>
      <h4>Install Instructions</h4>
    </div>
    <div>
      <div class="oos-body modal-body d-flex justify-content-center align-items-center flex-column">
        <ng-container *ngTemplateOutlet="instructions"></ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #notStandalone>
  <div class="row min-full-height me-0 ms-0">
    <div class="col-md-4 min-full-height onboarding-left-section">
      <div class="left-wrapper">
        <app-onboarding-title
        [title]="'Send Instructions'"></app-onboarding-title>

        <form [formGroup]="sendForm" (ngSubmit)='submit()' class="onboarding-form">
          <div *ngIf="error" class="alert alert-danger pt-3" role="alert">
            {{ error }}
          </div>
          <div class='form-group'>
            <label>Send your teammate an invitation email</label>
            <input formControlName='email' type='email' class='form-control' placeholder='email@example.com'>
            <div *ngIf="sendForm.controls.email.invalid && sendForm.controls.email.touched" class="error-message">
              <p *ngIf="sendForm.controls.email.errors.required">Email address is required</p>
              <p *ngIf="sendForm.controls.email.errors.email">Please enter correct email address</p>
            </div>
          </div>

          <button type='submit' class="btn btn-submit" [class.disabled]="sendForm.invalid">
            <span *ngIf="!waitingForResponse; else waiting" class='caps'>Send Instructions</span>
            <ng-template #waiting>
              <i class="fa-solid fa-spinner fa-spin"></i>
            </ng-template>
          </button>
        </form>

        <hr>

        <app-onboarding-navigation>
        </app-onboarding-navigation>
      </div>
    </div>
    <div class="col-md-8 min-full-height onboarding-right-section">
      <div class="right-wrapper-default container">
        <div class="instructions-wrapper">
          <ng-container *ngTemplateOutlet="instructions"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
