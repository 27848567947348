import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { AttrModelMapping } from 'src/app/core/constants/attribution-models';

@Component({
  selector: 'app-cp-summary',
  templateUrl: './cp-summary.component.html',
  styleUrls: ['./cp-summary.component.scss']
})
export class CpSummaryComponent implements OnInit, OnChanges {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() summary;
  @Input() prevSummary;
  @Input() prevSummaryParams;
  @Input() className;
  @Input() channelName;
  @Input() isSpecific;
  @Input() isLoading;

  projectCurrency = '$';
  ROI;
  prevROI;
  ROI2;
  prevROI2;
  adSpend;
  prevAdSpend;
  totalRevenue;
  prevTotalRevenue;
  RPS;
  CPS;
  prevRPS;
  prevCPS;
  attrModelMapping = AttrModelMapping;

  constructor() { }

  ngOnInit() {
    this.project$.subscribe( x => this.projectCurrency = x.project?.currency_symbol ? x.project.currency_symbol : '$');
  }

  ngOnChanges() {
    if (this.summary) {
      this.RPS = this.summary.total_revenue / this.summary.sales_count;
      this.CPS = this.summary.advertising_spend / this.summary.sales_count;
      this.ROI = this.summary.return_on_ad_spend_rate;
      this.ROI2 = this.summary.return_on_ad_spend_total;
      this.adSpend = this.summary.advertising_spend;
      this.totalRevenue = this.summary.total_revenue;
    }
    if (this.prevSummary) {
      this.prevRPS = this.prevSummary.total_revenue / this.prevSummary.sales_count;
      this.prevCPS = this.prevSummary.advertising_spend / this.prevSummary.sales_count;
      this.prevROI = this.prevSummary.return_on_ad_spend_rate;
      this.prevROI2 = this.prevSummary.return_on_ad_spend_total;
      this.prevAdSpend = this.prevSummary.advertising_spend;
      this.prevTotalRevenue = this.prevSummary.total_revenue;
    }
  }
}
