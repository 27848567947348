import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { BaseService } from '../services/base/base.service';

@Pipe({
  name: 'formattedMoment',
  pure: false
})
export class FormattedMomentPipe implements PipeTransform, OnDestroy {
  private time;
  private interval;

  constructor(public baseService: BaseService) {}

  transform(value: any, format: string, fromFormat?: string, interval?: number): any {
    if (interval) {
      this.setTime(value, format, fromFormat);
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.setTime(value, format, fromFormat);
        }, interval);
      }
    } else this.setTime(value, format, fromFormat);

    return this.time;
  }

  ngOnDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  }

  setTime(value, format, fromFormat?) {
    if (value === null || typeof(value) === 'undefined') { return null; }
    let time = this.baseService.getDateWithTimeZone(value, format, fromFormat);
    if (time === 'Invalid date') { time = value; }
    this.time = time;
  }
}
