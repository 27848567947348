import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { find } from 'lodash-es';
import { Observable } from 'rxjs';
import { Snippets } from 'src/app/core/constants/snippets';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { Account } from 'src/app/core/models/account.model';
import { Project } from 'src/app/core/models/project.model';
import { OnboardingService } from 'src/app/core/services/onboarding/onboarding.service';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SettingsStateModel } from 'src/app/store/states/settings.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-instructions',
  templateUrl: './send-instructions.component.html',
  styleUrls: ['./send-instructions.component.scss']
})
export class SendInstructionsComponent implements OnInit {
  isInstallInstructions = false;
  installProjectIdentifier;
  sendForm;
  error;
  waitingForResponse;

  assetPath = environment.assetPath;

  sectionName = 'send-instructions';
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.settings) settings$: Observable<SettingsStateModel>;
  @Select(state => state.account) account$: Observable<Account>;

  @Input() selectFromStatusEvent: Event;

  selectedTab;
  project: Project;
  dataStatus;

  componentNames = ComponentNames;
  settingsSections = SettingsSections;

  attributionUrl   = environment.scriptHost + environment.attributionJsPath;
  attributionUrlV2 = environment.scriptHost + 'v2/' + environment.attributionJsPath;
  attributionUrlV3 = environment.scriptHost + 'v3/' + environment.attributionJsPath;
  snippets         = Snippets;

  installationTypes = [
    { key: 'manual',      label: 'Manual',      tab: 0 },
    { key: 'segment',     label: 'Segment',     tab: 1 },
    { key: 'shopify',     label: 'Shopify',     tab: 2, hidden: true },
    { key: 'stripe',      label: 'Stripe',      tab: 3 },
    { key: 'bigcommerce', label: 'BigCommerce', tab: 4, hidden: true },
    { key: 'heap',        label: 'HEAP',        tab: 5, hidden: true }
  ];
  chosenTab: any = { key: 'manual',    label: 'Manual',    tab: 0 };
  exampleEventName = 'Signed Up';
  integrationAdsLink = `${environment.oldDashboardUrl}/#!/settings/integrations/ads`;
  stripeConnectLink = `${environment.oldDashboardUrl}/#!/settings/integrations/show/stripe`

  constructor(private onboardingService: OnboardingService, private fb: UntypedFormBuilder, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe( x => {
      if (x.project) {
        this.isInstallInstructions = true;
        this.installProjectIdentifier = x.project;
      } else {
        this.sendForm = this.fb.group({
          email: ['', [Validators.required, Validators.email]],
        });
        this.project$.subscribe(state => this.project = state.project);
        this.settings$.subscribe(resp => this.dataStatus = resp.usage);
        this.account$.subscribe(resp => {
          const defaultType = (resp.signup_integration || resp.partner || 'manual');
          this.selectInstallationType(defaultType);
        });
      }
    });
  }

  selectInstallationType(typeName) {
    this.chosenTab = find(this.installationTypes, x => x.key == typeName);
  }

  get noHiddenTypes() {
    return this.installationTypes.filter(x => x.hidden !== true);
  }

  get tabKeyArray() {
    return this.installationTypes.map(x => x.key);
  }

  prepareSnippet(code) {
    if (environment.trackingHost != 'https://track.attributionapp.com') {
      code = code.replace(/Attribution.load\((.+)\);/, `Attribution.load($1, { apiHost: '${environment.trackingHost}' });`);
    }
    return code.
      replace('[project.identifier]', this.isInstallInstructions ? this.installProjectIdentifier : this.project?.identifier).
      replace('[attributionUrlV3]', this.attributionUrlV3).
      replace('[attributionUrlV2]', this.attributionUrlV2).
      replace('[attributionUrl]', this.attributionUrl);
  }

  submit() {
    this.error = null;
    if (this.sendForm.valid) {
      this.waitingForResponse = true;
      this.onboardingService.send_instructions(this.sendForm.get('email').value).subscribe( x => {
        this.sendForm.reset();
        this.waitingForResponse = false;
      }, error => {
        this.error = error.error.error;
        this.waitingForResponse = false;
      });
    }
  }

  setExampleEventName(input) {
    this.exampleEventName = input.value;
  }

  get isStripeOauth() {
    return this.project?.oauths?.filter(x => x.type == 'stripe').length > 0;
  }

}
