import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extendedAdUrlsSumOfClicks',
})
export class ExtendedAdUrlsSumOfClicksPipe implements PipeTransform {
  transform(group: any[]): any {
    return group.reduce((acc, curr) => {
        return acc + curr.clicks;
        }
    , 0);
  }
}