<ng-template #settingsNavbar>
  <ul class="navbar-nav me-auto nav-column-left">
    @if (!(project | isShopifySegment:account)) {
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier]">
            <i class="fa-solid fa-chevron-left me-2 green-nav-item"></i>
            Back to Dashboard
          </a>
        </div>
      </li>
    }
  </ul>
</ng-template>

<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand" [routerLink]="['', currentIdentifier]">
    <img src="{{ assetPath }}/assets/images/mark.svg" />
  </a>
  <button class="navbar-toggler navbar-toggle" type="button" data-toggle="collapse"
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <i class="fa-solid fa-bars font-1-25-em"></i>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    @if (project | isShopifySegment:account) {
      <div class="white em-1_2 ms-5 bold">
        CDP Connector by Attribution
      </div>
    }
    <ul *ngIf="currentComponent != componentNames.settings && currentComponent != componentNames.account; else settingsNavbar" class="navbar-nav me-auto nav-column-left">
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier]">
            Dashboard
          </a>
        </div>
      </li>
      <li *ngIf="isOverviewActive || !isProd" class="nav-item" routerLinkActive="active">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier, componentNames.overview]">
            <i class="fa-solid fa-lock red me-1" *ngIf="!isOverviewActive"></i>
            Overview
          </a>
          <span class="badge text-bg-warning">beta</span>
        </div>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier, componentNames.dashboardBuilder]">
            Dashboard Builder
          </a>
        </div>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier, componentNames.cohort]">
            Cohort
          </a>
        </div>
      </li>
      <li *ngIf="isFirstTimePurchasersActive || !isProd" class="nav-item" routerLinkActive="active">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier, componentNames.firstTimePurchasers ]">
            <i class="fa-solid fa-lock red me-1" *ngIf="!isFirstTimePurchasersActive"></i>
            First Time Purchasers
          </a>
        </div>
      </li>
      <li *ngIf="isCpActive || !isProd" class="nav-item" routerLinkActive="active">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier, componentNames.channelPerformance]">
            <i class="fa-solid fa-lock red me-1" *ngIf="!isCpActive"></i>
            Channel Performance
          </a>
        </div>
      </li>
      <li *ngIf="isPathsActive || !isProd" class="nav-item">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier, componentNames.paths]">
            <i class="fa-solid fa-lock red me-1" *ngIf="!isPathsActive"></i>
            Paths
          </a>
        </div>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier]" [queryParams]="{sidebar: sidebarNames.users}">
            Users
          </a>
        </div>
      </li>
      <li *ngIf="isCompaniesActive || !isProd" class="nav-item" routerLinkActive="active">
        <div class='nav_underline'>
          <a class="nav-link" [routerLink]="['', currentIdentifier]" [queryParams]="{sidebar: sidebarNames.companies}">
            <i class="fa-solid fa-lock red me-1" *ngIf="!isCompaniesActive"></i>
            Companies
          </a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav navbar-right" *ngIf='userIsAuthenticated && !isDemo'>
      @if (project | isShopifySegment:account) {
        <li  class="ms-1 me-1">
          <div class="d-flex align-items-center h-100">
            <a href="{{ shopifyUpgradeUrl }}" class="nav-item green-nav-item">
              <div class="btn btn-new btn-new__light-blue align-middle">
                <i class="fa-regular fa-circle-up fa-fw me-2"></i> Upgrade
              </div>
            </a>
          </div>
        </li>
        <li  class="ms-2 me-1">
          <div class="d-flex align-items-center h-100">
            <a href="https://docs.attributionapp.com" target="_blank" class="nav-item green-nav-item">
              <div class="btn btn-new btn-new__light-green align-middle">
                  <i class="fa-solid fa-circle-question fa-fw me-2"></i> Docs
              </div>
            </a>
          </div>
        </li>
        <li class="ms-2 me-1">
          <div class="d-flex align-items-center h-100">
            <a href="mailto:help@attributionapp.com" class="nav-item green-nav-item">
              <div class="btn btn-new btn-new__light-green align-middle">
                <i class="fa-solid fa-comments fa-fw me-2"></i> Support
              </div>
            </a>
          </div>
        </li>
      } @else {
        <li class="m-l-17-5" *ngIf="project?.owner?.trial_days">
          <div class="centered">
            <a [routerLink]="['/', componentNames.account, settingsSections.billing]" class="btn-cta" [ngSwitch]="project.owner.trial_days">
              Trial Expires
              <span *ngSwitchCase="0">Today</span>
              <span *ngSwitchCase="1">Today</span>
              <span *ngSwitchDefault>in {{ project.owner.trial_days }} Days</span>
            </a>
          </div>
        </li>
        <li class="m-l-17-5">
          <div class="centered">
            <a href="https://docs.attributionapp.com" target="_blank" class="nav-item green-nav-item">
              <span class="visible-xs">Docs</span>
              <span class="hidden-xs">
                <i class="fa-solid fa-circle-question font-1-35-em"></i>
              </span>
            </a>
          </div>
        </li>
        <li>
          <div class="centered">
            <a href="mailto:help@attributionapp.com" class="nav-item green-nav-item">
              <span class="visible-xs">Support</span>
              <span class="hidden-xs">
                <i class="fa-solid fa-comments font-1-35-em"></i>
              </span>
            </a>
          </div>
        </li>
        <li>
          <div class="centered">
            <a class="nav-item green-nav-item" [routerLink]="['/', currentIdentifier, componentNames.settings, isProjectNew ? settingsSections.instructions : settingsSections.overview]">
              <span class="visible-xs">Settings</span>
              <span class="hidden-xs">
                <i class="fa-solid fa-gear font-1-35-em"></i>
              </span>
            </a>
          </div>
        </li>
      }
      <li class="ms-1 me-1 visible-xs">
        <div class="centered">
          <a (click)="logout()">
            Logout
          </a>
        </div>
      </li>
      <div class="{{ (project | isShopifySegment:account) ? 'ms-5 me-4' : 'ms-1' }} nav-item dropdown hidden-xs" ngbDropdown #projectSelectDrop="ngbDropdown" (openChange)="setJSheap()">
        <div class="centered">
          <div class="nav-link dropdown-toggle green-nav-item logout-link d-flex align-items-center" id="projectDropdownToggle" role="button" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle ngbTooltip="Project Menu">
            <div class="pt-1">
              <img class="header__gravatar" src="https://secure.gravatar.com/avatar/{{ account?.email | md5 }}?d=retro">
            </div>
            <span [class.ms-2]="project | isShopifySegment:account">
              @if (projectIsSwitching) {
                <i class="fa-solid fa-spinner fa-spin fa-lg"></i>
              } @else {
                <i class="fa-solid fa-chevron-down fa-lg"></i>
              }
            </span>
          </div>
        </div>
        <div class="dropdown-menu project-dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
          <div class="dropdown-account">
            <i class="fa-solid fa-rotate reload-all-data" (click)="refreshAllData()" ngbTooltip="Refresh All Data"></i>
            <img class="dropdown__gravatar" src="https://secure.gravatar.com/avatar/{{ account?.email | md5 }}?d=retro">
            <p class="account_summary">
              <span class="mute">Project:</span> {{ project?.name }}
            </p>
            <p class="account_summary ng-binding">
              <span class="mute">Account:</span> {{ account?.email }}
            </p>
            <div class="d-flex justify-content-center flex-column align-items-center mt-4 hide-prod">
              <div *ngIf="branch" class="white bold mt-1">
                <i class="fab fa-github me-1"></i> {{ branch }}
              </div>
              <div *ngIf="githubLink">
                <a class="white" href="{{githubLink}}" target="_blank">
                  {{ shortVersion }}
                </a>
              </div>
              <div class="light small mt-1" *ngIf="jsHeapSize">
                JsHeapSize: {{ jsHeapSize / (1024 * 1024) | number }} Mb
              </div>
            </div>
          </div>
          <div class='dropdown-list' [class.disabled]="projectIsSwitching">
            <p class="dropdown-heading" *ngIf="projectsList?.length > 1">Switch to... <i *ngIf="projectIsSwitching" class="fa-solid fa-spinner fa-spin me-3 green"></i></p>
            <a *ngFor='let project of projectsList' (click)="switchProject(project?.id, project?.identifier);projectSelectDrop.close();"
              class='dropdown-menu-item'>
              <div *ngIf='project.owner.id != account.id' class='medium-dark'>
                <small>{{ project.owner?.email }}</small>
              </div>
              <div>
                {{ project.name }}
              </div>
            </a>
          </div>
          <div class='dropdown-buttons' [class.justify-content-end]="project | isShopifySegment:account">
            @if (!(project | isShopifySegment:account)) {
              <span (click)='openNewProjectModal(); projectSelectDrop.close();' class='btn btn-default caps'>
                <strong>New Project </strong>
                <i class="fa-solid fa-plus blue"></i>
              </span>
            }
            <span (click)="logout(); projectSelectDrop.close();" class="btn btn-default caps">
              <strong>Log Out </strong>
              <i class="fa-solid fa-right-from-bracket blue"></i>
            </span>
          </div>
        </div>
      </div>
    </ul>
  </div>
</nav>
