<table>
    <tr *ngFor="let param of sort">
        <td class="green bold text-start text-nowrap">{{VIEW_DICT[param]}}{{changedParams && (param |includedInArray:changedParams) ? '*' : ''}}</td>
        <td class="medium-dark text-start text-capitalize">
            <div [ngSwitch]="param">
                <span *ngSwitchCase="'attribution_model'">
                    {{ attributionModels[paramsToList[param]] || '-'}}
                </span>
                <span *ngSwitchCase="'traffic_option'">
                    <span *ngIf="( 'cutoff' | includedInText:paramsToList[param]); else normalTrafficOption">
                        {{ ( 'direct' | includedInText:paramsToList[param]) ? 'Direct' : 'All'}} Traffic excluded after "{{paramsToList['cutoff_event']}}" event
                    </span>
                    <ng-template #normalTrafficOption>
                        {{ ( 'direct' | includedInText:paramsToList[param]) ? 'Exclude all Direct Traffic' : 'Include all Traffic'}}
                    </ng-template>
                </span>
                <span *ngSwitchCase="'attribution_type'">
                    {{ attrTypeDict[paramsToList[param]] }}
                    {{ paramsToList['attribution_type'] === 'backward' && paramsToList['lookback_window']? ' ('+paramsToList['lookback_window']+' days)' : null }}
                </span>
                <span *ngSwitchCase="'daterange_preset'">
                    {{ isPreset ? (paramsToList[param] || '-') : 'start: '+chosenDates.start+', end: '+chosenDates.end }}
                </span>
                <span *ngSwitchDefault>
                    {{paramsToList[param] || '-'}}
                </span>
            </div>
        </td>
    </tr>
</table>