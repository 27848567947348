import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { APP_BASE_HREF } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountUpModule } from 'ngx-countup';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { CoreModule } from './core/core.module';

import { ModuleExtension } from './module-extension';

import {
  AccountState,
  BaseState,
  OnboardingState,
  ProjectState,
  FilterTreeState,
  SelectedState,
  ReportState,
  CohortState,
  FirstTimePurchaseState,
  ChannelPerformanceState,
  OverviewState,
  OnlySimpleState,
  PathsState,
  PropertiesState,
  SettingsState,
  SidebarState,
  TimePeriodsState,
  WorkerState} from './store/index.state';
import { SharedModule } from './modules/shared/shared.module';
import { ConversionEventsState } from './store/states/conversion-events.state';
import { logoutPlugin } from './store/logout-plugin';
import { ShellComponent } from './modules/shell/shell.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WidgetWrapperModule } from './modules/shared/widgetWrapper.module';
import { SettingsModule } from './modules/settings/with-identifier/settings.module';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './core/error-handler/rollbar-error-handler';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      BaseState,
      AccountState,
      OnboardingState,
      ProjectState,
      FilterTreeState,
      SelectedState,
      ConversionEventsState,
      ReportState,
      CohortState,
      FirstTimePurchaseState,
      TimePeriodsState,
      ChannelPerformanceState,
      OverviewState,
      OnlySimpleState,
      PathsState,
      PropertiesState,
      SidebarState,
      SettingsState,
      WorkerState],
    {
      compatibility: {
        strictContentSecurityPolicy: true
      }
    }),
    NgxsStoragePluginModule.forRoot({
      key: [
        'base',
        'account',
        'onboarding',
        'project',
        'filterTree.filterTree',
        'selected',
        'conversionEvents',
        'properties',
        'channelPerformance.prevSummary',
        'channelPerformance.prevSummaryParams',
        'channelPerformance.summaryParams',
        'settings',
      ],
      storage: StorageOption.SessionStorage
    }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    CoreModule,
    DashboardModule,
    HttpClientModule,
    SharedModule,
    WidgetWrapperModule,
    SettingsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CountUpModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: false // (environment.production === true)
    }),
    ...ModuleExtension.segmentModule
  ],
  declarations: [
    AppComponent,
    ShellComponent,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: NGXS_PLUGINS, useValue: logoutPlugin, multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
