import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

import { Project } from '../../models/project.model';
import { ProjectService } from '../project/project.service';
import { ProjectStateModel } from 'src/app/store/states/project.state';

export interface CheckUrlResponse {
  isSuccess: boolean;
  urlEmpty: boolean;
  projectIdentifier: string;
  changed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UrlCheckerService {
  projectState: Project;
  nextIdentifier: String = null;
  accountState;

  constructor(
    private store: Store,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute) {}

  checkUrl(projectIdentifier, redirectUrl) {
    const projectState = this.store.selectSnapshot<ProjectStateModel>(state => state.project);
    this.nextIdentifier = projectState.nextIdentifier;
    this.projectState = projectState.project;
    this.accountState = this.store.selectSnapshot<Project[]>( state => state.account);
    if (this.projectState !== null && this.accountState.projects.length > 0) {
      const QueryParams = this.route.snapshot.queryParams;
      // this._checkQueryParams(QueryParams);
      this._checkUrl(projectIdentifier).subscribe( resp => {
        if (resp == null || !resp.isSuccess) {
          const accountEmail = this.store.selectSnapshot( state => state.account)?.email;
          if (typeof(projectIdentifier) !== 'undefined') {
            if (projectIdentifier != 'goto') {
              setTimeout(() =>
                this.toastr.error(
                  `You have been redirected to the last active project (${this.projectState.identifier})`,
                  `Could not find such project in your account (${accountEmail})`,
                  {disableTimeOut: true}
                )
              );
            }
          }
          const path = [resp.projectIdentifier];
          if (redirectUrl) {
            const split = redirectUrl.split('/');
            split.forEach( x => path.push(x));
          }
          this.router.navigate(
            path,
            {queryParams: QueryParams});
          //throw Error(`You have been redirected to the last active project (${this.projectState.identifier}), Could not find such project in your account (${accountEmail}), searched project identifier was ${projectIdentifier}`);
        }
      });
    }
  }

  private _checkUrl(projectIdentifierParam?): Observable<CheckUrlResponse> {
    const CurrentProjectIdentifier = this.projectState?.identifier;
    const ProjectWithTheIdentifier = this.accountState?.projects?.find(x => x?.identifier === projectIdentifierParam);
    let checkUrlResponse: CheckUrlResponse = {
      isSuccess: true,
      urlEmpty: false,
      projectIdentifier: ProjectWithTheIdentifier ? projectIdentifierParam : CurrentProjectIdentifier,
      changed: false
    };
    if (!projectIdentifierParam) {
      checkUrlResponse.isSuccess = false;
      checkUrlResponse.urlEmpty = true;
      return of(checkUrlResponse);
    } else if (projectIdentifierParam && !ProjectWithTheIdentifier) {
      checkUrlResponse.isSuccess = false;
      return of(checkUrlResponse);
    }
    else if (projectIdentifierParam && !(projectIdentifierParam == CurrentProjectIdentifier || (this.nextIdentifier != null && projectIdentifierParam == this.nextIdentifier))) {
      this.projectService.selectProject(ProjectWithTheIdentifier.id, ProjectWithTheIdentifier.identifier).subscribe(
        () => {
          checkUrlResponse = {
            isSuccess: true,
            urlEmpty: false,
            projectIdentifier: projectIdentifierParam,
            changed: true
          };
          return of(checkUrlResponse);
        },
        (error: Error) => {
          return throwError(error);
        }
      );
    }
    checkUrlResponse.changed = true;
    return of(checkUrlResponse);
  }

}
