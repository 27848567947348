<div class="cohort__content">
  <div class='row no-gutters'>
    <div class='col-auto'>
      <table class="table table-condensed table-striped">
        <thead>
          <tr>
            <th class="cohort__content--left">DATE</th>
            <th class="cohort__content--left">
              <ng-template #tipContent>Break Even Period <br/> (cells outlined in orange)</ng-template>
              <abbr placement="top" [ngbTooltip]="tipContent">BE</abbr>
            </th>
            <th>Spend</th>
          </tr>
        </thead>
        <tbody>
          @for (c of cohorts; let cohortIndex = $index; track c.period.start) {
            <tr>
              <td *ngIf="period == 'Q'" class="cohort__content--left">{{ c.period.start | date: 'MMM d, y'}}-{{ c.period.end | date: 'MMM d, y'}}</td>
              <td *ngIf="period == 'M' || period == 'W'" class="cohort__content--left">{{ c.period.start | date: 'M/dd'}}-{{ c.period.end | date: 'M/dd'}}</td>
              <td *ngIf="period == 'D'" class="cohort__content--left">{{ c.period.start | date: 'MMM d, y'}}</td>
              <td class="bold cohort__content--left">{{ c.revenue.break_even? period+c.revenue.break_even : '-'}}</td>
              <td [ngSwitch]="denomination" class="bold" [class.profit--negative]="c.amount != 0">
                <span *ngIf="c.amount">-</span>
                <span *ngSwitchCase='"Cents"'>{{ c.amount | projectCurrency: 'dollars' }}</span>
                <span *ngSwitchCase='"Dollars"'>{{ c.amount | projectCurrency: 'dollars' : '1.0' : true }}</span>
                <span *ngSwitchCase='"Thousands"'>{{ (c.amount / 1000) | projectCurrency: 'dollars' : '1.1' : true }}k</span>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class='col of-x-scroll'>
      <table id='cohort-values-table' class="table table-condensed table-striped" [class.heatmap]="heatmap">
        <thead *ngIf="align == 'Left'">
          <tr>
            @for (day of cohortLength; let i = $index; track day) {
              <th>
                <div class='in-header' [style.min-width.px]="cellMaxWidth">
                  {{ period+(i+1) }}
                </div>
              </th>
            }
          </tr>
        </thead>
        <thead *ngIf="align == 'Right'">
          @for (period of periodsForRightAlign; track period) {
            <th>
              <div class='in-header' [style.min-width.px]="cellMaxWidth">
                {{ period }}
              </div>
            </th>
          }
        </thead>
        <tbody>
          @for (c of cohorts; let cohortIndex = $index; track cohortIndex) {
            <tr>
              <td *ngIf="align == 'Right' && cohortIndex > 0" [attr.colspan]="cohortIndex"></td>
              @for (itemRevenue of (revenue == 'Absolute'? c.revenue.absolute : c.revenue.relative); let colIndex = $index; track colIndex) {
                <td [ngClass]="metric | cohortCalculateClassName:itemRevenue:c.amount:heatmap" [class.break-even]="colIndex == c.revenue.break_even-1">
                  <span *ngIf="metric == 'Revenue' && revenue == 'Relative' && itemRevenue > 0">+</span>
                  <span *ngIf="metric === 'Percentage'; else noPercent">
                    <span>{{ c.amount > 0 ? (itemRevenue | percentage: c.amount) : '-' }}</span>
                  </span>
                  <ng-template #noPercent>
                    <span *ngIf="revenue === 'Relative' && itemRevenue === 0; else noRelativeZero">-</span>
                    <ng-template #noRelativeZero [ngSwitch]="denomination">
                      <span *ngSwitchCase='"Cents"'>{{ (metric | cohortCalculateAmount:itemRevenue:c.amount:denomination) | projectCurrency: 'dollars' }}</span>
                      <span *ngSwitchCase='"Dollars"'>{{ (metric | cohortCalculateAmount:itemRevenue:c.amount:denomination) | projectCurrency: 'dollars' : '1.0' }}</span>
                      <span *ngSwitchCase='"Thousands"'>{{ (metric | cohortCalculateAmount:itemRevenue:c.amount:denomination) | projectCurrency: 'dollars' : '1.1' }}k</span>
                    </ng-template>
                  </ng-template>
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
