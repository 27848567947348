export class SetTimePeriods {
  static readonly type = '[timePeriods] SetFirstTImePurchase';
  constructor(public payload: any) {}
}

export class PatchTimePeriods {
  static readonly type = '[timePeriods] PatchTimePeriods';
  constructor(public payload: any) {}
}
  
export class SetTPInProgress {
  static readonly type = '[timePeriods] SetFTPInProgress';
  constructor(public payload?: {isFalse: boolean}) {}
}

export class SetTPReportToNull {
  static readonly type = '[timePeriods] SetFirstTimePurchaseReportToNull';
}

export class ClearTPState {
  static readonly type = '[timePeriods] Clear';
}
  