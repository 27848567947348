import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ReportService } from '../report/report.service';
import { CohortService } from '../cohort/cohort.service';
import { FirstTimePurchaseService } from '../first-time-purchase/first-time-purchase.service';
import { ChannelPerformanceService } from '../channel-performance/channel-performance.service';
import { ReportStateModel } from 'src/app/store/states/report.state';
import { CohortStateModel } from 'src/app/store/states/cohort.state';
import { FirstTimePurchaseStateModel } from 'src/app/store/states/first-time-purchase.state';
import { ChannelPerformanceStateModel } from 'src/app/store/states/channel-performance.state';
import { Project } from '../../models/project.model';
import { OverviewStateModel } from 'src/app/store/states/overview.state';
import { OverviewService } from '../overview/overview.service';
import { reportNames } from 'src/app/core/constants/overview';
import { PathsStateModel } from 'src/app/store/states/paths.state';
import { PathsService } from '../paths/paths.service';
import { TimePeriodService } from '../time-period/time-period.service';
import { TimePeriodsStateModel } from 'src/app/store/states/time-periods.state';
import { ComponentNames } from '../../enums/component-names';

@Injectable({
  providedIn: 'root'
})
export class DataRefresherService {

  constructor(
    private store: Store,
    private reportService: ReportService,
    private cohortService: CohortService,
    private firstTimePurchaseService: FirstTimePurchaseService,
    private timePeriodService: TimePeriodService,
    private cpService: ChannelPerformanceService,
    private overviewService: OverviewService,
    private pathService: PathsService
  ) { }

  // TODO pass component instance instead of path
  shouldComponentDataRefresh(componentPath, additionalOptions?) {
    const ProjectState = this.store.selectSnapshot<Project>( state => state.project.project );
    const ReportState = this.store.selectSnapshot<ReportStateModel>( state => state.report );
    const CohortState = this.store.selectSnapshot<CohortStateModel>( state => state.cohort );
    const FirstTimePurchaseState = this.store.selectSnapshot<FirstTimePurchaseStateModel>( state => state.firstTimePurchase );
    const TimePeriodsState = this.store.selectSnapshot<TimePeriodsStateModel>(state => state.timePeriods);
    const OverviewState = this.store.selectSnapshot<OverviewStateModel>( state => state.overview );
    const CPState = this.store.selectSnapshot<ChannelPerformanceStateModel>( state => state.channelPerformance );
    const pathState = this.store.selectSnapshot<PathsStateModel>( state => state.paths );

    if (ProjectState == null) {
      return true;
    }

    let answer = true;
    switch (componentPath) {
      case '':
        if ((ReportState.report == null && ReportState.inProgress !== true && !ReportState.meta?.is_empty) || additionalOptions?.needNewReport) {
          this.reportService.getReports();
          answer = true;
        } else {
          answer = false;
        }
        break;
      case ComponentNames.cohort:
        if (additionalOptions && additionalOptions.urlParamsDifferent) {
          this.cohortService.getCohort();
          answer = true;
        } else if (CohortState.report == null && CohortState.inProgress !== true) {
          this.cohortService.getCohort();
          answer = true;
        } else {
          answer = false;
        }
        break;
      case ComponentNames.firstTimePurchasers:
        if (additionalOptions && additionalOptions.urlParamsDifferent) {
          this.firstTimePurchaseService.getFirstTimePurchase();
          answer = true;
        } else if (FirstTimePurchaseState.report == null && FirstTimePurchaseState.inProgress !== true) {
          this.firstTimePurchaseService.getFirstTimePurchase();
          answer = true;
        } else {
          answer = false;
        }
        break;
      case ComponentNames.timeframe:
        if (additionalOptions && additionalOptions.urlParamsDifferent) {
          this.timePeriodService.getTimePeriod();
          answer = true;
        } else if (TimePeriodsState.report == null && TimePeriodsState.inProgress !== true) {
          this.timePeriodService.getTimePeriod();
          answer = true;
        } else {
          answer = false;
        }
        break;
      case ComponentNames.channelPerformance:
        if (additionalOptions && additionalOptions.urlParamsDifferent) {
          this.cpService.get(additionalOptions);
          this.cpService.getSummary(additionalOptions);
          this.cpService.getComparison();
          answer = true;
        } else if ((CPState.report == null)) {
          this.cpService.get(additionalOptions);
          this.cpService.getSummary(additionalOptions);
          if( CPState.comparison == null) {
            this.cpService.getComparison();
          }
          answer = true;
        } else {
          answer = false;
        }
        break;
      case ComponentNames.paths:
        if (additionalOptions && additionalOptions.urlParamsDifferent) {
          this.pathService.load(additionalOptions);
          answer = true;
        } else if (pathState.report == null && pathState.inProgress !== true && pathState.additionInProgress !== true) {
          this.pathService.load(additionalOptions);
          answer = true;
        } else {
          answer = false;
        }
        break;
      case ComponentNames.overview:
        let presetChanged = false;
        const widgets = reportNames.reduce((widgets, element) => {
            if (!OverviewState.report || !OverviewState.report[element]) {
              widgets.push(element);
            } else if (OverviewState.report[element].meta.needRefresh) {
              widgets.push(element);
              presetChanged = true;
            }
            return widgets;
          }, []);

        if (widgets.length > 0 && OverviewState.inProgress !== true) {
          if (presetChanged) {
            // give some time for reports to build on backend
            setTimeout(() => this.overviewService.getReports(widgets, true), 2500);
          } else {
            this.overviewService.getReports(widgets, true);
          }
          answer = true;
        } else {
          answer = false;
        }
        break;
    }

    return answer;
  }
}
