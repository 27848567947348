<ng-container *ngTemplateOutlet="isFTP ? ftpRow : dashboardRow"></ng-container>

<ng-template #ftpRow>
  <div class="vertical-header colored-hover" [ngbTooltip]="totalToolTip"  placement="right">
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="red fa-solid fa-money-bill-alt"></i>
        <span class="medium-dark small extra-small ms-2 bold">Spend</span>
      </div>
      <div class="color-spend" [class.medium]="(item | ftpTotalFor:report:true).a == 0">
        {{ (item | ftpTotalFor:report:true).a | projectCurrency }}
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="green fa-regular fa-money-bill-1"></i>
        <span class="medium-dark small extra-small ms-2 bold">First Revenue</span>
      </div>
      <div class="color-first-revenue" [class.medium]="(item | ftpTotalFor:report:true).fr == 0">{{ (item | ftpTotalFor:report:true).fr | projectCurrency }}</div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="blue fa-regular fa-money-bill-1"></i>
        <span class="medium-dark small extra-small ms-2 bold">Repeated Revenue</span>
      </div>
      <div class="color-repeated-revenue" [class.medium]="(item | ftpTotalFor:report:true).r == 0">{{ (item | ftpTotalFor:report:true).r | projectCurrency }}</div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="fa-regular fa-circle-dot orange"></i>
        <span class="medium-dark small extra-small ms-2 bold">First Time Conversions</span>
      </div>
      <div class="color-first-conversions">
        <span [class.medium]="(item | ftpTotalFor:report:true).fc == 0">{{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? ((item | ftpTotalFor:report:true).fc | integer) : ((item | ftpTotalFor:report:true).fc | defaultNumber) }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="fa-solid fa-repeat yellow"></i>
        <span class="medium-dark small extra-small ms-2 bold">Repeated Conversions</span>
      </div>
      <div class="color-repeated-conversions">
        <span [class.medium]="(item | ftpTotalFor:report:true).c == 0">{{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? ((item | ftpTotalFor:report:true).c | integer) : ((item | ftpTotalFor:report:true).c | defaultNumber) }}</span>
      </div>
    </div>
  </div>
  @for (period of periodArray; let i = $index; track i) {
    <div class="flex-item first-time-purchasers">
      <ng-template #tableTooltip>
        <div class="d-flex justify-content-between">
          <div class="pe-2">
            <strong>Filter:</strong>
          </div>
          <div>
            {{ item?.n || rowName }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="pe-2">
            <strong>Period:</strong>
          </div>
          <div>
            {{ period.label }}
          </div>
        </div>
        <div *ngIf="tableRevenue == 'Relative'">
          <div>
            <strong>Absolute Values</strong>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>Spend:</strong>
            </div>
            <div>
              <span class="red">{{ (item | ftpReportForItem:report:period.id:tableRevenue:true:true)?.a | projectCurrency }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>First Revenue:</strong>
            </div>
            <div>
              <span class="green">{{ (item | ftpReportForItem:report:period.id:tableRevenue:true:true)?.fr | projectCurrency }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>Repeated Revenue:</strong>
            </div>
            <div>
              <span class="blue">{{ (item | ftpReportForItem:report:period.id:tableRevenue:true:true)?.r | projectCurrency }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>First Time Conversions:</strong>
            </div>
            <div>
              <span class="orange">{{ (item | ftpReportForItem:report:period.id:tableRevenue:true:true)?.fc | integer }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>Repeated Conversions:</strong>
            </div>
            <div>
              <span class="yellow">{{  (item | ftpReportForItem:report:period.id:tableRevenue:true:true)?.c | integer }}</span>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="d-flex justify-content-end">
        <div *ngIf="tableRevenue == 'Absolute' || i > 0; else firstColumnForRelativeForFTP" class="timeframe-val colored-hover" [ngbTooltip]="tableTooltip"  placement="top-right">
          <div class="tfv-row">
            <div class="color-spend">
              <span [class.medium]="(item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.a == 0">
                <span *ngIf="tableRevenue == 'Relative' && (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.a > 0">+</span>{{ (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.a | projectCurrency}}</span>
            </div>
          </div>
          <div class="tfv-row">
            <div class="color-first-revenue">
              <span [class.medium]="(item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.fr == 0">
                <span *ngIf="tableRevenue == 'Relative' && (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.fr > 0">+</span>{{ (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.fr | projectCurrency }}</span>
            </div>
          </div>
          <div class="tfv-row">
            <div class="color-repeated-revenue">
              <span [class.medium]="(item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.r == 0">
                <span *ngIf="tableRevenue == 'Relative' && (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.r > 0">+</span>{{ (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.r | projectCurrency }}</span>
            </div>
          </div>
          <div class="tfv-row">
            <div class="color-first-conversions">
              <span [class.medium]="(item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.fc == 0">
                <span *ngIf="tableRevenue == 'Relative' && (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.fc > 0">+</span>{{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? ((item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.fc | integer) : ((item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.fc | defaultNumber) }}</span>
            </div>
          </div>
          <div class="tfv-row">
            <div class="color-repeated-conversions">
              <span [class.medium]="(item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c == 0">
                <span *ngIf="tableRevenue == 'Relative' && (item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c > 0">+</span>{{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? ((item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c | integer) : ((item | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c | defaultNumber) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</ng-template>

<ng-template #firstColumnForRelativeForFTP>
  <ng-template #firstColumnForRelativeTooltipForFTP>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Filter:</strong>
      </div>
      <div>
        {{ item?.n || rowName }}
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Period:</strong>
      </div>
      <div>
        {{ periodArray[0].label }}
      </div>
    </div>
    <div>
      <strong>Absolute Values</strong>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Spend:</strong>
      </div>
      <div>
        <span class="red">{{ (item | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.a | projectCurrency }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>First Revenue:</strong>
      </div>
      <div>
        <span class="green">{{ (item | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.fr | projectCurrency }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Repeated Revenue:</strong>
      </div>
      <div>
        <span class="blue">{{ (item | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.r | projectCurrency }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>First Time Conversions:</strong>
      </div>
      <div>
        <span class="orange">{{ (item | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.fc | integer }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Repeated Conversions:</strong>
      </div>
      <div>
        <span class="yellow">{{ (item | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.c | integer }}</span>
      </div>
    </div>
  </ng-template>
  <div class="timeframe-val" [ngbTooltip]="firstColumnForRelativeTooltipForFTP"  placement="top-right">
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
  </div>
</ng-template>

<ng-template #dashboardRow>
  <div class="vertical-header colored-hover" [ngbTooltip]="totalToolTip"  placement="right">
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="red fa-solid fa-money-bill-alt"></i>
        <span class="medium-dark small extra-small ms-2 bold">Amounts</span>
      </div>
      <div class="color-spend" [class.medium]="(item | ftpTotalFor:report:true).a == 0" [class.clickable]="!rowName" (click)="openSidebar(sidebarNames.amounts, null, item ? {id: item.id, type: item.t, special: item.s} : {special: special, rowName: rowName})">
        {{ (item | ftpTotalFor:report:true).a | projectCurrency }}
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="fa-regular fa-money-bill-1 orange"></i>
        <span class="medium-dark small extra-small ms-2 bold">Visits</span>
      </div>
      <div class="color-first-conversions clickable" [class.medium]="(item | ftpTotalFor:report:true)?.e == 0 || !(item | ftpTotalFor:report:true)?.e" (click)="openSidebar(sidebarNames.visits, null, item ? {scope: 'visits', id: item.id, type: item.t, special: item.s} : {scope: 'visits', special: special, rowName: rowName})">
        {{ ((item | ftpTotalFor:report:true).e  || 0) | integer }}
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="fa-regular fa-money-bill-1 blue"></i>
        <span class="medium-dark small extra-small ms-2 bold">Conversions</span>
      </div>
      <div class="color-repeated-conversions clickable" [class.medium]="(item | ftpTotalFor:report:true).c == 0"  (click)="openSidebar(sidebarNames.visits, null, item ? {scope: 'conversions', id: item.id, type: item.t, special: item.s} : {scope: 'conversions', special: special, rowName: rowName})">
        {{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? ((item | ftpTotalFor:report:true).c | integer) : ((item | ftpTotalFor:report:true).c | defaultNumber) }}
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-2">
        <i class="fa-regular fa-circle-dot green"></i>
        <span class="medium-dark small extra-small ms-2 bold">Revenue</span>
      </div>
      <div class="color-first-revenue clickable" (click)="openSidebar(sidebarNames.revenues, null, item ? {id: item.id, type: item.t, special: item.s} : {})">
        <span [class.medium]="(item | ftpTotalFor:report:true).r == 0">{{ (item | ftpTotalFor:report:true).r | projectCurrency }}</span>
      </div>
    </div>
  </div>
  @for (period of periodArray; let i = $index; track i) {
    <div class="flex-item first-time-purchasers">
      <ng-template #tableTooltip>
        <div class="d-flex justify-content-between">
          <div class="pe-2">
            <strong>Filter:</strong>
          </div>
          <div>
            {{ item?.n || rowName }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="pe-2">
            <strong>Period:</strong>
          </div>
          <div>
            {{ period.label }}
          </div>
        </div>
        <div *ngIf="tableRevenue == 'Relative'">
          <div>
            <strong>Absolute Values</strong>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>Amounts:</strong>
            </div>
            <div>
              <span class="red">{{ ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:true:true)?.a | projectCurrency }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>Visits:</strong>
            </div>
            <div>
              <span class="green">{{ (((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:true:true)?.e || 0) | integer }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>Conversions:</strong>
            </div>
            <div>
              <span class="blue">{{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? (((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:true:true)?.c | integer) : (((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:true:true)?.c | defaultNumber) }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-2">
              <strong>Revenue:</strong>
            </div>
            <div>
              <span class="orange">{{ ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:true:true)?.r | projectCurrency }}</span>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="d-flex justify-content-end">
        <div *ngIf="tableRevenue == 'Absolute' || i > 0; else firstColumnForRelativeForDashboard" class="timeframe-val colored-hover" [ngbTooltip]="tableTooltip"  placement="top-right">
          <div class="tfv-row">
            <div class="color-spend" [class.clickable]="!rowName" (click)="openSidebar(sidebarNames.amounts, period, item ? {id: item.id, type: item.t, special: item.s} : {special: special, rowName: rowName})">
              <span [class.medium]="((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.a == 0">
                <span *ngIf="tableRevenue == 'Relative' && ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.a > 0">+</span>{{ ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.a | projectCurrency}}</span>
            </div>
          </div>
          <div class="tfv-row">
            <div class="color-first-conversions clickable" (click)="openSidebar(sidebarNames.visits, period, item ? {scope: 'visits', id: item.id, type: item.t, special: item.s} : {scope: 'visits', special: special, rowName: rowName})">
              <span [class.medium]="((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.e == 0 || !((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.e">
                <span *ngIf="tableRevenue == 'Relative' && ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.e > 0">+</span>{{ (((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.e || 0) | integer }}</span>
            </div>
          </div>
          <div class="tfv-row">
            <div class="color-repeated-conversions clickable" (click)="openSidebar(sidebarNames.visits, period, item ? {scope: 'conversions', id: item.id, type: item.t, special: item.s} : {scope: 'conversions', special: special, rowName: rowName})">
              <span [class.medium]="((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c == 0">
                <span *ngIf="tableRevenue == 'Relative' && ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c > 0">+</span>{{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? (((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c | integer) : (((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.c | defaultNumber) }}</span>
            </div>
          </div>
          <div class="tfv-row">
            <div class="color-first-revenue clickable" (click)="openSidebar(sidebarNames.revenues, period, item ? {id: item.id, type: item.t, special: item.s} : {})">
              <span [class.medium]="((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.r == 0">
                <span *ngIf="tableRevenue == 'Relative' && ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.r > 0">+</span>{{ ((item || rowName) | ftpReportForItem:report:period.id:tableRevenue:false:true)?.r | projectCurrency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</ng-template>

<ng-template #totalToolTip>
  <div class="d-flex justify-content-between">
    <div class="pe-2">
      <strong>Filter:</strong>
    </div>
    <div>
      {{ item?.n || rowName }}
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="pe-2">
      <strong>Period:</strong>
    </div>
    <div>
      Total
    </div>
  </div>
</ng-template>

<ng-template #firstColumnForRelativeForDashboard>
  <ng-template #firstColumnForRelativeTooltipForDashboard>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Filter:</strong>
      </div>
      <div>
        {{ item?.n || rowName }}
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Period:</strong>
      </div>
      <div>
        {{ periodArray[0].label }}
      </div>
    </div>
    <div>
      <strong>Absolute Values</strong>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Spend:</strong>
      </div>
      <div>
        <span class="red">{{ ((item || rowName) | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.a | projectCurrency }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Visits:</strong>
      </div>
      <div>
        <span class="orange">{{ ((item || rowName) | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.fr | projectCurrency }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Conversions:</strong>
      </div>
      <div>
        <span class="blue">{{ selectedAttributionModel?.name == 'first_touch' || selectedAttributionModel?.name == 'last_touch' ? (((item || rowName) | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.c | integer) : (((item || rowName) | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.c | defaultNumber) }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="pe-2">
        <strong>Revenue:</strong>
      </div>
      <div>
        <span class="green">{{ ((item || rowName) | ftpReportForItem:report:periodArray[0].id:tableRevenue:true:true)?.r | projectCurrency }}</span>
      </div>
    </div>
  </ng-template>
  <div class="timeframe-val" [ngbTooltip]="firstColumnForRelativeTooltipForDashboard"  placement="top-right">
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
    <div class="tfv-row">
      -
    </div>
  </div>
</ng-template>