import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'liveDebugTakePath',
})
export class LiveDebugTakePathPipe implements PipeTransform {
    transform(url): any {
        const parsed = new URL(url);
        return parsed.pathname;
    }
}