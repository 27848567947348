import { Pipe, PipeTransform } from '@angular/core';
import { compact } from 'lodash-es';

@Pipe({
  name: 'commaLocation'
})
export class CommaLocationPipe implements PipeTransform {
  transform(value: any ): any {
    let places = '';
    if (value !== undefined) {
      const placesArray = compact([value.city, value.region_name, value.country_name]);
      places = placesArray.join(', ');
    }
    return places;
  }
}
