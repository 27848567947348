<div class="col--checkbox">
  <i class="fa-solid fa-check-circle fa-lg" [class.green]="path.selected" [class.medium]="!path.selected" (click)="toggleSelected(path.index)"></i>
</div>
<div class="col--count bold">
  <small class="percentage">{{ path.count | percentage : summary?.conversions }}</small>
  {{ path.count }}
  <span class="muted times ps-1 pe-1">&times;</span>
</div>

<div class="col--path">
  <div>
    @for (filter of touchpointArray; track filter) {
      <div class="filter">
        <app-filter-badge
          [filter]="filter"
          [isChannels]="labels === 'channels'"></app-filter-badge>
      </div>
    }
    <span class="small muted ps-1 pe-1"> &rsaquo;&rsaquo; </span><strong class="small event-name">{{ conversionEventName }}</strong>
  </div>
</div>

<div class="col--ttp">
  {{ path.avg_time_to_conv | daysHoursFromSeconds }}
</div>

<div class="col--tr">
  {{ path.total_revenue ? (path.total_revenue | projectCurrency) : '--' }}
</div>

<div class="col--ar">
  {{ path.avg_revenue ? (path.avg_revenue | projectCurrency) : '--' }}
</div>
