import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, delay, first, iif, map, mergeMap, Observable, of } from 'rxjs';
import { SetRefreshEta, SetRefreshGeneratedAt, SetRefreshOvertime, SetRefreshState } from 'src/app/store/actions/base.actions';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base/base.service';
import { FilterTreeWorkerService } from '../filter-tree-worker/filter-tree-worker.service';
import { ProjectStateModel } from 'src/app/store/states/project.state';

@Injectable({
  providedIn: 'root'
})
export class DataDownloaderService {
  @Select(state => state.worker.filterWorkerInProgress) filterWorkerInProgress$: Observable<boolean>;
  @Select(state => state.worker.groupWorkerInProgress) groupWorkerInProgress$: Observable<boolean>;
  eta: number = null;
  countDown;

  passFilterAndGroupIdsFor = ['dashboard', 'time_period', 'first_time_purchasers'];
  ongoingReportIds = {
    dashboard: [],
    time_period: [],
    first_time_purchasers: [],
    cohort: [],
    channel_performance: [],
    channel_performance_summary: [],
    report_comparison: [],
    path_break_down: [],
  };
  
  constructor(
      private httpClient: HttpClient,
      private toastr: ToastrService,
      private baseService: BaseService,
      private store: Store,
      private FTWS: FilterTreeWorkerService) {}

  cancelReportGeneration(reportTypes?: string[]) {
    this.clearCountDown();
    let types = Object.keys(this.ongoingReportIds);
    if (reportTypes && reportTypes.length > 0) {
      types = reportTypes;
    }
    let ReportIDs = [];
    const URL = environment.reportingApiUrl + '/v2/report/requests';
    types.forEach(reportType => {
      if (this.ongoingReportIds[reportType].length > 0) {
        ReportIDs = ReportIDs.concat(this.ongoingReportIds[reportType]);
      }
    });
    if (ReportIDs.length > 0) {
      return this.httpClient.delete(URL, {body: {report_ids: ReportIDs}}).subscribe(() => {
        types.forEach(reportType => {
          this.ongoingReportIds[reportType] = [];
        });
      });
    }
  }

  requestReport(params?, isSecondTry?) {
    const Url = environment.reportingApiUrl + '/v2/report/request';
    this.clearCountDown();

    return this.httpClient.get(Url, { params: params, observe: 'response' }).pipe(
      mergeMap( (resp: HttpResponse<any>) => {
        if (resp.body.meta.status === 'ready' &&
          (resp.body.report != null || resp.body.meta.is_empty)) {
          return of(resp.body);
        } else {
          if (resp.body.meta.eta_avg) {
              this.startEtaCountdown(resp.body.meta.eta_avg, {report_id: resp.body.meta.report_id, status: resp.body.meta.status, refresh: resp.body.meta.refresh});
          }
          this.ongoingReportIds[params.report].push(resp.body.meta.report_id);
          return this.checkStatusAndDownload({meta: resp.body.meta, reportType: params.report, shouldPassFilterAndGroupIds: this.passFilterAndGroupIdsFor.includes(params.report), with_other: true, requestParams: params, isSecondTry});
        }
      }),
      map((resp:any) => {
        this.clearCountDown();
        if (resp.meta?.refresh?.generated_at) {
          this.store.dispatch([
            new SetRefreshState('done'),
            new SetRefreshGeneratedAt(resp.meta.refresh.generated_at)
          ]);
        }
        this.ongoingReportIds[params.report] = this.ongoingReportIds[params.report].filter(id => id !== resp.meta.report_id);
        return resp;
      }),
    );
  }

  getFilterAndGroupIds(reportType?) {
    const projectState = this.store.selectSnapshot<ProjectStateModel>(state => state.project);
    const hiddenFilters = projectState.currentViewParams.hidden_filters;
    const hiddenGroups = projectState.currentViewParams.hidden_filter_groups;
    if (this.FTWS.filters && this.FTWS.groups) {
      return of({
        'filter_ids': Object.values(this.FTWS.filters).filter((f: any) => this.passFilterAndGroupIdsFor.includes(reportType) ? (f.visible && !hiddenFilters.includes(f.id)) : f.visible).map((f: any) => f.id),
        'filter_group_ids': Object.values(this.FTWS.groups).filter((g: any) => this.passFilterAndGroupIdsFor.includes(reportType) ? (g.visible && !hiddenGroups.includes(g.id)) : g.visible).map((g: any) => g.id),
      });
    } else {
      return combineLatest([this.filterWorkerInProgress$, this.groupWorkerInProgress$]).pipe(map(([filterWorkerInProgress, groupWorkerInProgress]) => {
        if (!filterWorkerInProgress && !groupWorkerInProgress) {
          return {
            'filter_ids': this.FTWS.filters ? Object.values(this.FTWS.filters).filter((f: any) => this.passFilterAndGroupIdsFor.includes(reportType) ? (f.visible && !hiddenFilters.includes(f.id)) : f.visible).map((f: any) => f.id) : [],
            'filter_group_ids': this.FTWS.groups ? Object.values(this.FTWS.groups).filter((g: any) => this.passFilterAndGroupIdsFor.includes(reportType) ? (g.visible && !hiddenGroups.includes(g.id)) : g.visible).map((g: any) => g.id) : [],
          };
        }
      }));
    } 
  }

  checkStatusAndDownload({meta, reportType, filterAndGroupIds, shouldPassFilterAndGroupIds, with_other = false, requestParams, isSecondTry = false, shouldDelay = false}: {meta: any; reportType: string; filterAndGroupIds?: any; shouldPassFilterAndGroupIds?: any, with_other?: boolean, requestParams?: any, isSecondTry?: boolean, shouldDelay?: boolean}): Observable<any> {
    const respFunc = (innerResp: any) => {
      if (innerResp.meta.eta_avg) {
        this.startEtaCountdown(innerResp.meta.eta_avg, {report_id: meta.report_id, status: innerResp.meta.status, refresh: innerResp.meta.refresh});
      }
      return iif(
        () => innerResp.report || innerResp.meta.is_empty || innerResp.meta.request_new_report || innerResp.meta.status === 'failed',
        (innerResp.meta.status === 'failed' && !isSecondTry) ? this.requestReport(requestParams, true) : of(innerResp),
        this.checkStatusAndDownload({meta: innerResp.meta, reportType, shouldPassFilterAndGroupIds, with_other, requestParams, isSecondTry, shouldDelay: true})
      );
    };


    if (meta.refresh?.started_at || meta.refresh?.eta || meta.refresh?.overtime) {
      this.store.dispatch(new SetRefreshState('refreshing'));
      if (meta.refresh.eta) {
        this.store.dispatch(new SetRefreshEta(meta.refresh.eta));
      }
      if (meta.refresh.overtime) {
        this.store.dispatch(new SetRefreshOvertime(meta.refresh.overtime));
      }
    } else {
      this.store.dispatch(new SetRefreshState('done'));
    }
    
    const Url = environment.reportingApiUrl + `/v2/report/download/${meta.report_id}`;
    if (['started', 'ready', 'scheduled', 'not_found'].includes(meta.status) || (meta.status === 'failed' && !isSecondTry)) {
      if (filterAndGroupIds) {
        return this.httpClient.post(Url, {...filterAndGroupIds, with_other}).pipe(
          delay(shouldDelay ? 2000 : 0),
          mergeMap(respFunc)
        );
      } else if (shouldPassFilterAndGroupIds) {
        return this.getFilterAndGroupIds(reportType).pipe(first( x => typeof(x) != 'undefined')).pipe(mergeMap(x => {
          return this.httpClient.post(Url, {...x, with_other}).pipe(
            delay(shouldDelay ? 2000 : 0),
            mergeMap(respFunc)
          );
        }));
      } else {
        return this.httpClient.post(Url, {with_other}).pipe(
          delay(shouldDelay ? 2000 : 0),
          mergeMap(respFunc)
        );
      }
    } else {
      this.clearCountDown();
      setTimeout(() => {
        let message = `Failed to fetch Reports Data from the server. Please <a href="${this.baseService.currentPath}">reload</a>.`;

        if (meta.reason) {
          message += `<br>${meta.reason}`;
        }

        this.toastr.error(
          message,
          `Status: ${meta.status}`,
          {disableTimeOut: true, enableHtml: true}
        );
      });
      throw { message: 'Failed to load dashboard report', metadata: {report_id: meta.report_id, status: meta.status, refresh: meta.refresh}};
    }
  }

  startEtaCountdown(eta, metaData) {
    metaData.avg_eta = eta;
    const startCountdown = (x, metaData) => {
      const time = x > 10 ? x*3*1000 : 30000;
      this.countDown = setTimeout(() =>
        {
          this.toastr.warning(
            `Report is taking too long to load, if it doesn't complete in a minute please <a href="mailto:help@attributionapp.com">contact us</a>.`,
            `status: timeout`,
            {disableTimeOut: true, enableHtml: true}
          );
          throw {level: 'warning', message: 'Long running report generation detected', metadata: {...metaData, countDown: time}};
        },
        time
      );
    }
    if (this.eta == null) {
      this.eta = eta;
      startCountdown(eta, metaData);
    }
  }

  downloadCSV(reportId) {
    const Url = environment.reportingApiUrl + `/v2/report/download/${reportId}.csv?with_other=true`;
    return this.httpClient.get(Url, {responseType: 'arraybuffer'});
  }
    
  clearCountDown() {
    if (this.countDown) clearTimeout(this.countDown);
    this.countDown = null;
    this.eta = null;
  }
}
