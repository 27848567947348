import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  get(token?) {
    let Url = `${environment.clientApiUrl}/unsubscribe`;

    if (token) Url += `?unsubscribe_token=${token}`;

    return this.httpClient.get(Url);
  }

  update(params, token?) {
    let Url = `${environment.clientApiUrl}/unsubscribe`;

    if (token) Url += `?unsubscribe_token=${token}`;

    return this.httpClient.post(Url, params);
  }
}