import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DataRefresherService } from 'src/app/core/services/data-refresher/data-refresher.service';
import { OverviewState, OverviewStateModel } from 'src/app/store/states/overview.state';
import { OverviewSettings } from 'src/app/store/interfaces/selected';
import { SetCurrentComponent, SetOverviewSettings, ResetOverviewSettings } from 'src/app/store/actions/selected.actions';
import { SetOverviewReportsToNull } from 'src/app/store/actions/overview.actions';
import { Project } from 'src/app/core/models/project.model';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { ActivatedRoute } from '@angular/router';
import { ComponentNames } from 'src/app/core/enums/component-names';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy {
  @Select(OverviewState) overview$: Observable<OverviewStateModel>;
  @Select(state => state.selected.overviewSettings) overviewSettings$: Observable<OverviewSettings>;
  @Select(state => state.overview.inProgress ) isLoading$: Observable<boolean>;
  @Select(state => state.project.project ) project$: Observable<Project>;

  componentPath = ComponentNames.overview;
  overview;
  earliestDate;
  selectedOverviewSettings: OverviewSettings;
  isLoading = true;
  public expandChannelsNum = 5;

  isLoadingSub;
  overviewSub;
  overviewSettingsSub;
  projectSub;
  paramSub;

  constructor(
    private store: Store,
    private dataRefresher: DataRefresherService,
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService) {}

  ngOnInit() {
    this.store.dispatch(new SetCurrentComponent({ currentComponent: this.componentPath}));

    this.isLoadingSub = this.isLoading$.subscribe( isLoading => this.isLoading = isLoading);

    this.overviewSettingsSub = this.overviewSettings$.subscribe( resp => {
      if (resp) {
        this.selectedOverviewSettings = resp;
      } else {
        // reset settings with defaults
        this.store.dispatch(new ResetOverviewSettings());
      }
    });

    this.overviewSub =this.overview$.subscribe( resp => {
      this.dataRefresher.shouldComponentDataRefresh(this.componentPath);
      this.overview = resp;
    });

    this.projectSub = this.project$.subscribe( state => {
      this.earliestDate = state.earliest_date;
    });

    this.paramSub = this.route.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, this.componentPath);
    });
  }

  ngOnDestroy(): void {
    this.isLoadingSub?.unsubscribe();
    this.overviewSub?.unsubscribe();
    this.overviewSettingsSub?.unsubscribe();
    this.projectSub?.unsubscribe();
    this.paramSub?.unsubscribe();
  }

  presetChange(preset, widget) {
    this.selectedOverviewSettings[widget] = { period: preset};
    this.store.dispatch(new SetOverviewSettings({[widget]: { period: preset } }));
    this.store.dispatch(new SetOverviewReportsToNull(widget));
  }

}
