<div *ngIf="channelBuilderQueryParams" class="debug-in-builder">
  <a class="small caps" [routerLink]="['', project.identifier, componentNames.dashboardBuilder]" [queryParams]="channelBuilderQueryParams" (mouseover)="debugHovered = true" (mouseout)="debugHovered = false"><i class="fa-solid fa-code"></i> {{debugHovered ? 'Debug in Channel Builder' : null}}</a>
</div>
<app-empty-sidebar
  *ngIf="!inProgress && isEmpty"
  [openedSidebar]="sidebar?.openedSidebar"></app-empty-sidebar>
<div *ngIf="!isEmpty || inProgress">
  <app-sidebar-title
    [title]="'Revenue'"
    [name]="filter?.n"
    [type]="selected.filterType"
    [filterId]="filter?.id"
    [filterVfilters]="filter?.vf"
    [inProgress]="inProgress"
    [isEmpty]="isEmpty"
    [isVirtual]="isVirtual"
    [filterTree]="filterTree"
    [periodStart]="sidebar?.options?.periodStart"
    [periodEnd]="sidebar?.options?.periodEnd"></app-sidebar-title>
  <div class="revenue-chart-wrapper">
    <app-sidebar-bar-chart
      *ngIf="filterTimeline.length > 0"
      [chartName]="'revenue-chart'"
      [data]="filterTimeline"
      [tooltipName]="'revenue-chart'"
      (mouseOver)="tooltipRef.onMouseOver('revenue', $event)"
      (mouseOut)="tooltipRef.onMouseOut($event)" ></app-sidebar-bar-chart>
  </div>
  <app-event-breakdown
    *ngIf="revenueEventsBreakdown.length > 0"
    [events]="revenueEventsBreakdown"
    [title]="'Attributed Revenue Breakdown'"
  ></app-event-breakdown>
  <div
    class="table-infinite"
    infiniteScroll
    [infiniteScrollContainer]="selector"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()">
    <table *ngIf="revenues.length" class="table sidebar-table">
      <thead>
        <tr>
          <th>Visitor</th>
          <th class="text-right">
            {{ revenues[0]?.attributed_revenue ? 'Attributed' : 'Total' }}
            <span class="small muted" *ngIf="revenues[0]?.attributed_revenue"><br/>Total</span>
          </th>
          <th class="text-right">Payment Time</th>
        </tr>
      </thead>
      <tbody>
        <tr class="sidebar-tr-link" *ngFor="let revenue of revenues" (click)="revenue.company_id ? null : showVisitor(revenue.id)" [class.cursor-default]="revenue.company_id">
          <td>
            <a class="blue" (click)="revenue.company_id ? showVisitor(revenue.id) : null">{{ revenue | nameForUser }}</a><br />
            <a *ngIf="revenue.company_id" class="strong blue" (click)="showCompany(revenue.company_id)">{{ revenue.company_name || revenue.company_identifier }}</a>
            <span class="d-block small muted">{{ revenue.ip && IPLocations[revenue.ip] ? (IPLocations[revenue.ip] | commaLocation) : ' ' }}</span>
          </td>
          <td class="text-right">
            <span *ngIf="revenue.attributed_revenue">
              {{ revenue.attributed_revenue | projectCurrency }}<br/>
              <span class="small muted">{{ revenue.amount | projectCurrency }}</span>
            </span>
            <span *ngIf="!revenue.attributed_revenue">{{ revenue.amount | projectCurrency }}</span>
          </td>
          <td class="text-right no-w-wrap">
            {{ revenue.event_time | formattedMoment:'DD' }}<br/>
            <span class="small muted" ngbTooltip="{{ revenue.event_time }}" container="body" placement="left">{{ revenue.event_time | formattedMoment:'t' }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="inProgress" class="loader-sm">
      <app-loader
        *ngIf="inProgress"
        [position]="'relative'"
        [color]="'green'"
        [height]="'30px'"
        [zIndex]="1"></app-loader>
    </div>
  </div>
</div>
<div class='text-center p-20px pt-0' *ngIf="!inProgress && !isMore">
  <i class="fa-solid fa-stop-circle fa-2x light"></i>
</div>
