import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SetCurrentComponent } from 'src/app/store/actions/selected.actions';
import { Select, Store } from '@ngxs/store';
import { AdditionalOptions, PathsService } from 'src/app/core/services/paths/paths.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { saveAs } from 'file-saver';
import { Filter } from 'src/app/core/models/filter.model';
import { Integration } from 'src/app/core/constants/integration';
import { PathsState, PathsStateModel } from 'src/app/store/states/paths.state';
import { SidebarState, SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { clone } from 'lodash-es';
import { getPermutation } from 'src/app/core/helpers/other';
import { customPallete, specificColors } from 'src/app/core/constants/colors';
import { ChosenDates } from 'src/app/store/interfaces/selected';
import { DataRefresherService } from 'src/app/core/services/data-refresher/data-refresher.service';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project } from 'src/app/core/models/project.model';

@Component({
  selector: 'app-paths',
  templateUrl: './paths.component.html',
  styleUrls: ['./paths.component.scss']
})
export class PathsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(SidebarState) sidebar$: Observable<SidebarStateModel>;
  @Select(PathsState) pathsState$: Observable<PathsStateModel>;
  @Select(state => state.filterTree) filterTree$: Observable<any>;
  @Select(state => state.worker.filterWorkerInProgress) filterWorkerInProgress$: Observable<boolean>;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  componentPath = ComponentNames.paths;
  paths = [];
  originalPaths = [];
  count = 0;
  summary = {};
  labels: string;
  tab = 'common';
  isAggregated = true;
  isCombineRecurring: boolean;
  conversionEventName: string;
  filterType: string = 'none';
  filterOrGroup: {
    id: number,
    name: string,
    type: string,
  } | null;
  chosenDates: ChosenDates;
  infiniteScrollSelector = '#app_pane';
  inProgress = false;
  additionalInProgress = false;
  filters = [];
  pathsStateSubscription: Subscription;
  isSidebarOpen: boolean;
  allTouchPoints = [];
  sankeyLinks = [];
  sankeyMaxLength = 0;
  nodes: any = [
    {
      name: '',
      integration: '',
      id: null,
      color: this.idToColor(null, '', ''),
    }
  ];

  project: Project;

  chartHeight = 400;

  sortHover: string;
  sortColumn: string;
  sortDirection: string;
  wasScrolled = false;

  sidebarSub;
  paramSub;

  constructor(
    private store: Store,
    private dataRefresher: DataRefresherService,
    private pathsService: PathsService,
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService,
    private filterTreeWorkerService: FilterTreeWorkerService) {
  }

  ngOnInit() {
    this.store.dispatch(new SetCurrentComponent({currentComponent: this.componentPath}));

    this.sidebarSub = this.sidebar$.subscribe( state => {
      this.isSidebarOpen = state.openedSidebar !== null;
    });

    this.paramSub = this.route.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, this.componentPath);
    });
  }

  ngAfterViewInit(): void {
    const el = document.getElementById('sankey-chart-wrapper');
    if (el) {
      this.chartHeight = (window.innerHeight - 150 - 70 - 115) * 0.75;
    }

    this.pathsStateSubscription = combineLatest([this.project$, this.pathsState$, this.route.queryParams, this.filterTree$, this.filterWorkerInProgress$]).subscribe(([projectState, pathState, queryParams, filterTree, filterWorkerInProgress]) => {
      this.flow(projectState, pathState, queryParams, filterTree, filterWorkerInProgress);
    });
  }

  ngOnDestroy() {
    this.pathsStateSubscription?.unsubscribe();
    this.sidebarSub?.unsubscribe();
    this.paramSub?.unsubscribe();
  }

  flow(project, pathState, queryParams, filterTree, filterWorkerInProgress) {
    this.filterType = pathState.filterType;
    this.filterOrGroup = pathState.filterOrGroup;

    this.project = project.project;

    this.inProgress = pathState.inProgress || filterWorkerInProgress;
    if (this.inProgress) return;

    if (!this.wasScrolled) this.resetFetchedData();
    let urlParamsDifferent = false;

    if (filterTree.filterTree && this.filterTreeWorkerService.filters) {
      this.filters = Object.values(this.filterTreeWorkerService.filters);
    } else {
      this.filters = [];
    }

    if (project.currentViewParams?.conversion_event) this.conversionEventName = project.currentViewParams?.conversion_event;

    if (queryParams.tab) {
      if (this.tab != queryParams.tab) {
        this.sortColumn = null;
        this.sortDirection = null;
        this.sortHover = null;
        this.tab = queryParams.tab;
        urlParamsDifferent = true;
      }
      if (this.tab == 'common') this.isAggregated = true;
      else this.isAggregated = false;
    } else  {
      this.tab = 'common';
      this.isAggregated = true;
    }

    if (this.labels != pathState.labels) {
      if (typeof(this.labels) != 'undefined') urlParamsDifferent = true;
      this.labels = pathState.labels;
    }

    if (this.isCombineRecurring != pathState.isCombineRecurring) {
      if (typeof(this.isCombineRecurring) != 'undefined') urlParamsDifferent = true;
      this.isCombineRecurring = pathState.isCombineRecurring;
    }

    this.additionalInProgress = pathState.additionInProgress;
    const additionalParams: AdditionalOptions = {
      isAggregated: this.isAggregated,
      isCombineRecurring: this.isCombineRecurring,
      labels: this.labels,
      urlParamsDifferent: urlParamsDifferent,
    }
    const requested = this.dataRefresher.shouldComponentDataRefresh(this.componentPath, additionalParams);
    if (pathState.report) {
      if (!requested && !pathState.additionInProgress && !pathState.inProgress) {
        this.generateReport(pathState.report);
      }
    } else {
      this.paths = null;
      this.summary = null;
      this.sankeyLinks = null;
    }
  }

  generateReport(report) {
    if (report.count) this.count = report.count;
    this.summary = report.summary;
    this.paths = report.data;
    if (!this.isAggregated) {
      this.generateAllTouchPoints();
    } else {
      this.paths = this.paths.map((x, i) => {
        if (x.inxdex) return x;
        else return {
            ...x,
            avg_revenue: x.total_revenue ? x.total_revenue / x.count : null,
            avg_time_to_conv: x.total_time_to_conv ? x.total_time_to_conv / x.count : null,
            selected: i < 10 ? true : false,
            index: i,
            touchpoints: x.touchpoints.map(y => {
              return {
                ...y,
                color: this.idToColor(this.isChannels && y.channel_id != null ? y.channel_id : y.id, this.isChannels && y.channel_id != null ? y.channel_name : y.name, y.channel_name)
              }
            })
          };
      });
    }
    if (this.isAggregated) {
      this.generateDataForSankey();
    }
    this.originalPaths = clone(this.paths);
    this.inProgress = false;
    this.additionalInProgress = false;
    this.wasScrolled = false;
  }

  sort(sortColumn) {
    const nextDirection = () => {
      switch (this.sortDirection) {
        case null:
          this.sortDirection = 'Down';
          break;
        case 'Down':
          this.sortDirection = 'Up';
          break;
        case 'Up':
          this.sortDirection = null;
          break;
      }
    };

    const checkSort = (column) => {
      if (this.sortColumn !== column) {
        this.sortDirection = 'Down';
      } else {
        nextDirection();
      }
      if (this.sortDirection !== null) {
        this.sortColumn = column;
      } else {
        this.sortColumn = null;
      }
    };

    checkSort(sortColumn);
    if (this.sortDirection !== null) {
      this.paths = this.sortTable(this.originalPaths, this.sortDirection, this.sortColumn);
    } else {
      this.paths = clone(this.originalPaths);
    }
  }

  sortTable(array, direction, column) {
    let sorted;
    sorted = clone(array).sort( (a, b) => {
      let itemA = a[column];
      let itemB = b[column];
      if (column == 'time') {
        itemA = new Date(a[column]);
        itemB = new Date(b[column]);
      }
      if (direction === 'Down') {
        return itemB - itemA;
      } else {
        return itemA - itemB;
      }
    });
    return sorted;
  }

  generateDataForSankey() {
    const selected = this.paths.filter(x => x.selected);
    this.sankeyMaxLength = selected.reduce((max, x) => x.touchpoints.length > max ? x.touchpoints.length : max, 0);
    let sankey = [];
    let links = [];
    selected.forEach(x => {
      let sankeyNodes;
      if (x.touchpoints.length > 1) {
        const arrayPairs = getPermutation(x.touchpoints,2);
        sankeyNodes = arrayPairs.map((y,i) => {
          const sourceName = this.isChannels && y[0].channel_id != null ? y[0].channel_name : y[0].name;
          const targetName = this.isChannels && y[1].channel_id != null ? y[1].channel_name : y[1].name;
          const sourceId = this.isChannels && y[0].channel_id != null ? y[0].channel_id : y[0].id;
          const targetId = this.isChannels && y[1].channel_id != null ? y[1].channel_id : y[1].id;
          return {
            source: `${sourceName}_id:${sourceId}=_+_=${i}`,
            target: `${targetName}_id:${targetId}=_+_=${i+1}`,
            value: x.count || (x.touchpoints?.length || 0),
            sourceId: sourceId,
            targetId: targetId,
            sourceName: sourceName,
            targetName: targetName,
            sourceChannelName: y[0].channel_name,
            targetChannelName: y[1].channel_name,
            sourceChannelId: y[0].channel_id,
            targetChannelId: y[1].channel_id,
          };
        });
      } else {
        const name = this.isChannels && x.touchpoints[0].channel_id != null ? x.touchpoints[0].channel_name : x.touchpoints[0].name;
        const id   = this.isChannels && x.touchpoints[0].channel_id != null ? x.touchpoints[0].channel_id   : x.touchpoints[0].id;
        sankeyNodes = {
          source: `${name}_id:${id}=_+_=0`,
          target: '',
          value: x.count,
          sourceName: name,
          targetName: name,
          sourceId: id,
          targetId: id,
          sourceChannelName: x.touchpoints[0].channel_name,
          targetChannelName: x.touchpoints[0].channel_name,
          sourceChannelId: x.touchpoints[0].channel_id,
          targetChannelId: x.touchpoints[0].channel_id,
        };
      }
      sankey = sankey.concat(sankeyNodes);
    });
    sankey.forEach(x => {
      const sourceName = `${x.sourceName}_id:${x.sourceId}`;
      const sourceIndex = this.nodes.findIndex(y => y.name == sourceName && y.id == x.sourceId);
      if (sourceIndex == -1) {
        this.nodes.push({
          name: sourceName,
          id: x.sourceId,
          color:  this.idToColor(
                    this.isChannels && x.sourceChannelId != null ?
                      x.sourceChannelId
                      : x.sourceId,
                    this.isChannels && x.sourceChannelId != null ?
                      x.sourceChannelName
                      : x.sourceName,
                    x.sourceChannelName),
        });
      }
      const targetName = `${x.targetName}_id:${x.targetId}`;
      const targetIndex = this.nodes.findIndex(y => y.name == targetName && y.id == x.targetId);
      if (targetIndex == -1) {
        this.nodes.push({
          name: targetName,
          id: x.targetId,
          color:  this.idToColor(
                    this.isChannels && x.targetChannelId != null ?
                      x.targetChannelId
                      : x.targetId,
                    this.isChannels && x.targetChannelId != null ?
                      x.targetChannelName
                      : x.targetName,
                    x.targetChannelName
                  )
        });
      }
    });
    links = sankey.map(x => {
      return {
        source: x.source,
        target: x.target,
        value: x.value
      };
    });
    this.sankeyLinks = links;
  }

  aggregatedTouchpoints(commaSeparatedFilters: string): any[] {
    return commaSeparatedFilters.split(',').map((id) => {
      const filterId = parseInt(id, 10);
      const point = { id: filterId };
      return this.touchpointToFilter(point);
    }).map((id) => {
      return {
        id: id
      };
    });
  }

  touchpointToFilter(point): Filter {
    const name = this.filterName(point.id);
    const integration = Integration.get.handles[this.filterGroupName(point.id)];
    return {
      id: point.id,
      type: point.t,
      name: name,
      integration: integration,
      integrationType: null,
      level: point.l,
      colorIdx: this.idToColorIdx(point.id, name),
      color: this.idToColor(point.id, name, integration),
    };
  }

  generateAllTouchPoints() {
    if (this.paths) {
      this.paths.forEach( x => {
        const pathTouchPoints = x.touchpoints.map((point) => {
          return {
            delta: point.delta,
            series: point.series,
            filter: this.touchpointToFilter(point)
          };
        });
        this.allTouchPoints.push(pathTouchPoints);
      });
    }
  }

  downloadCSV() {
    const additionalParams: AdditionalOptions = {
      isAggregated: this.isAggregated,
      isCombineRecurring: this.isCombineRecurring,
      labels: this.labels,
      csv: true
    }
    this.pathsService.load(additionalParams).subscribe((resp: any) => {
      let filename;
      if (this.isAggregated) {
        filename = 'Paths Aggregated';
      } else {
        filename = 'Paths';
      }
      filename = filename + ` - ${this.conversionEventName}.csv`;
      const blob = new Blob([resp], {type: 'text/csv'});
      saveAs(blob, filename);
    });
  }

  fetchedAll(): boolean {
    return this.paths.length >= this.count;
  }

  resetFetchedData() {
    this.paths = [];
    this.nodes = [
      {
        name: '',
        integration: '',
        id: null,
        color: this.idToColor(null, '', ''),
      }
    ];
    this.allTouchPoints = [];
    this.count = 0;
    this.summary = {};
  }

  onScroll() {
    if (this.fetchedAll() || this.inProgress || this.additionalInProgress || this.isAggregated) { return; }
    const additionalParams: AdditionalOptions = {
      isAggregated: this.isAggregated,
      isCombineRecurring: this.isCombineRecurring,
      labels: this.labels,
      offset: this.paths.length,
      urlParamsDifferent: true
    }
    this.wasScrolled = true;
    this.dataRefresher.shouldComponentDataRefresh(this.componentPath, additionalParams);
  }

  filterName(filterId: number, force?): string {
    const filter = this.filters.find(x => x.id == filterId);
    if (!filter) { return `Filter-${filterId}`; }
    if (this.isChannels || force) {
      return filter.topParent ? filter.topParent.n : filter.n;
    } else {
      return filter.n;
    }
  }

  filterGroupName(filterId: number, f?): string {
    const filter = f ? f : this.filters.find(x => x.id == filterId);
    if (!filter) { return `Group-${filterId}`; }
    return filter.topParent ? filter.topParent.n : filter.n;
  }

  idToColor(id: number, name: string, channel: string): string {
    let color;
    for (let x of specificColors) {
      if (name != null) {
        if (name.length == 0) {
          color = '#ffffff';
        } else if (name.toLowerCase().includes(x.name) || channel?.toLocaleLowerCase().includes(x.name)) {
          color = x.color;
          break;
        }
      } else {
        if (channel.length == 0) {
          color = '#ffffff';
        } else if (channel?.toLocaleLowerCase().includes(x.name)) {
          color = x.color;
          break;
        }
      }
    }
    return color != null ? color : customPallete[id % customPallete.length+1];
  }

  idToColorIdx(idx: number, name): number {
    return idx % customPallete.length+1;
  }

  toggleSelect(index: number) {
    this.originalPaths[index].selected = !this.originalPaths[index].selected;
    this.generateDataForSankey();
  }

  unselectAll() {
    this.originalPaths.forEach(x => x.selected = false);
  }

  private get isChannels(): boolean {
    return this.labels === 'channels';
  }
}
