import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { map, mergeMap, timeout} from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { Project, NewProjectResponse } from '../../models/project.model';
import { SetProject, UpdateProjectInfo, SetProjectIsLoading, JustSetProject, SetProjectNextIdentifier } from 'src/app/store/actions/project.actions';
import { AccountService } from '../account/account.service';
import { SetOverviewSettings } from 'src/app/store/actions/selected.actions';
import { LocalSubscriptionService } from '../local-subscription/local-subscription.service';
import { SetInitialLoading } from 'src/app/store/actions/report.actions';
import { SetIsInitialLoad } from 'src/app/store/actions/base.actions';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  inProgress;
  apiUrl = environment.clientApiUrl;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private accountService: AccountService,
    private lsService: LocalSubscriptionService,) {}

  selectProject(projectId, identifier) {
    const Url = this.apiUrl + `/project_select`;

    this.lsService.unsubscribeFromSubscriptions();

    return this.httpClient.post(Url, {project_id: projectId}).pipe(
      mergeMap( (response: any) => {
          localStorage.setItem('token', response.token);
          this.store.dispatch(new SetInitialLoading());
          return this.getProjectInfo();
      })
    );
  }

  getProjectInfo(requestWithoutAdditionalRequests?) {
    const Url = this.apiUrl + `/v2/project`;

    this.inProgress = true;
    this.store.dispatch(new SetProjectIsLoading());
    return this.httpClient.get<Project>(Url).pipe(
      map(
        response => {
          if (!requestWithoutAdditionalRequests) {
            this.store.dispatch(new SetProject(response)).subscribe(() => {
              this.store.dispatch(new SetProjectNextIdentifier(response.identifier));
              this.inProgress = false;
            });
          } else {
            this.store.dispatch(new JustSetProject(response));
          }
          return response;
        }, error => this.inProgress = false
      )
    );
  }

  updateProjectInfo() {
    const Url = this.apiUrl + `/v2/project`;

    return this.httpClient.get<Project>(Url).pipe(
      map((response: Project) => {
        this.store.dispatch(new UpdateProjectInfo(response));
        return response;
      })
    );
  }

  createNewProject(ownerId, ownerName) {
    const Url = this.apiUrl + `/v2/projects`;

    return this.httpClient.post(Url, {owner: ownerId, name: ownerName}).pipe(
      map( (resp: NewProjectResponse) => {
        localStorage.setItem('token', resp.token);
        this.store.dispatch(new SetProject(resp.project));
        this.accountService.getAccountInfo().subscribe();
        return resp.project;
      }),
      timeout(10000)
    );
  }

  setAttribute(name: string, value: any) {
    const Url = environment.clientApiUrl + `/v2/project`;
    return this.httpClient.post(Url, { [name]: value });
  }

  getWidgets() {
    const Url = this.apiUrl + `/v2/project/widgets`;

    return this.httpClient.get(Url).pipe(
      map((response: any[]) => {
        this.store.dispatch(new SetOverviewSettings(response));
        return response;
      })
    );
  }

  enableLiveDebug(params) {
    const Url = this.apiUrl + `/v2/project/enable_live_debug`;

    return this.httpClient.post(Url, params);
  }

  disableLiveDebug() {
    const Url = this.apiUrl + `/v2/project/disable_live_debug`;

    return this.httpClient.post(Url, {});
  }
}
