<ng-template #noChecked>
  <input type="radio">
</ng-template>

<div class="attr-card mt-4">
  <div class="traffic__option" [class.project-option-disabled]="disabled">
    <div *ngIf="disabled" class="tooltip-overlap" (mouseover)="showDisabledTooltip()" (mouseout)="hideDisabledTooltip()"></div>
    <div *ngIf="showTitle" class="mb-4">
      <p class="d-inline align-middle title-no-bold">Traffic Conversion and Revenue</p> <a class="ms-5 align-middle" href="https://docs.attributionapp.com/docs/model-customizations"><i class="fa-solid fa-circle-question"></i></a>
    </div>

    <div *ngIf="isInTrafficOptionsArray('include_all') || !isProd" class="traffic__radio cursor-pointer mb-4" [ngClass]="{'revenue__active': view?.traffic_option === 'include_all'}" (click)="selectTrafficOption('include_all')">
      <label class="radio__label">
        <input *ngIf="view?.traffic_option == 'include_all'; else noChecked" type="radio" checked="checked">
      </label>
      <h4>
        <i class="fa-solid fa-lock red me-1" *ngIf="!isInTrafficOptionsArray('include_all') && !isProd"></i>
        Include all Traffic
      </h4>
      <p class="medium-dark">
        Select this option if you want to attribute conversions to direct visits such as visits from type-in traffic or bookmarks in the filter for your attribution model.
        This method is best for e-commerce companies or companies that have many disconnected transactions.
      </p>
    </div>

    <div *ngIf="isInTrafficOptionsArray('exclude_direct_cutoff') || !isProd" class="traffic__radio cursor-pointer mb-4" [ngClass]="{'revenue__active': view?.traffic_option === 'exclude_direct_cutoff', 'project-option-disabled': conversionEvents?.length == 0}" (click)="selectTrafficOption('exclude_direct_cutoff')">
      <div *ngIf="conversionEvents?.length == 0" class="tooltip-overlap" (mouseover)="showDisabledTooltip('option_disabled')" (mouseout)="hideDisabledTooltip()"></div>
      <label class="radio__label">
        <input *ngIf="view?.traffic_option == 'exclude_direct_cutoff'; else noChecked" type="radio" checked="checked">
      </label>
      <div class="clearfix">
        <h4 class="pull-left">
          <i class="fa-solid fa-lock red me-1" *ngIf="!isInTrafficOptionsArray('exclude_direct_cutoff') && !isProd"></i>
          Include all Direct Traffic until Cutoff Event:
        </h4>
        <app-conversion-event-selector
          *ngIf="view?.traffic_option == 'exclude_direct_cutoff'"
          class="pull-left conversion_event_selector form-inline dash__heading__dropdown"
          [isCutoff]="true"
          (selectedCutoffEvent)="selectedCutoff($event)"></app-conversion-event-selector>
      </div>
      <p class="medium-dark">
        Select this option if you want to attribute conversions to direct visits until specific conversion event is tracked, such as a sign up or a purchase. This is typically used by SaaS or subscription commerce companies. Be sure to choose the cutoff event in the dropdown above.
      </p>
    </div>

    <div *ngIf="isInTrafficOptionsArray('exclude_direct') || !isProd" class="traffic__radio cursor-pointer mb-4" [ngClass]="{'revenue__active': view?.traffic_option === 'exclude_direct'}" (click)="selectTrafficOption('exclude_direct')">
      <label class="radio__label">
        <input *ngIf="view?.traffic_option == 'exclude_direct'; else noChecked" type="radio" checked="checked">
      </label>
      <h4>
        <i class="fa-solid fa-lock red me-1" *ngIf="!isInTrafficOptionsArray('exclude_direct') && !isProd"></i>
        Exclude all Direct Traffic
      </h4>
      <p class="medium-dark">
        Select this option if you do not want to attribute conversions to direct traffic in your attribution model.
        This is best for companies that have users that tend to visit the site every day, such as SaaS companies.
      </p>
    </div>

    <div *ngIf="isInTrafficOptionsArray('exclude_all_cutoff') || !isProd" class="traffic__radio cursor-pointer mb-4" [ngClass]="{'revenue__active': view?.traffic_option === 'exclude_all_cutoff', 'project-option-disabled': conversionEvents?.length == 0}" (click)="selectTrafficOption('exclude_all_cutoff')">
      <div *ngIf="conversionEvents?.length == 0" class="tooltip-overlap" (mouseover)="showDisabledTooltip('option_disabled')" (mouseout)="hideDisabledTooltip()"></div>
      <label class="radio__label">
        <input *ngIf="view?.traffic_option == 'exclude_all_cutoff'; else noChecked" type="radio" checked="checked">
      </label>
      <div class="clearfix">
        <h4 class="pull-left">
          <i class="fa-solid fa-lock red me-1" *ngIf="!isInTrafficOptionsArray('exclude_all_cutoff') && !isProd"></i>
          Exclude all Traffic after Cutoff Event:
        </h4>
        <app-conversion-event-selector
          *ngIf="view?.traffic_option == 'exclude_all_cutoff'"
          class="pull-left conversion_event_selector form-inline dash__heading__dropdown"
          [isCutoff]="true"
          [project]="project"
          (selectedCutoffEvent)="selectedCutoff($event)"></app-conversion-event-selector>
      </div>
      <p class="medium-dark">
        Select this option if you do not want to attribute conversions to traffic sources once cutoff event is fired. This is best for companies that have a subscription service with a free trial.
      </p>
    </div>
  </div>
</div>

