import { Injectable } from '@angular/core';
import { ReportService } from '../report/report.service';
import { CohortService } from '../cohort/cohort.service';
import { ChannelPerformanceService } from '../channel-performance/channel-performance.service';
import { OverviewService } from '../overview/overview.service';
import { Store } from '@ngxs/store';
import { ExpandUpTheTree } from 'src/app/store/actions/filter-tree.actions';
import { of, Observable } from 'rxjs';
import { findInTreeV2 } from '../../helpers/find-in-tree';

@Injectable({
  providedIn: 'root'
})
export class LocalSubscriptionService {

  constructor(
    private reportService: ReportService,
    private cohortService: CohortService,
    private cpService: ChannelPerformanceService,
    private overviewService: OverviewService,
    private store: Store
  ) { }

  unsubscribeFromSubscriptions() {
    const project = this.store.selectSnapshot(state => state.project.project);
    if (!project?.options?.features?.load_report_button) this.reportService.unsubscribeAndCancelReportGeneration();
    this.cohortService.unsubscribeAndCancelReportGeneration();
    this.cpService.unsubscribeAndCancelReportGeneration();
    if (typeof(this.overviewService.subscription) !== 'undefined' && this.overviewService.subscription !== null) { this.overviewService.subscription.unsubscribe(); }
  }

  expandToFilter(filter): Observable<any> {
    let parentId = filter.parent_filter_group_id;
    if (!parentId) {
      const tree = this.store.selectSnapshot(state => state.filterTree.filterTree);
      const parent = findInTreeV2({c: tree}, 'c', 'id', filter.id, 't', filter.type, true).parent;
      parentId = parent.id;
    }
    return this.store.dispatch(new ExpandUpTheTree({id: parentId}));
  }

  showFilter(selectedFilter) {
    let elemId = `filter-${selectedFilter.type}-${selectedFilter.id}`;
    const scrollToElem = document.getElementById(elemId);
    if (scrollToElem) {
      this.scrollToFilter(elemId);
    } else {
      this.expandToFilter(selectedFilter).subscribe( () => {
        elemId = `filter-${selectedFilter.type}-${selectedFilter.id}`;
        this.scrollToFilter(elemId);
      });
    }
  }

  scrollToFilter(elemId) {
    setTimeout(() => {
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.scrollIntoView({behavior: 'smooth', block: 'center'});
        this.highlightFilter(elem);
      }
    }, 100);
  }

  highlightFilter(elem) {
    elem.classList.add('highlighted');
    setTimeout(() => {
      elem.classList.remove('highlighted');
    }, 2000);
  }
}
