import { State, Action, StateContext } from '@ngxs/store';

import { SetFirstTimePurchase, SetFTPInProgress, SetFirstTimePurchaseReportToNull, SetFilter, ClearFTPState, PatchFirstTimePurchase } from '../actions/first-time-purchase.actions';
import { CohortStateModel } from './cohort.state';
import { Injectable } from '@angular/core';
import { SelectedFilter } from '../interfaces/cohort';

export interface FirstTimePurchaseStateModel {
  inProgress: boolean;
  meta: any;
  report: any;
  selectedFilter: SelectedFilter;
}

@State<FirstTimePurchaseStateModel>({
  name: 'firstTimePurchase',
  defaults: {
    inProgress: false,
    meta: null,
    report: null,
    selectedFilter: null
  }
})
@Injectable()
export class FirstTimePurchaseState {
  constructor() {}

  @Action(SetFirstTimePurchase)
  SetFirstTimePurchase(ctx: StateContext<FirstTimePurchaseStateModel>, { payload }: SetFirstTimePurchase) {
    const getState = ctx.getState();
    ctx.setState({...getState, ...payload, inProgress: false});
  }

  @Action(PatchFirstTimePurchase)
  PatchFirstTimePurchase(ctx: StateContext<FirstTimePurchaseStateModel>, { payload }: PatchFirstTimePurchase) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: {
        ...getState.report,
        f: {
          ...getState.report.f,
          ...payload.report.filters
        },
        g: {
          ...getState.report.g,
          ...payload.report.groups
        },
      }
    });
  }

  @Action(SetFTPInProgress)
  SetFTPInProgress(ctx: StateContext<FirstTimePurchaseStateModel>) {
    const getState = ctx.getState();
    ctx.setState({...getState, inProgress: true});
  }

  @Action(SetFirstTimePurchaseReportToNull)
  SetFirstTimePurchaseReportToNull(ctx: StateContext<FirstTimePurchaseStateModel>) {
    const getState = ctx.getState();
    ctx.setState({...getState, report: null});
  }

  @Action(SetFilter)
  SetFilter(ctx: StateContext<CohortStateModel>, { payload }: SetFilter) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: null,
      selectedFilter: payload.filter
    });
  }

  @Action(ClearFTPState)
  ClearFTPState(ctx: StateContext<FirstTimePurchaseStateModel>) {
    ctx.setState({
      inProgress: false,
      meta: null,
      report: null,
      selectedFilter: null
    });
  }

}
