import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cohortGetFilterName',
})
export class CohortGetFilterNamePipe implements PipeTransform {
  transform(filter, filterTree, FTWS): any {
    if (filter?.n) return filter.n;
    if (filterTree) {
      const filterFound = FTWS.findInTree({node: {c: filterTree}, childrenKey: 'c', key: 'id', value: parseInt(filter.id), additionalKey: 't', additionalValue: filter.t});
      return filterFound?.n;
    } else {
      return null;
    }
  }
}