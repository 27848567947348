<app-loader *ngIf="isLoading; else loaded" [position]="'relative'" [color]="'green'" [height]="'100px'" [backgroundColor]="className == 'channel-summary row' ? 'white' : '#f9faf9'">
</app-loader>

<ng-template #prevSummaryParamsTooltip>
  <span class="bold mb-1">Previous Result</span><br>
  <span class="small">{{prevSummaryParams?.dates?.start | date: 'mediumDate'}}-{{prevSummaryParams?.dates?.end | date: 'mediumDate'}}</span><br>
  <span class="small">{{attrModelMapping[prevSummaryParams?.attrModel]}} Model</span><br>
  <span class="small">{{prevSummaryParams?.attrType}} looking Attribution</span><br>
  <span class="small">{{prevSummaryParams?.trafficModel}} Traffic</span><br>
</ng-template>
<ng-template #loaded>
  <div class="{{ className }}">

    <div class="col">
      <div class="summary-item">
        <div *ngIf="prevSummary" class="prev" placement="top" tooltipClass="tooltip-over-controlbar" container="body" [ngbTooltip]="prevSummaryParamsTooltip">
          <span class="me-1">
            <i *ngIf="ROI < prevROI; else arrowDown" class="fa-solid fa-arrow-up green"></i>
            <ng-template #arrowDown>
              <i *ngIf="ROI != prevROI" class="fa-solid fa-arrow-down red"></i>
            </ng-template>
          </span>
          <span *ngIf="prevAdSpend !== 0" [countUp]="prevROI" [options]="{decimalPlaces: 1, duration: 0.4}">{{ prevROI | number: '1.0-1' }}</span><span *ngIf="prevAdSpend !== 0; else infinityRoas">&times;</span>
        </div>
        <div class="summary-item-value" [class.funds-green]="ROI > 0 ||ROI == null" [class.funds-red]="ROI < 0" [class.w-p-s]="prevSummary">
          <span *ngIf="adSpend !== 0;" [countUp]="ROI" [options]="{decimalPlaces: 1, duration: 0.4}">{{ ROI | number: '1.0-1' }}</span><span *ngIf="adSpend !== 0; else infinityRoas">&times;</span>
          <ng-template #infinityRoas> &infin;</ng-template>
        </div>
        <p class="title">Return on Investment</p>
        <p class="channel">{{ channelName? channelName : 'All Traffic' }}</p>
      </div>
    </div>

    <div class="col">
      <div class="summary-item">
        <div *ngIf="prevSummary" class="prev" placement="top" tooltipClass="tooltip-over-controlbar" container="body" [ngbTooltip]="prevSummaryParamsTooltip">
          <span class="me-1">
            <i *ngIf="totalRevenue < prevTotalRevenue; else arrowDown" class="fa-solid fa-arrow-up green"></i>
            <ng-template #arrowDown>
              <i *ngIf="summary && totalRevenue != prevTotalRevenue" class="fa-solid fa-arrow-down red"></i>
            </ng-template>
          </span>
          <span [countUp]="prevTotalRevenue" [options]="{prefix: projectCurrency, duration: 0.4}">{{ prevTotalRevenue | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <div class="summary-item-value" [class.funds-green]="totalRevenue > 0" [class.w-p-s]="prevSummary">
          <span [countUp]="totalRevenue" [options]="{prefix: projectCurrency, duration: 0.4}">{{ totalRevenue | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <p class="title">Attributed Revenue</p>
        <p class="channel">{{ channelName? channelName : 'All Traffic' }}</p>
      </div>
    </div>

    <div class="col">
      <div class="summary-item">
        <div *ngIf="prevSummary" class="prev" placement="top" tooltipClass="tooltip-over-controlbar" container="body" [ngbTooltip]="prevSummaryParamsTooltip">
          <span class="me-1">
            <i *ngIf="ROI2 < prevROI2; else arrowDown" class="fa-solid fa-arrow-up green"></i>
            <ng-template #arrowDown>
              <i *ngIf="summary && ROI2 != prevROI2" class="fa-solid fa-arrow-down red"></i>
            </ng-template>
          </span>
          <span [countUp]="prevROI2" [options]="{prefix: projectCurrency, duration: 0.4}">{{ prevROI2 | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <div class="summary-item-value" [class.funds-green]="ROI2 > 0" [class.funds-red]="ROI2 < 0" [class.w-p-s]="prevSummary">
          <span [countUp]="ROI2" [options]="{prefix: projectCurrency, duration: 0.4}">{{ ROI2 | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <p class="title">Return on Investment</p>
        <p class="channel">{{ channelName? channelName : 'All Traffic' }}</p>
      </div>
    </div>

    <div class="col">
      <div class="summary-item">
        <div *ngIf="prevSummary" class="prev" placement="top" tooltipClass="tooltip-over-controlbar" container="body" [ngbTooltip]="prevSummaryParamsTooltip">
          <span class="me-1">
            <i *ngIf="adSpend < prevAdSpend; else arrowDown" class="fa-solid fa-arrow-up green"></i>
            <ng-template #arrowDown>
              <i *ngIf="summary && adSpend != prevAdSpend" class="fa-solid fa-arrow-down red"></i>
            </ng-template>
          </span>
          <span [countUp]="prevAdSpend" [options]="{prefix: projectCurrency, duration: 0.4}">{{ prevAdSpend | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <div class="summary-item-value funds-red" [class.w-p-s]="prevSummary">
          <span [countUp]="adSpend" [options]="{prefix: projectCurrency, duration: 0.4}">{{ adSpend | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <p class="title">Advertising Spend</p>
        <p class="channel">{{ channelName? channelName : 'All Traffic' }}</p>
      </div>
    </div>

    <div class="col">
      <div class="summary-item">
        <div *ngIf="prevSummary" class="prev" placement="top" tooltipClass="tooltip-over-controlbar" container="body" [ngbTooltip]="prevSummaryParamsTooltip">
          <span class="me-1">
            <i *ngIf="RPS < prevRPS; else arrowDown" class="fa-solid fa-arrow-up green"></i>
            <ng-template #arrowDown>
              <i *ngIf="summary && RPS != prevRPS" class="fa-solid fa-arrow-down red"></i>
            </ng-template>
          </span>
          <span [countUp]="prevRPS" [options]="{prefix: projectCurrency, duration: 0.4}">{{ prevRPS | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <div class="summary-item-value funds-green" [class.w-p-s]="prevSummary">
          <span [countUp]="RPS" [options]="{prefix: projectCurrency, duration: 0.4}">{{ RPS| projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <p class="title">Revenue Per Sale</p>
        <p class="channel">{{ channelName? channelName : 'All Traffic' }}</p>
      </div>
    </div>

    <div class="col">
      <div class="summary-item">
        <div *ngIf="prevSummary" class="prev" placement="top" tooltipClass="tooltip-over-controlbar" container="body" [ngbTooltip]="prevSummaryParamsTooltip">
          <span class="me-1">
            <i *ngIf="CPS < prevCPS; else arrowDown" class="fa-solid fa-arrow-up green"></i>
            <ng-template #arrowDown>
              <i *ngIf="summary && CPS != prevCPS" class="fa-solid fa-arrow-down red"></i>
            </ng-template>
          </span>
          <span [countUp]="prevCPS" [options]="{prefix: projectCurrency, duration: 0.4}">{{ prevCPS | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <div class="summary-item-value funds-red" [class.w-p-s]="prevSummary">
          <span [countUp]="CPS" [options]="{prefix: projectCurrency, duration: 0.4}">{{ CPS | projectCurrency: 'dollars' : '1.0-0' }}</span>
        </div>
        <p class="title">Cost Per Sale</p>
        <p class="channel">{{ channelName? channelName : 'All Traffic' }}</p>
      </div>
    </div>
  </div>
</ng-template>