import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Account } from 'src/app/core/models/account.model';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Project } from 'src/app/core/models/project.model';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Integration, IntegrationTypes } from 'src/app/core/constants/integration';

@Component({
  selector: 'app-settings-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss'],
})
export class SharedComponent implements OnInit, OnDestroy {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.account) account$: Observable<Account>;

  documentationLink = 'https://docs.attributionapp.com/';

  account: Account;
  project: Project;
  projectIsLoading: boolean;
  currentIdentifier = '';

  componentNames = ComponentNames;
  settingsSections = SettingsSections;
  assetPath = environment.assetPath;

  projectSub: Subscription;
  accountSub: Subscription;
  routeSub: Subscription;

  shownIntegrationType: string;
  integrationTypes = IntegrationTypes;

  constructor(private authService: AuthService, private router: Router, private route:ActivatedRoute) { }

  ngOnInit() {
    this.projectSub = this.project$.subscribe(x => {
      this.project = x.project;
      this.currentIdentifier = x.project?.identifier;
      this.projectIsLoading = x.projectIsLoading;
    });
    this.accountSub = this.account$.subscribe(x => this.account = x);
    this.routeSub = combineLatest([this.router.events, this.route.queryParams]).subscribe(([val, qParams]) => {
      if (window.location.pathname.includes('settings/integrations/show')) {
        const pathAr = window.location.pathname.split('settings/integrations/show/');
        const integrationName = pathAr[pathAr.length - 1];
        const realIntegrationName = Integration.get.AdminDict.find(x => x.checkArray.includes(integrationName))?.newest || Integration.get.AdminDict.find(x => x.name == integrationName)?.newest || integrationName;
        this.shownIntegrationType = Integration.get.config[realIntegrationName]?.type;
      } else {
        if (qParams.type === 'ads') {
          this.shownIntegrationType = this.integrationTypes.integration;
        } else if (qParams.type === 'conversions') {
          this.shownIntegrationType = this.integrationTypes.connector;
        } else if (qParams.type === 'transformers') {
          this.shownIntegrationType = this.integrationTypes.transformer;
        } else {
          this.shownIntegrationType = null;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.projectSub?.unsubscribe();
    this.accountSub?.unsubscribe();
    this.routeSub?.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

}
