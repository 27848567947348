import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardFilterError',
})
export class DashboardFilterErrorPipe implements PipeTransform {
  transform(item, filterErrors): any {
    const type = item.t == 'f' ? 'filters' : 'groups';
    return filterErrors?.[type]?.[item.id];
  }
}