import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {
  transform(url: any, format: any ): any {
    if (url === null) { return; }
    if (format === 'pathname') {
      const path = /^[^#]*?:\/\/.*?(\/.*)$/g.exec(url);
      if (path) {
        return path[1];
      } else {
        return '/';
      }
    }
  }
}