<div id="legend" class="legend-wrapper">
  <div class="legend">
    <div class="header-and-selectors me-5">
      <div class="area-title d-inline-block me-3">Attribution Model Comparison</div>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center me-3 cursor-pointer" *ngFor="let item of legendArray" (click)="toggleVisibility(item)">
        <div class="{{item}}-box legend-box" [class.disabled]="item | includedInArray:doNotShow" (mouseenter)="addHoverClass(item)" (mouseleave)="removeHoverClass(item)"></div>
        <div class="d-inline-block" [class.box-disabled]="item | includedInArray:doNotShow">{{ attrModels[item] }}</div>
      </div>
    </div>
    <div class="mt-2">
      <div class="d-inline-block me-5" [ngClass]="{'mr-3': selectedComparison != 'revenue', 'mr-5': selectedComparison == 'revenue'}">
        <div class="pos-relative" [class.mt-4]="selectedComparison == 'conversions'">
          <small class="controllbar__hint top">Metric</small>
          <div ngbDropdown>
            <button class="btn btn-default dropdown-toggle btn-select text-capitalize btn-sm" id="dropdownBasic1" ngbDropdownToggle>{{selectedComparison}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="selectComparison('revenue')">Revenue</button>
              <button ngbDropdownItem (click)="selectComparison('conversions')">Conversions</button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-inline-block me-5" *ngIf="selectedComparison != 'revenue'">
        <div class="pos-relative" [class.mt-4]="selectedComparison == 'conversions'">
          <small class="controllbar__hint top">Conversion Event</small>
          <app-conversion-event-selector
            class="conversion_event_selector"
            [btnClass]="'btn-sm'"
            [eventFromComparison]="selectedEvent"
            (selectedCutoffEvent)="selectEvent($event)">
          </app-conversion-event-selector>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="comparisons-wrapper">
  <app-cp-compare-vertical-chart
    [max]="max"
    [data]="data"
    [comparison]="selectedComparison"></app-cp-compare-vertical-chart>
</div>