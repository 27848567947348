import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetPathReport, SetPathReportAdditionInProgress, SetPathReportInProgress } from 'src/app/store/actions/paths.actions';
import { environment } from 'src/environments/environment';
import { PathsStateModel } from 'src/app/store/states/paths.state';

export interface AdditionalOptions {
  isAggregated: boolean;
  isCombineRecurring: boolean;
  labels: string;
  offset?: number;
  csv?: boolean;
  urlParamsDifferent?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PathsService {
  subscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService,
    private store: Store
  ) { }

  load(additionalOptions: AdditionalOptions) {
    const pathState = this.store.selectSnapshot<PathsStateModel>(state => state.paths);
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    const aggregated = additionalOptions.isAggregated ? '/aggregated' : '';
    const suffix = additionalOptions.csv ? '.csv' : '';
    const Url = `${environment.reportingApiUrl}/v2/paths${aggregated}${suffix}`;

    const Params = {
      ...this.baseService.getReportingParams,
      offset: additionalOptions.offset || 0,
      labels: additionalOptions.labels,
      combine_recurring: additionalOptions.isCombineRecurring,
      is_aggregated: additionalOptions.isAggregated,
      response_type: additionalOptions.csv ? 'text' : 'json'
    };

    if (additionalOptions.csv) {
      Params.csv = additionalOptions.csv;
    }

    if (pathState.filterOrGroup) {
      Params.match_operator = pathState.filterType;
      if (pathState.filterOrGroup?.type === 'f') {
        Params.filter = pathState.filterOrGroup.id;
      } else if (pathState.filterOrGroup?.type === 'g') {
        Params.filter_group_id = pathState.filterOrGroup.id;
      }
    }

    if (additionalOptions.csv) {
      return this.httpClient.get(Url, {params: Params , responseType: 'arraybuffer'}).pipe(map( (x) => {
        this.baseService.trackReportLoaded('Paths', {
          start: Params.start,
          end: Params.end,
          conversions_event: Params.conversion_event_name,
          labels: additionalOptions.labels,
          offset: additionalOptions.offset
        });
        return x;
      }));
    } else {
      this.store.dispatch(additionalOptions.offset > 0 ? new SetPathReportAdditionInProgress(true) : new SetPathReportInProgress(true));
      this.subscription = this.httpClient.get(Url, { params: Params }).pipe(map( (x) => {
        this.baseService.trackReportLoaded('Paths', {
          start: Params.start,
          end: Params.end,
          conversions_event: Params.conversion_event_name,
          labels: additionalOptions.labels,
          offset: additionalOptions.offset
        });
        return x;
      })).subscribe( (resp:any) => {
        const pathState = this.store.selectSnapshot(state => state.paths);
        const data = !additionalOptions.offset || additionalOptions.offset == 0 ? [] : (pathState.report?.data || []);
        const newData = data.concat(resp.data)
        this.store.dispatch(new SetPathReport({
          count: resp.count,
          data: newData,
          summary: resp.summary
        }));
      });
    }
  }

}
