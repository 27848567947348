<div class="overview-wrapper">
  <app-loader *ngIf="isLoading" [backgroundColor]="'rgba(0, 0, 0, 0.75)'"></app-loader>
  <div class="container-fluid mb-3">
      <div *ngIf="overview?.report && overview?.report?.summary?.meta?.status == 'ready'" class="chart-wrapper">
          <div class="chart-header">
            <div class="chart-name">
              Summary
            </div>
            <app-preset-switcher-dropdown
              [earliestDate]="earliestDate"
              [name]="'Selected period'"
              [selectedPreset]="selectedOverviewSettings?.summary?.period"
              (valueChange)="presetChange($event,'summary')"></app-preset-switcher-dropdown>
          </div>
    <app-cp-summary
      *ngIf='overview?.report?.summary'
      [className]="'summary-wrapper row no-gutters mt-3'"
      [summary]="overview?.report?.summary?.report"></app-cp-summary>
        </div>
    <div *ngIf="overview?.report" class="widgets-wrapper">
      <div class="row mt-3">
        <div class="col-md-6">
          <div *ngIf="overview?.report?.roas && overview?.report?.roas?.meta?.status == 'ready'" class="chart-wrapper">
            <div class="chart-header">
              <div class="chart-name">
                Roi
              </div>
              <app-preset-switcher-dropdown
                [name]="'Selected period'"
                [earliestDate]="earliestDate"
                [selectedPreset]="selectedOverviewSettings.roas.period"
                (valueChange)="presetChange($event,'roas')"></app-preset-switcher-dropdown>
            </div>
            <app-line-chart
              *ngIf="overview?.report?.roas?.list"
              [chartName]="'roi-chart'"
              [chartHeight]="200"
              [maxLabels]="5"
              [data]="overview?.report?.roas?.list"
              [color]="'green'"></app-line-chart>
          </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="overview?.report?.revenue && overview?.report?.revenue?.meta?.status == 'ready'" class="chart-wrapper">
            <div class="chart-header">
              <div class="chart-name">
                Revenue
              </div>
              <app-preset-switcher-dropdown
                [name]="'Selected period'"
                [earliestDate]="earliestDate"
                [selectedPreset]="selectedOverviewSettings.revenue.period"
                (valueChange)="presetChange($event,'revenue')"></app-preset-switcher-dropdown>
            </div>
            <app-line-chart
              *ngIf="overview?.report?.revenue?.list"
              [chartName]="'revenue-chart'"
              [chartHeight]="200"
              [maxLabels]="5"
              [data]="overview?.report?.revenue?.list"
              [color]="'green'"></app-line-chart>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div [ngClass]="(overview?.report?.roas_by_channel && overview?.report?.roas_by_channel?.meta?.status == 'ready' && overview?.report?.roas_by_channel?.list?.length >= expandChannelsNum) ? 'col-md-12' : 'col-md-6'">
          <div class="chart-wrapper" *ngIf="overview?.report?.roas_by_channel && overview?.report?.roas_by_channel?.meta?.status == 'ready'">
            <div class="chart-header">
              <div class="chart-name">
                Roi By Channel
              </div>
              <app-preset-switcher-dropdown
                [name]="'Selected period'"
                [earliestDate]="earliestDate"
                [selectedPreset]="selectedOverviewSettings.roas_by_channel.period"
                (valueChange)="presetChange($event,'roas_by_channel')"></app-preset-switcher-dropdown>
            </div>
            <app-combo-chart
              *ngIf="overview?.report?.roas_by_channel"
              [tooltipName]="'roas_by_channel'"
              [isScrollable]='false'
              [marginBottom]="95"
              [channel]="overview?.report?.roas_by_channel.list">
            </app-combo-chart>
          </div>
        </div>
        <div *ngIf="overview?.report?.spend_by_channel && overview.report.spend_by_channel.meta.status == 'ready' && overview?.report?.spend_by_channel?.list?.length >= expandChannelsNum" class="mt-10px"></div>
        <div [ngClass]="(overview?.report?.spend_by_channel && overview?.report?.spend_by_channel?.list?.length >= expandChannelsNum) ? 'col-md-12' : 'col-md-6'">
          <div class="chart-wrapper" *ngIf="overview?.report?.spend_by_channel && overview?.report?.spend_by_channel?.meta?.status == 'ready'">
            <div class="chart-header">
              <div class="chart-name">
                Spend By Channel
              </div>
              <app-preset-switcher-dropdown
                [name]="'Selected period'"
                [earliestDate]="earliestDate"
                [selectedPreset]="selectedOverviewSettings.spend_by_channel.period"
                (valueChange)="presetChange($event,'spend_by_channel')"></app-preset-switcher-dropdown>
            </div>
            <app-bar-chart
              *ngIf="overview?.report?.spend_by_channel"
              [chartName]="'spend-by-channel-chart'"
              [marginBottom]="80"
              [tooltipName]="'spend_by_channel'"
              [report]="overview?.report?.spend_by_channel"
              [color]="'red'"></app-bar-chart>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="chart-wrapper" *ngIf="overview?.report?.revenue_per_sale && overview?.report?.revenue_per_sale?.meta?.status == 'ready'">
            <div class="chart-header">
              <div class="chart-name">
                Revenue Per Sale
              </div>
              <app-preset-switcher-dropdown
                [name]="'Selected period'"
                [earliestDate]="earliestDate"
                [selectedPreset]="selectedOverviewSettings.revenue_per_sale.period"
                (valueChange)="presetChange($event,'revenue_per_sale')"></app-preset-switcher-dropdown>
            </div>
            <app-line-chart
              *ngIf="overview?.report?.revenue_per_sale?.list"
              [chartName]="'revenue-per-sale-chart'"
              [chartHeight]="200"
              [maxLabels]="5"
              [data]="overview?.report?.revenue_per_sale?.list"
              [color]="'green'"></app-line-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
