import { State, Action, StateContext, Store } from '@ngxs/store';

import { DateTime } from 'luxon';

import {
  SetChosenDates,
  SetAttributionModel,
  SetConversionEvent,
  ClearSelected,  
  SetCurrentComponent,
  SetCpOpenChannelId,
  SetOverviewSettings,
  ResetOverviewSettings,
  ClearReports,
  ToggleProperties,
  SetSettingSection,
  ClearReportsWithoutCPSummary,
  SetRevenueGroup,
  CloseProperties,
  ClearAggrPeriodReports,
  SetRevenueGroupAndPreset,
  SetLastSelectedPreset,
  SetDatesChanged
} from '../actions/selected.actions';
import { ConversionEvent } from 'src/app/core/models/conversionEvent.model';
import { ConversionEventService } from 'src/app/core/services/conversion-event/conversion-event.service';
import { SetViewDaterange, SetViewRevenueGroupId, UpdateAttributionModel, UpdateConversionEvent } from '../actions/project.actions';
import { ClearCohort } from '../actions/cohort.actions';
import { ClearReport } from '../actions/report.actions';
import { ClearCP, ClearCPWithoutSummary } from '../actions/channel-performance.actions';
import { SetOverviewReportsToNull, ClearOverview } from '../actions/overview.actions';
import { PropertyService } from '../../core/services/property/property.service';
import { Injectable } from '@angular/core';
import { ClearProperties, SetEventPropertyKey } from '../actions/properties.action';
import { ClearFTPState } from '../actions/first-time-purchase.actions';
import { AttributionModel, ChosenDates, OverviewSettings } from '../interfaces/selected';
import { defaultPreset } from 'src/app/core/constants/overview';
import { ClearPathReport } from '../actions/paths.actions';
import { ClearTPState } from '../actions/time-periods.actions';

const defaultOverviewSettings = {
  summary: {
    period: defaultPreset
  },
  roas: {
    period: defaultPreset
  },
  revenue: {
    period: defaultPreset
  },
  roas_by_channel: {
    period: defaultPreset
  },
  spend_by_channel: {
    period: defaultPreset
  },
  revenue_per_sale: {
    period: defaultPreset
  }
}

export interface SelectedStateModel {
  currentComponent: string;
  settingSection: string;
  chosenDates: ChosenDates;
  attributionModel: AttributionModel;
  conversionEvent: ConversionEvent;
  cpOpenChannelId: number;
  overviewSettings: OverviewSettings;
  isPropertiesOpened: boolean;
  revenueGroup: any;
  lastSelectedPreset: string;
  datesChanged: boolean;
}

@State<SelectedStateModel>({
  name: 'selected',
  defaults: {
    currentComponent: null,
    settingSection: null,
    chosenDates: {
      start: null,
      end: null,
    },
    datesChanged: true,
    attributionModel: null,
    conversionEvent: null,
    cpOpenChannelId: null,
    overviewSettings: defaultOverviewSettings,
    isPropertiesOpened: false,
    revenueGroup: null,
    lastSelectedPreset: null
  }
})
@Injectable()
export class SelectedState {
  constructor(
    private eventService: ConversionEventService,
    private propertyService: PropertyService,
    private store: Store,) {}

  @Action(SetChosenDates)
  SetChosenDates(ctx: StateContext<SelectedStateModel>, { payload }: SetChosenDates) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      chosenDates: payload,
      datesChanged: true,
    });
    if (!payload.skipEventsRequest) this.eventService.getEventNames().subscribe();
    if (getState.isPropertiesOpened) {
      this.propertyService.getKeys();
    }
    this.store.dispatch(new ClearReports());
  }

  @Action(SetDatesChanged)
  SetDatesChanged(ctx: StateContext<SelectedStateModel>, { payload }: SetDatesChanged) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      datesChanged: payload
    });
  }

  @Action(SetAttributionModel)
  SetAttributionModel(ctx: StateContext<SelectedStateModel>, { payload }: SetAttributionModel) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      attributionModel: payload.attributionModel
    });
    if (!payload.fromProjectSettings) {
      this.store.dispatch(new UpdateAttributionModel(payload.attributionModel.name)).subscribe(() => {
        this.store.dispatch(new ClearReportsWithoutCPSummary());
      });
    }
  }

  @Action(SetConversionEvent)
  SetConversionEvent(ctx: StateContext<SelectedStateModel>, { payload }: SetConversionEvent) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      conversionEvent: payload.event
    });
    this.store.dispatch(new UpdateConversionEvent(payload.event.name)).subscribe(() => {
      if (getState.isPropertiesOpened) {
        this.propertyService.getKeys();
      } else {
        this.store.dispatch(new ClearProperties());
      }
    }); 
  }

  @Action(SetCurrentComponent)
  SetCurrentComponent(ctx: StateContext<SelectedStateModel>, { payload }: SetCurrentComponent) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      currentComponent: payload.currentComponent
    });
  }

  @Action(SetSettingSection)
  SetSettingSection(ctx: StateContext<SelectedStateModel>, { payload }: SetSettingSection) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      settingSection: payload
    });
  }

  /**
   * @param payload `{ summary: { period: '1 Month'}, ...}`
   */
  @Action(SetOverviewSettings)
  SetOverviewSettings(ctx: StateContext<SelectedStateModel>, { payload }: SetOverviewSettings) {
    const getState = ctx.getState();
    const newSettings = getState.overviewSettings || {} as OverviewSettings;

    if (payload) {
      Object.keys(payload).forEach( widget => {
        newSettings[widget] = payload[widget];
      });
    }

    ctx.setState({
      ...getState,
      overviewSettings: newSettings
    });
  }

  @Action(ResetOverviewSettings)
  ResetOverviewSettings(ctx: StateContext<SelectedStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      overviewSettings: defaultOverviewSettings
    });
    this.store.dispatch(new SetOverviewReportsToNull());
  }

  @Action(SetCpOpenChannelId)
  SetCpOpenChannelId(ctx: StateContext<SelectedStateModel>, { payload }: SetCpOpenChannelId) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      cpOpenChannelId: payload.channelId
    });
  }

  @Action(ClearSelected)
  ClearSelected(ctx: StateContext<SelectedStateModel>) {
    ctx.setState({
      currentComponent: null,
      settingSection: null,
      chosenDates: {
        start: DateTime.now().minus({days: 30}).toFormat('y-MM-dd'),
        end: DateTime.now().minus({days: 1}).toFormat('y-MM-dd')
      },
      datesChanged: true,
      attributionModel: null,
      conversionEvent: null,
      cpOpenChannelId: null,
      overviewSettings: defaultOverviewSettings,
      isPropertiesOpened: false,
      revenueGroup: null,
      lastSelectedPreset: null
    });
  }

  @Action(ToggleProperties)
  ToggleProperties(ctx: StateContext<SelectedStateModel>,{ payload }: ToggleProperties) {
    const getState = ctx.getState();
    const keyRequested = this.store.selectSnapshot(state => state.properties.keysRequested);
    if (!payload && !getState.isPropertiesOpened && keyRequested === false) {
      this.propertyService.getKeys();
    }
    ctx.setState({
      ...getState,
      isPropertiesOpened: !getState.isPropertiesOpened
    });
    if (!!getState.isPropertiesOpened) {
      this.store.dispatch([
        new SetEventPropertyKey({key: null, shouldUpdateView: true}),
        new ClearReport(),
        new ClearPathReport(),
      ]);
    }
  }

  @Action(CloseProperties)
  CloseProperties(ctx: StateContext<SelectedStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      isPropertiesOpened: false
    });
  }

  @Action(SetRevenueGroup)
  SetRevenueGroup(ctx: StateContext<SelectedStateModel>, { payload }: SetRevenueGroup) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      revenueGroup: payload.revenueGroup
    });
    this.store.dispatch(new SetViewRevenueGroupId(payload.revenueGroup?.id));
  }

  @Action(SetLastSelectedPreset)
  SetLastSelectedPreset(ctx: StateContext<SelectedStateModel>, { payload }: SetLastSelectedPreset) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      lastSelectedPreset: payload
    });
    this.store.dispatch(new SetViewDaterange(payload));
  }

  @Action(SetRevenueGroupAndPreset)
  SetRevenueGroupAndPreset(ctx: StateContext<SelectedStateModel>, { payload }: SetRevenueGroupAndPreset) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      revenueGroup: payload.revenueGroup,
      lastSelectedPreset: payload.preset
    });
  }

  @Action(ClearReports)
  ClearReports() {
    this.store.dispatch([
      new ClearReport(),
      new ClearOverview(),
      new ClearCohort(),
      new ClearCP(),
      new ClearFTPState(),
      new ClearPathReport(),
      new ClearTPState(),
    ]);
  }

  @Action(ClearAggrPeriodReports)
  ClearAggrPeriodReports() {
    this.store.dispatch([
      new ClearCohort(),
      new ClearCP(),
      new ClearFTPState(),
      new ClearTPState(),
    ]);
  }

  @Action(ClearReportsWithoutCPSummary)
  ClearReportsWithoutCPSummary() {
    this.store.dispatch([
      new ClearReport(),
      new ClearOverview(),
      new ClearCohort(),
      new ClearCPWithoutSummary(),
      new ClearFTPState(),
      new ClearTPState(),
      new ClearPathReport()
    ]);
  }
}
