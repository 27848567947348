import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ProjectService } from '../project/project.service';

@Injectable()
export class OverviewResolver  {
  constructor(private service: ProjectService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.service.getWidgets();
  }
}
