import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FadeInOutAnimation } from 'src/app/core/animations/fade-in-out';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [FadeInOutAnimation]
})
export class LoaderComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() position: string;
  @Input() color: string;
  @Input() height: string;
  @Input() zIndex: number;

  style;
  gifStyle;
  borderStyle;
  assetPath = environment.assetPath;

  constructor() { }

  ngOnInit() {
    this.style = {
      'background-color': this.backgroundColor || 'white',
      'position': this.position || 'fixed',
      'z-index': this.zIndex ? this.zIndex : 2
    };
    this.gifStyle = {
      'height': this.height || 'auto',
      'width': this.height ? 'auto' : this.color === 'green' ? '3%' : '5%'
    };
  }

}
