import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetAccount, SetLastRegistrationStep, SetRegistrationStep } from 'src/app/store/actions/account.actions';
import { SetCurrentStep, SetFlow, SetLastStep } from 'src/app/store/actions/onboarding.actions';
import { environment } from 'src/environments/environment';
import { Project } from '../../models/project.model';
import { SetProject } from '../../../store/actions/project.actions';
import { map } from 'rxjs/operators';
import { SetCard } from 'src/app/store/actions/settings.actions';


@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private httpClient: HttpClient, private router: Router, private store: Store) { }

  getFlow() {
    const Url =  `${environment.clientApiUrl}/onboarding/get_flow`;

    return this.httpClient.get(Url).pipe(
      map(x => {
        this.store.dispatch(new SetFlow(x));
        return x;
      })
    );
  }

  setStep(step) {
    const Url = `${environment.clientApiUrl}/onboarding/current_step`;

    this.httpClient.post(Url, { step: step }).subscribe(
      (x: any) => {
        this.store.dispatch([
          new SetCurrentStep(x.current_step),
          new SetLastStep(x.last_step),
          new SetRegistrationStep(x.current_step)
        ]);
        this.navigateToStep(x.current_step);
      }
    );
  }

  navigateToStep(step, queryParams?) {
    if (queryParams) {
      this.router.navigate(['onboarding', step], {queryParams: queryParams});
    } else this.router.navigate(['onboarding', step]);
  }

  navigateToNext(flowStep?) {
    if (flowStep) this.setStep(flowStep.next_page);
    else {
      this.setStep(this.currentStep.next_page);
    }
  }

  navigateBack(flowStep) {
    this.setStep(flowStep.prev_page);
  }

  navigateToCurrentStep(queryParams?) {
    const Url =  `${environment.clientApiUrl}/onboarding/current_step`;

    this.httpClient.get(Url).subscribe( (x: any) => {
      this.store.dispatch([
        new SetCurrentStep(x.current_step),
        new SetLastStep(x.last_step)
      ]);
      this.navigateToStep(x.current_step, queryParams);
    });
  }

  send_instructions(email: string) {
    const Url =  `${environment.clientApiUrl}/onboarding/send_instructions`;

    return this.httpClient.post(Url, {email: email});
  }

  createAccount(inviteCode, email, password) {
    const Url =  `${environment.clientApiUrl}/onboarding/create_account`;

    return this.httpClient.post(Url, {email: email, password: password, inviteCode: inviteCode});
  };

  updateAccount(email, password) {
    const Url =  `${environment.clientApiUrl}/onboarding/update_account`;

    return this.httpClient.post(Url, {email: email, password: password});
  };

  createProject(workspace, website, name, timezone) {
    const Url =  `${environment.clientApiUrl}/onboarding/create_project`;

    this.httpClient.post(Url, { workspace: workspace, website: website, name: name, timezone: timezone}).subscribe((x: any) => {
      this.saveToken(x.token);
      this.store.dispatch(new SetAccount(x.account));
      this.store.dispatch(new SetProject(x.project));

      this.navigateToNext(this.currentStep);
    });
  };

  updateProject(workspace, website, name, timezone) {
    const Url = `${environment.clientApiUrl}/onboarding/update_project`;

    this.httpClient.post(Url, { workspace: workspace, website: website, name: name, timezone: timezone}).subscribe((x: any) => {
      this.store.dispatch(new SetAccount(x.account));
      this.store.dispatch(new SetProject(x.project));

      this.navigateToNext(this.currentStep);
    });
  }

  saveCard(token) {
    const Url = `${environment.clientApiUrl}/onboarding/save_card`;

    this.httpClient.post(Url, {stripe_token: token}).subscribe( (x: any) => {
      this.store.dispatch(new SetCard(x));

      this.navigateToNext(this.currentStep);
    });
  }

  finish() {
    const Url = `${environment.clientApiUrl}/onboarding/finish`;

    this.httpClient.post(Url, {}).subscribe( x => {
      this.store.dispatch([
        new SetRegistrationStep('finished'),
        new SetLastRegistrationStep(0),
        new SetLastStep(0)
      ]);
      const project: Project = this.store.selectSnapshot( state => state.project.project);
      const identifier = project?.identifier;
      this.router.navigate([identifier]); // not sure test it please if it navigates to dashboard
    });
  }

  saveToken(token) {
    return localStorage.setItem('token', token);
  }

  canGoForward(step, lastStep) {
    if (step) return step.skippable? true : lastStep > step.step_number || lastStep == 0 ? true : false;
    else return false;
  }

  canGoBack(step) {
    if (step) return step.previous;
    else return false;
  }

  get currentStep() {
    const Onboarding = this.store.selectSnapshot(state => state.onboarding);
    return Onboarding.flow[Onboarding.current_step];
  }

}
