<div class="preset-wrapper">
  <!-- <i class="fa-solid fa-info-circle me-2" [attr.placement]="tooltipPosition" attr.ngbTooltip="{{name}}: {{selectedPreset}}"></i> -->
  <span class="me-3">{{selectedPreset}}</span>
  <div ngbDropdown class="d-inline-block dropdown">
    <span class="cursor-pointer" id="preset-dropdown" ngbDropdownToggle>
      <i class="fa-solid fa-bars"></i>
    </span>
    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="preset-dropdown" ngbDropdownMenu>
      <span class="dropdown-item" [class.disabled]="earliestDate && earliestDate > preset.end" [class.active]="selectedPreset === preset.label" *ngFor='let preset of presets' (click)='selectPreset(preset.label)'>
        {{preset.label}}
      </span>
    </div>
  </div>
</div>
