import { Pipe, PipeTransform } from '@angular/core';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { shouldShowLookbackOption } from 'src/app/core/helpers/other';

@Pipe({
  name: 'controlbarShouldShow',
})
export class ControlbarShouldShowPipe implements PipeTransform {
  transform(part, currentComponent, additionalParam?): any {
    switch (part) {
        case 'conversionEvents':
            return [ComponentNames.dashboard, ComponentNames.paths,  ComponentNames.timeframe].includes(currentComponent);
        case 'propertiesTab':
            return [ComponentNames.dashboard, ComponentNames.paths].includes(currentComponent);
        case 'attrModelSelectorIsNotOnLeft':
            return [ComponentNames.dashboard].includes(currentComponent);
        case 'aggregationPeriod':
            return [ComponentNames.channelPerformance, ComponentNames.firstTimePurchasers, ComponentNames.cohort, ComponentNames.timeframe].includes(currentComponent);
        case 'attrModel':
            return ![ComponentNames.paths, ComponentNames.dashboardBuilder].includes(currentComponent);
        case 'projectOptions':
            return [ComponentNames.dashboard, ComponentNames.cohort, ComponentNames.channelPerformance, ComponentNames.firstTimePurchasers, ComponentNames.timeframe, ComponentNames.paths].includes(currentComponent);
        case 'attributionType':
            return ![ComponentNames.dashboardBuilder].includes(currentComponent);
        case 'lookbackOption':
            if (additionalParam?.project?.options) {
                return shouldShowLookbackOption(additionalParam?.project?.options);
            }
            return false;
        case 'revenueGroups':
            return additionalParam?.project?.options?.features?.revenue_groups == true;
        case 'views':
            return ![ComponentNames.paths].includes(currentComponent);
        default:
            return false;
    }
  }
}