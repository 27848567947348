<div class="container empty-dialog-wrapper">
  <div class="modal-header d-flex justify-content-between">
    <h3 class="modal-title">Please Complete Your Setup</h3>
    <div>
      <i class="fa-solid fa-xmark clickable" ngbTooltip="Hide dialog" (click)="close()"></i>
    </div>
  </div>
  <div class="modal-body">
    <p>To get visitor data on your dashboard you will first need to install Attribution App on your webpages.</p>
    <p>For instructions on installing Attribution app please see the links below:</p>
      <ol>
        <li><a href="https://help.attributionapp.com/docs/a-simple-attribution-install" target="_blank">Instructions on installing </a></li>
        <li><a href="https://docs.attributionapp.com/" target="_blank">Developer docs</a></li>
      </ol>
    <p>After installation it will take roughly 15 minutes for data to appear on your dashboard.</p>
    <p>Once that is complete you can connect your ad platforms by going to <a class="clickable blue" (click)="goToAds()">Settings -> Ad Integrations</a>.</p>
  </div>
  <div class="modal-footer space-between">
    <div>
      <a href="https://help.attributionapp.com/" target="_blank">Learn More</a>
    </div>
    <div>
      <a class="btn btn-success white" (click)="goToSettings()">
        Go To Settings
      </a>
    </div>
  </div>
</div>
