import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { Path } from 'src/app/core/models/path.model';
import { Project } from 'src/app/core/models/project.model';
import { PathsLabelsChanged, SetCombineRecurring, SetPathFilterOrGroup, SetPathFilterType } from 'src/app/store/actions/paths.actions';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-paths-header',
  templateUrl: './paths-header.component.html',
  styleUrls: ['../paths.component.scss', './paths-header.component.scss']
})

export class PathsHeaderComponent {
  @Input() labels: string;
  @Input() isAggregated: boolean;
  @Input() paths: [Path];
  @Input() count: number;
  @Input() filterType: string;
  @Input() project: Project;
  @Input() filterOrGroup: {
    id: number,
    type: string,
    name: string,
  } | null;
  @Input() isCombineRecurring: boolean = false;
  @Output() downloadCSV: EventEmitter<boolean> = new EventEmitter<boolean>();

  envName = environment.envName;

  hideSelect = true;
  componentNames = ComponentNames;
  filterTypes = [
    {id: 'none', label: 'None'},
    {id: 'begins_with', label: 'Begins with'},
    {id: 'ends_with', label: 'Ends with'},
    {id: 'contains', label: 'Contains'},
    {id: 'does_not_contain', label: 'Does not contain'},
  ];

  constructor(
    private store: Store
  ) { }

  channelOrFilterSelected(labels) {
    this.hideSelect = !this.hideSelect;
    this.labels = labels;
    this.store.dispatch(new PathsLabelsChanged(labels));
  }

  downloadCSVClicked() {
    this.downloadCSV.emit(true);
  }

  toggleCombineRecurring() {
    this.store.dispatch(new SetCombineRecurring(!this.isCombineRecurring));
  }

  chooseFilterType(filterType) {
    this.store.dispatch(new SetPathFilterType(filterType.id));
  }

  chooseFilterOrGroup(filterOrGroup) {
    if (filterOrGroup?.item) {
      this.store.dispatch(new SetPathFilterOrGroup({
        id: filterOrGroup.item?.id,
        type: filterOrGroup.item?.t,
        name: filterOrGroup.item?.n,
      }));
    } else if (filterOrGroup?.id) {
      this.store.dispatch(new SetPathFilterOrGroup({
        id: filterOrGroup.id,
        type: filterOrGroup.type,
        name: filterOrGroup.name,
      }));
    } else {
      this.store.dispatch(new SetPathFilterOrGroup(null));
    }
  }
}
