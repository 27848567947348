<div class="modal-header">
  <h4 class="modal-title">Confirm Your Upload</h4>
  <p class="mt-3 mb-0" *ngIf="type == 'regulations'"> {{ resp.length }} user's information will be deleted.</p>
</div>
<div class="modal-body" [ngSwitch]="type">
  <div *ngSwitchCase="'amounts'">
    <div class="text-center" *ngIf="errorMessage">
      <small class="red">{{ errorMessage }}</small>
    </div>
    <table class="table amount_table" *ngIf="resp?.amounts.length > 0">
      <thead>
        <tr>
          <th>Amount</th>
          <th>Date</th>
        </tr>
      </thead>
      <tr *ngFor="let amount of resp?.amounts">
        <td>
          {{ amount.value | projectCurrency }}
        </td>
        <td>
          {{ amount.date | formattedMoment: 'L/d'}}
        </td>
      </tr>
    </table>
    <div class="alert alert-warning" *ngIf="resp?.amounts.length === 0">
      <p>Couldn't detect any spendings in your upload.</p>
    </div>
  </div>
  <div *ngSwitchCase="'regulations'" class="regulations">
    <div class="text-center" *ngIf="errorMessage">
      <small class="red">{{ errorMessage }}</small>
    </div>
    <table class="table amount_table" *ngIf="resp?.length > 0">
      <thead>
        <tr>
          <th>User ID</th>
        </tr>
      </thead>
      <tr *ngFor="let userId of resp">
        <td>
          {{ userId }}
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" (click)="submit()" *ngIf="!errorMessage">
    <span *ngIf="!inProgress; else waiting">Confirm</span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
  <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">Cancel</button>
</div>