<div class="emptys-sidebar-wrapper" [ngSwitch]="openedSidebar">
  <div *ngSwitchCase="sidebarNames.amounts">
    <h1><i class="fa-solid fa-clock-rotate-left green"></i></h1>
    <h2>No spend yet</h2>
    <h5>We pull spend data from Google and Facebook at midnight each night. Enter an amount and time range below to manually track spend.</h5>
  </div>
  <div *ngSwitchCase="sidebarNames.visits">
    <h1><i class="fa-solid fa-users green"></i></h1>
    <h2>Nobody here yet</h2>
    <h5>Check to make sure that your Javascript events are firing and your traffic filter matches the exact param or referrer shown on the Channel Builder.</h5>
    <a [routerLink]="[currentIdentifier, componentNames.dashboardBuilder]" class="btn btn-lg btn-default spacer caps">Channel Builder</a>
  </div>
  <div *ngSwitchCase="sidebarNames.overlaps">
    <h1><i class="fa-solid fa-users green"></i></h1>
    <h2>No Overlaps</h2>
  </div>
  <div *ngSwitchCase="sidebarNames.revenues">
    <h1><i class="fa-solid fa-credit-card green"></i></h1>
    <h2>No Revenue yet</h2>
    <h5>If you think there should be revenue here, check to make sure that your Javascript track events are properly recording revenue.</h5>
    <a href="https://docs.attributionapp.com/docs/track#special-properties-for-track-revenue" class="btn btn-lg btn-default spacer caps">Revenue Docs</a>
  </div>
  <div *ngSwitchCase="sidebarNames.users">
    <h1><i class="fa-solid fa-users green"></i></h1>
    <h2>Nothing here yet</h2>
    <h5>Check to make sure that you have identified your customers and they have email trait set.</h5>
    <a href="https://docs.attributionapp.com/docs/identify" class="btn btn-lg btn-default spacer caps">Identity Docs</a>
  </div>
  <div *ngSwitchCase="sidebarNames.companies">
    <h1><i class="fa-solid fa-users green"></i></h1>
    <h2>Nothing here yet</h2>
    <h5>Check to make sure that you have identified your customers with <i>company_id</i> trait set .</h5>
    <a href="https://docs.attributionapp.com/docs/identify#special-properties-for-identify" class="btn btn-lg btn-default spacer caps">Identity Docs</a>
  </div>
</div>