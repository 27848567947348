<div [class.prod]="isProd">
  <app-header></app-header>
  <app-control-bar *ngIf="shouldControlbarShow"></app-control-bar>
  <div class="app-wrapper" [class.only-header]="!shouldControlbarShow">
    <div id="app_pane" [ngStyle]="appPaneStyle" [class.only-header]="!shouldControlbarShow" (scroll)="appPaneOnScroll($event)">
      <router-outlet></router-outlet>
    </div>
    <div id='sidebar' class="sidebar" [class.only-header]="!shouldControlbarShow" *ngIf="sidebar && (currentComponent == componentNames.dashboard || currentComponent === componentNames.paths || currentComponent === componentNames.dashboardBuilder || currentComponent === componentNames.timeframe)" [ngStyle]="sidebarStyle"  [@SlideInOutAnimationRTL]>
      <div class="sidebar-header--fixed">
        <div>
          <a *ngIf="
            (
              (sidebar?.openedSidebar === sidebarNames.visitors)
              || (sidebar?.openedSidebar === sidebarNames.companies && sidebar?.options?.id && currentComponent != null)
            )
            && currentComponent !== componentNames.paths" (click)="sidebarGoBack()" class="sidebar-back">
            <i class="fa-solid fa-circle-chevron-left"></i>
          </a>
        </div>
        <div class="f-grow-2">
          <span class="sidebar-title">
            {{ scrolledLowerThenTitle? sidebarName : null }}
          </span>
        </div>
        <div>
          <a class="sidebar-close" (click)="closeSidebar()">
            <i class="fa-solid fa-circle-xmark"></i>
          </a>
        </div>
      </div>
      <div id="sidebar-content" class='sidebar-content' *ngIf="sidebar?.openedSidebar" [ngSwitch]="sidebar?.openedSidebar" (scroll)="onSidebarScroll($event)">
        <app-channel-builder-sidebar
          *ngSwitchCase="sidebarNames.dashboardBuilder"
          [sidebar]="sidebar"></app-channel-builder-sidebar>
        <app-amounts-sidebar
          *ngSwitchCase="sidebarNames.amounts"
          [sidebar]="sidebar"></app-amounts-sidebar>
        <app-visits-sidebar
          *ngSwitchCase="sidebarNames.visits"
          [sidebar]="sidebar"
          [project]="project"></app-visits-sidebar>
        <app-overlaps-sidebar
          *ngSwitchCase="sidebarNames.overlaps"
          [sidebar]="sidebar"
          [project]="project"></app-overlaps-sidebar>
        <app-revenues-sidebar
          *ngSwitchCase="sidebarNames.revenues"
          [sidebar]="sidebar"
          [project]="project"></app-revenues-sidebar>
        <app-users-sidebar
          *ngSwitchCase="sidebarNames.users"
          [sidebar]="sidebar"
          [project]="project"></app-users-sidebar>
        <ng-container
          *ngSwitchCase="sidebarNames.companies">
          <app-company-sidebar
            *ngIf="sidebar?.options?.id; else companiesSidebar"
            [sidebar]="sidebar"
            [project]="project"></app-company-sidebar>
          <ng-template #companiesSidebar>
            <app-companies-sidebar
            [sidebar]="sidebar"
            [project]="project"></app-companies-sidebar>
          </ng-template>
        </ng-container>
        <app-visitor-history
          *ngSwitchCase="sidebarNames.visitors"
          [sidebar]="sidebar"
          [project]="project"></app-visitor-history>
      </div>
      <div class='resizer' (mousedown)="startResizing()"></div>
    </div>
    <app-tooltip
    [project]="project"
    [view]="view"></app-tooltip>
  </div>
</div>
