import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectApiTokenService {

  constructor(private httpClient: HttpClient) { }

  get() {
    const Url = `${environment.clientApiUrl}/v2/project/api_key`;

    return this.httpClient.get(Url);
  }

}
