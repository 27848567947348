import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarStateService {
  sidebarState: any = {};
  scrollPosition = 0;
  wentBack = false;

  constructor(
  ) { }

  setState(state) {
    this.sidebarState = state;
  }

  setScrollPosition(position) {
    this.scrollPosition = position;
  }

  setWentBack(boolean) {
    this.wentBack = boolean;
  }
}
