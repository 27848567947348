import { ConversionEvent } from 'src/app/core/models/conversionEvent.model';

export class SetConversionEvents {
    static readonly type = '[conversionEvents] SetConversionEvents';
    constructor(public payload: ConversionEvent[]) {}
}

export class SetRevenueEvents {
    static readonly type = '[conversionEvents] SetRevenueEvents';
    constructor(public payload: ConversionEvent[]) {}
}

export class ClearConversionEvents {
    static readonly type = '[conversionEvents] ClearConversionEvents';
}