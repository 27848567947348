export class SetEventPropertyKey {
  static readonly type = '[properties] SetEventPropertyKey';
  constructor(public payload: {key: string, shouldUpdateView?: boolean }) {}
}

export class SetEventPropertyValue {
  static readonly type = '[properties] SetEventPropertyValue';
  constructor(public payload: {value: string, shouldUpdateView?: boolean }) {}
}

export class SetKnownProperties {
  static readonly type = '[properties] SetKnownProperties';
  constructor(public payload: string[]) {}
}

export class SetKnownPropertyValues {
  static readonly type = '[properties] SetKnownPropertyValues';
  constructor(public payload: string[]) {}
}

export class SetKeysAreLoading {
  static readonly type = '[properties] SetKeysAreLoading';
}

export class SetValuesAreLoading {
  static readonly type = '[properties] SetValuesAreLoading';
}

export class ClearProperties {
  static readonly type = '[properties] ClearProperties';
}
