<div class='conversion-events-wrapper' [class.in-traffic-options]="isCutoff">
  <small *ngIf="isCutoff" class="controllbar__hint top">Conversion Event</small>
  <div data-cy="conversion-event-selector" class='btn btn-default btn-select {{ btnClass }}' [class.hide]="!hideSelect" #btn (click)="openSelect(btn,select)">
    <i class="fa-solid fa-tag fa--header"></i>
    {{selectedEvent? selectedEvent.name : 'Select event...'}}
    <span class='caret'></span>
  </div>
  <ng-select #select class='ng-form-control'
    [class.ng-select-hide]='hideSelect'
    [items]="conversionEvents"
    bindLabel="name"
    placeholder="Conversion Event"
    (close)="closeSelect()"
    [clearable]="false"
    [disabled]="disabled"
    [(ngModel)]="selectedEvent"
    (ngModelChange)="selectEvent()">
    <ng-template ng-option-tmp let-item="item">
      <div *ngIf="item.name === 'divider'; else showItem" class="ng-select-firstToShow-divider"></div>
      <ng-template #showItem>
        <div class="d-flex justify-content-between">
          <div>
            <i *ngIf='item.revenue' class="fa-solid fa-dollar-sign"></i>
            <span [class.left-padded]="!item.revenue" data-cy="conversion-event-selector-item"> {{ item.name }}</span>
          </div>
          <div class="ms-5" hover-class="hovered" [class.show-on-hover]="!isInFavorites(item)">
            <div class="hide-in-hovered">
              @if (isInFavorites(item)) {
                <i class="fa-solid fa-star medium-dark"></i>
              } @else {
                <i class="fa-regular fa-star"></i>
              }
            </div>
            <div class="show-in-hovered" (click)="toggleFavorite(item, $event)">
              <i class="fa-solid fa-star-half-stroke"></i>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </ng-select>
</div>
