<div class="attribution-models-wrapper">
  <div class="dropdown" ngbDropdown>
    <button #attrModelDropdown class="btn btn-default dropdown-toggle btn-select" type="button" id="dropdownMenuButton" [attr.data-boundary]="attrModelDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
      <i class="fa-solid fa-chart-pie fa--header"></i>
      {{selectedAttributionModel? selectedAttributionModel.label : "Attribution Model"}}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
      <span class="dropdown-item" [class.disabled]="!isAvailable(attrModel) && isProd" *ngFor="let attrModel of attributionModels" (click)="selectModel(attrModel)">
        <i class="fa-solid fa-lock red me-1" *ngIf="!isAvailable(attrModel) && !isProd"></i>
        {{ attrModel.label }}
      </span>
      <div *ngIf="PBR">
        <hr>
        <span class="dropdown-item disabled">
          Position Based Ratios: {{ PBR }}
        </span>
      </div>
    </div>
  </div>
</div>
