import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AccountService } from '../services/account/account.service';
import { Account } from '../models/account.model';

@Injectable()
export class OnboardingGuard  {

  constructor(
    private store: Store,
    private router: Router,
    private accountService: AccountService,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (routerState.url.startsWith('/login?otp_token=')) {
      return true;
    }

    const accountState: Account = this.store.selectSnapshot(state => state.account);

    if (localStorage.getItem('token') && !accountState.id) {
      this.accountService.getAccountInfo().subscribe(accountState => {
        this.onboardingFinished(accountState);
      });
    } else {
      this.onboardingFinished(accountState);
    }

    return true;
  }

  onboardingFinished(accountState) {
    if (
      accountState.registration_step != null &&
      typeof (accountState.registration_step) != 'undefined' &&
      !(accountState.registration_step == 'finished' || accountState.last_registration_step == 0)
    ) {
      this.router.navigate(['onboarding']);
    }
  }
}
