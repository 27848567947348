<div class="sidebar-title">
  <h4 class="text-center">
    <span class="medium" *ngIf="!inProgress && !isEmpty">Showing </span><span *ngIf="name === 'raw'" class="medium">raw </span><span class="title-in-sidebar">{{ title }}</span>
    <span class="medium" *ngIf="name && name !== 'raw'"> from </span>
    {{ name === 'raw' ? null : name }}
    <div *ngIf="title === 'conversions' && properties.event_property_key !== null && properties.event_property_value !== null ">
      <span class="medium">with</span>
      {{ properties.event_property_key }} <span class="medium">=</span> {{ properties.event_property_value }}
    </div>
    <div class="small" *ngIf="periodStart && periodEnd">
      @if (periodStart === periodEnd) {
        for {{ periodStart | date:'MMM d, y' }}
      } @else {
        from {{ periodStart | date:'MMM d, y' }} to {{ periodEnd | date:'MMM d, y' }}
      }
    </div>
  </h4>
  <div *ngIf="virtualFilters.length > 0" class="text-center">
    <span class="small medium-dark">Groups:</span>
    <span *ngFor="let vFilter of virtualFilters" class="badge text-bg-primary ms-1 me-1">
      {{ vFilter }}
    </span>
  </div>
  <div *ngIf="!isVirtual && uniqueVisitors && (type == 'f' || name == 'Raw Traffic')" class='sidebar-subtitle'>
    {{ uniqueVisitors | integer }} unique visitors
  </div>
</div>
