<app-empty-sidebar
  *ngIf="!inProgress && isEmpty"
  [openedSidebar]="sidebar?.openedSidebar"></app-empty-sidebar>
<app-sidebar-title
  [title]="'spending'"
  [name]="filter?.n"
  [inProgress]="inProgress"
  [filterVfilters]="filter?.vf"
  [isEmpty]="isEmpty"
  [isVirtual]="isVirtual"
  [filterTree]="filterTree"
  [periodStart]="sidebar?.options?.periodStart"
  [periodEnd]="sidebar?.options?.periodEnd"></app-sidebar-title>
<div class="new-amount-wrapper" *ngIf="selected.filterType === 'f' && !isVirtual && filter?.i == null">
  <div class='new-amount-title'>
    Add spending
  </div>
  <div class="row">
    <div class="col-md-3 mb-3">
      <div class="input-group input-group-lg">
        <span class="input-group-text" id="amount-currency">{{currency}}</span>
        <input numeric numericType="decimal" ngModel name="newAmount" #newAmount="ngModel" type="text" value="{{ newSpend.amount | number: '1.2' }}" class="form-control" id="newAmount" placeholder="0.00" aria-describedby="amount-currency" (change)="amountChanged(newAmount)">
      </div>
    </div>
    <div class="col-md-8 mb-3">
      <app-ngb-datepicker
        class="little"
        [notMain]="true"
        (datesChosen)="datesSelected($event)"
        [allowFutureDates]="true"></app-ngb-datepicker>
    </div>
    <div class="col-md-1 mb-3">
      <button [class.disabled]="newSpend.amount == null" class="btn btn-link btn-xs btn-add" (click)="addNewSpend()">
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
  </div>
  <form class="form">
    <div class="form-group text-center">
      <label for="csvFile" >
        <span class="btn btn-default cursor-pointer">
          <i class="fa-solid fa-upload blue"></i>
          Upload CSV
        </span>
      </label>
      <p class="help-block">
        <ng-template #tooltipContent>
          <div>
            You can upload CSV with your spendings amounts, file must contain <em>Date</em> and <em>Amount</em> columns, additionally LinkedIn exports supported.
          </div>
        </ng-template>
        <i class="fa-solid fa-circle-info" [ngbTooltip]="tooltipContent"></i><small>
        <a href="{{ assetPath }}/assets/amounts-sample.csv" target="_blank"> Sample file</a></small></p>
    </div>
    <input data-file name="csvFile" type="file" id="csvFile" style="position: fixed; top: -100em;" ng-model="csvFile" (change)="onUpload($event.target.files)">
  </form>
  <div class="text-center mb-3">
    <div *ngIf="uploadError">
      <small class="red">{{ uploadError }}</small>
    </div>
    <small class="medium">Adding or removing amounts manually could take up to 25 minutes to reflect in dashboard report.</small>
  </div>
</div>
<div *ngIf="!isEmpty" class="amounts-table-wrapper" [class.mt-50px]="selected.filterType !== 'f'">
  <table *ngIf="amounts.length" class="table sidebar-table">
    <thead>
      <tr>
        <th>Amount</th>
        <th>Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let amount of amounts">
        <td>{{ amount.value | projectCurrency }}</td>
        <td>{{ formatDate(amount) }}</td>
        <td>
          <button *ngIf="amount.id" class="btn btn-link btn-xs btn-remove" (click)="removeSpend(amount)">
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="inProgress" class="loader-sm">
    <app-loader
      *ngIf="inProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
