import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AmountService } from 'src/app/core/services/amount/amount.service';
import { RegulationService } from 'src/app/core/services/regulations/regulation.service';

@Component({
  selector: 'app-csv-confirmation',
  templateUrl: './csv-confirmation.component.html',
  styleUrls: ['./csv-confirmation.component.scss']
})
export class CsvConfirmationComponent implements OnInit {
  @Input() resp;
  @Input() type:String = 'amounts';
  @Input() apiToken:String;

  inProgress;
  assetPath = environment.assetPath;
  errorMessage;

  constructor(
    public activeModal: NgbActiveModal,
    private amountService: AmountService,
    private regulationService: RegulationService
    ) { }

  ngOnInit() {}

  submit() {
    if (this.type == 'amounts') {
      if (this.inProgress) { return; }
      this.errorMessage = null;
      this.inProgress = true;
      this.amountService.uploadCSV(true, this.resp.filter, null, this.resp.formData).subscribe( () => {
        this.inProgress = false;
        this.activeModal.close('needAmountsRefresh');
      }, error => {
        this.inProgress = false;
        this.errorMessage = "Failed to Upload."
      });
    } else if (this.type == 'regulations') {
      if (this.inProgress) { return; }
      this.errorMessage = null;
      this.inProgress = true;
      this.regulationService.createGDPRdeletion(this.resp, this.apiToken).subscribe( (x:any) => {
        this.inProgress = false;
        this.activeModal.close({result: 'confirmed_user_ids', regulation_id: x.regulate_id});
      }, error => {
        this.inProgress = false;
        this.errorMessage = "Failed to Upload."
      });
    }

  }

}
