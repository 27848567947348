import { Pipe, PipeTransform } from '@angular/core';
import { isPageview } from 'src/app/core/helpers/other';

@Pipe({
  name: 'liveDebugCalculateType',
})
export class LiveDebugCalculateTypePipe implements PipeTransform {
    transform(item): any {
        if (item.value.type) {
            return item.value.type;
        } else if (isPageview(item.value.event)) {
            return 'page';
        } else if (item.value.traits) {
            return 'identify';
        } else if (item.value.event) {
            return 'track';
        } else {
            return 'message';
        }
    }
}