<div class="attr-card mt-4">
  <div class="exclude-filters">
    <div class="row mb-4">
      <div class="col-9">
        <p class="title-no-bold" *ngIf="showTitle">Exclude Certain Filters</p>
      </div>
      <div class="col-3 d-flex justify-content-end">
        <app-toggle-switch
          [isOn]="project?.settings?.exclude_filters_enabled"
          (eventEmitter)="toggleExcludeFilters($event)"></app-toggle-switch>
      </div>
    </div>
  
    <p class="medium-dark">Select the filters below that you wish to exclude from your attribution model. Excluded filters will still appear on your dashboard, but will not be assigned any credit. Only select filters that are not part of your marketing efforts that you wish to ignore (confirmation emails, transactional emails, etc.).</p>
    <div class="position-relative">
      <app-filter-search
        (filterSelected)="excludeFilter($event)"
        [isSettings]="true"
        [isVFilterModal]="true"
        [shouldAllowSpecials]="true"
        [excludedFilters]="excludedFilters"
      ></app-filter-search>
    </div>
    <div class="added-row" *ngFor="let item of excludedFilters">
      <i class="fa-solid fa-circle-xmark white cursor-pointer me-1" (click)="unexcludeFilter(item)"></i>
      <span *ngIf="item?.pathArray?.length > 0; else withoutPath" ngbTooltip="{{item?.pathArray?.join(' > ') +' > '+ item.n }}">{{ item.n }}</span>
      <ng-template #withoutPath>
        {{ item.n }}
      </ng-template>
    </div>
  </div>
</div>