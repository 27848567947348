import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { SetUsageReport } from 'src/app/store/actions/settings.actions';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsageService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  get() {
    const Url = `${environment.reportingApiUrl}/usage/status`;

    return this.httpClient.post(Url, {});
  }

  getReport() {

    const Url = `${environment.clientApiUrl}/v2/usage`;

    return this.httpClient.post(Url, {}).pipe(map((resp) => {
      return resp;
    }));
  }
}
