<app-empty-sidebar
  *ngIf="!inProgress && overlaps.length == 0"
  [openedSidebar]="sidebar?.openedSidebar"></app-empty-sidebar>
<div *ngIf="overlaps.length > 0 || inProgress">
  <app-sidebar-title
  [title]="'overlaps'"
  [name]="filter?.n"
  [type]="selected.filterType"
  [filterId]="filter?.id"
  [inProgress]="inProgress"
  [filterVfilters]="filter?.vf"
  [isEmpty]="isEmpty"
  [isVirtual]="isVirtual"
  [filterTree]="filterTree"></app-sidebar-title>
  <div *ngFor="let overlap of overlaps" class='overlaps-table-wrapper'>
    <table class="table sidebar-table">
      <thead>
        <tr>
          <th>{{ overlap.name }}</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let visitor of overlap.overlap" class="sidebar-tr-link" (click)="showVisitor(visitor.id)">
          <td>
            <a class="blue">{{ visitor | nameForUser }}</a><br/>
            <span *ngIf="visitor.city || visitor.country; else noCityOrCountry" class="small muted">{{ visitor.city }}, {{ visitor.country }}</span>
            <span class="d-block small muted">{{ visitor.ip && IPLocations[visitor.ip] ? (IPLocations[visitor.ip] | commaLocation) : ' ' }}</span>
          </td>
          <td class="text-right no-w-wrap">
            {{ visitor.time | formattedMoment:'DD' }}<br/>
            <span class="muted" ngbTooltip="{{visitor.time}}" container="body" placement="left">{{ visitor.time | formattedMoment:'t'}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="inProgress" class="loader-sm">
    <app-loader
      *ngIf="inProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
