const tableau20 = ['#1F77B4', '#AEC7E8', '#FF7F0E', '#FFBB78', '#2CA02C', '#98DF8A', '#D62728', '#FF9896', '#9467BD', '#C5B0D5', '#8C564B', '#C49C94', '#E377C2', '#F7B6D2', '#7F7F7F', '#C7C7C7', '#BCBD22', '#DBDB8D', '#17BECF', '#9EDAE5'];

const tableau10 = ['#1F77B4', '#FF7F0E', '#2CA02C', '#D62728', '#9467BD', '#8C564B', '#E377C2', '#7F7F7F', '#BCBD22', '#17BECF'];

const customPallete = ['#1F77B4', '#FF7F0E', '#2CA02C', '#D62728', '#9467BD', '#8C564B', '#E377C2', '#7F7F7F', '#BCBD22', '#17BECF', '#6d5ee3', '#3bc1de', '#f032e6', '#911eb4', '#800000', '#469990'];

const specificColors = [
  {name: 'filter', color: '#999'},
  {name: 'channel', color: '#999'},
  {name: 'direct', color: '#999'},
  {name: 'unsegmented', color: '#665'},
  {name: 'unattributed', color: '#333'},
  {name: 'organic', color: '#e87d97'},
  {name: 'google', color: '#F4B400'},
  {name: 'twitter', color: '#1DA1F2'},
  {name: 'adroll', color: '#2C9FDD'},
  {name: 'linkedin', color: '#0077B5'},
  {name: 'facebook', color: '#1877F2'},
  {name: 'quora', color: '#B92B27'},
  {name: 'outbrain', color: '#F08421'},
  {name: 'bing', color: '#008373'},
  {name: 'youtube', color: '#FF0000'}
];

const enum chartColors {
  green = '#5BC28B',
  red = '#bd6d65',
  blue = '#377eb8'
};

export { tableau10, tableau20, customPallete, specificColors, chartColors };
