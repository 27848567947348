import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { BaseService } from '../base/base.service';
import {
  SetChannelPerformanceReport,
  SetChannelPerformanceInProgress,
  SetChannelPerformanceSummary,
  SetChannelPerformanceSummaryInProgress, SetChannelPerformanceComparisonInProgress, SetChannelPerformanceComparison
} from 'src/app/store/actions/channel-performance.actions';
import { DataDownloaderService } from '../data-downloader/data-downloader.service';

@Injectable({
  providedIn: 'root'
})
export class ChannelPerformanceService {
  cpSubscription: Subscription;
  summarySubscription: Subscription;
  comparisonSubscription: Subscription;

  constructor(private store: Store, private baseService: BaseService, private toastr: ToastrService, private dataDownloader: DataDownloaderService) {}

  get(additionalOptions) {

    const ReportingParams = this.baseService.getReportingParams;
    const ChannelPerformanceParams = this.baseService.getChannelPerformanceParams;

    this.store.dispatch(new SetChannelPerformanceInProgress());

    const Params = {...ReportingParams, ...ChannelPerformanceParams, ...additionalOptions};

    return this.cpSubscription = this.dataDownloader.requestReport({...Params, report: 'channel_performance'}).subscribe( (resp: any) => {
      if (resp.meta.status === 'ready') {
        this.store.dispatch(new SetChannelPerformanceReport({
          meta: resp.meta,
          report: resp.report?.other?.channel_performance
        }));
        this.baseService.trackReportLoaded('Channel performance', Params);
      } else {
        setTimeout(() =>
          this.toastr.error(
            'Failed to fetch Channel Performance Data from the server.',
            `status: ${status}`,
            {disableTimeOut: true}
          )
        );
      }
      this.cpSubscription = null;
    });
  }

  getSummary(additionalOptions?) {
    const ReportingParams = this.baseService.getReportingParams;
    const ChannelPerformanceParams = this.baseService.getChannelPerformanceParams;

    const Params = {...ReportingParams, ...ChannelPerformanceParams, ...additionalOptions};

    if (additionalOptions) {
      this.store.dispatch(new SetChannelPerformanceSummaryInProgress({isSpecific: true}));
    } else {
      this.store.dispatch(new SetChannelPerformanceSummaryInProgress({isSpecific: false}));
    }

    return this.summarySubscription = this.dataDownloader.requestReport({...Params, report: 'channel_performance_summary'}).subscribe( (resp: any) => {
      if (resp.meta.status === 'ready') {
        if (additionalOptions && additionalOptions.filter_group_id) {
          this.store.dispatch(new SetChannelPerformanceSummary({ channel: additionalOptions.filter_group_id, data: {
            meta: resp.meta,
            report: resp.report?.other?.summary
          }}));
        } else {
          this.store.dispatch(new SetChannelPerformanceSummary({report: resp.report?.other?.summary}));
        }
      } else {
        setTimeout(() =>
          this.toastr.error(
            `Failed to fetch Channel Performance summary from the server. Please <a href="${this.baseService.currentPath}">reload</a>.`,
            `status: ${status}`,
            {disableTimeOut: true, enableHtml: true}
          )
        );
        throw new Error('Failed to load Channel Performance report');
      }
      this.summarySubscription = null;
    });
  }

  getComparison(specificEvent?) {
    const ReportingParams = this.baseService.getReportingParams;
    const ChannelPerformanceParams = this.baseService.getChannelPerformanceParams;

    const Params = {...ReportingParams, ...ChannelPerformanceParams};

    if (specificEvent) {
      Params.conversion_event_name = specificEvent;
    }

    this.store.dispatch(new SetChannelPerformanceComparisonInProgress());

    return this.comparisonSubscription = this.dataDownloader.requestReport({...Params, report: 'report_comparison'}).subscribe( (resp: any) => {
      this.store.dispatch(new SetChannelPerformanceComparison({
        meta: resp.meta,
        report: resp.report?.other?.comparison
      }));
      this.comparisonSubscription = null;
    });
  }

  unsubscribeAndCancelReportGeneration(reportType?) {
    if (reportType) {
      switch (reportType) {
        case 'channel_performance':
          if (this.cpSubscription) {
            this.cpSubscription.unsubscribe();
            this.cpSubscription = null;
          }
          break;
        case 'channel_performance_summary':
          if (this.summarySubscription) {
            this.summarySubscription.unsubscribe();
            this.summarySubscription = null;
          }
          break;
        case 'report_comparison':
          if (this.comparisonSubscription) {
            this.comparisonSubscription.unsubscribe();
            this.comparisonSubscription = null;
          }
          break;
        default:
          break;
      }
      if (this.cpSubscription) {
        this.dataDownloader.cancelReportGeneration(['channel_performance']);
      }
      if (this.summarySubscription) {
        this.dataDownloader.cancelReportGeneration(['channel_performance_summary']);
      }
      if (this.comparisonSubscription) {
        this.dataDownloader.cancelReportGeneration(['report_comparison']);
      }
    } else {
      if (this.cpSubscription) {
        this.dataDownloader.cancelReportGeneration(['channel_performance']);
      }
      if (this.summarySubscription) {
        this.dataDownloader.cancelReportGeneration(['channel_performance_summary']);
      }
      if (this.comparisonSubscription) {
        this.dataDownloader.cancelReportGeneration(['report_comparison']);
      }
      this.cpSubscription && this.cpSubscription.unsubscribe();
      this.cpSubscription = null;
      this.summarySubscription && this.summarySubscription.unsubscribe();
      this.summarySubscription = null;
      this.comparisonSubscription && this.comparisonSubscription.unsubscribe();
      this.comparisonSubscription = null;
      this.store.dispatch([
        new SetChannelPerformanceInProgress({isFalse: true}),
        new SetChannelPerformanceSummaryInProgress({isFalse: true}),
        new SetChannelPerformanceComparisonInProgress({isFalse: true})
      ]);
    }
  }
}
