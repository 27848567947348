<div class="container empty-dialog-wrapper">
  <div class="modal-header d-flex justify-content-between">
    <div class="modal-title">
      <h3 class="mb-0 mt-0">{{ type }} Integration Complete</h3>
    </div>
    <div>
      <i class="fa-solid fa-xmark clickable" ngbTooltip="Hide dialog" (click)="close()"></i>
    </div>
  </div>
  <div class="modal-body">
    <p>Attribution snippet has been installed on your {{ type }} Store it will track all your pages automatically, you don't need to do any manual setup. Visit data will start populating in your dashboard in 10 minutes.</p>
    <p>Once your customer makes a purchase Attribution will automatically fire <b>Order Paid</b> event.</p>
    <p>Your Customers names and emails are automatically identified once purchase is made.</p>
    <p>Meanwhile you can connect your <a [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.integrations]">Ad Integrations</a> to start track your Ad Channels and Spend.</p>
  </div>
  <div class="modal-footer space-between">
    <div>
      <a href="https://help.attributionapp.com/" target="_blank">Learn More</a>
    </div>
    <div>
      <a class="btn btn-success white" (click)="goToSettings()">Go To Settings</a>
    </div>
  </div>
</div>
