<div class="modal-header">
  <h4 class="modal-title">Which account?</h4>
</div>
<div class="modal-body">
  <div class="container">
    <div *ngFor="let account of accounts" class="account-select-item" [class.selected]="selectedAccount == account" (click)="selectAccount(account)">
      <strong [class.medium]="!account.sufficient_role">{{account.name}}</strong>
      <small *ngIf="account.description">({{account.description}})</small>
      <span class="help-inline" *ngIf="!account.sufficient_role && (integrationName == 'facebook3' || integrationName == 'facebook4')">
        <i>ADVERTISE</i> or <i>MANAGE</i> task permission required (this might be false-positive warning if you're Business Admin)
      </span>
      <span class="help-inline" *ngIf="!account.sufficient_role && isGoogle">Manager Account required</span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" [class.disabled]="accountNotSelected == true" (click)="finishSelecting()">
    <span *ngIf="!waitingForResponse; else waiting" class='caps'>
      Connect
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>
