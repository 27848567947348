<div *ngIf="!warningHidden" class="position-relative alert alert-warning pt-3">
  <div class="container-xl position-relative">
    Channel builder may take additional time to load depending on the amount of raw data you sent  in selected date range. Visits number is aproximate and may differ once filter is created. Please allow up to 10 minutes for data to reload after adjusting your filters.
    <div class="close-icon" placement="bottom-right" ngbTooltip="Hide this warning" (click)="hideWarning()">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </div>
</div>
<div class="container-xl builder">
  <div class="channel-builder-top">
    <div class="mb-2 d-flex align-items-center">
      <div class="switcher-wrapper mb-0">
        <div class="switcher-button" data-cy="manage-traffic-tab-selector" [class.active]="page == 'manage-traffic'" (click)="navigateTo('manage-traffic', true)">
          Manage Traffic
        </div>
        <div class="switcher-button" data-cy="manage-channels-tab-selector" [class.active]="page == 'manage-channels'" (click)="navigateTo('manage-channels', true)">
          Manage Channels
        </div>
      </div>
      <div *ngIf="page == 'manage-traffic'" class="medium ms-2 me-2">:</div>
      <div *ngIf="page == 'manage-traffic'" class="switcher-wrapper mb-0">
        <div class="switcher-button" [class.active]="section == 'params'" (click)="navigateTo('params')">
          Parameters
        </div>
        <div class="switcher-button" [class.active]="section == 'referrers'" (click)="navigateTo('referrers')">
          Referring Domains
        </div>
        <div class="switcher-button" [class.active]="section == 'paths'" (click)="navigateTo('paths')">
          Paths
        </div>
      </div>
    </div>
    <div *ngIf="page == 'manage-traffic'" class="d-flex align-items-center m-1">
      <div class="calendar-wrapper">
        <small class="controllbar__hint top" *ngIf="refreshedAt">Report generated {{ refreshedAt | timeAgo: { humanizeOpts: {units: ['mo', 'd', 'h', 'm'], delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</small>
        <app-ngb-datepicker></app-ngb-datepicker>
      </div>
    </div>
  </div>

  <ng-template #visitsTaggedSelect>
    <div class="d-flex justify-content-center align-items-center">
      <div class="me-3">
          Show visits tagged only with
      </div>
      <div>
        <div class='btn btn-default btn-select' [class.hide]="!hideSelect" #btn (click)="openSelect(btn,select)">
          {{paramKeyModel? (paramKeyModel.key || paramKeyModel.label) : 'Any'}}
          <span class='caret'></span>
        </div>
        <ng-select
          #select
          class='ng-form-control param-select'
          [class.ng-select-hide]='hideSelect'
          [items]="paramKeys"
          [addTag]="true"
          addTagText=""
          [groupBy]="'grouping'"
          bindLabel="key"
          placeholder="Any"
          [clearable]="false"
          (close)="closeSelect()"
          [(ngModel)]="paramKeyModel"
          (ngModelChange)="changeStateKey()">
          <ng-template ng-option-tmp let-item="item">
            <span> {{ item.label || item.key }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </ng-template>

  <ng-template #spinnerIcon>
    <i class="no-cursor fa-solid fa-fw fa-spinner fa-spin ms-3"></i>
  </ng-template>

  <div *ngIf="page == 'manage-traffic'">
    <div *ngIf="addingToChannel != true; else showAddingToChannel">
      <div class="channel-builder-header">
        <div class="switcher-wrapper">
          <div class="d-flex justify-content-center align-items-center">
            <div class="me-3">
              Selected Channel
              <ng-template #tipContent>Selecting anything but Unsegmented Traffic is for <b class="caps">debug purpose</b> <br> only and can significantly increase load times</ng-template>
              <i *ngIf="selectedSource?.special != 'unsegmented'" class="fa-solid fa-circle-exclamation fa-lg ms-2 red" [ngbTooltip]="tipContent"></i>
            </div>
            <div ngbDropdown>
              <button data-cy="db-source-selector" class="btn btn-default btn-select ms-0 mb-0 m-w-100 text-center" id="sourceDropdown" ngbDropdownToggle>{{selectedSource?.name || 'Select Channel'}}</button>
              <div ngbDropdownMenu aria-labelledby="sourceDropdown">
                @for (source of sourceList; track source) {
                  <button data-cy="db-source-selector-item" ngbDropdownItem (click)="selectSource(source)">{{source.name}}</button>
                }
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="section == 'params' && (!values || values.length == 0)" class="switcher-wrapper">
          <ng-container *ngTemplateOutlet="visitsTaggedSelect"></ng-container>
        </div>
        <div class="switcher-wrapper standalone-button" [class.standalone-button-green]="selectedIndexes.length > 0 && selectedSource?.special == 'unsegmented'" (click)="startAddingToChannel()">
          Add to Channel
          <i *ngIf="!addingInProgress; else spinnerIcon" class="fa-solid fa-circle-arrow-right fa-lg ms-3"></i>
        </div>
      </div>

      <div *ngIf="section == 'params' && values && values.length > 0" class="channel-builder-header start">
        <div class="switcher-wrapper">
          <ng-container *ngTemplateOutlet="visitsTaggedSelect"></ng-container>
        </div>
        <div class="switcher-wrapper ms-3">
          <div class="me-3">
            Selected params:
          </div>
          <div>
            @for (param of values; track param) {
              <button class="btn btn-default btn-md" (click)="removeParam(param)">
                <i class="fa-solid fa-circle-xmark blue"></i> {{ param.key }}={{ param.value }}
              </button>
            }
          </div>
        </div>
      </div>

      <div id="builder-table" class="builder__table" (window:resize)="onWinResize()">
        <app-loader *ngIf="busy" [position]="'relative'" [color]="'green'" [backgroundColor]="'white'"></app-loader>
        <div class="text-center" *ngIf="!busy && params.length === 0">
          <h1><i class="fa-regular fa-compass green"></i></h1>
          <h2>Nothing here</h2>
          <h4 class="medium-dark">There're no parameters left with this combination.<br>Try to combine other parameters.</h4>
        </div>
        <div *ngIf="!busy && params.length !== 0" class="cb-table">
          <div class="container-fluid">
            <div class="cb-table__header row">
              <div class="col-8 d-flex align-items-center">
                <i class="fa-solid fa-list-check me-3 clickable" ngbTooltip="{{toggleAllShouldSelect ? 'Select' : 'Unselect'}} All" (click)="toggleAll()"></i>
                <app-order-arrow
                    (whenClicked)="changeSort('Url Params')"
                    [label]="section == 'params' ? 'URL Parameters' : section == 'referrers' ? 'Referring Domain' : 'Path'"
                    [currentDirection]="directions.urlParamsDirection"></app-order-arrow>
              </div>
              <div class="col-4 text-right">
                <app-order-arrow
                    (whenClicked)="changeSort('Visits')"
                    [label]="'Visits'"
                    [currentDirection]="directions.visitsDirection"></app-order-arrow>
              </div>
            </div>
          </div>
          <div class="cb-table__body">
            <cdk-virtual-scroll-viewport *ngIf="!busy && params.length !== 0" [ngStyle]="{'height.px': scrollerHeight}" [itemSize]="50">
              <div class="container-fluid">
                <div *cdkVirtualFor="let param of params" class="row">
                  <div class="params col-9 clickable" (click)="toggleSelect(param)">
                    <span *ngIf="param.path != '/' && selectedSource?.special == 'unsegmented'" class="me-2">
                      <ng-template #alreadyAdded>
                        <i class="fa-solid fa-fw fa-check green me-2"></i>
                      </ng-template>
                      <i *ngIf="!isAddedAlready(param); else alreadyAdded" [ngClass]="{'fa-solid fa-square-check green': isSelected(param), 'fa-regular fa-square medium': !isSelected(param)}" class="fa-lg fa-fw clickable "></i>
                    </span>
                    <a *ngIf="section == 'params'; else notParam" (click)="addParam(param.key, param.value)">{{ param.combo }}</a>
                    <ng-template #notParam>{{ section == 'referrers' ? param.domain : param.path }}</ng-template>
                  </div>
                  <div class="visits col-3 text-right">
                    <a data-cy="db-sidebar-number" (click)="openSidebar(param)">
                      {{ param.visits | number }}
                    </a>
                  </div>
                </div>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    </div>

    <ng-template #showAddingToChannel>
      <div class="fix-to-top fix-long">
        <div class="row fix-wrapper">
          <div class="col-4">
            <div class="switcher-wrapper switcher-wrapper__transparent" (click)="this.addingToChannel = false">
              <div class="just-text clickable">
                <i class="fa-solid fa-chevron-left me-2"></i> Back
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="manage-channels__header switcher-wrapper switcher-wrapper__transparent d-flex flex-column">
              <div class="just-text hide-when-fixed bold mb-3">
                Select a Channel
              </div>
              <app-filter-search
              (filterSelected)="showSelectedFilter($event)"
              [onlySearch]="true"></app-filter-search>
              <small class="medium mt-1" *ngIf="whichChannel">
                New filter(s) will be created {{ whichChannel == 'on-top' ? 'on top level' : ('inside "' + whichChannel?.n+ '"') }}
              </small>
            </div>
          </div>
          <div class="col-4 justify-content-end">
            <div class="switcher-wrapper standalone-button position-relative" [class.standalone-button-green]="whichChannel != null" (click)="openNewChannelModal()">
              <i class="fa-solid fa-circle-plus fa-lg me-3"></i> Create New<br>Channel
            </div>
            <div class="separator">OR</div>
            <div class="switcher-wrapper standalone-button" [class.standalone-button-green]="whichChannel != null" (click)="addFilters()">
              Add to<br>Channel <i class="fa-solid fa-check-circle fa-lg ms-3"></i>
            </div>
          </div>
        </div>
      </div>
      <app-group-selector
        (groupSelector)="selectChannel($event)"></app-group-selector>
    </ng-template>
  </div>

  <div *ngIf="page == 'manage-channels'">
    <div class="manage-channels" *ngIf="!moving">
      <div class="fix-to-top">
        <div class="manage-channels__header mt-3 fix-wrapper">
          <div class="d-flex align-items-center">
            <div>
              <app-filter-search
                (filterSelected)="showSelectedFilter($event)"
                [onlySearch]="true"></app-filter-search>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <div class="switcher-wrapper standalone-button me-3 mb-0" [class.standalone-button-green]="allTheSelected.length < 2" (click)="openNewChannelModal(true)" ngbTooltip="Unselect all items or select only one channel under which you want to create new channel">
              <i class="fa-solid fa-plus fa-lg me-3"></i> Add Channel
            </div>
            <div class="switcher-wrapper standalone-button me-3 mb-0" [class.standalone-button-green]="allTheSelected.length > 0" (click)="openMoving()">
              <i class="fa-solid fa-up-down-left-right fa-lg me-3"></i> Move To
            </div>
            <div class="switcher-wrapper standalone-button me-3 mb-0 ps-4 pe-4" [class.standalone-button-red]="allTheSelected.length > 0" (click)="openConfirmDelete()">
              <i class="fa-solid fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
      <app-group-selector
        [isManageChannels]="true"
        (multipleSelector)="selectFiltersAndGroups($event)"
        [reset]="resetSelectedInGroupSelector.asObservable()"></app-group-selector>
    </div>

    <div *ngIf="moving">
      <div class="fix-to-top">
        <div class="manage-channels__header row fix-wrapper">
          <div class="col-4">
            <div class="switcher-wrapper switcher-wrapper__transparent" (click)="moving = false;  dropSelected();">
              <div class="just-text clickable">
                <i class="fa-solid fa-chevron-left me-2"></i> Back
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="switcher-wrapper switcher-wrapper__transparent justify-content-center d-flex flex-column">
              <div class="just-text hide-when-fixed bold mb-3">
                Select a Channel
              </div>
              <app-filter-search
              (filterSelected)="showSelectedFilter($event)"
              [onlySearch]="true"></app-filter-search>
            </div>
          </div>
          <div class="col-4 justify-content-end">
            <div class="switcher-wrapper standalone-button position-relative" [class.standalone-button-green]="movingToChannel != null" (click)="move()">
              <i class="fa-solid fa-fw fa-check fa-lg me-3"></i> Move
            </div>
          </div>
        </div>
      </div>

      <app-group-selector
        (groupSelector)="selectChannelForMoveTo($event)"
        [selectedGroupsForMove]="selectedGroups"></app-group-selector>
    </div>
  </div>

</div>
