<div class="attr-card mt-4">
  <p class="title-no-bold mb-4" *ngIf="showTitle">Currency Symbol</p>

  <p class="medium-dark">You can choose which currency symbol to display in dashboard. Please note it's just the symbol - it doesn't make any currency conversions for you, all amounts and revenues numbers are show as is.</p>
  <div class="currency-symbol-select">
    <ng-select #currencySymbolSelect class='ng-form-control'
      [clearable]="false"
      [addTag]="true"
      [items]="currencySymbols"
      [(ngModel)]="currencySymbol"
      (ngModelChange)="saveCurrencySymbol()"></ng-select>
  </div>

  <div *ngIf="currencyConversionEnabled || !isProd">
    <hr>
    <p class="title-no-bold mb-4" *ngIf="showTitle">Currency Conversion <i class="fa-solid fa-lock red me-1" *ngIf="!currencyConversionEnabled"></i></p>

    <p class="medium-dark">
      Attribution supports automatic currency conversion for selected scenarios, please check <a href="https://docs.attributionapp.com/docs/automatic-currency-conversion" target="_blank">documentation</a> before enabling this feature.
    </p>

    <span>Project base currency:</span>
    <div class="project-currency-select">
      <ng-select #projectCurrencySelect class='ng-form-control'
        [clearable]="false"
        [addTag]="true"
        [items]="currencies"
        [(ngModel)]="currency"
        (ngModelChange)="saveCurrency()"></ng-select>
    </div>
  </div>
</div>
