import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-timezone-setting',
  templateUrl: './timezone-setting.component.html',
  styleUrls: ['./timezone-setting.component.scss']
})
export class TimezoneSettingComponent implements OnChanges {
  @Input() project;
  @Input() integration;

  oauths;

  constructor() { }

  ngOnChanges(): void {
    if (this.project) {
      this.oauths = this.project.oauths.filter( x => {
        return x.type == this.integration;
       });
    }
  }

}
