import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { trim } from 'lodash-es';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { findInTree, returnAllParents } from 'src/app/core/helpers/find-in-tree';
import { Filter } from 'src/app/core/models/filter.model';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit, OnChanges {
  @Output() filterSelected = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() isSettings = false;
  @Input() isOnDashboard = false;
  @Input() isVFilterModal = false;
  @Input() onlySearch = false;
  @Input() selectOnlyFilters = false;
  @Input() shouldAllowSpecials = false;
  @Input() excludedFilters = [];
  @Input() selectedLabel;
  @Input() withoutVirtuals = false;

  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;

  AlreadySelected = [];

  q = '';

  foundFilter: any;
  filterTree;
  mutableFilterTreeForSelect = [];
  allTrafficItem = {id: 0, n: 'All Traffic', b: true, t: 'v'};
  filterTreeForSelect: any = [];
  projectsFullTreeEnabled = [2109, 2098];
  shouldShowSelect = true;
  waitingForAutoComplete = false;
  excludedIds = [];

  constructor(private filterService: FilterService, private store: Store, private FTWS: FilterTreeWorkerService) { }

  ngOnInit(): void {
    if (!this.isVFilterModal) this.filterTreeForSelect.push(this.allTrafficItem);
    this.filterTree$.subscribe( resp => {
      this.filterTree = resp.filterTree;
      this._resetFilterTrees();
      if (Array.isArray(resp.filterTree) && resp.filterTree.length > 0) {
        if (!this.selectOnlyFilters) {
          this.addTopChannels(resp);
        }
        this.addFilterTree(resp);
      }
      this.filterTreeForSelect = [
        ...this.filterTreeForSelect,
        ...this.mutableFilterTreeForSelect
      ];
    });
  }

  addTopChannels(resp) {
    this.mutableFilterTreeForSelect.push({n: 'Channels', t: 'label'});
    resp.filterTree.forEach( item => {
      if (!(this.isVFilterModal && item.s != null)) {
        this._addTopChannel(item);
      }
    });
  }

  addFilterTree(resp) {
    if (!this.selectOnlyFilters) {
      this.mutableFilterTreeForSelect.push({n: 'Filter Tree', t: 'label'});
    }
    resp.filterTree.forEach( item => {
      if (this.shouldAllowSpecials ? item.s != 'virtual' : !(this.isVFilterModal && item.s != null)) {
        this._generateFilterTreeForSelect(item, 0);
      }
    });
  }

  ngOnChanges() {
    if (this.isVFilterModal || this.onlySearch) this.shouldShowSelect = false;
    this.excludedIds = this.excludedFilters.map( x => x.id);
    if (this.selectedLabel) {
      this.foundFilter = {name: this.selectedLabel };
    }
  }

  toggleDropdown() {
    this.shouldShowSelect = !this.shouldShowSelect;
  }

  private _addTopChannel(item) {
    const ItemToPush = {
      id: item.id,
      n: item.n,
      b: item.t === 'g' ? true : false,
      s: Array(1).fill(null),
      t: item.t
    };
    if (ItemToPush.b) {
      this.mutableFilterTreeForSelect.push(ItemToPush);
    }
  }

  private _generateFilterTreeForSelect(item, spaces) {
    const project = this.store.selectSnapshot(state => state.project.project);
    const ItemToPush = {
      id: item.id,
      n: item.n,
      b: item.t === 'g' ? true : false,
      s: Array(spaces).fill(null),
      t: item.t
    };
    this.mutableFilterTreeForSelect.push(ItemToPush);
    if (item.c) {
      spaces += 1;
      if (spaces <= 2 || this.projectsFullTreeEnabled.includes(project.id)) {
        item.c.forEach(child => this._generateFilterTreeForSelect(child, spaces));
      }
    }
  }

  private _resetFilterTrees() {
    this.filterTreeForSelect = [];
    if (!this.isVFilterModal) this.filterTreeForSelect.push(this.allTrafficItem);
    this.mutableFilterTreeForSelect = [];
  }

  searchFormatter = (selectedFilter, shouldReturnWholeChannels?) => {
    const path = returnAllParents({ tree: this.filterTree, childrenKey: 'c', key: 'id', value: selectedFilter.id, additionalKey: 't', additionalValue: selectedFilter.type, shouldReturnWholeChannels });
    if (shouldReturnWholeChannels) return path;
    if (path.length > 0) {
      path.push(selectedFilter.name);
      return path.join(' > ');
    } else {
      if (selectedFilter.parent_filter_group_id) {
        const parentNode = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: selectedFilter.parent_filter_group_id, additionalKey: 't', additionalValue: 'g'});
        return `${parentNode.n} > ${selectedFilter.name}`;
      } else {
        return selectedFilter.name;
      }
    }
  }

  findFilters = (text$: Observable<string>) => {
    text$.pipe(
      map(q => this.q = q)
    );
    return text$.pipe(
      filter(text => trim(text).length > 0),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(q => {
        this.waitingForAutoComplete = true;
        return this.filterService.search(q, this.withoutVirtuals)
      })
    ).pipe(map( x => {
      this.waitingForAutoComplete = false;
      return x;
    }));
  }

  showSelectedFilter(event) {
    if (this.selectOnlyFilters && event.item.type != 'f') return;
    this.q = '';
    
    if (this.isVFilterModal) {
      const path = this.searchFormatter(event.item);
      this.filterSelected.emit({...event.item, path: path });
    } else {
      const channels = this.searchFormatter(event.item, true);
      this.filterSelected.emit({...event, topParent: channels[0]})
    };
    this.shouldShowSelect = false;
    setTimeout(()=>{
      if (!this.selectedLabel) {
        this.foundFilter = null;
      }
    }, 100);
  }

  selectFilter(x) {
    let param = x;
    let canSelectFilter = x.t !== 'label';
    if (this.selectOnlyFilters && x.t !== 'f') {
      canSelectFilter = false;
    }
    if (canSelectFilter) {
      if (this.isOnDashboard) {
        const filter = findInTree({node: {c: this.filterTree }, childrenKey: 'c', key: 'id', value: x.id, additionalKey: 't', additionalValue: x.t, returnParent: true, updateLevel: true});
        param = { item: {
            id: filter.id,
            name: filter.n,
            type: filter.t,
            parent_filter_group_id: filter.parent?.id ? filter.parent.id : null
          }
        };
      } else if (this.isSettings) {
        param = { item: findInTree({node: {c: this.filterTree }, childrenKey: 'c', key: 'id', value: x.id, additionalKey: 't', additionalValue: x.t, returnParent: true, updateLevel: false}) };
      }
      if (this.isVFilterModal) param.path = this.searchFormatter(x);
      const channels = this.searchFormatter(x, true);
      this.filterSelected.emit({...param, topParent: channels[0]});
      this.shouldShowSelect = false;
    }
  }

  closeSearch() {
    this.close.emit();
  }

  toggleSelect(x, isFocus?) {
    if (isFocus && typeof(isFocus) != 'undefined') {
      this.shouldShowSelect = false;
    } else {
      if (this.isVFilterModal || this.onlySearch) this.shouldShowSelect = false;
      else this.shouldShowSelect = x.value.length == 0;
    }
  }

  isNotExcluded(id) {
    return !this.excludedIds.includes(id);
  }

}
