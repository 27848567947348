import { DateTime } from 'luxon';

const shouldShowLookbackOption = (options) => {
  if (options.attribution_types != 'undefined' && options.attribution_types != null) {
    return options.attribution_types.length > 1;
  } else {
    return options.features?.attribution_type;
  }
};

const isPageview = (string) => {
  return (
    (string != null && typeof(string) != 'undefined') && (
      (string.includes('Viewed ') && string.includes(' Page')) ||
      (string == 'Loaded a Page')
    )
  );
};

const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}
const getPermutation = (arr, chunk) => {
  let result = arr.reduce((acc,e,i) => {
      if(i + chunk <= arr.length)
          acc.push(arr.slice(i,i+chunk));
      return acc;
  },[])
  return result
};

const buildPath = (pathArray, self?) => {
  if (pathArray) {
    if (pathArray.length > 0) {
      return pathArray.join(' > ') + ' > ' + self ?? null;
    } else {
      return null;
    }
  } else return null;
};

const IsNotFullPeriod = (period, selectedAgggregation) => {
  const daysInMonth = DateTime.fromISO(period.start).daysInMonth;
  const dateDiff = DateTime.fromFormat(period.end, 'y-MM-dd').diff(DateTime.fromFormat(period.start, 'y-MM-dd'), 'days').toObject().days;
  return selectedAgggregation == 'week' ? dateDiff < 6 : selectedAgggregation == 'month' ? dateDiff < daysInMonth - 1 : false;
}

function groupByParam(array, paramName) {
  return array.reduce((obj, value) => {
    obj[value[paramName]] = value;
    return obj;
  }, {})
}

const twoArraysEqual = (arr1, arr2) => {
  if (arr1.length != arr2.length) {
    return false;
  } else {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] != arr2[i]) {
        return false;
      }
    }
    return true;
  }
}

export { shouldShowLookbackOption, isPageview, titleCase, getPermutation, buildPath, daysInMonth, groupByParam, IsNotFullPeriod, twoArraysEqual };
