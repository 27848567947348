import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardShouldShowZeros'
})
export class DashboardShouldShowZerosPipe implements PipeTransform {
  transform(item: any, nestableList: any, showZerosArray: any, showVirtualZerosArray: any ): any {
    if (nestableList && item.id && (showZerosArray || showVirtualZerosArray)) {
      if (item.t === 'g') {
        return showZerosArray.includes(item.id);
      } else {
        return showVirtualZerosArray.includes(item.id);
      }
    } else return false;
  }
}