import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { SetFirstTimePurchase, SetFTPInProgress } from 'src/app/store/actions/first-time-purchase.actions';
import { BaseService } from '../base/base.service';
import { Subscription } from 'rxjs';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { DataDownloaderService } from '../data-downloader/data-downloader.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class FirstTimePurchaseService {
  subscription: Subscription;

  constructor(private store: Store, private baseService: BaseService, private toastr: ToastrService, private dataDownloader: DataDownloaderService) {}

  getFirstTimePurchase(paidOnly?) {
    this.unsubscribeAndCancelReportGeneration();

    let ReportingParams = this.baseService.getReportingParams;
    ReportingParams.attribution_type = 'backward';
    const TimePeriodParams = this.baseService.getTimePeriodParams(true);
    const Params = {...ReportingParams, ...TimePeriodParams};
    const {conversion_event_name, ...usedParams} = Params;
    const selectedState: SelectedStateModel = this.store.selectSnapshot( state => state.selected);
    const projectState = this.store.selectSnapshot( state => state.project);

    let aggregationPeriod = (projectState.currentViewParams?.aggregation_period || 'Day').toLowerCase();

    const dayDiff = DateTime.fromFormat(selectedState.chosenDates.end, 'y-MM-dd').diff(DateTime.fromFormat(selectedState.chosenDates.start, 'y-MM-dd'), 'days').toObject().days;

    if ((aggregationPeriod == 'day' || aggregationPeriod == 'week') && dayDiff > 200) {
      aggregationPeriod = 'Month';
    } else if (aggregationPeriod == 'day' && dayDiff > 61) {
      aggregationPeriod = 'Week';
    }

    usedParams.aggregate_by_period = aggregationPeriod;

    if (paidOnly) {
      usedParams.paid_only = paidOnly;
      return this.dataDownloader.requestReport({...usedParams, report: 'first_time_purchasers'});
    } else {
      this.store.dispatch(new SetFTPInProgress());
      this.subscription = this.dataDownloader.requestReport({...usedParams, report: 'first_time_purchasers'}).subscribe( (resp: any) => {
        if (resp.meta.status === 'ready') {
          this.store.dispatch(new SetFirstTimePurchase({
            meta: resp.meta,
            report: {
              f: resp.report?.filters,
              g: resp.report?.groups,
              p: resp.report?.other?.periods,
              s: resp.report?.other?.summary
            }
          }));
          this.baseService.trackReportLoaded('First time purchasers', usedParams);
        } else {
          setTimeout(() =>
            this.toastr.error(
              'Failed to fetch data from the server.',
              `status: ${status}`,
              {disableTimeOut: true}
            )
          );
        }
        this.subscription = null;
      });
    }
  }

  unsubscribeAndCancelReportGeneration() {
    if (this.subscription) {
      this.dataDownloader.cancelReportGeneration(['first_time_purchasers']);
      this.subscription.unsubscribe();
      this.subscription = null;
      this.store.dispatch(new SetFTPInProgress({isFalse: true}));
    }
  }

}
