import { State, Action, StateContext } from '@ngxs/store';

import {
  OpenSidebar,
  CloseSidebar,
  RefreshSidebar
} from '../actions/sidebar.actions';
import { Injectable } from '@angular/core';

export interface SidebarStateModel {
  openedSidebar: string;
  name?: string;
  options: {
    filter?: string;
    group?: string;
    scope?: string;
    q?: string;
    id?: string;
    periodStart?: string;
    periodEnd?: string;
    [propName: string]: any;
  };
}

@State<SidebarStateModel>({
  name: 'sidebar',
  defaults: {
    openedSidebar: null,
    options: null
  }
})
@Injectable()
export class SidebarState {

  @Action(OpenSidebar)
  OpenSidebar(ctx: StateContext<SidebarStateModel>, { payload }: OpenSidebar) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      openedSidebar: payload.openedSidebar,
      options: payload.options
    });
  }

  @Action(CloseSidebar)
  CloseSidebar(ctx: StateContext<SidebarStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      openedSidebar: null,
      options: null
    });
  }

  @Action(RefreshSidebar)
  RefreshSidebar(ctx: StateContext<SidebarStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState
    });
  }
}
