<div class="refres-button-wrapper">
  <button class="btn btn-default btn-multiline" (click)="refresh()" *ngIf="refresher?.generated_at && refresher?.state == 'done'">
    <div class="line-one"> <i class="fa-solid fa-rotate blue"></i> Refreshed</div>
    <div class="line-two">
      <span>{{ refresher?.generated_at | timeAgo: { humanizeOpts: {units: ['mo', 'd', 'h', 'm'], delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</span>
    </div>
  </button>
  <button class="btn btn-warning btn-multiline" *ngIf="refresher?.state == 'pending'" (click)="refresh()">
    <div class="line-two">Virtual Filters updated</div>
    <div class="line-one"><i class="fa-solid fa-rotate lightest"></i> Refresh Now</div>
  </button>
  <button class="btn btn-default btn-multiline" *ngIf="refresher?.state == 'refreshing'" (click)="refresh()">
    <div class="line-one"><i class="fa-solid fa-rotate blue fa-spin"></i> Refreshing</div>
    <ng-template #showETA>
      <div *ngIf="refresher?.eta" class="line-two">ETA: {{ refresher?.eta * 1000 | formattedMoment: 'mm:ss' : 'milis' : 1000 }}</div>
    </ng-template>
    <div class="line-two red" *ngIf="refresher?.overtime; else showETA">Overtime: {{ refresher?.overtime * 1000 | formattedMoment: 'mm:ss' : 'milis' : 1000 }}</div>
  </button>
  <button class="btn btn-success btn-multiline" *ngIf="refresher?.state == 'ready'" (click)="reload()">
    <div class="line-one"><i class="fa-solid fa-check darkest"></i> Ready!</div>
    <div class="line-two darkest">Reload dashboard</div>
  </button>
</div>

