import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardSortedBreakdowns',
})
export class DashboardSortedBreakdownsPipe implements PipeTransform {
  transform(breakDowns, lastEmittedSort): any {
    if (lastEmittedSort) {
      const sorted = [...breakDowns].sort( (a, b) => {
        if (lastEmittedSort.sortDirection === 'Down') {
          return b.object[lastEmittedSort.sortColumn] - a.object[lastEmittedSort.sortColumn];
        } else {
          return a.object[lastEmittedSort.sortColumn] - b.object[lastEmittedSort.sortColumn];
        }
      });
      return sorted;
    } else {
      return breakDowns;
    }
  }
}