import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project, View } from 'src/app/core/models/project.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-widget-wrapper-modal',
  templateUrl: './widget-wrapper-modal.component.html',
  styleUrls: ['./widget-wrapper-modal.component.scss']
})
export class WidgetWrapperModalComponent implements AfterViewInit, OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  isProd = environment.isProd;
  project: Project;
  view: View;
  @Input() modalType: string;
  modalAlertText;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.project$.subscribe(x => {
      this.project = x.project;
      this.view = x.currentViewParams;
    });
  }

  ngAfterViewInit(): void {
    const element = document.getElementById(this.modalType);
    const scrollElement = document.getElementsByTagName("ngb-modal-window")[0];
    if (element && typeof(element) != 'undefined' && typeof(scrollElement) != 'undefined') {
      setTimeout(() => scrollElement.scrollTo({top: element.offsetTop+171, left: 0, behavior: 'smooth'}), 1);
    }
  }

}
