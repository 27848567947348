export class SetChannelPerformanceReport {
  static readonly type = '[channel-performance] SetChannelPerformanceReport';
  constructor(public payload: any) {}
}

export class SetChannelPerformanceSummary {
  static readonly type = '[channel-performance] SetChannelPerformanceSummary';
  constructor(public payload: any) {}
}

export class SetChannelPerformanceComparison {
  static readonly type = '[channel-performance] SetChannelPerformanceComparison';
  constructor(public payload: any) {}
}

export class SetChannelPerformanceInProgress {
  static readonly type = '[channel-performance] SetChannelPerformanceInProgress';
  constructor(public payload?: {isFalse: boolean}) {}
}

export class SetChannelPerformanceSummaryInProgress {
  static readonly type = '[channel-performance] SetChannelPerformanceSummaryInProgress';
  constructor(public payload?: {isSpecific?: boolean; isFalse?: boolean;}) {}
}

export class SetChannelPerformanceComparisonInProgress {
  static readonly type = '[channel-performance] SetChannelPerformanceComparisonInProgress';
  constructor(public payload?: {isFalse: boolean}) {}
}

export class SetChannelPerformancePeriod {
  static readonly type = '[channel-performance] SetChannelPerformancePeriod';
  constructor(public payload: any) {}
}

export class SetTrafficModel {
  static readonly type = '[channel-performance] SetTrafficModel';
  constructor(public payload: string) {}
}

export class SetChannelPerformanceChannelSummaryToNull {
  static readonly type = '[channel-performance] SetChannelPerformanceChannelSummaryToNull';
}

export class ClearCP {
  static readonly type = '[channel-performance] ClearCP';
}

export class ClearCPWithoutSummary {
  static readonly type = '[channel-performance] ClearCPWithoutSummary';
}

export class ClearPrevSummary {
  static readonly type = '[channel-performance] ClearPrevSummary';
}
