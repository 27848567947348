import { State, Action, StateContext } from '@ngxs/store';

import { ClearOnboardingState, SetCurrentStep, SetFlow, SetLastStep } from '../actions/onboarding.actions';
import { Injectable } from '@angular/core';

export interface OnboardingStateModel {
  flow: any;
  current_step: string;
  last_step: number;
  max_steps: number;
}

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults: {
    flow: null,
    current_step: null,
    last_step: null,
    max_steps: null
  }
})
@Injectable()
export class OnboardingState {
  @Action(SetCurrentStep)
  SetCurrentStep(ctx: StateContext<OnboardingStateModel>, { payload }: SetCurrentStep) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      current_step: payload
    });
  }

  @Action(SetLastStep)
  SetLastStep(ctx: StateContext<OnboardingStateModel>, { payload }: SetLastStep) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      last_step: payload
    });
  }

  @Action(SetFlow)
  SetFlow(ctx: StateContext<OnboardingStateModel>, { payload }: SetFlow) {
    const getState = ctx.getState();

    //find number of steps
    let maxSteps = 0;
    Object.entries(payload).forEach(([key, index]) => {
      if (payload[key].step_number > 0) maxSteps = payload[key].step_number;
    });

    ctx.setState({
      ...getState,
      flow: payload,
      max_steps: maxSteps
    });
  }

  @Action(ClearOnboardingState)
  ClearAccountState(ctx: StateContext<ClearOnboardingState>) {
    ctx.setState({
      flow: null,
      current_step: null,
      last_step: null,
      max_steps: null
    });
  }
}