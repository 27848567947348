<div class="modal-header">
    <h4 class="modal-title">
        {{ oauth.name ?? oauth.type + ' - ' + oauth.id }} Logs
    </h4>
</div>

<div class="modal-body">
    @if (loading) {
        <div class="w-100 mt-5 mb-5">
            <app-loader [position]="'relative'" [color]="'green'" [backgroundColor]="'white'" [height]="'50px'"></app-loader>
        </div>
    } @else {
        <table class="table table-striped">
            <thead>
                <th>Started_at</th>
                <th>Ended_at</th>
                <th>Duration</th>
                <th>Successful</th>
                <th>Result</th>
            </thead>
            <tbody>
                @for (log of logs; track log) {
                    <tr>
                        <td>{{ log.started_at }}</td>
                        <td>{{ log.ended_at }}</td>
                        <td>{{ log.duration }}</td>
                        <td>{{ log.successful }}</td>
                        <td>{{ log.result | json }}</td>
                    </tr>
                }
            </tbody>
        </table>
    }
</div>