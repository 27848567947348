import { Component, Input } from '@angular/core';
import { Path } from 'src/app/core/models/path.model';
import { CloseSidebar, OpenSidebar } from 'src/app/store/actions/sidebar.actions';
import { Store } from '@ngxs/store';

import * as humanizeDuration from 'humanize-duration';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { ActivatedRoute, Router } from '@angular/router';

const MAX_TOUCHPOINTS = 20;

@Component({
  selector: 'app-paths-row',
  templateUrl: './paths-row.component.html',
  styleUrls: ['../paths.component.scss', './paths-row.component.scss']
})
export class PathsRowComponent {
  @Input() isSidebarOpen;
  @Input() paths: Array<Path>;
  @Input() labels: string;
  @Input() conversionEventName: string;
  @Input() touchpoints: [any];

  showingSidebarForUser: bigint;
  showingSidebarForCompany: bigint;

  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.sidebar === SidebarNames.companies) {
          this.showingSidebarForCompany = queryParams.id;
          this.showingSidebarForUser = null;
          this.store.dispatch(new OpenSidebar({
            openedSidebar: SidebarNames.companies,
            options: {id: this.showingSidebarForCompany}
          }));
      } else if (queryParams.sidebar === SidebarNames.visitors) {
        this.showingSidebarForUser = queryParams.id;
        this.showingSidebarForCompany = null;
        this.store.dispatch(new OpenSidebar({
          openedSidebar: SidebarNames.visitors,
          options: {id: this.showingSidebarForUser}
        }));
      }
    });
  }

  showDelta(delta) {
    if (delta) {
      return humanizeDuration(delta*100, {largest: 1, round: true}).replace(/(\d+)\s(\w).*/, '$1$2');
    } else {
      return null;
    }
  }

  toggleSidebarForVisitor(id: bigint) {
    this.router.navigate([], {
      queryParams: {
        ...this.route.snapshot.queryParams,
        sidebar: SidebarNames.visitors,
        id
      },
    });
  }

  toggleSidebarForCompany(id) {
    this.router.navigate([], {
      queryParams: {
        ...this.route.snapshot.queryParams,
        sidebar: SidebarNames.companies,
        id
      },
    });
  }

  tooManyTouchpoints(path): boolean {
    return path.touchpoints.length > MAX_TOUCHPOINTS;
  }

  lastTouchpoints(i): any[] {
    if (this.touchpoints[i]) {
      let start = this.touchpoints[i].length - MAX_TOUCHPOINTS;
      return this.touchpoints[i].slice((start > 0 ? start : 0), this.touchpoints[i].length);
    } else {
      return [];
    }
  }
}
