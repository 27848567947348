import { State, Action, StateContext } from '@ngxs/store';
import { ClearPathReport, PathsLabelsChanged, SetCombineRecurring, SetPathFilterOrGroup, SetPathFilterType, SetPathReport, SetPathReportAdditionInProgress, SetPathReportInProgress } from '../actions/paths.actions';
import { Injectable } from '@angular/core';

export interface PathsStateModel {
  labels: string;
  isCombineRecurring: boolean;
  report: any;
  filterType: string;
  filterOrGroup: {
    id: number,
    type: string,
    name: string,
  } | null;
  inProgress: boolean;
  additionInProgress: boolean;
}

@State<PathsStateModel>({
  name: 'paths',
  defaults: {
    labels: 'filters',
    isCombineRecurring: false,
    filterType: 'none',
    filterOrGroup: null,
    report: null,
    inProgress: false,
    additionInProgress: false,
  }
})
@Injectable()
export class PathsState {

  @Action(SetPathFilterType)
  SetPathFilterType(ctx: StateContext<PathsStateModel>, { payload }: SetPathFilterType) {
    const getState = ctx.getState();
    if (payload !== getState.filterType) {
      ctx.setState({
        ...getState,
        filterType: payload,
        report: getState.filterOrGroup !== null ? null : getState.report,
      });
    }
  }

  @Action(SetPathFilterOrGroup)
  SetPathFilterOrGroup(ctx: StateContext<PathsStateModel>, { payload }: SetPathFilterOrGroup) {
    const getState = ctx.getState();
    if (!(payload?.id === getState.filterOrGroup?.id && payload?.type === getState.filterOrGroup?.type)) {
      ctx.setState({
        ...getState,
        filterOrGroup: payload,
        report: null,
        filterType: payload === null ? 'none' : getState.filterType,
      });
    }
  }

  @Action(PathsLabelsChanged)
  PathsLabelsChanged(ctx: StateContext<PathsStateModel>, { payload }: PathsLabelsChanged) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      labels: payload,
    });
  }

  @Action(SetCombineRecurring)
  SetCombineRecurring(ctx: StateContext<PathsStateModel>, { payload }: SetCombineRecurring) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      isCombineRecurring: payload,
    });
  }

  @Action(SetPathReport)
  SetPathReport(ctx: StateContext<PathsStateModel>, { payload }: SetPathReport) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: payload,
      inProgress: false,
      additionInProgress: false,
    });
  }

  @Action(SetPathReportInProgress)
  SetPathReportInProgress(ctx: StateContext<PathsStateModel>, { payload }: SetPathReportInProgress) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      inProgress: payload,
    });
  }

  @Action(SetPathReportAdditionInProgress)
  SetPathReportAdditionInProgress(ctx: StateContext<PathsStateModel>, { payload }: SetPathReportAdditionInProgress) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      additionInProgress: payload,
    });
  }

  @Action(ClearPathReport)
  ClearPathReport(ctx: StateContext<PathsStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: null,
      inProgress: false,
      additionInProgress: false,
    });
  }
}
