import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(
    private httpClient: HttpClient
  ) { }

  changePassword(pass, resetToken?, section?) {
    const Url = `${environment.clientApiUrl}/password`;
    const params: any = { password: pass, section };

    if (resetToken) {
      params.token = resetToken;
    }

    return this.httpClient.post(Url, params);
  }

  requestPasswordReset(userEmail) {
    const Url = `${environment.clientApiUrl}/password_resets`;

    return this.httpClient.post(Url, { email: userEmail});
  }

  checkToken(section, token) {
    const Url = `${environment.clientApiUrl}/password_resets/check?section=${section}&token=${token}`;
    return this.httpClient.get(Url);
  }
}
