<h1 class="mt-0">Shopify Settings</h1>
<div class="attr-card max-w-800">
    <p class="em-1_3 mb-4">Set Shopify Settings</p>
    @for (setting of settings; track setting) {
        <div class="d-flex justify-content-between align-items-center mt-2">
            @if (setting.type === 'boolean') {
                    <div class="bold">
                        {{ setting.name }}
                    </div>
                    <div>
                        <app-toggle-switch
                            [isOn]="setting.value"
                            (eventEmitter)="toggleSetting(setting)">
                        </app-toggle-switch>
                    </div>
            } @else if (setting.options_for_select) {
                    <div class="bold">
                        {{ setting.name }}
                    </div>
                    <div>
                        <ng-select class='ng-form-control'
                            [items]="setting.options_for_select"
                            [addTag]="false"
                            [searchable]="false"
                            bindLabel="name"
                            [placeholder]="setting.placeholder || 'Select channel'"
                            [clearable]="false"
                            [(ngModel)]="setting.value"
                            (ngModelChange)="updateSetting(setting)">
                        </ng-select>
                    </div>
            }
        </div>
    }
</div>
