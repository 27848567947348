import { Pipe, PipeTransform } from '@angular/core';
import { findTopNodeInTree } from 'src/app/core/helpers/find-in-tree';

@Pipe({
  name: 'visitTimelinePrependChannelName',
})
export class VisitTimelinePrependChannelNamePipe implements PipeTransform {
  transform(filter, filterTree): any {
    const channel = findTopNodeInTree(filterTree, 'c', 'id', filter.id);
    if (!channel) {
      return filter.name;
    } else {
      return `${channel.n} - ${filter.name}`
    }
  }
}