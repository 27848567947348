import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetPBR } from 'src/app/store/actions/project.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';

@Component({
  selector: 'app-position-based-attribution-ratios',
  templateUrl: './position-based-attribution-ratios.component.html',
  styleUrls: ['./position-based-attribution-ratios.component.scss']
})
export class PositionBasedAttributionRatiosComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() showTitle = true;
  @Input() isInModal = false;

  project: Project;
  firstTouch: number;
  middle: number;
  lastTouch: number;

  pbrSaving = false;
  constructor(private projectService: ProjectService, private store: Store) { }

  ngOnInit(): void {
    this.project$.subscribe(x => {
      this.project = x.project;
      if (this.project?.position_based_ratios) {
        const ratiosArray = this.project?.position_based_ratios.split(',');
        this.firstTouch = ratiosArray[0];
        this.middle = ratiosArray[1];
        this.lastTouch = ratiosArray[2];
      } else {
        this.firstTouch = 40;
        this.middle = 20;
        this.lastTouch = 40;
      }
    });
  }

  alignPBR(position) {
    if ([0, 2].indexOf(position) == -1) {
      return;
    }
    let first   = this.firstTouch;
    let middle  = this.middle;
    let last    = this.lastTouch;
    let current;

    switch(position) {
      case 0:
        current = this.firstTouch;
        break;
      case 1:
        current = this.middle;
        break;
      case 2:
        current = this.lastTouch
        break;
    }

    if (current > 100) {
      current = 100;
    } else if ((first + last) == 0) {
      current = 1;
    }

    if (position == 0) {
      first = current;
      last = ((current + last) <= 100) ? last : (100 - current);
      middle = (100 - (first + last));
    } else if (position == 2) {
      last = current;
      first = ((current + first) <= 100) ? first : (100 - current);
      middle = (100 - (first + last));
    }

    this.firstTouch = first;
    this.middle = middle;
    this.lastTouch = last;
  };

  savePBR() {
    if (this.pbrSaving) {
      return;
    }

    const newPBR = `${this.firstTouch},${this.middle},${this.lastTouch}`;
    this.pbrSaving = true;
    this.projectService.setAttribute('position_based_ratios', newPBR).subscribe(() => {
      this.store.dispatch(new SetPBR(newPBR));
      this.pbrSaving = false;
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

}
