import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { find } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { IntegrationAuthService } from 'src/app/core/services/integrations/integration-auth.service';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {
  @Input() integration;
  @Input() step;
  @Input() type;

  accounts;
  selectedAccount;
  integrationName;
  waitingForResponse = false;
  accountSelected = false;

  constructor(
    private service: IntegrationAuthService,
    private router: Router,
    public activeModal: NgbActiveModal,
    private store: Store,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.accounts = this.integration.oauth.accounts;
    this.integrationName = this.integration.name;
    const sufficientRole1 = find(this.accounts, x => x.sufficient_role == true);
    if (typeof(sufficientRole1) != 'undefined') {
      this.selectAccount(sufficientRole1);
    }
  }

  finishSelecting() {
    if (this.accountNotSelected || this.waitingForResponse == true) return;
    this.waitingForResponse = true;
    this.service.saveAccount(this.selectedAccount, this.integration).subscribe(
      x => {
        if (typeof(x.error) != 'undefined') {
          const project = this.store.selectSnapshot(state => state.project.project);
          this.router.navigate([project.identifier, ComponentNames.settings, SettingsSections.integrations, 'show', this.integration.name]);
          setTimeout(() =>
            this.toastr.warning(
              x.error,
              ``,
              {disableTimeOut: true, enableHtml: true}
            )
          );
        } else if (this.type != 'onboarding') {
          this.waitingForResponse = false;
          this.router.navigate([], {queryParams: {
            integration: this.integration.name,
            step: this.step+1
          }});
        } else {
          this.activeModal.close({integration: this.integration.name, step: this.step+1 });
          return;
        }
        this.close();
      }
    );
  }

  get accountNotSelected() {
    return (this.selectedAccount == null || typeof(this.selectAccount) == 'undefined');
  }

  selectAccount(account) {
    if (account.sufficient_role == true) {
      this.selectedAccount = account;
    }
  }

  close() {
    this.activeModal.close('close');
  }

  get isGoogle() {
    return ['adwords', 'googleads', 'googleads2'].includes(this.integrationName);
  }

}
