import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project, View } from 'src/app/core/models/project.model';
import { SetAttributionType, SetLookbackWindow } from 'src/app/store/actions/project.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lookback-option',
  templateUrl: './lookback-option.component.html',
  styleUrls: ['./lookback-option.component.scss']
})
export class LookbackOptionComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() showTitle = true;
  @Input() isInModal = false;
  @Input() isTimeframe = false;

  project: Project;
  view: View;
  attributionTypes: Array<string> = ['forward'];
  isProd = environment.isProd;

  additionalLookbackWindows = [];

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.project$.subscribe(x => {
      this.project = x.project;
      this.view = x.currentViewParams;
      this.additionalLookbackWindows = this.project?.options?.additional_lookback_windows || [];
      this.attributionTypes = this.project.options.attribution_types || ['forward'];
    });
  }

  selectAttributionType(type: string) {
    if (this.view?.attribution_type != type) {
      this.store.dispatch(new SetAttributionType(type)).subscribe(() => {
        if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
      });
    }
    if (type == 'backward' && this.view.lookback_window == null) {
      this.selectPeriod(30);
    }
  }

  selectPeriod(period) {
    this.store.dispatch(new SetLookbackWindow(period)).subscribe(() => {
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

}
