<div class='app_controlbar'>
  <div class='dropdowns-wrapper'>
    <div class='container-fluid'>
      <div class='controlbar-wrapper'>
        <div class="left-controlbar-filters">
          <div class='inline-block controlbar-switch' *ngIf="'conversionEvents' | controlbarShouldShow:currentComponent">
            <small class="controllbar__hint top">Conversion Event</small>
            <small class='controllbar__hint top right' *ngIf="'propertiesTab' | controlbarShouldShow:currentComponent">
              <a class="btn-micro" (click)="toggleProperties()" title="Filter by event property">
                <span *ngIf="!isPropertiesOpened">+</span>
                <span *ngIf="isPropertiesOpened">-</span>&thinsp;prop
              </a>
            </small>
            <app-conversion-event-selector></app-conversion-event-selector>
          </div>
          <app-project-options *ngIf="'projectOptions' | controlbarShouldShow:currentComponent"></app-project-options>
          <span *ngIf="'propertiesTab' | controlbarShouldShow:currentComponent">
            <span id="property-selector-wrapper" [ngClass]="{visible: isPropertiesOpened}">
              <span class='medium'> : </span>
              <div class='inline-block controlbar-switch'>
                <small class='controllbar__hint top'>Event Property</small>
                <app-properties-selector
                [reportIsLoading]="reportIsLoading"
                [properties]="properties"></app-properties-selector>
              </div>
              <span *ngIf="properties?.event_property_key !== null">
                <span class='medium'> <i class="fa-solid fa-equals"></i> </span>
                <div class='inline-block controlbar-switch'>
                  <small class='controllbar__hint top'>Property value</small>
                  <app-property-values-selector
                  [reportIsLoading]="reportIsLoading"
                  [properties]="properties"></app-property-values-selector>
                </div>
              </span>
            </span>
          </span>
          <span *ngIf="'attrModel' | controlbarShouldShow:currentComponent">
            <span class='medium' *ngIf="('attrModelSelectorIsNotOnLeft' | controlbarShouldShow:currentComponent) || ('conversionEvents' | controlbarShouldShow:currentComponent)"> : </span>
            <div class='inline-block controlbar-switch'>
              <ng-template #tipContent>
                <div>
                  <a href="https://docs.attributionapp.com/docs/multi-touch-attribution-example" target="_blank">Learn more</a>
                </div>
              </ng-template>
              <small class='controllbar__hint top'>Attribution Model <i class="fa-solid fa-info-circle dash_tooltip" placement="end" [ngbTooltip]="tipContent" container="body" triggers="click:blur" tooltipClass="tooltip-on-top" autoClose="outside"></i></small>
              <app-attribution-model-selector></app-attribution-model-selector>
            </div>
          </span>
          <span *ngIf="('attributionType' | controlbarShouldShow:currentComponent) && (('lookbackOption' | controlbarShouldShow:currentComponent:{project: project}) || !isProd)">
            <span class='medium'> : </span>
            <div class='inline-block controlbar-switch'>
              <small class='controllbar__hint top'><i *ngIf="!('lookbackOption' | controlbarShouldShow:currentComponent:{project: project})" class="fa-solid fa-lock red me-1"></i> Attribution Type</small>
              <app-attribution-type-selector
                [isFtp]="this.currentComponent === componentNames.firstTimePurchasers"></app-attribution-type-selector>
            </div>
          </span>
          <span *ngIf="(('revenueGroups' | controlbarShouldShow:currentComponent:{project: project}) || !isProd) && this.currentComponent != componentNames.dashboardBuilder">
            <span class='medium'> : </span>
            <div class='inline-block controlbar-switch'>
              <small class='controllbar__hint top'><i class="fa-solid fa-lock red me-1" *ngIf="!('revenueGroups' | controlbarShouldShow:currentComponent:{project: project}) && !isProd"></i> Revenue Group</small>
              <app-revenue-group-selector></app-revenue-group-selector>
            </div>
          </span>
          <span *ngIf="'views' | controlbarShouldShow:currentComponent">
            <span class="medium"> : </span>
            <div class='inline-block controlbar-switch'>
              <small class='controllbar__hint top'>Saved Views</small>
              <app-view-selector></app-view-selector>
            </div>
          </span>
        </div>
        <div class="right-controlbar-filters">
          <span *ngIf="('aggregationPeriod' | controlbarShouldShow:currentComponent) || isTimeframe">
            <div class='inline-block'>
              <aggregation-period-select (valueChange)="aggregationPeriodChanged($event)"></aggregation-period-select>
            </div>
            <span class='medium'> : </span>
          </span>
          <div *ngIf="this.project?.options?.features?.load_report_button" class="load-report-btn">
            <button class="btn" (click)="loadReport()" [ngClass]="{'btn-default disabled': reportNeedsToLoad || !paramsWereChanged || reportIsLoading, 'btn-warning bold': !reportNeedsToLoad && paramsWereChanged && !reportIsLoading}">
              Load Report
            </button>
          </div>
          @if (project) {
            <app-refresh-button [class.hide-prod]="!refresher?.isVirtual"></app-refresh-button>
            <div class="calendar-wrapper">
              <small class="controllbar__hint top" *ngIf="refreshedAt">Report generated {{ refreshedAt | timeAgo: { humanizeOpts: {units: ['mo', 'd', 'h', 'm'], delimiter: ' and ', largest:1}, suffix: ' ago' } : 60000 }}</small>
              <app-ngb-datepicker></app-ngb-datepicker>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
