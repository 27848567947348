import { Component, OnInit, Input } from '@angular/core';
import { Integration } from 'src/app/core/constants/integration';

@Component({
  selector: 'app-filter-icon',
  templateUrl: './filter-icon.component.html',
  styleUrls: ['./filter-icon.component.scss']
})
export class FilterIconComponent implements OnInit {
  @Input() item;
  @Input() onlyIcon: boolean = false;
  @Input() isRawFilter: boolean = false;
  @Input() isAlerts = false;

  subName;

  constructor() { }

  ngOnInit() {
    this.defineFilterSubName(this.item);
  }

  defineFilterSubName(item) {
    if (this.isAlerts) {
      let icon = '<i class="fa-solid fa-triangle-exclamation orange"></i>';
      if (Integration.get.icons.hasOwnProperty(item.i)) {
        icon = Integration.get.icons[item.i];
      }
      this.subName = icon;
      return;
    }


    let icon = '';
    if (Integration.get.icons.hasOwnProperty(item.i)) {
      icon = Integration.get.icons[item.i];
    }

    if (this.onlyIcon) {
      this.subName = icon;
      return;
    }

    if (item.it) {
      this.subName = icon ? (icon + ' ' + item.it) : item.it;
    } else {
      if (item.t === 'g') {
        this.subName = 'Channel';
      } else if (item.t === 'f') {
        this.subName = 'Filter';
      }
    }

  }

}
