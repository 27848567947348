<div class="modal-header">
  <h3 class="modal-title">Create New Project</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="alert alert-danger" *ngIf="errorMessage">
    {{ errorMessage }} <a class="alert-link" [routerLink]="[componentNames.account, settingsSections.billing]">Upgrade</a> to create more projects.
  </div>
  <form [formGroup]="newProjectForm">
    <div class="form-group">
      <label class="caps">Owner</label>
      <select class="form-control" formControlName="ownerSelect">
        <option *ngFor='let owner of owners' value="{{owner.id}}">{{owner.email}}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="caps">Name</label>
      <input type="text" class="form-control" formControlName="projectName" placeholder="My Project Name" autofocus>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type='submit' id='login-button' class="btn btn-success" [class.disabled]="!newProjectForm.valid" (click)="createNewProject()">
    <span *ngIf="!waitingForResponse; else waiting" class='caps'>
      <span class="caps">Create Project </span>
      <i class="fa-solid fa-check-circle"></i>
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>