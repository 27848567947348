import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

import { IntegrationAuthService } from 'src/app/core/services/integrations/integration-auth.service';

@Component({
  selector: 'app-segment_reverse_events',
  templateUrl: './segment_reverse_events.component.html',
  styleUrls: ['./segment_reverse_events.component.scss']
})
export class SegmentReverseEventsComponent {
  @Input() integration;
  @Input() isOnboarding = false;
  @Input() step = 0;
  @Input() type = 'explainer';

  error = null;
  waitingForResponse = false;

  connectForm = this.fb.group({
    segment_write_key: ['', [Validators.required]],
  });
  learnMoreLink: any;

  constructor(private service: IntegrationAuthService, public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private router: Router) {}

  connectLogin() {
    if (this.connectForm.invalid) return;
    const segment_write_key = this.connectForm.get('segment_write_key').value;

    this.waitingForResponse = true;
    this.service.connect(this.integration, { segment_write_key: segment_write_key}).subscribe( x => {
      this.waitingForResponse = false;
      this.activeModal.close();
      const path = this.isOnboarding ? [] : [ComponentNames.settings, SettingsSections.integrations];
      this.router.navigate(path,  { queryParams: {
        integration: this.integration.name,
        segment_write_key: segment_write_key
      }})
    }, e => {
      switch (e.status) {
        case 429:
          this.error = e.message + '. Please try again later.';
          break;
        default:
          this.error = 'Unknown error. Please contact Attribution support.'
      }
    });
  }

  close() {
    this.activeModal.close('close');
  }
}
