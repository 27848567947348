import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetWrapperModalComponent } from './components/widget-wrapper-modal/widget-wrapper-modal.component';
import { SettingsModule } from '../settings/with-identifier/settings.module';

@NgModule({
  declarations: [
    WidgetWrapperModalComponent
  ],
  imports: [
    CommonModule,
    SettingsModule
  ],
  exports: [
    WidgetWrapperModalComponent
  ]
})
export class WidgetWrapperModule { }
