import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { reduce } from 'lodash-es';
import { IntegrationsService } from 'src/app/core/services/integrations/integrations.service';
import { ProjectService } from 'src/app/core/services/project/project.service';

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss']
})
export class IntegrationSettingsComponent implements OnInit {
  @Input() integrationName;
  @Input() oauth;

  schema;
  saveInProgress = false;
  settings = {};

  constructor(
    private integrationsService: IntegrationsService,
    public activeModal: NgbActiveModal,
    private projectService: ProjectService) { }

  ngOnInit(): void {
    this.settings = this.oauth.settings;
  }

  save() {
    if (this.saveInProgress) return;
    this.saveInProgress = true;
    this.integrationsService.updateOauth(this.oauth.id, this.settings).subscribe({
      next: x => {
        this.oauth = x;
        this.projectService.getProjectInfo(true).subscribe();
        this.saveInProgress = false;
        this.activeModal.close();
      },
      error: () => {
        this.saveInProgress = false;
      }
      }
    );
  }

  renderEventNames(data){
    return reduce(data, function(result, item){
      result.push({id: item.name, name: item.name, level: 0});
      return result;
    }, []);
  };

  renderTree(data){
    return reduce(data, function(result, item){
      result.push({id: item.id, name: item.n, level: item.l});
      /* Uncomment to include children groups */
      /*
      if (item.c){
        result.push.apply(result, renderTree(item.c));
      }
      */
      return result;
    }, []);
  };

  cancel() {
    this.activeModal.close();
  }

  receivedSchema(schema) {
    this.schema = schema;
  }

  setSchemaSetting(settings) {
    this.settings = settings;
  }
}
