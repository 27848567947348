@switch(type) {
  @case ('explainer') {
    <div class="modal-header">
      <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
      <h4 class="modal-title">
        Attribution tracking for {{ config?.label }}
      </h4>
    </div>
    <div class="modal-body mt-0 pt-0">
      <div *ngIf="docsLoading; else docsLoaded" class="m-5">
        <app-loader [position]="'relative'" [color]="'green'" [backgroundColor]="'white'"></app-loader>
      </div>
      <ng-template #docsLoaded>
        <div *ngIf="docs" class="docs docs__in-modal hide-prod">
          <div class="d-flex align-items-center mb-3">
            <h1 class="mb-0 d-inline">
              {{ docs.title }}
            </h1>
          </div>
          <p>{{ docs.excerpt }}</p>
          <div [innerHTML]="docs.body_html"></div>
       </div>
      </ng-template>
    </div>

    <div class="modal-footer">
      <a *ngIf="config.docLink" href="{{config.docLink}}" class="btn btn-default pull-left" target="_blank">
        <i class="fa-solid fa-book blue"></i>
        Learn More
      </a>
      <button *ngIf="nextStep" type="button" (click)="continueExplainer()" class="btn btn-success">Continue with {{ config?.label }}</button>
    </div>
  } @case ('finish') {
    @switch (integration.name){
      @case ('facebook_leads') {
        <div class="modal-header">
          <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title">
            Facebook Leads Connected
          </h4>
        </div>
        <div class="modal-body">
          <div class="container">
            <h6>
              <strong>We've started pulling your leads</strong>
            </h6>
            <div class="row">
              <div class="col-12">
                <p class="small">Your Facebook Leads now would be captured.</p>
                <p class="small">If they came from a paid Facebooks Ad - it would be tracked accordingly.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" (click)="close()" class="btn btn-success">Finish</button>
        </div>
      } @case ('segment_reverse_events') {
        <div class="modal-header">
          <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title">
            Segment Reverse Events Connected
          </h4>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="small">Your Segment Reverse Events integration has been connected, as a next please click Settings to configure which events you would like to send back to segment.</p>
                <p class="small">Please note that Events are sent once per day and are not live streamed.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" (click)="close()" class="btn btn-success">Finish</button>
        </div>
      } @default {
        <div class="modal-header">
          <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title">
            Tagging your Ads
          </h4>
        </div>
        <div class="modal-body">
          <div class="container">
            <h6>
              <strong>We've started tagging your ads</strong>
            </h6>
            <div class="row">
              <div class="col-12">
                <p class="small">This may take several minutes depending on how many active ads you
                have.</p>
                <p class="small">Please reload the dashboard in several minutes to see your new filters.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" (click)="close()" class="btn btn-success">Finish</button>
        </div>
      }
    }
  }
}
  