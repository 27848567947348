import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedComponent } from '../shared/shared.component';
import { RouterModule } from '@angular/router';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    SharedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule
  ],
   exports: [
     SharedComponent
   ]
})
export class SettingsSharedModule { }
