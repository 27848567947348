<div class='properties-wrapper'>
  <div class='btn btn-default btn-select' [class.disabled]='!buttonEnabled' #btn (click)="buttonEnabled ? openSelect(btn,select) : null">
    {{eventPropertyKey? eventPropertyKey : buttonText}}
    <span *ngIf="buttonText != 'Loading...'" class='caret'></span>
  </div>
  <ng-select #select class='ng-form-control'
    [class.ng-select-hide]='hideSelect'
    [items]="knownProperties"
    bindLabel="name"
    placeholder="Property"
    (close)="closeSelect()"
    [clearable]="true"
    [(ngModel)]="eventPropertyKey"
    (ngModelChange)="selectEventPropertyKey()">
    <ng-template ng-option-tmp let-item="item">
      <span> {{ item }}</span>
    </ng-template>
  </ng-select>
</div>
