import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OnboardingService } from 'src/app/core/services/onboarding/onboarding.service';

@Component({
  selector: 'app-onboarding-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnChanges {
  @Select( state => state.onboarding) onboarding$: Observable<any>;
  @Input() restrictForward = false;

  step: any;
  canGoForward = false;
  canGoBack = false;
  lastStep: any;
  isFinish: boolean = false;
  restartedOnboarding: boolean = false;

  constructor(private onboardingService: OnboardingService, private store: Store, private router: Router) { }

  ngOnInit(): void {
    this.onboarding$.subscribe( x => {
      if (x.flow && x.current_step) {
        this.lastStep = x.last_step;
        this.step = x.flow[x.current_step];
        this.canGoForward = this.onboardingService.canGoForward(this.step, this.lastStep) && !this.restrictForward;
        this.canGoBack = this.onboardingService.canGoBack(this.step);
        this.isFinish = this.step?.step_number == x.max_steps;
        this.restartedOnboarding = this.lastStep == 0;
      }
    });
  }

  ngOnChanges() {
    if (this.step && this.lastStep) {
      this.canGoForward = this.onboardingService.canGoForward(this.step, this.lastStep) && !this.restrictForward;
    }
  }

  goNext() {
    if (!this.isFinish) this.onboardingService.navigateToNext(this.step);
    else this.onboardingService.finish();
  }

  goBack() {
    this.onboardingService.navigateBack(this.step);
  }

}
