import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../shared/shared.module';
import { CohortComponent } from './cohort/cohort.component';
import { CohortContentComponent } from './cohort/cohort-content/cohort-content.component';
import { DndModule } from 'ngx-drag-drop';
import { ChannelPerformanceComponent } from './channel-performance/channel-performance.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FixedRowsComponent } from './home/fixed-rows/fixed-rows.component';
import { DashHeaderComponent } from './home/header/dash-header.component';
import { OverviewComponent } from './overview/overview.component';
import { PathsComponent } from './paths/paths.component';
import { PathsHeaderComponent } from './paths/header/paths-header.component';
import { PathsRowComponent } from './paths/row/paths-row.component';
import { RouterModule } from '@angular/router';
import { PathsAggregatedRowComponent } from './paths/aggregated-row/paths-aggregated-row.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChannelBuilderComponent } from './channel-builder/channel-builder.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CountUpModule } from 'ngx-countup';
import { TimeframeRowComponent } from './home/timeframe-row/timeframe-row.component';
import { MatTreeModule } from '@angular/material/tree';
import { CpIsSelectedPipe } from './channel-builder/pipes/is-selected.pipe';
import { CpIsAddedAlreadyPipe } from './channel-builder/pipes/is-added-already.pipe';
import { CohortCalculateClassNamePipe } from './cohort/cohort-content/calculate-class-name.pipe';
import { CohortCalculateAmountPipe } from './cohort/cohort-content/calculate-amount.pipe';
import { FTPReportForItemPipe } from './home/pipes/ftp-report-for-item.pipe';
import { FtpTotalForPipe } from './home/pipes/ftp-total-for.pipe';
import { DashboardGetItemReportPipe } from './home/pipes/get-item-report.pipe';
import { DashboardShouldShowZerosPipe } from './home/pipes/should-show-zeros.pipe';
import { DashboardChannelOauthExpiredPipe } from './home/pipes/channel-oauth-expired.pipe';
import { DashboardFilterExcludedPipe } from './home/pipes/filter-excluded.pipe';
import { DashboardFirstExpiredOauthPipe } from './home/pipes/first-expired-oauth.pipe';
import { DashboardIsFilterErrorPipe } from './home/pipes/is-filter-error.pipe';
import { DashboardFilterErrorPipe } from './home/pipes/filter-error.pipe';
import { DashboardBreakdownHiddenPipe } from './home/pipes/breakdown-hidden.pipe';
import { DashboardShowAdIntegrationIconPipe } from './home/pipes/show-ad-integration-icon.pipe';
import { DashboardFilterIsInVirtualPipe } from './home/pipes/is-in-virtual.pipe';
import { DashboardSortedBreakdownsPipe } from './home/pipes/sorted-breakdowns.pipe';
import { DashboardShowShimmerPipe } from './home/pipes/dashboard-show-shimmer.pipe';
import { DashboardNotHiddenItemsPipe } from './home/pipes/not-hidden-items.pipe';
import { DashboardShouldShowEditButtonPipe } from './home/pipes/should-show-edit-button.pipe';
import { CohortGetFilterNamePipe } from './cohort/get-filter-name.pipe';
import { DashboardExpandLoadingPipe } from './home/pipes/expand-loading.pipe';
import { DashboardCanNavigateToAdServicePipe } from './home/pipes/can-navigate-to-ad-service.pipe';
import { DashboardCalculateZerosPipe } from './home/pipes/calculate-zeros.pipe';
import { DashboardNeedsDataRefreshPipe } from './home/pipes/needs-data-refresh.pipe';
import { PathsGetFilterTypeLabelPipe } from './paths/header/get-filterType-label.pipe';
import { DashboardGetBreakdownsPipe } from './home/pipes/get-breakdowns.pipe';
import { FindAllFiltersAndChannelsPipe } from './home/pipes/find-all-filter-and-groups.pipe';
import { DashboardGetFilterPathPipe } from './home/pipes/get-filter-path.pipe';

@NgModule({
  declarations: [
    HomeComponent,
    CohortComponent,
    CohortContentComponent,
    ChannelPerformanceComponent,
    FixedRowsComponent,
    DashHeaderComponent,
    OverviewComponent,
    PathsComponent,
    PathsHeaderComponent,
    PathsRowComponent,
    PathsAggregatedRowComponent,
    ChannelBuilderComponent,
    TimeframeRowComponent,
    CpIsSelectedPipe,
    CpIsAddedAlreadyPipe,
    CohortCalculateClassNamePipe,
    CohortCalculateAmountPipe,
    FTPReportForItemPipe,
    FtpTotalForPipe,
    DashboardGetItemReportPipe,
    DashboardShouldShowZerosPipe,
    DashboardChannelOauthExpiredPipe,
    DashboardFilterExcludedPipe,
    DashboardFirstExpiredOauthPipe,
    DashboardIsFilterErrorPipe,
    DashboardFilterErrorPipe,
    DashboardBreakdownHiddenPipe,
    DashboardShowAdIntegrationIconPipe,
    DashboardFilterIsInVirtualPipe,
    DashboardSortedBreakdownsPipe,
    DashboardShowShimmerPipe,
    DashboardNotHiddenItemsPipe,
    DashboardShouldShowEditButtonPipe,
    CohortGetFilterNamePipe,
    DashboardExpandLoadingPipe,
    DashboardCanNavigateToAdServicePipe,
    DashboardCalculateZerosPipe,
    DashboardNeedsDataRefreshPipe,
    PathsGetFilterTypeLabelPipe,
    DashboardGetBreakdownsPipe,
    FindAllFiltersAndChannelsPipe,
    DashboardGetFilterPathPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    DndModule,
    NgbModule,
    RouterModule,
    InfiniteScrollModule,
    ScrollingModule,
    CountUpModule,
    MatTreeModule
  ]
})
export class DashboardModule { }
