import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { SetReport, SetReportInProgress } from 'src/app/store/actions/report.actions';
import { BaseService } from '../base/base.service';
import { DataDownloaderService } from '../data-downloader/data-downloader.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  subscription: Subscription;
  eta: number = null;
  countDown;

  constructor(
    private store: Store,
    private baseService: BaseService,
    private dataDownloader: DataDownloaderService) {}

  getReports() {
    this.unsubscribeAndCancelReportGeneration();

    this.store.dispatch(new SetReportInProgress());

    const Params = this.baseService.getReportingParams;

    this.subscription = this.dataDownloader.requestReport({...Params, report: 'dashboard'}).subscribe( (resp: any) => {
      this.store.dispatch( new SetReport(resp));
      this.baseService.trackReportLoaded('Dashboard', Params);
      this.subscription = null;
    });
  }

  unsubscribeAndCancelReportGeneration() {
    if (this.subscription) {
      this.dataDownloader.cancelReportGeneration(['dashboard']);
      this.subscription.unsubscribe();
      this.subscription = null;
      this.store.dispatch(new SetReportInProgress({ isFalse: true}));
    }
  }

}
