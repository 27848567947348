import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ProjectState, ProjectStateModel } from 'src/app/store/states/project.state';
import { Observable, combineLatest } from 'rxjs';
import { SelectedState, SelectedStateModel } from 'src/app/store/states/selected.state';
import { Project } from 'src/app/core/models/project.model';
import { DatePipe } from '@angular/common';
import { BaseService } from 'src/app/core/services/base/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SetChosenDates, SetLastSelectedPreset } from 'src/app/store/actions/selected.actions';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';
import { ChosenDates } from 'src/app/store/interfaces/selected';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ngb-datepicker',
  templateUrl: './ngb-datepicker.component.html',
  styleUrls: ['./ngb-datepicker.component.scss']
})
export class NgbDatepickerComponent implements OnInit {
  @Input() notMain = false;
  @Input() allowFutureDates = false;
  @Output() datesChosen = new EventEmitter();

  @Select(ProjectState) project$: Observable<ProjectStateModel>;
  @Select(SelectedState) selected$: Observable<SelectedStateModel>;
  DP: DatePipe = new DatePipe('en-US');;

  project: Project;
  chosenDates: ChosenDates;

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  minDate: NgbDate;
  maxDate: NgbDate;
  latestToDate: NgbDate;
  latestFromDate: NgbDate;
  presets = [];
  dateWarning = false;
  isSelectingEndDate = false;
  dateToday;
  isSelectingEndDateFromTop = false;
  datePickerOpened = false;
  currentComponent = "";
  isProd = environment.envName == 'production';
  MONTH_LIMIT = 25;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private baseService: BaseService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService) {}

  ngOnInit() {
    combineLatest([this.project$, this.selected$]).subscribe( ([projectState, selectedState]) => {
      this.project = projectState.project;
      this.chosenDates = selectedState.chosenDates;
      this.currentComponent = selectedState.currentComponent;
      const customLimit = projectState.project?.options?.custom_aggr_period_limits?.months;
      if (customLimit) this.MONTH_LIMIT = customLimit;
      this.initCalendar();
    });
  }

  selectPreset(preset) {
    this.fromDate = NgbDate.from(this.formatter.parse(preset.start));
    this.toDate = NgbDate.from(this.formatter.parse(preset.end));
    this.choseDates();
    this.store.dispatch(new SetLastSelectedPreset(preset.label));
  }

  initCalendar() {
    this.setToday();
    if (!this.notMain) {
      this.fromDate =  NgbDate.from(this.formatter.parse(this.chosenDates.start));
      this.toDate = NgbDate.from(this.formatter.parse(this.chosenDates.end));
    }
    this.latestFromDate = this.fromDate;
    this.latestToDate = this.toDate;
    if (this.project) {
      this.minDate = NgbDate.from(this.formatter.parse(this.project.earliest_date));
      this.presets = this.baseService.getPresets(true);
    } else {
      this.minDate = NgbDate.from(this.formatter.parse('2000-01-01'))
    }

    if (!this.allowFutureDates) {
      this.maxDate = this.baseService.getDateWithTimeZone(DateTime.now().toISO());
    }
    
    this.checkIfToDateIsMaxDate();
  }

  choseDates() {
    if (typeof(this.fromDate) == 'undefined' || typeof(this.toDate) == 'undefined') return;
    const start = this.formatter.format(this.fromDate);
    let end = this.formatter.format(this.toDate);
    if (!this.notMain) {
      if (this.fromDate && this.toDate) {
        const monthDiff = DateTime.fromISO(end).diff(DateTime.fromISO(start), 'months').toObject().months;
        if (monthDiff > this.MONTH_LIMIT) {
          end = DateTime.fromISO(start).plus({ months: 25 }).toISODate();
          this.toastr.warning(
            `You have selected too long period. The maximum period is 25 months. The end date has been set to ${end}`,
            'Period too long',
            {disableTimeOut: true, enableHtml: true}
          );
        }

        this.datePickerOpened = false;
        // this.store.dispatch(new SetChosenDates({ start: start, end: end }));
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {...this.route.snapshot.queryParams, start: start, end: end}
        });
      }
      this.checkIfToDateIsMaxDate();
    } else {
      this.datesChosen.emit({start: start, end: end});
    }
  }

  checkIfToDateIsMaxDate() {
    if (this.toDate && this.toDate.equals(this.maxDate)) {
      this.dateWarning = true;
    } else if (this.toDate) {
      this.dateWarning = false;
    } else {
      this.dateWarning = false;
    }
  }

  isLatestDate(date) {
    return this.formatter.format(date) === this.formatter.format(this.maxDate);
  }

  onDateSelection(date: NgbDate, datepicker: NgbInputDatepicker) {
    if (!this.fromDate && !this.toDate && !this.isSelectingEndDateFromTop) {
      this.fromDate = date;
      this.isSelectingEndDate = true;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || (date.equals(this.fromDate) && !this.isSelectingEndDateFromTop))) {
      this.toDate = date;
      this.latestToDate = date;
      this.latestFromDate = this.fromDate;
      this.isSelectingEndDate = false;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.isSelectingEndDate = true;
    }
    this.isSelectingEndDateFromTop = false;
    this.removeFocus();
    this.choseDates();
  }

  openEndDate(datePicker: NgbInputDatepicker, shouldNotNavigateToMonthBefore?) {
    if (this.isSelectingEndDate && !shouldNotNavigateToMonthBefore) {
      this.isSelectingEndDateFromTop = true;
      this.isSelectingEndDate = true;
    } else {
      this.isSelectingEndDate = true;
      this.datePickerOpened = true;
      datePicker.open();
      if (this.latestToDate) {
        const monthBefore = this.toDate ? this.calendar.getPrev(this.toDate, 'm',1) : this.calendar.getPrev(this.latestToDate, 'm',1)
        if (!shouldNotNavigateToMonthBefore) {
          datePicker.navigateTo(monthBefore);
        }
        datePicker.dateSelect.next(this.fromDate);
      }
    }
  }

  checkIfStartDateChanged(date, datePicker) {
    if (!this.toDate || this.toDate.before(this.fromDate)) {
      this.toDate = this.fromDate;
    }
    if (this.latestToDate) {
      if (!this.latestFromDate.equals(this.fromDate)) {
        this.latestFromDate = this.fromDate;
        if (!this.isSelectingEndDateFromTop) {
          this.choseDates();
        } else {
          this.isSelectingEndDate = true;
          this.datePickerOpened = true;
          this.openEndDate(datePicker, true);
        }
      }
    }
  }

  removeFocus() {
    const elem = document.activeElement as HTMLElement;
    elem.blur();
  }

  defineUndefined() {
    if (this.toDate == null) {
      this.toDate = this.latestToDate;
    }
  }

  issBeforeOrAfterMinMax(date: NgbDate) {
    return date.before(this.minDate) || date.after(this.maxDate);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  get formattedForInput() {
    return `${this.DP.transform(this.formatter.format(this.fromDate), 'longDate')} - ${this.DP.transform(this.formatter.format(this.toDate), 'longDate')}`;
  }

  isStart(date: NgbDate) {
    const start = this.fromDate || this.latestFromDate;
    return date.equals(start);
  }

  isEnd(date: NgbDate) {
    const end = this.toDate || this.latestToDate;
    return date.equals(end);
  }

  setToday() {
    this.dateToday = this.formatter.parse(this.baseService.getDateWithTimeZone(DateTime.now().toISO()).toFormat('y-MM-dd'));
  }

  isToday(date: NgbDate) {
    return date.equals(this.dateToday);
  }

  toggleDP(dp) {
    this.datePickerOpened = !this.datePickerOpened;
    dp.toggle();
  }

}
