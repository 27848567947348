
<div *ngIf="data?.length > 0; else noData" id="{{chartName}}" class="multi-line-chart-wrapper" [class.dragscroll]="isScrollable" [class.responsive]="!isScrollable" (window:resize)="drawChart()">
</div>

<ng-template #noData>
  <div class="m-5">
    <div class="alert alert-info" role="alert">
      No data for chart.
    </div>
  </div>
</ng-template>