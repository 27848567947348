<h3>Timezone setting</h3>
<div *ngIf="project?.timezone">
  <p>Project timezone: <strong>{{ project.timezone }}</strong></p>
  <div *ngFor="let oauth of oauths">
    <div class="alert alert-warning" *ngIf="oauth.timezone && oauth.timezone != project.timezone">
      <strong>{{ oauth.name }}</strong> integration timezone <strong>{{ oauth.timezone }}</strong> does not match your Attribution Project timezone.<br/>
      This might result in clicks, spent amounts and events count reporting discrepancies on day-by-day basis between Attribution and integration platform, totals should still match up.
    </div>
  </div>
</div>

<div *ngIf="!project.timezone">
  <p>Project timezone: <strong>is not set</strong>, using your computer timezone setting.</p>
</div>
