import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Integration } from 'src/app/core/constants/integration';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { IntegrationAuthService } from 'src/app/core/services/integrations/integration-auth.service';
import { ReadMeComDocsService } from 'src/app/core/services/readMe-com-docs/readMe-com-docs.service';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent {
  @Input() integration;
  @Input() step = 0;
  @Input() type = 'explainer';
  @Input() isOnboarding = false;

  config;
  slug;
  docs;
  docsLoading = true;
  nextStep;

  constructor(private service: IntegrationAuthService, public activeModal: NgbActiveModal, private router: Router, private readmeComDocsService: ReadMeComDocsService) {}

  ngOnInit() {
    this.config = Integration.get.config[this.integration.name];
    this.nextStep = this.config.steps[this.step + 1];
    this.slug = Integration.get.readMeComSlugs[this.integration.name];
    if (!this.docs && this.slug) {
      this.readmeComDocsService.get(this.slug).subscribe( (x: any) => {
        this.docs = x;
        this.docsLoading = false;
      });
    }
  }

  connect() {
    this.service.authDefault(this.integration);
  }

  continueExplainer() {
    if (this.nextStep === 'auth') {
      this.connect();
    } else if (this.nextStep === 'connect') {
      this.continue();
    }
  }

  continue() {
    this.activeModal.close();
    const path = this.isOnboarding ? [] : [ComponentNames.settings, SettingsSections.integrations];
    this.router.navigate(path, {
      queryParams: {
        integration: this.integration.name,
        step: this.step+1,
        continueType: 'auth'
      }
    });
  }

  close() {
    this.activeModal.close('close');
  }
}
