import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/core/services/account/account.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SegmentService } from 'ngx-segment-analytics';
import { Router } from '@angular/router';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  isWrongCode = false;
  assetPath = environment.assetPath;
  waitingForResponse;
  termsUrl = `https://www.attributionapp.com/terms/`;
  privacyUrl = `https://www.attributionapp.com/privacy/`;
  emailTaken = false;
  alreadyClicked = false;

  constructor(
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private segment: SegmentService,
    private router: Router) { }

  form = this.fb.group({
    inviteCode: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  ngOnInit() {
    this.onValueChanges();

    if (environment.production && environment.segmentIoWriteKey !== '') {
      if (environment.envName === 'staging') {
        this.segment.load(environment.segmentIoWriteKey);
      } else {
        this.segment.load(environment.segmentIoWriteKey, {
          integrations: {
            All: false,
            Attribution: true,
            Intercom: true,
            'Segment.io': true
          }
        });
      }
    }

    const Properties = {
      url: window.location.origin + window.location.pathname + window.location.search,
      path: window.location.pathname,
      search: window.location.search
    };
    this.segment.page('Signup', null, Properties);
  }

  submit() {
    if (this.alreadyClicked) return;
    if (this.form.valid && !this.emailTaken) {
      this.alreadyClicked = true;
      this.accountService.postValidate(
        this.form.get('inviteCode').value,
        this.form.get('email').value,
        this.form.get('password').value
      ).subscribe( (resp: any) => {
        if (resp.token) {
          const demo = environment.envName === 'demo';
          const tokenAlreadySet = false;
          const email = this.form.get('email').value;
          const password = this.form.get('password').value;
          this.authService.login(email, password, demo, tokenAlreadySet).subscribe(
            (respProject) => {
              this.waitingForResponse = false;
              this.router.navigate([ComponentNames.account, SettingsSections.billing]);
            }, (error) => {
              this.waitingForResponse = false;
              this.alreadyClicked = false;
              throw new Error(error);
            }
          );
        } else this.alreadyClicked = false;
      });
    }
  }

  onValueChanges(): void {
    this.form.controls.email.valueChanges.subscribe(email => {
      this.accountService.getValidate(email).subscribe( (resp: Array<any>) => {
        if (resp.length > 0) {
          this.emailTaken = true;
        } else {
          this.emailTaken = false;
        }
      });
    });
  }

}
