import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const convertFiltersToArrayParams = (queryParams) => {
  let params = {...queryParams};
  if (typeof(params.filter) == 'object' && Array.isArray(params.filter)) {
    delete params.filter;
    params['filter[]'] = queryParams.filter;
  }
  return params;
}

@Injectable({
  providedIn: 'root'
})

export class ChannelBuilderService {
  reportingApiUrl;

  constructor(private httpClient: HttpClient) {
    this.reportingApiUrl = environment.reportingApiUrl;
   }

  getParams(section, queryParams) {
    const params = convertFiltersToArrayParams(queryParams);
    let Url;
    if (section == 'params') Url = this.reportingApiUrl + '/v2/builder/params';
    else if (section == 'referrers') Url = this.reportingApiUrl + '/v2/builder/referrers';
    else if (section == 'paths') Url = this.reportingApiUrl + '/v2/builder/paths';
    return this.httpClient.get(Url, { params: params});
  }

  getKeys(queryParams) {
    const params = convertFiltersToArrayParams(queryParams);
    const Url = this.reportingApiUrl + '/v2/builder/params/keys';
    return this.httpClient.get(Url, { params: params});
  }

  getEvents(queryParams) {
    const params = convertFiltersToArrayParams(queryParams);
    const Url = this.reportingApiUrl + '/v2/builder/events';
    return this.httpClient.get(Url, { params: params});
  }
}
