<div class="live-debug__wrapper">
  <div class="live-debug__header">
    <div class="row">
      <div class="col-7">
        <h1>Events Stream</h1>
        <p class="medium-new">
          Inspect the events that are being sent to your project in real-time. This is useful for debugging and verifying that your events are being sent correctly.
        </p>
      </div>
      <div class="col-5 d-flex align-items-center justify-content-center">
        <button class="btn btn-new" (click)="toggleDebug()">
          @if (!debugStarted) {
            Start Live-Stream
            <i class="fa-solid fa-play fa-fw green"></i>
          } @else {
            Stop Live-Stream
            <i class="fa-solid fa-stop fa-fw red"></i>
          }
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="debugData.length > 0" class="debug-content row g-0">
    <div class="col-lg-6 debug-side debug-table">
      <div *ngFor="let item of debugData.slice().reverse()" class="w-100 w-animation">
        <div *ngIf="!item.value.error; else errorRow" class="data-row" [class.sysMsg]="item.value.sysMsg" [class.selected-row]="item.index == selectedItemIndex" [class.anonymous-highlight]="selectedAnonymousId && item.index != selectedItemIndex && (item.value.anonymousId || item.value.cookie_id) == selectedAnonymousId" (click)="item.value.sysMsg ? null : showRaw(item)">
          <div class="data-row__status">
            <!-- <i class="fa-regular fa-circle-check fa-2x green"></i> -->
            <svg width="30" height="30" [data-jdenticon-value]="item.value.anonymousId || item.value.cookie_id"></svg>
          </div>
          <div class="data-row__type">
            <span class="text-capitalize">{{ item | liveDebugCalculateType }}</span>
          </div>
          <div class="data-row__name">
            <div class="data-row__name__inside">
              @switch (item | liveDebugCalculateType) {
                @case ('page') {
                  <strong *ngIf="item | liveDebugGetUserId">{{ item | liveDebugGetUserId }}</strong> {{ item.value.properties?.url ? (item.value.properties.url | liveDebugTakePath) : item.value.event }}
                }
                @case ('identify') {
                  <strong *ngIf="item | liveDebugGetUserId">{{ item | liveDebugGetUserId }}</strong> {{ item.value.traits?.email }}
                }
                @case ('track') {
                  <strong *ngIf="item | liveDebugGetUserId">{{ item | liveDebugGetUserId }}</strong> {{ item.value.event }}
                }
                @case ('alias') {
                  <strong *ngIf="item | liveDebugGetUserId">{{ item | liveDebugGetUserId }}</strong> {{ item.value.previous_id || item.value.previousId }}
                }
                @case ('group') {
                  <strong *ngIf="item | liveDebugGetUserId">{{ item | liveDebugGetUserId }}</strong> {{ item.value.group_id || item.value.groupId }}
                }
                @case ('*') {
                  <strong *ngIf="item | liveDebugGetUserId">{{ item | liveDebugGetUserId }}</strong> {{ item.value }}
                }
                @default {
                  <strong *ngIf="item | liveDebugGetUserId">{{ item | liveDebugGetUserId }}</strong> {{ item.value.event }}
                }
              }
            </div>
          </div>
          <div class="data-row__time">
            <div>{{ item.value.timestamp | date: 'MM.dd.yyyy' }}</div>
            <div class="medium-new">{{ item.value.timestamp | date: 'h:mm:ss a' }}</div>
          </div>
        </div>
        <ng-template #errorRow>
          <div class="data-row">
            <div class="data-row__status">
              <i class="fa-regular fa-circle-xmark red"></i>
            </div>
            <div class="data-row__type">
              Error
            </div>
            <div class="data-row__name">
              {{ item.value.error }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-lg-6 debug-side debug-raw">
      <div class="debug-raw__inner">
        <pre *ngIf="raw" [innerHtml]="raw | prettyjson: [true, 3]"></pre>
      </div>
    </div>
  </div>
</div>
