import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { ToggleProperties } from 'src/app/store/actions/selected.actions';
import { environment } from 'src/environments/environment';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { ReportState, ReportStateModel } from 'src/app/store/states/report.state';
import { PropertiesStateModel, PropertiesState } from 'src/app/store/states/properties.state';
import { Project } from 'src/app/core/models/project.model';
import { SetReportNeedsToLoad } from 'src/app/store/actions/report.actions';
import { SetAggregationPeriod } from 'src/app/store/actions/project.actions';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { PropertyService } from 'src/app/core/services/property/property.service';

@Component({
  selector: 'app-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss']
})
export class ControlBarComponent implements OnInit {
  @Select(state => state.selected.currentComponent) currentComponent$: Observable<string>;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(PropertiesState) propertiesState$: Observable<PropertiesStateModel>;
  @Select(state => state.properties.knownKeys) knownKeys$: Observable<String[]>;
  @Select(state => state.base.refresher) refresher$: Observable<any>;
  @Select(ReportState) report$: Observable<ReportStateModel>;
  @Select(state => state.selected.isPropertiesOpened ) isPropertiesOpened$: Observable<boolean>;

  currentComponent: any = ComponentNames.dashboard;
  componentNames = ComponentNames;
  refreshedAt;
  properties: PropertiesStateModel;
  isProd = environment.isProd;
  isPropertiesOpened = false;
  reportIsLoading = true;
  project: Project;
  reportNeedsToLoad;
  paramsWereChanged;
  refresher;

  shouldShowConversionEvents;
  shouldShowPropertiesTab;
  attrModelSelectorIsNotOnLeft;
  shouldShowAggregationPeriod;
  shouldShowAttrModel;
  shouldShowProjectOptions;
  shouldShowAttributionType;
  shouldShowViews;

  shouldShowLookback;
  shouldShowRevenueGroups;

  isTimeframe: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private propertyService: PropertyService
  ) {}

  ngOnInit() {
    combineLatest([this.currentComponent$, this.project$]).subscribe(([currentComponent, project]) => {
      this.project = project.project;
      this.currentComponent = currentComponent;
    });
    this.refresher$.subscribe( resp => {
      this.refresher = resp;
      if (resp && resp.generated_at) {
        this.refreshedAt = resp.generated_at;
      } else {
        this.refreshedAt = null;
      }
    });
    this.report$.subscribe( resp => {
      this.reportIsLoading = resp.inProgress;
      this.paramsWereChanged = resp.paramsWereChanged;
      this.reportNeedsToLoad = resp.needsToLoad;
    });
    this.propertiesState$.subscribe( resp => {
      this.properties = resp;
    });
    combineLatest([this.knownKeys$, this.isPropertiesOpened$]).subscribe(([knownKeys, isPropertiesOpened]) => {
      this.isPropertiesOpened = isPropertiesOpened;
      if (isPropertiesOpened && !knownKeys) {
        this.propertyService.getKeys();
      }
    });
    this.route.queryParams.subscribe( qPArams => {
      this.isTimeframe = qPArams.timeframe == 'true' ? true : false;
    });
  }

  aggregationPeriodChanged(selectedPeriod) {
    this.store.dispatch(new SetAggregationPeriod(selectedPeriod));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, period: selectedPeriod}
    });
  }

  toggleProperties() {
    this.store.dispatch(new ToggleProperties(true));
  }

  loadReport() {
    if (!this.reportNeedsToLoad && this.paramsWereChanged && !this.reportIsLoading) this.store.dispatch(new SetReportNeedsToLoad());
  }

}
