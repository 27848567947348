<form class="form-inline" id="search-filter" [ngClass]="{'in-v-filter-modal': isVFilterModal, 'only-search': onlySearch}">
  <div class="position-relative input-group">
    <input type="text" class="form-control default-font-size min-width-350" placeholder="{{selectOnlyFilters ? 'Enter Filter name' : 'Enter Filter or Channel name' }}"
      #inp
      [(ngModel)]="foundFilter"
      [ngbTypeahead]="findFilters"
      [inputFormatter]="searchFormatter"
      [resultFormatter]="searchFormatter"
      [editable]='false'
      (selectItem)="showSelectedFilter($event)"
      name="filter-search-input"
      (ngModelChange)="toggleSelect(inp, true)"
      (focus)="toggleSelect(inp,true)"
      (focusout)="toggleSelect(inp)"
    />
    <i *ngIf="waitingForAutoComplete" class="fa-solid fa-circle-notch green fa-lg fa-spin spin-inside"></i>
    <i id="search-button" *ngIf="onlySearch" class="fa-solid fa-magnifying-glass fa-lg medium"></i>
    <div *ngIf="!onlySearch">
      <button class="btn btn-outline-secondary h-100" type="button" *ngIf="isVFilterModal; else closeButton" (click)="toggleDropdown()">
        <i *ngIf="shouldShowSelect; else showCaret" class="fa-solid fa-xmark fa-lg medium"></i>
        <ng-template #showCaret>
          <i class="fa-solid fa-caret-down fa-lg medium"></i>
        </ng-template>
      </button>
      <ng-template #closeButton>
        <button class="btn btn-outline-secondary h-100" type="button" [class.btn-close-cohort]="!isOnDashboard" (click)="closeSearch()">
          <i class="fa-solid fa-xmark blue"></i>
        </button>
      </ng-template>
    </div>
  </div>
  <div *ngIf="shouldShowSelect" class="select-wrapper">
    <cdk-virtual-scroll-viewport [itemSize]="22.5" class="v-scroll-viewport">
      <ng-container *cdkVirtualFor="let item of filterTreeForSelect">
        <div *ngIf="item.id | filterSearchIsNotExcluded:excludedIds" class="select-item" [class.bold]="item.b" [class.medium]="item.t === 'label'" [class.clickable]="item.t != 'label'" (click)="selectFilter(item)">
          <span *ngFor='let space of item.s'>&nbsp;&nbsp;</span>
          {{item.n}}
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</form>
