<div class="attr-card mt-4">
  <div class="revenue-groups">
    <div class="row mb-4">
      <div class="col-8">
        <p class="title-no-bold" *ngIf="showTitle">Revenue Groups <i class="fa-solid fa-lock red me-1" *ngIf="!project?.options?.features?.revenue_groups"></i></p>
        <p class="small medium-dark">
          You can create multiple revenue groups by selecting only specific events (Include only) or you can exclude all but selected events (Exclude).
        </p>
      </div>
      <div class="col-4 text-right">
        <div *ngIf="revenueEvents.length > 0" class="dropdown rev-grp-dropdown" ngbDropdown>
          <button #newGroupDropdown class="btn btn-default dropdown-toggle btn-select" type="button" id="dropdownMenuButton" [attr.data-boundary]="newGroupDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <i class="fa-solid fa-plus"></i>
            Add Revenue Group
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
            <span class="dropdown-item" (click)="addNewGroup('include_only')">
              Include Only
            </span>
            <span class="dropdown-item" (click)="addNewGroup('exclude')">
              Exclude
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isCreatingNewGroup">
      <div *ngIf="errorMessage" class="alert alert-danger mb-2" role="alert">
        {{ errorMessage }}
      </div>
      <input [(ngModel)]="newGroupName" class='form-control' type='text' placeholder='Enter Group Name'>
      <div class="row">
        <div *ngFor="let event of revenueEvents; let i = index;" class="col-4 mt-3 clickable" (click)="addOrRemoveEventToNewGroup(event.name)">
          <i class="fa-solid" [ngClass]="{'fa-square-check': newGroup.type == 'include_only', 'fa-square-minus': newGroup.type == 'exclude', 'medium': !newGroupIncludesEvent(event.name), 'green': newGroup.type == 'include_only' && newGroupIncludesEvent(event.name), 'red': newGroup.type == 'exclude' && newGroupIncludesEvent(event.name)}"></i> {{ event.name }}
        </div>
      </div>
      <div class="mt-3 mb-3">
        <button class="btn btn-success d-inline-block" type="button" id="dropdownMenuButton" (click)="createNewGroup()">Create</button>
        <button class="btn btn-default d-inline-block ms-2" type="button" id="dropdownMenuButton" (click)="newGroup = null; newGroupName = ''">Cancel</button>
      </div>
    </div>
    <div class="d-inline">
      <div class="revenue-group d-inline-block" [class.default]="!project?.settings?.default_revenue_group">
        <div class="d-flex justify-content-between align-items-center">
          <div class="group-name">All Revenue</div>
          <div>
            <span *ngIf="!project?.settings?.default_revenue_group" class="badge badge-warning">Default</span>
          </div>
        </div>
        <div class="mt-1 mb-1">
          All revenue events included
        </div>
        <div class="text-center mt-3" *ngIf="project?.settings?.default_revenue_group">
          <button class="btn btn-default btn-sm" (click)="makeDefault(null)">
            Make Default
          </button>
        </div>
      </div>
    </div>
    <div class="d-inline" *ngIf="isThereRevenueGroups">
      <div *ngFor="let group of project?.settings?.revenue_groups" class="revenue-group d-inline-block" [class.default]="group.id == project?.settings?.default_revenue_group">
        <div class="d-flex justify-content-between align-items-center">
          <div class="group-name">{{ group.name }}</div>
          <div>
            <span *ngIf="group.id == project?.settings?.default_revenue_group" class="badge badge-warning me-3">Default</span><i class="fa-solid fa-trash red clickable" (click)="deleteGroup(group.id)"></i>
          </div>
        </div>
        <div *ngFor="let event of group.events" class="mt-1 mb-1">
          <i class='fa-solid me-1' [ngClass]="{'green fa-square-check': group.type == 'include_only', 'red fa-square-minus': group.type == 'exclude'}"></i>{{event}}
        </div>
        <div class="text-center mt-3" *ngIf="group.id != project?.settings?.default_revenue_group">
          <button class="btn btn-default btn-sm" (click)="makeDefault(group.id)">
            Make Default
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
