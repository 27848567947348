import { Component, Input, AfterViewInit, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import * as dragscroll from 'dragscroll';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';

@Component({
  selector: 'app-combo-chart',
  templateUrl: './combo-chart.component.html',
  styleUrls: ['./combo-chart.component.scss']
})
export class ComboChartComponent implements AfterViewInit, OnChanges {
  @Input() index;
  @Input() channel;
  @Input() period;
  @Input() chartHeight;
  @Input() isScrollable = true;
  @Input() marginBottom = 65;
  @Input() tooltipName;
  rendered = false;
  // chart configuration settings
  minBarWidth = 15;
  baseChartHeight = 150;
  maxColumns = 30;
  margin;

  axisTextColor = '#6c6a71';
  colorNegative = '#7d3c36';
  colorPositive = '#338057';
  colors = [
    '#bd6d65',
    '#60bf8d'
  ];

  constructor(private tooltipService: TooltipService) { }

  ngOnChanges() {
    if (!this.rendered) { return; }
    return this.drawChart();
  }

  ngAfterViewInit() {
    this.rendered = true;
    return this.drawChart();
  }

  drawChart() {
    // remove prone graph if any
    d3.select(`#cp-chart-${this.index}`).select('svg').remove();
    d3.select(`#cp-chart-all`).select('svg').remove();

    this.margin = {top: 10, right: 0, bottom: this.marginBottom, left: 80};

    const wrapperProperties = document.getElementsByClassName('combo-charts-wrapper')[0].getBoundingClientRect();

    if (this.chartHeight) {
      d3.select(`#cp-chart-${this.index}`).style('height', this.chartHeight + 'px');
    }

    const colors = d3.scaleOrdinal(this.colors);

    const columnsToShow = this.channel.length >= this.maxColumns ? this.maxColumns : this.channel.length;
    // width to fit bar and gap
    let barWidth = (wrapperProperties.width - this.margin.left)  / columnsToShow;
    barWidth = (barWidth / 2) < this.minBarWidth ? this.minBarWidth * 2 : barWidth;
    // all bars width
    const scaleWidth = barWidth * this.channel.length - barWidth * 0.4;
    // make accessible to local scopes
    const isScrollable = (this.isScrollable && wrapperProperties.width < scaleWidth) ? true : false;

    const formatCurrency = d3.format('-$,.0f');

    const series = d3.stack()
          .keys(['spend', 'revenue'])
          .offset(d3.stackOffsetDiverging)
          (this.channel);

    let svg;
    if (this.index) {
      svg = d3.select(`#cp-chart-${this.index}`).append('svg');
    } else {
      svg = d3.select(`#cp-chart-all`).append('svg');
    }
    svg.attr('height', this.margin.top + this.baseChartHeight + this.margin.bottom + 5);

    if (isScrollable) {
      svg
        .attr('width', scaleWidth + this.margin.left)
        .classed('cursor-grab', true);
    } else {
      svg
        .attr('width', wrapperProperties.width);
    }

    const dateArray = this.channel.map( (item, _) => item.label);

    const x = d3.scaleBand()
      .domain(dateArray)
      .paddingOuter(0.3)
      .paddingInner(isScrollable ? 0.5 : 0.3)
      .range([0, (isScrollable ? scaleWidth : wrapperProperties.width - this.margin.left)]);

    const height: any = svg.attr('height');
    const width: any = svg.attr('width');

    function stackMin(s) {
      return d3.min(s, d => d[0]);
    }

    function stackMax(s) {
      return d3.max(s, d => d[1]);
    }

    const min = parseInt(d3.min(series, stackMin));
    const max = parseInt(d3.max(series, stackMax));

    const y = d3.scaleLinear()
          .domain([min, max])
          .rangeRound([height - this.margin.bottom, this.margin.top]);

    // render left Axis
    const yAxis = svg.append('g')
      .attr('transform', `translate(${this.margin.left - 5},0)`)
      .call(
        d3.axisLeft(y)
          .tickSize(-(width - this.margin.left - 5))
          .tickPadding(10)
          .tickFormat(d => formatCurrency(d))
          .ticks(5, 's')
      )
      .call( g => g.select('.domain').remove());

    yAxis.selectAll('line')
      .attr('stroke', '#eeeeee');

    // these functions are for calculating filler rectangle heights
    function getFillerHeightPositive(h) {
      if ( (y(0) - y(h)) > 3 ) {
        return 3;
      } else if ((y(0) - y(h)) > 2 ) {
        return 2;
      } else if ((y(0) - y(h)) > 1 ) {
        return 1;
      } else {
        return 0;
      }
    }

    function getFillerHeightNegative(h) {
      if ( (y(h) - y(0)) > 3 ) {
        return 3;
      } else if ((y(h) - y(0)) > 2 ) {
        return 2;
      } else if ((y(h) - y(0)) > 1 ) {
        return 1;
      } else {
        return 0;
      }
    }

    // draw fillers
    svg.append('g')
      .attr('transform', `translate(${this.margin.left}, 0)`)
      .selectAll('g')
      .data(series)
      .enter().append('g')
        .attr('fill', d  => colors(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
        .attr('width', x.bandwidth())
        .attr('x', d => {
          return x(d.data.label);
        })
        .attr('y', d => {
          // positive & negative start on different places
          if ( d[0] < 0 ) {
            return y(0);
          } else {
            return y(0) - getFillerHeightPositive(d[1]);
          }
        })
        .attr('height', d => {
          if (d[0] < 0) {
            return getFillerHeightNegative(d[0]);
          } else {
            return getFillerHeightPositive(d[1]);
          }
        } );

    // rectangles
    svg.append('g')
      .attr('transform', `translate(${this.margin.left}, 0)`)
      .selectAll('g')
      .data(series)
      .enter().append('g')
        .attr('fill', d => colors(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
        .attr('width', x.bandwidth())
        .attr('x', d => {
          return x(d.data.label);
        })
        .attr('y', d => y(d[1]))
        .attr('rx', '4px')
        .attr('height', d => y(d[0]) - y(d[1]))
          .on('mouseover', (e, d: any) => {
            this.tooltipService.mouseOver.emit({
              name: this.tooltipName,
              data: { data: (d.data == null ? d : d.data) }
            });
        })
          .on('mouseout', () => this.tooltipService.mouseOut.emit());

    // ROAS dot
    svg.append('g')
      .attr('transform', `translate(${this.margin.left}, 0)`)
      .selectAll('g')
      .data(this.channel)
      .enter().append('circle')
        .attr('fill', d => {
          if (d.roas > 0 ) {
            return this.colorPositive;
          } else if ( d.roas < 0) {
            return this.colorNegative;
          } else { return 'gray'; }
        })
        .attr('cx', d => {
          return x(d.label) + x.bandwidth() / 2;
        })
        .attr('cy', (d: any) => y(d.roas))
        .attr('r', (d: any) => (d.revenue === 0 && d.spend === 0) ? 0 : 5)
          .on('mouseover', (e, d: any) => this.tooltipService.mouseOver.emit({
            name: this.tooltipName,
            data : { data: (d.data == null ? d : d.data) }
          }))
          .on('mouseout', () => this.tooltipService.mouseOut.emit());

    // draw x axis
    const xAxis = svg.append('g')
      .attr('transform', `translate(${this.margin.left}, ${height - this.margin.bottom + 5})`)
      .call(
        d3.axisBottom(x)
          // .ticks(this.channel.length)
          .tickValues(dateArray)
          .tickSize(0)
      )
      .call( g => g.select('.domain').remove())
      .selectAll('text')
        .style('text-anchor', 'end')
        .attr('transform', 'rotate(-45)');


    yAxis.selectAll('text')
      .style('color', this.axisTextColor);
    xAxis.selectAll('text')
      .style('color', this.axisTextColor);

    dragscroll.reset();
  }

}
