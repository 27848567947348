import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardGetFilterPath'
})
export class DashboardGetFilterPathPipe implements PipeTransform {
  transform(item: any, allFilters: any[], allChannels: any[]): any {
    let path;
    if (item.t === 'g') {
      path = allChannels?.find( c => c.id === item.id)?.path;
    } else {
      path = allFilters?.find( f => f.id === item.id)?.path;
    }

    if (!path) return null;

    path = path.names.join(' > ');

    return path === item.n ? null : (path + ` > ${item.n}`);
  }
}