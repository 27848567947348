import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, timeout, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { jwtDecode } from "jwt-decode";
import { Store } from '@ngxs/store';
import { SegmentService } from 'ngx-segment-analytics';

import { environment } from 'src/environments/environment';
import { IAuthResponse } from './auth.model';
import { ProjectService } from '../project/project.service';
import { AccountService } from '../account/account.service';
import { Logout } from 'src/app/store/actions/logout.action';
import { forkJoin } from 'rxjs';
import { SetIsAuthenticated } from 'src/app/store/actions/base.actions';
import { ComponentNames } from '../../enums/component-names';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.clientApiUrl;
  environment: any = environment;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private store: Store,
    private projectService: ProjectService,
    private accountService: AccountService,
    private segment: SegmentService) {}

  get isAuthenticated() {
    const Token = localStorage.getItem('token');
    return !!Token;
  }

  get getTokenProjectId() {
    const Token = this.decodeToken();
    return Token.project_id;
  }

  login(loginEmail, loginPassword, demo?, token?, otpToken?) {
    if (demo) {
      localStorage.setItem('token', this.environment.publicUserToken);
      this.store.dispatch(new SetIsAuthenticated(true));
      return this.getAccountAndProject();
    } else if (token) {
      localStorage.setItem('token', token);
      this.store.dispatch(new SetIsAuthenticated(true));
      return this.getAccountAndProject();
    } else {
      const Url = this.apiUrl + `/authenticate`;
      let params: any = {email: loginEmail, password: loginPassword};
      if (otpToken) { params = {token: otpToken}; }

      return this.httpClient.post(Url, params).pipe(
        mergeMap((response: IAuthResponse) => {
            if (response.account_id) {
              this.segment.identify(response.account_id.toString());
              // this.segment.track('Logged In'); // handled on backend
            }
            localStorage.setItem('token', response.token);
            this.store.dispatch(new SetIsAuthenticated(true));
            return this.getAccountAndProject();
        }),
        timeout(10000)
      );
    }
  }

  getAccountAndProject() {
    return forkJoin({
      account: this.accountService.getAccountInfo(),
      project: this.projectService.getProjectInfo()
    }).pipe(map( resp => {
      return {...resp.project, registration_step: resp.account.registration_step, last_registration_step: resp.account.last_registration_step, partner: resp.account.partner};
    }), timeout(15000));
  }

  logout() {
    this.router.navigate([ComponentNames.login]).then(() => {
      this.clearStorage();
    });
  }

  clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
    this.store.dispatch(new Logout());
  }

  msLogin(code) {
    const Url = `${this.apiUrl}/authenticate/sso/microsoft`;
    return this.httpClient.post(Url, { code: code, callback_url: `${window.location.origin}/integration-callback.html`});
  }

  googleLogin(code) {
    const Url = `${this.apiUrl}/authenticate/sso/google`;
    return this.httpClient.post(Url, { code: code, callback_url: `${window.location.origin}/integration-callback.html?integration=googleads`});
  }

  private decodeToken(): any{
    const Token = localStorage.getItem('token');
    if (Token) {
      return jwtDecode(Token);
    }
    return undefined;
  }

}
