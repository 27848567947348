import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oauthsByStatus',
})
export class OauthsByStatusPipe implements PipeTransform {
  transform(oauths, status): any {
    let filtered;
    switch (status) {
      case 'active':
        filtered = oauths?.filter(oauth => (oauth.expired_at == null && oauth.identifier != null));
        break;
      case 'inactive':
        filtered = oauths?.filter(oauth => oauth.identifier == null);
        break;
      case 'expired':
        filtered = oauths?.filter(oauth => oauth.expired_at != null);
        break;
    }
    return filtered;
  }
}