<div class="attr-card mt-4">
  <div class="position_based_ratios">
    <div class="row">
      <div class="col-12">
        <p class="title-no-bold mb-4" *ngIf="showTitle">Position-based Attribution Ratios</p>

        <p class="medium-dark">Choose the percentage you want allocated to your first touch and last touch. The middle touches (however many are left) will be allocated in a linear fashion.</p>

        <div class="form-inline row ratios">
          <div class="form-group col-2 flex-column">
            <label for="firstRatio">First-touch</label>
            <input class="form-control form-control-lg" type="number" min="0" max="100" name="firstRatio" id="firstRatio" [(ngModel)]="firstTouch" (ngModelChange)="alignPBR(0)">
          </div>
          <div class="form-group col-2 flex-column">
            <label for="middleRatio">Middle</label>
            <input class="form-control form-control-lg" type="number" min="0" max="100" name="middleRatio" id="middleRatio" [(ngModel)]="middle" disabled>
          </div>
          <div class="form-group col-2 flex-column">
            <label for="lastRatio">Last-touch</label>
            <input class="form-control form-control-lg" type="number" min="0" max="100" name="lastRatio" id="lastRatio" [(ngModel)]="lastTouch" (ngModelChange)="alignPBR(2)">
          </div>
          <div class="form-group col-2 flex-column justify-content-between">
            <label>&nbsp;</label>
            <div>
              <button class="btn btn-success color-initial" (click)="savePBR()">
                <span *ngIf="!pbrSaving; else waiting">
                  Save
                </span>
                <ng-template #waiting>
                  <i class="fa-solid fa-spinner fa-spin"></i>
                </ng-template>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
