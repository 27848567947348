import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash-es';

@Pipe({
  name: 'dashboardChannelOauthExpired'
})
export class DashboardChannelOauthExpiredPipe implements PipeTransform {
  transform(item: any, expiredOauthChannels: any): any {
    if (item.t === 'g') {
      const found =  find(expiredOauthChannels, oauth => {
        return oauth.filter_group_id === item.id;
      });
      if (typeof(found) === 'undefined') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}