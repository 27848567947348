import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

import { IntegrationAuthService } from 'src/app/core/services/integrations/integration-auth.service';

@Component({
  selector: 'app-marketo',
  templateUrl: './marketo.component.html',
  styleUrls: ['./marketo.component.scss']
})
export class MarketoComponent {
  @Input() integration;
  @Input() isOnboarding = false;
  @Input() step = 0;
  @Input() type = 'explainer';

  error = null;
  waitingForResponse = false;

  connectForm = this.fb.group({
    munchkinId: ['', [Validators.required]],
    clientId: ['', [Validators.required]],
    clientSecret: ['', [Validators.required]]
  });
  learnMoreLink: any;

  constructor(private service: IntegrationAuthService, public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private router: Router) {}

  connectLogin() {
    if (this.connectForm.invalid) return;
    const munchkinId = this.connectForm.get('munchkinId').value;
    const clientId = this.connectForm.get('clientId').value;
    const clientSecret = this.connectForm.get('clientSecret').value;

    this.waitingForResponse = true;
    this.service.connect(this.integration, { munchkin_id: munchkinId, client_id: clientId, client_secret: clientSecret}).subscribe( x => {
      this.waitingForResponse = false;
      this.activeModal.close();
      const path = this.isOnboarding ? [] : [ComponentNames.settings, SettingsSections.integrations];
      this.router.navigate(path,  { queryParams: {
        integration: this.integration.name
      }})
    }, e => {
      this.error = e.error;
    });
  }

  close() {
    this.activeModal.close('close');
  }

}
