export const hoverLine = {
  id: 'hoverLine',
  afterDatasetsDraw: function(chart, args, options) {
    const {firstLineSize, secondLineColor} = options;
    if (chart.tooltip?._active && chart.tooltip?._active.length) {
      const activePoint = chart.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.element.x;
      const y = activePoint.element.y;
      const bottomY = chart.scales.y.bottom;
      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = (activePoint.index >= firstLineSize) ? secondLineColor : activePoint.element.options.borderColor;
      ctx.setLineDash([5, 5]);
      ctx.stroke();
      ctx.restore();
    }
  }
};
