import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SetAttributionModel } from 'src/app/store/actions/selected.actions';
import { AttributionModels } from 'src/app/core/constants/attribution-models';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { environment } from 'src/environments/environment';
import { AttributionModel } from 'src/app/store/interfaces/selected';
import { View } from 'src/app/core/models/project.model';

@Component({
  selector: 'app-attribution-model-selector',
  templateUrl: './attribution-model-selector.component.html',
  styleUrls: ['./attribution-model-selector.component.scss']
})
export class AttributionModelSelectorComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  hideSelect = true;
  PBR;
  attributionModels = AttributionModels;
  selectedAttributionModel: AttributionModel;
  availableModels: Array<string> = [];
  isProd = environment.isProd;
  view: View;

  constructor(private store: Store) {}

  ngOnInit() {
    this.project$.subscribe(x => {
      this.PBR = x.project?.position_based_ratios;
      this.view = x.currentViewParams;
      this.selectedAttributionModel = AttributionModels.find(x => x.name === this.view?.attribution_model) || AttributionModels[0];
      this.availableModels = x.project?.options.attribution_models || [];
    });
  }

  selectModel(attrModel) {
    if (!this.isAvailable(attrModel) && this.isProd) return;
    this.selectedAttributionModel = attrModel;
    this.store.dispatch(new SetAttributionModel({ attributionModel: attrModel }));
  }

  closeSelect(btn) {
    this.hideSelect = !this.hideSelect;
    btn.classList.remove('hide');
  }

  isAvailable(x) {
    if (this.availableModels.length > 0) {
      return this.availableModels.includes(x.name);
    } else {
      return true;
    }
  }

}
