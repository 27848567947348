<div class='property-values-wrapper'>
  <div class='btn btn-default btn-select' [class.disabled]='!buttonEnabled' #btn (click)="buttonEnabled ? openSelect(btn,select) : null">
    {{ valuesLoading ? 'Loading...' : eventPropertyValue ? eventPropertyValue : 'Select one...'}}
    <span class='caret'></span>
  </div>
  <ng-select #select class='ng-form-control'
    [class.ng-select-hide]='hideSelect'
    [items]="valuesLoading ? [] : knownValues"
    bindLabel="name"
    placeholder="Property value"
    (close)="closeSelect()"
    [clearable]="true"
    [(ngModel)]="eventPropertyValue"
    [addTag]="true"
    (ngModelChange)="selectEventPropertyValue()">
    <ng-template ng-option-tmp let-item="item">
      <span> {{ item }}</span>
    </ng-template>
  </ng-select>
</div>
