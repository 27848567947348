<h1 class="mt-0">GDPR Regulations</h1>

<div class="attr-card">
  <p class="title-no-bold mb-4">Delete personal information for particular users within Attribution. Upload a CSV with the users' ID (one user ID per line) to delete all associated personal information from Attribution's system.</p>

  <form *ngIf="projectApiToken" class="form">
    <div class="form-group">
      <div *ngIf="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <label for="csvFile" >
        <span class="btn btn-default cursor-pointer">
          <i class="fa-solid fa-upload blue"></i>
          Upload CSV
        </span>
      </label>
    </div>
    <input data-file name="csvFile" type="file" id="csvFile" style="position: fixed; top: -100em;" ng-model="csvFile" (change)="onUpload($event.target.files)">
  </form>

  <div *ngIf="uploadConfirmed">
    <div class="alert alert-info" *ngIf="regulation_id">
      All personal infomation for the uploaded users is scheduled to be deteleted.<BR>You can query your regulation status  <a href="https://docs.attributionapp.com/reference/getregulation"  target="_blank">using API</a>.<BR><BR>Regulation ID: <strong>{{ regulation_id }}</strong>
    </div>
  </div>

  <div class="" *ngIf="regulation_id">
    <h4 style="margin-top: 0">
      Query now in your terminal:
    </h4>
    <pre class="code">curl --request GET \
  --url https:{{ environment.clientApiUrl }}/regulations/{{ regulation_id }} \
  --header 'accept: application/json' \
  --header 'authorization: Bearer {{ iAmProjectOwner ? projectApiToken : 'YOUR_PROJECT_API_TOKEN' }}'</pre>
    <p *ngIf="!iAmProjectOwner">
      Please ask the project owner (<a href="mailto:{{ projectOwnerEmail }}">{{ projectOwnerEmail }}</a>) if you need to know YOUR_PROJECT_API_TOKEN.
    </p>
  </div>
</div>
