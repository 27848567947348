<ng-template
  #switches>
  <div class="d-flex align-items-center notification-row justify-normal mb-1 blue">
    <i class="fa-solid fa-circle-exclamation blue fa-lg me-3"></i> Choose what you'd like to be emailed about
  </div>
  <div>
    <div *ngIf="wasSuccess" class="alert alert-success" role="alert">
      {{ wasSuccess }}
    </div>
    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
    <div class="attr-card">
      <div class="notification-row">
        <div>
          <p class="title-no-bold">Global Notification Setting</p>
          <p class="medium-dark me-5">Enable/disable this setting to receive weekly marketing emails and summary emails from Attribution.<br>To only receive summary emails from certain projects, please toggle the settings below.</p>
        </div>

        <app-toggle-switch
          [isOn]="accountIsOn"
          (eventEmitter)="accountIsOn = !accountIsOn;">
        </app-toggle-switch>
      </div>
    </div>

    <div class="attr-card mt-4">
      <div class="project-notifications-wrapper" [class.disabled]="!accountIsOn">
        <p class="title-no-bold notification-row">Per project weekly notifications</p>
        <div *ngFor="let project of projects" class="ps-4 notification-row">
          <span class="me-5">{{ project.name }}</span>
          <app-toggle-switch
            [disabled]="!accountIsOn"
            [isOn]="accountIsOn && project.active"
            (eventEmitter)="project.active = !project.active" ng-class="small">
          </app-toggle-switch>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="isSettings; else standalone">
  <ng-container *ngTemplateOutlet="switches"></ng-container>
  <button class="mt-5 btn btn-lg btn-success oos-button" (click)="submit();">
    <span *ngIf="!waitingForResponse; else waiting">
      Update Preferences
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>
<ng-template #standalone>
  <div class="oos-wrapper">
    <div class="container-fluid">
      <a [routerLink]="'/'" class="btn-cta caps">back to website</a>
    </div>
    <div class="container">
      <div class="oos-title">
        <img src="{{ assetPath }}/assets/images/mark.svg" width="65" alt="Attribution" />
        <h1>Attribution</h1>
        <h4>Notification Preferences</h4>
      </div>
      <div>
        <div class="oos-body modal-body d-flex justify-content-center align-items-center flex-column">
          <ng-container *ngTemplateOutlet="switches"></ng-container>
        </div>
        <div class="oos-footer">
          <button class="btn btn-lg btn-success oos-button" (click)="submit();">
            <span *ngIf="!waitingForResponse; else waiting">
              Update Preferences
            </span>
            <ng-template #waiting>
              <i class="fa-solid fa-spinner fa-spin"></i>
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
