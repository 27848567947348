import { State, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetUsage, SetNeedReportRefresh, SetUsageReport, SetSubscription, SetProducts, SetCard, SetPrevSubscription, SetAccesses, SetTwitterTokens, ClearUsages } from '../actions/settings.actions';

export interface SettingsStateModel {
  usage: any;
  usageReport: any;
  subscription: any;
  prevSubscription: any;
  products: Array<any>;
  card: any;
  accesses: any;
  needReportRefresh: boolean;
  twitterTokens: {
    request_token: string;
    request_token_secret: string;
  };
}

@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    usage: null,
    usageReport: null,
    subscription: null,
    prevSubscription: null,
    products: null,
    card: null,
    accesses: null,
    needReportRefresh: false,
    twitterTokens: {
      request_token: null,
      request_token_secret: null
    }
  }
})
@Injectable()
export class SettingsState {
  constructor() {}

  @Action(SetUsage)
  SetUsage(ctx: StateContext<SettingsStateModel>, { payload }: SetUsage) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      usage: payload
    });
  }

  @Action(SetUsageReport)
  SetUsageReport(ctx: StateContext<SettingsStateModel>, { payload }: SetUsageReport) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      usageReport: payload
    });
  }

  @Action(SetSubscription)
  SetSubscription(ctx: StateContext<SettingsStateModel>, { payload }: SetSubscription) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      subscription: payload
    });
  }

  @Action(SetPrevSubscription)
  SetPrevSubscription(ctx: StateContext<SettingsStateModel>, { payload }: SetPrevSubscription) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      subscription: payload
    });
  }

  @Action(SetCard)
  SetCard(ctx: StateContext<SettingsStateModel>, { payload }: SetCard) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      card: payload
    });
  }

  @Action(SetProducts)
  SetProducts(ctx: StateContext<SettingsStateModel>, { payload }: SetProducts) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      products: payload
    });
  }

  @Action(SetNeedReportRefresh)
  SetNeedReportRefresh(ctx: StateContext<SettingsStateModel>, { payload }: SetNeedReportRefresh) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      needReportRefresh: payload
    });
  }

  @Action(SetAccesses)
  SetAccesses(ctx: StateContext<SettingsStateModel>, { payload }: SetAccesses) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      accesses: payload
    });
  }

  @Action(SetTwitterTokens)
  SetTwitterTokens(ctx: StateContext<SettingsStateModel>, { payload }: SetTwitterTokens) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      twitterTokens: payload
    });
  }

  @Action(ClearUsages)
  ClearUsages(ctx: StateContext<SettingsStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      usage: null,
      usageReport: null,
    });
  }
}