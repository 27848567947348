import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetFilterWorkerInProgress, SetGroupWorkerInProgress } from 'src/app/store/actions/worker.actions';
import { findAllInTree, findInTree } from '../../helpers/find-in-tree';
import Rollbar from 'rollbar';
import { RollbarService } from '../../error-handler/rollbar-error-handler';
import { FilterTreeSetOrinals } from 'src/app/store/actions/filter-tree.actions';

@Injectable({
  providedIn: 'root'
})
export class FilterTreeWorkerService {
    filters: any = null;
    groups: any = null;
    filterWorker: Worker;
    groupWorker: Worker;
    ordinalWorker: Worker;

    projectNotToUseWorkers = [
        2716,
    ];

    constructor(private store: Store, @Inject(RollbarService) private rollbar: Rollbar)  {}

    setFilters(filters) {
        this.filters = filters;
        this.store.dispatch(new SetFilterWorkerInProgress(false));
    }

    setGroups(groups) {
        this.groups = groups;
        this.store.dispatch(new SetGroupWorkerInProgress(false));
    }

    postToWorker(data) {
        const projectId = this.store.selectSnapshot(state => state.project.project)?.id;
        if (this.projectNotToUseWorkers.includes(projectId) || !projectId) return;
        
        if (typeof Worker !== 'undefined') {
            this.filters = null;
            this.groups = null;
            
            if (this.filterWorker) this.filterWorker.terminate();
            this.filterWorker = new Worker(new URL('../../../workers/filterTree.worker', import.meta.url));
            if (this.filterWorker) {
                this.filterWorker.onmessage = ({ data }) => {
                    this.setFilters(data);
                    this.filterWorker.terminate();
                };
                this.store.dispatch(new SetFilterWorkerInProgress(true));
                this.filterWorker.postMessage(data);
            }

            if (this.groupWorker) this.groupWorker.terminate();
            this.groupWorker = new Worker(new URL('../../../workers/groupTree.worker', import.meta.url));
            if (this.groupWorker) {
                this.groupWorker.onmessage = ({ data }) => {
                    this.setGroups(data);
                    this.groupWorker.terminate();
                };
                this.store.dispatch(new SetGroupWorkerInProgress(true));
                this.groupWorker.postMessage(data);
            }
            
            if (this.ordinalWorker) this.ordinalWorker.terminate();
            this.ordinalWorker = new Worker(new URL('../../../workers/treeOrdinals.worker', import.meta.url));
            if (this.ordinalWorker) {
                this.ordinalWorker.onmessage = ({ data }) => {
                    this.store.dispatch(new FilterTreeSetOrinals(data));
                    this.ordinalWorker.terminate();
                };
                this.ordinalWorker.postMessage(data);
            }
        } else {
            this.rollbar.debug('Web workers are not supported in this environment.');
            let filtersObj = {};
            const filters = findAllInTree({c: data}, 'c', 't', 'f', false, false, true, [], true);
            filters.forEach( x => {
                filtersObj[x.id] = x;
            });
            this.setFilters(filtersObj);

            let groupsObj = {};
            const groups = findAllInTree({c: data}, 'c', 't', 'g', false, false, true, [], true);
            groups.forEach( x => {
                groupsObj[x.id] = x;
            });
            this.setGroups(groupsObj);
        }
    }

    findInTree({node, childrenKey, key, value,  additionalKey = null, additionalValue = null, returnParent = null, updateLevel = null, topParent = null, withPath = null}) {
        if (this.filters && additionalValue === 'f') {
            const filter = this.filters[value];
            if (filter) {
                filter.pathArray = filter.path?.names || [];
                return filter;
            } else return findInTree({node, childrenKey, key, value,  additionalKey, additionalValue, returnParent, updateLevel, topParent, withPath});
        } else if (this.groups && additionalValue === 'g') {
            const group = this.groups[value];
            if (group) {
                group.pathArray = group.path?.names || [];
                return group;
            } else return findInTree({node, childrenKey, key, value,  additionalKey, additionalValue, returnParent, updateLevel, topParent, withPath});
        } else {
            return findInTree({node, childrenKey, key, value,  additionalKey, additionalValue, returnParent, updateLevel, topParent, withPath});
        }
    }
    
}