import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SettingsStateModel } from 'src/app/store/states/settings.state';
import { Project, View } from 'src/app/core/models/project.model';
import { SetDisregardExistingUsers } from 'src/app/store/actions/project.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';

@Component({
  selector: 'app-disregard-existing-users',
  templateUrl: './disregard-existing-users.component.html',
  styleUrls: ['./disregard-existing-users.component.scss']
})
export class DisregardExistingUsersComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.settings) settings$: Observable<SettingsStateModel>;

  @Input() showTitle = true;
  @Input() isInModal = false;
  @Input() disabled = false;
  @Input() reason;

  project: Project;
  view: View;
  dataStatus;

  constructor(
    private store: Store,
    private tooltipService: TooltipService) { }

  ngOnInit(): void {
    this.project$.subscribe(state =>  {
      this.project = state.project;
      this.view = state.currentViewParams;
    });
    this.settings$.subscribe(resp => this.dataStatus = resp.usage);
  }

  setDisregardExistingUsers(e) {
    if (this.disabled) return;
    this.store.dispatch(new SetDisregardExistingUsers(e)).subscribe(() => {
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

  clearDEU() {
    if (this.disabled) return;
    this.store.dispatch(new SetDisregardExistingUsers(null)).subscribe(() => {
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    }); 
  }

  showDisabledTooltip() {
    this.tooltipService.mouseOver.emit({
      name: 'disabled',
      data: { data: {name: 'Disregard Existing Users', reason: this.reason} }
    });
  }

  hideDisabledTooltip() {
    this.tooltipService.mouseOut.emit()
  }

}
