import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Project, View } from 'src/app/core/models/project.model';
import { ViewsService } from 'src/app/core/services/views/views.service';
import { CreateNewProjectView, DeleteProjectView, SetAttributesFromView, SetCurrentViewId, UpdateProjectView } from 'src/app/store/actions/project.actions';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { NewViewModalComponent } from '../new-view-modal/new-view-modal.component';
import { CheckIfViewParamsChangedPipe } from './check-if-params-changed.pipe';
import { PropertiesStateModel } from 'src/app/store/states/properties.state';

@Component({
  selector: 'app-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss']
})
export class ViewSelectorComponent implements OnInit {
  @Select(state => state.project) projectState$: Observable<ProjectStateModel>;
  @Select(state => state.selected) selectedState$: Observable<SelectedStateModel>;
  nameInput: string;

  project: Project;
  currentViewParams: View;
  selectedState: SelectedStateModel;

  views: Array<View> = [];

  editNameId;
  currentComponent;
  currentView;

  constructor(
    private modalService: NgbModal,
    private viewsService: ViewsService,
    private store: Store,
    private toastr: ToastrService,
    private checkViewParamPipe: CheckIfViewParamsChangedPipe
  ) { }

  ngOnInit(): void {
    this.projectState$.subscribe(project => {
      this.project = project.project;
      this.currentViewParams = project.currentViewParams;
      this.views = this.project?.views;
      this.currentView = this.project?.views?.[this.project?.current_view_id];
    });
    this.selectedState$.subscribe(selected => {
      this.selectedState = selected;
      this.currentComponent = selected.currentComponent;
    });
  }

  selectView(view) {
    this.viewsService.updateCurrentView(view.id).subscribe( (resp:any) => {
      if (resp.status === 'ok') {
        this.store.dispatch([
          new SetAttributesFromView(view),
          new SetCurrentViewId(view.id)
        ]);
      }
    });
  }

  toggleNameInput(view) {
    this.nameInput = view.name;
    this.editNameId = this.editNameId === view.id ? null : view.id;
  }

  openNewViewModal() {
    if (this.missingViewParams.length > 0) {
      setTimeout(() =>
        this.toastr.warning(
          `Could not create new view, because following project parameters are missing: <br>
          ${this.missingViewParams.join(', ')}.`,
          `status: could not create new view`,
          {disableTimeOut: true, enableHtml: true}
        )
      );
      return;
    }

    const propertiesState: PropertiesStateModel = this.store.selectSnapshot(state => state.properties);

    let viewParams: View = {
      attribution_model: this.currentViewParams?.attribution_model,
      attribution_type: this.currentViewParams?.attribution_type,
      aggregation_period: this.currentViewParams?.aggregation_period,
      daterange_preset: this.selectedState.lastSelectedPreset,
      conversion_event: this.currentViewParams?.conversion_event,
      traffic_option: this.currentViewParams?.traffic_option,
      cutoff_event: this.currentViewParams?.cutoff_event,
      lookback_window: this.currentViewParams?.lookback_window,
      disregard_existing_users: this.currentViewParams?.disregard_existing_users || null,
      revenue_group_id: this.currentViewParams.revenue_group_id,
      hidden_filters: this.currentViewParams.hidden_filters,
      hidden_filter_groups: this.currentViewParams.hidden_filter_groups,
      property_key: propertiesState.event_property_key,
      property_value: propertiesState.event_property_value,
    };

    const modalRef = this.modalService.open(NewViewModalComponent, {windowClass: 'slideInDown'});
    modalRef.componentInstance.view = viewParams;
    modalRef.result.then(result => {
      if (result.name) {
        viewParams.name = result.name;
        if (!result.isPreset) {
          viewParams.daterange_preset = this.selectedState.chosenDates.start+','+this.selectedState.chosenDates.end;
        }

        this.viewsService.createView(viewParams).subscribe( (resp:any) => {
          if (resp.status == "ok") {
            this.store.dispatch(new CreateNewProjectView(resp.project_view)).subscribe(() => {
              this.selectView(resp.project_view);
            });
          }
        });
      }
    },
    e => console.log(e));
  }

  deleteView(view) {
    if (view.id == this.project.current_view_id) return;
    if(confirm("Are you sure to delete "+view.name+"?")) {
      this.viewsService.deleteView(view.id).subscribe( (resp:any) => {
        if (resp.status == "ok") {
          this.store.dispatch(new DeleteProjectView(view.id));
        }
      });
    }
  }

  saveView(view) {
    if (this.project.current_view_id !== view.id || !this.checkViewParamPipe.transform(this.currentViewParams, view, this.selectedState.chosenDates).changed) return;
    else if (this.missingViewParams.length > 0) {
      setTimeout(() =>
        this.toastr.warning(
          `Could not save view, because one or more of the following project parameters are missing: <br>
          ${this.missingViewParams.join(', ')}.`,
          `status: could not create new view`,
          {disableTimeOut: true, enableHtml: true}
        )
      );
      return;
    } 

    let updatedViewParams: View = {
      attribution_model: this.currentViewParams?.attribution_model,
      traffic_option: this.currentViewParams?.traffic_option,
      cutoff_event: this.currentViewParams?.cutoff_event,
      attribution_type: this.currentViewParams?.attribution_type,
      lookback_window: this.currentViewParams?.lookback_window,
      aggregation_period: this.currentViewParams?.aggregation_period,
      conversion_event: this.currentViewParams?.conversion_event,
      disregard_existing_users: this.currentViewParams?.disregard_existing_users || null,
      revenue_group_id: this.currentViewParams.revenue_group_id,
      daterange_preset: this.selectedState.lastSelectedPreset,
      hidden_filters: this.currentViewParams.hidden_filters,
      hidden_filter_groups: this.currentViewParams.hidden_filter_groups,
      property_key: this.currentViewParams.property_key,
      property_value: this.currentViewParams.property_value,
    };

    const modalRef = this.modalService.open(NewViewModalComponent, {windowClass: 'slideInDown'});
    modalRef.componentInstance.name = view.name;
    modalRef.componentInstance.view = updatedViewParams;

    modalRef.result.then(result => {
      if (result.name) {
        updatedViewParams.name = result.name;
        if (!result.isPreset) {
          updatedViewParams.daterange_preset = this.selectedState.chosenDates.start+','+this.selectedState.chosenDates.end;
        }

        this.viewsService.updateView(updatedViewParams, view.id).subscribe( (resp:any) => {
          if (resp.status == "ok") {
            this.store.dispatch(new UpdateProjectView(resp.project_view)).subscribe(() => {
              this.selectView(resp.project_view);
            });
          }
        });
      }
    },
    e => console.log(e));
  }

  renameView(newName, view) {
    if (newName == view.name) {
      this.editNameId = null;
      return;
    } else {
      let updatedView = {
        ...view,
        name: newName.value
      };
      const {id, account_id, project_id, ...rest} = updatedView;
      this.viewsService.updateView(rest, view.id).subscribe( (resp:any) => {
        if (resp.status == "ok") {
          this.store.dispatch(new UpdateProjectView(resp.project_view));
        }
      });
      this.editNameId = null;
    }
  }

  private get missingViewParams() {
    const VIEW_PARAMS_TO_CHECK = ['attribution_model', 'attribution_type', 'aggregation_period', 'conversion_event', 'traffic_option', 'daterange_preset'];
    const missingParams = [];
    
    VIEW_PARAMS_TO_CHECK.forEach(param => {
      if (!this.currentViewParams[param]) {
        missingParams.push(param);
      }
    });

    return missingParams;
  }
}
