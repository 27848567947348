import { getActionTypeFromInstance } from '@ngxs/store';
import { Logout } from './actions/logout.action';

import { DateTime } from 'luxon';

export function logoutPlugin(state, action, next) {

  // Use the get action type helper to determine the type
  if (getActionTypeFromInstance(action) === Logout.type) {

    // if we are a logout type, lets erase all the state
    state = {
      report: {
        inProgress: false,
        initialLoading: true,
        meta: null,
        report: null,
        needsToLoad: false,
        paramsWereChanged: true
      },
      cohort: {
        inProgress: false,
        meta: null,
        report: null,
        selectedFilter: null
      },
      channelPerformance: {
        inProgress: false,
        summary: null,
        summaryParams: null,
        prevSummary: null,
        prevSummaryParams: null,
        report: null,
        openChannelSummary: null,
        totals: null,
        comparison: null,
      },
      overview: {
        inProgress: false,
        summary: null,
        report: null
      },
      firstTimePurchase: {
        inProgress: false,
        meta: null,
        report: null,
        selectedFilter: null
      },
      timePeriods: {
        shouldShow: false,
        inProgress: false,
        meta: null,
        report: null
      },
      conversionEvents: {
        events: [],
        revenueEvents: []
      },
      sidebar: {
        openedSidebar: null,
        options: null
      },
      selected: {
        currentComponent: null,
        settingSection: null,
        chosenDates: {
          start: DateTime.now().minus({days: 30}).toFormat('y-MM-dd'),
          end: DateTime.now().minus({days: 1}).toFormat('y-MM-dd')
        },
        datesChanged: true,
        attributionModel: null,
        conversionEvent: null,
        cpOpenChannelId: null,
        isPropertiesOpened: false,
        revenueGroup: null,
        lastSelectedPreset: null,
      },
      filterTree: {
        filterTree: null,
        showZeros: [],
        refreshScheduled: false,
        inProgress: false,
        lastAction: null
      },
      project: {
        project: null,
        projectIsLoading: false,
        nextIdentifier: null,
        currentViewParams: null,
      },
      account: {
        id: null,
        email: null,
        name: null,
        partner: null,
        projects: [],
        signup_integration: null,
        subscription: null,
        shopify_store: null,
      },
      onboarding: {
        flow: null,
        current_step: null,
        last_step: null,
        max_steps: null
      },
      onlySimple: {
        sidebarName: null
      },
      base: {
        isAuthenticated: false,
        appVersion: '',
        fullVersion: '',
        refresher: {
          state: 'done',
          generated_at: '',
          eta: '',
          overtime: '',
          isVirtual: false
        },
        firstPurchaseOnly: false,
        isInitialLoad: true,
        pendingDataRefresh: {
          movingFrom: null,
          movingFromPath: [],
          filter: {},
          group: {}
        }
      },
      paths: {
        labels: 'filters',
        isCombineRecurring: false,
        filterType: 'none',
        filterOrGroup: null,
        report: null,
        inProgress: false,
        additionInProgress: false,
      },
      properties: {
        keysAreLoading: false,
        valuesAreLoading: false,
        knownKeys: null,
        knownValues: [],
        event_property_key: null,
        event_property_value: null,
        keysRequested: false
      },
      settings: {
        usage: null,
        usageReport: null,
        subscription: null,
        prevSubscription: null,
        products: null,
        card: null,
        accesses: null,
        needReportRefresh: false,
        twitterTokens: {
          request_token: null,
          request_token_secret: null
        }
      },
      worker: {
        filterWorkerInProgress: false,
      }
    };
  }

  // return the next function with the empty state
  return next(state, action);
}
