<div id="loginModal">
  <div class="modal show d-block">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="login-form-wrapper" *ngIf="section === 'login'">
          <form [formGroup]="loginForm" (ngSubmit)='submit({})'>
            <div class="modal-header">
              <h5 class="modal-title">{{ waitingTokenResponse != null ? waitingTokenResponse : 'Log in'}}</h5>
            </div>
            <div class="modal-body">
              <div class="loader-height" *ngIf="waitingTokenResponse; else noTokenLogin">
                <app-loader [position]="'relative'" [color]="'green'" [backgroundColor]="'white'" [height]="'70px'"></app-loader>
              </div>
              <ng-template #noTokenLogin>
                <div *ngIf='loginError' class='alert alert-danger'>
                  {{ loginError }}
                </div>
                <div class='form-group'>
                  <label>Email</label>
                  <input formControlName='email' class='form-control' type='email'>
                </div>
                <div class='form-group mt-3'>
                  <label>Password</label>
                  <input formControlName='password' class='form-control' type='password'>
                </div>
                <div *ngIf="!waitingTokenResponse" class="form-group pw-login mt-3">
                  <a class='btn btn-link caps' (click)="openForgotPassword()">Forgot Password</a>
                  <button type='submit' id='login-button' class="btn btn-success">
                    <span *ngIf="!waitingForResponse; else waiting" class='caps'>Log In</span>
                    <ng-template #waiting>
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </ng-template>
                  </button>
                </div>
              </ng-template>
            </div>
            <div *ngIf="!waitingTokenResponse" class="modal-footer sso-login">
              <button type="button" (click)="googleLogin()" class="google-button google-sso-button">
                <img src="{{ assetPath }}/assets/images/integrations/google-g.svg" width="38" height="38">Sign in with Google
              </button>
              <a class='btn btn-link' (click)="microsoftLogin()" class="ms-sso-button">
                <img src="{{ assetPath }}/assets/images/ms_logo.svg">Sign in with Microsoft
              </a>
            </div>
          </form>
        </div>
        <div class="forgot-password-form-wrapper" *ngIf="section === 'forgot_password'">
          <form *ngIf="!passwordResetSuccess" [formGroup]="forgotPasswordForm" (ngSubmit)='submitResetPassword()'>
            <div class="modal-header">
              <h5 class="modal-title">Reset Password</h5>
            </div>
            <div class="modal-body">
              <div *ngIf="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
              </div>
              <div class='form-group'>
                <label>Login Email</label>
                <input formControlName='email' class='form-control' type='email'>
              </div>
            </div>
            <div class="modal-footer">
              <a class='btn btn-link caps' (click)="backToLogin()">back to login</a>
              <button type='submit' id='login-button' class="btn btn-success">
                <span *ngIf="!waitingForResponse; else waiting" class='caps'>
                  Reset Password <i class="fa-solid fa-envelope"></i>
                </span>
                <ng-template #waiting>
                  <i class="fa-solid fa-spinner fa-spin"></i>
                </ng-template>
              </button>
            </div>
          </form>
          <div *ngIf="passwordResetSuccess">
            <div class="modal-header">
              <h5 class="modal-title">Reset Password</h5>
            </div>
            <div class="modal-body password-reset-success">
              <h1><i class="fa-solid fa-envelope green"></i></h1>
              <h3>Check your Email</h3>
              <p>A password reset notification has been sent to your email address. Click the link in the email to reset your password.</p>
            </div>
            <div class="modal-footer">
              <a class='btn btn-link caps' (click)="backToLogin()">back to login</a>
            </div>
          </div>
        </div>
        <div class="new-password-form-wrapper" *ngIf="section === 'accesses' || section === 'password_reset'">
          <form [formGroup]="changePasswordForm" (ngSubmit)='submitNewPassowrd()'>
            <div class="modal-header">
              <h5 class="modal-title">{{ section === 'accesses' ? 'Set Your Password' : 'Reset Password' }}</h5>
            </div>
            <div class="modal-body">
              <div *ngIf="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
              </div>
              <div class='form-group'  *ngIf="!errorMessage">
                <label>Please type your new password</label>
                <input formControlName='password' class='form-control' type='password'>
                <div class="help-inline" *ngIf="changePasswordForm.controls.password.touched && changePasswordForm.controls.password.errors?.minlength">
                  minimum length 6
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a class='btn btn-link caps' (click)="backToLogin()">back to login</a>
              <button type='submit' id='login-button' class="btn btn-success" [disabled]="changePasswordForm.invalid"  *ngIf="!errorMessage">
                <span *ngIf="!waitingForResponse; else waiting" class='caps'>
                  Submit
                </span>
                <ng-template #waiting>
                  <i class="fa-solid fa-spinner fa-spin"></i>
                </ng-template>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
