import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includedInArray'
})
export class IncludedInArrayPipe implements PipeTransform {
  transform(item: any, array: Array<any>, specialType?: string): any {
    if (!specialType) {
      return array?.includes(item) || false;
    } else {
      if (specialType === 'virtualChildArray') {
        return array?.filter( x => x.t === item.t).map( x => x.id).includes(item.id) || false;
      } else {
        return array?.includes(item) || false;
      }
    }
  }
}
