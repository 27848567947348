import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forEach } from 'lodash-es';

import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EventsUploadService {
  apiUrl = environment.clientApiUrl;

  constructor(private httpClient: HttpClient, private store: Store) { }

  

  validateCSV(file: File) {
    const Url = `${environment.clientApiUrl}/csv/validate`;

    const fd = new FormData();
    fd.append('file', file);
    
    return this.httpClient.post(Url, fd);
  }

  uploadCSV(file: File) {
    const Url = `${environment.clientApiUrl}/csv/process`;

    const fd = new FormData();
    fd.append('file', file);
    

    return this.httpClient.post(Url, fd);
  }

}
