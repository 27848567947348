import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { AttirbutionModelLabels } from 'src/app/core/constants/attribution-models';
import { ConversionEvent } from 'src/app/core/models/conversionEvent.model';
import { Project } from 'src/app/core/models/project.model';
import { ChannelPerformanceService } from 'src/app/core/services/channel-performance/channel-performance.service';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cp-compare-vertical',
  templateUrl: './cp-compare-vertical.component.html',
  styleUrls: ['./cp-compare-vertical.component.scss']
})
export class CpCompareVerticalComponent implements OnInit {
  @Select(state => state.channelPerformance.comparison) comparison$: Observable<any>;
  @Select(state => state.selected.conversionEvent) convEvent$: Observable<ConversionEvent>;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() totalsComparison: any;

  isProd = environment.isProd;

  selectedComparison = 'revenue';
  selectedEvent;
  alreadyTookEventFromState = false;
  max;
  data;
  originalData;
  doNotShow = [];
  legendArray = [
    'first_touch',
    'last_touch',
    'linear',
    'position_based',
    'time_decay'
  ];

  attrModels = AttirbutionModelLabels;

  channels: Array<string>;
  models: Array<string>;
  attrs: Array<string>;

  project: Project;

  constructor(private cpService: ChannelPerformanceService) {}

  ngOnInit() {
    this.project$.subscribe(x => this.project = x.project);
    combineLatest([this.comparison$, this.convEvent$]).subscribe(([comparison, event]) => {
      if (comparison) {
        this.totalsComparison = comparison;
        this.prepareComparisonData();
      }

      if (!this.alreadyTookEventFromState) {
        this.selectedEvent = event;
        this.alreadyTookEventFromState = true;
      }
    });
  }

  prepareComparisonData() {
    this.max = this.selectedComparison == 'revenue' ? this.totalsComparison.meta.max_revenue : this.totalsComparison.meta.max_conversions;
    this.originalData = this.totalsComparison.report.map(x => {
      const dataArray = [];
      Object.keys(x.data).forEach( key => {
        dataArray.push({
          name: key,
          value: this.selectedComparison == 'revenue' ? x.data[key].r : x.data[key].c
        });
      });
      return {
        name: x.name,
        data: dataArray
      };
    });
    this.data = [...this.originalData].map( x => {
      return {name: x.name, data: x.data.filter( y => !this.doNotShow.includes(y.name))};
    });;
  }

  addHoverClass(item) {
    const rects = document.getElementsByClassName(`${item}-rect`);
    const boxes = document.getElementsByClassName(`${item}-box`);
    for(var i = 0; i < rects.length; i++)
    {
      rects[i].setAttribute('class', `${item}-rect hover`);
    }
    for(var i = 0; i < boxes.length; i++)
    {
      boxes[i].className = `${item}-box legend-box hover`;
    }
  }

  removeHoverClass(item) {
    const rects = document.getElementsByClassName(`${item}-rect`);
    const boxes = document.getElementsByClassName(`${item}-box`);
    for(var i = 0; i < rects.length; i++)
    {
      rects[i].setAttribute('class', `${item}-rect`);
    }
    for(var i = 0; i < boxes.length; i++)
    {
      boxes[i].className = `${item}-box legend-box ${this.doNotShow.includes(item) ? 'disabled' : null}`;
    }
  }

  selectComparison(comparison) {
    this.selectedComparison = comparison;
    this.prepareComparisonData();
  }

  selectEvent(event) {
    this.selectedEvent = event;
    this.cpService.getComparison(this.selectedEvent.name);
  }

  toggleVisibility(item) {
    if (this.doNotShow.includes(item)) {
      this.doNotShow = this.doNotShow.filter(x => x != item);
    } else {
      this.doNotShow.push(item);
    }
    this.data = [...this.originalData].map( x => {
      return {name: x.name, data: x.data.filter( y => !this.doNotShow.includes(y.name))};
    });
  }

}
