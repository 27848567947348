import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardGetItemReport'
})
export class DashboardGetItemReportPipe implements PipeTransform {
  transform(item: any, report: any, isTimeframe?: boolean ): any {
    let result;
    if (isTimeframe) {
      result = report && item ? (item.t == 'g' ? (report?.g?.[item.id] || false) : (report?.f?.[item.id] || false)) : false;
    } else {
      result = report && item ? (item.t == 'g' ? (report?.groups?.[item.id] || false) : (report?.filters?.[item.id] || false)) : false;
    }
    return JSON.stringify(result) === '{}' ? false : result;
  }
}