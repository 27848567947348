import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {
  @Input() disabled: boolean;
  @Input() isOn: boolean;
  @Input() onLabel: string = 'ON';
  @Input() offLabel: string = 'OFF'

  @Output() eventEmitter = new EventEmitter<any>();

  constructor() { }

  toggle() {
    if (!this.disabled) this.eventEmitter.emit('toggled');
  }

}
