import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { PropertyService } from 'src/app/core/services/property/property.service';
import { PropertiesStateModel } from 'src/app/store/states/properties.state';
import { SetEventPropertyValue } from 'src/app/store/actions/properties.action';
import { UpdateViewPropertyValue } from 'src/app/store/actions/project.actions';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project, View } from 'src/app/core/models/project.model';

@Component({
  selector: 'app-property-values-selector',
  templateUrl: './property-values-selector.component.html',
  styleUrls: ['./property-values-selector.component.scss']
})
export class PropertyValuesSelectorComponent implements OnInit, OnChanges {
  @Input() properties: PropertiesStateModel;
  @Input() reportIsLoading: boolean;
  @Select(state => state.project) projectState$: Observable<ProjectStateModel>;

  hideSelect = true;
  knownValues: string[];
  eventPropertyKey: string;
  eventPropertyValue: string;
  buttonEnabled: boolean;
  currentViewParams: View;
  project: Project;
  valuesLoading = false;

  constructor(private propertyService: PropertyService, private store: Store) {}

  ngOnInit(): void {
    this.projectState$.subscribe((projectState) => {
      this.project = projectState.project;
      this.currentViewParams = projectState.currentViewParams;
    });
  }

  ngOnChanges() {
    this.knownValues = this.properties.knownValues.sort( (a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    this.knownValues = ['ANY'].concat(this.knownValues);
    const prevVProperty = this.eventPropertyKey;
    this.eventPropertyKey = this.properties.event_property_key;
    if (this.eventPropertyKey && prevVProperty !== this.properties.event_property_key) {
      this.eventPropertyValue = null;
      this.valuesLoading = true;
      this.buttonEnabled = false;
      this.propertyService.getValues(this.eventPropertyKey).subscribe(() => {
        this.valuesLoading = false;
        this.buttonEnabled = true;
      });
    } else {
      this.eventPropertyValue = this.properties.event_property_value;
    }
    this.buttonEnabled = !this.reportIsLoading;
  }

  selectEventPropertyValue() {
    if (this.valuesLoading) return;
    const shouldUpdateView = this.currentViewParams.id !== this.project.views.default.id;
    this.store.dispatch(new SetEventPropertyValue({value: this.eventPropertyValue, shouldUpdateView: shouldUpdateView}));
  }

  openSelect(btn, select) {
    this.hideSelect = !this.hideSelect;
    btn.classList.add('hide');
    select.open();
    setTimeout(() => { select.focus(); }, 100);
  }

  closeSelect() {
    this.hideSelect = !this.hideSelect;
    document.getElementsByClassName('property-values-wrapper')[0].getElementsByClassName('btn-select')[0].classList.remove('hide');
  }

}
