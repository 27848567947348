<div class="attribution-type-wrapper">
  <div class="dropdown" ngbDropdown>
    <button #attrTypeDropdown class="btn btn-default dropdown-toggle btn-select" type="button" id="dropdownMenuButton" [attr.data-boundary]="attrTypeDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
      <i class="fa-solid fa-signs-post fa--header"></i>
        {{ view?.attribution_type == 'forward' ? 'Marketer Spend View' : view?.attribution_type == 'backward' ? view?.lookback_window != null ? 'Lookback Window ('+view?.lookback_window+' days)' : 'Lookback Window' : 'Undefined' }}
    </button>
    <div class="dropdown-menu" container="body" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
      <span class="dropdown-item" (click)="selectAttributionType('forward')">
        Marketer Spend View
      </span>
      <span class="dropdown-item" (click)="selectAttributionType('backward')">
        Lookback Window <span *ngIf="view?.lookback_window">({{ view?.lookback_window }} days)</span>
      </span>
    </div>
  </div>
</div>
