<div *ngIf="channelBuilderQueryParams" class="debug-in-builder">
  <a class="small caps" [routerLink]="['', project.identifier, componentNames.dashboardBuilder]" [queryParams]="channelBuilderQueryParams" (mouseover)="debugHovered = true" (mouseout)="debugHovered = false"><i class="fa-solid fa-code"></i> {{debugHovered ? 'Debug in Channel Builder' : null}}</a>
</div>
<app-empty-sidebar
  *ngIf="!inProgress && isEmpty"
  [openedSidebar]="sidebar?.openedSidebar"></app-empty-sidebar>
<div *ngIf="!isEmpty || inProgress">
  <app-sidebar-title
    [title]="scope"
    [name]="getName"
    [type]="selected.filterType"
    [filterId]="filter?.id"
    [inProgress]="inProgress"
    [isEmpty]="isEmpty"
    [filterVfilters]="filter?.vf"
    [isVirtual]="isVirtual"
    [filterTree]="filterTree"
    [periodStart]="sidebar?.options?.periodStart"
    [periodEnd]="sidebar?.options?.periodEnd"></app-sidebar-title>
  <div *ngIf="scope === 'conversions' || scope === 'visits'" class="conversion-chart-wrapper">
    <div *ngIf="scope === 'visits' && environmentName !== 'production'; else oldChart">
      <app-sidebar-bar-new-chart
        *ngIf="timeline.length > 0"
        [chartName]="scope + '-chart'"
        [data]="timeline"
        [tooltipName]="scope + '-sidebar-chart'"
        (mouseOver)="tooltipRef.onMouseOver('conversions', $event)"
        (mouseOut)="tooltipRef.onMouseOut($event)">
      </app-sidebar-bar-new-chart>
    </div>
    <ng-template #oldChart>
      <app-sidebar-bar-chart
        *ngIf="timeline.length > 0"
        [chartName]="'conversion-chart'"
        [data]="timeline"
        [tooltipName]="'conversion-chart'"
        (mouseOver)="tooltipRef.onMouseOver('conversions', $event)"
        (mouseOut)="tooltipRef.onMouseOut($event)">
      </app-sidebar-bar-chart>
    </ng-template>
  </div>
  <app-event-breakdown
    *ngIf="eventBreakdown?.length > 0"
    [events]="eventBreakdown"
    [title]="'Events Breakdown'"
    [filterId]="filter?.id"
    [project]="project"
    class="hide-prod"
  ></app-event-breakdown>
  <div
    class="table-infinite"
    infiniteScroll
    [infiniteScrollContainer]="selector"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()">
    <table *ngIf="items.length" class="table sidebar-table">
      <thead>
        <tr>
          <th>Visitor</th>
          <th *ngIf="items[0]?.convertion_percentage" class="text-right">Attr. %</th>
          <th class="text-right">Time of {{ scope === 'conversions' ? 'conversion' : 'visit' }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="sidebar-tr-link cursor-default" *ngFor="let item of items">
          <td>
            <a (click)="showVisitor(item.id)" class="blue">{{ item | nameForUser }}</a><br/>
            <a (click)="showCompany(item.company_id)" *ngIf="item.company_id && (isCompanyBasedAttributionEnabled || !isProd)" class="strong blue"><i class="fa-solid fa-lock red me-1" *ngIf="!isCompanyBasedAttributionEnabled && !isProd"></i>{{ item.company_name || item.company_identifier }}</a>
            <span class="d-block small muted">{{ item.ip && IPLocations[item.ip] ? (IPLocations[item.ip] | commaLocation) : ' ' }}</span>
          </td>
          <td *ngIf="item.convertion_percentage" class="text-right">{{ item.convertion_percentage | percent:'1.2-2' }}</td>
          <td class="text-right no-w-wrap">
            {{ (scope === 'conversions' ? item.conversion_time : item.time) | formattedMoment:'MMM d, y' }}<br/>
            <span class="muted" ngbTooltip="{{ scope === 'conversions' ? item.conversion_time : item.time }}" container="body" placement="left">{{ (scope === 'conversions' ? item.conversion_time : item.time) | formattedMoment:'t'}}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="inProgress" class="loader-sm">
      <app-loader
        *ngIf="inProgress"
        [position]="'relative'"
        [color]="'green'"
        [height]="'30px'"
        [zIndex]="1"></app-loader>
    </div>
  </div>
</div>
<div class='text-center p-20px pt-0' *ngIf="!inProgress && !isMore">
  <i class="fa-solid fa-stop-circle fa-2x light"></i>
</div>
