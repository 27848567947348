import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UrlCheckerService} from 'src/app/core/services/url-checker/url-checker.service';
import { CohortState, CohortStateModel } from 'src/app/store/states/cohort.state';

import { SetCohortFilter } from 'src/app/store/actions/cohort.actions';
import { SetCurrentComponent } from 'src/app/store/actions/selected.actions';
import { DataRefresherService } from 'src/app/core/services/data-refresher/data-refresher.service';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';

import { DateTime } from 'luxon';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SetAggregationPeriod } from 'src/app/store/actions/project.actions';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { DataDownloaderService } from 'src/app/core/services/data-downloader/data-downloader.service';
import { CohortService } from 'src/app/core/services/cohort/cohort.service';

@Component({
  selector: 'app-cohort',
  templateUrl: './cohort.component.html',
  styleUrls: ['./cohort.component.scss']
})
export class CohortComponent implements OnInit, OnDestroy {
  @Select(CohortState) cohort$: Observable<CohortStateModel>;
  @Select(state => state.filterTree) tree$: Observable<FilterTreeStateModel>;

  componentPath = ComponentNames.cohort;
  filter;
  period;
  cohortIsLoading = true;
  cohorts;
  cohortLength = [];
  periodsForRightAlign: string[];
  align = 'Left';
  heatmap = true;
  revenue = 'Relative';
  metric = 'Revenue';
  denomination = 'Dollars';
  cohortSubscription;
  shouldShowSelect = false;
  filterTree;

  treeSub;
  paramSub;

  constructor(
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService,
    private router: Router,
    private store: Store,
    private dataRefresher: DataRefresherService,
    private FTWS: FilterTreeWorkerService,
    private dataDownloader: DataDownloaderService,
    private cohortService: CohortService) {
   }

  ngOnInit() {
    this.store.dispatch(new SetCurrentComponent({ currentComponent: this.componentPath}));

    this.treeSub = this.tree$.subscribe( tree => {
      this.filterTree = tree.filterTree;
    });

    this.cohortSubscription = this.cohort$.subscribe( resp => {
      this.cohortIsLoading = resp.inProgress;
      this.cohorts = resp.report;
      if (!resp.report && !resp.inProgress) {
        this.dataRefresher.shouldComponentDataRefresh(this.componentPath)
        this.periodsForRightAlign = [];
      } else if (resp.report) {
        this.filter = resp.selectedFilter;
        this._setPeriod(
          this.store.selectSnapshot<ProjectStateModel>(state => state.project).currentViewParams?.aggregation_period || 'Day'
        );
        this.periodsForRightAlign = resp.report.map( x => {
          if (this.period !== 'D') {
            return `${DateTime.fromISO(x.period.start).toFormat('M/dd')}-${DateTime.fromISO(x.period.end).toFormat('M/dd')}`;
          } else {
            return DateTime.fromISO(x.period.start).toFormat('MMM d');
          }
        });
        this._generatePeriodsForRightAlign();
        if (resp.meta) {
          this.cohortLength = new Array(resp.meta.cohort_length);
        }
      }
    });

    const QueryParams = this.route.snapshot.queryParams;
    const Period = QueryParams.period || this._getPeriod;
    let selectedFilter;
    if (QueryParams.filter) {
      selectedFilter = { id: QueryParams.filter, t: 'f'};
    } else if ( QueryParams.group ) {
      selectedFilter = { id: QueryParams.group, t: 'g'};
    } else {
      selectedFilter = this.filter;
    }
    if ( this._getPeriod !== Period || this.filter !== selectedFilter ) {
      this.store.dispatch([
        new SetCohortFilter({ filter: selectedFilter}),
        new SetAggregationPeriod(Period)
      ] );
      this.dataRefresher.shouldComponentDataRefresh(this.componentPath, {urlParamsDifferent: true});
    }

    if (QueryParams.align) {
      this.align = QueryParams.align;
    }
    if (QueryParams.heatmap) {
      this.heatmap = QueryParams.heatmap === 'true' ? true : false;
    }
    if (QueryParams.revenue) {
      this.revenue = QueryParams.revenue;
    }
    if (QueryParams.metric) {
      this.metric = QueryParams.metric;
    }
    if (QueryParams.denomination) {
      this.denomination = QueryParams.denomination;
    }

    this.paramSub = this.route.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, this.componentPath);
    });
  }

  ngOnDestroy() {
    this.cohortSubscription?.unsubscribe();
    this.treeSub?.unsubscribe();
    this.paramSub?.unsubscribe();
    this.cohortService.unsubscribeAndCancelReportGeneration();
  }

  filterChanged(selectedFilter) {
    if (selectedFilter.item?.type && typeof(selectedFilter.item?.type) != 'undefined') selectedFilter = { id: selectedFilter.item.id, n: selectedFilter.item.name, t: selectedFilter.item.type };
    const {filter, group, ...withoutFilter} = this.route.snapshot.queryParams;
    this.store.dispatch( new SetCohortFilter({ filter: selectedFilter }));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: selectedFilter.id === 0 ? withoutFilter :
      selectedFilter.t === 'g' ? {...withoutFilter, group: selectedFilter.id} :
      {...withoutFilter, filter: selectedFilter.id}
    });
    this.shouldShowSelect = false;
  }

  selectAlignment(alignment) {
    this.align = alignment;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, align: alignment}
    });
  }

  selectHeatmap(selectedHeatmap) {
    this.heatmap = selectedHeatmap;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, heatmap: selectedHeatmap}
    });
  }

  selectRevenue(selectedRevenue) {
    this.revenue = selectedRevenue;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, revenue: selectedRevenue}
    });
  }

  selectMetric(selectedMetric) {
    const { denomination, ...withoutDenomination } = this.route.snapshot.queryParams;
    this.metric = selectedMetric;
    let params = {};
    if (selectedMetric === 'ROAS' || selectedMetric === 'Revenue') {
      params = { ...params, ...this.route.snapshot.queryParams };
    } else {
      this.denomination = 'Dollars';
      params = { ...params, ...withoutDenomination };
    }
    params = { ...params, metric: selectedMetric };
    if (selectedMetric !== 'Revenue') {
      this.revenue = 'Absolute';
      params = { ...params, revenue: 'Absolute'};
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params
    });
  }

  selectDenomination(seletedDenomination) {
    this.denomination = seletedDenomination;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...this.route.snapshot.queryParams, denomination: seletedDenomination}
    });
  }

  private _setPeriod(period) {
    const p = period.charAt(0).toUpperCase() + period.slice(1);
    switch (p) {
      case 'Day':
        this.period = 'D';
        break;
      case 'Week':
        this.period = 'W';
        break;
      case 'Month':
        this.period = 'M';
        break;
      case 'Quarter':
        this.period = 'Q';
        break;
    }
  }

  private get _getPeriod() {
    let period;
    switch (this.period) {
      case 'D':
        period = 'Day';
        break;
      case 'W':
        period = 'Week';
        break;
      case 'M':
        period = 'Month';
        break;
      case 'Q':
        period = 'Quarter';
        break;
      default:
        period = 'Day';
    }
    return period;
  }

  private _generatePeriodsForRightAlign() {
    const LastPeriod = this.cohorts[this.cohorts.length - 1];
    if (LastPeriod.revenue.relative.length > 1) {
      let toBeAdded;
      const luxonPeriodEnd = DateTime.fromISO(LastPeriod.period.end);
      switch (this.period) {
        case 'D':
          toBeAdded = Array.from(Array(LastPeriod.revenue.relative.length - 1), (_, index) => {
            return luxonPeriodEnd.plus({days: index + 1}).toFormat('MMM d');
          });
          break;
        case 'W':
          toBeAdded = Array.from(Array(LastPeriod.revenue.relative.length - 1), (_, index) => {
            return `${luxonPeriodEnd.plus({day: 1}).plus({weeks: index}).toFormat('M/dd')}-${luxonPeriodEnd.plus({day: 1}).plus({weeks: index + 1}).toFormat('M/dd')}`;
          });
          break;
        case 'M':
          toBeAdded = Array.from(Array(LastPeriod.revenue.relative.length - 1), (_, index) => {
            return `${luxonPeriodEnd.plus({day: 1}).plus({months: index}).toFormat('M/dd')}-${luxonPeriodEnd.plus({day: 1}).plus({months: index+1}).toFormat('M/dd')}`;
          });
          break;
        case 'Q':
          toBeAdded = Array.from(Array(LastPeriod.revenue.relative.length - 1), (_, index) => {
            return `${luxonPeriodEnd.plus({day: 1}).plus({quarter: index}).toFormat('M/dd')}-${luxonPeriodEnd.plus({day: 1}).plus({quarter: index+1}).toFormat('M/dd')}`;
          });
          break;
      }
      if (toBeAdded) {
        this.periodsForRightAlign.push(...toBeAdded);
      }
    }
  }

}
