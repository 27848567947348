<div class="modal-header">
  <h4 class="modal-title">
    {{ schema?.title}}
  </h4>
</div>

<div class="modal-body">
  <app-schema-view
    [oauth]="oauth"
    (settingsChange)="setSchemaSetting($event)"
    (schemaEmitter)="receivedSchema($event)"></app-schema-view>
</div>

<div class="modal-footer">
  <button (click)="cancel()" class="btn btn-default">
    <span class="caps">Cancel</span>
  </button>
  <button (click)="save()" class="btn btn-success">
    <span class="caps bold">
      @if (saveInProgress) {
        <i class="fa fa-spinner fa-lg fa-spin"></i>
      } @else {
        Save
      }
    </span>
  </button>
</div>
