import { environment } from '../../src/environments/environment';
import { SegmentModule } from 'ngx-segment-analytics';

const segmentModule = [
  SegmentModule.forRoot({
    apiKey: environment.segmentIoWriteKey,
    debug: environment.production === true ? false : true,
    loadOnInitialization: false })
];

export const ModuleExtension = { segmentModule };
