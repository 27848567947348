<div class="companies-warpper">
  <div *ngIf="company">
    <div class='row'>
      <div class="col-sm-12">
        <div class='sidebar-title no-padding-top'>
          <h4 class="app_sidebar_title">
            <span class="title-in-sidebar">
              <i class="medium far fa-building"></i>
              {{ company?.name || company?.identifier }}
            </span>
          </h4>
          <dl class='dl-horizontal traits-wrapper'>
            <div class="row">
              <dt class="col-sm-4 dt">
                Identifier
              </dt>
              <dd class="col-sm-8 dd">
                {{ company?.identifier }}
              </dd>
            </div>
            <div class="row">
              <dt class="col-sm-4 dt">
                Users Count
              </dt>
              <dd class="col-sm-8 dd">
                {{ company?.users?.length }}
              </dd>
            </div>
            <div *ngFor="let trait of traits()">
              <div class="row" *ngIf="trait.value">
                <dt *ngIf="trait.key.length > 13" class="col-sm-4 dt text-truncate" placement="right" ngbTooltip="{{trait.key}}">
                  {{ trait.key }}
                </dt>
                <dt *ngIf="trait.key.length <= 13" class="col-sm-4 dt">{{ trait.key }}</dt>
                <dd class="col-sm-8 dd">
                  <span *ngIf="isTime(trait.key); else noTime">
                    {{ item.value | formattedMoment:'ff'}}
                  </span>
                  <ng-template #noTime>
                    <span [innerHtml]="trait.key === 'revenue' ? (trait.value | projectCurrency) : (trait.value | formattedTrait)">
                    </span>
                  </ng-template>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <table class="table sidebar-table">
      <thead>
        <tr>
          <th class="col-sm-12">Users</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let visitor of company.visitors" (click)="showVisitor(visitor.id)" class="sidebar-tr-link">
          <td class="col-sm-12">
            <a (click)="showVisitor(visitor.id)" class="blue">{{ visitor | nameForUser }}</a><br />
            <div *ngIf="visitor.email && ((visitor | nameForUser) != visitor.email)" class="medium-dark">
              {{ visitor.email }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="infoInProgress" class="loader-sm">
    <app-loader
      *ngIf="inProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
<div>
  <app-visit-timeline
  [companyId]="companyId"
  [companyVisitors]="company?.visitors"
  [summary]="summary"
  [data]="data"
  [project]="project"
  [queryParams]="queryParams"
  (orderChanged)="orderChanged($event)"
  [canScroll]="canScroll"
  (scroll)="onScroll()"></app-visit-timeline>
  <div *ngIf="inProgress" class="loader-sm">
    <app-loader
      *ngIf="inProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
