import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { SetKeysAreLoading, SetKnownProperties, SetKnownPropertyValues, SetValuesAreLoading } from '../../../store/actions/properties.action';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  clientApiUrl = environment.clientApiUrl;

  constructor(private httpClient: HttpClient, private store: Store) {}

  getKeys() {
    const Url = environment.reportingApiUrl + `/v2/properties`;
    const selectedState = this.store.selectSnapshot<SelectedStateModel>(state => state.selected);
    const convEvent = this.store.selectSnapshot(state => state.project.currentViewParams)?.conversion_event;
    const chosenDates = selectedState.chosenDates;

    if (chosenDates && convEvent) {
      const StartDate = chosenDates.start;
      const EndDate = chosenDates.end;

      this.store.dispatch(new SetKeysAreLoading());

      return this.httpClient.get<string[]>(
        Url,
        { params: {start: StartDate, end: EndDate, conversion_event_name: convEvent}}
        ).pipe(
        map((response: string[]) => {
          this.store.dispatch(new SetKnownProperties(response));
          return response;
        })
      ).subscribe();
    }
  }

  getValues(propertyName: string) {
    const Url = environment.reportingApiUrl + `/v2/properties/${propertyName}`;
    const ChosenDates = this.store.selectSnapshot(state => state.selected.chosenDates);
    const currentViewParams = this.store.selectSnapshot(state => state.project.currentViewParams);
    const conversionEventName = currentViewParams.conversion_event;

    if (ChosenDates && conversionEventName) {
      const StartDate = ChosenDates.start;
      const EndDate = ChosenDates.end;

      this.store.dispatch(new SetValuesAreLoading());

      return this.httpClient.get<string[]>(Url, { params: {start: StartDate, end: EndDate, conversion_event_name: conversionEventName}}).pipe(
        map((response: string[]) => {
          this.store.dispatch(new SetKnownPropertyValues(response));
          return response;
        })
      );
    } else {
      return of(null);
    }
  }

  getEventNameProperty(name: string, filterId: number) {
    const Url = environment.reportingApiUrl + `/v2/properties/filtered`;
    const selectedState = this.store.selectSnapshot<SelectedStateModel>(state => state.selected);
    const chosenDates = selectedState.chosenDates;

    const StartDate = chosenDates.start;
    const EndDate = chosenDates.end;

    return this.httpClient.get<string[]>(
      Url,
      { params: { start: StartDate, end: EndDate, conversion_event_name: name, filter: filterId } }
      );
  }

  getEventNamePropertyValues(event, filterId: number, propertyname) {
    const Url = environment.reportingApiUrl + `/v2/properties/filtered/${propertyname}`;
    const selectedState = this.store.selectSnapshot<SelectedStateModel>(state => state.selected);
    const chosenDates = selectedState.chosenDates;

    if (chosenDates && event.name && filterId && propertyname) {
      const StartDate = chosenDates.start;
      const EndDate = chosenDates.end;

      return this.httpClient.get<string[]>(
        Url,
        { params: {start: StartDate, end: EndDate, conversion_event_name: event.name, filter: filterId, events_count: event.value}}
        );
    }
  }
}
