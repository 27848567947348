<div class="sidebar-title no-padding-top minus-margin">
  <div class="title-in-sidebar">
    <h4 class="text-center">
      Users
      <div>
        <small class="medium">Visitors you have identified</small>
      </div>
    </h4>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <form (submit)="search(q.value)">
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" ngModel #q name="q" type="text" placeholder="email OR id" id="users-search-input" required>
          <span class="input-group-btn">
            <button type="submit" class="btn btn-default"><i class="fa-solid fa-magnifying-glass blue"></i> Search</button>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>
<app-empty-sidebar
  *ngIf="!inProgress && isEmpty"
  [openedSidebar]="sidebar?.openedSidebar"></app-empty-sidebar>
<div
  *ngIf="!isEmpty || inProgress"
  infiniteScroll
  [infiniteScrollContainer]="selector"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()">
  <table class="table sidebar-table" *ngIf="users.length > 0">
    <thead>
      <tr>
        <th>
          <span *ngIf="!searchQuery">User</span>
          <span *ngIf="searchQuery"><b>Recently visited users</b></span>
        </th>
        <th class="text-right">Identified</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users" (click)="showVisitor(user.id)" class="sidebar-tr-link">
        <td>
          <a (click)="showVisitor(user.id)" class="blue">
            {{ user | nameForUser }}
            <i *ngIf="user?.identifier" class="fa-solid fa-user muted" placement="top" ngbTooltip="User ID: {{ user.identifier}}" container="body" tooltipClass="tooltip-sidebar"></i>
          </a><br/>
          <span class="small muted">{{ user.email }}</span>
        </td>
        <td class="text-right no-w-wrap">
          <div *ngIf="user.time">
            {{ user.time | formattedMoment:'DD' }}<br />
            <span class="small muted">{{ user.time | formattedMoment:'t' }}</span>
          </div>
          <div *ngIf="!user.time">
            <span class="small muted">unknown</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="inProgress" class="loader-sm">
    <app-loader
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
