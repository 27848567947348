import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nullable-checkbox',
  standalone: true,
  imports: [],
  templateUrl: './nullable-checkbox.component.html',
  styleUrl: './nullable-checkbox.component.scss'
})
export class NullableCheckboxComponent {
  @Input() value: boolean | null;
  @Input() nullable = false;
  @Output() valueChange = new EventEmitter<boolean | null>();

  toggleBool(val: boolean | null) {
    this.valueChange.emit(val);
  }

}
