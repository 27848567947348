import { AfterViewInit, Component, Input, NgZone, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import * as d3Sankey from 'd3-sankey';

@Component({
  selector: 'app-sankey-chart',
  templateUrl: './sankey-chart.component.html',
  styleUrls: ['./sankey-chart.component.scss']
})
export class SankeyChartComponent implements OnChanges, AfterViewInit {
  @Input() links: Array<any> = [];
  @Input() nodes: Array<any> = [];
  @Input() chartHeight;
  @Input() maxLength;

  rendered = false;
  chartName = 'sankey';
  chartData;

  constructor(private ngZone: NgZone) { }

  ngOnChanges(): void {
    if (!this.rendered) { return; }
    if (this.links.length > 0 && this.nodes.length > 0) {
      this.drawChart();
    }
  }

  ngAfterViewInit() {
    this.rendered = true;
    if (this.links.length > 0 && this.nodes.length > 0) this.drawChart();
  }

  drawChart() {
    this.ngZone.runOutsideAngular(() => {
      d3.select(`#${this.chartName}`).select('svg').remove();
      d3.select(`#${this.chartName}`).style("height", this.chartHeight + 'px');

      const wrapperProperties = document.getElementById(this.chartName).getBoundingClientRect();
      const width = wrapperProperties.width
      const chart = this.SankeyChart({
        nodes: null,
        links: this.links
      }, {
        nodeGroup: d => {
          return d.id.split('=_+_=')[0];
        },
        format: (f => d => `${f(d)}`)(d3.format(",.1~f")),
        width,
        height: this.chartHeight
      });
      d3.select(`#${this.chartName}`).html(chart.outerHTML);
    });
  }

  SankeyChart = // Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/sankey-diagram
function SankeyChart({
  nodes, // an iterable of node objects (typically [{id}, …]); implied by links if missing
  links // an iterable of link objects (typically [{source, target}, …])
}, {
  format = d3.format(","), // a function or format specifier for values in titles
  align = "left", // convenience shorthand for nodeAlign
  nodeId = d => d.id,
  nodeGroup = null, // given d in nodes, returns an (ordinal) value for color
  nodeGroups = null, // an array of ordinal values representing the node groups
  nodeLabel = null, // given d in (computed) nodes, text to label the associated rect
  nodeTitle = d => `${d.id.split('=_+_=')[0].split('_id:')[0]}\n${format(d.value)}`, // given d in (computed) nodes, hover text
  nodeAlign = align, // Sankey node alignment strategy: left, right, justify, center
  nodeWidth = 15, // width of node rects
  nodePadding = 10, // vertical separation between adjacent nodes
  nodeLabelPadding = 6, // horizontal separation between node and label
  nodeStroke = "currentColor",
  nodeStrokeWidth = null, // width of stroke around node rects, in pixels
  nodeStrokeOpacity = null, // opacity of stroke around node rects
  nodeStrokeLinejoin = null, // line join for stroke around node rects
  linkSource = ({source}) => source, // given d in links, returns a node identifier string
  linkTarget = ({target}) => target, // given d in links, returns a node identifier string
  linkValue = ({value}) => value, // given d in links, returns the quantitative value
  linkPath = d3Sankey.sankeyLinkHorizontal(), // given d in (computed) links, returns the SVG path
  linkTitle = d => `${d.source.id.split('=_+_=')[0].split('_id:')[0]} → ${d.target.id.split('=_+_=')[0].split('_id:')[0]}\n${format(d.value)}`, // given d in (computed) links
  linkColor = "source-target", // source, target, source-target, or static color
  linkStrokeOpacity = 0.5, // link stroke opacity
  linkMixBlendMode = "multiply", // link blending mode
  colors = d3.schemeTableau10, // array of colors
  width = 640, // outer width, in pixels
  height = 400, // outer height, in pixels
  marginTop = 5, // top margin, in pixels
  marginRight = 1, // right margin, in pixels
  marginBottom = 5, // bottom margin, in pixels
  marginLeft = 1, // left margin, in pixels
} = {}) {
  // Convert nodeAlign from a name to a function (since d3-sankey is not part of core d3).
  if (typeof nodeAlign !== "function") nodeAlign = {
    left: d3Sankey.sankeyLeft,
    right: d3Sankey.sankeyRight,
    center: d3Sankey.sankeyCenter
  }[nodeAlign] ?? d3Sankey.sankeyJustify;

  const caculateColor = (i) => {
    const found = this.nodes.find(x => x.name.toLowerCase() == G[i].toLowerCase());
    return found ? found.color : '#999';
  };

  // Compute values.
  const LS = links.map(linkSource).map(intern);
  const LT = links.map(linkTarget).map(intern);
  const LV = links.map(linkValue);
  if (nodes === null) nodes = Array.from(new Set([...LS, ...LT]), id => ({id}));
  const N = nodes.map(nodeId).map(intern);
  const G = nodeGroup == null ? null : nodes.map(nodeGroup).map(intern);

  // Replace the input nodes and links with mutable objects for the simulation.
  nodes = nodes.map((_, i) => ({id: N[i]}));
  links = links.map((_, i) => ({source: LS[i], target: LT[i], value: LV[i]}));

  // Ignore a group-based linkColor option if no groups are specified.
  if (!G && ["source", "target", "source-target"].includes(linkColor)) linkColor = "currentColor";

  // Compute default domains.
  if (G && nodeGroups === null) nodeGroups = G;

  // Construct the scales.
  const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

  // Compute the Sankey layout.
  const sankey = d3Sankey.sankey()
      .nodeId(({index: i}) => N[i])
      .nodeAlign(nodeAlign)
      .nodeWidth(nodeWidth)
      .nodePadding(nodePadding)
      .extent([[marginLeft, marginTop], [width - marginRight, height - marginBottom]])
    ({nodes, links});

  // Compute titles and labels using layout nodes, so as to access aggregate values.
  const Tl = nodeLabel === null ? N : nodeLabel == null ? null : nodes.map(nodeLabel);
  const Tt = nodeTitle == null ? null : nodes.map(nodeTitle);
  const Lt = linkTitle == null ? null : links.map(linkTitle);

  // A unique identifier for clip paths (to avoid conflicts).
  const uid = `O-${Math.random().toString(16).slice(2)}`;

  const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0, 0, ${width}, ${height}`)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

  const node = svg.append("g")
    .selectAll("rect")
    .data(nodes)
    .join("rect")
      .attr("x", (d: any) => d.x0)
      .attr("y", (d: any) => d.y0)
      .attr("height", (d: any) => d.y1 - d.y0)
      .attr("width", (d: any) => d.x1 - d.x0);

  if (G) node.attr("fill", ({index: i}) => {
    return caculateColor(i);
  });
  if (Tt) node.append("title").text(({index: i}) => Tt[i]);

  const link = svg.append("g")
      .attr("fill", "none")
    .selectAll("g")
    .data(sankey.links)
    .join("g")
      .style("mix-blend-mode", linkMixBlendMode)
      .attr("stroke-opacity", (d: any) => {
        return d.target.id.length == 0 ? 0 : linkStrokeOpacity;
      });

  if (linkColor === "source-target") link.append("linearGradient")
      .attr("id", (d:any) => `${uid}-link-${d.index}`)
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", (d:any) => d.source.x1)
      .attr("x2", (d:any) => d.target.x0)
      .call(gradient => gradient.append("stop")
          .attr("offset", "0%")
          .attr("stop-color", ({source: {index: i}}) => {
            return caculateColor(i);
          }))
      .call(gradient => gradient.append("stop")
          .attr("offset", "100%")
          .attr("stop-color", ({target: {index: i}}) => {
            return caculateColor(i);
          }));

  link.append("path")
      .attr("d", linkPath)
      .attr("stroke", ({index: i}) => `url(#${uid}-link-${i})`)
      .attr("stroke-width", ({width}) => Math.max(1, width))
      .call(Lt ? path => path.append("title").text(({index: i}) => Lt[i]) : () => {});

  if (Tl) svg.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
    .selectAll("text")
    .data(nodes)
    .join("text")
      .attr("x", (d: any) => d.x0 < width / 2 ? d.x1 + nodeLabelPadding : d.x0 - nodeLabelPadding)
      .attr("y", (d: any) => (d.y1 + d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", (d: any) => d.x0 < width / 2 ? "start" : "end")
      .text(({index: i}) => {
        return Tl[i].split('=_+_=')[0].split('_id:')[0];
      });

  function intern(value) {
    return value !== null && typeof value === "object" ? value.valueOf() : value;
  }

  return Object.assign(svg.node(), {scales: {color}});
}


}
