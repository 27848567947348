import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { findAllInTree, findInTree, removeInTree } from 'src/app/core/helpers/find-in-tree';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { ReArrangeFilters } from 'src/app/store/actions/filter-tree.actions';
import { ReportRefresherService } from 'src/app/core/services/report-refresher/report-refresher.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewFilterModalComponent } from '../new-filter-modal/new-filter-modal.component';
import { NewVirtualFilterModalComponent } from '../new-virtual-filter-modal/new-virtual-filter-modal.component';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { AddToPendingDataRefresh, SetPendingRefreshMovingFrom } from 'src/app/store/actions/base.actions';

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss']
})
export class GroupSelectorComponent implements OnInit, OnDestroy {
  @Select(state => state.filterTree) $filterTree: Observable<any>;
  @Input() isManageChannels = false;
  @Input() sort;
  @Input() selectedGroupsForMove;
  @Input() reset: Observable<void>;

  @Output() groupSelector = new EventEmitter<any>();
  @Output() multipleSelector = new EventEmitter<any>();

  resetSubscription;

  filterTree = [];
  allFilters = [];
  allChannels = [];
  selectedGroup;
  selectedFilters = [];
  selectedGroups = [];
  disabledFilters = [];
  disabledGroups = [];

  editNameId;
  nameInput;
  editFilterWaiting;

  dndEventData;

  constructor(
    private filterService: FilterService,
    private store: Store,
    private reportRefresher: ReportRefresherService,
    private modalService: NgbModal,
    private FTWS: FilterTreeWorkerService
  ) { }

  ngOnInit(): void {
    if (this.reset) {
      this.resetSubscription = this.reset.subscribe( (group: any) => {
        this.selectedFilters = [];
        this.selectedGroups = [];
        if (group) {
          this.enableChildren(group);
        }
      });
    }

    this.$filterTree.subscribe(x => {
      let tree = JSON.parse(JSON.stringify(x.filterTree));
      if (this.selectedGroupsForMove) {
        this.selectedGroupsForMove.forEach(g => {
          removeInTree({c: tree}, 'c', 'id', g.id, 't', 'g');
        });
      }
      this.filterTree = tree;
      this.allChannels = findAllInTree({c: tree}, 'c', 't', 'g', null, null, null, true);
      this.allFilters = findAllInTree({c: tree}, 'c', 't', 'f', null, null, null, true);
    });
  }

  ngOnDestroy() {
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
  }

  onlyGroups(item) {
    if (!item) return [];
    return item.filter(x => x.t == 'g');
  }

  toggleGroup(group) {
    if (group == 'on-top') {
      if (this.selectedGroup == 'on-top') this.selectedGroup = null;
      else this.selectedGroup = 'on-top';
    }
    else if (this.selectedGroup?.id == group.id) this.selectedGroup = null;
    else this.selectedGroup = group;
    this.groupSelector.emit(this.selectedGroup);
  }

  onDrop( event: DndDropEvent, list?: any[] ) {
    this.dndEventData = {id: event.data.id, t: event.data.t};
    if ( list
      && (event.dropEffect === 'copy'
        || event.dropEffect === 'move') ) {

      let index = event.index;

      if ( typeof index === 'undefined' ) {

        index = list.length;
      }

      list.splice( index, 0, event.data );
    }
  }

  onDragStart(item: any) {
    const node = findInTree({node: {c: this.filterTree }, childrenKey: 'c', key: 'id', value: item.id, additionalKey: 't', additionalValue: item.t, withPath: this.filterTree, getPathIds: true});
    this.store.dispatch(
      new SetPendingRefreshMovingFrom({
        id: node.id,
        pathArray: node.pathArray,
      })
    );
  }

  onDragged( item: any, list: any[], effect: DropEffect ) {
    if ( effect === 'move' ) {
      const index = list.indexOf( item );
      list.splice( index, 1 );
    }
  }

  onDragEnd( event: DragEvent ) {
    const node = findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: this.dndEventData.id, additionalKey: 't', additionalValue: this.dndEventData.t, returnParent: true, updateLevel: true, withPath: this.filterTree, getPathIds: true});
    this.filterService.move(node).subscribe( () => {
      this.store.dispatch([
        new ReArrangeFilters({tree: this.filterTree, noMinify: true}),
        new AddToPendingDataRefresh({id: node.id, type: node.t, pathArray: node.pathArray, case: 'move', movingTo: node.parent.id})
      ]);
      this.reportRefresher.scheduleRefresh();
    });
  }

  withoutSpecials(array) {
    return array?.filter( x => {
      return x.s === null || x.s == 'virtual';
    });
  }

  isAddedAlready(item) {
    let isAlready;
    if (item.t == 'f') {
      isAlready = typeof(this.selectedFilters.find( x => x.id == item.id)) != 'undefined';
    } else if (item.t == 'g') {
      isAlready = typeof(this.selectedGroups.find( x => x.id == item.id)) != 'undefined';
    }
    return isAlready;
  }

  isDisabled(item) {
    let isDisabled;
    if (item.t == 'f') {
      isDisabled = typeof(this.disabledFilters.find( x => x.id == item.id)) != 'undefined';
    } else if (item.t == 'g') {
      isDisabled = typeof(this.disabledGroups.find( x => x.id == item.id)) != 'undefined';
    }
    return isDisabled;
  }

  enableChildren(item){
    if (!item) return;
    if (item.c) {
      item.c.forEach( x => {
        if (x.t == 'f') {
          this.disabledFilters = this.disabledFilters.filter(f => f.id != x.id);
        } else {
          this.disabledGroups = this.disabledGroups.filter(g => g.id != x.id);
          if (x.c?.length > 0) {
            this.enableChildren(x);
          }
        }
      });
    }
  };

  toggleSelect(item, event) {
    if (event.target.classList.contains('avoid-row-click') || this.isDisabled(item)) return;

    const disableChildren = (item) => {
      if (item.c) {
        item.c.forEach( x => {
          if (x.t == 'f') {
            this.disabledFilters.push(x);
            this.selectedFilters = this.selectedFilters.filter( f => f.id != x.id);
          } else {
            this.disabledGroups.push(x);
            this.selectedGroups = this.selectedGroups.filter( g => g.id != x.id);
            if (x.c?.length > 0) {
              disableChildren(x);
            }
          }
        });
      }
    };

    if (this.isAddedAlready(item)) {
      if (item.t == 'f') {
        this.selectedFilters = this.selectedFilters.filter(x => x.id != item.id);
      } else {
        this.selectedGroups = this.selectedGroups.filter(x => x.id != item.id);
        this.enableChildren(item);
      }
    } else {
      if (item.t == 'f') {
        this.selectedFilters.push(item);
      } else {
        this.selectedGroups.push(item);
        if (item.c?.length > 0) {
          disableChildren(item);
        }
      }
    }
    this.multipleSelector.emit({filters: this.selectedFilters, groups: this.selectedGroups});
  }

  toggleNameInput(channelId, name) {
    this.nameInput = name;
    this.editNameId = this.editNameId === channelId ? null : channelId;
  }

  renameChannel(nameInput, channelId) {
    this.filterService.editChannel(channelId, nameInput.value);
    this.editNameId = null;
    this.renameFilterOrGroup('g', nameInput.value, channelId)
  }

  private renameFilterOrGroup(type, name, id) {
    const editedItem = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: id, additionalKey: 't', additionalValue: type});
    if (editedItem) {
      editedItem.name = name;
    }
  }

  navigateToEditModal(item) {
    if (item.s == 'virtual') {
      const queryParamsToPass: any = {
        edit_virtual_filter_id: item.id
      };
      this.openEditVirtualModal(queryParamsToPass)
    } else {
      const queryParamsToPass: any = {
        edit_filter_id: item.id
      };
      this.openEditModal(queryParamsToPass);
    }
  }

  openEditModal(queryParams) {
    let item;
    if (queryParams.edit_filter_id) {
      item = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: queryParams.edit_filter_id, additionalKey: 't', additionalValue: 'f'});
    }
    if (item) {
      this.editFilterWaiting = item.id;
      this.filterService.getFilter(item.id).subscribe( resp => {
        this.editFilterWaiting = null;
        const modalRef = this.modalService.open(NewFilterModalComponent);
        if (item) {
          modalRef.componentInstance.filterInfo = resp;
          modalRef.componentInstance.filterIntegration = item.i;
        }
        modalRef.result.then(
          result => {
            if (result.result === 'filterDeleted') {
              this.removeFilterOrGroup('f', result.filterId);
            } else if (result.result === 'filterEdited') {
              this.renameFilterOrGroup('f', result.filter.name, result.filter.id);
            }
          }
        );
      }, error => {
        this.editFilterWaiting = null;
      });
    }
  }

  openEditVirtualModal(queryParams) {
    let item;
    if (queryParams.edit_virtual_filter_id) {
      item = this.FTWS.findInTree({node:{c: this.filterTree}, childrenKey:'c', key: 'id', value: queryParams.edit_virtual_filter_id, additionalKey: 't', additionalValue: 'f'});
    }
    if (item) {
      if (item.name == 'Paid Traffic') return;
      this.editFilterWaiting = item.id;
      this.filterService.getFilter(item.id).subscribe( resp => {
        this.editFilterWaiting = null;
        const modalRef = this.modalService.open(NewVirtualFilterModalComponent);
        modalRef.componentInstance.allFilters = this.allFilters;
        modalRef.componentInstance.allChannels = this.allChannels;
        if (item) {
          modalRef.componentInstance.vFilterInfo = resp;
        }
        modalRef.result.then(
          result => {
            if (result.result === 'vFilterDeleted') {
              this.removeFilterOrGroup('f', result.filterId);
            } else if (result.result === 'vFilterEdited') {
              this.renameFilterOrGroup('f', result.filter.name, result.filter.id);
              this.reportRefresher.scheduleRefresh(true);
            }
          }
        );
      }, error => {
        this.editFilterWaiting = null;
      });
    }
  }

  private removeFilterOrGroup(type, id) {
    removeInTree({c: this.filterTree}, 'c', 'id', id, 't', type);
    removeInTree({c: this.filterTree}, 'c', 'id', id, 't', type);
  }

}
