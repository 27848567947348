export const AttributionModels = [
  { label: 'Linear', name: 'linear'},
  { label: 'First Touch', name: 'first_touch'},
  { label: 'Last Touch', name: 'last_touch'},
  { label: 'Time Decay', name: 'time_decay'},
  { label: 'Position-Based', name: 'position_based'}
]

export const AttirbutionModelLabels = {
  first_touch:    'First Touch',
  last_touch:     'Last Touch',
  linear:         'Linear',
  position_based: 'Position Based',
  time_decay:     'Time Decay'
}

export const AttrModelMapping = AttributionModels.reduce((map, obj) => {
  map[obj.name] = obj.label;
  return map;
}, {});