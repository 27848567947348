<div class="modal-header">
  <div class="modal-title">
    <ng-template #showOverride>
      <h3 class="mb-0 mt-0">Override {{name}} View</h3>
    </ng-template>
    <h3 *ngIf="!name; else showOverride" class="mb-0 mt-0">Save Dashboard View</h3>
    <small class="extra-small">A Dashboard view is a view or snapshot of the dashboard where attribution model, type, date-range preset, aggregation-period, conversione event, revenue group, traffic conversion and revenue setting, 'Disregard existing user' setting has been selected.</small>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--source">
  <input class="form-control me-2 flex-grow-1" type="text" [ngModel]="nameInput" (ngModelChange)="changeNameInput($event)" placeholder="View Name">
  <h4>
    Selected Parameters
  </h4>
  <view-selected-params [view]="view" [isPreset]="isPreset"></view-selected-params>
</div>
<div class="modal-footer space-between">
  <div>
    <app-toggle-switch
      [onLabel]="'Date Preset'"
      [offLabel]="'Fixed Dates'"
      [isOn]="this.isPreset"
      (eventEmitter)="togglePresetOrDate($event)"></app-toggle-switch>
  </div>
  <button type='submit' class="btn btn-success" [class.disabled]="!isValid" (click)="submit()">
    <span *ngIf="!waitingForResponse; else waiting">
      <span class="caps">{{ name ? 'Override' : 'Save'}}</span>
      <i class="ms-2 fa-solid fa-check-circle"></i>
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>