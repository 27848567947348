<div class="sidebar-title no-padding-top minus-margin">
  <div class="title-in-sidebar">
    <h4 class="text-center">
      Companies
      <div class="text-center">
        <small class="medium">Aggregated users info that belong to a single company (account)</small>
      </div>
    </h4>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <form (submit)="search(q.value)">
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" ngModel #q name="q" type="text" placeholder="email OR id" id="companies-search-input" required>
          <span class="input-group-btn">
            <button type="submit" class="btn btn-default"><i class="fa-solid fa-magnifying-glass blue"></i> Search</button>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>
<app-empty-sidebar
  *ngIf="!inProgress && isEmpty"
  [openedSidebar]="sidebar?.openedSidebar"></app-empty-sidebar>
<div
  *ngIf="!isEmpty || inProgress"
  class="table-infinite"
  infiniteScroll
  [infiniteScrollContainer]="selector"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()">
  <table class="table sidebar-table" *ngIf="companies.length > 0">
    <thead>
      <tr>
        <th>
          <span *ngIf="!searchQuery">Company</span>
          <span *ngIf="searchQuery"><b>Recently active companies</b></span>
        </th>
        <th class="text-right">Time of last visit</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let company of companies" (click)="showCompany(company.id)" class="sidebar-tr-link">
        <td>
          <a (click)="showCompany(company.id)" class="blue">{{ company.name }}</a><br/>
          <span class="small muted">{{ company?.identifier }}</span>
        </td>
        <td class="text-right no-w-wrap">
          {{ company.time | formattedMoment:'DD' }}<br />
          <span class="small muted">{{ company.time | formattedMoment:'t' }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="inProgress" class="loader-sm">
    <app-loader
      *ngIf="inProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
