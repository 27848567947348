import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project, View } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetProjectName } from 'src/app/store/actions/project.actions';
import { environment } from 'src/environments/environment';
import { SetSettingSection } from 'src/app/store/actions/selected.actions';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { OnboardingService } from 'src/app/core/services/onboarding/onboarding.service';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.base.firstPurchaseOnly) firstPurchaseOnly$: Observable<boolean>;
  firstPurchaseOnly = false;

  isProd = environment.isProd;
  project: Project;
  view: View;
  projectName: string;
  isProjectNameBeingEditted = false;

  constructor(
    private projectService: ProjectService,
    private store: Store,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService,
    private onboardingService: OnboardingService,
    ) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, `${ComponentNames.settings}/${SettingsSections.projectSettings}`);
    });
    this.store.dispatch(new SetSettingSection(SettingsSections.projectSettings));
    this.project$.subscribe(x => {
      this.project = x.project;
      this.view = x.currentViewParams;
      this.projectName = x.project?.name;
    });
    this.firstPurchaseOnly$.subscribe( resp => this.firstPurchaseOnly = resp);
  }

  createProject() {
    if (this.projectName.toUpperCase() != 'MY PROJECT') {
      this.projectService.setAttribute('name', this.projectName).subscribe(() => {
        this.store.dispatch(new SetProjectName(this.projectName));
        this.isProjectNameBeingEditted = false;
      });
    } else {
      setTimeout(() =>
        this.toastr.error(
          'Project name is not allowed to be "My Project".',
          `Project name is not allowed to be "My Project".`,
          {disableTimeOut: true}
        )
      );
    }
  }

  restartOnboarding() {
    if (confirm("Restart Onboarding?")) {
      this.onboardingService.setStep('update-project');
    }
  }

}
