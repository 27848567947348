import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { findTopNodeInTree } from 'src/app/core/helpers/find-in-tree';

@Pipe({
  name: 'visitTimelineFilterTooltip',
})
export class VisitTimelineFilterTooltipPipe implements PipeTransform {
  transform(filter, filterTree): any {
    let res = '';
    const channel = findTopNodeInTree(filterTree, 'c', 'id', filter.id);
    if (channel) {
      res += `${channel.n} · `;
    }
    res += new DatePipe('en').transform(filter.time);
    return res;
  }
}