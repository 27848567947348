import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardNotHiddenItems',
})
export class DashboardNotHiddenItemsPipe implements PipeTransform {
  transform(array, viewParams): any {
    if (!array || !viewParams) return [];
    return array.filter((item) => {
      if (item.t === 'g') {
        return !viewParams.hidden_filter_groups.includes(item.id);
      } else {
        return !viewParams.hidden_filters.includes(item.id);
      }
    });
  }
}