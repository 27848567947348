export class SetFirstTimePurchase {
  static readonly type = '[firstTimePurchase] SetFirstTImePurchase';
  constructor(public payload: any) {}
}

export class PatchFirstTimePurchase {
  static readonly type = '[firstTimePurchase] PatchFirstTimePurchase';
  constructor(public payload: any) {}
}

export class SetFTPInProgress {
  static readonly type = '[firstTimePurchase] SetFTPInProgress';
  constructor(public payload?: { isFalse: boolean }) {}
}

export class SetFilter {
  static readonly type = '[firstTimePurchase] SetFilter';
  constructor(public payload: { filter: any}) {}
}

export class SetFirstTimePurchaseReportToNull {
  static readonly type = '[firstTimePurchase] SetFirstTimePurchaseReportToNull';
}

export class ClearFTPState {
  static readonly type = '[firstTimePurchase] Clear';
}
