<div *ngIf="canGoBack || canGoForward" class="onboarding-skip">
  <div>
    <a *ngIf="canGoBack" (click)="goBack()">
      Back
    </a>
  </div>
  <div>
    <a *ngIf="canGoForward && !isFinish" (click)="goNext()">
      Next
    </a>
  </div>
</div>
<button *ngIf="isFinish" type='submit' class="btn btn-submit mt-5" (click)="goNext()">
  Finish
</button>