import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-integration-status-icon',
  templateUrl: './integration-status-icon.component.html',
  styleUrls: ['./integration-status-icon.component.scss']
})
export class IntegrationStatusIconComponent implements OnInit {
  @Input() oauth;

  constructor() { }

  ngOnInit(): void {
  }

}
