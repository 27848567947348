import { State, Action, StateContext, Store, NgxsOnInit, NgxsOnChanges, NgxsSimpleChange } from '@ngxs/store';

import {
  SetFilterTree,
  ClearFilterTree,
  ToggleExpand,
  AddFilterChannel,
  RenameChannel,
  DeleteChannel,
  AddFilter,
  DeleteFilter,
  EditFilter,
  ReArrangeFilters,
  ShowChannelZeros,
  ExpandUpTheTree,
  ClearFilterErrors,
  ScheduleTreeRefresh,
  AddOrRemoveVirtualFilter,
  EditVirtualFilter,
  MoveFiltersAndChannels,
  SetFTInProgress,
  FilterTreeSetOrinals
} from '../actions/filter-tree.actions';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { Injectable } from '@angular/core';
import { findInTree, removeInTree } from 'src/app/core/helpers/find-in-tree';
import { cloneDeep } from 'lodash-es';
import { FilterTreeItem } from '../interfaces/filter-tree';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';

export const enum TreeActions {
  toggleExpand = 'toggle-expand',
}

export interface FilterTreeStateModel {
  filterTree: Array<FilterTreeItem>;
  showZeros: Array<number>;
  refreshScheduled: boolean;
  inProgress: boolean;
  lastAction?: string;
  ordinals?: {id: number, t: string, ordinal: number}[];
}

@State<FilterTreeStateModel>({
  name: 'filterTree',
  defaults: {
    filterTree: null,
    showZeros: [],
    refreshScheduled: false,
    inProgress: false,
    lastAction: null,
    ordinals: null
  }
})
@Injectable()
export class FilterTreeState {
  constructor(private filterService: FilterService, private filterTreeWorkerService: FilterTreeWorkerService, private FTWS: FilterTreeWorkerService) {}

  setWorker(data) {
    this.filterTreeWorkerService.postToWorker(data);
  }

  @Action(SetFilterTree)
  SetFilterTree(ctx: StateContext<any>, payload ) {
    const getState = ctx.getState();
    ctx.setState({...getState, filterTree: payload.payload, inProgress: false, lastAction: null});
    this.setWorker(payload.payload);
  }

  @Action(FilterTreeSetOrinals)
  FilterTreeSetOrinals(ctx: StateContext<FilterTreeStateModel>, { payload }: FilterTreeSetOrinals) {
    const getState = ctx.getState();
    ctx.setState({...getState, ordinals: payload, lastAction: null});
  }

  @Action(ReArrangeFilters)
  ReArrangeFilters(ctx: StateContext<FilterTreeStateModel>, { payload }: ReArrangeFilters) {
    const fixLevel = (array, parentLevel) => {
      const level = parentLevel + 1;
      return array.map(x => {
        const fixed: any = {
          ...x,
          l: level - 1,
        };
        if (x.c && x.c.length > 0) {
          fixed.c = fixLevel(x.c, level);
        }
        return fixed;
      });
    };

    const getState = ctx.getState();
    const newState = fixLevel(payload.tree, 0);
    ctx.setState({...getState, filterTree: newState, lastAction: null});
    this.setWorker(newState);
  }

  @Action(ShowChannelZeros)
  ShowChannelZeros(ctx: StateContext<FilterTreeStateModel>, { payload }: ShowChannelZeros) {
    const getState = ctx.getState();
    let showZerosArray = new Array();
    if (getState.showZeros.length > 0) {
      showZerosArray = getState.showZeros;
    }
    showZerosArray.push(payload);
    ctx.setState({...getState, showZeros: showZerosArray, lastAction: null});
  }

  @Action(ScheduleTreeRefresh)
  ScheduleTreeRefresh(ctx: StateContext<any>) {
    const getState = ctx.getState();
    ctx.setState({...getState, refreshScheduled: true, lastAction: null});
  }

  @Action(ClearFilterTree)
  ClearFilterTree(ctx: StateContext<any>) {
    ctx.setState({showZeros: [], filterTree: null, refreshScheduled: false, inProgress: false, lastAction: null});
    this.setWorker([]);
  }

  @Action(SetFTInProgress)
  SetFTInProgress(ctx: StateContext<FilterTreeStateModel>, { payload }: SetFTInProgress) {
    const getState = ctx.getState();
    ctx.setState({...getState, inProgress: payload, lastAction: null});
  }

  @Action(AddFilterChannel)
  AddFilterChannel(ctx: StateContext<FilterTreeStateModel>, { payload }: AddFilterChannel) {
    const getState = ctx.getState();
    let tree = getState.filterTree;
    if (payload.parentFilterGroupId) {
      tree = addChannelOrFiltersBelow(tree, payload.parentFilterGroupId, {channel: payload.channel});
    } else {
      tree.unshift(newChannel(payload.channel));
    }
    const newState = new Array(...tree);
    ctx.setState({...getState, filterTree: newState, lastAction: null});
    this.setWorker(newState);
  }

  @Action(AddFilter)
  AddFilter(ctx: StateContext<FilterTreeStateModel>, { payload }: AddFilter) {
    const getState = ctx.getState();
    let tree = getState.filterTree;
    if (payload.filter) {
      tree.unshift(newFilter(payload.filter));
    } else if (payload.filters) {
      const filters = payload.filters.map( f => newFilter(f));
      if (payload.parentFilterGroupId) {
        tree = addChannelOrFiltersBelow(tree, payload.parentFilterGroupId, {filters: filters})
      } else {
        tree = [...filters, ...tree];
      }
    }
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.setWorker(tree);
  }

  @Action(EditFilter)
  EditFilter(ctx: StateContext<FilterTreeStateModel>, { payload }: EditFilter) {
    const getState = ctx.getState();
    let tree: Array<FilterTreeItem> = getState.filterTree;
    tree = editChannelOrFilter('rename', tree, payload.id, 'f', payload.n);
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.setWorker(tree);
  }

  @Action(AddOrRemoveVirtualFilter)
  AddOrRemoveVirtualFilter(ctx: StateContext<FilterTreeStateModel>, { payload }: AddOrRemoveVirtualFilter) {
    const getState = ctx.getState();
    let tree: Array<FilterTreeItem> = getState.filterTree;
    tree = toggleVfilterInFilter(tree, payload.action, payload.item, payload.virtualFilterId);
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.setWorker(tree);
  }

  @Action(EditVirtualFilter)
  EditVirtualFilter(ctx: StateContext<FilterTreeStateModel>, { payload }: EditVirtualFilter) {
    const getState = ctx.getState();
    let tree: Array<FilterTreeItem> = getState.filterTree;
    tree = addVFilterToAllFilters(tree, payload.filters, payload.groups, payload.virtualFilterId);
    ctx.setState({...getState, filterTree: tree, lastAction: null});
  }


  @Action(ClearFilterErrors)
  ClearFilterErrors(ctx: StateContext<FilterTreeStateModel>, { payload }: ClearFilterErrors) {
    const getState = ctx.getState();
    const filterOrGroup = payload.filter_id? payload.filter_id : payload.filter_group_id;
    const type = payload.filter_id? 'f' : 'g';
    const tree = editChannelOrFilter('clear_filter_errors', getState.filterTree, filterOrGroup, type);
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.filterService.filterTreeExpanded.next();
  }

  @Action(DeleteFilter)
  DeleteFilter(ctx: StateContext<FilterTreeStateModel>, { payload }: DeleteFilter) {
    const getState = ctx.getState();
    let tree: Array<FilterTreeItem> = getState.filterTree;
    if (payload.multi) {
      tree = multiDelete(tree, payload.id, 'f');
    } else {
      tree = deleteChannelOrFilter(tree, payload.id, 'f');
    }
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.setWorker(tree);
  }

  @Action(DeleteChannel)
  DeleteChannel(ctx: StateContext<FilterTreeStateModel>, { payload }: DeleteChannel) {
    const getState = ctx.getState();
    let tree: Array<FilterTreeItem> = getState.filterTree;
    if (payload.multi) {
      tree = multiDelete(tree, payload.id, 'g');
    } else {
      tree = deleteChannelOrFilter(tree, payload.id, 'g');
    }
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.setWorker(tree);
  }

  @Action(RenameChannel)
  RenameChannel(ctx: StateContext<FilterTreeStateModel>, { payload }: RenameChannel) {
    const getState = ctx.getState();
    let tree: Array<FilterTreeItem> = getState.filterTree;
    tree = editChannelOrFilter('rename', tree, payload.id, 'g', payload.n);
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.setWorker(tree);
  }

  @Action(ToggleExpand)
  ToggleExpand(ctx: StateContext<FilterTreeStateModel>, { payload }: ToggleExpand ) {
    const getState = ctx.getState();
    const Tree = cloneDeep(getState.filterTree);
    let newTree;
    if (payload.isVirtual) {
      newTree = Tree.map( x => {
        if (x.id === payload.id && x.t === 'f' && x.s === 'virtual') {
          x.e = payload.isExpanded;
        }
        return x;
      });
    } else {
      const iter = x => {
        if (x.id === payload.id) {
          x.e = payload.isExpanded;
        } else if (x.c) {
          x.c.forEach(c => iter(c));
        }
        return x;
      };
      newTree = Tree.map( x => iter(x));
    }


    ctx.setState({
      ...getState,
      filterTree: newTree,
      lastAction: TreeActions.toggleExpand
    });
    this.setWorker(newTree);
  }

  @Action(ExpandUpTheTree)
  ExpandUpTheTree(ctx: StateContext<FilterTreeStateModel>, { payload }: ExpandUpTheTree ) {
    const getState = ctx.getState();
    let showZerosArray = new Array();
    if (getState.showZeros.length > 0) {
      showZerosArray = getState.showZeros;
    }
    const Tree = getState.filterTree;
    const iter = (x, id, parents?: Array<any>) => {
      if (x.id === id && x.t == 'g') {
        x.e = true;
        showZerosArray.push(id);
        if (parents) {
          parents.forEach( p => {
            p.e = true;
            showZerosArray.push(p.id);
          });
        }
      } else if (x.c) {
        const parentsArray = parents ? [...parents, x] : [x];
        x.c.forEach(c => iter(c, payload.id, parentsArray));
      }
      return x;
    };
    const newTree = Tree.map( x => iter(x, payload.id));

    ctx.setState({
      ...getState,
      filterTree: newTree,
      showZeros: showZerosArray,
      lastAction: null
    });
    this.filterService.filterTreeExpanded.next();
  }

  @Action(MoveFiltersAndChannels)
  MoveFiltersAndChannels(ctx: StateContext<FilterTreeStateModel>, { payload }: MoveFiltersAndChannels) {
    const getState = ctx.getState();
    let tree: Array<FilterTreeItem> = getState.filterTree;
    tree = this.move(tree, payload.destinationId, payload.filterIds, payload.groupIds);
    ctx.setState({...getState, filterTree: tree, lastAction: null});
    this.setWorker(tree);
  }

  move(tree, destinationId, filterIds, groupIds) {
    const filters = [];
    const groups = [];
    filterIds.forEach(f => {
      const removed = removeInTree({c: tree}, 'c', 'id', f, 't', 'f', true);
      filters.push(removed);
    });
    groupIds.forEach(g => {
      const removed = removeInTree({c: tree}, 'c', 'id', g, 't', 'g', true);
      groups.push(removed);
    });
    let destinationItem = findInTree({node: {c: tree}, childrenKey:'c', key: 'id', value: destinationId, additionalKey: 't', additionalValue: 'g'});
    if (typeof(destinationItem) == 'undefined') {
      tree = [...groups, ...filters, ...tree]
    } else {
      if (destinationItem.c) destinationItem.c = [...groups, ...filters, ...destinationItem.c];
      else destinationItem.c = [...groups,...filters];
    }
    return tree;
  }
}

function addChannelOrFiltersBelow(tree, parentFilterGroupId, channelOrFilters: { channel?: any, filters?: any}) {
  if (tree.find( x => x.id == parentFilterGroupId && x.t == 'g')) {
    tree.map(x => {
      if (x.id == parentFilterGroupId && x.t == 'g') {
        if (channelOrFilters.channel) {
          x.c = x.c || [];
          x.c.unshift(newChannel(channelOrFilters.channel, x.l + 1))
        } else if (channelOrFilters.filters) {
          const filters = channelOrFilters.filters.map( filter => {
            filter.l = x.l +1;
            return filter;
          });
          x.c = x.c || [];
          x.c = [...filters, ...x.c];
        }
      }
    });
  } else {
    tree = tree.map( x => {
      if (x.c) {
        x.c = addChannelOrFiltersBelow(x.c, parentFilterGroupId, channelOrFilters);
      }
      return x;
    });
  }

  return tree;
}

function newChannel(payload, level?) {
  return {
    id: payload.id,
    n: payload.name,
    t: 'g',
    e: payload.expanded,
    c: [],
    i: null,
    a: null,
    s: null,
    ie: null,
    l: level || 0,
    vf: []
  };
}

function newFilter(payload, level?) {
  return {
    id: payload.id,
    n: payload.n,
    t: 'f',
    i: null,
    a: null,
    ie: null,
    it: payload.it || null,
    l: level || 0,
    s: typeof(payload.s) != 'undefined' ? payload.s : null,
    vf: []
  };
}

function multiDelete(tree, ids, type) {
  tree = tree.filter( item => {
    if (item.t == type && ids.includes(item.id)) {
      ids = ids.filter( i => i != item.id);
      return false;
    } else {
      return true;
    }
  });
  tree = tree.map( x => {
    if (x.c) {
      x.c = multiDelete(x.c, ids, type);
    }
    return x;
  });
  return tree;
};

function deleteChannelOrFilter(tree, id, type) {
  if (tree.find( x => x.id == id && x.t == type)) {
    tree = tree.filter( item => {
      if (item.id == id && item.t == type) {
        return false
      } else return true;
    });
  } else {
    tree = tree.map( x => {
      if (x.c) {
        x.c = deleteChannelOrFilter(x.c, id, type);
      }
      return x;
    });
  }

  return tree;
}

function addVFilterToAllFilters(tree, filters, groups, virtualId) {
  if (tree.find( x => (x.t == 'f' && filters.includes(x.id)) || (x.t == 'g' && groups.includes(x.id)) )) {
    tree = tree.map( x => {
      if ( (x.t == 'f' && filters.includes(x.id)) || (x.t == 'g' && groups.includes(x.id)) ) {
        if (x.vf == null) {
          x.vf = [virtualId];
        } else if (!x.vf.includes(virtualId)) {
          x.vf.push(virtualId);
        }
      } else {
        if (x.vf != null && x.vf.includes(virtualId)) {
         x.vf = x.vf.filter(x => x != virtualId);
        }
      };
      return x;
    });
  } else {
    tree = tree.map( x => {
      if (x.c) {
        x.c = addVFilterToAllFilters(x.c, filters, groups, virtualId);
      }
      return x;
    });
  }

  return tree;
}

function toggleVfilterInFilter(tree, action, item, virtualId) {
  if (tree.find( x => x.id == item.id && x.t == item.t)) {
    tree = tree.map( x => {
      if (x.id == item.id && x.t == item.t) {
        if (action == 'add') {
          if (x.vf != null) {
            if (!x.vf.includes(virtualId)) {
              x.vf.push(virtualId);
            }
          } else {
            x.vf = [virtualId];
          }
        } else {
          x.vf = x.vf.filter(y => y != virtualId);
        }
      }
      return x;
    });
  } else {
    tree = tree.map( x => {
      if (x.c) {
        x.c = toggleVfilterInFilter(x.c, action, item, virtualId);
      }
      return x;
    });
  }

  return tree;
}

function editChannelOrFilter(operation, tree, id, type, name?) {
  if (tree.find( x => x.id == id && x.t == type)) {
    if (operation == 'rename') {
      tree = replaceName(tree, id, name, type);
    } else if (operation == 'clear_filter_errors') {
      tree = clearErrors(tree, id, type);
    }
  } else {
    tree = tree.map( x => {
      if (x.c) {
        x.c = editChannelOrFilter(operation, x.c, id, type, name);
      }
      return x;
    });
  }

  return tree;

  function replaceName(array, filterOrChannelId, filterOrChannelName, filterOrChannelType) {
    const newArray = array.map( x => {
      if (x.id == filterOrChannelId && x.t == filterOrChannelType) {
        x.n = filterOrChannelName;
      }
      return x;
    });
    return newArray;
  }

  function clearErrors(array, filterOrChannelId, filterOrChannelType) {
    const newArray = array.map( x => {
      if (x.id === filterOrChannelId) {
        clearAllChildErrors(x, filterOrChannelType);
      }
      return x;

      function clearAllChildErrors(x, type) {
        if (type == 'f') {
          x.ie = 0;
        } else {
          x.c.map( y => {
            clearAllChildErrors(y, y.t);
          });
        }
      }
    });
    return newArray;
  }

}
