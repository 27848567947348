import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'getValueByParamFromArray',
})
export class GetValueByParamFromArrayPipe implements PipeTransform {
  transform(array, value, paramName, valueParam): any {
    if (valueParam) {
      return array?.find( x => x[paramName] == value)[valueParam];
    } else {
      return array?.find( x => x[paramName] == value);
    }
  }
}