import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class SchemaService {
    constructor(private httpClient: HttpClient,) {

    }

    getSettingsConfig(integrationName) {
        const Url = `${environment.clientApiUrl}/v2/oauths/schema/${integrationName}`;
    
        return this.httpClient.get(Url);
    }

    getOptionsForSelect(url) {
        return this.httpClient.get(url);
    }
}