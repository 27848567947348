<form class="form-inline">
  <div class="form-group hidden">
    <div class="input-group datepicker">
      <input name="datepicker"
             class="form-control no-size"
             ngbDatepicker
             #datepicker="ngbDatepicker"
             container="{{notMain ? 'body' : 'none'}}"
             [autoClose]="'outside'"
             (dateSelect)="onDateSelection($event, datepicker)"
             [displayMonths]="2"
             [dayTemplate]="t"
             outsideDays="collapsed"
             firstDayOfWeek="7"
             [startDate]="fromDate"
             [minDate]="minDate"
             [maxDate]="maxDate"
             (closed)="removeFocus(); defineUndefined(); isSelectingEndDate = false; datePickerOpened = false; checkIfStartDateChanged($event, datepicker); setToday();">
      <ng-template #t let-date>
        <span class="custom-day"
              [class.hightlighted]="isToday(date)"
              [class.start-day]="isStart(date)"
              [class.end-day]="isEnd(date)"
              [class.disabled]="issBeforeOrAfterMinMax(date)"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              [class.selecting-endDate]="isSelectingEndDate"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          <span *ngIf="isLatestDate(date); else normal">
            <ng-template #calendarTooltip>
              Attribution data updates entirely within a 24hr window therefore when selecting <strong>today</strong> you may see inaccurate figures.
            </ng-template>
            <span placement="left" [ngbTooltip]="calendarTooltip" container="body">
              {{ date.day }}
            </span>
          </span>
          <ng-template #normal>
            <span >{{ date.day }}</span>
          </ng-template>
        </span>
      </ng-template>
    </div>
  </div>
  <div class="form-group">
    <div class="input-group datepicker-fake-input-wrapper" [class.datepicker-fake-input-wrapper__little]="notMain">
      <div class='datepicker-fake-input text-nowrap' [class.datepicker-fake-input__little]="notMain">
        <span class="date-hover" (click)="toggleDP(datepicker)" [class.blue]="datePickerOpened && !isSelectingEndDate">{{ fromDate ? (formatter.format(fromDate) | date: 'longDate') : notMain ? 'Start' : '...' }}</span>&nbsp;&nbsp;&ndash;&nbsp;&nbsp;<span class="date-hover" (click)="openEndDate(datepicker)" [class.blue]="datePickerOpened && isSelectingEndDate">{{ toDate ? (formatter.format(toDate) | date: 'longDate') : latestToDate ? (formatter.format(latestToDate) | date: 'longDate') : notMain ? 'End' : '...'}}</span>
      </div>
      <div *ngIf="!notMain" ngbDropdown class="input-group-append" placement="bottom-right">
        <button ngbDropdownToggle class="btn btn-outline-secondary no-arrow-dropdown h-100">
          <i class="fa-regular fa-fw fa-calendar-days"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="presets-dropdown">
          <div *ngFor="let preset of presets" ngbDropdownItem class="presets-dropdown-item" (click)="selectPreset(preset)" [class.disabled]="preset.outOfProjectRange">
            <span class="me-2">{{preset.label}}</span><span class="item-aside">{{ preset.start | date: 'mediumDate'}} - {{ preset.end | date: 'mediumDate'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<small *ngIf="dateWarning && !notMain" class="controllbar__hint bottom">
  <i class="fa-solid fa-triangle-exclamation red"></i>&nbsp;<span class="red">Todays data may be incomplete</span>
</small>