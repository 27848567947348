import { ReportStateModel } from '../states/report.state';

export class SetReport {
    static readonly type = '[report] SetReport';
    constructor(public payload: ReportStateModel) {}
}

export class PatchReport {
    static readonly type = '[report] PatchReport';
    constructor(public payload: any) {}
}

export class SetReportInProgress {
    static readonly type = '[report] SetReportInProgress';
    constructor(public payload?: { isFalse: boolean}) {}
}

export class SetReportNeedsToLoad {
    static readonly type = '[report] SetReportNeedsToLoad';
}

export class SetInitialLoading {
    static readonly type = '[report] SetInitialLoading';
}

export class ClearReport {
    static readonly type = '[report] ClearReport';
}
