import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';

import { SetRefreshState, SetRefreshGeneratedAt, SetRefreshEta, SetRefreshOvertime, SetRefreshIsVirtual } from 'src/app/store/actions/base.actions';
import { mergeMap, delay, map } from 'rxjs/operators';
import { iif, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportRefresherService {
  subscription;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) { }


  scheduleRefresh(isVirtual?) {
    const Url = `${environment.clientApiUrl}/v2/project/schedule_refresh`;

    let params: any = {};

    if (isVirtual) {
      params.only_virtual_filters = true;
    }

    return this.httpClient.post(Url, params).subscribe( (resp: any) => {
      if (resp.pending_refresh) {
        this.store.dispatch(new SetRefreshState(resp.pending_refresh == true || resp.pending_refresh == 'only_virtual_filters' ? 'pending' : 'done'));
        if (resp.pending_refresh == 'only_virtual_filters') this.store.dispatch(new SetRefreshIsVirtual(true));
      }
    });
  }

  refreshReport() {
    const Url = `${environment.clientApiUrl}/v2/report/refresh`;
    let params:any = {};

    return this.subscription = this.httpClient.post(Url, params, { observe: 'response' }).pipe(
      mergeMap( (resp: HttpResponse<any>) => {
        this.store.dispatch(new SetRefreshState('refreshing'));
        return this.getStatus();
      })
    ).pipe(map((resp: any) => {
        this.store.dispatch([new SetRefreshState('ready'), new SetRefreshGeneratedAt(resp.meta.refresh.generated_at)]);
      })
    );
  }

  getStatus(previousResponse?) {
    if (previousResponse) {
      if (previousResponse.meta.refresh.eta) {
        this.store.dispatch(new SetRefreshEta(previousResponse.meta.refresh.eta));
      }
      if (previousResponse.meta.refresh.overtime) {
        this.store.dispatch(new SetRefreshOvertime(previousResponse.meta.refresh.overtime));
      }
    }

    const Url = `${environment.reportingApiUrl}/v2/reports/status`;

    const PreviousGeneratedAt = this.store.selectSnapshot(state => state.base.refresher.generated_at);

    return this.httpClient.get(Url).pipe(
      delay(1000),
      mergeMap( (resp: any) =>
        iif(
          () => {
            return resp.meta &&
                resp.meta.refresh &&
                resp.meta.refresh.generated_at &&
                resp.meta.refresh.generated_at !== PreviousGeneratedAt;
          },
          of(resp),
          this.getStatus(resp)
        )
      )
    );
  }
}
