import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';

@Component({
  selector: 'app-timeframe-row',
  templateUrl: './timeframe-row.component.html',
  styleUrls: ['./timeframe-row.component.scss']
})
export class TimeframeRowComponent {
  @Input() periodArray;
  @Input() report;
  @Input() selectedAttributionModel;
  @Input() item;
  @Input() itemId;
  @Input() special;
  @Input() rowName;
  @Input() tableRevenue;
  @Input() isFTP:boolean;

  @Output() OpenSidebar = new EventEmitter();

  sidebarNames = SidebarNames;

  constructor() { }

  itemCostPerConversion(periodId) {
    return this.report[periodId] ? this.report[periodId].a / this.report[periodId].c : 0;
  }

  itemConversionRate(periodId) {
    return this.report[periodId] ? this.report[periodId].c / this.report[periodId].e : 0
  }

  itemRevenuePerConversion(periodId) {
    return this.report[periodId] ? this.report[periodId].r / this.report[periodId].c : 0
  }

  itemProfitOrLoss(periodId) {
    return this.report[periodId] ? this.report[periodId].r - this.report[periodId].a : 0
  }

  openSidebar(path, period, params?) {
    if (path == 'amounts' && params.rowName) return;
    if (period) {
      params.periodStart = period.start;
      params.periodEnd = period.end;
    }
    if (this.itemId) {
      params.id = this.itemId;
      params.type = 'f';
      params.special = this.item.s;
    }
    this.OpenSidebar.emit({path, params});
  }

}
