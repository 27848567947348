import { State, Action, StateContext, Store } from '@ngxs/store';

import { CheckAppVersion, SetAppVersion, ClearBaseState, SetRefreshState, SetRefreshGeneratedAt, SetRefreshEta, SetRefreshOvertime, ToggleFirstPurchaseOnly, SetRefreshIsVirtual, SetIsOnboarding, SetIsAuthenticated, SetIsInitialLoad, AddToPendingDataRefresh, SetPendingRefreshMovingFrom } from '../actions/base.actions';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Logout } from '../actions/logout.action';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/core/services/base/base.service';
import { Injectable } from '@angular/core';
import { Refresher } from '../interfaces/base';
import { findInTree } from 'src/app/core/helpers/find-in-tree';

export interface BaseStateModel {
  isAuthenticated: boolean;
  appVersion: string;
  fullVersion: string;
  branch: string;
  refresher: Refresher;
  firstPurchaseOnly: boolean;
  isOnboarding: boolean;
  isInitialLoad: boolean;
  pendingDataRefresh: {
    movingFrom?: number;
    movingFromPath?: any[];
    filter: {
      [id: number]: number;
    };
    group: {
      [id: number]: number;
    };
  }
}

@State<BaseStateModel>({
  name: 'base',
  defaults: {
    isAuthenticated: false,
    appVersion: '',
    fullVersion: '',
    branch: '',
    refresher: {
      state: 'done',
      generated_at: '',
      eta: '',
      overtime: '',
      isVirtual: false
    },
    firstPurchaseOnly: false,
    isOnboarding: false,
    isInitialLoad: true,
    pendingDataRefresh: {
      movingFrom: null,
      movingFromPath: [],
      filter: {},
      group: {}
    }
  }
})
@Injectable()
export class BaseState {
  constructor(
    private store: Store,
    private authService: AuthService,
    private toastr: ToastrService,
    private baseService: BaseService
  ) {}

  @Action(CheckAppVersion)
  CheckAppVersion(ctx: StateContext<BaseStateModel>, { payload }: CheckAppVersion) {
    const getState = ctx.getState();

    if (payload.version !== getState.appVersion) {
      this.store.dispatch(new Logout()).subscribe( () => {
        this.store.dispatch(new SetAppVersion({version: payload.version, fullVersion: payload.fullVersion, branch: payload.branch}));
        this.authService.getAccountAndProject().subscribe( () => {
          if ( getState.appVersion !== '') {
            setTimeout(() => {
              this.baseService.refreshAllData();
              this.toastr.warning(
                `Application has been updated. Please reload the page to use the latest version. <a href="${this.baseService.currentPath}">Refresh</a>`,
                `status: ${status}`,
                {disableTimeOut: true, enableHtml: true}
              )
            });
          }
        });
      });
    }
  }

  @Action(AddToPendingDataRefresh)
  AddToPendingDataRefresh(ctx: StateContext<BaseStateModel>, { payload }: AddToPendingDataRefresh) {
    const getState = ctx.getState();
    const type = payload.type === 'f' ? 'filter' : 'group';
    const id = payload.id;
    const timestamp = Date.now();

    let newState = {
      ...getState,
    };

    if (payload.case === 'move') {
      if (newState.pendingDataRefresh.movingFrom === payload.movingTo) {
        return;
      } else if (newState.pendingDataRefresh.movingFromPath.length > 0) {
        newState.pendingDataRefresh.movingFromPath.forEach((pathItem) => {
          newState = {
            ...newState,
            pendingDataRefresh: {
              ...newState.pendingDataRefresh,
              group: {
                ...newState.pendingDataRefresh.group,
                [pathItem.id]: timestamp,
              },
            }
          };
        });
      }
    }

    if (payload.case === 'creating-in-channel') {
      newState = {
        ...newState,
        pendingDataRefresh: {
          ...newState.pendingDataRefresh,
          group: {
            ...newState.pendingDataRefresh.group,
            [payload.movingTo]: timestamp,
          },
        }
      };
      newState.pendingDataRefresh.movingFromPath.forEach((pathItem) => {
        newState = {
          ...newState,
          pendingDataRefresh: {
            ...newState.pendingDataRefresh,
            group: {
              ...newState.pendingDataRefresh.group,
              [pathItem.id]: timestamp,
            },
          }
        };
      });
    }

    if (payload.id) {
      newState = {
        ...newState,
        pendingDataRefresh: {
          ...newState.pendingDataRefresh,
          [type]: {
            ...newState.pendingDataRefresh[type],
            [id]: timestamp,
          },
        }
      };
      if (payload.pathArray && payload.pathArray.length > 0) {
        payload.pathArray.forEach((pathItem) => {
          newState = {
            ...newState,
            pendingDataRefresh: {
              ...newState.pendingDataRefresh,
              group: {
                ...newState.pendingDataRefresh.group,
                [pathItem.id]: timestamp,
              },
            }
          };
        });
      }
    } else {
      payload.ids.forEach((idItem) => {
        newState = {
          ...newState,
          pendingDataRefresh: {
            ...newState.pendingDataRefresh,
            [type]: {
              ...newState.pendingDataRefresh[type],
              [idItem]: timestamp,
            },
          }
        };
      });
    }

    if (payload.movingTo && !payload.pathArray) {
      const tree = this.store.selectSnapshot(state => state.filterTree.filterTree);
      const node = findInTree({node: {c: tree }, childrenKey: 'c', key: 'id', value: payload.movingTo, additionalKey: 't', additionalValue: 'g', withPath: tree, getPathIds: true});
      if (node && node.pathArray && node.pathArray.length > 0) {
        [node,...node.pathArray].forEach((pathItem) => {
          newState = {
            ...newState,
            pendingDataRefresh: {
              ...newState.pendingDataRefresh,
              group: {
                ...newState.pendingDataRefresh.group,
                [pathItem.id]: timestamp,
              },
            }
          };
        });
      }
    }

    ctx.setState(newState);
  }

  @Action(SetPendingRefreshMovingFrom)
  SetPendingRefreshMovingFrom(ctx: StateContext<BaseStateModel>, { payload }: SetPendingRefreshMovingFrom) {
    const getState = ctx.getState();
    const newState: BaseStateModel = {
      ...getState,
      pendingDataRefresh: {
        ...getState.pendingDataRefresh,
        movingFrom: payload.id,
        movingFromPath: payload.pathArray,
      }
    };
    ctx.setState(newState);
  }

  @Action(SetIsOnboarding)
  SetIsOnboarding(ctx: StateContext<BaseStateModel>, { payload }: SetIsOnboarding) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      isOnboarding: payload
    });
  }

  @Action(SetIsInitialLoad)
  SetIsInitialLoad(ctx: StateContext<BaseStateModel>, { payload }: SetIsInitialLoad) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      isInitialLoad: payload
    });
  }

  @Action(SetIsAuthenticated)
  SetIsAuthenticated(ctx: StateContext<BaseStateModel>, { payload }: SetIsAuthenticated) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      isAuthenticated: payload
    });
  }

  @Action(SetRefreshState)
  SetRefreshState(ctx: StateContext<BaseStateModel>, { payload }: SetRefreshState) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      refresher: { ...getState.refresher, state: payload}
    });
  }

  @Action(SetRefreshIsVirtual)
  SetRefreshIsVirtual(ctx: StateContext<BaseStateModel>, { payload }: SetRefreshIsVirtual) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      refresher: { ...getState.refresher, isVirtual: payload}
    });
  }

  @Action(ToggleFirstPurchaseOnly)
  ToggleFirstPurchaseOnly(ctx: StateContext<BaseStateModel>) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState(
      {
        ...getState,
        firstPurchaseOnly: !getState.firstPurchaseOnly
      }
    );
  }

  @Action(SetRefreshGeneratedAt)
  SetRefreshGeneratedAt(ctx: StateContext<BaseStateModel>, { payload }: SetRefreshGeneratedAt) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      refresher: { ...getState.refresher, generated_at: payload}
    });
  }

  @Action(SetRefreshEta)
  SetRefreshEta(ctx: StateContext<BaseStateModel>, { payload }: SetRefreshEta) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      refresher: { ...getState.refresher, eta: payload}
    });
  }

  @Action(SetRefreshOvertime)
  SetRefreshOvertime(ctx: StateContext<BaseStateModel>, { payload }: SetRefreshOvertime) {
    const getState: BaseStateModel = ctx.getState();
    ctx.setState({
      ...getState,
      refresher: { ...getState.refresher, overtime: payload}
    });
  }

  @Action(SetAppVersion)
  SetAppVersion(ctx: StateContext<BaseStateModel>, { payload }: SetAppVersion) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      appVersion: payload.version,
      fullVersion: payload.fullVersion,
      branch: payload.branch
    });
  }

  @Action(ClearBaseState)
  ClearBaseState(ctx: StateContext<BaseStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      isAuthenticated: false,
      appVersion: '',
      fullVersion: '',
      refresher: {
        state: 'done',
        generated_at: '',
        eta: '',
        overtime: '',
        isVirtual: false
      },
      isInitialLoad: true,
      pendingDataRefresh: {
        movingFrom: null,
        movingFromPath: [],
        filter: {},
        group: {},
      }
    });
  }

}
