import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { SetSubscription, SetPrevSubscription } from 'src/app/store/actions/settings.actions';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  get(lastActive?) {
    const Url = `${environment.clientApiUrl}/subscription`;

    let params = lastActive ? {last_active: 'true'} : {};

    return this.httpClient.get(Url, {params: params, observe: 'body'}).subscribe( (resp: any) => {
      if (lastActive) {
        this.store.dispatch(new SetPrevSubscription(resp));
      } else {
        if (!resp.status) {
          this.get(true);
        } else {
          this.store.dispatch(new SetSubscription(resp));
        }
      }
    });
  }
}
