<div>
  <div class="settings__title d-flex justify-content-between align-items-center">
    <div>
      <i class="go-back-button fa-solid fa-chevron-left me-5 clickable fa-2x align-middle" (click)="goBack()"></i>
      <div class="d-inline-block">
        <app-integration-logo [integrationName]="integrationName" [isShow]="true"></app-integration-logo>
      </div>
      <span *ngIf="integrationName == 'facebook3' || integrationName == 'facebook4'" class="integration-facebook">Facebook Ads</span>
      <span *ngIf="integrationName == 'facebook_leads'" class="integration-facebook">Facebook Lead Ads</span>
      <span *ngIf="integrationName == 'twitter'" class="integration-twitter">X Ads</span>
      <span *ngIf="integrationName | includedInArray:['bing', 'msads']" class="integration-bing">Advertising</span>
    </div>
    <div>
      <a *ngIf="learnMoreLink" href="{{learnMoreLink}}" class="btn btn-default learn-more-link" target="_blank">
        <i class="fa-solid fa-book blue"></i>
        Learn More
      </a>
      <button *ngIf="!(integrationName | includedInArray:['adwords', 'googleads', 'googleads2', 'linkedin'])" class="btn btn-connect btn-success ms-2" (click)="setup()">
        <i class="fa-solid fa-plus"></i> Connect
      </button>
      <button *ngIf="['googleads', 'googleads2'].indexOf(integrationName) > -1" class="google-button ms-3" (click)="setup()">
        <img src="/assets/images/integrations/google-g.svg" alt="Sign in with Google" width="38" height="38">Sign in with Google
      </button>
    </div>
  </div>
</div>
<ul class="list-group">
  <li *ngFor="let oauth of oauths" [ngClass]="{'list-group-item-danger': oauth.disconnecting}"  class="list-group-item vertical-align oauth__block">
    <div class="row">
      <div class="col-md-7">
        <h4>
          <app-integration-status-icon [oauth]="oauth"></app-integration-status-icon>
          {{ oauth | oauthStatus }}: <strong>{{ oauth.name }}</strong>
          <div class="ms-3 inline-block medium-dark" *ngIf="oauth.identifier != null && oauth.identifier.length > 0; else incomplete">
            <span *ngIf="oauth.type | includedInArray:['adwords', 'googleads', 'googleads2']; else identifierTemplate">
              {{ [oauth.identifier.slice(0,3), oauth.identifier.slice(3,6), oauth.identifier.slice(6)].join('-') }}
            </span>
            <ng-template #identifierTemplate>
              <span>{{ oauth.identifier }}</span>
            </ng-template>
          </div>
          <span *ngIf="oauth.type | includedInArray:legacyIntegrations" class="badge badge-success ms-3">Legacy</span>
          <ng-template #incomplete>
            <em class="medium">(incomplete)</em>
          </ng-template>
        </h4>

        <div class="medium-dark small d-flex justify-content-start">
          <div>
            <div *ngIf="oauth.issued_at && !oauth.expired_at">Token issued on {{ oauth.issued_at | date:'medium' }}</div>
            <div *ngIf="oauth.expired_at">Disconnected on {{ oauth.expired_at | date:'medium' }} <span *ngIf="oauth.disconnected_reason">(reason: {{ oauth.disconnected_reason }})</span></div>
          </div>
          <span *ngIf="oauth.settings.currency" class="badge bg-secondary ms-2" title="Integration uses non-default currency">{{ oauth.settings.currency }}</span>
        </div>
      </div>

      <div class="col-md-5 d-flex justify-content-end align-items-center">
        <i *ngIf="oauth.id | includedInArray:beingDeleted" class="fa-solid fa-trash-alt me-2 medium" ngbTooltip="In the process of deleting this oauth"></i>
        <button *ngIf="oauth.errors_count > 0" class="btn btn-sm btn-default red me-1" (click)="navigateToIntegrationErrorModal(oauth)">
          <strong>{{ oauth.errors_count }}</strong> {{ oauth.errors_count == 1 ? 'Error' : 'Errors' }}
        </button>
        @if (isNotTransformerOrIsSettingsEnabbled(oauth)) {
          <button class="btn btn-sm btn-success me-1" (click)="editSettings(oauth)"><i class="fa-solid fa-cog" [class.disabled]="oauth.id | includedInArray:beingDeleted"></i> Settings</button>
        }
        <button class="btn btn-sm btn-success me-1" (click)="manage(oauth)" *ngIf="oauth | canManageIntegration">
          <i class="fa-solid fa-gears" [class.disabled]="oauth.id | includedInArray:beingDeleted"></i> Manage Campaigns</button>
        <button class="btn btn-sm btn-default me-1" (click)="openJobLogs(oauth)">
          <i class="fa fa-list-ul" [class.disabled]="oauth.id | includedInArray:beingDeleted"></i> Logs
        </button>
        <button class="btn btn-sm btn-warning me-1 " *ngIf="!(oauth.type | includedInArray:legacyIntegrations) && oauth.expired_at != null" (click)="setup(true)"><i class="fa-solid fa-rotate"></i> Reconnect</button>
        <button *ngIf="oauth.expired_at == null; else deleteButton" class="btn btn-sm btn-default" (click)="oauth.isBeingDisconnected = true;" [class.disabled]="oauth.isBeingDisconnected">
          <i class="fa-solid fa-link-slash"></i> Disconnect
        </button>
        <ng-template #deleteButton>
          <button class="btn btn-sm btn-default" (click)="oauth.isBeingDeleted = true;" [class.disabled]="oauth.isBeingDeleted"><i class="fa-solid fa-trash"></i> Delete</button>
        </ng-template>
      </div>

      <div *ngIf="oauth.isBeingDisconnected" class="p-3">
        <div class="alert alert-warning mb-0">
          <p><strong>Warning:</strong> Existing data (filters, spent amounts, events) associated with this account won't be lost, but no further updates will happen, integration will be paused.</p>
          <button class="btn btn-warning caps me-1" (click)="disconnectOauth(oauth)">Disconnect</button>
          <button class="btn btn-default caps" (click)="oauth.isBeingDisconnected = false;">Cancel</button>
        </div>
      </div>

      <div *ngIf="oauth.isBeingDeleted" class="p-3">
        <div class="alert alert-danger mb-0">
          <p><strong>Danger:</strong> All data associated with this account (filters, spent amounts) will be permanently deleted! Events generated by integration will stay intact.</p>
          <button class="btn btn-danger  caps me-1" (click)="deleteOauth(oauth)">Delete</button>
          <button class="btn btn-default caps" (click)="oauth.isBeingDeleted = false;">Cancel</button>
        </div>
      </div>
    </div>
  </li>
</ul>

<app-timezone-setting *ngIf="project" [integration]="integrationName" [project]="project"></app-timezone-setting>

<div *ngIf="docs" class="docs-wrapper hide-prod">
  <div class="docs">
    <div class="attr-card">
      <div class="d-flex align-items-center mb-3">
        <div>
          <div>
            <h1 class="mb-0 d-inline">
              Documentation &ndash; {{ docs.title }}
              <a href="https://docs.attributionapp.com/docs/{{slug}}" target="_blank" ngbTooltip="https://docs.attributionapp.com/docs/{{slug}}" placement="right">
                <i class="fa-solid fa-arrow-up-right-from-square fa-2xs blue"></i>
              </a>
            </h1>
          </div>
          <p *ngIf="docs.excerpt" class="excerpt">{{ docs.excerpt }}</p>
          <hr>
        </div>
      </div>
      <div [innerHTML]="docs.body_html"></div>
    </div>
  </div>
</div>
