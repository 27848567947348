export class ClearUsages {
  static readonly type = '[settings] ClearUsages';
}

export class SetUsage {
  static readonly type = '[settings] SetUsage';
  constructor(public payload: any) {}
}

export class SetUsageReport {
  static readonly type = '[settings] SetUsageReport';
  constructor(public payload: any) {}
}

export class SetSubscription {
  static readonly type = '[settings] SetSubscription';
  constructor(public payload: any) {}
}

export class SetPrevSubscription {
  static readonly type = '[settings] SetPrevSubscription';
  constructor(public payload: any) {}
}

export class SetProducts {
  static readonly type = '[settings] SetProducts';
  constructor(public payload: Array<any>) {}
}

export class SetCard {
  static readonly type = '[settings] SetCard';
  constructor(public payload: any) {}
}

export class SetNeedReportRefresh {
  static readonly type = '[settings] SetNeedReportRefresh';
  constructor(public payload: boolean) {}
}

export class SetAccesses {
  static readonly type = '[settings] SetAccesses';
  constructor(public payload: any) {}
}

export class SetTwitterTokens {
  static readonly type = '[settings] SetTwitterTokens';
  constructor(public payload: {request_token: string, request_token_secret: string}) {}
}