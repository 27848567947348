import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IntegrationAuthService } from 'src/app/core/services/integrations/integration-auth.service';

@Component({
  selector: 'app-stackadapt',
  templateUrl: './stackadapt.component.html',
  styleUrls: ['./stackadapt.component.scss']
})
export class StackadaptComponent {
  @Input() integration;
  @Input() step = 0;
  @Input() type = 'explainer';

  error = null;
  waitingForResponse = false;

  connectForm = this.fb.group({
    apikey: ['', [Validators.required]]
  });
  learnMoreLink: any;

  constructor(private service: IntegrationAuthService, public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private router: Router) {}

  connectLogin() {
    if (this.connectForm.invalid) return;
    const apikey = this.connectForm.get('apikey').value;

    this.waitingForResponse = true;
    this.service.connect(this.integration, { apikey: apikey}).subscribe( x => {
      this.waitingForResponse = false;
      this.activeModal.close();
      this.router.navigate([],  { queryParams: {
        integration: this.integration.name,
        apikey: apikey
      }})
    }, e => {
      this.waitingForResponse = false;

      switch (e.status) {
        case 401:
          this.error = 'API Key is incorrect.'
          break;
        case 403:
          this.error = 'Access denied. Please make sure you have requested API access.'
          break;
        case 429:
          this.error = e.message + '. Please try again later.';
          break;
        default:
          this.error = 'Unknown error. Please contact Attribution support.'
      }
    });
  }

  close() {
    this.activeModal.close('close');
  }

}
