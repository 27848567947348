import { State, Action, StateContext } from '@ngxs/store';

import { SetCohort, SetCohortInProgress, ClearCohort, SetCohortFilter } from '../actions/cohort.actions';
import { Injectable } from '@angular/core';
import { CohortService } from 'src/app/core/services/cohort/cohort.service';
import { SelectedFilter } from '../interfaces/cohort';

export interface CohortStateModel {
  inProgress: boolean;
  meta: any;
  report: any;
  selectedFilter: SelectedFilter;
}

@State<CohortStateModel>({
  name: 'cohort',
  defaults: {
    inProgress: false,
    meta: null,
    report: null,
    selectedFilter: null
  }
})
@Injectable()
export class CohortState {
  constructor(private cohorService: CohortService) {}

  @Action(SetCohort)
  SetCohort(ctx: StateContext<CohortStateModel>, { payload }: SetCohort) {
    const getState = ctx.getState();
    ctx.setState({...getState, ...payload, inProgress: false});
  }

  @Action(SetCohortFilter)
  SetCohortFilter(ctx: StateContext<CohortStateModel>, { payload }: SetCohortFilter) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: null,
      selectedFilter: payload.filter
    });
  }

  @Action(SetCohortInProgress)
  SetCohortInProgress(ctx: StateContext<CohortStateModel>, { payload }: SetCohortInProgress) {
    const getState = ctx.getState();
    ctx.setState({...getState, inProgress: payload?.isFalse ? false : true});
  }

  @Action(ClearCohort)
  ClearCohort(ctx: StateContext<CohortStateModel>) {
    if (typeof(this.cohorService.subscription) !== 'undefined' && this.cohorService.subscription !== null) {
      this.cohorService.subscription.unsubscribe();
      this.cohorService.subscription = null;
    }
    const getState = ctx.getState();
    ctx.setState({...getState, report: null, meta: null, inProgress: false});
  }

}
