import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardCalculateZeros',
})
export class DashboardCalculateZerosPipe implements PipeTransform {
  transform(children, report, showTimeframe): any {
    if (report && children && children.length > 0) {
        let total = 0;
        children.forEach(child => {
            const type = showTimeframe ? child.t : child.t === 'f' ? 'filters' : 'groups';
            if (typeof(report[type][child.id]) === 'undefined') {
                total++;
            }
        });
        return total;
    } else return 0;
  }
}