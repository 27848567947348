<h1 class="mt-0">Team</h1>
<div class="attr-card">
    <p class="title-no-bold">Invite Your Team</p>
    <p>Team members will have full access to Attribution.</p>

  <div uib-alert class="alert-danger p-3 mb-3" *ngIf="error" close="error = null">
    {{ error }} <span *ngIf="errorUpgrade">
      <a class="alert-link" [routerLink]="[componentNames.account, settingsSections.billing]">Upgrade</a> to invite more users.
    </span>
  </div>

  <form [formGroup]="emailForm">
    <div class="input-group input-group--single">
      <input class="form-control form-control-lg" placeholder="email@example.com" type="email" [(ngModel)]="email" formControlName="email">
      <div class="input-group-btn">
        <button class="btn btn-success" [ngClass]="{disabled: emailForm.status == 'INVALID'}" (click)="saveAccess()">
          <span class="caps">Add Team Member</span>
        </button>
      </div>
    </div>
    <div class="help-inline" *ngIf="emailForm.controls.email.errors?.email">
      That doesn't look like an email address...
    </div>
  </form>

  <div class="spacer">
    <div class="clearfix invite__line" *ngFor="let access of accesses">
      <hr>
      <img class="invite__gravatar" *ngIf="access.email" src="https://secure.gravatar.com/avatar/{{ access.email | md5 }}?d=retro" />
      <p class="strong">{{ access.email }}</p>
      <p class="small muted pull-left">
        <span *ngIf="access.owner">Owner</span>
        <span class="small muted" *ngIf="!access.active">Invited</span>
      </p>
      <span *ngIf="access.last_accessed_at" class="invite__last_accessed_at">
        Last accessed on {{ access.last_accessed_at | date: 'short'}}
      </span>
      <span *ngIf="!access.owner" class="invite__delete">
        <ng-template #deleting>
          <a class="btn btn-link" (click)="access.deleting = true;">
            <i class="fa-solid fa-user-xmark red"></i>
          </a>
        </ng-template>
        <span *ngIf="access.deleting; else deleting">
          <span class="btn btn-danger caps" (click)="delete(access)"> Delete {{ access.email }}</span>
          <a class="btn btn-link caps" (click)="access.deleting = false;"> Whoops, nevermind</a>
        </span>
      </span>
    </div>
  </div>
</div>
<div class="attr-card mt-5">
  <p class="title-no-bold">API Token</p>
  <p>You can use our API to automate reports and build complex tasks. Please use this auth token when making calls:</p>

  <div *ngIf="account && project && account.id == project.owner.id; else noToken">
    <div class="input-group input-group-lg mb-4">
      <input type="text" class="form-control" readonly="readonly" [(ngModel)]="projectApiToken" name="projectApiToken">
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" (click)='copyInputGroupValue()'>Copy</button>
      </span>
    </div>
    <div class="mb-5">
      <h4>Example usage:</h4>
      <code>$ curl -H "Authorization: Bearer {{ projectApiToken }}" https://api.attributionapp.com/filters</code>
    </div>
  </div>
  <ng-template #noToken>
    <div class="alert alert-danger">
      Only project owner has access to API Token.
    </div>
  </ng-template>



  <div class="alert alert-danger" *ngIf="!project?.options?.features?.api_access">
    <i class="fa-solid fa-triangle-exclamation"></i>
    Your current API access is limited. Please <a [routerLink]="['/', componentNames.account, settingsSections.billing]">upgrade</a>.
  </div>
</div>
