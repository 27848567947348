import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReadMeComDocsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  get(slug: string) {
    const Url = `${environment.clientApiUrl}/integrations/readme-text/${slug}`;

    return this.httpClient.get(
      Url);
  }
}
