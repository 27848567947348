import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

@Component({
  selector: 'app-complete-setup-dialog',
  templateUrl: './complete-setup-dialog.component.html',
  styleUrls: ['./complete-setup-dialog.component.scss']
})
export class CompleteSetupDialogComponent {
  @Output() onClose = new EventEmitter<any>();

  constructor(private router: Router, private store: Store) { }

  goToAds() {
    const project = this.store.selectSnapshot(state => state.project.project);
    if (project) {
      this.router.navigate([project.identifier, ComponentNames.settings, SettingsSections.integrations], { queryParams: {type: 'ads'}});
    }
  }

  goToSettings() {
    const project = this.store.selectSnapshot(state => state.project.project);
    if (project) {
      this.router.navigate([project.identifier, ComponentNames.settings, SettingsSections.instructions])
    }
  }

  close() {
    this.onClose.emit('Closed');
  }

}
