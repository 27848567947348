import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { Store } from '@ngxs/store';
import { findAllInTree } from '../../helpers/find-in-tree';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService,
    private store: Store) {}

  getFiltered(filterOrGroupId, type, since?, periodStart?, periodEnd?) {
    let Url;
    if (filterOrGroupId) {
      Url = `${environment.reportingApiUrl}/v2/conversions/filtered`;
    } else if (type === 'raw') {
      Url = `${environment.reportingApiUrl}/v2/conversions/raw`;
    } else {
      Url = `${environment.reportingApiUrl}/v2/conversions/all`;
    }
    const Params: any = {
      ...this.baseService.getReportingParams
    };

    if (type === 'f') {
      Params.filter = filterOrGroupId;
    } else if (type === 'g') {
      Params.filter_group_id = filterOrGroupId;
    } else if (type == 'v') {
      Params.virtual_filter = filterOrGroupId;
    }

    if (since) { Params.since = since; }

    const selectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected);
    Params.start = selectedState.chosenDates.start;
    Params.end = selectedState.chosenDates.end;

    if (periodStart && periodEnd) {
      Params.period_start = periodStart;
      Params.period_end = periodEnd;
    }

    return this.httpClient.post(Url, {}, {params: Params, observe: 'body'});
  }

  getOverlaps(filterOrGroupId, type) {
    const Url = `${environment.reportingApiUrl}/v2/conversions/overlap`;

    const Params: any = {
      ...this.baseService.getReportingParams
    };

    const filterTree = this.store.selectSnapshot<Array<any>>(state => state.filterTree.filterTree);
    const filters = findAllInTree({c: filterTree}, 'c', 't', 'f').map( x => {
      return {filter: x.id, name: x.n};
    });

    if (type === 'f') {
      Params.filter = filterOrGroupId;
    } else if (type === 'g') {
      Params.filter_group_id = filterOrGroupId;
    } else if (type == 'v') {
      Params.virtual_filter = filterOrGroupId;
    }

    return this.httpClient.post(Url, filters, {params: Params, observe: 'body'});
  }
}
