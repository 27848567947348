import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { Account } from 'src/app/core/models/account.model';
import { SetAccount } from 'src/app/store/actions/account.actions';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  apiUrl = environment.clientApiUrl;
  inProgress;

  constructor(
    private httpClient: HttpClient,
    private store: Store) { }

  getAccountInfo() {
    const Url = this.apiUrl + `/account`;

    this.inProgress = true;
    return this.httpClient.get<Account>(Url).pipe(
      map(
        (response: Account) => {
          this.inProgress = false;
          this.store.dispatch(new SetAccount(response));
          return response;
        }, error => this.inProgress = false
      )
    );
  }

  getValidate(userEmail) {
    const Url =  `${this.apiUrl}/accounts`;

    return this.httpClient.get(Url, {params: {email: userEmail}, observe: 'body'});
  }

  postValidate(code, userEmail, userPassword) {
    const Url =  `${this.apiUrl}/accounts`;

    return this.httpClient.post(Url, {email: userEmail, password: userPassword, inviteCode: code});
  }
}
