import {
  trigger,
  style,
  transition,
  animate
} from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    transition(':enter', [
      style({transform: 'translateY(-15%)'}),
      animate('300ms linear', style({transform: 'translateY(0%)'}))
    ])
  ])
];

export const SlideInOutAnimationLTR = [
  trigger('SlideInOutAnimationLTR', [
    transition(':enter', [
      style({transform: 'translateX(-15%)'}),
      animate('400ms ease-in-out', style({transform: 'translateX(0%)'}))
    ])
  ])
];

export const SlideInOutAnimationRTL = [
  trigger('SlideInOutAnimationRTL', [
    transition(':enter', [
      style({transform: 'translateX(15%)'}),
      animate('300ms ease-in-out', style({transform: 'translateX(0%)'}))
    ])
  ])
];