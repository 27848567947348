<div class="modal-header">
  <h4 class="modal-title">
    <button type="button" class="btn-close" data-dismiss="modal" (click)="close()"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
    Reddit Connection Setup
  </h4>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div *ngIf="error" class="alert alert-danger alert-dismissible" role="alert">
          {{ error }}
        </div>
        <form [formGroup]="connectForm" autocomplete="false">
          <div class="form-group">
            <label for="redditClientId">Reddit Client ID Key</label>
            <input type="text" formControlName="client_id" class="form-control">
            <label for="redditSecretKey">Reddit Secret Key</label>
            <input type="text" formControlName="secret_key" class="form-control">
          </div>

          <div class="text-center">
            <button class="btn btn-success" (click)="connectLogin()">
              <span *ngIf="!waitingForResponse; else waiting" class='caps'>
                Connect
              </span>
              <ng-template #waiting>
                <i class="fa-solid fa-spinner fa-spin"></i>
              </ng-template>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
