export class CheckAppVersion {
  static readonly type = '[base] CheckAppVersion';
  constructor(public payload: {version: string; fullVersion: string; branch?: string;}) {}
}

export class SetIsAuthenticated {
  static readonly type = '[base] SetIsAuthenticated';
  constructor(public payload: boolean) {}
}

export class SetAppVersion {
  static readonly type = '[base] SetAppVersion';
  constructor(public payload: {version: string; fullVersion: string; branch?: string;}) {}
}

export class AddToPendingDataRefresh {
  static readonly type = '[base] AddToPendingDataRefresh';
  constructor(public payload: {id?: number; ids?: number[]; type: string; pathArray?: any[], case?: string; movingTo?: number;}) {}
}

export class SetPendingRefreshMovingFrom {
  static readonly type = '[base] SetPendingRefreshMovingFrom';
  constructor(public payload: {id: number; pathArray?: any[]; }) {}
}

export class ClearBaseState {
  static readonly type = '[base] ClearBaseState';
}

export class SetRefreshState {
  static readonly type = '[base] SetRefreshState';
  constructor(public payload: string) {}
}

export class SetRefreshIsVirtual {
  static readonly type = '[base] SetRefreshIsVirtual';
  constructor(public payload: boolean) {}
}

export class SetRefreshGeneratedAt {
  static readonly type = '[base] SetRefreshGeneratedAt';
  constructor(public payload: string) {}
}

export class SetRefreshEta {
  static readonly type = '[base] SetRefreshEta';
  constructor(public payload: string) {}
}

export class SetRefreshOvertime {
  static readonly type = '[base] SetRefreshOvertime';
  constructor(public payload: string) {}
}

export class ToggleFirstPurchaseOnly {
  static readonly type = '[base] ToggleFirstPurchaseOnly';
}

export class SetIsOnboarding {
  static readonly type = '[base] SetIsOnboarding';
  constructor(public payload: boolean) {}
}

export class SetIsInitialLoad {
  static readonly type = '[base] SetIsInitialLoad';
  constructor(public payload: boolean) {}
}