import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConversionEvent } from '../../models/conversionEvent.model';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetConversionEvents, SetRevenueEvents } from 'src/app/store/actions/conversion-events.action';
import { of } from 'rxjs';
import { ProjectService } from '../project/project.service';

@Injectable({
  providedIn: 'root'
})
export class ConversionEventService {
  clientApiUrl = environment.clientApiUrl;

  constructor(private httpClient: HttpClient, private store: Store, private projectService: ProjectService) {}

  getEventNames() {
    const Url = environment.reportingApiUrl + `/v2/event_names`;
    const ChosenDates = this.store.selectSnapshot(state => state.selected && state.selected.chosenDates);

    if (ChosenDates) {
      let dateParams;
      if (ChosenDates.start && ChosenDates.end) {
        dateParams = {start: ChosenDates.start, end: ChosenDates.end};
      }

      return this.httpClient.get<ConversionEvent[]>(Url, { params: dateParams}).pipe(
        map((response: ConversionEvent[]) => {
          this.store.dispatch(new SetConversionEvents(response));
          return response;
        })
      );
    } else {
      return of(null);
    }
  }

  getUnfilteredEventNames() {
    const Url = environment.reportingApiUrl + `/v2/event_names`;

    return this.httpClient.get<ConversionEvent[]>(Url);
  }

  getRevenueEvents() {
    const Url = environment.reportingApiUrl + `/v2/event_names`;
    const Params = {
      with_revenue_only: 'true'
    }

    return this.httpClient.get<ConversionEvent[]>(Url, { params: Params}).subscribe(x => {
      if (x.length > 0) {
        this.store.dispatch(new SetRevenueEvents(x));
      }
    });
  }
}
