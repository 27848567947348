<div class="modal-header">
  <div class="modal-title">
    <h3 class="mb-0 mt-0">Channel Name</h3>
    <small *ngIf="justAddingChannel; else addingFilters">
      New channel "{{ name }}" will be created {{ parentGroup == null ? 'on top level' : ('under "' + parentGroup?.n + '" channel') }}
    </small>
    <ng-template #addingFilters>
      <small class="extra-small">New filter(s) will be created {{ parentGroup == null ? 'on top level' : ('under "' + parentGroup?.n + '" channel') }}</small><small *ngIf="!stringIsEmpty(name)">, inside {{ name }} channel</small>
    </ng-template>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="errorMessage" class="alert modal_alert alert-warning">
  <i class="fa-solid fa-triangle-exclamation"></i>
  {{ errorMessage }}
</div>
<div class="modal-body modal-body--source">
  <div class="filter_title">
    <label class="caps">Name</label>
    <input class="form-control" type="name" id="name" name="name" [(ngModel)]="name" autofocus placeholder="Channel Name">
  </div>
</div>
<div class="modal-footer">
  <button type='submit' class="btn btn-success" [class.disabled]="stringIsEmpty(name)" (click)="submit()">
    <span *ngIf="!waitingForResponse; else waiting">
      <span class="caps">Submit</span>
      <i class="ms-2 fa-solid fa-check-circle"></i>
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>