import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-view-modal',
  templateUrl: './new-view-modal.component.html',
  styleUrls: ['./new-view-modal.component.scss']
})
export class NewViewModalComponent implements OnInit, OnChanges {
  @Input() view;
  @Input() name;
  isValid = false;
  waitingForResponse = false;
  nameInput: string;
  isPreset = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.name) {
      this.isValid = true;
      this.nameInput = this.name;
    }
    this.isPreset = !this.view.daterange_preset.includes(',');
  }  

  ngOnChanges(changes: SimpleChanges): void {
    if (this.name) {
      this.isValid = true;
      this.nameInput = this.name;
    }
    this.isPreset = !this.view.daterange_preset.includes(',');
  }

  togglePresetOrDate(event) {
    this.isPreset = !this.isPreset;
  }

  submit() {
    if (!this.isValid) return;
    this.activeModal.close({ name: this.nameInput, isPreset: this.isPreset});
  }

  changeNameInput(event) {
    this.nameInput = event;
    this.isValid = this.nameInput.length > 0;
  }

}
