<div class="oos-wrapper">
  <div class="container-fluid">
    <a [routerLink]="'/'" class="btn-cta caps">back to website</a>
  </div>
  <div class="container">
    <div class="oos-title">
      <img src="{{ assetPath }}/assets/images/mark.svg" width="65" alt="Attribution" />
      <h1>Welcome to Attribution</h1>
      <h4>Create an account to get started</h4>
    </div>
    <div class="row">
      <form class='col-sm-6 offset-sm-3' [formGroup]="form" (ngSubmit)="submit()">
        <div class="oos-body modal-body">
          <div class="form-group">
            <label for="inputInviteCode">Invite code</label>
            <input type="text" formControlName="inviteCode" name="inviteCode" class="form-control" id="inputInviteCode" placeholder="XXXXXX">
            <div class="help-inline" *ngIf="form.dirty && form.controls.inviteCode.errors?.required">
              Please fill it out.
            </div>
          </div>
          <div class="form-group spacer-pad">
            <label for="InputEmail">Email address</label>
            <input type="email" formControlName="email" name="email" class="form-control" id="inputEmail" placeholder="you@company.com">
            <div class="help-inline" *ngIf="form.controls.email.errors?.email">
              That doesn't look like an email address...
            </div>
            <div class="help-inline" *ngIf="form.dirty && form.controls.email.errors?.required">
              Please fill it out.
            </div>
            <div class="help-inline" *ngIf="emailTaken">
              Oops, looks like that email is taken
            </div>
          </div>
          <div class="form-group spacer-pad">
            <label for="InputPassword">Password</label>
            <input name="password" type="password" formControlName="password" class="form-control" id="inputPassword" placeholder="Keep it original">
            <div class="help-inline" *ngIf="form.controls.password.errors?.minlength">
                That's too weak, make your password at least 6 characters long
            </div>
            <div class="help-inline" *ngIf="form.dirty && form.controls.password.errors?.required">
              Please fill it out.
            </div>
          </div>
        </div>
        <div class="oos-footer">
          <button type="submit" class="btn btn-lg btn-success oos-button" [disabled]="form.invalid || emailTaken || alreadyClicked">
            <span *ngIf="!waitingForResponse; else waiting">
              Sign up
            </span>
            <ng-template #waiting>
              <i class="fa-solid fa-spinner fa-spin"></i>
            </ng-template>
          </button>
        </div>
      </form>
    </div>
    <div class="oos-notice">
      By signing up I agree to the <a target="_blank" href="{{ termsUrl }}">Terms</a> and <a target="_blank" href="{{ privacyUrl }}">Privacy Policy</a>.
    </div>
  </div>
</div>
