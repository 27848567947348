import { Component, AfterViewInit, Input, OnChanges } from '@angular/core';
import * as d3 from 'd3';

import { Report } from 'src/app/store/interfaces/overview';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements AfterViewInit, OnChanges {
  @Input() tooltipName;
  @Input() chartName;
  @Input() chartHeight;
  @Input() report: Report;
  @Input() color;
  @Input() maxLabels = 10;
  @Input() marginBottom = 40;
  rendered = false;
  baseChartHeight = 150;

  constructor(private tooltipService: TooltipService) { }

  ngOnChanges() {
    if (!this.rendered) { return; }
    return this.drawChart();
  }

  ngAfterViewInit() {
    this.rendered = true;
    return this.drawChart();
  }

  drawChart() {
    // remove prone graph if any
    d3.select(`#${this.chartName}`).select('svg').remove();

    d3.select(`#${this.chartName}`).style('height', this.chartHeight + 'px');

    if (!document.getElementById(this.chartName)) {
      return;
    }

    const wrapperProperties = document.getElementById(this.chartName).getBoundingClientRect();

    const barColor = this.color === 'green' ? '#60bf8d' : this.color === 'red' ? '#bd6d65' : 'grey';

    const margin = {top: 10, right: 0, bottom: this.marginBottom, left: 80};

    const formatCurrency = d3.format('-$,.0f');

    const svg = d3.select(`#${this.chartName}`).append('svg')
                  .attr('width', wrapperProperties.width);

    if (this.chartHeight) {
      d3.select(`#${this.chartName}`).style("height", this.chartHeight + 'px');
    }

    const graphWidth = wrapperProperties.width - margin.left - margin.right;

    svg.attr('height', margin.top + this.baseChartHeight + margin.bottom + 5);

    const height: any = svg.attr('height');

    // reduce axis labels, include last label
    const ratio = Math.round(this.report.list.length / (this.maxLabels - 1));

    const labels = this.report.list.length > this.maxLabels ?
      this.report.list.filter( (_, index) => {
        return index % ratio === 0 || index === this.report.list.length - 1;
      }).map( item => item.label) :
      this.report.list.map( item => item.label);

    const x = d3.scaleBand()
      .domain(this.report.list.map( item => item.label))
      .range([0, graphWidth])
      .paddingInner(0.3)
      .paddingOuter(0.3);

    const y = d3.scaleLinear()
      .domain([d3.max(this.report.list, d => d.amount + 100), 0])
      .rangeRound([0, height - margin.bottom - margin.top]);

    // render left Axis
    const yAxis = svg.append('g')
      .attr('class', 'ticks')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(
        d3.axisLeft(y)
          .tickSize(-(graphWidth - 10))
          .tickPadding(10)
          .tickFormat(d => formatCurrency(d))
          .ticks(5, 's')
      )
      .call( gr => gr.select('.domain').remove())
      .selectAll('line')
        .attr('stroke', '#eeeeee');

    // bottom axis
    const xAxis = svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${margin.left}, ${height - margin.bottom + 5})`)
      .call(
        d3.axisBottom(x)
          .tickSize(0)
          .tickValues(labels)
      )
      .call(gr => gr.select('.domain').remove())
      .selectAll('text')
        .style('text-anchor', 'end')
        .attr('transform', 'rotate(-45)');

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('width', graphWidth)
      .attr('height', height - margin.top - margin.bottom);

    const rects = g.selectAll('rect')
      .data(this.report.list);

    rects.enter()
      .append('rect')
        .attr('y', d => y(d.amount))
        .attr('x', d => x(d.label))
        .attr('rx', '4px')
        .attr('width', x.bandwidth)
        .attr('height', d => height - margin.top - margin.bottom - y(d.amount))
        .attr('fill', barColor)
          .on('mouseover', (e, d) => this.tooltipService.mouseOver.emit({
            name: this.tooltipName,
            data: { data: d }
          }))
          .on('mouseout', () => this.tooltipService.mouseOut.emit());


    function getFillerHeight(h) {
      if ( (y(0) - y(h)) > 3 ) {
        return 3;
      } else if ((y(0) - y(h)) > 2 ) {
        return 2;
      } else if ((y(0) - y(h)) > 1 ) {
        return 1;
      } else {
        return 0;
      }
    }

    rects.enter()
      .append('rect')
      .attr('y', d => height - margin.top - margin.bottom - getFillerHeight(d.amount))
      .attr('x', d => x(d.label))
      .attr('width', x.bandwidth)
      .attr('height', d => getFillerHeight(d.amount))
      .attr('fill', barColor)
        .on('mouseover', (e, d) => this.tooltipService.mouseOver.emit({
          name: this.tooltipName,
          data: { data: d }
        }))
        .on('mouseout', () => this.tooltipService.mouseOut.emit());
  }

}
