<div class="modal-header">
    <h4 class="modal-title">Ad destination urls for "{{ item.n }}"</h4>
  </div>
  <div class="modal-body">
    <app-loader *ngIf="urlsAreLoading; else loaded" [position]="'relative'" [color]="'green'" [backgroundColor]="'white'">
    </app-loader>
    <ng-template #loaded>
      <table class="table break-all" style="margin-bottom: 0">
        <thead>
          <tr>
            <th>Destination url</th>
          </tr>
        </thead>
        <tbody *ngFor="let key of objectKeys(groupedUrls)" (click)="toggleSelected(key)">
          <tr>
            <td class="app_sidebar_tr_link"><i class="fa-solid fa-chevron-right" [ngClass]="{'fa-chevron-down': isSelected(key), 'fa-chevron-right': !isSelected(key)}"></i>
              <strong> {{ groupedUrls[key] | extendedAdUrlsSumOfClicks }} &times;</strong> {{ key }}
            </td>
          </tr>

          <tr *ngFor="let row of (isSelected(key) ? groupedUrls[key] : [])" class="dim-background">
            <td>
              {{ row.name }}
              <i *ngIf="row.ads_manager_link" class="fa-solid fa-arrow-up-right-from-square muted" aria-hidden="true"></i>
              <a *ngIf="row.ads_manager_link" href="{{row.ads_manager_link}}" class='ads-manager-link' target="_blank">
                Ads manager
              </a>
              <span *ngIf="row.url_tags">url_tags: {{ row.url_tags }}</span>
              <span *ngIf="row.status" class="pull-right muted">&nbsp;| {{ row.status }}</span>
              <span *ngIf="row.clicks != null" class="pull-right muted">&nbsp;| clicks: {{ row.clicks }}</span>
              <span class="pull-right muted">id: {{ row.id }}</span>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="isEmpty">
          <tr>
            <td>No direct destination urls found.</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="activeModal.close('Close click')">Close</button>
  </div>
