import { ActivecampaignComponent } from "src/app/modules/settings/integrations/explainers/activecampaign/activecampaign.component";
import { CallrailComponent } from "src/app/modules/settings/integrations/explainers/callrail/callrail.component";
import { FriendbuyComponent } from "src/app/modules/settings/integrations/explainers/friendbuy/friendbuy.component";
import { ImpactComponent } from "src/app/modules/settings/integrations/explainers/impact/impact.component";
import { KlaviyoComponent } from "src/app/modules/settings/integrations/explainers/klaviyo/klaviyo.component";
import { MarketoComponent } from "src/app/modules/settings/integrations/explainers/marketo/marketo.component";
import { OutbrainComponent } from "src/app/modules/settings/integrations/explainers/outbrain/outbrain.component";
import { RedditComponent } from "src/app/modules/settings/integrations/explainers/reddit/reddit.component";
import { SegmentReverseComponent } from "src/app/modules/settings/integrations/explainers/segment_reverse/segment_reverse.component";
import { SegmentReverseEventsComponent } from "src/app/modules/settings/integrations/explainers/segment_reverse_events/segment_reverse_events.component";
import { ShopifyComponent } from "src/app/modules/settings/integrations/explainers/shopify/shopify.component";
import { StackadaptComponent } from "src/app/modules/settings/integrations/explainers/stackadapt/stackadapt.component";
import { environment } from "src/environments/environment";
import IntConfig from './integration-config.json';

import { map } from "lodash-es";

export enum IntegrationTypes {
  connector = 'connector',
  integration = 'integration',
  transformer = 'transformer'
};

const urlEncodeParams = (params, integrationName) => {
  const callbackUrl = `${window.location.origin}/${integrationName == 'salesforce' ? 'callback.html' : 'integration-callback.html' }`;
  params.redirect_uri = callbackUrl;
  // Microsoft doesn't allow parameters in redirect uri
  if (integrationName == 'googleads2') integrationName = 'googleads';
  if (!['bing', 'msads', 'pinterest'].includes(integrationName)) params.redirect_uri += `?integration=${integrationName}`;

  return map(params, function(value, key) {
    let val = value;
    if (val.includes('{Math.random()}')) {
      val = val.replace('{Math.random()}', Math.random().toString());
    } else if (val.includes('{Math.random().toString(36).substring(2)}')) {
      val = val.replace('{Math.random().toString(36).substring(2)}', Math.random().toString(36).substring(2));
    }
    return key + '=' + encodeURIComponent(val);
  }).join('&');
}

export class Integration {
  private static instance: Integration;
  static get get() {
    if (!Integration.instance) {
    Integration.instance = new Integration();
    }
    return Integration.instance;
  }

  public state: any;

  get config() {
    return this.state || this.makeConfig;
  }

  OldIntegrations = ['adwords', 'facebook3', 'googleads', 'bing', 'linkedin'];

  NonDefaultLogos = [
    'adwords', 'googleads', 'googleads2', 'getbase', 'facebook3', 'facebook4', 'facebook_leads', 'bing', 'msads', 'segment_reverse', 'segment_reverse_events', 'linkedin2', 'influence_upload', 'csv_upload', 'zuora', 'twitter'];

  //This dict is to interpret enabled integrations from ADMIN, because it is just 'facebook' and not 'facebook4' there.
  AdminDict = [
    {
      checkArray: ['facebook3', 'facebook4'],
      name: 'facebook',
      newest: 'facebook4'
    },
    {
      checkArray: ['googleads2', 'googleads', 'adwords'],
      name: 'googleads',
      newest: 'googleads2'
    },
    {
      checkArray: ['linkedin2', 'linkedin'],
      name: 'linkedin',
      newest: 'linkedin2'
    },
    {
      checkArray: ['msads', 'bing'],
      name: 'msads',
      newest: 'msads'
    }
  ];

  HiddenOnProd = [];

  IsIntegration = (name) => {
    return this.config[name].type == IntegrationTypes.integration;
  }

  isConnector = (name) => {
    return this.config[name].type == IntegrationTypes.connector;
  };

  isTransformer = (name) => {
    return this.config[name].type == IntegrationTypes.transformer;
  };

  AllConnectedOauths = (project) => [...new Set(project?.oauths?.map(x => x.type))];

  IsIntegrationOpen = (name, project) => {
    let integrationName = name;
    this.AdminDict.forEach( x => {
      if (x.checkArray.includes(name)) integrationName = x.name;
    });

    if (this.IsIntegration(name)) {
      return project.options.integrations.includes('all')
                || project.options.integrations.includes(integrationName);
    } else if (this.isConnector(name)) {
      return (
              project?.options?.connectors?.includes('all')
              || project?.options?.connectors?.includes(integrationName)
             ) ;
    } else if (this.isTransformer(name)) {
      return true;
    }
  };

  get names() {
    let names = {
      [IntegrationTypes.integration]: {},
      [IntegrationTypes.connector]: {},
      [IntegrationTypes.transformer]: {},
    };

    Object.keys(this.config).forEach((integrationKey) => {
      names[this.config[integrationKey].type][integrationKey] = this.config[integrationKey].label;
    });
    return names;
  }

  buildUrl = (integration, additionalParams?) => {
    const defaultParams = urlEncodeParams({...environment.integrations[integration.name], ...integration.config.oauthParams}, integration.name);

    let url;
    switch (integration.config.requestUrlType) {
      case 'default':
        url = `${integration.config.requestUrl}?${defaultParams}`;
        break;
      case 'bigcommerce':
        url = `https://login.bigcommerce.com/app/${environment.integrations.bigcommerce.client_id}/install`;
        break;
      case 'shopify':
        url = `https://${additionalParams.shop}.myshopify.com/admin/oauth/authorize?${defaultParams}`;
        break;
      default:
        break;
    }

    return url;
  };

  addConfig = (integrations) => {
    Object.keys(integrations).forEach(x => {
      integrations[x].config = this.config[x];
    });

    return integrations;
  };

  get readMeComSlugs() {
    let slugs = {};
    Object.keys(this.config).forEach((integrationKey) => {
      if (this.config[integrationKey].docLink) slugs[integrationKey] = this.config[integrationKey].docLink.replace('https://docs.attributionapp.com/docs/','');
    });
    return slugs;
  }

  addComponents = (integrations) => {
    const components = {
      activecampaign: ActivecampaignComponent,
      callrail: CallrailComponent,
      friendbuy: FriendbuyComponent,
      impact: ImpactComponent,
      klaviyo: KlaviyoComponent,
      marketo: MarketoComponent,
      outbrain: OutbrainComponent,
      reddit: RedditComponent,
      segment_reverse: SegmentReverseComponent,
      segment_reverse_events: SegmentReverseEventsComponent,
      shopify: ShopifyComponent,
      stackadapt: StackadaptComponent,
    };
    Object.keys(integrations).forEach(x => {
      if (components[x]) {
        integrations[x].component = components[x];
      }
    });

    return integrations;
  };

  get icons() {
    let icons = {};
    Object.keys(this.config).forEach((integrationKey) => {
      if (this.config[integrationKey].icon) icons[integrationKey] = this.config[integrationKey].icon;
    });
    return icons;
  };

  handles = {
    'Facebook Social': 'facebook4',
    'Facebook Ads': 'facebook4',
    'Facebook': 'facebook4',
    'Twitter Social': 'twitter',
    'Linkedin': 'linkedin',
    'Google': 'googleads',
    'Google Ads': 'googleads',
    'Quora': 'quora',
  };

  CSS_FILTER_COLOR_COUNT = 17;


  get makeConfig () {
    const conf: any = {...IntConfig};

    conf.adwords = conf.googleads2;

    const goThroughKeys = (obj) => {
      if (!obj) return;

      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object') {
          goThroughKeys(obj[key]);
          return;
        }
        if (typeof obj[key] !== 'string') return;

        if (obj[key].includes('{environment')) {
          const envString = obj[key].split('{')[1].split('}')[0];
          obj[key] = obj[key].replace(`{${envString}}`, (x) => {
            const stringArray = envString.split('.');
            stringArray.shift();
            let envVar = environment;
            stringArray.forEach((y) => {
              envVar = envVar[y];
            });
            return typeof envVar != 'string' ?  envVar.toString() : envVar;
          });
        }
      });
    };

    Object.keys(conf).forEach((integration) => {
      goThroughKeys(conf[integration]);
    });

    this.state = conf;

    return conf;
  }
}
