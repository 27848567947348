<div class="modal-header">
  <div class="modal-title">
    <h3 class="mb-0 mt-0">Delete Channels/Filters</h3>
    <small class="extra-small">Are you sure, you want to delete following items?</small>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="errorMessage" class="alert modal_alert alert-warning">
  <i class="fa-solid fa-triangle-exclamation"></i>
  {{ errorMessage }}
</div>
<div class="modal-body modal-body--source">
  <div *ngIf="groupsToDelete.length > 0">
    <p>Channels:</p>
    <ul>
      <li *ngFor="let group of groupsToDelete">
        {{ group.n }}
      </li>
    </ul>
  </div>
  <div *ngIf="filtersToDelete.length > 0">
    <p>Filters:</p>
    <ul>
      <li *ngFor="let filter of filtersToDelete">
        {{ filter.n }}
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button type='submit' class="btn btn-success" (click)="confirm()">
    <span *ngIf="!waitingForResponse; else waiting">
      <span class="caps">Confirm</span>
      <i class="ms-2 fa-solid fa-check-circle"></i>
    </span>
    <ng-template #waiting>
      <i class="fa-solid fa-spinner fa-spin"></i>
    </ng-template>
  </button>
</div>