<div *ngIf="!isWithIdentifier; else main" class='settings-wrapper'>
  <app-settings-shared></app-settings-shared>
  <div class="settings-content">
    <div class="settings__block container-fluid">
      <ng-container [ngTemplateOutlet]="main"></ng-container>
    </div>
  </div>
</div>

<ng-template #main>
  @if (activeTab == 'ads' || !activeTab) {
    <div class="row">
      <div class="col-6">
        <h1 class="d-inline mt-0">
          Ad Integrations
        </h1>
        <a class="ms-3 align-text-bottom" href="https://docs.attributionapp.com/docs/overview-of-ad-integrations" target="_blank"><i class="fa-solid fa-lg fa-circle-question"></i></a>
      </div>
      <div class="col-6">
        <div class="right-side medium-dark">
          Please note — you will not be able to integrate with the ad channels below unless you have admin permission for that ad channel.
        </div>
      </div>
    </div>
    <div class="container-full">
      <div class="row">
        <div *ngIf="openIntegrations.length == 0">
          <div class="alert alert-warning">
            Your current plan doesn't allow adding more integrations.
          </div>
        </div>
        <div *ngFor="let integration of (isProd ? openIntegrations : connectors)" class="col-xl-3 col-lg-6 col-6 integration-wrapper" [class.hide-prod]="(integration.key | includedInArray:hiddenOnProd) && !(openHiddenForProjects[integration.key] && (project?.id | includedInArray:openHiddenForProjects[integration.key]))">
          <div class="card clickable" [class.card-connected]="hasConnected(integration)" (click)="goToShowOrStart(integration)">
            <div class="beta-label" *ngIf="integration.name | integrationIsBeta"></div>
            <div class="image">
              <app-integration-logo [integrationName]="integration.key"></app-integration-logo>
            </div>
            <div class="footer" [class.footer-connected]="hasConnected(integration)" [class.footer-disconnected]="hasDisconnected(integration)">
              <strong class="ms-2" *ngIf="oauthsByType(integration.key).length == 0; else statuses">
                @if (integration | isNotOpenIntegration:openIntegrations) {
                  <i class="fa-solid fa-lock red me-2"></i>
                }
                {{ integration.name }}
              </strong>
              <ng-template #statuses>
                <div class="full-width d-flex justify-content-between">
                  <div class="ms-2 me-1">
                    <strong class="me-2">{{ integration.name }}</strong>
                  </div>
                  <div class="me-2">
                    @if (oauthsByType(integration.key)?.length <= 5) {
                      <span *ngFor="let oauth of oauthsByType(integration.key)">
                        <app-integration-status-icon [oauth]="oauth"></app-integration-status-icon>
                      </span>
                    } @else {
                      @if ((oauthsByType(integration.key) | oauthsByStatus:'active')?.length > 0) {
                        <span ngbTooltip="Active integrations" placement="top" container="body">
                          <i class="fa-lg me-1 align-middle fas green fa-check-circle"></i>&times;<b>{{ (oauthsByType(integration.key) | oauthsByStatus:'active')?.length }}</b>
                        </span>
                      }
                      @if ((oauthsByType(integration.key) | oauthsByStatus:'expired')?.length > 0) {
                        <span ngbTooltip="Expired integrations" placement="top" container="body">
                          <i class="fa-lg ms-3 me-1 align-middle fas orange fa-pause-circle" ></i>&times;<b>{{ (oauthsByType(integration.key) | oauthsByStatus:'active')?.length }}</b>
                        </span>
                      }
                      @if ((oauthsByType(integration.key) | oauthsByStatus:'inactive')?.length > 0) {
                        <span ngbTooltip="Inactive integrations" placement="top" container="body">
                          <i class="fa-lg ms-3 me-1 align-middle fas red fa-times-circle"></i>&times;<b>{{ (oauthsByType(integration.key) | oauthsByStatus:'active')?.length }}</b>
                        </span>
                      }
                    }
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  }


  @if (activeTab == 'conversions' || !activeTab) {
    <div class="row" [class.mt-5]="!activeTab">
      <div class="col-6">
        <h1 class="d-inline">
          Conversion Integrations
        </h1>
        <a class="ms-3 align-text-bottom" href="https://docs.attributionapp.com/docs/a-visit-to-conversion-example" target="_blank"><i class="fa-solid fa-lg fa-circle-question"></i></a>
      </div>
      <div class="col-6">
        <div class="right-side medium-dark">
          Please note — you will not be able to integrate with the conversion source below unless you have admin permission for that.
        </div>
      </div>
    </div>
    <div class="container-full">
      <div class="row">
        <div *ngIf="openConnectors.length == 0">
          <div class="alert alert-warning">
            Your current plan doesn't allow adding more integrations.
          </div>
        </div>
        <div *ngFor="let integration of (isProd ? openConnectors : connectors)" class="col-xl-3 col-lg-6 col-6 integration-wrapper"  [class.hide-prod]="(integration.key | includedInArray:hiddenOnProd) && !(openHiddenForProjects[integration.key] && (project?.id | includedInArray:openHiddenForProjects[integration.key]))">
          <div class="card clickable" [class.card-connected]="hasConnected(integration)" (click)="goToShowOrStart(integration)">
            <div class="beta-label" *ngIf="integration.name | integrationIsBeta"></div>
            <div class="image">
              <app-integration-logo [integrationName]="integration.key"></app-integration-logo>
            </div>
            <div class="footer" [class.footer-connected]="hasConnected(integration)" [class.footer-disconnected]="hasDisconnected(integration)">
              <strong class="ms-2" *ngIf="oauthsByType(integration.key).length == 0; else statuses">
                @if (integration | isNotOpenIntegration:openConnectors) {
                  <i class="fa-solid fa-lock red me-2"></i>
                }
                {{ integration.name }}
              </strong>
              <ng-template #statuses>
                <div class="full-width d-flex justify-content-between">
                  <div class="ms-2 me-1">
                    <strong class="me-2">{{ integration.name }}</strong>
                  </div>
                  <div>
                    <span *ngFor="let oauth of oauthsByType(integration.key)">
                      <app-integration-status-icon [oauth]="oauth"></app-integration-status-icon>
                    </span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (activeTab == 'transformers' || !activeTab) {
    <div class="row" [class.mt-5]="!activeTab">
      <div class="col-6">
        <h1 class="d-inline">
          Transformers
        </h1>
        <a class="ms-3 align-text-bottom" href="https://docs.attributionapp.com/docs/" target="_blank"><i class="fa-solid fa-lg fa-circle-question"></i></a>
      </div>
      <div class="col-6">
        <div class="right-side medium-dark">
          Please note — you will not be able to enable transformer source below unless you have admin permission for that.
        </div>
      </div>
    </div>
    <div class="container-full">
      <div class="row">
        <div *ngFor="let integration of openTransformers" class="col-xl-3 col-lg-6 col-6 integration-wrapper"  [class.hide-prod]="(integration.key | includedInArray:hiddenOnProd) && !(openHiddenForProjects[integration.key] && (project?.id | includedInArray:openHiddenForProjects[integration.key]))">
          <div class="card clickable" [class.card-connected]="hasConnected(integration)" (click)="goToShowOrStart(integration)">
            <div class="beta-label" *ngIf="integration.name | integrationIsBeta"></div>
            <div class="image">
              <app-integration-logo [integrationName]="integration.key" [isTransformer]="true"></app-integration-logo>
            </div>
            <div class="footer" [class.footer-connected]="hasConnected(integration)" [class.footer-disconnected]="hasDisconnected(integration)">
              <strong class="ms-2" *ngIf="oauthsByType(integration.key).length == 0; else statuses">{{ integration.name }}</strong>
              <ng-template #statuses>
                <div class="full-width d-flex justify-content-between">
                  <div class="ms-2 me-1">
                    <strong class="me-2">{{ integration.name }}</strong>
                  </div>
                  <div>
                    <span *ngFor="let oauth of oauthsByType(integration.key)">
                      <app-integration-status-icon [oauth]="oauth"></app-integration-status-icon>
                    </span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</ng-template>
