import { Component, OnChanges, Input } from '@angular/core';
import { last, omit, pick, pickBy, identity, concat, take } from 'lodash-es';

import { VisitorService } from 'src/app/core/services/visitor/visitor.service';
import { SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { Project } from 'src/app/core/models/project.model';
import { Router, ActivatedRoute } from '@angular/router';
import { LocationService } from 'src/app/core/services/location/location.service';
import { NameForUserPipe } from 'src/app/core/pipes/name-for-user.pipe';
import { Store } from '@ngxs/store';
import { SidebarSetName } from 'src/app/store/actions/only-simple.actions';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/core/services/base/base.service';

@Component({
  selector: 'app-visitor-history',
  templateUrl: './visitor-history.component.html',
  styleUrls: ['./visitor-history.component.scss']
})
export class VisitorHistoryComponent implements OnChanges {
  @Input() sidebar: SidebarStateModel;
  @Input() project: Project;
  nameForUserPipe: NameForUserPipe = new NameForUserPipe();

  isProd = environment.isProd;

  visitorId: number;
  visitor: any;
  visitorName: string;
  traits: any;
  summary: any;
  infoInProgress;
  inProgress;
  data = [];
  isMore = true; // needs to be more then 0 at the start
  canScroll = true;
  queryParams = {
    order: 'time DESC',
    since: null
  };
  maxTraits = 8;
  showAllTraits = false;
  isCompanyBasedAttributionEnabled = false;

  objectKeys = Object.keys;

  constructor(
    private store: Store,
    private visitorService: VisitorService,
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private baseService: BaseService
  ) { }

  ngOnChanges() { 
    this.isCompanyBasedAttributionEnabled = this.project?.options?.features?.company_based_attribution;
    this.inProgress = true;
    this.visitor = null;
    this.data = [];
    this.infoInProgress = true;
    this.visitorId = parseInt(this.sidebar.options.id);
    this.visitorService.get(this.visitorId).subscribe(resp => {
      this.visitor = resp;
      this.traits = omit( this.visitor.traits, ['id', 'email', 'name', 'ip']);
      if (this.visitor.ip) {
        this.locationService.get(this.visitor.ip).subscribe( resp => {
          this.visitor.location = resp;
        });
      }
      this.visitorName = this.nameForUserPipe.transform(this.visitor);
      this.store.dispatch(new SidebarSetName({name: this.visitorName}));
      this.infoInProgress = false;
    });

    this.inProgress = true;
    this.requestAnew();
  }

  onScroll() {
    if (!this.isMore || this.inProgress) { return; }
    this.inProgress = true;
    this.requestAnew();
  }

  showCompany(companyId) {
    this.baseService.showCompany(companyId);
  }

  isTime(key) {
    return ['createdAt', 'createdat', 'created_at'].includes(key);
  }

  requestAnew() {
    this.visitorService.getHistory(this.visitorId, pickBy(this.queryParams, identity)).subscribe(
      (resp: { data: Array<any>; more: boolean; summary: any; }) => {
        this.data = concat(this.data, resp.data);
        this.summary = resp.summary;
        this.isMore = resp.more || false;
        if (resp.data.length > 0 ) {
          this.queryParams.since = last(resp.data).time;
        }
        this.inProgress = false;
        if (this.isMore) {
          this.canScroll = true;
        } else {
          this.canScroll = false;
        }
      }
    );
  }

  moveToVisitor(id) {
    this.baseService.showVisitor(id);
  }

  orderChanged(event) {
    this.queryParams.order = `time ${event.order}`;
    this.data = [];
    this.queryParams.since = null;
    this.requestAnew();
  }

  limitedTraits(){
    if (!this.traits) { return null }
    if(this.showAllTraits) { return this.traits }

    const sortedKeys = Object.keys(this.traits).sort();
    const limitedKeys = take(sortedKeys, this.maxTraits);

    return pick(this.traits, limitedKeys);
  }

  isTooManyTraits() {
    if (!this.traits) { return false }

    return Object.keys(this.traits).length > this.maxTraits;
  }
}
