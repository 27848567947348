import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

import { IntegrationAuthService } from 'src/app/core/services/integrations/integration-auth.service';
import { Integration } from 'src/app/core/constants/integration';

@Component({
  selector: 'app-callrail',
  templateUrl: './callrail.component.html',
  styleUrls: ['./callrail.component.scss']
})
export class CallrailComponent implements OnInit {
  @Input() integration;
  @Input() step = 0;
  @Input() type = 'explainer';

  error = null;
  waitingForResponse = false;

  connectForm = this.fb.group({
    apiKey: ['', [Validators.required]]
  });

  learnMoreLink;

  constructor(private service: IntegrationAuthService, public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private router: Router) {

  }

  ngOnInit() {
    this.learnMoreLink = Integration.get.config[this.integration.name].docLink;
  }

  connectLogin() {
    if (this.connectForm.invalid) return;
    const apiKey = this.connectForm.get('apiKey').value;

    this.waitingForResponse = true;
    this.service.connect(this.integration, { api_key: apiKey}).subscribe( x => {
      this.waitingForResponse = false;
      this.activeModal.close();
      this.router.navigate([],  { queryParams: {
        integration: this.integration.name
      }})
    }, e => {
      this.waitingForResponse = false;
      this.error = e.error?.message;
    });
  }

  continue() {
    this.activeModal.close();
    this.router.navigate([ComponentNames.settings, SettingsSections.integrations], {
      queryParams: {
        integration: this.integration.name,
        step: this.step+1,
        continueType: 'auth'
      }
    });
  }

  close() {
    this.activeModal.close('close');
  }

}
