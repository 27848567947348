import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reddit',
  templateUrl: './reddit.component.html',
  styleUrls: ['./reddit.component.scss']
})
export class RedditComponent {
  @Input() integration;
  @Input() step = 0;
  @Input() type = 'explainer';

  error = null;
  waitingForResponse = false;

  connectForm = this.fb.group({
    secret_key: ['', [Validators.required]],
    client_id: ['', [Validators.required]],
  });
  learnMoreLink: any;

  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private router: Router) {}

  connectLogin() {
    if (this.connectForm.invalid) return;
    const client_id = this.connectForm.get('client_id').value;
    const secret_key = this.connectForm.get('secret_key').value;

    this.waitingForResponse = true;
    const callbackUrl = `&redirect_uri=${window.location.origin}/integration-callback.html?integration=${this.integration.name}`;
    const client_param = `&client_id=${client_id}`
    const redirect_url = this.integration.config.additionalUrls.connect.url + client_param + callbackUrl;
    
    sessionStorage.setItem('reddit_secret_key', secret_key)
    sessionStorage.setItem('reddit_client_id', client_id)
    
    window.location.href = redirect_url
  }

  close() {
    this.activeModal.close('close');
  }
}
