import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { ProjectApiTokenService } from 'src/app/core/services/project-api-token/project-api-token.service';
import { CsvConfirmationComponent } from 'src/app/modules/shared/components/csv-confirmation/csv-confirmation.component';
import { SetSettingSection } from 'src/app/store/actions/selected.actions';
import { environment } from 'src/environments/environment';
import { combineLatest, Observable } from 'rxjs';
import { Account } from 'src/app/core/models/account.model';
import { ProjectStateModel } from 'src/app/store/states/project.state';

@Component({
  selector: 'app-gdpr-rules',
  templateUrl: './gdpr-rules.component.html',
  styleUrls: ['./gdpr-rules.component.scss']
})
export class GdprRulesComponent implements OnInit {
  @Select(state => state.account) account$: Observable<Account>;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  error;
  projectApiToken: String;
  uploadConfirmed = false;
  regulation_id: String;
  environment = environment;
  iAmProjectOwner = false;
  projectOwnerEmail: string;

  constructor(private modalService: NgbModal, private apiTokenService: ProjectApiTokenService, private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new SetSettingSection(SettingsSections.gdprRules));
    combineLatest([this.project$, this.account$]).subscribe(([project, account]) => {
      this.iAmProjectOwner = project.project.owner.id === account.id;
      this.projectOwnerEmail = project.project.owner.email;
    });
    this.apiTokenService.get().subscribe( (x: any) => this.projectApiToken = x.api_token);

    // Uncomment this to debug "File is uploaded" state
    // this.uploadConfirmed = true;
    // this.regulation_id = '1111-2222-3333-4444';
  }

  onUpload(files: FileList) {
    if (this.isValidCSVFile(files[0])) {
      let reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        const records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);
        const modalRef = this.modalService.open(CsvConfirmationComponent, {windowClass: 'slideInDown'});
        modalRef.componentInstance.resp = records;
        modalRef.componentInstance.type = 'regulations';
        modalRef.componentInstance.apiToken = this.projectApiToken;
        modalRef.result.then(result => {
          if (result.result == 'confirmed_user_ids') {
            this.uploadConfirmed = true;
            this.regulation_id = result.regulation_id;
          }
          const csvElem = document.getElementById('csvFile') as HTMLInputElement;
          csvElem.value = '';
        }, () => {
          const csvElem = document.getElementById('csvFile') as HTMLInputElement;
          csvElem.value = '';
        });
      };
      reader.onerror = () => {
        this.error = 'error is occured while reading file!';
      };
    } else {
      this.error = 'You can upload only .CSV files'
    }
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    let csvArr = [];

    for (let i = 0; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split("\n");
      csvArr.push(curruntRecord[0].trim());
    }
    return csvArr;
  }

}
