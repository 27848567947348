import { Component, OnChanges, Input, OnDestroy } from '@angular/core';
import { SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { Select, Store } from '@ngxs/store';
import { toNumber, last } from 'lodash-es';
import { RevenueService } from 'src/app/core/services/revenue/revenue.service';
import { Project } from 'src/app/core/models/project.model';
import { LocationService } from 'src/app/core/services/location/location.service';
import { SidebarStateService } from 'src/app/core/services/sidebar-state/sidebar-state.service';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { BaseService } from 'src/app/core/services/base/base.service';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { Observable, Subscription } from 'rxjs';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';

@Component({
  selector: 'app-revenues-sidebar',
  templateUrl: './revenues-sidebar.component.html',
  styleUrls: ['./revenues-sidebar.component.scss']
})
export class RevenuesSidebarComponent implements OnChanges, OnDestroy {
  @Input() sidebar: SidebarStateModel;
  @Input() project: Project;
  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;

  componentNames = ComponentNames;

  selector;
  inProgress;
  revenues = [];
  isMore = false;
  isEmpty;
  filter;
  selected = {
    filterType: null,
    filterOrGroupId: null
  };
  filterTimeline = [];
  revenueEventsBreakdown = [];
  channelBuilderQueryParams;
  debugHovered = false;
  isVirtual = false;
  IPLocations = {};
  filterTree;
  treeSub: Subscription;

  constructor(
    private revenueService: RevenueService,
    private locationService: LocationService,
    private sidebarState: SidebarStateService,
    private baseService: BaseService,
    private FTWS: FilterTreeWorkerService
  ) { }

  ngAfterViewInit() {
    document.getElementById('sidebar-content').scrollTop = this.sidebarState.scrollPosition;
  }

  ngOnChanges() {
    if (this.filterTree && this.project) {
      this.initFlow();
    } else {
      this.treeSub?.unsubscribe();
      this.treeSub = this.filterTree$.subscribe(filterTree => {
        if (!this.filterTree && filterTree.filterTree && this.project) {
          this.filterTree = filterTree.filterTree;
          this.initFlow();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.treeSub?.unsubscribe();
  }

  initFlow() {
    const oldState = this.sidebarState.sidebarState;
    const oldStateKeyArray = Object.keys(oldState);
    if (this.sidebarState.wentBack == true && oldStateKeyArray.length > 0) {
      for (let key of oldStateKeyArray) {
        this[key] = oldState[key];
      }
      this.sidebarState.setWentBack(false);
    } else {
      this.inProgress = true;
      this.revenues = [];
      this.isVirtual = false;
      if (this.sidebar.options.filter || this.sidebar.options.virtual_filter) {
        if (this.sidebar.options.filter === 'raw') {
          this.selected.filterType = 'raw';
          this.filter = {n: 'raw'};
        } else {
          if (this.sidebar.options.virtual_filter) {
            this.isVirtual = true;
          }
          this.selected.filterType = 'f';
          this.selected.filterOrGroupId = toNumber(this.sidebar.options.filter || this.sidebar.options.virtual_filter);
          this.channelBuilderQueryParams = { filter: this.selected.filterOrGroupId};
        }
      } else if (this.sidebar.options.group) {
        this.selected.filterType = 'g';
        this.selected.filterOrGroupId = toNumber(this.sidebar.options.group);
        this.channelBuilderQueryParams = { filter_group: this.selected.filterOrGroupId};
      } else {
        this.selected.filterType = null;
        this.selected.filterOrGroupId = null;
      }
      this.selector = document.getElementById('sidebar-content');
      if (this.selected.filterOrGroupId) {
        this.filter = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: this.selected.filterOrGroupId, additionalKey: 't', additionalValue: this.selected.filterType});
      } else {
        this.filter = null;
      }

      this.revenueService.get({ filterOrGroupId: this.selected.filterOrGroupId, type: this.isVirtual ? 'v' : this.selected.filterType, periodStart: this.sidebar.options.periodStart, periodEnd: this.sidebar.options.periodEnd}).subscribe(
        (resp: {more: boolean; data: Array<any>; timeline: Array<any>; revenue_events_breakdown: Array<any>;}) => {
          this.revenues = resp.data;
          if (resp.timeline) {
            this.filterTimeline = resp.timeline;
          } else {
            this.filterTimeline = [];
          }
          this.revenueEventsBreakdown = resp.revenue_events_breakdown || [];
          this.isMore = resp.more;
          this.isEmpty = this.revenues.length === 0;
          this.inProgress = false;
          this.locationService.requestBulk(resp.data, 50).subscribe( (resp: any) => {
            this.IPLocations = {
              ...this.IPLocations,
              ...resp
            };
            this.sidebarState.setState({
              openedSidebar: this.sidebar.openedSidebar,
              selector: this.selector,
              revenues: this.revenues,
              isMore: this.isMore,
              isEmpty: this.isEmpty,
              inProgress: this.inProgress,
              filter: this.filter,
              selected: this.selected,
              IPLocations: this.IPLocations
            });
          });
        }
      );
    }
  }

  onScroll() {
    if (!this.isMore || this.inProgress) { return; }
    this.inProgress = true;
    this.revenueService.get({filterOrGroupId: this.selected.filterOrGroupId, type: this.isVirtual ? 'v' : this.selected.filterType, since: last(this.revenues).event_time, periodStart: this.sidebar.options.periodStart, periodEnd: this.sidebar.options.periodEnd}).subscribe(
      (resp: {more: boolean; data: Array<any>; }) => {
        this.revenues = this.revenues.concat(resp.data);
        this.inProgress = false;
        this.isMore = resp.more;
        this.locationService.requestBulk(resp.data, 50).subscribe( (resp: any) => {
          this.IPLocations = {
            ...this.IPLocations,
            ...resp
          };
          this.sidebarState.setState({
            openedSidebar: this.sidebar.openedSidebar,
            selector: this.selector,
            revenues: this.revenues,
            isMore: this.isMore,
            isEmpty: this.isEmpty,
            inProgress: this.inProgress,
            filter: this.filter,
            selected: this.selected,
            IPLocations: this.IPLocations
          });
        });
      }
    );
  }

  showVisitor(visitorId) {
    this.baseService.showVisitor(visitorId);
  }

  showCompany(companyId) {
    this.baseService.showCompany(companyId);
  }
}
