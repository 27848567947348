import { State, Action, StateContext } from '@ngxs/store';

import { ClearTPState, PatchTimePeriods, SetTimePeriods, SetTPInProgress, SetTPReportToNull } from '../actions/time-periods.actions';
import { Injectable } from '@angular/core';

export interface TimePeriodsStateModel {
  shouldShow: boolean;
  inProgress: boolean;
  meta: any;
  report: any;
}

@State<TimePeriodsStateModel>({
  name: 'timePeriods',
  defaults: {
    shouldShow: false,
    inProgress: false,
    meta: null,
    report: null
  }
})
@Injectable()
export class TimePeriodsState {
  constructor() {}

  @Action(SetTimePeriods)
  SetTimePeriods(ctx: StateContext<TimePeriodsStateModel>, { payload }: SetTimePeriods) {
    const getState = ctx.getState();
    ctx.setState({...getState, ...payload, inProgress: false});
  }

  @Action(PatchTimePeriods)
  PatchTimePeriods(ctx: StateContext<TimePeriodsStateModel>, { payload }: PatchTimePeriods) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: {
        ...getState.report,
        f: {
          ...getState.report.f,
          ...payload.report.filters
        },
        g: {
          ...getState.report.g,
          ...payload.report.groups
        },
      }
    });
  }

  @Action(SetTPInProgress)
  SetTPInProgress(ctx: StateContext<TimePeriodsStateModel>, {payload}: SetTPInProgress) {
    const getState = ctx.getState();
    ctx.setState({...getState, inProgress: payload?.isFalse ? false : true});
  }

  @Action(SetTPReportToNull)
  SetTPReportToNull(ctx: StateContext<TimePeriodsStateModel>) {
    const getState = ctx.getState();
    ctx.setState({...getState, report: null});
  }

  @Action(ClearTPState)
  ClearTPState(ctx: StateContext<TimePeriodsStateModel>) {
    ctx.setState({
      shouldShow: false,
      inProgress: false,
      meta: null,
      report: null
    });
  }

}
