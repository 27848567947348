<div class="ft-purchase-wrapper">
  <div class="container-fluid" *ngIf="report?.s">
    <div class="summary-wrapper row pt-2">
      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value funds-green">
            <span [countUp]="report?.s?.first_time?.ro/100" [options]="{decimalPlaces: 2, duration: 0.4, prefix: project?.currency_symbol ? project?.currency_symbol : '$'}">{{ report?.s?.first_time?.ro | projectCurrency }}</span>
          </div>
          <p class="title">First Time Purchasers Return on Investment</p>
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value funds-green">
            <span [countUp]="report?.s?.first_time?.r/100" [options]="{decimalPlaces: 2, duration: 0.4, prefix: project?.currency_symbol ? project?.currency_symbol : '$'}">{{ report?.s?.first_time?.r | projectCurrency }}</span>
          </div>
          <p class="title">First Time Purchasers Attributed Revenue</p>
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value orange">
            <span [countUp]="report?.s?.first_time?.fc" [options]="{duration: 0.4}">{{ report?.s?.first_time?.fc }}</span>
          </div>
          <p class="title">First Time Purchasers Conversions</p>
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value funds-green">
            <span [countUp]="report?.s?.first_time?.revenue_per_sale/100" [options]="{decimalPlaces: 2, duration: 0.4, prefix: project?.currency_symbol ? project?.currency_symbol : '$'}">{{ report?.s?.first_time?.revenue_per_sale | projectCurrency }}</span>
          </div>
          <p class="title">First Time Purchasers Revenue Per Sale</p>
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value funds-green">
            <span class="red" [countUp]="report?.s?.amount/100" [options]="{decimalPlaces: 2, duration: 0.4, prefix: project?.currency_symbol ? project?.currency_symbol : '$'}">{{ report?.s?.amount | projectCurrency }}</span>
          </div>
          <p class="title">Advertising Spend</p>
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value blue">
            <span [countUp]="report?.s?.repeated?.r/100" [options]="{decimalPlaces: 2, duration: 0.4, prefix: project?.currency_symbol ? project?.currency_symbol : '$'}">{{ report?.s?.repeated?.r | projectCurrency }}</span>
          </div>
          <p class="title">Repeat Purchasers Attributed Revenue</p>
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value yellow">
            <span [countUp]="report?.s?.repeated?.c" [options]="{duration: 0.4}">{{ report?.s?.repeated?.c }}</span>
          </div>
          <p class="title">Repeat Purchasers Conversions</p>
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div class="summary-item">
          <div class="summary-item-value blue">
            <span [countUp]="report?.s?.repeated?.revenue_per_sale/100" [options]="{decimalPlaces: 2, duration: 0.4, prefix: project?.currency_symbol ? project?.currency_symbol : '$'}">{{ report?.s?.repeated?.revenue_per_sale | projectCurrency }}</span>
          </div>
          <p class="title">Repeat Purchasers Revenue Per Sale</p>
        </div>
      </div>

    </div>
  </div>

  <div class="area-title ms-3 mt-0 pt-3">
    First Time & Repeat Customers Summary
  </div>
  <div class="ftp-chart-wrapper">
    <div class='chart-filters'>
      <div class='ml-4 mb-1'>
        <div class="inline-block">
          <div class='switch-wrapper'>
            <small class="controllbar__hint top text-nowrap">Metric</small>
            <div class="dropdown" ngbDropdown>
              <button class="btn btn-default dropdown-toggle btn-select" type="button" #metricDropdown id="dropdownMenuButton" [attr.data-boundary]="metricDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                <i class="fa-solid fa-calculator fa--header"></i>
                {{ chartMetric }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
                <span class="dropdown-item" (click)="chartMetric = 'Revenue'">Revenue</span>
                <span class="dropdown-item" (click)="chartMetric = 'Conversions'">Conversions</span>
              </div>
            </div>
          </div>
        </div>
        <span class='medium'> : </span>
        <div class="inline-block">
          <div class='switch-wrapper'>
            <small class="controllbar__hint top text-nowrap">Sources</small>
            <div class="switch-wrapper"></div>
            <ng-container *ngIf="chartMetric == 'Revenue'; else conversionButtons">
              <button class="btn btn-default btn-select" type="button" (click)="toggleLine('marketing_spend')">
                <i class="fa-solid fa-money-bill red" [class.medium]="lineIsDisabled('marketing_spend')"></i>
                Marketing Spend
              </button>
              <button class="btn btn-default btn-select ms-1" type="button" (click)="toggleLine('first_time_customers')">
                <i class="fa-solid fa-money-bill green" [class.medium]="lineIsDisabled('first_time_customers')"></i>
                First Time Customers
              </button>
              <button class="btn btn-default btn-select ms-1" type="button" (click)="toggleLine('repeated_customers')">
                <i class="fa-solid fa-money-bill blue" [class.medium]="lineIsDisabled('repeated_customers')"></i>
                Repeat Customers
              </button>
            </ng-container>
            <ng-template #conversionButtons>
              <button class="btn btn-default btn-select ms-1" type="button" (click)="toggleLine('first_time_conversions')">
                <i class="fa-solid fa-money-bill orange" [class.medium]="lineIsDisabled('first_time_conversions')"></i>
                First Time Conversions
              </button>
              <button class="btn btn-default btn-select" type="button" (click)="toggleLine('repeated_conversions')">
                <i class="fa-solid fa-money-bill yellow" [class.medium]="lineIsDisabled('repeated_conversions')"></i>
                Repeated Conversions
              </button>
            </ng-template>
            <button class="btn btn-default btn-select ms-1 hide-prod" type="button" [class.active]="paidOnly" (click)="togglePaidOnly()">
              <i class="fa-solid fa-dollar-sign blue"></i>
              Paid Only Sources
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-loader *ngIf="reportIsLoading; else chartLoaded"  [position]="'relative'" [color]="'green'" [backgroundColor]="'#f9faf9'" class="m-5">
    </app-loader>
    <ng-template #chartLoaded>
      <app-multi-line-chart
        [chartName]="'rev-spend-comparison'"
        [chartHeight]="350"
        [disabledLines]="disabledLines"
        [data]="reportForChart"
        [chartMetric]="chartMetric"
      ></app-multi-line-chart>
    </ng-template>
  </div>
</div>