<span class="controllbar__hint bottom" *ngIf="project != null && view != null">
  <a (click)="openSettingsModal()"><i class="fa-solid fa-gear"></i></a>
  <a [ngSwitch]="view?.traffic_option" (click)="openSettingsModal('conversion-and-revenue')">
    <span class="medium">&nbsp;:&nbsp;</span>
    <small *ngSwitchCase="'include_all'">All Traffic Included</small>
    <small *ngSwitchCase="'exclude_direct_cutoff'">Direct Traffic included until "{{ view.cutoff_event}}" event</small>
    <small *ngSwitchCase="'exclude_direct'">All Direct Traffic excluded</small>
    <small *ngSwitchCase="'exclude_all_cutoff'">All Traffic excluded after "{{ view.cutoff_event}}" event</small>
  </a>
  <span *ngIf="project?.settings?.exclude_filters_enabled">
    <span class="medium">&nbsp;:&nbsp;</span>
    <a (click)="openSettingsModal('exclude-filters')">
      <small>Exclude Filters</small>
    </a>
  </span>
  <span *ngIf="view?.disregard_existing_users != null">
    <span class="medium">&nbsp;:&nbsp;</span>
    <a (click)="openSettingsModal('disregard-existing-users')">
      <small>Disregard Existing Users</small>
    </a>
  </span>
  <span *ngIf="project?.include_only_users_registered_in_daterange">
    <span class="medium">&nbsp;:&nbsp;</span>
    <a (click)="openSettingsModal('only-users-in-daterange')">
      <small>Include Only Users Registered In Daterange</small>
    </a>
  </span>
  <span *ngIf="project?.company_based_attribution">
    <span class="medium">&nbsp;:&nbsp;</span>
    <a (click)="openSettingsModal('company-based-attribution')">
      <small>Company Based Attribution</small>
    </a>
  </span>
  <span *ngIf="firstPurchaseOnly">
    <span class="medium">&nbsp;:&nbsp;</span>
    <a (click)="openSettingsModal('first-purchase-only')">
      <small>First Purchase Only</small>
    </a>
  </span>
  <span *ngIf="revenueGroup">
    <span class="medium">&nbsp;:&nbsp;</span>
    <a (click)="openSettingsModal('revenue-groups')">
      <small>Revenue Group</small>
    </a>
  </span>
  <span *ngIf="shouldShowLookbackOption">
    <span class="medium">&nbsp;:&nbsp;</span>
    {{ view?.attribution_type == 'forward' ? 'Marketer Spend View' : view?.attribution_type == 'backward' ? view?.lookback_window != null ? 'Lookback Window ('+view?.lookback_window+' days)' : 'Lookback Window' : 'Undefined' }}
  </span>
</span>
