import { Component, OnInit } from '@angular/core';
import { EventsUploadService } from 'src/app/core/services/events-upload/events-upload.service';
import { DateTime } from 'luxon';
import { FormArray, FormBuilder } from '@angular/forms';

type JsonItem = {
  n: string;
  c?: JsonItem[];
  default?: boolean;
}

@Component({
  selector: 'app-events-uploader',
  templateUrl: './events-uploader.component.html',
  styleUrls: ['./events-uploader.component.scss']
})
export class EventsUploaderComponent implements OnInit {
  selectedType = 'track';
  selectedIdType = 'user_id';
  csvArray: any = [];

  isTimestamp = true;
  properties = [
    { n: 'revenue'}
  ];
  traits = [
    { n: 'email'},
    { n: 'name'}
  ];

  nameChecked = false;
  trackByEmailChecked = false;
  messageIdChecked = false;

  validateError;
  validateErrorLine;
  validateErrorLineNumber;

  uploadError;
  errorLine;
  errorLineNumber;

  nameInput = 'Attribution Test Event';
  userIdInput = 'test_123';
  timestampInput = DateTime.now().setZone('UTC').set({millisecond: 0}).toISO({suppressMilliseconds: true});
  dateInput = DateTime.now().toISODate();
  timeInput = DateTime.now().toFormat('HH:mm:ss');
  timezoneInput = 'America/New_York';
  emailInput = 'example@example.com';
  messageIdInput = '00000000000000000000000000000000';

  propertiesForm: FormArray;
  traitsForm: FormArray;

  selectedFile: File;
  validateActive: boolean = false;
  afterValidateRecordsCounts: number;
  processActive: boolean = false;
  fileProcessed: boolean = false;

  validateInProgress: boolean = false;
  processingInProgress: boolean = false;

  constructor(
    private eventsUploadService: EventsUploadService,
    private fb: FormBuilder,
    ) {}

  ngOnInit(): void {
    this.propertiesForm = this.fb.array(['10.99']);
    this.traitsForm = this.fb.array(['example@example.com', 'test_name']);
  }

  buildCSVHeaders(jsArray: JsonItem[], parentName?: string) {
    const headers = [];
    jsArray.forEach(item => {
      headers.push(parentName ? `${parentName}.${item.n}` : item.n);
      if (item.c) {
        headers.push(...this.buildCSVHeaders(item.c, item.n));
      }
    });
    return headers;
  }

  selectType(type: string) {
    this.selectedType = type;
    this.selectedIdType = 'user_id';
    this.nameInput = 'Attribution Test Event';
    this.userIdInput = 'test_123';
    this.timestampInput = DateTime.now().setZone('UTC').set({millisecond: 0}).toISO({suppressMilliseconds: true});
    this.dateInput = DateTime.now().toISODate();
    this.timeInput = DateTime.now().toFormat('HH:mm:ss');
    this.timezoneInput = 'America/New_York';
    this.emailInput = 'example@example.com';
    this.trackByEmailChecked = false;
    this.nameChecked = false;
    this.messageIdChecked = false;
    this.messageIdInput = '00000000000000000000000000000000';

    if (this.selectedType === 'page') {
      this.properties = [
        { n: 'url'},
        { n: 'referrer'},
      ];
      this.propertiesForm = this.fb.array(['https://www.example.com', 'https://www.example.com/referrer']);
    } else {
      this.properties = [
        { n: 'revenue'}
      ];
      this.propertiesForm = this.fb.array(['10.99']);
    }
  }

  selectIdType(type: string) {
    if (type === 'user_id') {
      this.userIdInput = 'test_123';
    } else {
      this.userIdInput = '00000000-0000-0000-0000-000000000000';
    }
    
    this.nameChecked = false;
    this.trackByEmailChecked = false;

    this.selectedIdType = type;
  }

  addField() {
    this.csvArray = [...this.csvArray, { n: '' }];
  }

  addProperty() {
    this.propertiesForm.push(this.fb.control([`value${this.properties.length+1}`]));
    this.properties = [...this.properties, { n: `property${this.properties.length+1}` }];
  }

  addTrait() {
    this.traitsForm.push(this.fb.control([`value${this.traits.length+1}`]));
    this.traits = [...this.traits, { n: `trait${this.traits.length+1}` }];
  }

  removeChild(index, array, type) {
    array.splice(index, 1);
    if (type === 'properties') this.propertiesForm.removeAt(index);
    if (type === 'traits') this.traitsForm.removeAt(index);
  }

  downloadCSVHeaders() {
    const addInputs = (headers): string => {
      const dict = [
        {n: this.selectedType === 'page' ? 'name' : 'event', value: this.nameInput},
        {n: 'user_id', value: this.userIdInput},
        {n: 'anonymous_id', value: this.userIdInput},
        {n: 'timestamp', value: this.timestampInput},
        {n: 'type', value: this.selectedType},
        {n: 'date', value: this.dateInput},
        {n: 'time', value: this.timeInput},
        {n: 'timezone', value: this.timezoneInput},
        {n: 'context.atb_track_by_email', value: this.emailInput},
        {n: 'message_id', value: this.messageIdInput}
      ]

      let singleHeaders = headers.filter(h => !((h.n).includes('properties.') || (h.n).includes('traits.')));
      singleHeaders = singleHeaders.map((h) => ({
        ...h,
        value: dict.find(d => d.n === h.n).value,
      }));

      let propertiesHeaders = headers.filter((h) => (h.n).includes('properties.'));
      propertiesHeaders = propertiesHeaders.map((h,i) => ({
        ...h,
        value: this.propertiesForm.controls[i].value,
      }));
      let traitHeaders = headers.filter(h => (h.n).includes('traits.'));
      traitHeaders = traitHeaders.map((h,i) => ({
        ...h,
        value: this.traitsForm.controls[i].value,
      }));

      const values = [...singleHeaders, ...propertiesHeaders, ...traitHeaders].map(h => h.value);
      return values.join(',');
    };

    let fullCSV = [{n: this.selectedIdType}, {n: 'type'}];

    if (this.selectedType !== 'identify') {
      if (this.isTimestamp) fullCSV = [...fullCSV, {n: 'timestamp'}];
      else fullCSV = [...fullCSV, {n: 'date'}, {n: 'time'}, {n: 'timezone'}];
    }

    if (this.trackByEmailChecked) fullCSV = [...fullCSV, {n: 'context.atb_track_by_email'}];
    if (this.messageIdChecked) fullCSV = [...fullCSV, {n: 'message_id'}];

    switch(this.selectedType) {
      case 'track':
        fullCSV = [{n: 'event'}, ...fullCSV, ...this.properties.map(p => ({n: `properties.${p.n}`}))];
        break;
      case 'identify':
        fullCSV = [...fullCSV, ...this.traits.map(t => ({n: `traits.${t.n}`}))];
        break;
      case 'page':
        if (this.nameChecked) fullCSV = [...fullCSV, {n: 'name'}];
        fullCSV = [...fullCSV, ...this.properties.map(p => ({n: `properties.${p.n}`}))];
        break;
    }

    let csvContent =  "data:text/csv;charset=utf-8," + this.buildCSVHeaders(fullCSV).join(',');
    csvContent += '\n'+addInputs(fullCSV);
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  inputChanged(item, event) {
    item.n = event.target.value;
  }

  addChild(item) {
    item.c = item.c ? [...item.c, {n: ''}] : [{n: ''}];
  }

  onSelect(files: FileList) {
    if (files.length === 0) return;
    this.selectedFile = files.item(0);
    this.validateActive = true;
    this.processActive = false;
    this.afterValidateRecordsCounts = null;
    this.fileProcessed = false;
    const csvElem = document.getElementById('selectFile') as HTMLInputElement;
    csvElem.value = '';
  }

  validate() {
    if (!this.validateActive || this.validateInProgress) return;
    this.validateInProgress = true;
    this.eventsUploadService.validateCSV(this.selectedFile).subscribe({next: (resp: any) => {
      this.validateError = null;
      this.validateActive = false;
      this.validateInProgress = false;
      this.processActive = true;
      this.afterValidateRecordsCounts = resp.json?.length;
    }, error: error => {
      this.validateError = 'Failed to validate your file.';
      this.validateError = error?.error?.message;
      if (error.error.error_line) this.validateErrorLine = error.error.error_line;
      if (error.error.error_line_number) this.validateErrorLineNumber = error.error.error_line_number;
      this.validateActive = false;
      this.validateInProgress = false;
      this.processActive = false;
      this.selectedFile = null;
      this.afterValidateRecordsCounts = null;
    }});
  }

  process() {
    if (!this.processActive || this.processingInProgress) return;
    this.processingInProgress = true;
    this.eventsUploadService.uploadCSV(this.selectedFile).subscribe({next:resp => {
      this.uploadError = null;
      this.validateActive = false;
      this.processActive = false;
      this.processingInProgress = false;
      this.selectedFile = null;
      this.afterValidateRecordsCounts = null;
      this.fileProcessed = true;
    }, error: error => {
      this.uploadError = error?.error?.message;
      if (error.error.error_line) this.errorLine = error.error.error_line;
      if (error.error.error_line_number) this.errorLineNumber = error.error.error_line_number;
      this.validateActive = false;
      this.processActive = false;
      this.processingInProgress = false;
      this.selectedFile = null;
      this.afterValidateRecordsCounts = null;
    }});
  }
}
