import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { ConversionEvents } from 'src/app/store/states/conversion-events.state';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { UpdateProjectInfo } from 'src/app/store/actions/project.actions';
import { ConversionEventService } from 'src/app/core/services/conversion-event/conversion-event.service';

@Component({
  selector: 'app-revenue-groups',
  templateUrl: './revenue-groups.component.html',
  styleUrls: ['./revenue-groups.component.scss']
})
export class RevenueGroupsComponent implements OnInit, OnDestroy {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.conversionEvents) conversionEvents$: Observable<ConversionEvents>;
  @Input() showTitle = true;
  @Input() isInModal = false;

  project: Project;
  newGroup: any;
  newGroupName = '';
  revenueEvents = [];
  errorMessage = null;

  convSub;

  constructor(private store: Store, private projectService: ProjectService, private conversionEventService: ConversionEventService) { }

  ngOnInit(): void {
    this.project$.subscribe(x => this.project = x.project);
    this.convSub = this.conversionEvents$.subscribe(x => {
      if (x.revenueEvents.length == 0) {
          this.conversionEventService.getRevenueEvents();
      } else {
        this.revenueEvents = x.revenueEvents;
      }
    });
  }

  ngOnDestroy() {
    this.convSub.unsubscribe();
  }

  addNewGroup(type){
    this.newGroup = new Object({});
    this.newGroup.type = type;
    this.newGroup.events = [];
  }

  createNewGroup() {
    let revenueGroups = typeof(this.project.settings.revenue_groups) != 'undefined' ? this.project.settings.revenue_groups : [];
    if (this.newGroupName.length > 0) {
      let newGroup: any = {
        name: this.newGroupName,
        id: revenueGroups.length > 0 ? revenueGroups[revenueGroups.length - 1].id + 1 : 1,
      };
      if (this.newGroup.events.length > 0) {
        newGroup.events = this.newGroup.events;
      } else {
        this.errorMessage = 'You need to choose at least one event';
        return;
      };
      newGroup.type = this.newGroup.type;

      let newSettings = {
        ...this.project.settings
      };
      revenueGroups.push(newGroup);
      newSettings = {...newSettings, revenue_groups: revenueGroups};
      this.projectService.setAttribute('settings', newSettings).subscribe( x => {
        this.store.dispatch(new UpdateProjectInfo({...this.project, settings: newSettings}));
        this.newGroup = null;
        this.newGroupName = '';
      });
    } else {
      this.errorMessage = "Group name cannot be blank";
      return;
    };
  }

  deleteGroup(groupId){
    let newSettings = {...this.project.settings};
    if (confirm("Are you sure you want to delete this group?")) {
      newSettings.revenue_groups = this.project.settings.revenue_groups.filter(x => x.id != groupId);
      newSettings.default_revenue_group = null
      this.projectService.setAttribute('settings', newSettings).subscribe( x => {
        this.store.dispatch(new UpdateProjectInfo({...this.project, settings: newSettings}));
      });
    }
  }

  makeDefault(groupId) {
    let newSettings = {...this.project.settings};
    if (confirm("Are you sure you want to make this group as default?")) {
      newSettings.default_revenue_group = groupId;
      this.projectService.setAttribute('settings', newSettings).subscribe( x => {
        this.store.dispatch(new UpdateProjectInfo({...this.project, settings: newSettings}));
      });
    }
  }

  addOrRemoveEventToNewGroup(eventName) {
    if (this.newGroup.events.includes(eventName)) {
      this.newGroup.events = this.newGroup.events.filter(x => x != eventName);
    } else {
      this.newGroup.events.push(eventName);
    }

  }

  newGroupIncludesEvent(eventName) {
    return this.newGroup.events.includes(eventName);
  }

  get isThereRevenueGroups() {
    return typeof(this.project.settings.revenue_groups) != 'undefined';
  }

  get isCreatingNewGroup() {
    return this.newGroup && Object.keys(this.newGroup).length != 0;
  }

}
