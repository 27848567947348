import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integrationIsBeta',
})
export class IntegrationIsBetaPipe implements PipeTransform {
  transform(name): any {
    // return ['StackAdapt', 'ActiveCampaign', 'Klaviyo', 'Impact Ads', 'Reddit', 'CallRail', 'TikTok Ads', 'Sage Accounting'].includes(name);
    return false;
  }
}
