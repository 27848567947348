import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardIsFilterError',
})
export class DashboardIsFilterErrorPipe implements PipeTransform {
  transform(item, param, filterErrors): any {
    const type = item.t == 'f' ? 'filters' : 'groups';
    return typeof(filterErrors[type]) != 'undefined' && typeof(filterErrors[type][item.id]) != 'undefined' && filterErrors[type][item.id][param] > 0;
  }
}