import { Component, OnChanges, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { UserService } from 'src/app/core/services/user/user.service';
import { SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { toNumber, last } from 'lodash-es';
import { Router, ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/core/models/project.model';
import { SidebarSetName } from 'src/app/store/actions/only-simple.actions';
import { SidebarStateService } from 'src/app/core/services/sidebar-state/sidebar-state.service';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { BaseService } from 'src/app/core/services/base/base.service';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { Observable, Subscription } from 'rxjs';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';

@Component({
  selector: 'app-users-sidebar',
  templateUrl: './users-sidebar.component.html',
  styleUrls: ['./users-sidebar.component.scss']
})
export class UsersSidebarComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() sidebar: SidebarStateModel;
  @Input() project: Project;
  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;

  selector;
  inProgress;
  users = [];
  isMore = false;
  isEmpty;
  isRecent = true;
  filter;
  searchQuery: string = null;
  selected = {
    filterType: null,
    filterOrGroupId: null
  };
  filterTree;
  treeSub: Subscription;

  constructor(
    private store: Store,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private sidebarState: SidebarStateService,
    private baseService: BaseService,
    private FTWS: FilterTreeWorkerService
  ) { }

  ngAfterViewInit() {
    document.getElementById('sidebar-content').scrollTop = this.sidebarState.scrollPosition;
    document.getElementById('users-search-input').focus();
  }

  ngOnChanges() {
    this.treeSub?.unsubscribe();
    this.treeSub = this.filterTree$.subscribe(filterTree => {
      this.filterTree = filterTree.filterTree;
      if (this.filterTree && this.project) {
        this.initFlow();
      }
    });
  }

  ngOnDestroy(): void {
    this.treeSub?.unsubscribe();
  }

  initFlow() {
    const oldState = this.sidebarState.sidebarState;
    const oldStateKeyArray = Object.keys(oldState);
    if (this.sidebarState.wentBack == true && oldStateKeyArray.length > 0) {
      for (let key of oldStateKeyArray) {
        this[key] = oldState[key];
      }
      this.sidebarState.setWentBack(false);
    } else {
      this.inProgress = true;
      this.users = [];
      this.store.dispatch(new SidebarSetName({name: SidebarNames.users}));
      if (this.sidebar.options.q && this.sidebar.options.q.length > 0) {
        this.searchQuery = this.sidebar.options.q;
      } else {
        this.searchQuery = null;
        this.inProgress = false;
        return;
      }
      if (this.sidebar.options.filter) {
        this.selected.filterType = 'f';
        this.selected.filterOrGroupId = toNumber(this.sidebar.options.filter);
      } else {
        this.selected.filterType = 'g';
        this.selected.filterOrGroupId = toNumber(this.sidebar.options.group);
      }
      this.selector = document.getElementById('sidebar-content');
      this.filter = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: this.selected.filterOrGroupId, additionalKey: 't', additionalValue: this.selected.filterType});

      this.userService.get(this.searchQuery).subscribe(
        (resp: {more: boolean; data: Array<any>; }) => {
          this.users = resp.data;
          this.isMore = resp.more;
          this.isEmpty = this.users.length === 0;
          this.inProgress = false;
          this.sidebarState.setState({
            openedSidebar: this.sidebar.openedSidebar,
            selector: this.selector,
            users: this.users,
            isMore: this.isMore,
            isEmpty: this.isEmpty,
            isRecent: this.isRecent,
            searchQuery: this.searchQuery,
            inProgress: this.inProgress,
            filter: this.filter,
            selected: this.selected
          });
        }
      );
    }
  }

  onScroll() {
    if (!this.isMore || this.inProgress) { return; }
    this.inProgress = true;
    this.userService.get(this.searchQuery, last(this.users).time).subscribe(
      (resp: {more: boolean; data: Array<any>; }) => {
        this.users = this.users.concat(resp.data);
        this.isMore = resp.more;
        this.inProgress = false;
        this.sidebarState.setState({
          openedSidebar: this.sidebar.openedSidebar,
          selector: this.selector,
          users: this.users,
          isMore: this.isMore,
          isEmpty: this.isEmpty,
          isRecent: this.isRecent,
          searchQuery: this.searchQuery,
          inProgress: this.inProgress,
          filter: this.filter,
          selected: this.selected
        });
      }
    );
  }

  search(q) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...queryParams, q }
    });
  }

  showVisitor(visitorId) {
    this.baseService.showVisitor(visitorId);
  }

}
