import { Pipe, PipeTransform } from '@angular/core';
import { Integration, IntegrationTypes } from 'src/app/core/constants/integration';

@Pipe({
  name: 'canManageIntegration',
})
export class CanManageIntegrationPipe implements PipeTransform {
  transform(oauth): any {
    return oauth.type in Integration.get.names[IntegrationTypes.integration];
  }
}
