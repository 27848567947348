import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { Breakdown } from 'src/app/core/enums/breakdown.type';

import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { Project } from 'src/app/core/models/project.model';
import { environment } from 'src/environments/environment';

export const sortTable = (array, direction, column) => {
  let sorted;
  sorted = array.map( x => {
    if (x.c) {
      x.c = sortTable(x.c, direction, column);
    }
    return x;
  });

  sorted = sorted.sort( (a, b) => {
    if (direction === 'Down') {
      return (b.object[column] - a.object[column]);
    } else {
      return (a.object[column] - b.object[column]);
    }
  });
  return sorted;
};

@Component({
  selector: 'app-fixed-rows',
  templateUrl: './fixed-rows.component.html',
  styleUrls: ['./fixed-rows.component.scss']
})
export class FixedRowsComponent implements OnInit {
  @Input() rowName;
  @Input() rowNameSub;
  @Input() amounts;
  @Input() conversions;
  @Input() visits;
  @Input() clicks;
  @Input() revenue;
  @Input() selectedAttributionModel;
  @Input() showLines: boolean;
  @Input() maxValues;
  @Input() id;
  @Input() overlaps;
  @Input() shouldBreakDown = false;
  @Input() mapping;
  @Input() lastEmittedSort;
  @Input() special;
  @Input() tableRevenue;

  @Input() periodArray;
  @Input() isTimeframe;
  @Input() timeframeReport;
  @Input() isFTP = false;
  @Input() itemBDs: Breakdown;
  @Input() allTrafficPathOpened: boolean;

  @Output() OpenSidebar = new EventEmitter();
  @Output() breakdown = new EventEmitter();

  isProd = environment.isProd;
  sidebarNames = SidebarNames;

  sortTable = sortTable;

  dontShowPathsForSpecials = ['unattributed'];

  tooltip = null;
  tooltips = {
    'Unsegmented Traffic': {
      content: 'Learn more <a href="https://help.attributionapp.com/docs/default-channels" target="_blank">here</a>.'
    },
    'Direct Traffic' : {
      content: 'Learn more <a href="https://help.attributionapp.com/en/articles/3456091-modifying-your-attribution-model" target="_blank">here</a>.'
    },
    'Unknown Source': {
      content: 'Learn more <a href="https://help.attributionapp.com/docs/default-channels" target="_blank">here</a>.'
    },
    'Raw Traffic': {
      content : 'Learn more <a href="https://help.attributionapp.com/docs/default-channels" target="_blank">here</a>'
    },
    'Unresolved GCLIDs': {
      content : 'Learn more <a href="hhttps://help.attributionapp.com/docs/default-channels" target="_blank">here</a>.'
    }
  };

  constructor(private store: Store) { }

  ngOnInit() {
    const project: Project = this.store.selectSnapshot(state => state.project.project);
    if (this.rowNameSub == null) {
      switch (this.rowName) {
        case 'Unattributed Traffic':
          this.rowNameSub = 'Events without an associated visit';
          break;
        case 'Unsegmented Traffic':
          if (project) this.rowNameSub = `Filter your <a href="/${project.identifier}/dashboard-builder">tagged</a> and <a href="/${project.identifier}/dashboard-builder?type=referrers">referral</a> sources`;
          break;
        case 'Unresolved GCLIDs':
          this.rowNameSub = 'Google clicks may take up to 24hrs to resolve';
          break;
      }
    }
    if (this.tooltips[this.rowName]) {
      this.tooltip = this.tooltips[this.rowName].content;
    }
  }

  sortedBreakdowns(breakDowns) {
    if (this.lastEmittedSort) {
      return sortTable(breakDowns, this.lastEmittedSort.sortDirection, this.lastEmittedSort.sortColumn);
    } else {
      return breakDowns;
    }
  }

  breakDown() {
    this.breakdown.emit({id: this.id, t: 'f'});
  }

  openSidebar(path, params?) {
    if (this.id) {
      params.id = this.id;
      params.type = 'f';
    }
    this.OpenSidebar.emit({path, params});
  }

  openTimeFrameSidebar(params) {
    this.OpenSidebar.emit(params);
  }

}
