import { Component, Input, ChangeDetectorRef, OnInit, OnChanges } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { Store } from '@ngxs/store';

declare var $: any;

@Component({
  selector: 'cohort-content',
  templateUrl: './cohort-content.component.html',
  styleUrls: ['./cohort-content.component.scss']
})
export class CohortContentComponent implements OnInit, OnChanges {

  @Input() cohortLength;
  @Input() cohorts;
  @Input() align;
  @Input() metric;
  @Input() periodsForRightAlign;
  @Input() heatmap;
  @Input() revenue;
  @Input() period;
  @Input() denomination;
  cellMaxWidth;
  Math = Math;
  PP: PercentPipe = new PercentPipe('en-US');

  constructor(private cdr: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    this.normalizeCells();
  }

  ngOnChanges() {
    this.normalizeCells();
  }

  trackByIndex(index) {
    return index;
  }

  private normalizeCells() {
    this.cellMaxWidth = null;
    this.cdr.detectChanges();
    const headerElements = document.getElementsByClassName('in-header');
    let max = 0;
    Array.prototype.forEach.call(headerElements, cell => {
        const Width = cell.offsetWidth;
        max = max < Width ? Width : max;
    });

    this.cellMaxWidth = max > 100 ? 100 : max;
    this.cdr.detectChanges();
  }

}
