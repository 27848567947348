<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ oauth.name }}</h4>
  <small *ngIf="oauth.identifier && ['adwords', 'googleads', 'googleads2'].includes(oauth.type); else fullIdentifier">
    {{ [oauth.identifier.slice(0,3), oauth.identifier.slice(3,6), oauth.identifier.slice(6)].join('-') }}
  </small>
  <ng-template #fullIdentifier>
    <small ng-else>{{ oauth.identifier }}</small>
  </ng-template>
</div>
<div class="alert modal_alert alert-warning">
  <i class="fa-solid fa-triangle-exclamation"></i>
  Campaigns could be manually disabled or automatically disabled if no spend or visits are tracked, if any spend is detected on disabled campaign it would be automatically enabled.
</div>
<div class="modal-body">
  <app-loader *ngIf="isLoading; else loaded" [position]="'relative'" [color]="'green'" [backgroundColor]="'white'">
  </app-loader>
  <ng-template #loaded>
    <div *ngIf="tree.length == 0" class="alert alert-info" role="alert">
      No Campaigns tracked
    </div>
    <div *ngIf="tree.length != 0">
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: tree }"></ng-container>
      <ng-template
        #recursiveList
        let-list>

        <div *ngFor="let item of list">
          <div class="d-flex align-items-center">
            <div class="d-inline-block form-check form-switch">
              <input class="form-check-input green" type="checkbox" role="switch" id="flexSwitchCheckDefault" ngbTooltip="{{ item.t == 'g' ? 'Group' : 'Filter'}} is currently {{ item.v ? 'enabled' : 'disabled' }}. Click on the icon to switch status" (change)="update(item)" [attr.checked]="item.v ? 'true' : null">
            </div>
            <span [class.font-weight-bold]="item.t == 'g'">{{ item.n }}</span>
            <span class="ms-2 medium small">{{ item.it || '' }}</span>
          </div>

          <div *ngIf="itemHasChildren(item)" class="children" [class.children-in-grey]="!item.v">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.c }"></ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
