import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { NextDay } from 'src/app/core/helpers/next-day';
import { Project } from 'src/app/core/models/project.model';
import { ChannelBuilderService } from 'src/app/core/services/channel-builder/channel-builder.service';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SidebarStateModel } from 'src/app/store/states/sidebar.state';

@Component({
  selector: 'app-channel-builder-sidebar',
  templateUrl: './channel-builder-sidebar.component.html',
  styleUrls: ['./channel-builder-sidebar.component.scss']
})
export class ChannelBuilderSidebarComponent implements OnChanges, OnDestroy {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;
  @Input() sidebar: SidebarStateModel;

  sidebarNames = SidebarNames;

  params;
  title;
  value;
  scope;
  order = 'time DESC';
  inProgress = true;
  items = [];
  queryParams: any;
  selector;
  project: Project;
  filterTree;
  isMore = false;
  sub: Subscription;

  constructor(private store: Store, private service: ChannelBuilderService, private FTWS: FilterTreeWorkerService) { }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.sub?.unsubscribe();
    this.sub = combineLatest([this.project$, this.filterTree$]).subscribe( ([project, filterTree]) => {
      this.project = project.project;
        this.filterTree = filterTree.filterTree;
      if (project.project && filterTree.filterTree) {
        
        this.initFlow();
      };
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  initFlow() {
    this.selector = document.getElementById('sidebar-content');
    this.scope = this.sidebar.options.scope;
    if (this.scope === 'referrers') {
      this.value = this.sidebar.options.value;
      this.params = {
        referrers: [{ value: this.sidebar.options.value, operator: 'equals' }]
      };
    } else if (this.scope === 'params') {
      this.value = "params=" + this.sidebar.options.values;

      const parsed = JSON.parse(this.sidebar.options.values);
      let result = [];
      Object.keys(parsed).forEach( x => {
        result.push({key: x, value: parsed[x]})
      });

      this.params = { params: result };
    } else if (this.scope === 'paths') {
      this.value = this.sidebar.options.value;
      this.params = { paths: [{ value: this.sidebar.options.value }] };
    }

    const SelectedState = this.store.selectSnapshot( state => state.selected);

    const StartDate = SelectedState.chosenDates.start;
    const EndDate = SelectedState.chosenDates.end;

    this.queryParams = {
      start: StartDate,
      end: NextDay(EndDate, 1),
      values: JSON.stringify(this.params),
      order: this.order,
    };

    if (this.sidebar.options.filter) {
      const filterFromTree = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: this.sidebar.options.filter, additionalKey: 't', additionalValue: this.sidebar.options.filterType == 'filter' ? 'f' : 'g'});
      if (typeof(filterFromTree) != 'undefined') {
        if (filterFromTree.s == 'unsegmented') {
          this.title = 'unsegmented visits';
        } else this.title = `filtered visits(${filterFromTree.n})`;
      } else this.title = this.title = 'all visits'

      if (this.sidebar.options.filterType == 'filter') this.queryParams.filter = parseInt(this.sidebar.options.filter);
      else if (this.sidebar.options.filterType == 'group') this.queryParams.filter_group_id = parseInt(this.sidebar.options.filter);
    } else {
      this.title = 'all visits'
    }
    this.getInitial();
  }

  changeOrder() {
    if (this.order == 'time DESC') {
      this.order = 'time ASC';
    } else this.order = 'time DESC';
    this.queryParams.order = this.order;
    this.getInitial();
  }

  getInitial() {
    this.service.getEvents(this.queryParams).subscribe( (x: any) => {
      this.inProgress = false;
      this.items = x.data;
      this.addFilterPaths()
      this.isMore = x.more;
    });
  }

  addFilterPaths() {
    const filterTree = this.store.selectSnapshot( state => state.filterTree.filterTree);
    if (filterTree) {
      this.items = this.items.map(x => {
        if (x.filter == null || typeof(x.filter) == 'undefined') return x;
        const item = this.FTWS.findInTree({node: {c: filterTree}, childrenKey: 'c', key: 'id', value: x.filter, additionalKey: 't', additionalValue: 'f', withPath: filterTree});
        if (item) {
          return {
            ...x,
            filter: item.pathArray.length > 0 ? item.pathArray.join(' > ') + ' > ' + item.n : item.n
          }
        } else return x;
      });
    };
  }

  onScroll() {
    if (!this.isMore || this.inProgress) { return; }
    this.inProgress = true;
    const params = {
      ...this.queryParams,
      since: this.items[this.items.length-1].time
    };
    this.service.getEvents(params).subscribe( (x:any) => {
      this.items = this.items.concat(x.data);
      this.isMore = x.more;
      this.inProgress = false;
    });
  }

  formatUrl(url) {
    let path:any = /^[^#]*?:\/\/.*?(\/.*)$/g.exec(url);
    if (path)
      path = path[1];
    else
      path = '/';

    return path.replace(new RegExp(this.value, "gi"), match => {
        return '<b class="blue">' + match + '</b>';
    });
  }

  sameHost(item) {
    const itemHost = new URL(item.url).host;
    const refHost = new URL(item.referrer).host;
    return itemHost == refHost;
  }

}
