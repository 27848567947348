<div class="input-group">
  <input
    class="form-control"
    placeholder="yyyy-mm-dd"
    name="dp"
    [(ngModel)]="model"
    ngbDatepicker
    #d="ngbDatepicker"
    (click)="d.toggle()"
    (dateSelect)="onDateSelection($event)">
  <button class="btn btn-outline-secondary" type="button" (click)="onButtonClick()">{{ buttonText }}</button>
</div>