import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { Integration, IntegrationTypes } from 'src/app/core/constants/integration';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { Project } from 'src/app/core/models/project.model';
import { IntegrationsService } from 'src/app/core/services/integrations/integrations.service';
import { SetCurrentComponent, SetSettingSection } from 'src/app/store/actions/selected.actions';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, OnDestroy {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  oldIntegrations = ['adwords', 'facebook3', 'googleads', 'bing', 'linkedin'];

  integrations = Object.keys(Integration.get.names[IntegrationTypes.integration]).map( x => {
    return { key: x, name: Integration.get.names[IntegrationTypes.integration][x] }
  }).filter( x => !Integration.get.OldIntegrations.includes(x.key) );

  connectors = Object.keys(Integration.get.names[IntegrationTypes.connector]).map( x => {
    return { key: x, name: Integration.get.names[IntegrationTypes.connector][x] }
  }).filter( x => !Integration.get.OldIntegrations.includes(x.key) );

  transformers = Object.keys(Integration.get.names[IntegrationTypes.transformer]).map( x => {
    return { key: x, name: Integration.get.names[IntegrationTypes.transformer][x] }
  });

  activeTab = 'ads';
  project: Project;
  isWithIdentifier;
  openIntegrations = [];
  openConnectors = [];
  openTransformers = [];
  settingsSections = SettingsSections;
  hiddenOnProd = Integration.get.HiddenOnProd;
  isProd = true;

  openHiddenForProjects = {
    csv_upload: [],
  }

  routeDataSub: Subscription;
  projectSub: Subscription;
  queryparamsSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private integrationsService: IntegrationsService,
    private store: Store,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.isProd = environment.envName == 'production';
    this.routeDataSub = this.route.data.subscribe(v => this.isWithIdentifier = v.isWithIdentifier ? true : false);
    this.store.dispatch([new SetCurrentComponent({ currentComponent: ComponentNames.settings}), new SetSettingSection(SettingsSections.integrations)]);
    this.projectSub = this.project$.subscribe( x => {
      this.project = x.project;

      let openIntegrations = [];
      if (this.project?.options?.integrations == 'all') openIntegrations = this.integrations;
      else if (!this.project?.options?.integrations) openIntegrations = [];

      else openIntegrations = this.integrations.filter(x => Integration.get.IsIntegrationOpen(x.key, this.project));

      let openConnectors = [];
      if (!this.project.options.connectors) openConnectors = [];
      else if (this.project.options.connectors == 'all') openConnectors = this.connectors;
      else openConnectors = this.connectors.filter(x => this.project.options.connectors.includes(x.key));

      let openTransformers = [];
      // if (this.project?.options?.transformers == 'all' || !this.project?.options?.transformers) openTransformers = this.transformers;
      // else openTransformers = this.transformers.filter(x => this.project?.options?.transformers?.includes(x.key));
      openTransformers = this.transformers;

      Integration.get.AllConnectedOauths(this.project).forEach( x => {
        const oauthIntegration = this.integrations.find(y => y.key == x);
        if (oauthIntegration) {
          if (typeof(openIntegrations.find(y => y.key == x)) == 'undefined') openIntegrations.push(oauthIntegration);
        } else {
          const oauthConnector = this.connectors.find(y => y.key == x);
          if (oauthConnector && typeof(openConnectors.find(y => y.key == x)) == 'undefined') {
            openConnectors.push(oauthConnector);
          } else {
            const oauthTransformer = this.transformers.find(y => y.key == x);
            if (oauthTransformer && typeof(openTransformers.find(y => y.key == x)) == 'undefined') {
              openTransformers.push(oauthTransformer);
            }
          }
        }
      });

      this.integrations = this.integrations.sort((a,b) => a.name.localeCompare(b.name));
      this.connectors = this.connectors.sort((a,b) => a.name.localeCompare(b.name));
      this.openIntegrations = openIntegrations.sort((a,b) => a.name.localeCompare(b.name));
      this.openConnectors = openConnectors.sort((a,b) => a.name.localeCompare(b.name));
      this.openTransformers = openTransformers.sort((a,b) => a.name.localeCompare(b.name));
    });

    this.queryparamsSub = this.route.queryParams.subscribe( x => {
      if (x.type) {
        if (x.type == 'ads') this.activeTab = 'ads';
        else if (x.type == 'conversions') this.activeTab = 'conversions';
        else if (x.type == 'transformers') this.activeTab = 'transformers';
        else this.activeTab = null;
      } else {
        this.activeTab = null;
      }

      if (typeof(x.integration) != 'undefined') {
        let params = {...x};
        let callbackUrl:string;
        switch (x.integration) {
          case 'salesforce':
            callbackUrl = `${window.location.origin}/callback.html?integration=${x.integration}`;
            break;
          case 'pinterest':
            callbackUrl = `${window.location.origin}/integration-callback.html`; // Pinterest doesn't allow query params in callback url
            break;
          default:
            callbackUrl = `${window.location.origin}/integration-callback.html?integration=${x.integration}`;
            break;
        }
        const continueType = x.continueType ? x.continueType : 'default';
        switch(continueType) {
          case 'auth':
            this.integrationsService.continueAuth(params);
            break;
          default:
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: null
            });
            params = {...params, callback_url: callbackUrl};
            if (x.integration == 'reddit'){
              params = {
                ...params,
                secret_key: sessionStorage.getItem("reddit_secret_key"),
                client_id: sessionStorage.getItem("reddit_client_id")
              }
              sessionStorage.removeItem("reddit_secret_key")
              sessionStorage.removeItem("reddit_client_id")
            }

            this.integrationsService.continue(params);
            break;
        }
      }
    });
  }

  oauthsByType(type) {
    if (!this.project) return [];
    const fromAdminDict = Integration.get.AdminDict.find(x => x.checkArray.includes(type));
    const oauths = this.project.oauths.filter( x => {
      return fromAdminDict ? fromAdminDict.checkArray.includes(x.type) : x.type == type;
    });
    return oauths;
  }

  ngOnDestroy() {
    this.routeDataSub?.unsubscribe();
    this.projectSub?.unsubscribe();
    this.queryparamsSub?.unsubscribe();
  }

  connect(integrationName) {
    this.integrationsService.start({integrationName:integrationName});
  }

  goToShowOrStart(integration) {
    if (integration.key == 'csv_upload') {
      this.router.navigate([this.project.identifier, ComponentNames.settings, this.settingsSections.eventsUploader]);
    } else if (integration.key == 'influence_upload') {
      this.router.navigate([this.project.identifier, ComponentNames.settings, this.settingsSections.influencesUploader]);
    } else if (this.oauthsByType(integration.key).length > 0) {
      let intKey = integration.key;
      const fromDict = Integration.get.AdminDict.find(x => x.checkArray.includes(integration.key));
      if (fromDict) {
        intKey = fromDict.name;
      }
      this.router.navigate([this.project.identifier, ComponentNames.settings, SettingsSections.integrations, 'show', intKey]);
    } else {
      this.connect(integration.key);
    }
  }

  hasConnected(integration) {
    const oauths = this.oauthsByType(integration.key);
    return typeof(oauths.find(x => x.expired_at == null && x.identifier != null)) != 'undefined';
  }

  hasDisconnected(integration) {
    const oauths = this.oauthsByType(integration.key);
    return typeof(oauths.find(x => x.expired_at != null || x.identifier == null)) != 'undefined';
  }

}
