<h4 class="medium text-center-header">
  showing
  <span class="dark">{{ title }}</span>
  with
  <span class="dark">
    {{ value }}
  </span>
  <span *ngIf="scope === 'params'">url parameter</span>
  <span *ngIf="scope === 'paths'">landing path</span>
  <span *ngIf="scope === 'referrers'">referrer</span>
</h4>


<a class="clickable blue event-sorter btn btn-link" (click)="changeOrder()" *ngIf="items.length != 0">
  <i *ngIf="order == 'time DESC';" class="fa-solid fa-arrow-down-short-wide"></i>
  <i *ngIf="order == 'time ASC';" class="fa-solid fa-arrow-up-short-wide"></i>
</a>

<div
  class="table-infinite"
  infiniteScroll
  [infiniteScrollContainer]="selector"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()">
  <table *ngIf="items.length > 0" class="table sidebar-table">
    <tbody>
      <tr class="sidebar-tr-link cursor-default" *ngFor="let item of items">
        <td class="event-wrapper word-break-all small">
          <div *ngIf="item.type == 'date'">
            <a *ngIf="item.visitor_id" class="me-1" [routerLink]="[]" [queryParams]="{sidebar: sidebarNames.visitors, id: item.visitor_id}"  placement="top-left no-w-wrap" ngbTooltip="Visitor History"><i class="fa-regular fa-user icon me-1 mt-1"> </i></a>
            {{ item.value | formattedMoment:'DDD' }}
          </div>
          <div>
            <a class="me-1" [routerLink]="[]" [queryParams]="{sidebar: sidebarNames.visitors, id: item.visitor_id}" placement="top-left" ngbTooltip="Visitor History"><i class="fa-regular fa-user icon me-1 mt-1"> </i></a>
            <span *ngIf="!item.url">{{ item.name }}<br/></span>
            <a class="medium-dark word-break-all" href="{{ item.url }}" *ngIf="item.url" [innerHTML]="formatUrl(item.url)"><br/></a>
            <div *ngIf="item.referrer">
              <span class="medium caps me-1 word-break-all">{{sameHost(item) ? 'Self-Referral' : 'Referrer'}}:</span>
              <a href="{{item.referrer}}" class="clickable blue">{{item.referrer}}</a>
            </div>
            <div *ngIf="!item.referrer && item.url">
              <span class="sidebar_event_details_key muted-caps">Referrer:</span>
              <span class="sidebar_rereferrer">(Direct)</span>
            </div>
            <div *ngIf="true">
              <span class="medium caps me-1">Filtered By:</span>
              <span class="sidebar_rereferrer word-break-all"> {{ item.filter }}</span>
            </div>
            <div *ngIf="item.revenue">
              <span class="sidebar_event_details_key muted-caps">Revenue:</span>
              <span>{{ item.revenue | projectCurrency }}</span>
            </div>
          </div>
        </td>
        <td class="min-width-100 text-right small no-w-wrap">
          <span>{{ (scope === 'conversions' ? item.conversion_time : item.time) | formattedMoment:'MMM d, y' }}</span><br/>
          <span class="muted" ngbTooltip="{{ scope === 'conversions' ? item.conversion_time : item.time }}" container="body" placement="left">{{ (scope === 'conversions' ? item.conversion_time : item.time) | formattedMoment:'t'}}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="inProgress" class="loader-sm">
    <app-loader
      *ngIf="inProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
<div class='text-center p-20px pt-0' *ngIf="!inProgress && !isMore">
  <i class="fa-solid fa-stop-circle fa-2x light"></i>
</div>