import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { Router } from '@angular/router';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

@Component({
  selector: 'app-new-project-modal',
  templateUrl: './new-project-modal.component.html',
  styleUrls: ['./new-project-modal.component.scss']
})
export class NewProjectModalComponent implements OnInit {
  @Input() owners: Array<{id: number, email: string}>;
  newProjectForm: UntypedFormGroup;
  errorMessage: string;
  waitingForResponse = false;

  componentNames = ComponentNames;
  settingsSections = SettingsSections;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private projectService: ProjectService,
    private router: Router) {}

  ngOnInit() {
    this.newProjectForm = this.fb.group({
      ownerSelect: ['', Validators.required],
      projectName: ['', Validators.required]
    });
    if (this.owners) {
      this.newProjectForm.controls['ownerSelect'].setValue(this.owners[0].id);
    }
  }

  createNewProject() {
    this.errorMessage = null;

    if (this.newProjectForm.valid) {
      this.waitingForResponse = true;
      this.projectService.createNewProject(
        this.newProjectForm.controls['ownerSelect'].value,
        this.newProjectForm.controls['projectName'].value
      ).subscribe( resp => {
        this.activeModal.close();
        this.router.navigate([resp?.identifier, '']);
        this.waitingForResponse = false;
      }, error => {
        this.waitingForResponse = false;
        this.errorMessage = error?.error?.error || 'Woops! Your query has timed out. Please try reloading the app.';
      });
    }
  }
}
