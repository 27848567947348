import { ErrorHandler, Injectable, InjectionToken, Inject, Injector } from '@angular/core';
import Rollbar from 'rollbar';
import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';
import { BaseService } from '../services/base/base.service';

const rollbarConfig: Rollbar.Configuration = {
    accessToken: environment.rollbarAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: ['production', 'skeleton', 'staging'].includes(environment.envName),
    environment: environment.envName,
    nodeSourceMaps: true,
  };

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  private store: Store;
  private baseService: BaseService;

  constructor(@Inject(RollbarService) private rollbar: Rollbar, injector: Injector) {
    setTimeout(() => {
      this.store = injector.get(Store);
      this.baseService = injector.get(BaseService);
    });
  }

  handleError(err: any): void {
    let ProjectState;
    let version = 'undefined';
    if (this.store) {
      ProjectState = this.store.selectSnapshot( state => {
        if (state.project) {
          return state.project.project;
        } else {
          return null;
        }
      });
      version = this.store.selectSnapshot(state => state.base.appVersion);
    }
    if (ProjectState) {
      this.rollbar.configure({
        payload: {
          person: {
            id: ProjectState.id, // required,
            username: ProjectState.name,
            email: ProjectState.owner.email
          },
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: version,
              guess_uncaught_frames: true
            }
          },
        },
        codeVersion: version,
      });
    }
    if (this.baseService) err.message= this.baseService.checkAndModifyErrorMessage(err);
    if (err.level == 'warning') {
      err.metadata ? this.rollbar.warning((err.originalError || err) , err.metadata) : this.rollbar.warning(err.originalError || (err.url ? err.message : err));
      console.warn(err);
    } else {
      err.metadata ? this.rollbar.error((err.originalError || err) , err.metadata) : this.rollbar.error(err.originalError || (err.url ? err.message : err));
      console.error(err);
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
