import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash-es';

@Pipe({
  name: 'visitTimelineVisitFilters',
})
export class VisitTimelineVisitFiltersPipe implements PipeTransform {
  transform(visitFilter, filters): any {
    return filter(filters, {id: visitFilter});
  }
}