import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdDestinationUrlsService } from 'src/app/core/services/ad-destination-urls/ad-destination-urls.service';
import { isEmpty } from 'lodash-es';
import { Store } from '@ngxs/store';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Component({
  selector: 'app-extended-ad-destination-urls-modal',
  templateUrl: './extended-ad-destination-urls-modal.component.html',
  styleUrls: ['./extended-ad-destination-urls-modal.component.scss']
})
export class ExtendedAdDestinationUrlsModalComponent {
  @Input() item: any;
  urlObjectName: string;
  groupedUrls;
  urlsAreLoading;
  selectedUrl;
  isEmpty = false;

  objectKeys = Object.keys;

  constructor(
    private service: AdDestinationUrlsService,
    public activeModal: NgbActiveModal,
    private store: Store
  ) { }

  ngOnInit() {
    this.urlsAreLoading = true;
    this.urlObjectName = this.item.name;

    const params: any = {};
    if (this.item.t == 'f') {
      params.filter_id = this.item.id;
    } else if (this.item.t == 'g') {
      params.filter_group_id = this.item.id;
    }

    const chosenDates = this.store.selectSnapshot<SelectedStateModel>( state => state.selected).chosenDates;
    params.start = chosenDates.start;
    params.end = chosenDates.end;

    this.service.get(params, true).subscribe( resp => {
      this.groupedUrls = resp;
      this.urlsAreLoading = false;
      this.isEmpty = isEmpty(resp);
    });
  }

  toggleSelected(url) {
    this.selectedUrl = this.isSelected(url) ? undefined : url;
  }

  isSelected(url) {
    return this.selectedUrl === url;
  }
}
