<div class="aggregation-period-wrapper" [class.timeframe]="isTimeframe">
  <small *ngIf="!isTimeframe" class="controllbar__hint top text-nowrap">Agg. Period {{ for ? 'for '+for : '' }}</small>
  @if (unavailablePeriods.length > 0) {
    <small class='controllbar__hint bottom red'><b class="text-capitalize me-1">{{ unavailablePeriods.join(', ') }}</b>period(s) are not available for the selected dates.</small>
  }
  <div class="dropdown" ngbDropdown>
    <button #periodDropdown class="btn btn-default dropdown-toggle btn-select" type="button" id="dropdownMenuButton" [attr.data-boundary]="periodDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
      <span *ngIf="isTimeframe" class="me-3">Timeframe Aggregation Period:</span>
      <i class="fa-regular fa-clock fa--header"></i>
      <span class="text-capitalize">{{selectedAggregationPeriod}}</span>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
      <ng-template #periodTooBig>
        Date range it too big for aggregation period
      </ng-template>
      <span class="dropdown-item text-capitalize" [class.medium]="!(period | periodIsOk:availablePeriods)" *ngFor='let period of aggregationPeriods' (click)='selectPeriod(period)' [ngbTooltip]="(period | periodIsOk:availablePeriods) ? null : periodTooBig">
        {{ period }}
      </span>
    </div>
  </div>
</div>
