import { State, Action, StateContext, Store } from '@ngxs/store';

import {
  SetChannelPerformanceReport,
  SetChannelPerformanceChannelSummaryToNull,
  SetChannelPerformancePeriod,
  SetChannelPerformanceSummary,
  SetChannelPerformanceInProgress,
  SetChannelPerformanceSummaryInProgress,
  SetChannelPerformanceComparison,
  SetChannelPerformanceComparisonInProgress,
  ClearCP,
  ClearCPWithoutSummary,
  ClearPrevSummary,
  SetTrafficModel
} from '../actions/channel-performance.actions';
import { SetCpOpenChannelId } from '../actions/selected.actions';
import { DateFormat } from '../../core/helpers/chart-date-formatter';
import { SelectedStateModel } from './selected.state';
import { Injectable } from '@angular/core';
import { ChannelPerformanceService } from 'src/app/core/services/channel-performance/channel-performance.service';
import { capitalize } from 'lodash-es';
import { CpTotalsItem, ReportItem } from '../interfaces/channel-performance';
import { ProjectStateModel } from './project.state';
import { View } from 'src/app/core/models/project.model';

export interface ChannelPerformanceStateModel {
    inProgress: boolean;
    summary: any;
    summaryParams: any;
    prevSummary: any;
    prevSummaryParams: any;
    report: Array<ReportItem>;
    openChannelSummary: any;
    summaryIsLoading: boolean;
    comparison: any;
    comparisonIsLoading: boolean;
    openChannelSummaryIsLoading: boolean;
    totals: Array<CpTotalsItem>;
}

@State<ChannelPerformanceStateModel>({
    name: 'channelPerformance',
    defaults: {
      inProgress: false,
      summary: null,
      summaryParams: null,
      prevSummary: null,
      prevSummaryParams: null,
      report: null,
      openChannelSummary: null,
      summaryIsLoading: false,
      comparison: null,
      comparisonIsLoading: false,
      openChannelSummaryIsLoading: false,
      totals: null
    }
})
@Injectable()
export class ChannelPerformanceState {
  constructor(private store: Store, private cpService: ChannelPerformanceService) {}

  @Action(SetChannelPerformanceReport)
  SetChannelPerformanceReport(ctx: StateContext<ChannelPerformanceStateModel>, { payload }: SetChannelPerformanceReport) {
    const getState = ctx.getState();

    const totalsArray = payload.report.map( (x: ReportItem) => {
      let totalRevenue = 0; let totalSpend = 0;
      for (const y of x.list) {
        totalRevenue += y.revenue;
        totalSpend += y.spend;
      }
      return { id: x.id, name: x.name, revenue: totalRevenue, spend: totalSpend, roas: totalRevenue + totalSpend };
    });

    const Period = this.store.selectSnapshot<ProjectStateModel>( state => state.project).currentViewParams?.aggregation_period || "Day";
    const Report = [];

    payload.report.forEach( (x: ReportItem) => {
      const List = x.list.map( y => {
        return {
          label: DateFormat(y.period, Period),
          roas: y.roas,
          revenue: y.revenue,
          spend: y.spend
        };
      });
      Report.push({
        id: x.id,
        name: x.name,
        list: List
      });
    });

    ctx.setState({
      ...getState,
      report: Report,
      inProgress: false,
      totals: totalsArray
    });
  }

  @Action(SetChannelPerformanceInProgress)
  SetChannelPerformanceInProgress(ctx: StateContext<ChannelPerformanceStateModel>, {payload}: SetChannelPerformanceInProgress) {
    const getState = ctx.getState();
    ctx.setState({...getState, inProgress: payload?.isFalse ? false : true});
  }

  @Action(SetTrafficModel)
  SetTrafficModel(ctx: StateContext<ChannelPerformanceStateModel>, { payload }: SetTrafficModel) {
    const getState = ctx.getState();
    const summaryParams = getState.summaryParams;
    ctx.setState({
      ...getState,
      summaryParams: {
        ...summaryParams,
        trafficModel: payload,
      }
    });
  }

  @Action(SetChannelPerformanceSummary)
  SetChannelPerformanceSummary(ctx: StateContext<ChannelPerformanceStateModel>, { payload }: SetChannelPerformanceSummary) {
    const getState = ctx.getState();
    const view: View = this.store.selectSnapshot( state => state.project.currentViewParams);
    const selectedState: SelectedStateModel = this.store.selectSnapshot(state => state.selected);
    const trafficModel = getState.summaryParams?.trafficModel ? getState.summaryParams.trafficModel : 'All';
    const prevSummaryParams = {
      dates: selectedState.chosenDates,
      attrModel: view?.attribution_model,
      attrType: capitalize(view?.attribution_type),
      trafficModel: trafficModel
    }
    if (payload.channel) {
      ctx.setState({
        ...getState,
        openChannelSummary: payload.data.report,
        openChannelSummaryIsLoading: false
      });
      this.store.dispatch(new SetCpOpenChannelId({channelId: payload.channel}));
    } else {
      ctx.setState({
        ...getState,
        summary: payload.report,
        summaryParams: prevSummaryParams,
        summaryIsLoading: false
      });
    }
  }

  @Action(SetChannelPerformanceComparison)
  SetChannelPerformanceComparison(ctx: StateContext<ChannelPerformanceStateModel>, { payload }: SetChannelPerformanceComparison) {
    const getState = ctx.getState();

    ctx.setState({
      ...getState,
      comparison: payload,
      comparisonIsLoading: false
    });
  }

  @Action(SetChannelPerformanceSummaryInProgress)
  SetChannelPerformanceSummaryInProgress(
    ctx: StateContext<ChannelPerformanceStateModel>,
    { payload }: SetChannelPerformanceSummaryInProgress) {
    const getState = ctx.getState();
    if (payload?.isFalse) {
      ctx.setState({...getState, openChannelSummaryIsLoading: false, summaryIsLoading: false});
    } else {
      if (payload.isSpecific) {
        ctx.setState({...getState, openChannelSummaryIsLoading: true});
      } else {
        ctx.setState({...getState, summaryIsLoading: true});
      }
    }
  }

  @Action(SetChannelPerformanceComparisonInProgress)
  SetChannelPerformanceComparisonInProgress(
    ctx: StateContext<ChannelPerformanceStateModel>, { payload }: SetChannelPerformanceComparisonInProgress) {
    const getState = ctx.getState();
    ctx.setState({...getState, comparisonIsLoading: payload?.isFalse ? false : true});
  }

  @Action(SetChannelPerformancePeriod)
  SetChannelPerformancePeriod(ctx: StateContext<ChannelPerformanceStateModel>, { payload }: SetChannelPerformancePeriod) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      report: null
    });
  }

  @Action(SetChannelPerformanceChannelSummaryToNull)
  SetChannelPerformanceChannelReportToNull(ctx: StateContext<ChannelPerformanceStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      openChannelSummary: null,
    });
  }

  @Action(ClearCP)
  ClearCP(ctx: StateContext<ChannelPerformanceStateModel>) {
    this.cpService.unsubscribeAndCancelReportGeneration();
    const getState = ctx.getState();
    const prevSummary = getState.summary;
    const prevSummaryParams = getState.summaryParams;
    ctx.setState({
      inProgress: false,
      summary: null,
      summaryParams: {trafficModel: prevSummary?.trafficModel},
      prevSummary: prevSummary,
      prevSummaryParams: prevSummaryParams,
      report: null,
      openChannelSummary: null,
      summaryIsLoading: false,
      comparison: null,
      comparisonIsLoading: false,
      openChannelSummaryIsLoading: false,
      totals: null,
    });
  }

  @Action(ClearCPWithoutSummary)
  ClearCPWithoutSummary(ctx: StateContext<ChannelPerformanceStateModel>) {
    this.cpService.unsubscribeAndCancelReportGeneration();
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      inProgress: false,
      report: null,
      openChannelSummary: null,
      openChannelSummaryIsLoading: false,
      totals: null,
      comparison: null
    });
  }

  @Action(ClearPrevSummary)
  ClearPrevSummary(ctx: StateContext<ChannelPerformanceStateModel>) {
    const getState = ctx.getState();
    ctx.setState({
      ...getState,
      prevSummary: null,
      prevSummaryParams: null,
    });
  }
}
