import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { ClearReports, SetRevenueGroup } from 'src/app/store/actions/selected.actions';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Component({
  selector: 'app-revenue-group-selector',
  templateUrl: './revenue-group-selector.component.html',
  styleUrls: ['./revenue-group-selector.component.scss']
})
export class RevenueGroupSelectorComponent implements OnInit {
  @Select(state => state.selected) selected$: Observable<SelectedStateModel>;
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  revenueGroups: Array<any> = [];
  selectedRevenueGroup: any;
  onlyShowForConversionEvent: boolean = false;

  constructor(private store: Store) { }

  ngOnInit(): void {

    combineLatest([this.project$, this.selected$]).subscribe(([project, selected]) => {
      if (project.project != null) {
        this.revenueGroups = project.project.settings.revenue_groups;
      }
      this.selectedRevenueGroup = selected.revenueGroup;
    });
  }

  selectRevenueGroup(group) {
    if (group != this.selectedRevenueGroup) {
      this.store.dispatch(new SetRevenueGroup({revenueGroup: group})).subscribe(x => {
        this.store.dispatch(new ClearReports());
      });
    }
  }
}
