import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetProjectName } from 'src/app/store/actions/project.actions';
import { ProjectStateModel } from 'src/app/store/states/project.state';

@Component({
  selector: 'app-rename-project',
  templateUrl: './rename-project.component.html',
  styleUrls: ['./rename-project.component.scss']
})
export class RenameProjectComponent {
  projectName;

  constructor(
    public activeModal: NgbActiveModal,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private store: Store
  ) { }

  createProject() {
    if (this.projectName.toUpperCase() != 'MY PROJECT') {
      this.projectService.setAttribute('name', this.projectName).subscribe(() => {
        this.store.dispatch(new SetProjectName(this.projectName));
        this.activeModal.close();
      });
    } else {
      setTimeout(() =>
        this.toastr.error(
          'Project name is not allowed to be "My Project".',
          `Project name is not allowed to be "My Project".`,
          {disableTimeOut: true}
        )
      );
    }
  }

}
