import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project } from 'src/app/core/models/project.model';
import { AccessService } from 'src/app/core/services/access/access.service';
import { ProjectApiTokenService } from 'src/app/core/services/project-api-token/project-api-token.service';
import { SetSettingSection } from 'src/app/store/actions/selected.actions';
import { SettingsStateModel } from 'src/app/store/states/settings.state';
import { Account } from 'src/app/core/models/account.model';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { ActivatedRoute } from '@angular/router';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { ComponentNames } from 'src/app/core/enums/component-names';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.settings) settings$: Observable<SettingsStateModel>;
  @Select(state => state.account) account$: Observable<Account>;

  account: Account;
  project: Project;
  emailForm: UntypedFormGroup;
  email: string;
  error;
  errorUpgrade;
  accesses;
  projectApiToken;

  componentNames = ComponentNames;
  settingsSections = SettingsSections;

  constructor(
    private fb: UntypedFormBuilder,
    private accessService: AccessService,
    private apiTokenService: ProjectApiTokenService,
    private store: Store,
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, `${ComponentNames.settings}/${SettingsSections.access}`);
    });
    this.store.dispatch(new SetSettingSection(SettingsSections.access));
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.project$.subscribe( x => {
      if (x.project) {
        this.project = x.project;
        this.getApiToken();
      }
    });
    this.accessService.get(true).subscribe( x => {
      this.accesses = x;
    });
    this.account$.subscribe(x => this.account = x);
  }

  getApiToken() {
    this.apiTokenService.get().subscribe( (x: any) => this.projectApiToken = x.api_token);
  }

  saveAccess() {
    this.error = null;
    if (this.emailForm.valid) {
      this.accessService.create(this.emailForm.get('email').value).subscribe( x => {
        this.emailForm.reset();
        this.accessService.get(true).subscribe( x => {
          this.accesses = x;
        });
      }, error => {
        this.error = error.error.error;
      });
    }
  }

  copyInputGroupValue() {
    this.copyToClipboard(this.projectApiToken);
  }

  delete(access) {
    this.accessService.delete(access.id).subscribe( x => {
      this.accesses = this.accesses.filter( y => y.id != access.id);
    })
  }

  copyToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position="fixed";  //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
    }

    document.body.removeChild(textArea);
  };

}
