import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includedInText'
})
export class IncludedInTextPipe implements PipeTransform {
  transform(word: any, text: string ): any {
    return text.includes(word);
  }
}
