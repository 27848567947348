import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/core/models/project.model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetIncludeOnlyUsersRegisteredInDaterange } from 'src/app/store/actions/project.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';

@Component({
  selector: 'app-only-users-in-daterange',
  templateUrl: './only-users-in-daterange.component.html',
  styleUrls: ['./only-users-in-daterange.component.scss']
})
export class OnlyUsersInDaterangeComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() showTitle = true;
  @Input() isInModal = false;
  @Input() disabled = false;

  project: Project;
  constructor(private projectService: ProjectService, private store: Store) { }

  ngOnInit(): void {
    this.project$.subscribe(x => this.project = x.project);
  }

  toggleIOURID(e) {
    if (this.disabled) return;
    this.projectService.setAttribute('include_only_users_registered_in_daterange', !this.project.include_only_users_registered_in_daterange).subscribe(() => {
      this.store.dispatch(new SetIncludeOnlyUsersRegisteredInDaterange(!this.project.include_only_users_registered_in_daterange));
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

}
