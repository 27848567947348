import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Observable, Subscription } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { SetSettingSection } from 'src/app/store/actions/selected.actions';
import { ActivatedRoute } from '@angular/router';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { ComponentNames } from 'src/app/core/enums/component-names';

@Component({
  selector: 'app-integration-instructions',
  templateUrl: './integration-instructions.component.html',
  styleUrls: ['./integration-instructions.component.scss'],
})
export class IntegrationInstructionsComponent implements OnInit, OnDestroy {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;

  project: Project;

  projectSub: Subscription;
  routeSub: Subscription;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService,
  ) { }

  ngOnInit() {
    this.routeSub = this.route.parent.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, `${ComponentNames.settings}/${SettingsSections.instructions}`);
    });
    this.store.dispatch(new SetSettingSection(SettingsSections.instructions));
    this.projectSub = this.project$.subscribe(project => this.project = project.project);
  }

  ngOnDestroy(): void {
    this.projectSub?.unsubscribe();
    this.routeSub?.unsubscribe();
  }

}
