import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import * as dragscroll from 'dragscroll';

import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';

@Component({
  selector: 'app-multi-line-chart',
  templateUrl: './multi-line-chart.component.html',
  styleUrls: ['./multi-line-chart.component.scss']
})
export class MultiLineChartComponent implements OnChanges, AfterViewInit {
  @Input() chartName;
  @Input() chartHeight;
  @Input() data: any[];
  @Input() isScrollable = true;
  @Input() disabledLines = [];
  @Input() chartMetric;

  tooltipName = 'multi-line';
  rendered = false;

  minBarWidth = 50;
  maxColumns = 20;

  colors = {
    first_time_customers: '#5bc28b',
    repeated_customers: '#1391a4',
    marketing_spend: '#e0734d',
    repeated_conversions: '#f9cf4c',
    first_time_conversions: '#fda44e'
  }

  dict = {
    'first_time_customers': 'First Time Customers',
    'repeated_customers': 'Repeated Customers',
    'marketing_spend': 'Marketing Spend',
    'repeated_conversions': 'Repeated Conversions',
    'first_time_conversions': 'First Time Conversions'
  };

  constructor(private tooltipService: TooltipService) { }

  ngOnChanges() {
    if (!this.rendered) { return; }
    if (this.data?.length > 0) this.drawChart();
  }

  ngAfterViewInit() {
    this.rendered = true;
    if (this.data?.length > 0) this.drawChart();
  }

  drawChart() {
    const chartElem = document.getElementById(this.chartName);

    if (!chartElem) {
      setTimeout(() => this.drawChart(), 1000);
      return;
    }

    // remove prone graph if any
    d3.select(`#${this.chartName}`).select('svg').remove();

    d3.select(`#${this.chartName}`).style("height", this.chartHeight + 'px');

    const wrapperProperties = document.getElementById(this.chartName).getBoundingClientRect();

    const margin = {top: 20, right: 10, bottom: 60, left: 60, fromLeftAxis: 10, scrollerHeight: 6};
    const height = this.chartHeight - margin.bottom - margin.top;
    const width = wrapperProperties.width - margin.left - margin.right - margin.fromLeftAxis;

    const columnsToShow = this.data.length >= this.maxColumns ? this.maxColumns : this.data.length;
    // width to fit bar and gap
    let barWidth = (wrapperProperties.width - margin.left - margin.fromLeftAxis - margin.right)  / columnsToShow;

    barWidth = barWidth < this.minBarWidth ? this.minBarWidth : barWidth;
    // all bars width
    const scaleWidth = barWidth * this.data.length;
    // make accessible to local scopes
    const isScrollable = (this.isScrollable && wrapperProperties.width < scaleWidth) ? true : false;

    const labels = this.data.map( x => x.period );

    const svg = d3.select(`#${this.chartName}`).append('svg')
      .attr('width', wrapperProperties.width)
      .attr('height', this.chartHeight - margin.scrollerHeight);

    if (isScrollable) {
      svg
        .attr('width', scaleWidth + margin.left)
        .classed('cursor-grab', true);
    }

    const x: any = d3.scaleOrdinal()
      .domain(labels)
      .range(this.data.map( (_, i) => barWidth * i));

    const filteredData = this.data.map((x, i) => {
      return {
        index: i,
        period: x.period,
        values: x.values.filter( y => this.chartMetric == 'Revenue' ? ['marketing_spend', 'repeated_customers', 'first_time_customers'].includes(y.name) : ['first_time_conversions', 'repeated_conversions'].includes(y.name))
      }
    });
    const max = d3.max(filteredData, d => Math.max(...d.values.map(x => x.value)));

    const y = d3.scaleLinear()
      .domain([0, max])
      .rangeRound([height, margin.top]);

    const formatCurrency = d3.format('-$,.0f');

    const line: any = d3.line()
      .x((d: any) => x(d.label))
      .y((d: any) => y(d.amount))
      .curve(d3.curveMonotoneX);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${margin.left + margin.fromLeftAxis + 20 + barWidth/2}, ${height + margin.top - 4})`)
      .call(
        d3.axisBottom(x)
          .tickSize(0)
          .tickValues(labels)
      )
      .call(gr => gr.select('.domain').remove())
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("transform", "rotate(-20)");

    svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .attr('class', 'y axis')
      .call(
        this.chartMetric == 'Revenue' ?
        d3.axisLeft(y)
          .tickSize(-scaleWidth)
          .tickPadding(10)
          .tickFormat(d => formatCurrency(d))
          .ticks(5, 's')
        :
        d3.axisLeft(y)
          .tickSize(-scaleWidth)
          .tickPadding(10)
          .ticks(5, 's')
      )
      .call( gr => gr.select('.domain').remove())
      .selectAll('line')
        .attr('stroke', '#eeeeee');

    const valueStructureExample = filteredData[0]?.values;

    if (valueStructureExample) valueStructureExample.forEach((_, i) => {
      const dataName = valueStructureExample[i].name;

      if (this.chartMetric == 'Revenue') {
        if (['first_time_conversions', 'repeated_conversions'].includes(dataName)) return;
      } else {
        if (['marketing_spend', 'repeated_customers', 'first_time_customers'].includes(dataName)) return;
      }

      if (this.disabledLines.includes(dataName)) return;

      const g = svg.append('g').attr('transform', `translate(${margin.left + margin.fromLeftAxis - 10}, 0)`);
          //.attr('transform', `translate(${margin.left + margin.fromLeftAxis - 10}, ${margin.top})`);

      const data = filteredData.map(x => {
        return {
          index: x.index,
          label: x.period,
          amount: x.values[i].value
        };
      });

      g.append('path')
        .attr('transform', `translate(${barWidth/2},0)`)
        .attr('class', 'line')
        .attr('fill', 'none')
        .attr('stroke', this.colors[valueStructureExample[i].name])
        .attr('stroke-width', '3px')
        .attr('d', line(data));

      g.selectAll(".dot")
        .data(data)
      .enter().append("circle")
        .attr('transform', `translate(${barWidth/2},0)`) // Uses the enter().append() method
        .attr("class", "line-chart-dot") // Assign a class for styling
        .attr("cx", (d) => x(d.label))
        .attr("cy", (d) => y(d.amount))
        .attr('fill', this.colors[valueStructureExample[i].name])
        .attr('stroke', 'white')
        .attr("r", 5)
          .on('mouseover', (e, d: any) => {
            this.tooltipService.mouseOver.emit({
              name: this.tooltipName,
              data: { data: this.data[d.index] }
            });
          })
          .on('mouseout', () => this.tooltipService.mouseOut.emit());
    });

    svg.append('g')
      .attr('transform', `translate(${margin.left + margin.fromLeftAxis - 10},0)`)
      .selectAll('rect')
      .data(this.data)
      .enter().append('rect')
        .attr('fill', 'transparent')
        .attr('width', barWidth)
        .attr('x', (d: any) => {
          return x(d.period);
        })
        .attr('y', d => y(0))
        .attr('height', d => 60)
          .on('mouseover', (e, d: any) => {
              this.tooltipService.mouseOver.emit({
                name: this.tooltipName,
                data: { data: d }
              });
          })
          .on('mouseout', () => this.tooltipService.mouseOut.emit());

    dragscroll.reset();
  }

}
