import { Component,  } from '@angular/core';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';

@Component({
  selector: 'app-oauth-row',
  templateUrl: './oauth-row.component.html',
  styleUrls: ['./oauth-row.component.scss']
})
export class OauthRowComponent {
  componentNames = ComponentNames;
  settingsSections = SettingsSections;

  constructor() { }

}
