import { Project, View } from 'src/app/core/models/project.model';

export class SetProject {
    static readonly type = '[project] SetProject';
    constructor(public payload: Project) {}
}

export class JustSetProject {
    static readonly type = '[project] JustSetProject';
    constructor(public payload: Project) {}
}

export class SetProjectIsLoading {
    static readonly type = '[project] SetProjectIsLoading';
}

export class UpdateProjectInfo {
    static readonly type = '[project] UpdateProjectInfo';
    constructor(public payload: Project) {}
}
export class UpdateAttributionModel {
    static readonly type = '[project] UpdateAttributionModel';
    constructor(public payload: string) {}
}

export class UpdateConversionEvent {
    static readonly type = '[project] UpdateConversionEvent';
    constructor(public payload: string) {}
}

export class UpdateViewProperty {
    static readonly type = '[project] UpdateViewProperty';
    constructor(public payload?: string) {}
}

export class UpdateViewPropertyValue {
    static readonly type = '[project] UpdateViewPropertyValue';
    constructor(public payload?: string) {}
}

export class SetProjectName {
    static readonly type = '[project] SetProjectName';
    constructor(public payload: string) {}
}

export class SetAggregationPeriod {
    static readonly type = '[project] SetAggregationPeriod';
    constructor(public payload: string) {}
}

export class SetTrafficOption {
    static readonly type = '[project] SetTrafficOption';
    constructor(public payload: string) {}
}

export class SetCutoffEvent {
    static readonly type = '[project] SetCutoffEvent';
    constructor(public payload: string) {}
}

export class SetDisregardExistingUsers {
    static readonly type = '[project] SetDisregardExistingUsers';
    constructor(public payload: string) {}
}

export class SetIncludeOnlyUsersRegisteredInDaterange {
    static readonly type = '[project] SetIncludeOnlyUsersRegisteredInDaterange';
    constructor(public payload: boolean) {}
}

export class SetCompanyBasedAttribution {
    static readonly type = '[project] SetCompanyBasedAttribution';
    constructor(public payload: boolean) {}
}

export class SetPBR {
    static readonly type = '[project] SetPBR';
    constructor(public payload: string) {}
}

export class SetCurrencySymbol {
    static readonly type = '[project] SetCurrencySymbol';
    constructor(public payload: string) {}
}

export class SetCurrency {
  static readonly type = '[project] SetCurrency';
  constructor(public payload: string) {}
}

export class SetTimezone {
    static readonly type = '[project] SetTimezone';
    constructor(public payload: string) {}
}

export class SetProjectSettings {
    static readonly type = '[project] SetProjectSettings';
    constructor(public payload: any) {}
}

export class SetAttributionType {
    static readonly type = '[project] SetAttributionType';
    constructor(public payload: string) {}
}

export class SetTimeframeAttributionType {
    static readonly type = '[project] SetTimeframeAttributionType';
    constructor(public payload: string) {}
}

export class SetLookbackWindow {
    static readonly type = '[project] SetLookbackWindow';
    constructor(public payload: number) {}
}

export class SetProjectNextIdentifier {
    static readonly type = '[project] SetProjectNextIdentifier';
    constructor(public payload: string) {}
}

export class SetProjectOptions {
    static readonly type = '[project] SetProjectOptions';
    constructor(public payload: any) {}
}

export class ClearOauthErrors {
    static readonly type = '[project] ClearOauthErrors';
    constructor(public oauth_id: number) {}
}

export class ClearProject {
    static readonly type = '[project] ClearProject';
}

export class CreateNewProjectView {
    static readonly type = '[project] CreateNewProjectView';
    constructor(public payload: any) {}
}

export class UpdateProjectView {
    static readonly type = '[project] UpdateProjectView';
    constructor(public payload: any) {}
}

export class DeleteProjectView {
    static readonly type = '[project] DeleteProjectView';
    constructor(public payload: number) {}
}

export class SetCurrentViewId {
    static readonly type = '[project] SetCurrentViewId';
    constructor(public payload: number) {}
}

export class SetAttributesFromView {
    static readonly type = '[project] SetAttributesFromView';
    constructor(public payload: View) {}
}

export class SetCurrentViewParams {
    static readonly type = '[project] SetCurrentViewParams';
    constructor(public payload: View) {}
}

export class SetViewRevenueGroupId {
    static readonly type = '[project] SetViewRevenueGroupId';
    constructor(public payload: number) {}
}

export class SetViewDaterange {
    static readonly type = '[project] SetViewDaterange';
    constructor(public payload: string) {}
}

export class HideFilter {
    static readonly type = '[project] HideFilter';
    constructor(public payload: number) {}
}

export class HideChannel {
    static readonly type = '[project] HideChannel';
    constructor(public payload: {filters: number[], groups: number[]}) {}
}

export class UnhideFilterChannels {
    static readonly type = '[project] UnhideFilterChannels';
}
