import { Component, OnChanges, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { ReportStateModel, ReportState } from 'src/app/store/states/report.state';
import { SidebarSetName } from 'src/app/store/actions/only-simple.actions';
import { PropertiesStateModel, PropertiesState } from '../../../../store/states/properties.state';

@Component({
  selector: 'app-sidebar-title',
  templateUrl: './sidebar-title.component.html',
  styleUrls: ['./sidebar-title.component.scss']
})
export class SidebarTitleComponent implements OnChanges, OnInit {
  @Input() title;
  @Input() name;
  @Input() type;
  @Input() filterId;
  @Input() filterVfilters;
  @Input() inProgress;
  @Input() isEmpty;
  @Input() isVirtual;
  @Input() filterTree: any[];
  @Input() periodStart;
  @Input() periodEnd;

  @Select(ReportState) report$: Observable<ReportStateModel>;
  @Select(PropertiesState) propertiesState$: Observable<PropertiesStateModel>;

  properties: PropertiesStateModel;
  uniqueVisitors;
  virtualFilters = [];

  constructor(
    private store: Store
  ) { }

  ngOnInit() {
    this.propertiesState$.subscribe( resp => {
      this.properties = resp;
    });
  }

  ngOnChanges() {
    if (this.filterTree) {
      this.store.dispatch(new SidebarSetName({name: this.name}));
      this.uniqueVisitors = null;
      this.virtualFilters = [];
      let type: string;
      if (this.type === 'f') {
        type = 'filters';
      } else if (this.type === 'g') {
        type = 'groups';
      }
      const allVFilters = this.filterTree.filter( x => x.t == 'f' && x.s == 'virtual');
      if (this.filterVfilters) {
        allVFilters.forEach( x => {
          if (this.filterVfilters.includes(x.id)) {
            this.virtualFilters.push(x.n);
          }
        });
      }
      if (this.title === 'visits') {
        this.report$.subscribe( state => {
          if (state.report) {
            if (this.filterId) {
              if (state.report[type][this.filterId]) {
                this.uniqueVisitors = state.report[type][this.filterId][state.meta.mapping.visitors];
              }
            } else {
              this.uniqueVisitors = state.report?.other?.all[state.meta.mapping.visitors];
            }
          }
        });
      }
    }
  }

}
