<h4>
    <span class="me-3">STATUS:</span>
    <span [ngClass]="{'green': status === 'ready', orange: status === 'scheduled', red: status === 'failed'}">{{ status }}</span>
</h4>
<label for="csvFile">
    <span class="btn btn-default cursor-pointer">
        <i class="fa-solid fa-upload blue"></i>
        Upload CSV
    </span>
</label>
<input data-file name="csvFile" type="file" id="csvFile" style="position: fixed; top: -100em;" ng-model="csvFile" (change)="onUpload($event.target.files)">
<br>
<div>
    <a class="small" href="{{ assetPath }}/assets/influences-sample.csv" target="_blank"> Sample file</a>
</div>
<div>
    <a class="small" href="{{ assetPath }}/assets/influences-sample2.csv" target="_blank"> Sample file 2</a>
</div>
<div class="mt-3 mb-3 green" *ngIf="uploadedSuccessfully">
    Influences uploaded successfully, please refresh to check status.
</div>
<div class="mt-3 mb-3" *ngIf="uploadError">
    <small class="red d-block">Error: "{{ uploadError }}" on line {{ errorLineNumber }}:</small>
    <em class="red d-block">{{ errorLine }}</em>
</div>
<div>
    <button class="btn btn-success" [class.disabled]="uploadSuccessful !== true" (click)="refreshStatus()">
        <i class="fa-solid fa-rotate me-2"></i> Refresh Status
    </button>
</div>
