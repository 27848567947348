import { NewChannelresponse } from 'src/app/core/services/filter/filter-service.models';

export class SetFilterTree {
    static readonly type = '[filterTree] SetFilterTree';
    constructor(public payload) {}
}

export class FilterTreeSetOrinals {
    static readonly type = '[filterTree] FilterTreeSetOrinals';
    constructor(public payload: any[]) {}
}

export class ReArrangeFilters {
    static readonly type = '[filterTree] ReArrangeFilters';
    constructor(public payload: any) {}
}

export class ShowChannelZeros {
    static readonly type = '[filterTree] ShowChannelZeros';
    constructor(public payload: number) {}
}

export class ToggleExpand {
    static readonly type = '[filterTree] ToggleExpand';
    constructor(public payload: { id: number; isExpanded: boolean; isVirtual?: boolean; }) {}
}

export class ExpandUpTheTree {
    static readonly type = '[filterTree] ExpandUpTheTree';
    constructor(public payload: { id: number; }) {}
}

export class SetFTInProgress {
    static readonly type = '[filterTree] SetInFTProgress';
    constructor(public payload: boolean) {}
}

export class ClearFilterTree {
    static readonly type = '[filterTree] ClearFilterTree';
}

export class AddFilterChannel {
    static readonly type = '[filterTree] AddFilterChannel';
    constructor(public payload: { channel: NewChannelresponse, parentFilterGroupId?: number }) {}
}

export class RenameChannel {
    static readonly type = '[filterTree] RenameChannel';
    constructor(public payload: {id: number, n: string}) {}
}

export class DeleteChannel {
    static readonly type = '[filterTree] DeleteChannel';
    constructor(public payload: {id: any, multi?: boolean}) {}
}

export class AddFilter {
    static readonly type = '[filterTree] AddFilter';
    constructor(public payload: {filter?: {id: number, n: string, [propName: string]: any;}, filters?: any, parentFilterGroupId?: number}) {}
}

export class EditFilter {
    static readonly type = '[filterTree] EditFilter';
    constructor(public payload: {id: number, n: string}) {}
}

export class ClearFilterErrors {
    static readonly type = '[filterTree] ClearFilterErrors';
    constructor(public payload: any) {}
}

export class DeleteFilter {
    static readonly type = '[filterTree] DeleteFilter';
    constructor(public payload: {id: any, multi?: boolean}) {}
}

export class AddOrRemoveVirtualFilter {
    static readonly type = '[filterTree] AddOrRemoveVirtualFilter';
    constructor(public payload: {action: string; item: any; virtualFilterId: number;}) {}
}

export class EditVirtualFilter {
    static readonly type = '[filterTree] EditVirtualFilter';
    constructor(public payload: {filters: Array<any>; groups: Array<any>; virtualFilterId: number;}) {}
}

export class MoveFiltersAndChannels {
    static readonly type = '[filterTree] MoveFiltersAndChannels';
    constructor(public payload: {filterIds: Array<number>; groupIds: Array<number>; destinationId: number;}) {}
}

export class ScheduleTreeRefresh {
    static readonly type = '[filterTree] ScheduleTreeRefresh';
}