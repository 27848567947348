import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Project } from 'src/app/core/models/project.model';
import { IntegrationsService } from 'src/app/core/services/integrations/integrations.service';
import { ProjectService } from 'src/app/core/services/project/project.service';

@Component({
  selector: 'app-shopify-settings',
  templateUrl: './shopify-settings.component.html',
  styleUrl: './shopify-settings.component.scss'
})
export class ShopifySettingsComponent implements OnInit {
  @Select(state => state.project.project) project$;
  integrationName = 'shopify';
  oauth: any;
  project: Project;
  settings = [];

  constructor(private integrationsService: IntegrationsService, private projectService: ProjectService){}

  ngOnInit(): void {
    this.project$.subscribe(project => {
      this.project = project.project;
      this.oauth = project?.oauths?.find(o => o.type === this.integrationName);
      if (this.oauth) {
        this.settings = [
          {
            name: "Track Gross Revenue on Order creation",
            type: 'boolean',
            field: 'track_gross_revenue_on_creation',
            value: this.oauth?.settings?.track_gross_revenue_on_creation || false
          },
          {
            name: 'User Id Source',
            type: 'string',
            field: 'user_id_source',
            value: this.oauth?.settings?.user_id_source || 'none',
            options_for_select: ['none', 'shopify_customer_id', 'email']
          }
        ];
      }
    });
  }

  updateSetting(setting) {
    this.integrationsService.updateOauth(this.oauth.id, {[setting.field]: setting.value}).subscribe( x => {
      this.oauth = x;
      this.projectService.getProjectInfo(true).subscribe();
    });
  };

  toggleSetting(setting) {
    setting.value = !setting.value;
    this.updateSetting(setting);
  }
}
