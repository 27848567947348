import { Component, OnInit, Input } from '@angular/core';
import { FilterErrorService } from 'src/app/core/services/filter-error/filter-error.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { find, has } from 'lodash-es';
import { Integration } from 'src/app/core/constants/integration';

@Component({
  selector: 'app-filter-errors-modal',
  templateUrl: './filter-errors-modal.component.html',
  styleUrls: ['./filter-errors-modal.component.scss']
})
export class FilterErrorsModalComponent implements OnInit {
  @Input() item?: any;
  @Input() type?: string;
  @Input() oauth?;
  errorObjectName: string;
  groupedErrors;
  integrationsAreLoading;
  selectedGroup;
  requestParams: any = {};

  objectKeys = Object.keys;

  constructor(
    private ieService: FilterErrorService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.integrationsAreLoading = true;
    if (this.item) {
      this.errorObjectName = this.item.n;
      if (this.item.t == 'f') {
        this.requestParams.filter_id = this.item.id;
      } else if (this.item.t == 'g') {
        this.requestParams.filter_group_id = this.item.id;
      }
    } else if (this.oauth) {
      const type = this.oauth.type;
      this.errorObjectName = find(Integration.get.names, function(names) {
        return has(names, type);
      })[this.oauth.type];
      this.requestParams.oauth_id = this.oauth.id;
    }

    this.ieService.get(this.requestParams, this.type).subscribe( resp => {
      this.groupedErrors = resp;
      this.integrationsAreLoading = false;
    });
  }

  toggleSelected(group) {
    this.selectedGroup = this.isSelected(group) ? undefined : group;
  }

  isSelected(group) {
    return this.selectedGroup === group;
  }

  clearErrors() {
    if (
      confirm("Are you sure you want clear errors? Attribution will try to tag active ads again that previously had error, so these errors might show up again.")
      ) {
      this.ieService.clearErrors(this.requestParams, this.oauth ? 'oauth' : this.type).subscribe( () => {
        this.activeModal.close({result: 'Integration errors cleared'});
      });
    }
  }

  switchTabTo(type) {
    if (type != this.type) {
      this.activeModal.close({ type: type, item: this.item})
    }
  }

}
