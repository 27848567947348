import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysHoursFromSeconds'
})
export class DaysHoursFromSecondsPipe implements PipeTransform {

  transform(seconds): unknown {
    let duration = '';
    const days = Math.floor(seconds / 86400);
    if (days > 0) duration += `${days} days`;
    const hours = Math.floor((seconds % 86400) / 3600);
    if (days > 0 && hours > 0) duration += `, ${hours} hours`;
    else if (hours > 0) duration += `${hours} hours`;
    if (duration.length == 0 && seconds > 60) duration = `${Math.floor(seconds/60)} minutes`;
    else if (duration.length == 0) duration = `${seconds} seconds`;
    return duration;
  }

}
