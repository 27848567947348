import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Integration, IntegrationTypes } from 'src/app/core/constants/integration';

@Component({
  selector: 'app-integration-logo',
  templateUrl: './integration-logo.component.html',
  styleUrls: ['./integration-logo.component.scss']
})
export class IntegrationLogoComponent implements OnChanges {
  @Input() integrationName: string;
  @Input() isShow = false;
  @Input() isTransformer = false

  nonDefaultLogos = Integration.get.NonDefaultLogos;
  integrationType: string;
  integrationTypes = IntegrationTypes;

  ngOnChanges(changes: SimpleChanges): void {
    this.integrationType = Integration.get.config[this.integrationName]?.type;
  }

  get label() {
    return this.integrationName.replaceAll('_', ' ');
  } 
}
