<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload Filters CSV</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <label for="csvFile">
        <span class="btn btn-default cursor-pointer">
            <i class="fa-solid fa-upload blue"></i>
            Upload CSV
        </span>
    </label>
    <input data-file name="csvFile" type="file" id="csvFile" style="position: fixed; top: -100em;" ng-model="csvFile" (change)="onUpload($event.target.files)">
    <br>
    <a class="small" href="{{ assetPath }}/assets/filter-uploads-sample.csv" target="_blank"> Sample file</a>
    <div class="mt-3 mb-3 green" *ngIf="uploadedSuccessfully">
        Filters uploaded successfully, please refresh Dashboard.
    </div>
    <div class="mt-3 mb-3" *ngIf="uploadError">
        <small class="red d-block">Error: "{{ uploadError }}" on line {{ errorLineNumber }}:</small>
        <em class="red d-block">{{ errorLine }}</em>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success" [class.disabled]="uploadSuccessful !== true" (click)="refresh()">
        <i class="fa-solid fa-rotate me-2"></i> Refresh Dashboard
    </button>
</div>