export class PathsLabelsChanged {
  static readonly type = '[pathsState] PathsLabelsChanged';
  constructor(public payload: string) {}
}

export class SetCombineRecurring {
  static readonly type = '[pathsState] SetCombineRecurring';
  constructor(public payload: boolean) {}
}

export class SetPathFilterType {
  static readonly type = '[pathsState] SetPathFilterType';
  constructor(public payload: string) {}
}

export class SetPathFilterOrGroup {
  static readonly type = '[pathsState] SetPathFilterOrGroup';
  constructor(public payload: {
    id: number,
    type: string,
    name: string,
  } | null) {}
}

export class SetPathFilter {
  static readonly type = '[pathsState] SetPathFilter';
  constructor(public payload: string) {}
}

export class SetPathReportInProgress {
  static readonly type = '[pathsState] SetPathReportInProgress';
  constructor(public payload: boolean) {}
}

export class SetPathReportAdditionInProgress {
  static readonly type = '[pathsState] SetPathReportAdditionInProgress';
  constructor(public payload: boolean) {}
}

export class SetPathReport {
  static readonly type = '[pathsState] SetPathReport';
  constructor(public payload: any) {}
}

export class ClearPathReport{
  static readonly type = '[pathsState] ClearPathReport';
}