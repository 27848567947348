import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  get(searchQuery, since?) {
    const params: any = new URLSearchParams();

    if (searchQuery) { params.append('q', searchQuery); }

    if (since) { params.append('since', since); }

    const Url = `${environment.reportingApiUrl}/v2/visitors/search?${params.toString()}`;

    return this.httpClient.get(Url);
  }

}
