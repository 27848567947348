import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetFilterWorkerInProgress, SetGroupWorkerInProgress } from '../actions/worker.actions';

export interface WorkerStateModel {
    filterWorkerInProgress: boolean;
    groupWorkerInProgress: boolean;
}

@State<WorkerStateModel>({
  name: 'worker',
  defaults: {
    filterWorkerInProgress: false,
    groupWorkerInProgress: false
  }
})
@Injectable()
export class WorkerState {
    @Action(SetFilterWorkerInProgress)
    SetFilterWorkerInProgress(ctx: StateContext<WorkerStateModel>, { payload }: SetFilterWorkerInProgress) {
        const getState = ctx.getState();
        ctx.setState({
            ...getState,
            filterWorkerInProgress: payload
        });
    }

    @Action(SetGroupWorkerInProgress)
    SetGroupWorkerInProgress(ctx: StateContext<WorkerStateModel>, { payload }: SetGroupWorkerInProgress) {
        const getState = ctx.getState();
        ctx.setState({
            ...getState,
            groupWorkerInProgress: payload
        });
    }

}