import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnChanges {
  @Input() style = 'old';
  @Input() status;
  @Input() isLoaded = false;
  color: string;
  icon: string;

  constructor() { }

  ngOnChanges(): void {
    if (!this.isLoaded) {
      this.color = 'medium-dark';
      this.icon =  'fa-regular fa-clock';
    } else if (this.status) {
      this.color = 'green';
      this.icon = this.style === 'old' ? 'fa-solid fa-check' : ' fa-solid fa-circle-check';
    } else {
      this.color = 'red';
      this.icon = this.style === 'old' ? 'fa-solid fa-xmark' : 'fa-solid fa-circle-xmark';
    }
  }

}
