<app-loader
    *ngIf="projectIsLoading"
    [backgroundColor]="'rgba(0, 0, 0, 0.75)'"></app-loader>

@if (project | isShopifySegment:account) {
  <div class="pt-40 d-flex h-100 justify-content-between flex-column">
    <div>
      <ng-container *ngTemplateOutlet="overview"></ng-container>
      <ng-container *ngTemplateOutlet="eventsStream"></ng-container>
      <ng-container *ngTemplateOutlet="team"></ng-container>
      <ng-container *ngTemplateOutlet="shopify"></ng-container>
      <ng-container *ngTemplateOutlet="segment"></ng-container>
    </div>
    <div class="logout-button ps-2 pb-5">
      <div class="clickable em-1_2" (click)="logout()">
        <i class="fa-solid fa-right-from-bracket me-2"></i>
        LogOut
      </div>
    </div>
  </div>
} @else {
  <h6 class="settings-nav__title">
    Project Settings
  </h6>
  <ng-container *ngTemplateOutlet="overview"></ng-container>
  <ng-container *ngTemplateOutlet="integrationInstructions"></ng-container>
  <ng-container *ngTemplateOutlet="eventsStream"></ng-container>
  <ng-container *ngTemplateOutlet="team"></ng-container>
  <ng-container *ngTemplateOutlet="projectSettings"></ng-container>
  <ng-container *ngTemplateOutlet="adIntegrations"></ng-container>
  <ng-container *ngTemplateOutlet="conversionIntegrations"></ng-container>
  <ng-container *ngTemplateOutlet="transformerIntegrations"></ng-container>
  <ng-container *ngTemplateOutlet="projectNotifications"></ng-container>
  <ng-container *ngTemplateOutlet="gdpr"></ng-container>
  <h6 class="settings-nav__title">
    Account Settings
  </h6>
  <ng-container *ngTemplateOutlet="billing"></ng-container>
  <ng-container *ngTemplateOutlet="paymentHistory"></ng-container>
  @if (account.projects.length > 1) {
    <ng-container *ngTemplateOutlet="accountNotifications"></ng-container>
  }
  <ng-container *ngTemplateOutlet="password"></ng-container>
  <div class="mt-5 mb-5">
    <a href="{{documentationLink}}" class="btn btn-lg caps settings__docs__button">
      Documentation
    </a>
  </div>
}

<ng-template #overview>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.overview]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-home"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Overview</p>
      <p class="item-subtitle">Data collection status</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #integrationInstructions>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.instructions]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-regular fa-fw fa-file-code"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Setup Instructions</p>
      <p class="item-subtitle">Track your events and users</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #eventsStream>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.liveDebug]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-code"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Events Stream</p>
      <p class="item-subtitle">Live pageviews and events</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #team>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.access]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-user-group"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Team</p>
      <p class="item-subtitle">Invite your team</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #projectSettings>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.projectSettings]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-gear"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Project Settings</p>
      <p class="item-subtitle">Customize attribution models</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #adIntegrations>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.integrations]"  [queryParams]="{type: 'ads'}" class="settings-nav__item" [class.active]="shownIntegrationType == integrationTypes.integration">
    <div class="settings-nav__icon" [class.active]="shownIntegrationType == integrationTypes.integration">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-right-left"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Ad Integrations</p>
      <p class="item-subtitle">Track campaigns and spend</p>
      <i class="fa-solid fa-chevron-right settings-nav__active" [class.active]="shownIntegrationType == integrationTypes.integration"></i>
    </div>
  </div>
</ng-template>

<ng-template #conversionIntegrations>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.integrations]" [queryParams]="{type: 'conversions'}" class="settings-nav__item" [class.active]="shownIntegrationType == integrationTypes.connector">
    <div class="settings-nav__icon" [class.active]="shownIntegrationType == integrationTypes.connector">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-money-bill-alt"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Conversion Integrations</p>
      <p class="item-subtitle">Connect CRMs and CDPs</p>
      <i class="fa-solid fa-chevron-right settings-nav__active" [class.active]="shownIntegrationType == integrationTypes.connector"></i>
    </div>
  </div>
</ng-template>

<ng-template #transformerIntegrations>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.integrations]" [queryParams]="{type: 'transformers'}" class="settings-nav__item" [class.active]="shownIntegrationType == 'transformers'">
    <div class="settings-nav__icon" [class.active]="shownIntegrationType == 'transformers'">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-shuffle fa-fw"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Transformers</p>
      <p class="item-subtitle">Enhance data quality</p>
      <i class="fa-solid fa-chevron-right settings-nav__active" [class.active]="shownIntegrationType == 'transformers'"></i>
    </div>
  </div>
</ng-template>

<ng-template #projectNotifications>
  <div *ngIf="account && project && account.id == project.owner.id" [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.weeklyEmails]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-regular fa-fw fa-envelope"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Notifications</p>
      <p class="item-subtitle">Weekly summary emails</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #gdpr>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.gdprRules]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-clipboard-list"></i>
      </div>
    </div>
    <div>
      <p class="item-title">GDPR Regulations</p>
      <p class="item-subtitle">Delete user personal information</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #billing>
  <div [routerLink]="['/', componentNames.account, settingsSections.billing]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-regular fa-fw fa-credit-card"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Billing Information</p>
      <p class="item-subtitle">Credit card and subscription details</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #paymentHistory>
  <div [routerLink]="['/', componentNames.account, settingsSections.paymentHistory]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-file-lines"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Payment History</p>
      <p class="item-subtitle">Invoices and usage</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #password>
  <div [routerLink]="['/', componentNames.account, settingsSections.password]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-key"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Password</p>
      <p class="item-subtitle">Change password</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #accountNotifications>
  <div [routerLink]="['/', componentNames.account, settingsSections.notifications]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-solid fa-fw fa-bell"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Account Notifications</p>
      <p class="item-subtitle">Weekly emails from all projects</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #shopify>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.shopify]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <i class="fa-brands fa-fw fa-shopify"></i>
      </div>
    </div>
    <div>
      <p class="item-title">Shopify Data Settings</p>
      <p class="item-subtitle">Currency, UserId, Revenue Events</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>

<ng-template #segment>
  <div [routerLink]="['/', currentIdentifier, componentNames.settings, settingsSections.segment]" routerLinkActive="active" class="settings-nav__item">
    <div routerLinkActive="active" class="settings-nav__icon">
      <div class="settings-nav__icon-wrapper">
        <div routerLinkActive="active" class="segment-icon" [ngStyle]="{ 'maskImage': 'url('+assetPath+'/assets/images/segment.svg'+')', 'WebkitMaskImage': 'url('+assetPath+'/assets/images/segment.svg'+')' }"></div>
      </div>
    </div>
    <div>
      <p class="item-title">Segment Connection</p>
      <p class="item-subtitle">Manage Segment Connection</p>
      <i routerLinkActive="active" class="fa-solid fa-chevron-right settings-nav__active"></i>
    </div>
  </div>
</ng-template>
