import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { AggregationPeriods, TimeframePeriods } from 'src/app/core/constants/aggregation-periods';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { SelectedStateModel, SelectedState } from 'src/app/store/states/selected.state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ProjectState } from 'src/app/store/index.state';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { BaseService } from 'src/app/core/services/base/base.service';
import { ToastrService } from 'ngx-toastr';
import { ComponentNames } from 'src/app/core/enums/component-names';

@Component({
  selector: 'aggregation-period-select',
  templateUrl: './aggregation-period-select.component.html',
  styleUrls: ['./aggregation-period-select.component.scss']
})
export class AggregationPeriodSelectComponent implements OnChanges, OnInit, OnDestroy {
  @Select(SelectedState) selectedState$: Observable<SelectedStateModel>;
  @Select(ProjectState) projectState$: Observable<ProjectStateModel>;
  hideSelect = true;
  aggregationPeriods = AggregationPeriods;
  availablePeriods = AggregationPeriods;
  selectedAggregationPeriod;
  sub: Subscription;
  routeSub: Subscription;
  unavailablePeriods = [];

  @Input() for;
  @Input() isTimeframe = false;
  @Input() defaultAggregationPeriod = null;
  @Input() disableds = [];
  @Output() valueChange = new EventEmitter();

  constructor(private route: ActivatedRoute, private baseService: BaseService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.sub = combineLatest([this.selectedState$, this.projectState$]).subscribe( ([selectedState, projectState]) => {
      this.aggregationPeriods = AggregationPeriods;
      if (selectedState.currentComponent == ComponentNames.cohort) {
        this.aggregationPeriods = [...this.aggregationPeriods, 'quarter'];
      }
      
      if (this.defaultAggregationPeriod == null) this.selectedAggregationPeriod = projectState.currentViewParams?.aggregation_period || 'Day';

      const periods = this.baseService.getAggregationPeriods(selectedState, projectState);
      this.availablePeriods = periods.aggrPeriods;
      if (!this.availablePeriods.includes(this.selectedAggregationPeriod)) {
        this.selectedAggregationPeriod = this.availablePeriods[0];
      }
      this.unavailablePeriods = this.aggregationPeriods.filter( x => !this.availablePeriods.includes(x));
    });
    this.routeSub = this.route.queryParams.subscribe( queryParams => {
      if (queryParams.period && this.availablePeriods.includes(queryParams.period)) {
        this.selectedAggregationPeriod = queryParams.period;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disableds.length > 0) {
      this.aggregationPeriods = this.aggregationPeriods.filter( x => !this.disableds.includes(x));
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.routeSub?.unsubscribe();
  }

  selectPeriod(period) {
    this.selectedAggregationPeriod = period;
    this.valueChange.emit(this.selectedAggregationPeriod);
  }

  closeSelect(btn) {
    this.hideSelect = !this.hideSelect;
    btn.classList.remove('hide');
  }

}
