import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SegmentService } from 'ngx-segment-analytics';
import { ToastrService } from 'ngx-toastr';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { LiveDebugService } from 'src/app/core/services/live-debug/live-debug.service';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { UrlCheckerService } from 'src/app/core/services/url-checker/url-checker.service';
import { SetSettingSection } from 'src/app/store/actions/selected.actions';

@Component({
  selector: 'app-live-debug',
  templateUrl: './live-debug.component.html',
  styleUrls: ['./live-debug.component.scss']
})
export class LiveDebugComponent implements OnInit, OnDestroy {
  debugData: any = [];
  queue = [];
  lastAdditionTime;
  raw = null;
  debugStarted = false;
  debugSub: any;
  maxRows = 1000;
  itemIndex = 0;
  selectedItemIndex;
  closesAt;
  timer;
  queueInterval;
  intervalMiliseconds = 15;
  socketKey;
  socketWS;
  liveKeeper;
  socket;

  selectedAnonymousId;

  constructor(
    private route: ActivatedRoute,
    private urlChecker: UrlCheckerService,
    private projectService: ProjectService,
    private service: LiveDebugService,
    private toastr: ToastrService,
    private store: Store,
    private segment: SegmentService
  ) { }

  ngOnInit(): void {
    this.lastAdditionTime = Date.now();

    this.route.parent.params.subscribe( params => {
      if (this.route.snapshot.fragment) return;
      this.urlChecker.checkUrl(params.project_identifier, `${ComponentNames.settings}/${SettingsSections.liveDebug}`);
    });

    this.store.dispatch(new SetSettingSection(SettingsSections.liveDebug));

    this.queueInterval = setInterval(() => {
      if (this.queue.length > 0) {
        this.debugData.push(this.queue[0]);
        this.queue.shift();
        this.lastAdditionTime = Date.now();
      }
    }, this.intervalMiliseconds);

    this.liveKeeper = setInterval(() => {
      if (this.socket) {
        this.socket.next('Keep Alive');
      }
    }, 30*1000);
  }

  ngOnDestroy() {
    if (this.debugSub) this.debugSub.unsubscribe();
    this.projectService.disableLiveDebug().subscribe();
    if (this.timer) clearInterval(this.timer);
    if (this.queueInterval) clearInterval(this.queueInterval);
    clearInterval(this.liveKeeper);
  }

  toggleDebug() {
    if (this.debugStarted) {
      this.socketKey = null;
      this.socketWS = null;
      this.closesAt = null;
      this.debugSub.unsubscribe();
      this.projectService.disableLiveDebug().subscribe();
      if (this.timer) clearInterval(this.timer);
      this.queue = [];
      this.debugData.push({ value: {event: 'Disconnected from Live-Debug stream', type: 'System', sysMsg: true }});
      this.socket = null;
    } else {
      this.startDebug();
    }
  }

  startDebug(isTimedCall?) {
    const checkQueueAndAdd = (item) => {
      if (this.queue.length == 0 && (Date.now() - this.lastAdditionTime) > this.intervalMiliseconds) {
        this.debugData.push(item);
        this.lastAdditionTime = Date.now();
      } else {
        this.queue.push(item);
      }
    }

    const debugFlow = (x, isTimedCall?) => {
      this.socket = this.service.openAndListen(x.ws);
      this.socketWS = x.ws;
      this.debugSub = this.socket.multiplex(() => {
        // checkQueueAndAdd({ value: {event: 'Connected to Live-Debug stream', type: 'System', sysMsg: true}});
        this.debugData.push({ value: {event: 'Connected to Live-Debug stream', type: 'System', sysMsg: true}});
        // clearInterval(this.timer);
        // this.startTimer();
        this.debugStarted = true;
        return 'Started';
      }, () => {
        // clearInterval(this.timer);
        // checkQueueAndAdd({ value: {event: 'Disconnected from Live-Debug stream', type: 'System', sysMsg: true}});
        this.debugData.push({ value: {event: 'Disconnected from Live-Debug stream', type: 'System', sysMsg: true}});
        this.debugStarted = false;
        this.socket = null;
        return isTimedCall ? 'Stopped' : 'Closed';
      }, () => true).subscribe(socketMessage => {
          if (this.debugData.length >= this.maxRows) this.debugData.shift();
          let msg;
          try {
            const parsed = JSON.parse(socketMessage);
            msg = {index: this.itemIndex, type: parsed.type, value: parsed};
          } catch (e) {
            msg = {index: this.itemIndex, type: '*', value: socketMessage};
          }
          // checkQueueAndAdd(msg);
          this.debugData.push(msg);
          this.itemIndex += 1;
        },
        // Called whenever there is a message from the server
        err => {
          // checkQueueAndAdd({index: this.itemIndex, value: {error: isTimedCall ? err : err?.statusText}});
          this.debugData.push({index: this.itemIndex, value: {error: isTimedCall ? err : err?.statusText}});
        },
        // Called if WebSocket API signals some kind of error
        () => {
          this.debugStarted = false;
          setTimeout(() =>
            this.toastr.warning(
              `You can continue live-debug by pressing the 'Start Live-debug' button`,
              'Live-debug session has ended',
              { disableTimeOut: true }
            )
          );
        } // Called when connection is closed (for whatever reason)
      );
    }

    if (isTimedCall) {
      this.projectService.enableLiveDebug({key: this.socketKey}).subscribe( (x:any) => {
        this.segment.track('Started Live-Debug');
        this.closesAt = x.closes_at;
        this.socketKey = x.key;
        if (x.ws != this.socketWS) {
          debugFlow(x, true);
        } else {
          clearInterval(this.timer);
          this.startTimer();
        }
      });
    } else {
      this.projectService.enableLiveDebug({}).subscribe( (x:any) => {
        this.closesAt = x.closes_at;
        this.socketKey = x.key;
        debugFlow(x);
      });
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      const closesAt = new Date(this.closesAt).getTime();

      if (closesAt > Date.now() && (closesAt - Date.now()) < 60*5*1000) {
        this.startDebug(true);
      }
    }, 5000);
  }

  showRaw(item) {
    this.raw = item.value;
    this.selectedItemIndex = item.index;
    const anonymousId = item.value.anonymousId || item.value.cookie_id;
    this.selectedAnonymousId = anonymousId;
  }

}
