import { Component, OnInit, Input } from '@angular/core';
import { Project, View } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { Store, Select } from '@ngxs/store';
import { SetTrafficOption, SetCutoffEvent } from 'src/app/store/actions/project.actions';
import { Observable } from 'rxjs';
import { ConversionEvents } from 'src/app/store/states/conversion-events.state';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { ConversionEvent } from 'src/app/core/models/conversionEvent.model';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';
import { environment } from 'src/environments/environment';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';

@Component({
  selector: 'app-conversion-and-revenue',
  templateUrl: './conversion-and-revenue.component.html',
  styleUrls: ['./conversion-and-revenue.component.scss']
})
export class ConversionAndRevenueComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.conversionEvents) conversionEvents$: Observable<ConversionEvents>;

  @Input() showTitle = true;
  @Input() isInModal = false;
  @Input() disabled = false;
  @Input() reason;

  project: Project;
  view: View;
  conversionEvents: Array<ConversionEvent>;
  isProd = environment.isProd;
  excludedFilters = [];

  constructor(
    private projectService: ProjectService,
    private store: Store,
    private tooltipService: TooltipService
  ) { }

  ngOnInit(): void {
    this.project$.subscribe(x => {
      this.project = x.project;
      this.view = x.currentViewParams;
    });
    this.conversionEvents$.subscribe(x => this.conversionEvents = x.events);
  }

  selectTrafficOption(traffic_option) {
    if (this.disabled) return;
    if (this.view?.traffic_option != traffic_option && (traffic_option == 'include_all' || traffic_option == 'exclude_direct' || this.conversionEvents.length > 0)) {
      this.store.dispatch(new SetTrafficOption(traffic_option)).subscribe( () => {
        if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
      });
    }
  }

  selectedCutoff(event) {
    if (this.disabled) return;
    this.store.dispatch(new SetCutoffEvent(event.name)).subscribe(() => {
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    }); 
  }

  showDisabledTooltip(option?) {
    this.tooltipService.mouseOver.emit({
      name: 'disabled',
      data: { data: {name: option? 'Option' : 'Traffic Conversion and Revenue', reason: option? 'there are no conversion events for chosen dates' : this.reason} }
    });
  }

  hideDisabledTooltip() {
    this.tooltipService.mouseOut.emit()
  }

  isInTrafficOptionsArray(x) {
    const options = this.project?.options?.traffic_options;
    if (options != null && typeof(options) != 'undefined') {
      const found = options.find( f => f == x);
      if (found != null && typeof(found) != 'undefined') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
