<button class="btn btn-default btn-sm color-initial pull-right mt-4" (click)="restartOnboarding()">
  Restart Onboarding
</button>
<h1 class="mt-0 mb-0">Project Settings</h1>

<div class="row">
<h4 class="col-lg-6">
  Attribution Project ID: <strong>{{project?.identifier}}</strong>
</h4>

<ng-template #editProjectNameButton>
  <h4 class="col-lg-6 text-right">
    Project Name: <strong>{{ project?.name }}</strong> &nbsp;
    <a class="btn btn-default color-initial pull-right" (click)="isProjectNameBeingEditted = true;">
      <i class="fa-solid fa-pencil"></i> Edit
    </a>
  </h4>
</ng-template>

<form *ngIf="isProjectNameBeingEditted; else editProjectNameButton" (submit)="createProject()" class="mb-3 col-lg-6">
  <div class="input-group input-group-lg">
    <input type="text" class="form-control" [(ngModel)]="projectName" name="projectName">
      <span class="input-group-btn">
      <button type="Submit" class="btn btn-default">Save</button>
    </span>
  </div>
</form>
</div>

<app-lookback-option></app-lookback-option>

<app-conversion-and-revenue></app-conversion-and-revenue>

<app-exclude-filters></app-exclude-filters>

<app-disregard-existing-users
  [disabled]="project?.company_based_attribution"
  [reason]="'company based attribution'"></app-disregard-existing-users>

<div *ngIf="project?.options?.features?.include_only_users_registered_in_daterange || !isProd">
  <app-only-users-in-daterange
    [disabled]="project?.company_based_attribution"></app-only-users-in-daterange>
</div>

<div *ngIf="project?.options?.features?.company_based_attribution || !isProd">
  <app-company-based-attribution
    [disabled]="view?.disregard_existing_users != null || project?.include_only_users_registered_in_daterange"></app-company-based-attribution>
</div>

<div *ngIf="project?.options?.features?.first_purchase_only || !isProd">
  <app-first-purchase-only></app-first-purchase-only>
</div>

<app-position-based-attribution-ratios></app-position-based-attribution-ratios>

<div *ngIf="project?.options?.features?.revenue_groups || !isProd">
  <app-revenue-groups></app-revenue-groups>
</div>

<app-currency-selector></app-currency-selector>

<app-timezone-selector></app-timezone-selector>
