import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forEach } from 'lodash-es';

import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AmountService {
  apiUrl = environment.clientApiUrl;

  constructor(private httpClient: HttpClient, private store: Store) { }

  get({id, type, periodStart, periodEnd}) {
    let Url;
    if (type === 'g') {
      Url = this.apiUrl + `/v2/filter_groups/${id}/amounts`;
    } else if (type === 'f') {
      Url = this.apiUrl + `/v2/filters/${id}/amounts`;
    } else if (type == 'v') {
      Url = this.apiUrl + `/v2/virtual_filters/${id}/amounts`;
    }
    const selectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected);
    const startDate = selectedState.chosenDates.start;
    const endDate = selectedState.chosenDates.end;
    
    const params: any = {start: startDate, end: endDate};

    if (periodStart && periodEnd) {
      params.period_start = periodStart;
      params.period_end = periodEnd;
    }

    return this.httpClient.get(Url, { params: params, observe: 'body'});
  }

  addSpend(newSpendParams) {
    const Url = this.apiUrl + `/v2/filters/${newSpendParams.filter}/amounts`;

    return this.httpClient.post(Url, newSpendParams);
  }

  delete(amount, filterId) {
    let url;
    if (amount.type === 'amount') {
      url = this.apiUrl + `/v2/filters/${filterId}/amounts/${amount.id}`;
    } else if (amount.type === 'amount_range') {
      url = this.apiUrl + `/v2/filters/${filterId}/amount_ranges/${amount.id}`;
    }
    return this.httpClient.delete(url);
  }

  uploadCSV(shouldPersist, filterId, files?, formData?: FormData) {
    const Url = `${environment.clientApiUrl}/v2/filters/${filterId}/amounts_upload`;

    const fd = new FormData();
    if (formData === null) {
      if (files.length === 1) {
        fd.append('csv_file', files.item(0));
      } else {
        forEach(files, (file, index) => {
          fd.append('csv_file' + '_' + index, file);
        });
      }
      fd.append('filter_id', filterId);
      fd.append('persist', shouldPersist);
    } else {
      formData.forEach( (value, key) => {
        fd.append(key, value);
      });
    }

    fd.set('persist', shouldPersist);

    return this.httpClient.post(Url, fd).pipe(
      map( resp => {
        return { formData: fd, filter: filterId, amounts: resp};
      })
    );
  }

}
