import { Component, AfterViewInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';

@Component({
  selector: 'app-cp-totals',
  templateUrl: './cp-totals.component.html',
  styleUrls: ['./cp-totals.component.scss']
})
export class CpTotalsComponent implements AfterViewInit, OnChanges {
  @Input() totals: Array<any>;
  @Output() openChannel = new EventEmitter();
  maxSymbolsInLabels = 0;
  rendered = false;

  tooltipName = 'cp-totals';

  constructor(
    private tooltipService: TooltipService
  ) { }

  ngOnChanges() {
    if (!this.rendered) { return; }
    if (this.totals.length > 0) {
      this.drawChart();
    }

  }

  ngAfterViewInit() {
    this.rendered = true;
    if (this.totals.length > 0) {
      return this.drawChart();
    }
  }

  drawChart() {
    d3.select(`#cp-totals`).select('svg').remove();

    if (!this.totals) {
      return;
    }

    for (const item of this.totals) {
      if ( item.name.length > this.maxSymbolsInLabels ) {
        this.maxSymbolsInLabels = item.name.length;
      }
    }

    const colors = d3.scaleOrdinal([
      '#bd6d65',
      '#60bf8d'
    ]);

    const darkRed = '#7d3c36';
    const darkGreen = '#338057';

    const formatCurrency = d3.format('-$,.0f');

    const wrapperProperties = document.getElementById('cp-totals').getBoundingClientRect();

    const div = d3.select('body').append('div')
            .attr('class', 'd3-tip')
            .style('dispay', 'none');

    const mouse = {x: 0, y: 0};
    const ref = {
      getBoundingClientRect: () => ({
        top: mouse.y,
        right: mouse.x,
        bottom: mouse.y,
        left: mouse.x,
        width: 0,
        height: 0,
      }),
      clientWidth: 0,
      clientHeight: 0,
    };

    const margin = {top: 15, right: 0, bottom: 30, left: this.maxSymbolsInLabels * 10 + 10, fromLabel: 10};

    const barWidth = 33;
    const barGap = barWidth * 1.5;

    const series = d3.stack()
          .keys(['spend', 'revenue'])
          .offset(d3.stackOffsetDiverging)
          (this.totals);

    const svg = d3.select(`#cp-totals`).append('svg')
            .style('font-family', '"Nunito Sans" !important')
            .attr('width', wrapperProperties.width)
            .attr('height', margin.top + (this.totals.length * barGap) + margin.bottom + 5);

    const height: any = svg.attr('height');
    const width: any = svg.attr('width');

    function stackMin(s) {
      return d3.min(s, d => d[0]);
    }

    function stackMax(s) {
      return d3.max(s, d => d[1]);
    }

    const min = parseInt(d3.min(series, stackMin));
    const max = parseInt(d3.max(series, stackMax));

    const x = d3.scaleLinear()
          .domain([max, min])
          .rangeRound([width - margin.fromLabel, margin.left]);

    // x axis top lines
    svg.append('g')
      .attr('transform', `translate(${margin.fromLabel - 0.5},${margin.top})`)
      .call(
        d3.axisTop(x)
          .tickSize(-height + margin.bottom)
          .ticks(10)
      )
      .call( g => g.select('.domain').remove())
      .call( g => g.selectAll('text').remove())
      .selectAll('line')
        .attr('stroke', '#dadada');

    // x axis top text
    svg.append('g')
      .attr('transform', `translate(${margin.fromLabel - 0.5},${margin.top})`)
      .call(
        d3.axisTop(x)
          .tickSize(0)
          .ticks(10)
          .tickFormat( d => formatCurrency(d))
      )
      .call( g => g.select('.domain').remove())
      .selectAll('text')
        .style('color', '#6c6a71');

    const nameArray = this.totals.map( t => t.name);

    const y: any = d3.scaleOrdinal()
      .domain(nameArray)
      .range(this.totals.map( (item, i) =>  barGap * i));

    // left axis
    svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top + 10 + (barWidth / 2)})`)
      .style('font-size', '15px')
      .call(
        d3.axisLeft(y)
          .ticks(this.totals.length)
          .tickSize(0)
      )
      .call( g => g.select('.domain').remove())
      .selectAll('text')
        .attr('class', 'cp-total-link')
        .on('click', (_, i: any) => {
          div.style('display', 'none');
          this.openChannel.emit(this.totals[i].id);
          this.tooltipService.mouseOut.emit()
        });

    // caluclating filler rect widths
    function getFillerWidthPositive(h) {
      if ( (x(h) - x(0)) > 3 ) {
        return 3;
      } else if ((x(h) - x(0)) > 2 ) {
        return 2;
      } else if ((x(h) - x(0)) > 1 ) {
        return 1;
      } else {
        return 0;
      }
    }

    function getFillerWidthNegative(h) {
      if ( (x(0) - x(h)) > 3 ) {
        return 3;
      } else if ((x(0) - x(h)) > 2 ) {
        return 2;
      } else if ((x(0) - x(h)) > 1 ) {
        return 1;
      } else {
        return 0;
      }
    }

    // draw fillers
    svg.append('g')
      .attr('transform', `translate(${margin.fromLabel}, ${margin.top + 10})`)
      .selectAll('g')
      .data(series)
      .enter().append('g')
        .attr('fill', d  =>  colors(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
        .attr('height', barWidth)
        .attr('y', (d, i) => barGap * i)
        .attr('x', d => {
          if ( d[1] > 0 ) {
            return x(0);
          } else {
            return x(0) - getFillerWidthNegative(d[0]);
          }
        })
        .attr('width', d => {
          if (d[0] < 0) {
            return getFillerWidthNegative(d[0]);
          } else {
            return getFillerWidthPositive(d[1]);
          }
        });

    // draw rects
    svg.append('g')
      .attr('transform', `translate(${margin.fromLabel}, ${margin.top + 10})`)
      .selectAll('g')
      .data(series)
      .enter().append('g')
        .attr('fill', d  =>  colors(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
        .style('cursor', 'pointer')
        .attr('height', barWidth)
        .attr('y', (_, i) => barGap * i)
        .attr('x', d => x(d[0]) )
        .attr('rx', '4px')
        .attr('width', d => x(d[1]) - x(d[0]) )
          .on('mouseover', (e, d: any) => this.tooltipService.mouseOver.emit({
            name: this.tooltipName,
            data: { data: (d.data == null ? d : d.data) }
          }))
          .on('mouseout', () => this.tooltipService.mouseOut.emit())
          .on('click', (_, i: any) => {
            div.style('display', 'none');
            this.openChannel.emit(this.totals[i].id);
            this.tooltipService.mouseOut.emit()
          });

    // draw circles
    svg.append('g')
      .attr('transform', `translate(${margin.fromLabel}, ${margin.top + 10 + (barWidth / 2)})`)
      .selectAll('g')
      .data(this.totals)
      .enter().append('circle')
        .attr('fill', d => {
          if (d.roas > 0 ) {
            return darkGreen;
          } else if ( d.roas < 0) {
            return darkRed;
          } else { return 'gray'; }
        })
        .style('cursor', 'pointer')
        .attr('id', (_, i) =>  'roas' + i)
        .attr('cy', (_, i) => barGap * i)
        .attr('cx', (d: any) => x(d.roas))
        .attr('r', () => 5)
        .on('mouseover', (e, d: any) => this.tooltipService.mouseOver.emit({
          name: this.tooltipName,
          data: { data: (d.data == null ? d : d.data) }
        }))
        .on('mouseout', () => this.tooltipService.mouseOut.emit())
        .on('click', (_, i: any) => {
          div.style('display', 'none');
          this.openChannel.emit(this.totals[i].id);
          this.tooltipService.mouseOut.emit()
        });
  }

}
