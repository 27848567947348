import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-order-arrow',
  templateUrl: './order-arrow.component.html',
  styleUrls: ['./order-arrow.component.scss']
})
export class OrderArrowComponent implements OnInit {
  @Output() whenClicked = new EventEmitter();
  @Input() label;
  @Input() currentDirection: String = 'neutral';

  constructor() { }

  ngOnInit(): void {
  }

  change() {
    this.whenClicked.emit();
  }

}
