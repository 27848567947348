import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SetCard } from 'src/app/store/actions/settings.actions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  get() {
    const Url = `${environment.clientApiUrl}/card`;

    return this.httpClient.get(Url).subscribe( (resp: any) => {
      this.store.dispatch(new SetCard(resp));
    });
  }

  registerToken(token) {
    const Url = `${environment.clientApiUrl}/card`;

    return this.httpClient.post(Url, {stripe_token: token}).pipe(
      map( resp => {
        this.store.dispatch(new SetCard(resp));
      })
    );
  }
}
