import { Component, OnInit, Input } from '@angular/core';
import { AdDestinationUrlsService } from 'src/app/core/services/ad-destination-urls/ad-destination-urls.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-ad-destination-urls-modal',
  templateUrl: './ad-destination-urls-modal.component.html',
  styleUrls: ['./ad-destination-urls-modal.component.scss']
})
export class AdDestinationUrlsModalComponent implements OnInit {
  @Input() item: any;
  urlObjectName: string;
  groupedUrls;
  urlsAreLoading;
  selectedUrl;
  isEmpty = false;

  objectKeys = Object.keys;

  constructor(
    private service: AdDestinationUrlsService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.urlsAreLoading = true;
    this.urlObjectName = this.item.name;

    const params: any = {};
    if (this.item.t == 'f') {
      params.filter_id = this.item.id;
    } else if (this.item.t == 'g') {
      params.filter_group_id = this.item.id;
    }

    this.service.get(params).subscribe( resp => {
      this.groupedUrls = resp;
      this.urlsAreLoading = false;
      this.isEmpty = isEmpty(resp);
    });
  }

  toggleSelected(url) {
    this.selectedUrl = this.isSelected(url) ? undefined : url;
  }

  isSelected(url) {
    return this.selectedUrl === url;
  }

}
