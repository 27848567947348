import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SettingsComponent } from './settings.component';
import { IntegrationInstructionsComponent } from './integration-instructions/integration-instructions.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { AccessComponent } from './access/access.component';
import { IntegrationsComponent } from '../integrations/integrations.component';
import { IntegrationShowComponent } from '../integrations/integration-show/integration-show.component';
import {WeeklyEmailComponent} from './weekly-email/weekly-email.component';
import { LiveDebugComponent } from './live-debug/live-debug.component';
import { GdprRulesComponent } from './gdpr-rules/gdpr-rules.component';
import { EventsUploaderComponent } from './events-uploader/events-uploader.component';
import { InfluencesUploaderComponent } from './influences-uploader/influences-uploader.component';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { SettingsOverviewComponent } from './overview/settings-overview.component';
import { ShopifySettingsComponent } from '../shopify-segment/shopify-settings/shopify-settings.component';
import { SegmentSettingsComponent } from '../shopify-segment/segment-settings/segment-settings.component';
import { SchemaViewComponent } from '../integrations/integration-settings/schema-view/schema-view.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      { path: SettingsSections.overview, component: SettingsOverviewComponent },
      { path: SettingsSections.integrations, component: IntegrationsComponent, data : {isWithIdentifier : true} },
      { path: `${SettingsSections.integrations}/show/:integration`, component: IntegrationShowComponent },
      { path: SettingsSections.instructions, component: IntegrationInstructionsComponent },
      { path: SettingsSections.access, component: AccessComponent },
      { path: SettingsSections.projectSettings, component: ProjectSettingsComponent },
      { path: SettingsSections.weeklyEmails, component: WeeklyEmailComponent },
      { path: SettingsSections.liveDebug, component: LiveDebugComponent},
      { path: SettingsSections.gdprRules, component: GdprRulesComponent},
      { path: SettingsSections.eventsUploader, component: EventsUploaderComponent},
      { path: SettingsSections.influencesUploader, component: InfluencesUploaderComponent},
      { path: SettingsSections.shopify, component: ShopifySettingsComponent},
      { path: SettingsSections.segment, component: SegmentSettingsComponent},
      { path: 'schema-test', component: SchemaViewComponent },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
