<div class="visitors-warpper">
  <div class='row' *ngIf="!infoInProgress">
    <div class="col-sm-12">
      <div class='sidebar-title no-padding-top'>
        <h4 class="clearfix">
          <img class="sidebar_gravatar pull-right" *ngIf="visitor?.email" src="https://secure.gravatar.com/avatar/{{ visitor?.email | md5 }}?d=blank" />
          <span class="title-in-sidebar">{{ visitorName }}</span>
          <div *ngIf="visitor?.migrated_to" class="migrated-to">
            Migrated to <a (click)="moveToVisitor(visitor?.migrated_to)">{{ visitor?.migrated_to }}</a>
          </div>
          <div *ngIf="visitor?.email && ((visitor | nameForUser) != visitor?.email)" class="medium-dark">
            {{ visitor?.email }}
          </div>
          <div *ngIf="visitor?.company_id && (isCompanyBasedAttributionEnabled || !isProd)">
            <a (click)="showCompany(visitor?.company_id)" class="strong blue"><i class="fa-solid fa-lock red me-1" *ngIf="!isCompanyBasedAttributionEnabled && !isProd"></i>{{ visitor?.company_name || visitor?.company_identifier }}</a>
          </div>
        </h4>
        <div class='row traits-wrapper'>
          <div class="row">
            <dt class="col-sm-4 dt">USER_ID</dt>
            <dd class="col-sm-8 dd" [class.fst-italic]="!visitor?.identifier">{{ visitor?.identifier || '(not set)'}}</dd>
          </div>
          <div class="row">
            <dt class="col-sm-4 dt">Email</dt>
            <dd class="col-sm-8 dd" [class.fst-italic]="!visitor?.email">{{ visitor?.email || '(not set)' }}</dd>
          </div>
          <div *ngIf="visitor?.ip" class="row">
            <dt class="col-sm-4 dt">IP</dt>
            <dd class="col-sm-8 dd">{{ visitor?.ip }}</dd>
          </div>
          <div class="row" *ngIf="visitor?.location">
            <dt class="col-sm-4 dt">Location</dt>
            <dd class="col-sm-8 dd">{{ visitor?.location | commaLocation }}</dd>
          </div>
          <div *ngFor="let item of limitedTraits() | keyvalue" class="row">
            <dt *ngIf="item.key.length > 13" class="col-sm-4 dt text-truncate" placement="right" ngbTooltip="{{item.key}}">
              {{ item.key }}
            </dt>
            <dt *ngIf="item.key.length <= 13" class="col-sm-4 dt">{{ item.key }}</dt>
            <dd class="col-sm-8 dd">
              <span *ngIf="isTime(item.key); else noTime">
                {{ item.value | formattedMoment:'ff'}}
              </span>
              <ng-template #noTime>
                <span [innerHtml]="item.key === 'revenue' ? (item.value | projectCurrency) : (item.value | formattedTrait)">
                </span>
              </ng-template>
            </dd>
          </div>
          <div class='row' *ngIf="isTooManyTraits() && showAllTraits == false">
            <dt class="col-sm-4 dt"></dt>
            <dd class="col-sm-8 dd">
              <a (click)="showAllTraits = true">Show all traits</a>
            </dd>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="infoInProgress" class="loader-sm">
    <app-loader
      *ngIf="infoInProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
<div>
  <app-visit-timeline
  [visitorId]="visitorId"
  [summary]="summary"
  [data]="data"
  [project]="project"
  [queryParams]="queryParams"
  (orderChanged)="orderChanged($event)"
  [canScroll]="canScroll"
  (scroll)="onScroll()"></app-visit-timeline>
  <div *ngIf="inProgress" class="loader-sm">
    <app-loader
      *ngIf="inProgress"
      [position]="'relative'"
      [color]="'green'"
      [height]="'30px'"
      [zIndex]="1"></app-loader>
  </div>
</div>
