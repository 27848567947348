import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'takeFromObjExcept',
})
export class takeFromObjExceptPipe implements PipeTransform {
  transform(obj, notToTake): any {
    if (obj) {
      const {[notToTake]: _, ...everythingElse} = obj;
      return everythingElse;
    } else return obj;
  }
}