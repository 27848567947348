<div *ngIf="summary" class="sidebar-summary row">
  <div class="col-sm-3">
    <p class="column-title muted-caps">Visits</p>
    <p>{{ summary?.visits | number: 0 }}</p>
  </div>
  <div class="col-sm-3">
    <p class="column-title muted-caps">Pageviews</p>
    <p>{{ summary?.pageviews | number:0 }}</p>
  </div>
  <div class="col-sm-3">
    <p class="column-title muted-caps">Events</p>
    <p>{{ summary?.tracked_events | number:0 }}</p>
  </div>
  <div class="col-sm-3">
    <p class="column-title muted-caps">LTV</p>
    <p><abbr title="" placement="bottom-right" ngbTooltip="Revenue: {{ summary?.revenue | projectCurrency }}
      Spend: {{ summary?.spend | projectCurrency }}" container="body">{{ (summary?.spend ? (summary?.revenue - summary?.spend) : summary?.revenue) | projectCurrency }}</abbr></p>
  </div>
</div>

<div *ngIf="path.length > 0" class="paths labels-filters">
  <h3 class="d-inline">
    First touch:
  </h3>
  <app-filter-badge
    [filter]="path[0]"
    [title]="path[0].time | date"
    [displayName]="path[0] | visitTimelinePrependChannelName:filterTree"
  ></app-filter-badge>

  <div class="path-row">
    <h3 *ngIf="path.length > showMaxFilters; else FullPath" class="d-inline me-1">Recent paths:</h3>
    <ng-template #FullPath><h3 class="d-inline me-2">Path:</h3></ng-template>

     <span class="filter" *ngFor="let filter of slicedPath; let i = index">
       <app-filter-badge
         [filter]="filter"
         [title]="filter | visitTimelineFilterTooltip:filterTree"
       ></app-filter-badge>
       <span *ngIf="i < path.length - 1" class="divider">
         <span class="small muted double-arrows">&rsaquo;</span>
         <span class="small muted double-arrows">&rsaquo;</span>
       </span>
     </span>
  </div>
</div>

<a class="event-sorter btn btn-link" (click)="changeOrder()" *ngIf="summary?.visits > 0">
  <i class="fa-solid fa-sort-amount-{{order}}"></i>
</a>

<div
  class="table-infinite"
  infiniteScroll
  [infiniteScrollContainer]="selector"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()">
  <div class="event-timeline">
    <div *ngFor="let visit of timeline">
      <div class="sidebar_date" *ngIf="visit.type == 'date'">
        <div>
          <strong>
            <span class="sidebar_events_date_dot"></span>
            <div class="sidebar_events_date">
              {{ visit.value | formattedMoment:'DDD' }}
            </div>
          </strong>
        </div>
      </div>

      <div class="sidebar_visit_wrapper" *ngIf="visit.type == 'visit'">
        <ng-container *ngFor="let filter of (visit.filter | visitTimelineVisitFilters:filters)">
          <div class="sidebar_visit row cursor-pointer" [class.with-revenue]="visit.revenue > 0" (click)="loadVisit(visit.id)">
            <span class="sidebar_visit_chevron">
              <i class="fa-solid fa-fw fa-chevron-right blue" [ngClass]="{ 'fa-chevron-down': !visit.busy && visit.expanded, 'fa-chevron-right': !visit.busy && !visit.expanded, 'fa-spinner fa-pulse': visit.busy } "></i>
            </span>
            <div class="col-sm-8">
              <span class="ps-1">VISIT</span>
            </div>
            <div class="col-sm-4 sidebar_event_time muted-caps">
              <span ngbTooltip="{{visit.time}}" container="body" placement="left">{{ visit.time | formattedMoment: 't' }}</span>
            </div>
          </div>

          <div class="sidebar_event_details">
            <!-- group name -->
            <div *ngIf="visit.visitor_id" class="visit-event">
              <div class="event-key muted-caps">Visitor:</div>
              <div class="event-value">
                <a (click)="showVisitor(visit.visitor_id)">{{ (companyVisitors | getValueByParamFromArray:visit.visitor_id:'id') | nameForUser }}</a>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="channelName; context:{ $implicit: (filter.id |visitTimelineFilterTopGroup: filterTree)}"></ng-container>
            <ng-template
              #channelName
              let-topGroup>
              <div *ngIf="topGroup" class="visit-event">
                <div class="event-key muted-caps">Channel:</div>
                <div class="event-value">
                  {{ topGroup.n }}
                  <a class='ms-2' (click)="moveToFilter(topGroup)"><i class="fa-solid fa-magnifying-glass"></i></a>
                </div>

              </div>
            </ng-template>

            <!-- filter name -->
            <div class="visit-event">
              <div class="event-key muted-caps">Filter:</div>
              <div class="event-value">
                <app-filter-icon
                [item]="filter" [onlyIcon]="true" [isRawFilter]="true"></app-filter-icon>
                {{ filter.n }}
                <a class='ms-2' (click)="moveToFilter(filter)"><i class="fa-solid fa-magnifying-glass"></i></a>
              </div>
            </div>

            <!-- revenue -->
            <div *ngIf="visit.revenue > 0" class="visit-event">
              <div class="event-key muted-caps">Revenue:</div>
              <div class="event-value"><strong class="dark-green">{{ visit.revenue | projectCurrency}}</strong></div>
            </div>

            <!-- spend -->
            <div *ngIf="visit.spend > 0" class="visit-event">
              <div class="event-key muted-caps">Spend:</div>
              <div class="event-value"><strong class="dark-red">{{ visit.spend | projectCurrency }}</strong></div>
            </div>

            <!-- events -->
            <div *ngIf="visit.tracked_events > 0" class="visit-event">
              <div class="event-key muted-caps">Events:</div>
              <div class="event-value" *ngIf="(visit.detailed_events | visitTimelineGetSize) > 0; else showTrackedEvents"> {{ visit.detailed_events | visitTimelineGetSize }}&nbsp;&nbsp;
                <app-comma-separated-list
                  [items]="visit.detailed_events"
                  [length]="40"
                  (load)="loadVisit(visit.id)"></app-comma-separated-list>
              </div>
              <ng-template #showTrackedEvents>
                <div class="event-value"> {{ visit.tracked_events }}</div>
              </ng-template>
            </div>

            <!-- pageviews count -->
            <div *ngIf="visit.pageviews > 0" class="visit-event">
              <div class="event-key muted-caps">Pageviews:</div>
              <div class="event-value"> {{ visit.pageviews }}</div>
            </div>
          </div>

          <div *ngIf="visit.expanded">
            <div class="sidebar_event" *ngFor="let event of visit.events; let i = index">
              <div *ngIf="event.type == 'date'" class="caps text-right">
                {{ event.value | formattedMoment:'DDD' }}
              </div>

              <div class="visit-event" *ngIf="event.type == 'event'"> 
                @if (event.name | isPageView) {
                  <div class="sidebar_url event-key" *ngIf="event.url">
                    <span class="muted-caps me-1">{{ event.name == 'Loaded a Page' ? 'VIEWED' : 'URL' }}:</span>
                  </div>
                  <div class="event-value visit-event justify-content-between">
                    <a href="{{ event.url }}" title="{{ event.url }}" target="_blank">{{ event.url | url: 'pathname' }}</a>
                    <div class="sidebar_event_time muted-caps"><span ngbTooltip="{{ event.time }}" container="body" placement="left">{{event.time | formattedMoment:'t'}}</span></div>
                  </div>
                } @else {
                  <div class="sidebar_eventname">{{ event.name }}</div>
                  <div class="event-value visit-event justify-content-end">
                    <div class="sidebar_event_time muted-caps"><span ngbTooltip="{{ event.time }}" container="body" placement="left">{{event.time | formattedMoment:'t'}}</span></div>
                  </div>
                }
              </div>

              <div class="sidebar_event_details" *ngIf="event.referrer || event.revenue || event.properties">
                <!--Pageview Event with Referrer -->
                <div *ngIf="event.referrer" [ngClass]="{'sidebar_referrer_mute' : event.referrer === visit.events[i].url}">
                  <div class="sidebar_rereferrer visit-event">
                    <span class="sidebar_event_details_key muted-caps event-key">Referrer:</span>
                    <a class="sidebar_event_details_value event-value"  href="{{ event.referrer }}" title="{{ event.referrer }}" target="_blank">{{ event.referrer }}</a>
                  </div>
                </div>

                <!--Event with Revenue -->
                <div *ngIf="event.revenue" class="visit-event">
                  <span class="sidebar_event_details_key muted-caps event-key">Revenue:</span>
                  <span class="event-value">{{ event.revenue | projectCurrency }}</span>
                </div>

                <!--Event with Properties -->
                <div *ngFor="let key_and_value of event.properties">
                  <div class="visit-event" *ngIf="(key_and_value[0] | uppercase) !== ('revenue' | uppercase)">
                    <span class="sidebar_event_details_key muted-caps event-key">{{ key_and_value[0] }}: </span>
                    <span class="event-value">{{ key_and_value[1] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="busy === false" class="sidebar_timeline_image">
  <i class="fa-regular fa-fw fa-calendar-xmark"></i>
</div>
