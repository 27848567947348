import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  get(id) {
    const Url = `${environment.reportingApiUrl}/v2/visitors/${id}`;

    return this.httpClient.get(Url, {observe: 'body'});
  }

  getHistory(id, requestParams?) {
    const Url = `${environment.reportingApiUrl}/v2/visitor_history/${id}`;

    return this.httpClient.get(Url, {params: requestParams, observe: 'body'});
  }

  getHistoryEvent(visitorId, eventId, queryParams) {
    const Url = `${environment.reportingApiUrl}/v2/visitor_history/${visitorId}/events/${eventId}`;

    return this.httpClient.get(Url, {params: queryParams, observe: 'body'});
  }

  getTraitKeys() {
    const Url = `${environment.reportingApiUrl}/v2/visitors/atb_traits_keys`;

    return this.httpClient.get(Url);
  }
}
