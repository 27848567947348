import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { stringIsEmpty } from 'src/app/core/helpers/string-is-empty';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { AddFilter, AddFilterChannel } from 'src/app/store/actions/filter-tree.actions';

@Component({
  selector: 'app-new-channel-modal',
  templateUrl: './new-channel-modal.component.html',
  styleUrls: ['./new-channel-modal.component.scss']
})
export class NewChannelModalComponent {
  @Input() parentGroup = null;
  @Input() justAddingChannel = false;
  name = '';

  errorMessage;
  waitingForResponse;

  stringIsEmpty = stringIsEmpty;

  constructor(
    public activeModal: NgbActiveModal,
    private filterService: FilterService,
    private store: Store
  ) { }

  submit() {
    if (stringIsEmpty(this.name)) return;
    this.filterService.createNewChannel(this.name, this.parentGroup?.id).subscribe( (resp: any) => {
      let forState: any = {channel: resp};
      if (this.parentGroup?.id) forState.parentFilterGroupId = this.parentGroup.id;
      this.store.dispatch(new AddFilterChannel(forState)).subscribe( () => {
        this.activeModal.close({result: 'createdGroup', groupId: resp.id});
      });
    });
  }
}
