import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Select } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { FirstTimePurchaseService } from 'src/app/core/services/first-time-purchase/first-time-purchase.service';
import { DateTime } from 'luxon';
import { ProjectState, SelectedState } from 'src/app/store/index.state';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Project, View } from 'src/app/core/models/project.model';
import { BaseService } from 'src/app/core/services/base/base.service';

@Component({
  selector: 'first-time-purchase',
  templateUrl: './first-time-purchase.component.html',
  styleUrls: ['./first-time-purchase.component.scss']
})
export class FirstTimePurchaseComponent implements OnInit, OnDestroy {
  @Select(ProjectState) projectState$: Observable<ProjectStateModel>;
  @Select(SelectedState) selectedState$: Observable<SelectedStateModel>;
  @Input() report;

  subscription;
  chartMetric = 'Revenue';
  aggregationPeriod = 'day';
  tableRevenue = 'Absolute';
  paidOnly = false;
  reportForChart;
  chartPeriodsWithoutTotal = [];
  tablePeriodsWithoutTotal = [];
  reportIsLoading = false;
  project: Project;
  view: View;

  disabledLines = [];

  constructor(private fpService: FirstTimePurchaseService, private baseService: BaseService) {}

  ngOnInit() {
    this.subscription = combineLatest([this.selectedState$, this.projectState$]).subscribe( ([selected, projectState]) => {
      this.project = projectState.project;
      this.view = projectState.currentViewParams;
      this.aggregationPeriod = this.view?.aggregation_period || 'Day';
      const periods = this.baseService.getAggregationPeriods(selected, projectState);
      const availablePeriods = periods.aggrPeriods;
      if (!availablePeriods.includes(this.aggregationPeriod)) {
        this.aggregationPeriod = availablePeriods[0];
      }
    });
  }

  ngOnChanges() {
    if (this.report) {
      this.reportIsLoading = this.report.inProgress;
      this.reportForChart = this.generateReportForChart(this.report);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.fpService.unsubscribeAndCancelReportGeneration();
  }

  generateReportForChart(report) {
    this.chartPeriodsWithoutTotal = report.p.slice(1);
    return this.chartPeriodsWithoutTotal.map( x => {
      if (x.id == 'total') return;
      return {
        period: this.periodLabel(x),
        values: [
          { name: 'first_time_customers', value: report.f.total ? (report.f.total[x.id]?.abs.fr / 100 || 0) : 0},
          { name: 'repeated_customers', value: report.f.total ? (report.f.total[x.id]?.abs.r / 100 || 0) : 0},
          { name: 'marketing_spend', value: report.f.total ? (report.f.total[x.id]?.abs.a / 100 || 0) : 0},
          { name: 'repeated_conversions', value: report.f.total ? (report.f.total[x.id]?.abs.c || 0) : 0},
          { name: 'first_time_conversions', value: report.f.total ? (report.f.total[x.id]?.abs.fc || 0) : 0}
        ],
      };
    });
  }

  togglePaidOnly() {
    this.paidOnly = !this.paidOnly;
    this.reportIsLoading = true;
    this.fpService.getFirstTimePurchase(this.paidOnly).subscribe( (resp: any) => {
      this.generateReportForChart(resp.report);
      this.reportIsLoading = false;
    });
  }

  toggleLine(lineName) {
    if (this.disabledLines.includes(lineName)) {
      this.disabledLines = this.disabledLines.filter(x => x != lineName);
    } else this.disabledLines = [...this.disabledLines, lineName];
  }

  lineIsDisabled(lineName){
    return this.disabledLines.includes(lineName);
  }

  private periodLabel(period) {
    const aggregationPeriod = this.aggregationPeriod.toLowerCase();
    return aggregationPeriod == 'day' ? DateTime.fromISO(period.start).toLocaleString(DateTime.DATE_MED) : aggregationPeriod == 'week' ? `${DateTime.fromISO(period.start).toLocaleString(DateTime.DATE_SHORT)}-${DateTime.fromISO(period.end).toLocaleString(DateTime.DATE_SHORT)}` : period.label;
  }
}
