import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'ftpTotalFor',
})
export class FtpTotalForPipe implements PipeTransform {
  transform(item: any, report: any, isTimeframe?: boolean): any {
    let row;
    if (isTimeframe) {
      row = report?.total;
    } else {
      row = report && item ? (item.t == 'g' ? report.g[item.id]?.total : report.f[item.id]?.total) : false;
    }
    return (row ? row : {a: 0, v: 0, fv: 0, c: 0, r: 0, ro: 0, fr: 0, fc: 0});
  }
}