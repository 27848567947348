import { State, Action, StateContext } from '@ngxs/store';

import { SetConversionEvents, ClearConversionEvents, SetRevenueEvents } from '../actions/conversion-events.action';
import { ConversionEvent } from 'src/app/core/models/conversionEvent.model';
import { Injectable } from '@angular/core';

export interface ConversionEvents {
    events: ConversionEvent[];
    revenueEvents: ConversionEvent[];
}

@State<ConversionEvents>({
  name: 'conversionEvents',
  defaults: {
    events: [],
    revenueEvents: []
  }
})
@Injectable()
export class ConversionEventsState {
  @Action(SetConversionEvents)
  SetConversionEvents(ctx: StateContext<ConversionEvents>, { payload }: SetConversionEvents) {
    const getState = ctx.getState();
    ctx.setState({...getState, events: payload});
  }

  @Action(SetRevenueEvents)
  SetRevenueEvents(ctx: StateContext<ConversionEvents>, { payload }: SetRevenueEvents) {
    const getState = ctx.getState();
    ctx.setState({...getState, revenueEvents: payload});
  }

  @Action(ClearConversionEvents)
  ClearConversionEvents(ctx: StateContext<ConversionEvents>) {
    const getState = ctx.getState()
    ctx.setState({...getState, events: [], revenueEvents: []});
  }
}
