import { Inject, Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

import { ErrorDialogService } from '../services/error-dialog/error-dialog.service';
import Rollbar from 'rollbar';
import { RollbarService } from '../error-handler/rollbar-error-handler';
import { BaseService } from '../services/base/base.service';
import { environment } from 'src/environments/environment';

export interface ErrorData {
    reason?: any;
    status?: any;
    requestUrl?: any;
    error?: any;
}

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {
    constructor(@Inject(RollbarService) private rollbar: Rollbar, private errorDialogService: ErrorDialogService, private baseService: BaseService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const reportingEndpoint = environment.reportingApiUrl;

        const whitelistReportingEndpoints = [
            {endpoint: '/v2/reports', method: 'POST' }
        ]

        let handle = next.handle(request);

        if (
            request.url.includes(reportingEndpoint)
            && !whitelistReportingEndpoints.includes({
                endpoint:request.url.replace(reportingEndpoint, ''),
                method: request.method
            })
        ) {
          handle = handle.pipe(retry({count: 1, delay: (error) => {
            const errorMessage = this.baseService.checkAndModifyErrorMessage(error);
            this.rollbar.warning(errorMessage);
            console.warn(errorMessage);
            return timer(1000)
          } , resetOnSuccess: false}));
        }
        return handle.pipe(
            catchError((error: HttpErrorResponse) => {
                let data: ErrorData = {};
                data = {
                    reason: error.error && error.error.message ? error.error.message : '',
                    status: error.status,
                    requestUrl: request.url,
                    error: error
                };
                this.errorDialogService.checkHttpErrorResponse(data);
                return throwError(() => error);
            })
        );
    }
}

