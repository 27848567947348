import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash-es';

@Pipe({
  name: 'dashboardFirstExpiredOauth'
})
export class DashboardFirstExpiredOauthPipe implements PipeTransform {
  transform(id: any, expiredOauthChannels: any,): any {
    return find(expiredOauthChannels, x => {
      return x.filter_group_id === id;
    })?.type;
  }
}