<ul class="nav nav-tabs">
  <li class="nav-item">
    <div class="nav-link"
       [class.active]="isAggregated"
       [routerLink]="['', project?.identifier, componentNames.paths]"
       [queryParams]="{tab: 'common'}"
       >Common Paths</div>
  </li>
  <li class="nav-item">
    <div class="nav-link"
       [class.active]="!isAggregated"
       [routerLink]="['', project?.identifier, componentNames.paths]"
       [queryParams]="{tab: 'individual'}"
       >Individual Paths</div>
  </li>
</ul>

<div class="header paths__table scroll-container" id="scroll-container"
     infinite-scroll-container="'#app_pane.paths'" infinite-scroll='morePaths()' infinite-scroll-distance='1'>
  <div class="dash__row dash__row--heading">
    <div class="d-flex flex-row">
      <div class="position-relative control">
        <small class='controllbar__hint top'>Display Labels as</small>
        <div class="dropdown" ngbDropdown>
          <button class="btn btn-default dropdown-toggle" ngbDropdownToggle>
            <i class="fa-solid fa-list blue"></i>
            {{ labels === 'channels' ? 'Channels' : 'Filters' }}
          </button>
          <ul class="dropdown-menu" role="menu" ngbDropdownMenu>
            <li class="dropdown-item" *ngIf="labels == 'channels'" (click)="channelOrFilterSelected('filters')">Filters</li>
            <li class="dropdown-item" *ngIf="labels == 'filters'"  (click)="channelOrFilterSelected('channels')">Channels</li>
          </ul>
        </div>
      </div>
      <div class="position-relative control ms-3" *ngIf="isAggregated">
        <small class='controllbar__hint top'>Combine Recurring Touchpoints</small>
        <div class="dropdown" ngbDropdown>
          <button class="btn btn-default dropdown-toggle" ngbDropdownToggle>
            <i class="fa-solid fa-puzzle-piece blue"></i>
            {{ isCombineRecurring ? 'ON' : 'OFF' }}
          </button>
          <ul class="dropdown-menu" role="menu" ngbDropdownMenu>
            <li class="dropdown-item" *ngIf="isCombineRecurring" (click)="toggleCombineRecurring()">OFF</li>
            <li class="dropdown-item" *ngIf="!isCombineRecurring" (click)="toggleCombineRecurring()">ON</li>
          </ul>
        </div>
      </div>
      <div class="d-flex">
        <div class="position-relative control ms-3">
          <small class='controllbar__hint top'>Filter</small>
          <div class="dropdown" ngbDropdown>
            <button class="btn btn-default dropdown-toggle text-capitalize" ngbDropdownToggle>
              <i class="fa-solid fa-filter blue"></i>
              {{ filterType | pathsGetFilterTypeLabel:filterTypes }}
            </button>
            <ul class="dropdown-menu" role="menu" ngbDropdownMenu>
              @for (type of filterTypes; track type) {
                <li class="dropdown-item" (click)="chooseFilterType(type)">{{ type.label }}</li>
              }
            </ul>
          </div>
        </div>
        <div *ngIf="filterType !== 'none'" class="position-relative ms-3 d-flex align-items-center">
          <app-filter-search
            (filterSelected)="chooseFilterOrGroup($event)"
            [isSettings]="true"
            [isVFilterModal]="true"
            [shouldAllowSpecials]="true"
            [selectedLabel]="filterOrGroup?.name"
            [withoutVirtuals]="true"
          ></app-filter-search>
          <div *ngIf="filterOrGroup" class="blue clickable ms-2" (click)="chooseFilterOrGroup(null)">
            Clear
          </div>
        </div>
      </div>
    </div>
    <div>
      <button class="btn btn-default control" (click)="downloadCSVClicked()">
        <i class="fa-solid fa-download blue"></i> Download CSV
      </button>
    </div>
  </div>
</div>
