<div class="attr-card mt-4">
  <div class="include_only_users_registered_in_daterange" [class.project-option-disabled]="disabled">
    <div class="row mb-4">
      <div class="col-9">
        <p class="title-no-bold" *ngIf="showTitle">Include only Users Registered in Date Range <span class="badge-sm badge-top badge badge-success">beta</span> <i class="fa-solid fa-lock red me-1" *ngIf="!project?.options?.features?.include_only_users_registered_in_daterange"></i></p>
      </div>
      <div class="col-3 d-flex justify-content-end">
        <app-toggle-switch
          [isOn]="project?.include_only_users_registered_in_daterange"
          (eventEmitter)="toggleIOURID($event)"></app-toggle-switch>
      </div>
    </div>

    <p class="medium-dark">When enabled it will only show conversions and revenue from users with <a href="https://docs.attributionapp.com/docs/identify#special-properties-for-identify" target="_blank">"createdAt"</a> within selected date range.</p>
  </div>
</div>
