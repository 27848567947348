import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findAllFiltersAndChannels'
})
export class FindAllFiltersAndChannelsPipe implements PipeTransform {
  transform(items: any[], allFilters: any[], allChannels: any[], ordinals: any[]): any {
    const arr = items.map( (item, i) => {
        if (item.t === 'g') {
          const channel = allChannels.find( c => c.id === item.id)
          return {
              ...channel,
              ordinal: ordinals?.find(x => x.id === item.id && x.t === item.t)?.ordinal || i,
          }
        } else {
          const filter = allFilters.find( f => f.id === item.id);
          return {
            ...filter,
            ordinal: ordinals?.find(x => x.id === item.id && x.t === item.t)?.ordinal || i,
          }
        }
    });
    return arr;
  }
}