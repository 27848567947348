import { Component, Input, OnChanges } from '@angular/core';
import { Integration } from 'src/app/core/constants/integration';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-filter-badge',
  templateUrl: './filter-badge.component.html',
  styleUrls: ['./filter-badge.component.scss']
})
export class FilterBadgeComponent implements OnChanges {
  @Input() filter;
  @Input() isChannels: boolean = false;
  @Input() title: String;
  @Input() displayName: String;
  @Select( state => state.worker.filterWorkerInProgress) filterWorkerInProgress$: Observable<boolean>;

  icon;
  caption: String;
  isHidden: boolean;
  pathToFilter: String;

  constructor(private filterTreeWorkerService: FilterTreeWorkerService) {}

  ngOnChanges() {
    if (this.filter) {
      if (this.filterTreeWorkerService.filters) {
        this.pathToFilter = this.filterTreeWorkerService.filters[this.filter.id]?.path?.names?.join(' > ') || '';
      } 
    }
    this.icon = !this.isChannels ? Integration.get.icons[Integration.get.handles[this.filter.channel_name]] || Integration.get.icons[Integration.get.handles[this.filter.name]] : this.filter.channel_id ? Integration.get.icons[Integration.get.handles[this.filter.channel_name]] : Integration.get.icons[Integration.get.handles[this.filter.name]];
    this.caption = this.displayName || this.isChannels ? this.filter.channel_id ? this.filter.channel_name : this.filter.name : this.filter.name;
    this.isHidden = this.filter.channel_name === 'hidden';
  }

}
