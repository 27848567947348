import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Integration, IntegrationTypes } from 'src/app/core/constants/integration';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SettingsSections } from 'src/app/core/enums/settings-sections';
import { IntegrationsService } from 'src/app/core/services/integrations/integrations.service';
import { FilterErrorsModalComponent } from 'src/app/modules/shared/components/filter-errors-modal/filter-errors-modal.component';
import { ClearOauthErrors } from 'src/app/store/actions/project.actions';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { IntegrationSettingsComponent } from '../integration-settings/integration-settings.component';
import { ManageIntegrationModalComponent } from '../manage-integration-modal/manage-integration-modal.component';
import { ReadMeComDocsService } from 'src/app/core/services/readMe-com-docs/readMe-com-docs.service';
import { environment } from 'src/environments/environment';
import { JobLogsComponent } from '../job-logs/job-logs.component';

@Component({
  selector: 'app-integration-show',
  templateUrl: './integration-show.component.html',
  styleUrls: ['./integration-show.component.scss']
})
export class IntegrationShowComponent implements OnInit, OnDestroy {
  @Select(state => state.project) $project: Observable<ProjectStateModel>;

  assetPath = environment.assetPath;
  project;
  oauths = [];
  integrationName;
  fromAdminDict: {name: string, newest: string, checkArray: string[]};
  beingDeleted = [];
  legacyIntegrations = ['adwords', 'facebook3', 'bing', 'linkedin'];

  //!!!REMEMBER TO CHECK HTML TEMPLATE AND IF LOGO LOOKS OK WHEN ADDING NEW INTEGRATION!!!
  nonDefaultLogos = Integration.get.NonDefaultLogos;
  //!!!REMEMBER TO CHECK HTML TEMPLATE AND IF LOGO LOOKS OK WHEN ADDING NEW INTEGRATION!!!

  learnMoreLink;

  docs;
  slug: string;

  paramSub: Subscription;
  queryParamSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private integrationsService: IntegrationsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private store: Store,
    private readmeComDocsService: ReadMeComDocsService,
  ) { }

  ngOnInit(): void {
    this.paramSub = combineLatest([this.$project, this.route.params]).subscribe(([project, params]) => {
      this.fromAdminDict = Integration.get.AdminDict.find(x => x.name == params.integration);
      if (this.fromAdminDict) {
        this.integrationName = this.fromAdminDict.newest;
      } else {
        this.integrationName = params.integration;
      }
      this.slug = Integration.get.readMeComSlugs[this.integrationName];
      if (!this.docs && this.slug) {
        this.readmeComDocsService.get(this.slug).subscribe( (x: any) => {
          this.docs = x;
        });
      }

      this.project = project.project;

      if (this.integrationName) {
        if (!Integration.get.IsIntegrationOpen(this.integrationName, this.project)) {
          this.router.navigate([this.project.identifier, ComponentNames.settings, SettingsSections.integrations], {queryParams: {type: Integration.get.isConnector(this.integrationName) ? 'conversions' : 'ads'}});
            setTimeout(() =>
              this.toastr.warning(
                "You don't have access to this integration",
                ``,
                {disableTimeOut: true, enableHtml: true}
            )
          );
        }
      }

      this.learnMoreLink = Integration.get.config[this.integrationName].docLink;

      let oauths = [];
      const target = this.fromAdminDict ? this.fromAdminDict.checkArray : [this.integrationName];
      this.project?.oauths.forEach( x => {
        if (target.includes(x.type)) oauths.push(x);
      });
      this.oauths = oauths;
    });

    this.queryParamSub = this.route.queryParams.subscribe( qParams => {
      if (qParams.showSettings) {
        const oauth = this.oauths.find(x => x.id == Number(qParams.showSettings));
        if (oauth) {
          this.editSettings(oauth);
        } else {
          this.toastr.error('Oauth not found');
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
    this.queryParamSub?.unsubscribe();
  }

  goBack() {
    const type = typeof(Integration.get.names[IntegrationTypes.integration][this.integrationName]) != 'undefined' ? 'ads'
                : typeof(Integration.get.names[IntegrationTypes.connector][this.integrationName]) != 'undefined' ? 'conversions'
                : 'transformers';
    this.router.navigate([
      this.project.identifier,
      ComponentNames.settings,
      SettingsSections.integrations
    ], { queryParams: {
      type: type
    }});
  }

  setup(reconnect = false) {
    var intName = this.integrationName == 'facebook3' ? 'facebook4' : this.integrationName;
    intName = this.integrationName == 'googleads' ? 'googleads2' : intName;
    if (!reconnect){
      intName = this.integrationName == 'linkedin' ? 'linkedin2' : intName;
      intName = this.integrationName == 'bing' ? 'msads' : intName;
    }
    this.integrationsService.start({integrationName: intName, isOnboarding: false, skipExplainer: true});
  }

  deleteOauth(oauth) {
    this.integrationsService.deleteOauth(oauth).subscribe( x => {
      this.beingDeleted.push(oauth.id);
    });
  }

  disconnectOauth(oauth) {
    this.integrationsService.disconnectOauth(oauth).subscribe( x => {
      setTimeout(() =>
        this.toastr.warning(
          'The integration will be disconnected shortly.',
          ``,
          {disableTimeOut: true, enableHtml: true}
        )
      );
    });
  }

  editSettings(oauth) {
    if (this.beingDeleted.includes(oauth.id)) return;
    const integration = {...Integration.get.names[IntegrationTypes.integration], ...Integration.get.names[IntegrationTypes.connector], ...Integration.get.names[IntegrationTypes.transformer]}[oauth.type];
    const modalRef = this.modalService.open(IntegrationSettingsComponent, {windowClass: 'slideInDown', size: 'lg'});
    modalRef.componentInstance.oauth = oauth;
    modalRef.componentInstance.integrationName = integration;
  }

  openJobLogs(oauth) {
    const modalRef = this.modalService.open(JobLogsComponent, {windowClass: 'slideInDown', size: 'lg'});
    modalRef.componentInstance.oauth = oauth;
  }

  navigateToIntegrationErrorModal(oauth) {
    const queryParamsToPass: any = {};
    queryParamsToPass.error_oauth_id = oauth.id
    this.router.navigate([], {
      queryParams: queryParamsToPass
    });
    const modalRef = this.modalService.open(FilterErrorsModalComponent, {windowClass: 'slideInDown', size: 'lg'});
    modalRef.componentInstance.oauth = oauth;
    modalRef.componentInstance.type = 'integration';
    modalRef.result.then(result =>{
      if (result.result == 'Integration errors cleared') {
        this.store.dispatch(new ClearOauthErrors(oauth.id));
      }
      this.removeErrorIdFromParams();
    }, () => {
      this.removeErrorIdFromParams();
    });
  }

  removeErrorIdFromParams() {
    const {error_oauth_id, ...otherParams} = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: otherParams
    });
  }

  canManage(oauth) {
    return (Integration.get.names[IntegrationTypes.integration][oauth.type] || oauth.type == 'facebook4') && (this.oauthStatus(oauth) == 'Connected') && (oauth.type !== 'stripe') && (oauth.type !== 'segment_reverse');
  };

  oauthIcon(oauth) {
    if (oauth.identifier === null) {
      return 'fa-times-circle red';
    } else if (oauth.expired_at) {
      return 'fa-pause-circle orange';
    } else {
      return 'fa-check-circle green';
    }
  };

  oauthStatus(oauth) {
    if (oauth.identifier === null) {
      return 'Invalid';
    } else if (oauth.expired_at) {
      return 'Paused';
    } else {
      return 'Connected';
    }
  };

  manage(oauth) {
    if (this.beingDeleted.includes(oauth.id)) return;
    const modalRef = this.modalService.open(ManageIntegrationModalComponent, {size: 'lg', windowClass: 'slideInDown'});
    modalRef.componentInstance.oauth = oauth;
  }

  isNotTransformerOrIsSettingsEnabbled(oauth) {
    const config = Integration.get.config[oauth.type];
    return config.type !== IntegrationTypes.transformer || config.settingsEnabled === true;
  }
}
