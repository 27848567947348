export class SetCohort {
    static readonly type = '[cohort] SetCohort';
    constructor(public payload: any) {}
}

export class SetCohortInProgress {
    static readonly type = '[cohort] SetCohortInProgress';
    constructor(public payload?: {isFalse: boolean}) {}
}

export class SetCohortFilter {
    static readonly type = '[cohort] SetCohortFilter';
    constructor(public payload: { filter: any}) {}
}

export class ClearCohort {
    static readonly type = '[cohort] ClearCohort';
}
