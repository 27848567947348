<div class="modal-header">
  <div class="modal-title">
    <h3 class="mb-0 mt-0">Please Rename Project</h3>
  </div>
</div>
<div class="alert modal_alert alert-warning">
  <i class="fa-solid fa-triangle-exclamation"></i>
  The project name can not be 'My Project' please rename to something different.
</div>
<div class="modal-body">
  <div class="container">
    <input type="text" class="form-control" [(ngModel)]="projectName" name="projectName">
  </div>
</div>
<div class="modal-footer justify-content-end">
  <button class="btn btn-success" (click)="createProject()">Submit</button>
</div>
