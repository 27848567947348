import { each } from 'lodash-es';
import { DateTime } from 'luxon';

const sameDate = (item, timezone, previousItem, timeMethod) => {
  const TimeZone = timezone || 'utc';
  return DateTime.fromISO(item[timeMethod], {zone: TimeZone}).ordinal === DateTime.fromISO(previousItem[timeMethod], {zone: TimeZone }).ordinal;
};

const convertToTimline = (items, timeZone, timeMethod?, defaultType?, includeFirst?) => {
  const timeline = [];
  let previousItem;

  const method = timeMethod || 'time';
  const type = defaultType || 'visit';
  includeFirst = (includeFirst === undefined ? true : includeFirst);

  each(items, (item) => {
    if (includeFirst && (!previousItem || !sameDate(item, timeZone, previousItem, method))) {
      timeline.push({
        type: 'date',
        value: item[method]
      });
    }
    includeFirst = true;

    item.type = item.type || type;
    timeline.push(item);
    previousItem = item;
  });

  return timeline;
};

export { convertToTimline };
