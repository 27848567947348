import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '../models/account.model';
import { Project } from '../models/project.model';
import { isShopifySegment } from '../helpers/is-shopify-segment';

@Pipe({
    name: 'isShopifySegment'
})
export class IsShopifySegmentPipe implements PipeTransform {
    transform(project: Project, account: Account,): boolean {
        // Check if account and project are valid
        return isShopifySegment(account, project);
    }
}