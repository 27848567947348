<app-loader *ngIf="isLoading || summaryIsLoading" [backgroundColor]="'rgba(0, 0, 0, 0.75)'"></app-loader>
<div class='cp-top-wrapper'>
  <div class='hp-15'>
    <div class="charts-wrapper">
      <div>
        <app-cp-summary
        [className]="'summary-wrapper row no-gutters'"
        [channelName]="activeVirtualFilter? activeVirtualFilter.n : null"
        [summary]="summary"
        [prevSummary]="prevSummary"
        [prevSummaryParams]="prevSummaryParams"></app-cp-summary>
      </div>
      <div class="all-section" *ngIf="report && openedChannel === null" [@slideInOut]>
        <!-- `All Traffic` | virtual groups navigation -->
        <div class="area-title">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item" [ngClass]=" !activeVirtualFilter == true ? 'traffic-active' : 'traffic-inactive'">
                <span [class.cursor-pointer]="activeVirtualFilter" (click)="goBackFromVirtualFilter()">{{ singleReport(0).report.name }}</span>
              </li>
              @for (vFilter of virtualFilters; track vFilter) {
                <li [ngClass]="activeVirtualFilter?.id == vFilter.id ? 'traffic-active' : 'traffic-inactive'" class="breadcrumb-item virtual">
                  <span [class.cursor-pointer]="activeVirtualFilter?.id !== vFilter.id" (click)="openVirtualFilter(vFilter)">{{ vFilter.n }}</span>
                </li>
              }
            </ol>
          </nav>
        </div>

        <app-combo-chart
          *ngIf="!isReportEmpty(singleReport(activeVirtualFilter?.id || 0).report.list); else noDataAvailable"
          [index]="null"
          [tooltipName]="'channel_performance_chart'"
          [channel]="singleReport(activeVirtualFilter?.id || 0).report.list">
        </app-combo-chart>

        <ng-template #noDataAvailable>
          <div class="no-data-available">
            No data available
          </div>
        </ng-template>

        <div *ngIf="totals" class="totals">
          <div class="area-title breadcrumb" *ngIf="!activeVirtualFilter; else summaryVirtualFilterTitle">Channel Performance Summary</div>
          <ng-template #summaryVirtualFilterTitle><div class="area-title breadcrumb">{{ activeVirtualFilter.n }} Channels Summary</div></ng-template>
          <app-cp-totals
            *ngIf="!isTotalsEmpty; else noDataAvailable"
            [totals]="totals"
            (openChannel)='openChannel($event)'>
          </app-cp-totals>
        </div>
      </div>
      <div class="channel-section" *ngIf="report && openedChannel !== null" [@slideInOut]>
        <div class="area-title" (click)="goBack()">
          <span class="cursor-pointer go-back">
            <i class="fa-solid fa-circle-left"></i>
            {{activeVirtualFilter ? activeVirtualFilter.n : singleReport(0).report.name}}
          </span>
        </div>
        <div class='cp-divider'></div>
        <div class="area-title">
          <div>
            {{ singleReport(openedChannel).report.name }}
          </div>
        </div>

        <div>
          <div *ngIf="!channelSummary" class="mb-5">
            <app-loader [position]="'relative'" [color]="'green'" [backgroundColor]="'transparent'">
            </app-loader>
          </div>
          <app-cp-summary
            *ngIf="channelSummary"
            [className]="'summary-wrapper row no-gutters no-margin-bottom'"
            [summary]="channelSummary"
            [channelName]="singleReport(openedChannel).report.name"
            [isLoading]="openChannelIsLoading"></app-cp-summary>
        </div>

        <app-combo-chart
          [tooltipName]="'channel_performance_chart'"
          [index]="singleReport(openedChannel).index"
          [channel]="singleReport(openedChannel).report.list">
        </app-combo-chart>
      </div>
    </div>
    <div>
      <hr>
      <div *ngIf="totals" id="comparison">
        <div class="comparsion-wrapper">
          <app-cp-compare-vertical>
          </app-cp-compare-vertical>
        </div>
      </div>
    </div>

  </div>
</div>
