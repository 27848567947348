<div class="attr-card mt-4">
  <p class="title-no-bold mb-4" *ngIf="showTitle">Timezone</p>

  <p class="medium-dark">All data will be align to match selected timezone. When you integrate with Ads providers like Facebook or Adwords please make sure that your timezone matches across platforms that is critical for correct spending reporting.</p>

  <div class="timezone-select">
    <ng-select #select class='ng-form-control'
      [clearable]="false"
      [items]="timezones"
      [groupBy]="groupTimezones"
      [(ngModel)]="selectedTimezone"
      (ngModelChange)="selectTimezone()"
      [virtualScroll]="true">
    </ng-select>
  </div>
</div>
