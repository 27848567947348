import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { SetAccesses } from 'src/app/store/actions/settings.actions';
import { Access } from '../../models/access.model';
import { Project } from '../../models/project.model';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  get(returnAccesses?): any {
    const Url = `${environment.clientApiUrl}/accesses`;

    if (returnAccesses) {
      return this.httpClient.get(Url);
    } else {
      return this.httpClient.get(Url).subscribe( x => {
        this.store.dispatch(new SetAccesses(x));
      });
    }
  }

  create(email) {
    const Url = `${environment.clientApiUrl}/accesses`;

    return this.httpClient.post(Url, {email: email});
  }

  delete(id) {
    const Url = `${environment.clientApiUrl}/accesses/${id}`;

    return this.httpClient.delete(Url);
  }

  getAccessForProject(project: Project) {
    const Url = `${environment.clientApiUrl}/access/for_project/${project.id}`;
    return this.httpClient.get(Url);
  }

  save(access: Access) {
    const Url = `${environment.clientApiUrl}/access/${access.id}`;
    return this.httpClient.post(Url, access).subscribe({ error: e => console.error(e) });
  }
}
