import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  get(searchQuery, since?) {
    const Url = `${environment.reportingApiUrl}/v2/company/search`;

    const Params: any = {};

    if (searchQuery) { Params.q = searchQuery; }

    if (since) { Params.since = since; }

    return this.httpClient.post(Url, {}, {params: Params, observe: 'body'});
  }

  getCompanyInfo(id) {
    const Url = `${environment.reportingApiUrl}/v2/company/${id}`;

    return this.httpClient.get(Url);
  }

  getHistory(id, requestParams?) {
    const Url = `${environment.reportingApiUrl}/v2/company/${id}/history`;

    return this.httpClient.post(Url, {} , {params: requestParams, observe: 'body'});
  }

  getHistoryEvent(visitorOrCompanyId, eventId, queryParams) {
    const Url = `${environment.reportingApiUrl}/v2/company/${visitorOrCompanyId}/history/${eventId}`;

    return this.httpClient.get(Url, {params: queryParams, observe: 'body'});
  }

}
