import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Project } from '../models/project.model';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'projectCurrency'
})
export class ProjectCurrencyPipe implements PipeTransform {
  CP: CurrencyPipe = new CurrencyPipe('en-US');

  constructor(private store: Store) {}

  transform(value: number, dollarsOrCents?: string, digitsInfo?: string, shouldRound?: boolean ): any {
    let CurrencySymbol = null;
    const ProjectState = this.store.selectSnapshot<Project>(state => state.project.project);
    if (ProjectState) {
      CurrencySymbol = ProjectState.currency_symbol;
    }
    let digits = '1.2';
    let currency = 'USD';
    if (CurrencySymbol) {
      switch (CurrencySymbol) {
        case '€':
          currency = 'EUR';
          break;
        case '£':
          currency = 'GBP';
          break;
        case '¥':
          currency = 'JPY';
          break;
        case '₹':
          currency = 'INR';
          break;
        case '₽':
          currency = 'RUB';
          break;
        default:
          currency = ProjectState.currency_symbol;
      }
    }
    if (digitsInfo) {
      digits = digitsInfo;
    }

    let val = shouldRound ? Math.round(value) : value;

    val = dollarsOrCents == 'dollars' ? val : (val / 100);

    if (value === null || typeof(value) === 'undefined' || isNaN(value)) val = 0;

    return this.CP.transform(val, currency, 'symbol', digits);
  }

}
