<div class="dash-header-wrapper">
  <div class="dash__row dash__row--heading">
    <div class="item-name stretchable" placement="right" ngbTooltip="{{dragDisabled ? null : 'Disable drag to enable sorting'}}" [autoClose]="true" triggers="manual" #t="ngbTooltip">
      <span *ngIf="!inSearchingState">
        <div *ngIf="!isFTP" class="dropdown" ngbDropdown placement="bottom-left">
          <button class="btn btn-default dropdown-toggle" [class.btn-lg]="!isTimeframe" #alignDropdown type="button" id="dropdownAddFilterOrChannel" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle ngbTooltip="Add new Filter, Channel or Group" placement="right" container="body">
            <i class="fa-solid fa-plus fa-fw blue"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownAddFilterOrChannel" ngbDropdownMenu>
            <span class="dropdown-item" (click)="openNewFilterModal()">Add New Filter</span>
            <span class="dropdown-item" (click)="addNewChannel()">Add New Channel</span>
            <span class="dropdown-item" (click)="addNewGroup()">Add New Group</span>
            <span class="dropdown-item" [class.hide-prod]="!(project?.id | includedInArray:whitelistedProjectsForFiltersCsvUpload)" (click)="openFiltersCsvUpload()">Upload Filters CSV</span>
          </div>
        </div>

        <span *ngIf="!isFTP" class="btn btn-default" data-cy="filter-search-button" [class.btn-lg]="!isTimeframe" (click)="toggleSearchingState()" placement="right" ngbTooltip="Find filter or channel">
          <i class="fa-solid fa-search fa-fw blue"></i>
        </span>
        <button *ngIf="!isFTP" class="btn btn-default btn-drag no-margin" [class.btn-lg]="!isTimeframe" [class.active]="!dragDisabled" [class.disabled]="sidebar?.openedSidebar !== null" (click)="toggleDragging()" placement="right" ngbTooltip="{{dragDisabled ? 'Dragging is disabled' : 'Dragging is enabled'}}"><i class="fa-solid fa-up-down-left-right fa-fw blue"></i></button>
        <div class="d-inline-block" *ngIf="!isProd || isTimeframeOpen">
          <button *ngIf="!isFTP" class="btn btn-default btn-drag no-margin" [class.btn-lg]="!isTimeframe" [class.active]="isTimeframe" (click)="toggleTimeFrame()" placement="right" ngbTooltip="Toggle Timeframe"><i class="fa-solid fa-clock-rotate-left blue"></i></button>
        </div>
        <div *ngIf="isTimeframe || isFTP" class="dropdown" ngbDropdown>
          <button class="btn btn-default dropdown-toggle" type="button" #revenueDropdown  id="dropdownMenuButton" [attr.data-boundary]="revenueDropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <i class="fa-solid fa-dollar-sign fa--header"></i>
            {{ tableRevenue == 'Absolute' ? 'Abs' : 'Rel' }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
            <span class="dropdown-item" (click)="changeTableRevenue('Absolute')">Absolute</span>
            <span class="dropdown-item" (click)="changeTableRevenue('Relative')">Relative</span>
          </div>
        </div>
        <div *ngIf="(withSpendNoVisits.length > 0 || unattributedPercent > 0) && !isProd && !isFTP" ngbDropdown class="d-inline-block">
          <button class="btn btn-default btn-drag no-margin" [class.btn-lg]="!isTimeframe" ngbDropdownToggle ngbTooltip="Filter Alerts" placement="right"><i class="fa-solid fa-triangle-exclamation orange"></i></button>
          <div ngbDropdownMenu aria-labelledby="withSpendNoVisits" class="dropdown-wrapper">
            <div *ngIf="unattributedPercent > 0" class="alert-dropdown-item unattributed clickable" ngbDropdownItem (click)="scrollToUnattributed()">
              <div class="left-part">
                <i class="fa-solid fa-triangle-exclamation orange" ngbTooltip="This filter has spend but no visits"></i>
              </div>
              <div class="right-part">
                Unknown source revenue is more than {{ unattributedPercent | percent:'1.0'}} of total
              </div>
            </div>
            <div *ngIf="withSpendNoVisits.length > 0" class="alert-dropdown-item">
              <div class="left-part more-padding">
                <i class="fa-solid fa-exclamation orange"></i>
              </div>
              <div class="right-part">
                You have {{ withSpendNoVisits.length }} filters with {{ noVisitsTotalAmount | projectCurrency }} ({{ noVisitsTotalAmount/totalAmounts | percent: '1.0' }}) total spend that don't track visits
              </div>
            </div>
            <div class="noSpendWrapper">
              <div *ngFor="let item of withSpendNoVisits" class="alert-dropdown-item clickable" (click)="showSelectedFilter({item: item})" ngbDropdownItem>
                <div class="left-part">
                  <div class="icon-wrapper">
                    <app-filter-icon
                    [item]="item"
                    [isAlerts]="true"></app-filter-icon>
                  </div>
                </div>
                <div class="right-part">
                  <div class="alert-parent-name">{{item?.topParent?.n}}</div>
                  <div class="alert-name">{{item?.n}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
      <div *ngIf="inSearchingState">
        <app-filter-search
          (filterSelected)="showSelectedFilter($event)"
          [isOnDashboard]="true"
          (close)="toggleSearchingState($event)"></app-filter-search>
      </div>
    </div>
    <ng-template #noTimeframe>
      <div class="flex-item" (click)="dragDisabled ? null : t.open()">
        <div class="dash__heading">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'amounts'"
            (mouseleave)="sortHover = null"
            (click)="sort('amounts')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'amounts'"
            [sortDirection]="sortDirection"
            [text]="'Spend'"></app-sort>
        </div>
        <div class="dash__heading dash__heading sub">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'costPerConversion'"
            (mouseleave)="sortHover = null"
            (click)="sort('costPerConversion')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'costPerConversion'"
            [sortDirection]="sortDirection"
            [text]="'Cost Per Conversion'"></app-sort>
        </div>
      </div>
      <div class="flex-item" (click)="dragDisabled ? null : t.open()">
        <div class="dash__heading">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'visits'"
            (mouseleave)="sortHover = null"
            (click)="sort('visits')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'visits'"
            [sortDirection]="sortDirection"
            [text]="'Visits'"></app-sort>
        </div>
        <div class="dash__heading dash__heading sub">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'conversionRate'"
            (mouseleave)="sortHover = null"
            (click)="sort('conversionRate')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'conversionRate'"
            [sortDirection]="sortDirection"
            [text]="'Conversion Rate'"></app-sort>
        </div>
      </div>
      <div class="flex-item" (click)="dragDisabled ? null : t.open()">
        <div class="dash__heading">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'conversions'"
            (mouseleave)="sortHover = null"
            (click)="sort('conversions')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'conversions'"
            [sortDirection]="sortDirection"
            [text]="'Conversions'"></app-sort>
        </div>
        <div class="dash__heading dash__heading sub">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'revenuePerConversion'"
            (mouseleave)="sortHover = null"
            (click)="sort('revenuePerConversion')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'revenuePerConversion'"
            [sortDirection]="sortDirection"
            [text]="'Revenue Per Conversion'"></app-sort>
        </div>
      </div>
      <div class="flex-item" (click)="dragDisabled ? null : t.open()">
        <div class="dash__heading">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'revenue'"
            (mouseleave)="sortHover = null"
            (click)="sort('revenue')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'revenue'"
            [sortDirection]="sortDirection"
            [text]="'Revenue'"></app-sort>
        </div>
        <div class="dash__heading dash__heading sub">
          <app-sort
            class="sortable-link"
            (mouseenter)="sortHover = 'profitOrLoss'"
            (mouseleave)="sortHover = null"
            (click)="sort('profitOrLoss')"
            [sortHover]="sortHover"
            [sortColumn]="sortColumn"
            [sort]="'profitOrLoss'"
            [sortDirection]="sortDirection"
            [text]="'Profit (loss)'"></app-sort>
        </div>
      </div>
    </ng-template>
    <ng-template #timeframe>
      <div class="dash-header-wrapper">
        <div class="dash__row dash__row--heading">
          <div class="vertical-header hdr position-relative">
            Total
          </div>
          <div class="flex-item" *ngFor="let p of tablePeriodsWithoutTotal; let i = index">
            <ng-template #normalPeriod>
              {{ p.id == 'total' ? 'Total' : p.label }}
            </ng-template>
            <span *ngIf="aggregationPeriod != 'day' && (i == 0 || i == tablePeriodsWithoutTotal.length - 1) && p.isNotFullPeriod; else normalPeriod" class="font-italic" ngbTooltip="Period is not complete" container="body">
              {{ p.id == 'total' ? 'Total' : p.label }}&#42;
            </span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="isTimeframe ? timeframe : noTimeframe"></ng-container>
  </div>
</div>
