import { Component, Input, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { toNumber } from 'lodash-es';

import { ConversionService } from 'src/app/core/services/conversion/conversion.service';
import { SidebarStateModel } from 'src/app/store/states/sidebar.state';
import { VisitService } from 'src/app/core/services/visit/visit.service';
import { Project } from 'src/app/core/models/project.model';
import { LocationService } from 'src/app/core/services/location/location.service';
import { SidebarStateService } from 'src/app/core/services/sidebar-state/sidebar-state.service';
import { environment } from 'src/environments/environment';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { BaseService } from 'src/app/core/services/base/base.service';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';
import { Observable, Subscription } from 'rxjs';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';


@Component({
  selector: 'app-visits-sidebar',
  templateUrl: './visits-sidebar.component.html',
  styleUrls: ['./visits-sidebar.component.scss']
})
export class VisitsSidebarComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() sidebar: SidebarStateModel;
  @Input() project: Project;
  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;

  isProd = environment.isProd;
  environmentName = environment.envName;

  componentNames = ComponentNames;

  selector;
  inProgress;
  items = [];
  isEmpty;
  filter;
  timeline = [];
  scope: string;
  service;
  selected = {
    filterType: null,
    filterOrGroupId: null
  };
  isCompanyBasedAttributionEnabled = false;
  channelBuilderQueryParams;
  debugHovered = false;
  isVirtual = false;
  isMore = false;
  IPLocations = {};
  eventBreakdown = [];
  filterTree;
  treeSub: Subscription;

  constructor(
    private conversionService: ConversionService,
    private visitService: VisitService,
    private locationService: LocationService,
    private sidebarState: SidebarStateService,
    private baseService: BaseService,
    private FTWS: FilterTreeWorkerService
  ) { }

  ngAfterViewInit() {
    document.getElementById('sidebar-content').scrollTop = this.sidebarState.scrollPosition;
    if (this.filterTree && this.project) {
      this.initFlow();
    }
  }

  ngOnChanges() {
    if (this.filterTree && this.project) {
      this.initFlow();
    } else {
      this.treeSub?.unsubscribe();
      this.treeSub = this.filterTree$.subscribe(filterTree => {
        if (!this.filterTree && filterTree.filterTree && this.project) {
          this.filterTree = filterTree.filterTree;
          this.initFlow();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.treeSub?.unsubscribe();
  }

  initFlow() {
    this.isCompanyBasedAttributionEnabled = this.project?.options?.features?.company_based_attribution;
    const oldState = this.sidebarState.sidebarState;
    const oldStateKeyArray = Object.keys(oldState);
    if (this.sidebarState.wentBack == true && oldStateKeyArray.length > 0 && oldState.openedSidebar == this.sidebar.openedSidebar) {
      for (let key of oldStateKeyArray) {
        this[key] = oldState[key];
      }
      this.sidebarState.setWentBack(false);
    } else {
      this.inProgress = true;
      this.items = [];
      this.timeline = [];
      this.isVirtual = false;
      this.scope = this.sidebar.options.scope;
      this.service = this.scope === 'conversions' ? this.conversionService : this.visitService;
      if (this.sidebar.options.filter || this.sidebar.options.virtual_filter) {
        if (this.sidebar.options.filter === 'raw') {
          this.selected.filterType = 'raw';
          this.filter = {n: 'raw'};
        } else {
          if (this.sidebar.options.virtual_filter) {
            this.isVirtual = true;
          }
          this.selected.filterType = 'f';
          this.selected.filterOrGroupId = toNumber(this.sidebar.options.filter || this.sidebar.options.virtual_filter);
          this.channelBuilderQueryParams = { filter: this.selected.filterOrGroupId};
        }
      } else if (this.sidebar.options.group) {
        this.selected.filterType = 'g';
        this.selected.filterOrGroupId = toNumber(this.sidebar.options.group);
        this.channelBuilderQueryParams = { filter_group: this.selected.filterOrGroupId};
      } else {
        this.selected.filterType = null;
        this.selected.filterOrGroupId = null;
      }
      this.selector = document.getElementById('sidebar-content');
      if (this.selected.filterOrGroupId) {
        this.filter = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: this.selected.filterOrGroupId, additionalKey: 't', additionalValue: this.selected.filterType});
      } else {
        this.filter = null;
      }

      this.service.getFiltered(this.selected.filterOrGroupId, this.isVirtual ? 'v' : this.selected.filterType, null, this.sidebar.options.periodStart, this.sidebar.options.periodEnd).subscribe(
        (resp: {more: boolean; data: Array<any>; timeline: Array<any>; clicks_timeline: Array<any>; event_breakdown: Array<any> }) => {
          this.items = resp.data;
          this.isMore = resp.more;
          this.isEmpty = this.items.length === 0;
          if (resp.timeline) {
            setTimeout(() => {
            this.timeline = resp.timeline;
            }, 200);
          } else {
            this.timeline = [];
          }
          this.inProgress = false;
          this.eventBreakdown = resp.event_breakdown || [];
          this.locationService.requestBulk(resp.data, 50).subscribe( (resp: any) => {
            this.IPLocations = {
              ...this.IPLocations,
              ...resp
            };
            this.sidebarState.setState({
              openedSidebar: this.sidebar.openedSidebar,
              items: this.items,
              isMore: this.isMore,
              isEmpty: this.isEmpty,
              timeline: this.timeline,
              scope: this.scope,
              selector: this.selector,
              inProgress: this.inProgress,
              filter: this.filter,
              service: this.service,
              selected: this.selected,
              IPLocations: this.IPLocations
            });
          });
        }
      );
    }
  }

  onScroll() {
    if (!this.isMore || this.inProgress) { return; }
    this.inProgress = true;
    //for using offset for conversion events
    // const sinceOrOffset = this.scope === 'conversions' ? this.items.length : this.items[this.items.length-1].time;
    const since = this.items[this.items.length-1][this.scope === 'conversions' ? 'conversion_time' : 'time'];
    this.service.getFiltered(this.selected.filterOrGroupId, this.isVirtual ? 'v' : this.selected.filterType, since, this.sidebar.options.periodStart, this.sidebar.options.periodEnd).subscribe(
      (resp: {more: boolean; data: Array<any>; }) => {
        this.items = this.items.concat(resp.data);
        this.inProgress = false;
        this.isMore = resp.more;
        this.locationService.requestBulk(resp.data, 50).subscribe( (resp: any) => {
          this.IPLocations = {
            ...this.IPLocations,
            ...resp
          };
          this.sidebarState.setState({
            openedSidebar: this.sidebar.openedSidebar,
            items: this.items,
            isMore: this.isMore,
            isEmpty: this.isEmpty,
            timeline: this.timeline,
            scope: this.scope,
            selector: this.selector,
            inProgress: this.inProgress,
            filter: this.filter,
            service: this.service,
            selected: this.selected,
            IPLocations: this.IPLocations
          });
        });
      }
    );
  }

  showVisitor(visitorId) {
    this.baseService.showVisitor(visitorId);
  }

  showCompany(companyId) {
    this.baseService.showCompany(companyId);
  }

  get getName() {
    return typeof(this.filter?.n) != 'undefined' && this.filter?.n != null ? this.filter.n : this.sidebar.options.rowName
  }
}


