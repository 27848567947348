import { Pipe, PipeTransform } from '@angular/core';
import { forEach } from 'lodash-es';

@Pipe({
  name: 'formattedTrait'
})
export class FormattedTraitPipe implements PipeTransform {
  transform(value: any ): any {
    if (typeof(value) == 'object') {
      let htmlToShow = '';
      forEach(value, (v,k) => {
        htmlToShow += `<span>${k}: ${v}</span><br/>`;
      });
      return htmlToShow;
    } else {
      return value;
    }
  }
}
