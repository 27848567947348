@for (path of paths; let i = $index; track i) {
  <div class="dash__row dash__row--parent">
    <div class="col--date dash__name">
      <div class="dash__name">
        {{ path.time | formattedMoment:'DD' }}
      </div>
      <div class="dash__name--sub">
        {{ path.time | formattedMoment:'t' }}
      </div>
    </div>
  
    <div class="col--visitor" [class.reduced-lh]="path.company?.identifier">
      <div *ngIf="path.company?.id">
        <a (click)="toggleSidebarForCompany(path.company?.id)" class="blue small bold">
          {{ path.company?.name || path.company?.identifier || path.company?.id }}
          <i class="fa-solid fa-building muted" placement="top" ngbTooltip="Company ID: {{ path.company?.id}}" container="body"></i>
        </a>
      </div>
      <a (click)="toggleSidebarForVisitor(path.visitor_id)" class="blue" [class.small]="path.company?.identifier">
        {{ path.traits | nameForUser }}
        <i *ngIf="path.traits?.identifier" class="fa-solid fa-user muted" placement="top" ngbTooltip="User ID: {{ path.traits.identifier}}" container="body"></i>
      </a>
      <div class="small muted" *ngIf="(path.traits | nameForUser) !== path.traits?.email">{{ path.traits?.email }}</div>
    </div>
  
    <div class="col--path">
      <div>
        <span *ngIf="tooManyTouchpoints(path)" class="filter">
          <app-filter-badge
            [filter]="{name: 'Hidden', integration: 'hidden'}" ngbTooltip="Too many touchpoints"></app-filter-badge>
        </span>
        @for (touchpoint of lastTouchpoints(i); track touchpoint) {
          <span class="filter">
            <span class="filter--delta small muted" *ngIf="touchpoint.delta">{{ showDelta(touchpoint.delta) }}</span>
            <app-filter-badge
              [filter]="touchpoint.filter"></app-filter-badge>
            <b *ngIf="touchpoint?.series"> <span class="muted times">&times;</span>{{ touchpoint.series.length + 1 }}</b>
          </span>
        }
        <span class="small muted double-arrows">&rsaquo;&rsaquo;</span>
        <strong class="small event-name">{{ conversionEventName }}</strong>
      </div>
    </div>
  </div>  
}
