import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CreateNewFilterResponse, GetFilterResponse } from 'src/app/core/services/filter/filter-service.models';
import { Select, Store } from '@ngxs/store';
import { AddFilter, DeleteFilter, EditFilter } from 'src/app/store/actions/filter-tree.actions';
import { ReportRefresherService } from 'src/app/core/services/report-refresher/report-refresher.service';
import { AddToPendingDataRefresh } from 'src/app/store/actions/base.actions';
import { findInTree } from 'src/app/core/helpers/find-in-tree';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Observable, Subscription } from 'rxjs';
import { Project } from 'src/app/core/models/project.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-filter-modal',
  templateUrl: './new-filter-modal.component.html',
  styleUrls: ['./new-filter-modal.component.scss']
})
export class NewFilterModalComponent implements OnInit, OnDestroy {
  @Input() filterInfo: GetFilterResponse;
  @Input() filterIntegration;
  @Input() eventParams;
  @Select( state => state.project) project$: Observable<ProjectStateModel>;

  filterForm: FormGroup;
  openSection = 'param';
  paramsArray = [];
  referrersArray = [];
  pathsArray = [];
  propertiesArray = [];
  isValid = false;
  waitingForResponse = false;
  deleteWaiting = false;
  pathLacksSlash = false;
  pathFilteringActive = false;

  onlyUnattributed = false;

  project: Project;
  projectSub: Subscription;

  isProd = environment.isProd;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private filterService: FilterService,
    private store: Store,
    private reportRefresher: ReportRefresherService) {}

  choseTab() {
    const isParam = this.filterInfo.params.length > 0;
    const isReferrer = this.filterInfo.referrers.length > 0;
    const isPath = this.filterInfo.paths.length > 0;
    const isEvent = this.filterInfo.properties?.length > 0;
    if (isParam && !isReferrer && !isPath) {
      this.openSection = 'param';
    } else if (!isParam && isReferrer && !isPath) {
      this.openSection = 'referrer';
      if (this.filterInfo.organic === true) {
        this.openSection = 'organic';
      }
    } else if (!isParam && !isReferrer && isPath) {
      this.openSection = 'path';
    } else if (!isParam && !isReferrer && !isPath && isEvent) {
      this.openSection = 'event';
    }
  }

  ngOnInit() {
    this.projectSub = this.project$.subscribe( project => {
      this.project = project.project;
    });
    this.filterForm = this.fb.group({
      name: [{value: null, disabled: this.filterIntegration !== null }, Validators.required],
      eventName: [null, Validators.required],
      formParams: this.fb.array([]),
      formSourceTypes: this.fb.array([]),
      formReferrers: this.fb.array([]),
      formReferrerTypes: this.fb.array([]),
      formPaths: this.fb.array([]),
      formPathTypes: this.fb.array([]),
      isOrganic: this.fb.control(false),
      formProperties: this.fb.array([]),
      formPropertyValues: this.fb.array([])
    });

    this.filterForm.valueChanges.subscribe(() => this.checkIfFormValid());

    if (!this.filterInfo) {
      this.addField(null);
    } else {
      if (this.filterInfo.type) {
        if (['param', 'referrer', 'organic', 'path', 'event'].includes(this.filterInfo.type.toLowerCase())) {
          this.openSection = this.filterInfo.type.toLowerCase();
        } else {
          this.choseTab();
        }
      } else {
        this.choseTab();
      }
      this.filterForm.controls.name.setValue(this.filterInfo.name);
      this.filterForm.controls.isOrganic.setValue(this.filterInfo.organic);
      if (this.filterInfo.params.length > 0) {
        this.filterInfo.params.forEach( item => {
          this.formParams.push(this.fb.control(item.value));
          this.formSourceTypes.push(this.fb.control(item.key));
          this.paramsArray.push(1);
        });
      } else { this.addField('param'); }
      if (this.filterInfo.referrers.length > 0) {
        this.filterInfo.referrers.forEach( item => {
          this.formReferrers.push(this.fb.control(item.value));
          this.formReferrerTypes.push(this.fb.control(item.operator));
          this.referrersArray.push(1);
        });
      } else { this.addField('referrer'); }
      if (this.filterInfo.paths.length > 0) {
        if (this.filterInfo.type.toLowerCase() !== 'path') {
          this.pathFilteringActive = true;
        }
        this.filterInfo.paths.forEach( item => {
          this.formPaths.push(this.fb.control(item.value));
          this.formPathTypes.push(this.fb.control(item.operator));
          this.pathsArray.push(1);
        });
      } else { this.addField('path'); }
      if (this.filterInfo.properties.length > 0) {
        this.eventNameControl.setValue(this.filterInfo.properties.find( x => x.key === '__EVENT_NAME__').value);
        this.filterInfo.properties.filter( x => x.key !== '__EVENT_NAME__').forEach( item => {
          this.formProperties.push(this.fb.control(item.key));
          this.formPropertyValues.push(this.fb.control(item.value));
          this.propertiesArray.push(1);
        });
        this.onlyUnattributed = this.filterInfo.only_unattributed;
      } else { this.addField('event'); }
    }

    if (this.eventParams) {
      this.openSection = 'event';
      this.filterForm.controls.name.setValue(this.eventParams.eventName);
      this.filterForm.controls.eventName.setValue(this.eventParams.eventName);
      this.formProperties.push(this.fb.control(this.eventParams.property));
      this.formPropertyValues.push(this.fb.control(this.eventParams.propValue));
      this.propertiesArray.push(1);
    }
  }

  ngOnDestroy(): void {
    this.projectSub?.unsubscribe();
  }

  addField(section) {
    switch (section) {
      case 'param':
        this.formParams.push(this.fb.control(null));
        this.formSourceTypes.push(this.fb.control(null));
        this.paramsArray.push(1);
        break;
      case 'referrer':
        this.formReferrers.push(this.fb.control(null));
        this.formReferrerTypes.push(this.fb.control(null));
        this.referrersArray.push(1);
        break;
      case 'path':
        this.formPaths.push(this.fb.control(null));
        this.formPathTypes.push(this.fb.control(null));
        this.pathsArray.push(1);
        break;
      case 'event':
        this.formProperties.push(this.fb.control(null));
        this.formPropertyValues.push(this.fb.control(null));
        this.propertiesArray.push(1);
        break;
      default:
        this.formParams.push(this.fb.control(null));
        this.formSourceTypes.push(this.fb.control(null));
        this.formReferrerTypes.push(this.fb.control(null));
        this.formReferrers.push(this.fb.control(null));
        this.formPaths.push(this.fb.control(null));
        this.formPathTypes.push(this.fb.control(null));
        this.paramsArray.push(1);
        this.referrersArray.push(1);
        this.pathsArray.push(1);
    }
  }

  removeField(section, index) {
    switch (section) {
      case 'param':
        this.formParams.removeAt(index);
        this.formSourceTypes.removeAt(index);
        this.paramsArray.pop();
        break;
      case 'referrer':
        this.formReferrers.removeAt(index);
        this.formReferrerTypes.removeAt(index);
        this.referrersArray.pop();
        break;
      case 'path':
        this.formPaths.removeAt(index);
        this.formPathTypes.removeAt(index);
        this.pathsArray.pop();
        break;
      case 'event':
        this.formProperties.removeAt(index);
        this.formPropertyValues.removeAt(index);
        this.propertiesArray.pop();
        break;
    }
  }

  switchTo(section) {
    this.openSection = section;
    this.checkIfFormValid();
  }

  togglePathFiltering() {
    this.pathFilteringActive = !this.pathFilteringActive;
    this.formPaths.clear();
    this.formPathTypes.clear();
    this.pathsArray = [];
    this.addField('path');
  }

  toggleOnlyUnattributed() {
    this.onlyUnattributed = !this.onlyUnattributed;
  }

  checkIfFormValid() {
    const checkControls = (array) => {
      const valuesArray: Array<any> = array.map( x => x.value);
      return !valuesArray.includes(null) && !valuesArray.includes('');
    }

    const isNotLessThenMinLengthOrIsAllowed = (array, minLength: number, specialAllowArray?: Array<any>) => {
      const valuesArray: Array<any> = array.map( x => x.value);
      return valuesArray.every( x => {
        return x.length > minLength || specialAllowArray.includes(x);
      });
    }

    const requireCharacter = (array, characters: Array<string>) => {
      const valuesArray: Array<any> = array.map( x => x.value);
      return valuesArray.every( x => {
        return characters.every( char => x.includes(char));
      });
    }

    const checkPaths = () => {
      this.pathLacksSlash = false;
      let isOnlyDash = false;

      this.formPaths.value.forEach( (x,i) => {
        if (x) {
          if (!this.pathLacksSlash) this.pathLacksSlash = !x.startsWith('/');
          if (!isOnlyDash && x.length < 2)  {
            isOnlyDash = true;
            if (this.openSection !== 'path' && this.formPathTypes.value[i] === 'equals') {
              isOnlyDash = false;
            }
          }
        }
      });

      if (!checkControls(this.formPaths.controls)) return false;
      else if (this.formPathTypes.value.includes(null)) return false;
      else if (this.pathLacksSlash) return false;
      else if (isOnlyDash) return false;
      else return true;
    };

    switch (this.openSection) {
      case 'param':
        this.isValid = checkControls(this.formParams.controls) && checkControls(this.formSourceTypes.controls)
        if (this.pathFilteringActive) {
          this.isValid = this.isValid && checkPaths();
        }
        break;
      case 'referrer':
        this.isValid =
        checkControls(this.formReferrers.controls)
        && checkControls(this.formReferrerTypes.controls)
        && isNotLessThenMinLengthOrIsAllowed(this.formReferrers.controls, 4, ['t.co'])
        && requireCharacter(this.formReferrers.controls, ['.']);
        if (this.pathFilteringActive) {
          this.isValid = this.isValid && checkPaths();
        }
        break;
      case 'path':
        this.isValid = checkPaths();
        break;
      case 'organic':
        this.isValid = checkControls(this.formReferrers.controls);
        break;
      case 'event':
        this.isValid =
          checkControls(this.formProperties.controls)
          && checkControls(this.formPropertyValues.controls)
          && this.eventNameControl.value !== null && this.eventNameControl.value.length > 0;
        break;
    }
    const name = this.filterForm.controls.name.value;

    if ( name === null || name.length === 0) { this.isValid = false; }
  }

  submit() {
    if (!this.isValid) {
      return;
    }
    this.waitingForResponse = true;
    const parameters: any = {
      name: this.filterForm.controls.name.value,
      // capitalize type string
      type: this.openSection.charAt(0).toUpperCase() + this.openSection.slice(1),

      params: this.paramsArray.map((_, i) => {
        if (this.formSourceTypes.at(i).value && this.formParams.at(i).value) {
          return {
            key: this.formSourceTypes.at(i).value.label ? this.formSourceTypes.at(i).value.label : this.formSourceTypes.at(i).value,
            value: this.formParams.at(i).value
          };
        }
      }).filter( item => item !== null && typeof(item) !== 'undefined'),

      referrers: this.referrersArray.map((_, i) => {
        if (this.formReferrerTypes.at(i).value && this.formReferrers.at(i).value) {
          return { operator: this.formReferrerTypes.at(i).value, value: this.formReferrers.at(i).value };
        }
      }).filter( item => item !== null && typeof(item) !== 'undefined'),

      paths: this.pathsArray.map((_, i) => {
        if (this.formPaths.at(i).value) {
          if (this.formPathTypes.at(i).value && this.formPaths.at(i).value) {
            return { operator: this.formPathTypes.at(i).value, value: this.formPaths.at(i).value };
          } else if (this.formPaths.at(i).value) {
            return { value: this.formPaths.at(i).value };
          }
        }
      }).filter( item => item !== null && typeof(item) !== 'undefined'),
    };

    if (this.openSection === 'event') {
      parameters.properties = [
        {
          key: "__EVENT_NAME__",
          value: this.eventNameControl.value,
        },
        ...this.propertiesArray.map((_, i) => {
          if (this.formPropertyValues.at(i).value && this.formProperties.at(i).value) {
            return { key: this.formProperties.at(i).value, value: this.formPropertyValues.at(i).value };
          }
        }).filter( item => item !== null && typeof(item) !== 'undefined')
      ]
    }

    if (this.openSection === 'organic') {
      parameters.referrers = this.referrersArray.map((_, i) => {
        if (this.formReferrerTypes.at(i).value && this.formReferrers.at(i).value) {
          return { operator: this.formReferrerTypes.at(i).value, value: this.formReferrers.at(i).value };
        } else if (this.formReferrers.at(i).value) {
          return { value: this.formReferrers.at(i).value };
        }
      }).filter( item => item !== null && typeof(item) !== 'undefined');
      parameters.organic = true;
    } else {
      parameters.organic = false;
    }
    

    if (this.openSection === 'event') {
      parameters.only_unattributed = this.onlyUnattributed;
    }

    switch (this.openSection) {
      case 'param':
        parameters.referrers = [];
        break;
      case 'organic':
        parameters.params = [];
        break;
      case 'referrer':
        parameters.params = [];
        break;
      case 'path':
        parameters.referrers = [];
        parameters.params = [];
        break;
    }

    if (this.openSection !== 'path' && !this.pathFilteringActive) {
      parameters.paths = [];
    }

    if (!this.filterInfo) {
      this.filterService.createNewFilter(parameters).subscribe( (resp: CreateNewFilterResponse) => {
        this.store.dispatch([
          new AddFilter({filter: {n: resp.name, id: resp.id, it: resp.type}}),
          new AddToPendingDataRefresh({id: resp.id, type: 'f'})
        ]).subscribe({next: () => {
          this.waitingForResponse = false;
          this.reportRefresher.scheduleRefresh();
          this.activeModal.close({ result: 'filterAdded', filter: resp});
        }, error: () => {
          this.waitingForResponse = false;
        }});
      }, error => {
        this.waitingForResponse = false;
        throw new Error(error);
      });
    } else {
      this.filterService.editFilter(this.filterInfo.id, parameters).subscribe( (resp: CreateNewFilterResponse) => {
        const filterTree = this.store.selectSnapshot(state => state.filterTree.filterTree);
        const node = findInTree({node: {c: filterTree }, childrenKey: 'c', key: 'id', value: this.filterInfo.id, additionalKey: 't', additionalValue: 'f', withPath: filterTree, getPathIds: true});
        this.store.dispatch([
          new EditFilter({n: resp.name, id: resp.id}),
          new AddToPendingDataRefresh({id: resp.id, type: 'f', pathArray: node.pathArray})
        ]).subscribe({next: () => {
          this.waitingForResponse = false;
          this.reportRefresher.scheduleRefresh();
          this.activeModal.close({result: 'filterEdited', filter: resp});
        }, error: error => {
          this.waitingForResponse = false;
        }});
      }, error => {
        this.waitingForResponse = false;
        throw new Error(error);
      });
    }
  }

  delete() {
    this.deleteWaiting = true;
    const filterTree = this.store.selectSnapshot(state => state.filterTree.filterTree);
    const node = findInTree({node: {c: filterTree }, childrenKey: 'c', key: 'id', value: this.filterInfo.id, additionalKey: 't', additionalValue: 'f', withPath: filterTree, getPathIds: true});
    this.store.dispatch(
      new AddToPendingDataRefresh({
        id: node.id,
        type: node.t,
        pathArray: node.pathArray,
      })
    );
    this.filterService.deleteFilter(this.filterInfo.id).subscribe( () => {
      this.store.dispatch(new DeleteFilter({id: this.filterInfo.id})).subscribe(() => {
        this.deleteWaiting = false;
        this.activeModal.close({result: 'filterDeleted', filterId: this.filterInfo.id});
      }, error => {
        this.deleteWaiting = false;
      });
    });
  }

  get formParams() {
    return this.filterForm.get('formParams') as FormArray;
  }

  get formSourceTypes() {
    return this.filterForm.get('formSourceTypes') as FormArray;
  }

  get formReferrers() {
    return this.filterForm.get('formReferrers') as FormArray;
  }

  get formReferrerTypes() {
    return this.filterForm.get('formReferrerTypes') as FormArray;
  }

  get formPaths() {
    return this.filterForm.get('formPaths') as FormArray;
  }

  get formPathTypes() {
    return this.filterForm.get('formPathTypes') as FormArray;
  }

  get formProperties() {
    return this.filterForm.get('formProperties') as FormArray;
  }

  get formPropertyValues() {
    return this.filterForm.get('formPropertyValues') as FormArray;
  }

  get eventNameControl() {
    return this.filterForm.get('eventName');
  }

  isPreviusWithValue(array: Array<any>, index) {
    const filtered = array.filter( x => x.value !== null && x.value.length > 0);
    const newIndex = filtered.indexOf(array[index]);
    if (array[newIndex - 1]) {
      return filtered[newIndex - 1].value ? true : false;
    } else { return false; }
  }

  nonEmpty(array) {
    return array.filter( x => x.value !== null && x.value.length > 0);
  }

  path_omit_special_char(event) {
    const k = event.keyCode;
    return ![38,63].includes(k);
  }

  referrer_omit_special_char(event) {
    const k = event.keyCode;
    return ![47,42,37].includes(k);
  }
}
