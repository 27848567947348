import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oauthStatus',
})
export class OauthStatusPipe implements PipeTransform {
  transform(oauth): any {
    if (oauth.identifier === null) {
        return 'Invalid';
      } else if (oauth.expired_at) {
        return 'Paused';
      } else {
        return 'Connected';
      }
  }
}