
<app-loader
  *ngIf="((isLoading || initialLoading) || !filterTree?.filterTree)"
  [backgroundColor]="'rgba(0, 0, 0, 0.75)'"></app-loader>

<div *ngIf="ShowMessageUntilReportLoaded && isFirstLoad; else dashboard"  class="dashboard-wrapper message-until-load pt-5" [class.sidebar-opened]="sidebar?.openedSidebar">
  <h4>Chose parameters & click "Load Report" when ready</h4>
</div>

<div ngbDropdown class="d-inline-block no-caret-dropdown" #settingsDropdown="ngbDropdown" container="body">
  <span ngbDropdownToggle></span>
  <div ngbDropdownMenu>
    <button *ngIf="!showTimeframe" (click)="breakDown(dropdownItem)" ngbDropdownItem>
      <ng-container *ngIf="{ itemBDs: dropdownItem | dashboardGetBreakdowns:breakdowns } as calculated">
        <span *ngIf="(calculated.itemBDs?.hidden | dashboardBreakdownHidden); else breakDownArrowDownDropdown">
          <i  class="fa-solid fa-fw fa-arrow-down-wide-short blue" [class.disabled]="calculated.itemBDs?.waitingForBreakdown"></i> Show Paths
        </span>
      </ng-container>
      <ng-template #breakDownArrowDownDropdown>
        <i class="fa-solid fa-fw fa-arrow-up-wide-short blue"></i> Show Filters
      </ng-template>
    </button>
    <div *ngIf="dropdownItem?.t === 'g'">
      <button *ngIf="!dropdownItem?.i" (click)="toggleNameInput(dropdownItem?.id, dropdownItem?.n)" ngbDropdownItem>
        <i class="fa-solid fa-fw fa-pencil blue"></i> Change name
      </button>
      <button *ngIf="dropdownItem?.i && dropdownLevel > 0" ngbDropdownItem (click)="waitingHideGroup !== dropdownItem?.id ? disableGroup(dropdownItem?.id) : null">
        <span *ngIf="waitingHideGroup !== dropdownItem?.id; else waitingHideGroupTemplate"><i class="fa-solid fa-fw fa-ban blue"></i> Disable</span>
        <ng-template #waitingHideGroup><i class="no-cursor fa-solid fa-fw fa-spinner fa-spin blue"></i></ng-template>
      </button>
      <button *ngIf="dropdownLevel === 0" (click)="hideGroup(dropdownItem)" ngbDropdownItem>
        <i class="fa-solid fa-fw fa-eye-slash blue"></i> Hide Channel
      </button>
      <div *ngIf="(dropdownItem | dashboardShowAdIntegrationIcon:dropdownLevel)">
        <button (click)="openAdDestinationUrlsModal(dropdownItem)" ngbDropdownItem>
          <i class="fa-solid fa-fw fa-info blue"></i> Ad Destination URLs
        </button>
        <button *ngIf="dropdownItem.i === 'googleads2' && dropdownItem.it !== 'Keyword'" (click)="openExtendedAdDestinationUrlsModal(dropdownItem)" ngbDropdownItem>
          <i class="fa-solid fa-fw fa-info blue"></i> Extended Ad Destination URLs
        </button>
      </div>
      <button *ngIf="dropdownItem?.i | dashboardCanNavigateToAdService" (click)="openExternalAdService(dropdownItem)" ngbDropdownItem>
        <i class="fa-solid fa-fw fa-arrow-up-right-from-square blue"></i> Open in ad manager
      </button>
    </div>
    <div *ngIf="dropdownItem?.t === 'f' && dropdownItem?.s !== 'direct' && dropdownItem?.s !== 'unsegmented' && !(dropdownItem?.s == 'virtual' && dropdownItem?.n == 'Paid Traffic')">
      <button *ngIf="dropdownItem | dashboardShouldShowEditButton" ngbDropdownItem (click)="editFilterWaiting !== dropdownItem?.id ? navigateToEditModal(dropdownItem) : null" data-cy="edit-filter">
        <span *ngIf="editFilterWaiting !== dropdownItem?.id; else waitinEditModal">
          <i class="fa-solid fa-fw fa-gear blue"></i>
          {{  dropdownItem?.s === 'virtual' ? 'Edit Group' : 'Edit filter' }}
        </span>
        <ng-template #waitinEditModal>
          <i class="no-cursor fa-solid fa-fw fa-spinner fa-spin blue"></i>
        </ng-template>
      </button>
      <button *ngIf="dropdownItem?.i" ngbDropdownItem>
        <span *ngIf="waitingHideFilter !== dropdownItem?.id; else waitingHideFilterTemplate" (click)="waitingHideFilter !== dropdownItem?.id ? disableFilter(dropdownItem?.id) : null"><i class="fa-solid fa-fw fa-ban blue"></i> Disable</span>
        <ng-template #waitingHideFilterTemplate><i class="no-cursor fa-solid fa-spinner fa-spin blue"></i></ng-template>
      </button>
      <div *ngIf="dropdownItem?.i && (dropdownItem | dashboardShowAdIntegrationIcon:dropdownLevel)">
        <button (click)="openAdDestinationUrlsModal(dropdownItem)" ngbDropdownItem>
          <i class="fa-solid fa-fw fa-info blue"></i> Ad Destination URLs
        </button>
        <button *ngIf="dropdownItem.i === 'googleads2' && dropdownItem.it !== 'Keyword'" (click)="openExtendedAdDestinationUrlsModal(dropdownItem)" ngbDropdownItem>
          <i class="fa-solid fa-fw fa-info blue"></i> Extended Ad Destination URLs
        </button>
      </div>
      <button *ngIf="dropdownItem?.i | dashboardCanNavigateToAdService" (click)="openExternalAdService(dropdownItem)" ngbDropdownItem>
        <i class="fa-solid fa-fw fa-arrow-up-right-from-square blue"></i> Open in ad manager
      </button>
    </div>
    <div *ngIf="dropdownItem?.s != 'virtual'" (click)="showAddToGroupItem = dropdownItem" class="dropdown-item-no-click clickable">
      <i class="fa-solid fa-fw fa-plus" [class.blue]="dropdownItem?.id !== showAddToGroupItem?.id || dropdownItem?.t !== showAddToGroupItem?.t"></i>
      {{ dropdownItem?.id === showAddToGroupItemId ? 'Select Groups' : 'Add to Group' }}
    </div>
    <div *ngIf="dropdownItem?.id === showAddToGroupItem?.id && dropdownItem?.t === showAddToGroupItem?.t" class="v-filter-list">
      <div class="dropdown-divider"></div>
      <div *ngIf="virtualFilters.length == 0" class="dropdown-item-no-click clickable" (click)="addNewGroup()">
        <i class="fa-solid fa-fw fa-empty blue"></i> Add New Group
      </div>
      @for (vf of virtualFilters; track $index) {
        <div *ngIf="!(vf.id | includedInArray:dropdownItem?.vf) || (dropdownItem | includedInArray:vf.vc:'virtualChildArray')" class="dropdown-item-no-click clickable" [class.addedVfilter]="dropdownItem | dashboardFilterIsInVirtual:vf"  (click)="toggleFilterInVirtual(dropdownItem, vf)">
          <i *ngIf="(dropdownItem | dashboardFilterIsInVirtual:vf); else notInVirtualIcon" class="fa-solid fa-fw fa-square-check green" aria-hidden="true"></i>
          <ng-template #notInVirtualIcon>
            <i class="fa-regular fa-fw fa-square medium" aria-hidden="true"></i>
          </ng-template>
          {{ vf.n }}
        </div>
      }
    </div>
  </div>
</div>

<ng-template #dashboard>
  <div *ngIf="emptyDialogType != null; notEmpty">
    <div [ngSwitch]="emptyDialogType">
      <div *ngSwitchCase="'complete-setup'">
        <app-complete-setup-dialog
          (onClose)="closeEmptyDialog($event)"></app-complete-setup-dialog>
      </div>
      <div *ngSwitchDefault>
        <app-shopify-dialog
          [type]="emptyDialogType"
          (onClose)="emptyDialogType = null; emptyDialogClosed = true;"></app-shopify-dialog>
      </div>
    </div>
  </div>
  <div *ngIf="emptyDialogType == null" class="dashboard-wrapper" [class.sidebar-opened]="sidebar?.openedSidebar" [class.timeframe]="showTimeframe" [class.first-time-purchase]="isFTP">
    <first-time-purchase
      *ngIf="showTimeframe && isFTP"
      [report]="timeframeFTPReport"
    ></first-time-purchase>
    <div class="dashboard-wrapper-inside" [class.d-inline-block]="showTimeframe">
      <div class="dash-header" [class.fixed]="!sidebar?.openedSidebar && !showTimeframe">
        <app-dash-header
          (NewFilter)="openNewFilterModal()"
          (NewChannel)="addNewChannel()"
          (NewGroup)="addNewGroup()"
          (ToggleDrag)="toggleDragging()"
          (ToggleTimeframe)="toggleTimeframe()"
          (Sort)="sort($event)"
          (onSearchFilter)="searchFilter($event)"
          [dragDisabled]="dragDisabled"
          [sidebar]="sidebar"
          [hasImpressions]="report?.other?.all?.i > 0"
          [hasClicks]="report?.other?.all?.k > 0"
          [withSpendNoVisits]="withSpendNoVisits"
          [totalAmounts]="report?.other?.all?.a"
          [unattributedPercent]="unattributedPercent"
          [report]="showTimeframe ? (isFTP ? timeframeFTPReport : timeframeReport) : report"
          [periodArray]="(isFTP ? periodsWithoutTotalFTP : periodsWithoutTotal)"
          [isTimeframe]="showTimeframe"
          [isFTP]="isFTP"
          [tableRevenue]="tableRevenue"
          (TableRevenueChanged)="tableRevenue = $event"
          [shouldResetSort]="shouldResetSort"
          (resetShouldResetSort)="resetShouldResetSort()"
          ></app-dash-header>
      </div>
      <div class="dash-content" [class.pt-212]="!this.sidebar?.openedSidebar && !showTimeframe">
        <div *ngIf="incompleteData" class="alert alert-warning" role="alert">
          Today's data is incomplete. Ad platforms can take up to 1 day to send all data to Attribution.
        </div>

        <app-oauth-row *ngIf="project?.oauths?.length < 2 && !isDemoProject"></app-oauth-row>

        <ng-template #allTrafficRow
          let-allTrafficPathOpened="allTrafficPathOpened"
          let-rowIsTop="rowIsTop">
          <app-fixed-rows
            [rowName]="'All Traffic'"
            [rowNameSub]="'Sum of all channels'"
            [shouldBreakDown]="true"
            [amounts]="report?.other?.all?.a"
            [conversions]="report?.other?.all?.c"
            [visits]="report?.other?.all?.e"
            [clicks]="report?.other?.all?.k"
            [revenue]="report?.other?.all?.r"
            [selectedAttributionModel]="selectedAttributionModel"
            (OpenSidebar)="openSidebarEmit($event)"
            [isTimeframe]="showTimeframe"
            [periodArray]="isFTP ? periodsWithoutTotalFTP : periodsWithoutTotal"
            [timeframeReport]="(isFTP ? timeframeFTPReport : timeframeReport)?.f?.total"
            [tableRevenue]="tableRevenue"
            [lastEmittedSort]="lastEmittedSort"
            [itemBDs]="breakdowns.all"
            (breakdown)="breakDown($event, rowIsTop ? 'top' : 'bottom')"
            [allTrafficPathOpened]="allTrafficPathOpened"
            [isFTP]="isFTP">
          </app-fixed-rows>
        </ng-template>

        <ng-container *ngTemplateOutlet="allTrafficRow; context: { rowIsTop: true, allTrafficPathOpened: breakdowns.allTrafficTopOpened}"></ng-container>

        <div
          dndDropzone
          (dndDrop)="onDrop($event, nestableList)">
            <ng-container *ngTemplateOutlet="recursiveList; context:{
              $implicit: nestableList,
              initial: true,
              parent: {},
              level: 0,
              belowVirtualFilter: false }"></ng-container>
        </div>

        <ng-template
          #recursiveList
          let-list
          let-isInitial="initial"
          let-isParentInitial="parentInitial"
          let-parent="parent"
          let-belowVirtualFilter="belowVirtualFilter"
          let-parentZeros="parentZeros"
          let-isInExpired="isInExpired"
          let-level="level">

          <div dndPlaceholderRef class="dndPlaceholder dash__row dash__row--placeholder"></div>

          <ng-template #nameTemplate let-item="item">
              <ng-container *ngIf="{filterExcluded: item | dashboardFilterExcluded:project:excludedFilters} as calculated">
                <i *ngIf="calculated.filterExcluded" class="fa-solid fa-ban medium me-2" ngbTooltip="Filter excluded from attribution" container="body"></i>
                <span *ngIf="item | dashboardChannelOauthExpired:expiredOauthChannels"><i class="fa-solid fa-circle-pause medium-dark" ngbTooltip="Integrtion has been paused" container="body"></i>&nbsp;</span>
                <span [class.medium]="calculated.filterExcluded">{{item.n}}</span>
              </ng-container>
          </ng-template>

          <ng-template class='form-inline' #nameEditForm let-item="item">
            <input class="form-control" type="text" [ngModel]="nameInput" #channelNameInput>
            <i class="fa-solid fa-check-circle icon-clickable" (click)="renameChannel(channelNameInput, item.id)"></i>
            <i class="fa-solid fa-trash-alt icon-clickable" [class.medium]="item.c?.length > 0" (click)="deleteChannel(item)"></i>
          </ng-template>

          @for (item of (list | dashboardNotHiddenItems:view); track item) {
            <div
            [class.drag-enabled]="!dragDisabled"
            [dndDraggable]="item"
            [dndType]="item.s == 'virtual' ? 'not-allowed' : 'allowed'"
            [dndDisableIf]="editNameId !== null || dragDisabled || sidebar?.openedSidebar !== null || (item | dashboardDisableDND) || belowVirtualFilter"
            dndEffectAllowed="move"
            (dndStart)="onDragStart(item)"
            (dndCopied)="onDragged(item, list, 'copy')"
            (dndLinked)="onDragged(item, list, 'link')"
            (dndMoved)="onDragged(item, list, 'move')"
            (dndCanceled)="onDragged(item, list, 'none')"
            (dndEnd)="onDragEnd($event)">

              <ng-container *ngIf="{
                itemReport: item | dashboardGetItemReport:(showTimeframe ? (isFTP ? timeframeFTPReport : timeframeReport) : report):showTimeframe,
                zeros:
                  item.t === 'g' && item.c?.length > 0 ?
                  (item.c | dashboardCalculateZeros:(showTimeframe ? (isFTP ? timeframeFTPReport : timeframeReport) : report): showTimeframe)
                  :
                    item.t === 'f' && item.vc?.length > 0 ?
                    (item.vc | dashboardCalculateZeros:(showTimeframe ? (isFTP ? timeframeFTPReport : timeframeReport) : report): showTimeframe)
                    : 0,
                parentShouldShowZeros: parent | dashboardShouldShowZeros:nestableList:showZerosArray:showVirtualZerosArray,
                channelOauthExpired: item | dashboardChannelOauthExpired:expiredOauthChannels,
                hasNoChildren: item | hasNoChildren,
                filterErrors: item | dashboardFilterError:filterErrors,
                waitingGroupExpand: item.t === 'g' ? (item.id | includedInArray:waitingExpandGroups) : (item.id | includedInArray:waitingExpandVirtualGroups),
                itemBDs: item | dashboardGetBreakdowns:breakdowns
              } as calculated">
              <div
                *ngIf="(reportIsEmpty || (calculated.itemReport || belowVirtualFilter) || calculated.parentShouldShowZeros) || isInitial"
                class="dash__row"
                [ngClass]="['dash__row--l' + level, (calculated.channelOauthExpired ? 'oauth-expired dash__row--stripped' : '')]"
                [attr.id]="(belowVirtualFilter ? 'inVirtual-' : 'filter-') + item.t + '-' + item.id"
                [class.dash__row--parent]="isInitial"
                [class.dash__row--subparent]="!isInitial && !calculated.hasNoChildren"
                [class.dash__row--child]="!isInitial && isParentInitial && calculated.hasNoChildren"
                [class.dash__row--subchild]="!isInitial && !isParentInitial && calculated.hasNoChildren"
                [class.virtual-filter]="item.s == 'virtual'"
                [class.breaked-down]="(calculated.itemBDs?.original && !calculated.itemBDs?.hidden) || calculated.itemBDs?.waitingForBreakdown"
                [class.highlighted-warn]="!isProd && (item.oi || parent.oi) && item.oi != parent.oi && item.it != 'Integration Channel' && !belowVirtualFilter">
                <div class="item-name right-padded">
                  <div dndHandle class="drag-indicator" [class.drag-disabled]="item | dashboardDisableDND">
                    <i class="fa-solid fa-grip-vertical blue" [class.medium]="editNameId !== null || dragDisabled || sidebar?.openedSidebar !== null || (item | dashboardDisableDND) || belowVirtualFilter"></i>
                  </div>
                  <div class="item-name-inside-wrapper">
                    <div class="item-names">
                      <div *ngIf="(item | canExpand:parent:this.isExceptionProject) && !belowVirtualFilter && (item.n !== 'Paid Traffic' || !isProd)" class="dash__row__arrow" (click)="toggleExpand(item)">
                        <i *ngIf="item.e; else arrowRight" class="fa-solid fa-chevron-down"></i>
                        <ng-template #arrowRight>
                          <i class="fa-solid fa-chevron-right"></i>
                        </ng-template>
                      </div>
                      <div class="dash__name">
                        <ng-container [ngTemplateOutlet]='editNameId !== item.id ? nameTemplate : nameEditForm' [ngTemplateOutletContext]="{item:item}"></ng-container>
                      </div>
                      <div class="dash__name--sub">
                        <i *ngIf="belowVirtualFilter" class="fa-solid fa-search fa-fw clickable blue darker-hover me-1" ngbTooltip="{{ item | dashboardGetFilterPath:allFilters:allChannels}}" (click)="showFilter(item)"></i>
                        <app-filter-icon
                          [item]="item"></app-filter-icon>
                        <span *ngIf="calculated.channelOauthExpired">
                          disconnected, <a class="bold" [routerLink]="[componentNames.settings, settingsSections.integrations, 'show', (item.id | dashboardFirstExpiredOauth:expiredOauthChannels)]">reconnect</a>
                        </span>
                        <a *ngIf="( item | dashboardIsFilterError:'ie':filterErrors) && !isInExpired" class="ms-2 caps red small bold caps" (click)="navigateToIntegrationErrorModal(item)">
                          <i class="fa-solid fa-circle fa-xs"></i>{{ calculated.filterErrors?.ie }}
                        </a>
                        <a *ngIf="!isProd && ( item | dashboardIsFilterError:'snv':filterErrors) && !isInExpired" class="ms-2 caps orange small bold caps" (click)="navigateToIntegrationErrorModal(item, true)" ngbTooltip="This {{ item.t == 'f' ? 'filter' : 'channel'}} has spend but no visits." placement="end" container="body">
                          <i class="fa-solid fa-circle fa-xs"></i>{{ calculated.filterErrors?.snv }}
                        </a>
                        <a *ngIf="(item.oi || parent.oi) && item.oi != parent.oi && item.it != 'Integration Channel' && !belowVirtualFilter" class="ms-2 caps medium-dark small bold caps" ngbTooltip="This {{ item.t == 'f' ? 'filter' : 'channel'}} doesn't belong to current integration channel ({{ parent.oi || 'none' }} vs {{ item.oi || 'none' }})." placement="end" container="body">
                          <i class="fa-solid fa-circle-half-stroke fa-xs"></i>
                        </a>
                        <a *ngIf="!showTimeframe && !(calculated.itemBDs?.hidden | dashboardBreakdownHidden)" (click)="breakDown(item)" class="ms-2 tool-button">
                          <span class="me-1" placement="top" ngbTooltip="Switch back to filters" container="body">
                            <i class="fa-solid fa-arrow-up-wide-short"></i> | Showing paths
                          </span>
                        </a>
                        <span class="ms-2 red" *ngIf="item.id | dashboardNeedsDataRefresh:item.t:pendingDataRefresState:lastReportMeta?.refresh?.generated_at" ngbTooltip="At least one of the filters has been altered (created, updated, edited, moved, deleted) which could affect filter and channel metrics, data refresh happens automatically in background, this message will go away when data re-calculates.">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                          Pending dashboard refresh
                        </span>
                      </div>
                    </div>
                    <div *ngIf="!belowVirtualFilter" class="item-settings">
                      <i class="fa-solid fa-ellipsis-vertical clickable medium tool-button" (click)="showSettingsDropdown($event, item, level, settingsDropdown)"></i>
                    </div>
                  </div>
                </div>
                <ng-template #noTimeframe>
                  <div class="flex-item">
                    <div class="dash__data">
                      <div class="numbers">
                        <a data-cy="amounts" (click)="openSidebar(sidebarNames.amounts, {id: item.id, type: item.t, special: item.s})">
                          {{ (calculated.itemReport ? calculated.itemReport.a : 0) | projectCurrency }}
                        </a>
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                    <div class="dash-progress">
                      <div class="dash-progress-bar" [ngStyle]="{'width': ((calculated.itemReport ? calculated.itemReport.a : 0) / report?.other?.max?.a * 100) + '%'}"></div>
                    </div>
                    <div class="dash__data--sub">
                      <div class="numbers" title="Cost per conversion">
                        {{ (calculated.itemReport ? calculated.itemReport.a : 0) === 0 ? '--' : ((calculated.itemReport ? calculated.itemReport.a / calculated.itemReport.c : 0) | projectCurrency) }}
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                  </div>
                  <div class="flex-item">
                    <div class="dash__data">
                      <div class="numbers">
                        <span class="impressions" *ngIf="(calculated.itemReport ? calculated.itemReport.i : 0) > 0 && !sidebar?.openedSidebar" title="Impressions">({{ (calculated.itemReport ? calculated.itemReport.i : 0) | integer }})</span>
                        <b data-cy="clicks" class="clicks hide-prod" *ngIf="(calculated.itemReport ? calculated.itemReport.k : 0) > 0" title="Clicks">{{ (calculated.itemReport ? calculated.itemReport.k : 0) | integer }}</b>
                        <a data-cy="visits" (click)="openSidebar(sidebarNames.visits, {id: item.id, type: item.t, scope: 'visits', special: item.s})">{{ (calculated.itemReport ? calculated.itemReport.e : 0) | integer }}</a>
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                    <div class="dash-progress">
                      <div class="dash-progress-bar" [ngStyle]="{'width': ((calculated.itemReport ? calculated.itemReport.e : 0) / report?.other?.max?.e * 100) + '%'}"></div>
                    </div>
                    <div class="dash__data--sub">
                      <div class="numbers" title="Conversion rate">
                        {{ (calculated.itemReport ? calculated.itemReport.e : 0) === 0 || (calculated.itemReport ? calculated.itemReport.c : 0) === 0 ? '--' : ((calculated.itemReport ? calculated.itemReport.c / calculated.itemReport.e : 0) | percent: '1.0-2') }}
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                  </div>
                  <div class="flex-item">
                    <div class="dash__data">
                      <div class="numbers">
                        <span class="dash__overlap" *ngIf="item.t === 'f' && item.s != 'virtual'">
                          (<a data-cy="overlaps" (click)="openSidebar(sidebarNames.overlaps, {id: item.id, type: item.t, special: item.s})">{{ calculated.itemReport ? calculated.itemReport.o : 0 }}</a>)
                        </span>
                        <a (click)="openSidebar(sidebarNames.visits, {id: item.id, type: item.t, scope: 'conversions', special: item.s})">
                          <span [ngSwitch]="selectedAttributionModel?.name">
                            <span data-cy="conversions"  *ngSwitchCase="'first_touch'">
                              {{ (calculated.itemReport ? calculated.itemReport.c : 0) | integer }}
                            </span>
                            <span data-cy="conversions"  *ngSwitchCase="'last_touch'">
                              {{ (calculated.itemReport ? calculated.itemReport.c : 0) | integer }}
                            </span>
                            <span data-cy="conversions"  *ngSwitchDefault>
                              {{ (calculated.itemReport ? calculated.itemReport.c : 0) | defaultNumber }}
                            </span>
                          </span>
                        </a>
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                    <div class="dash-progress">
                      <div class="dash-progress-bar" [ngStyle]="{'width': ((calculated.itemReport ? calculated.itemReport.c : 0) / report?.other?.max?.c * 100) + '%'}"></div>
                    </div>
                    <div class="dash__data--sub">
                      <div class="numbers" title="Revenue per conversion">
                        {{ (calculated.itemReport ? calculated.itemReport.r : 0) === 0 || (calculated.itemReport ? calculated.itemReport.c : 0) === 0 ? '--' : ((calculated.itemReport ? calculated.itemReport.r / calculated.itemReport.c : 0) | projectCurrency) }}
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                  </div>
                  <div class="flex-item">
                    <div class="dash__data">
                      <div class="numbers">
                        <a data-cy="revenue" (click)="openSidebar(sidebarNames.revenues, {id: item.id, type: item.t, special: item.s})">
                          {{ (calculated.itemReport ? calculated.itemReport.r : 0) | projectCurrency }}
                        </a>
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                    <div class="dash-progress">
                      <div class="dash-progress-bar" [ngStyle]="{'width': ((calculated.itemReport ? calculated.itemReport.r : 0) / report?.other?.max?.r * 100) + '%'}"></div>
                    </div>
                    <div class="dash__data--sub">
                      <div class="numbers">
                        {{ (calculated.itemReport ? calculated.itemReport.r : 0) === 0 || (calculated.itemReport ? calculated.itemReport.a : 0) === 0 ? '' : ((calculated.itemReport ? calculated.itemReport.r / calculated.itemReport.a : 0) | percent: '1.2') }}
                        <span class="bold" [class.funds-red-dash]="(calculated.itemReport ? calculated.itemReport.r - calculated.itemReport.a : 0) < 0" [class.funds-green]="(calculated.itemReport ? calculated.itemReport.r - calculated.itemReport.a : 0)  > 0"> {{ (calculated.itemReport ? calculated.itemReport.r - calculated.itemReport.a : 0)  | projectCurrency }}</span>
                      </div>
                      <div class="just-dash">-</div>
                      <!-- <app-pulse-loader></app-pulse-loader> -->
                      <!-- <div class="shimmer"></div> -->
                    </div>
                  </div>
                </ng-template>
                <ng-template #timeframeRow>
                  <app-timeframe-row
                    [item]="item"
                    [periodArray]="isFTP ? periodsWithoutTotalFTP : periodsWithoutTotal"
                    [report]="item | dashboardGetItemReport:(isFTP ? timeframeFTPReport : timeframeReport):showTimeframe"
                    [tableRevenue]="tableRevenue"
                    [selectedAttributionModel]="selectedAttributionModel"
                    (OpenSidebar)="openSidebarEmit($event)"
                    [isFTP]="isFTP">
                  </app-timeframe-row>
                </ng-template>
                <ng-container *ngTemplateOutlet="showTimeframe ? timeframeRow : noTimeframe"></ng-container>
              </div>

              <div *ngIf="calculated.itemBDs?.waitingForBreakdown" class="dash__row break-down-row">
                <div class="m-auto">
                  <app-loader [position]="'relative'" [color]="'green'" [backgroundColor]="'#f6fcff'" [height]="'35px'"></app-loader>
                </div>
              </div>

              <div *ngIf="calculated.itemBDs?.original && !calculated.itemBDs?.hidden && !showTimeframe">
                @for (path of (calculated.itemBDs?.original | dashboardSortedBreakdowns:lastEmittedSort); track path) {
                  <div
                  class="dash__row break-down-row"
                  [ngClass]="['dash__row--l' + level, (calculated.channelOauthExpired ? 'oauth-expired dash__row--stripped' : '')]">
                    <div class="item-name breakdown">
                      <div class="dash__name">
                        {{ path.name || '/' }}
                      </div>
                      <div class="dash__name--sub">
                        Path
                      </div>
                    </div>
                    <div class="flex-item"></div>
                    <div class="flex-item">
                      <div class="dash__data">
                        {{ path.object.visits ? (path.object.visits | integer) : 0 }}
                      </div>
                      <div class="dash-progress">
                        <div class="dash-progress-bar" [ngStyle]="{'width': (path.object.visits / report?.other?.max?.v * 100) + '%'}"></div>
                      </div>
                      <div class="dash__data--sub">
                        {{ path.object.visits === 0 || path.object.conversions === 0 ? '--' : ((path.object.conversions / path.object.visits) | percent: '1.0-2') }}
                      </div>
                    </div>
                    <div class="flex-item">
                      <div class="dash__data">
                        <span [ngSwitch]="selectedAttributionModel?.name">
                          <span  *ngSwitchCase="'first_touch'">
                            {{ path.object.conversions ? (path.object.conversions | integer) : 0 }}
                          </span>
                          <span  *ngSwitchCase="'last_touch'">
                            {{ path.object.conversions ?  (path.object.conversions | integer) : 0 }}
                          </span>
                          <span *ngSwitchDefault>
                            {{ path.object.conversions ? (path.object.conversions | defaultNumber) : 0 }}
                          </span>
                        </span>
                      </div>
                      <div class="dash-progress">
                        <div class="dash-progress-bar" [ngStyle]="{'width': (path.object.conversions * 100 / report?.other?.max?.c) + '%'}"></div>
                      </div>
                      <div class="dash__data--sub">
                        {{ path.object.revenue ? ((path.object.revenue / path.object.conversions) | projectCurrency) : '--' }}
                      </div>
                    </div>
                    <div class="flex-item">
                      <div class="dash__data">
                        {{ path.object.revenue ? (path.object.revenue | projectCurrency) : 0 }}
                      </div>
                      <div class="dash-progress">
                        <div class="dash-progress-bar" [ngStyle]="{'width': (path.object.revenue / report?.other?.max?.r * 100) + '%'}"></div>
                      </div>
                      <div class="dash__data--sub">
                        --
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div *ngIf="(item.t === 'g' || item.s === 'virtual') && calculated.waitingGroupExpand" class="zone-inside">
                <div class="dash__row dash__row--child no-traffic dash__row--stripped px8-less-width">
                  <div class="full-width fst-italic" [ngClass]="showTimeframe ? 'align-center-of-window' : 'text-center'">
                    <i class="fa-solid fa-circle-notch fa-spin blue"></i> Loading...
                  </div>
                </div>
              </div>


              <div *ngIf="((item.t === 'g' && !belowVirtualFilter) || (item.vc && item.vc.length > 0) ) && item.e && !calculated.waitingGroupExpand"
                class="zone-inside"
                dndDropzone
                [dndDropzone]="['allowed']"
                (dndDrop)="onDrop($event, item.c)">

                <div *ngIf="
                  item.t == 'g'
                  && calculated.hasNoChildren
                  && (showTimeframe ? (isFTP ? timeframeFTPReport : timeframeReport) : report)
                  && (
                    (
                      calculated.itemReport
                      || calculated.parentShouldShowZeros
                    )
                    || isInitial
                  )" class="dash__row dash__row--child no-traffic dash__row--stripped px8-less-width">
                  <div class="full-width" [ngClass]="showTimeframe ? 'align-center-of-window' : 'text-center'">
                    No Traffic Sources for {{ item.n }}
                  </div>
                </div>

                <ng-container *ngTemplateOutlet="recursiveList; context:{
                  $implicit: item.t !== 'g' ? (item.vc | findAllFiltersAndChannels:allFilters:allChannels:ordinals) : item.c,
                  parentInitial: isInitial,
                  parent: item,
                  parentZeros: calculated.zeros,
                  isInExpired: calculated.channelOauthExpired,
                  level: level+1,
                  belowVirtualFilter: (belowVirtualFilter || item.s === 'virtual')
                  }"></ng-container>
              </div>

              <div
                *ngIf="
                !(isLoading || initialLoading)
                && item.s !== 'virtual'
                && !belowVirtualFilter
                && (
                    (
                      calculated.itemReport
                      || calculated.parentShouldShowZeros
                    )
                    || isInitial
                  )
                && !calculated.waitingGroupExpand
                && calculated.zeros > 0
                && item.e
                && !(item | dashboardShouldShowZeros:nestableList:showZerosArray:showVirtualZerosArray)
                && (
                  parent.e
                  && (
                    isParentInitial
                    || parent.c.length > parentZeros
                    || (
                      !parentZeros
                      || calculated.parentShouldShowZeros)
                    )
                    || isInitial)" class="dash__row dash__row--child dash__row--hidden dash__row--stripped me-0 px8-less-width" [ngClass]="[showTimeframe ? 'align-center-of-window' : 'text-center', 'dash__row--l' + (level+1)]">
                <span class='blue-link' (click)="showZeros(item)">
                  {{ (calculated.zeros) === 1 ? 'One filter' : (calculated.zeros) + ' filters'}} with zero data hidden in {{ item.n }}, click to expand
                </span>
              </div>
              </ng-container>
            </div>
          }
        </ng-template>
        <div *ngIf="view?.hidden_filters?.length > 0 || view?.hidden_filter_groups?.length > 0" class="dash__row dash__row--parent dash__row--hidden dash__row--stripped px8-less-width" [ngClass]="showTimeframe ? 'align-center-of-window' : 'text-center'">
          <span class='blue-link bold' (click)="unhideFilterChannels()">
            {{view?.hidden_filters?.length > 0 ? view?.hidden_filters?.length + ' filter(s)' : ''}} {{view?.hidden_filters?.length > 0 && view.hidden_filter_groups.length > 0 ? 'and' : ''}} {{view?.hidden_filter_groups?.length > 0 ? view?.hidden_filter_groups?.length + ' channel(s)' : ''}} hidden, click to show all
          </span>
        </div>

        @for (item of specials; track item) {
          <ng-container *ngIf="{itemReport: item | dashboardGetItemReport:report} as calculated">
            <app-fixed-rows
              [rowName]="item.n"
              [amounts]="calculated.itemReport?.a || 0"
              [conversions]="calculated.itemReport?.c || 0"
              [visits]="calculated.itemReport?.e || 0"
              [revenue]="calculated.itemReport?.r || 0"
              [showLines]="true"
              [maxValues]="report?.other?.max"
              [overlaps]="calculated.itemReport?.o || 0"
              [id]="item.id"
              [attr.id]="'filter-' + item.t + '-' + item.id"
              (OpenSidebar)="openSidebarEmit($event)"
              [selectedAttributionModel]="selectedAttributionModel"
              [shouldBreakDown]="true"
              [mapping]="mapping"
              [special]="item.s"
              [lastEmittedSort]="lastEmittedSort"
              [isTimeframe]="showTimeframe"
              [periodArray]="isFTP ? periodsWithoutTotalFTP : periodsWithoutTotal"
              [timeframeReport]="(item | dashboardGetItemReport:(isFTP ? timeframeFTPReport : timeframeReport):true)"
              [tableRevenue]="tableRevenue"
              [itemBDs]="item | dashboardGetBreakdowns:breakdowns"
              (breakdown)="breakDown($event)"
              [isFTP]="isFTP"></app-fixed-rows>
          </ng-container>
        }


        <ng-container *ngTemplateOutlet="allTrafficRow; context: { rowIsTop: false, allTrafficPathOpened: breakdowns.allTrafficBottomOpened}"></ng-container>

        <app-fixed-rows
          *ngIf="showRaw"
          [rowName]="'Raw Traffic'"
          [rowNameSub]="'Raw data sent to Attribution app'"
          [amounts]="report?.other?.raw?.a"
          [conversions]="report?.other?.raw?.c"
          [visits]="report?.other?.raw?.e"
          [revenue]="report?.other?.raw?.r"
          (OpenSidebar)="openSidebarEmit($event, true)"
          [attr.id]="'filter-f-raw'"
          [selectedAttributionModel]="selectedAttributionModel"
          [isTimeframe]="showTimeframe"
          [periodArray]="isFTP ? periodsWithoutTotalFTP : periodsWithoutTotal"
          [timeframeReport]="(isFTP ? timeframeFTPReport : timeframeReport)?.raw"
          [tableRevenue]="tableRevenue"
          [isFTP]="isFTP"></app-fixed-rows>
      </div>
      <div class="dash-footer">
        <div class="btn btn-default" (click)="toggleRaw()">
          <i class="fa-solid fa-chart-column blue me-1"></i>
          {{ showRaw === false ? 'Show' : 'Hide' }} Raw data
        </div>
        <div class="btn btn-default" [class.disabled]="csvDownloading" (click)="downloadCSV()">
          @if (csvDownloading) {
            <i class="no-cursor fa-solid fa-fw fa-spinner fa-spin blue me-1"></i>
          } @else {
            <i class="fa-solid fa-download blue me-1"></i>
          }
          Download CSV
        </div>
      </div>
    </div>
  </div>
</ng-template>
