import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Project } from 'src/app/core/models/project.model';
import { SetCompanyBasedAttribution } from 'src/app/store/actions/project.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';

@Component({
  selector: 'app-company-based-attribution',
  templateUrl: './company-based-attribution.component.html',
  styleUrls: ['./company-based-attribution.component.scss']
})
export class CompanyBasedAttributionComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Input() showTitle = true;
  @Input() isInModal = false;
  @Input() disabled = false;

  project: Project;
  constructor(private projectService: ProjectService, private store: Store) { }

  ngOnInit(): void {
    this.project$.subscribe(x => this.project = x.project);
  }

  toggleCBA(e) {
    if (this.disabled) return;
    this.projectService.setAttribute('company_based_attribution', !this.project.company_based_attribution).subscribe(() => {
      this.store.dispatch(new SetCompanyBasedAttribution(!this.project.company_based_attribution));
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }
}
