import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { AttirbutionModelLabels } from 'src/app/core/constants/attribution-models';
import { IntegerPipe } from 'src/app/core/pipes/integer.pipe';
import { ProjectCurrencyPipe } from 'src/app/core/pipes/project-currency.pipe';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';
import * as dragscroll from 'dragscroll';

import * as d3 from 'd3';

@Component({
  selector: 'app-cp-compare-vertical-chart',
  templateUrl: './cp-compare-vertical-chart.component.html',
  styleUrls: ['./cp-compare-vertical-chart.component.scss'],
  providers: [ProjectCurrencyPipe, IntegerPipe]
})
export class CpCompareVerticalChartComponent implements OnChanges, AfterViewInit {
  @Input() max;
  @Input() data = [];
  @Input() tooltipName = 'comparison';
  @Input() comparison;
  @Input() isScrollable = true;
  minBarWidth = 15;
  axisTextColor = '#6c6a71';

  attrModels = AttirbutionModelLabels;

  rendered;

  colors = {
    last_touch: "#1391a4",
    first_touch: "#5bc28b",
    linear: "#f9cf4c",
    position_based: "#e0734d",
    time_decay: "#fda44e"
  }

  constructor(private tooltipService: TooltipService, private currencyPipe: ProjectCurrencyPipe, private intergerPipe: IntegerPipe) { }

  ngOnChanges(): void {
    if (!this.rendered) { return; }
    if (this.data?.length > 0) {
      return this.drawChart();
    }
  }

  ngAfterViewInit() {
    this.rendered = true;
    if (this.data?.length > 0) {
      return this.drawChart();
    }
  }

  drawChart() {
    setTimeout(() => {
      d3.select(`#comparison-chart-vertical`).select('svg').remove();
      const divs = d3.select(`#comparison-chart-vertical`).selectAll('.wrapper-div');
      divs.remove();

      const wrapperProperties = document.getElementById(`comparison`).getBoundingClientRect();
      const legendHeight = document.getElementById(`legend`).getBoundingClientRect().height;

      const chartHeight = wrapperProperties.height - legendHeight;

      const formatCurrency = d3.format('-$,.0f');

      const margin = {top: 0, right: 0, bottom: 0, left: 0};
      const bottomDivHeight = 70;
      const height = chartHeight - margin.top - margin.bottom - bottomDivHeight;

      const leftAxisSvg = d3.select("#left-axis-wrapper").append("svg")
                            .attr('width', 100)
                            .attr('height', chartHeight);

      const leftY = d3.scaleLinear()
      .domain([0, this.max])
      .range([ height, 0]);
      const leftYAxis = leftAxisSvg.append("g").attr('transform', `translate(95,0)`)
        .call(
          this.comparison == 'revenue' ?
          d3.axisLeft(leftY)
            .tickSize(0)
            .ticks(4)
            .tickFormat( d => formatCurrency(d))
          :
          d3.axisLeft(leftY)
            .tickSize(0)
            .ticks(4)
        )
        .call( g => g.select('.domain').remove());

      leftYAxis.selectAll("text")
        .style('color', this.axisTextColor);

      const barWidth = this.data.length > 7 ? 20 : 30;

      const scaleWidth = this.data.length * this.data[0].data?.length * barWidth;

      const isScrollable = (wrapperProperties.width - 100) < scaleWidth ? true : false;
      this.isScrollable = isScrollable;
      dragscroll.reset();

      this.data.forEach( (item, index) => {
        const isEven = index % 2 == 0;
        const dataQuantity = item.data.length > 1 ? item.data.length : 2;
        const width = dataQuantity * barWidth;

        const wrapperDiv = d3.select("#comparison-chart__vertical")
        .append("div")
          .attr('class', `wrapper-div wrapper-div__vertical ${isEven ? 'even': 'odd'}`);

        const svg = wrapperDiv.append("svg")
          .attr("width", width)
          .attr("height", height);

        let longestWordLength = 0;
        let name = item.name;

        const wordsApart = name.split(' ');
        wordsApart.forEach(x => {
          if (x.length > longestWordLength) longestWordLength = x.length;
        });

        if (name.length > 12 && wordsApart.length > 2) {
          wordsApart[1] = wordsApart[1]+'...';
          name = wordsApart.join(' ');
        }

        const barScaling = dataQuantity * (dataQuantity > 7 ? 14 : 16);
        const labelWidth = longestWordLength * 9.2 > barScaling ? barScaling : longestWordLength * 9.2 ;

        const p = wrapperDiv.append("div").attr('class', 'bottom-div').style("height", bottomDivHeight + "px").append('p');
        p.style("width", labelWidth + "px");
        p.html(name);
        p.on('mouseover', (event, d: any) => {
          this.tooltipService.mouseOver.emit({
            name: 'label',
            data: { data: {
              name: item.name
            }}
          });
        })
        .on('mouseout', (event, d: any) => {
          this.tooltipService.mouseOut.emit();
        });


        const y = d3.scaleLinear()
          .domain([0, this.max])
          .range([ height, 0 ]);

        const yAxis = svg.append("g")
          .call(
            d3.axisRight(y)
              .tickSize(width)
              .ticks(4)
              .tickFormat(() => "")
          )
          .call( g => g.select('.domain').remove())
          .selectAll('line')
            .attr('stroke', '#dadada');;

        const x = d3.scaleBand()
          .range([ 0, width ])
          .domain(item.data.map((d) => { return d.name; }))
          .padding(.2);

        const rects = svg.selectAll('rect')
          .data(item.data);

        rects.enter()
          .append("rect")
          .attr("x", (d: any) => { return x(d.name); } )
          .attr("y", (d: any) => y(d.value))
          .attr("width", x.bandwidth())
          .attr("height", (d: any) => {
            const rectHeight = height - y(d.value);
            return rectHeight >= 0 ? rectHeight : 0; } )
          .attr("fill", (d: any) => this.colors[d.name])
          .attr("class", (d: any) => `${d.name}-rect`)
            .on('mouseover', (event, d: any) => {
              this.tooltipService.mouseOver.emit({
                name: this.tooltipName,
                data: { data: {
                  name: this.attrModels[d.name],
                  value: this.comparison == 'revenue' ? this.currencyPipe.transform(d.value, 'cents', '1.0-0') : this.intergerPipe.transform(d.value)
                }}
              });
              d3.select("#comparisons").selectAll(`.${d.name}-rect`).attr("class", `${d.name}-rect hover`);
              d3.select("#comparisons").selectAll(`.${d.name}-box`).attr("class", `${d.name}-box legend-box hover`);
            })
            .on('mouseout', (event, d: any) => {
              this.tooltipService.mouseOut.emit();
              d3.select("#comparisons").selectAll(`.${d.name}-rect`).attr("class", `${d.name}-rect`);
              d3.select("#comparisons").selectAll(`.${d.name}-box`).attr("class", `${d.name}-box legend-box`);
            });
      });
    },1);
  }

}
