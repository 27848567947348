import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() isSettings = false;
  wasSuccess;
  errorMessage;
  waitingForResponse;
  assetPath = environment.assetPath;
  accountIsOn = false;
  projects;
  token;

  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe( x => {
      if (typeof(x.unsubscribe_token) != 'undefined') this.token = x.unsubscribe_token;
      this.notificationService.get(this.token).subscribe( (x: any)=> {
        this.accountIsOn = !x.unsubscribed;
        this.projects = x.projects;
      });
    });
  }

  submit() {
    this.errorMessage = null;
    this.wasSuccess = null;

    const params = {
      unsubscribed: !this.accountIsOn,
      projects: this.projects
    }

    this.waitingForResponse = true;
    this.notificationService.update(params, this.token).subscribe( () => {
      this.waitingForResponse = false;
      this.wasSuccess = "Notification settings have been updated."
    }, e => {
      this.waitingForResponse = false;
      this.errorMessage = e.error?.message;
    } );
  }

}
