import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

import * as humanizeDuration from 'humanize-duration';

@Pipe({
  name: 'timeAgo',
  pure: false
})
export class CustomTimeAgoPipe implements PipeTransform, OnDestroy {
  private text;
  private interval;

  constructor(){}

  transform(x: string, options?, interval?: number): any {
    if (interval) {
      this.generateText(x, options);
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.generateText(x, options);
        }, interval);
      }
    } else this.generateText(x, options);


    const prefix = typeof(options) != 'undefined' && typeof(options.prefix) != 'undefined' ? options.prefix : '';
    const suffix = typeof(options) != 'undefined' && typeof(options.suffix) != 'undefined' ? options.suffix : '';

    return `${prefix}${this.text}${suffix}`;
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  }

  generateText(x, options?) {
    let DT = DateTime.fromISO(x);
    if (options && typeof(options.fromFormat) != 'undefined') {
      switch(options.fromFormat) {
        case 'sql':
          DT = DateTime.fromSQL(x);
          break;
        default:
          DT = DateTime.fromFormat(x, options.fromFormat);
      }
    }

    if (options && typeof(options.formatFn) != 'undefined') {
      return options.formatFn(DT);
    }

    const dif = DateTime.now().diff(DT).milliseconds;

    this.text = humanizeDuration(dif, {...options.humanizeOpts, round: true});
  }

}