import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../base/base.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { SelectedStateModel } from 'src/app/store/states/selected.state';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService,
    private store: Store) {}

  getFiltered(filterOrGroupId, type, since?, periodStart?, periodEnd?) {
    let Url;
    if (filterOrGroupId) {
      Url = `${environment.reportingApiUrl}/v2/visits/filtered`;
    } else {
      Url = `${environment.reportingApiUrl}/v2/visits/all`;
    }

    const Params: any = {
      ...this.baseService.getReportingParams
    };

    if (type === 'f') {
      Params.filter = filterOrGroupId;
    } else if (type === 'g') {
      Params.filter_group_id = filterOrGroupId;
    } else if (type == 'v') {
      Params.virtual_filter = filterOrGroupId;
    }

    if (since) { Params.since = since; }
    
    const selectedState = this.store.selectSnapshot<SelectedStateModel>( state => state.selected);
    Params.start = selectedState.chosenDates.start;
    Params.end = selectedState.chosenDates.end;

    if (periodStart && periodEnd) {
      Params.period_start = periodStart;
      Params.period_end = periodEnd;
    }

    if (environment.envName !== 'production') Params.with_clicks = true;

    return this.httpClient.post(Url, {}, {params: Params, observe: 'body'});
  }
}
