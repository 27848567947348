import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InfluenceService {

  constructor(private httpClient: HttpClient) { }

  uploadCSV(files?) {
    const Url = `${environment.clientApiUrl}/v2/influences/csv`;

    const fd = new FormData();
    if (files.length === 1) {
        fd.append('csv_file', files.item(0));
    } else {
        for (let file of files)
        for (let fileIndex in files) {
          fd.append('csv_file' + '_' + fileIndex, files[fileIndex]);
        }
    }

    return this.httpClient.post(Url, fd);
  }

  getStatus() {
    const Url = `${environment.clientApiUrl}/v2/influences/status`;

    return this.httpClient.get(Url);
  }
}
