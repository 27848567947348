import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, zip } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import Rollbar from 'rollbar';
import { DateTime } from 'luxon';

import { SidebarStateModel, SidebarState } from '../../store/states/sidebar.state';
import { ProjectState, ProjectStateModel } from '../../store/states/project.state';
import { SidebarSetName } from '../../store/actions/only-simple.actions';
import { CloseSidebar } from '../../store/actions/sidebar.actions';
import { FilterService } from 'src/app/core/services/filter/filter.service';
import { SidebarStateService } from 'src/app/core/services/sidebar-state/sidebar-state.service';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { ClearReports, SetChosenDates } from 'src/app/store/actions/selected.actions';
import { SlideInOutAnimationRTL } from 'src/app/core/animations/slide-in-out';
import { SetIsOnboarding } from 'src/app/store/actions/base.actions';
import { environment } from 'src/environments/environment';
import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { ComponentNames } from 'src/app/core/enums/component-names';
import { SidebarNames } from 'src/app/core/enums/sidebar-names';
import { RollbarService } from 'src/app/core/error-handler/rollbar-error-handler';
import { AuthService } from 'src/app/core/services/auth/auth.service';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [SlideInOutAnimationRTL]
})
export class ShellComponent implements OnInit, OnDestroy {
  @Select(SidebarState) sidebar$: Observable<SidebarStateModel>;
  @Select(state => state.selected) selectedState$: Observable<SelectedStateModel>;
  @Select(state => state.onlySimple.sidebarName) sidebarName$: Observable<string>;
  @Select(ProjectState) project$: Observable<ProjectStateModel>;
  @Select(state => state.account) account$: Observable<any>;

  isProd = environment.isProd;
  sidebar;
  currentComponent: string;
  appPaneStyle;
  sidebarStyle;
  sidebarName: string = null;
  onResizeBound;
  stopResizeBound;
  project;
  account;
  view;
  sidebarWasScrolled = false;
  scrolledLowerThenTitle;
  notificationAlreadyShown = false;
  shouldControlbarShow = true;

  componentNames = ComponentNames;
  sidebarNames = SidebarNames;

  accountSub;
  projectSub;
  sidebarNamesSub;
  combinedSub;
  queryParamsSub;

  lastClickTime: DateTime = DateTime.now();

  title = 'AttributionApp';

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private filterService: FilterService,
    private sidebarState: SidebarStateService,
    private projectService: ProjectService,
    private authService: AuthService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (
      (this.sidebar?.openedSidebar === this.sidebarNames.visitors && this.currentComponent !== ComponentNames.paths) ||
      (this.sidebar?.openedSidebar === this.sidebarNames.companies && this.sidebar?.options?.id)
    ) {
      this.sidebarState.setWentBack(true);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (DateTime.now().minus({ hours: 8}) > this.lastClickTime) {
      this.rollbar.debug(`Inactivity detected`,
        {
          last_activity_at: this.lastClickTime.toISO(),
          inactive_at: DateTime.now().toISO(),
          inactivity_delta: DateTime.now().diff(this.lastClickTime, 'seconds').toObject(),
          project_id: this.project?.id,
          account_id: this.account?.id
        }
      );
      this.authService.logout();
    }
    this.lastClickTime = DateTime.now();
  }

  appPaneOnScroll(event) {
    if (this.currentComponent == this.componentNames.dashboardBuilder) {
      const elementToFix = document.querySelector('.fix-to-top') as HTMLElement;
      if (elementToFix) {
        let bp = 60;
        if (elementToFix.classList.contains('.fix-long')) bp = 130;
        const boundingRect = elementToFix.getBoundingClientRect();
        if (boundingRect.top - 60 <= 0 && !elementToFix.classList.contains('fixed')) {
          elementToFix.classList.add("fixed");
          elementToFix.classList.add("container-xl");
        } else if (event.srcElement.scrollTop < bp && elementToFix.classList.contains('fixed')) {
          elementToFix.classList.remove("fixed");
          elementToFix.classList.remove("container-xl");
        }
      }
    }
  }

  ngOnInit() {
    this.store.dispatch(new SetIsOnboarding(false));
    this.projectSub = this.project$.subscribe( state => {
      this.project = state.project;
      this.view = state.currentViewParams;
    });
    this.accountSub = this.account$.subscribe( state => {
      this.account = state;
    });
    this.sidebarNamesSub = this.sidebarName$.subscribe(state => this.sidebarName = state);
    this.combinedSub = combineLatest([this.selectedState$, this.sidebar$]).subscribe(([selected, sidebar]) => {
      if (this.authService.isAuthenticated && !selected.chosenDates.start && !selected.chosenDates.end) {
        const startDate = DateTime.now().minus({days: 30}).toFormat('y-MM-dd');
        const endDate = DateTime.now().minus({days: 1}).toFormat('y-MM-dd');
        this.store.dispatch(new SetChosenDates({ start: startDate, end: endDate, skipEventsRequest: true }));
      }

      this.currentComponent = selected.currentComponent;
      this.shouldControlbarShow = this.shouldShowControlbar;
      if (this.currentComponent !== this.componentNames.dashboard) {
        this.sidebar = null;
        this.appPaneStyle = {
          'width.%': '100'
        };
      }
      if (sidebar.openedSidebar) {
        if (
          (sidebar.openedSidebar == this.componentNames.dashboardBuilder && this.currentComponent != this.componentNames.dashboardBuilder)
          || (
            (sidebar.openedSidebar != this.componentNames.dashboardBuilder && sidebar.openedSidebar != SidebarNames.visitors)
            && ((this.currentComponent != this.componentNames.dashboard && this.currentComponent != this.componentNames.timeframe && this.currentComponent !== this.componentNames.paths) && this.currentComponent != null))
          ) {
          this.closeSidebar();
        } else {
          let sidebarWidth;
          if (sidebar.openedSidebar != this.componentNames.dashboardBuilder) {
            sidebarWidth = 33;
          } else {
            sidebarWidth = 50;
          }
          this.sidebar = sidebar;
          setTimeout(() => {
            this.sidebarStyle = {
              'width.%': sidebarWidth
            };
            this.appPaneStyle = {
              'width.%': (100-sidebarWidth)
            };
            const sidebarElem = document.querySelector('.sidebar') as HTMLElement;
            if (sidebarElem) {
              sidebarElem.scrollTop = 0;
              this.sidebarWasScrolled = false;
            }
          });
        }
      } else {
        this.sidebar = null;
        this.sidebarStyle = { 'width.%': '0' };
        this.appPaneStyle = { 'width.%': '100' };
      }
    });
    this.queryParamsSub = this.route.queryParams.subscribe( params => {
      if (params.from === 'v1') {
        zip(this.projectService.updateProjectInfo(), this.filterService.getFilterTree()).subscribe(() => {
          this.store.dispatch(new ClearReports);
          const {from, ...withoutFrom} = this.route.snapshot.queryParams;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: withoutFrom
          });
        });
      }
      if (!params.sidebar && this.sidebar) {
        this.closeSidebar(true);
      }
    });
  }

  ngOnDestroy(): void {
    this.projectSub?.unsubscribe();
    this.accountSub?.unsubscribe();
    this.sidebarNamesSub?.unsubscribe();
    this.combinedSub?.unsubscribe();
    this.queryParamsSub?.unsubscribe();
  }

  startResizing() {
    this.sidebarStyle.transition = 'none';
    this.appPaneStyle.transition = 'none';
    this.sidebarStyle.userSelect = 'none';
    this.appPaneStyle.userSelect = 'none';
    if (!this.onResizeBound) {
      this.onResizeBound = this.resize.bind(this);
    }
    if (!this.stopResizeBound) {
        this.stopResizeBound = this.stopResize.bind(this);
    }
    document.addEventListener('mousemove', this.resize);
    document.addEventListener('mouseup', this.stopResizeBound, false);
  }

  stopResize() {
    this.sidebarStyle.transition = 'all 300ms ease-in-out';
    this.appPaneStyle.transition = 'all 300ms ease-in-out';
    this.sidebarStyle.userSelect = 'auto';
    this.appPaneStyle.userSelect = 'auto';
    document.removeEventListener('mousemove', this.resize);
  }

  resize(e) {
    const appPane = document.querySelector('#app_pane') as HTMLElement;
    const sidebar = document.querySelector('.sidebar') as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;
    const bodyWidth = body.clientWidth;
    appPane.style.width = e.pageX + 'px';
    sidebar.style.width = bodyWidth - e.pageX + 'px';
  }

  closeSidebar(withoutRedirect?) {
    const wasChannelBuilder = this.store.selectSnapshot( state => state.sidebar).openedSidebar == SidebarNames.dashboardBuilder;
    this.store.dispatch(new SidebarSetName({}));
    this.store.dispatch(new CloseSidebar());
    setTimeout(() => {
      this.sidebarStyle = { 'width.%': '0' };
      this.appPaneStyle = { 'width.%': '100' };
    });
    const {sidebar, id, filter, group, scope, q, virtual_filter, rowName, periodStart, periodEnd, ...params} = this.route.snapshot.queryParams;
    if (wasChannelBuilder) params.channelBuilderSidebarClosed = true;
    if (!withoutRedirect) {
      setTimeout(() => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: params
        });
      }, 300);
    }
  }

  sidebarGoBack() {
    this.sidebarState.setWentBack(true);
    this.location.back();
  }

  onSidebarScroll() {
    const title = document.querySelector('.title-in-sidebar');
    if (title != null) {
      const sidebarTitle = document.querySelector('.title-in-sidebar').getBoundingClientRect();
      this.scrolledLowerThenTitle = sidebarTitle.top < 150;
    }
  }

  get shouldShowControlbar() {
    const notFor: Array<string> = [ComponentNames.overview, ComponentNames.settings, ComponentNames.account, ComponentNames.dashboardBuilder];
    return !notFor.includes(this.currentComponent);
  }
}
