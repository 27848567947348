import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { findInTree } from 'src/app/core/helpers/find-in-tree';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { SetProjectSettings } from 'src/app/store/actions/project.actions';
import { ClearReports } from 'src/app/store/actions/selected.actions';
import { SetNeedReportRefresh } from 'src/app/store/actions/settings.actions';
import { ProjectStateModel } from 'src/app/store/states/project.state';
import { cloneDeep } from 'lodash-es';
import { FilterTreeStateModel } from 'src/app/store/states/filter-tree.state';
import { FilterTreeWorkerService } from 'src/app/core/services/filter-tree-worker/filter-tree-worker.service';

@Component({
  selector: 'app-exclude-filters',
  templateUrl: './exclude-filters.component.html',
  styleUrls: ['./exclude-filters.component.scss']
})
export class ExcludeFiltersComponent implements OnInit {
  @Select(state => state.project) project$: Observable<ProjectStateModel>;
  @Select(state => state.filterTree) filterTree$: Observable<FilterTreeStateModel>;

  @Input() showTitle = true;
  @Input() isInModal = false;
  @Input() disabled = false;

  project;
  filterTree;
  excludedFilters = [];

  constructor(
    private store: Store,
    private projectService: ProjectService,
    private FTWS: FilterTreeWorkerService) { }

  ngOnInit(): void {
    this.project$.subscribe(x => {
      this.project = x.project;
      this.loadExcludedFilters();
    });
    this.filterTree$.subscribe(x => {
      this.filterTree = x.filterTree;
    });
  }

  loadExcludedFilters() {
    this.excludedFilters = [];

    if (!this.project?.settings?.exclude_filters) return;

    this.project.settings.exclude_filters.forEach(id => {
      const tree = this.store.selectSnapshot( state => state.filterTree.filterTree);
      const filter = this.FTWS.findInTree({node: {c: tree}, childrenKey: 'c', key: 'id', value: id, additionalKey: 't', additionalValue: 'f', withPath: tree});
      if (filter) this.excludedFilters.push(filter);
    });
  }

  toggleExcludeFilters(event) {
    if (this.disabled) return;
    const settings = cloneDeep(this.project.settings);
    if (typeof(settings.exclude_filters_enabled) == 'undefined') {
      settings.exclude_filters_enabled = true;
    } else {
      settings.exclude_filters_enabled = !settings.exclude_filters_enabled;
    }
    this.projectService.setAttribute('settings', settings).subscribe(() => {
      this.store.dispatch(new SetProjectSettings(settings));
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

  excludeFilter(event) {
    const addToExcludeFilters = (item) => {
      item.c.forEach(x => {
        const filterAlreadyThere = this.excludedFilters.find(f => {
          return f.id == x.id;
        });
        if (x.t == 'f' && !filterAlreadyThere) this.excludedFilters.push(x);
        if (x.t == 'g' && x.c?.length > 0) addToExcludeFilters(x);
      });
    };

    let item;
    if (event.item) {
      item = event.item;
    } else {
      item = this.FTWS.findInTree({node: {c: this.filterTree}, childrenKey: 'c', key: 'id', value: event.id, additionalKey: 't', additionalValue: event.t});
    };
    if (item.t == 'g') {
      addToExcludeFilters(item);
    } else {
      this.excludedFilters.push(item);
    }
    this.saveExcludedFilters();
  }

  unexcludeFilter(item) {
    this.excludedFilters = this.excludedFilters.filter( x => !(x.id == item.id && x.t == item.t));
    this.saveExcludedFilters();
  }

  saveExcludedFilters() {
    const excludedIds = this.excludedFilters.map(f => f.id);
    const settings = cloneDeep(this.project.settings);
    if (typeof(settings.exclude_filters_enabled) == 'undefined') {
      settings.exclude_filters_enabled = false;
    }
    settings.exclude_filters = excludedIds;
    this.projectService.setAttribute('settings', settings).subscribe(() => {
      this.store.dispatch(new SetProjectSettings(settings));
      if (!this.isInModal) { this.store.dispatch(new ClearReports())} else { this.store.dispatch(new SetNeedReportRefresh(true))};
    });
  }

}
