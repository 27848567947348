import { GetWidgetsResponse } from 'src/app/core/services/overview/overview.model';

export class SetOverview {
  static readonly type = '[overview] SetOverview';
  constructor(public payload: GetWidgetsResponse) {}
}

export class SetOverviewInProgress {
  static readonly type = '[overview] SetOverviewInProgress';
}

export class SetOverviewReportsToNull {
  static readonly type = '[overview] SetOverviewReportsToNull';
  constructor(public payload?: string | string[]) {}
}

export class ClearOverview {
  static readonly type = '[overview] ClearOverview';
}