<div class="container-fluid">
    @for ((property of settings | keyvalue: originalOrderFn); track property.key) {
        @if (property.value.$production != false || envName !== 'production') {
            <div class="row mt-3">
                <div class="col-6">
                    <div class="bold">
                        {{ property.value.title }}
                        @if (property.value.$production == false) {
                            <i class="fa-solid fa-lock red me-1"></i>
                        }
                    </div>
                    <div class="small medium-dark">{{ property.value.description }}</div>
                    <div class="small red hide-prod">{{ property.value.$comment }}</div>
                </div>
                <div class="col-6">
                    @if (!property.value.readOnly) {
                        @switch (property.value.settingType) {
                            @case ('string') {
                                <ng-container *ngTemplateOutlet="string; context: {$implicit: property.value, key: property.key}"></ng-container>
                            }
                            @case ('integer') {
                                <ng-container *ngTemplateOutlet="integer; context: {$implicit: property.value, key: property.key}"></ng-container>
                            }
                            @case ('boolean') {
                                <ng-container *ngTemplateOutlet="boolean; context: {$implicit: property.value, key: property.key}"></ng-container>
                            }
                            @case ('select') {
                                <ng-container *ngTemplateOutlet="select; context: {$implicit: property.value, key: property.key}"></ng-container>
                            }
                            @case ('array-object') {
                                <ng-container *ngTemplateOutlet="arrayObject; context: {$implicit: property.value, key: property.key}"></ng-container>
                            }
                            @case ('array-string') {
                                <ng-container *ngTemplateOutlet="arrayString; context: {$implicit: property.value, key: property.key}"></ng-container>
                            }
                            @case ('object') {
                                <ng-container *ngTemplateOutlet="object; context: {$implicit: property.value, key: property.key}"></ng-container>
                            }
                        }
                    } @else {
                        <ng-container *ngTemplateOutlet="readOnly; context: {$implicit: property.value, key: property.key}"></ng-container>
                    }
                </div>
            </div>
        }
    }
</div>

<ng-template #readOnly let-key="key" let-property>
    <textarea class="form-control" disabled>{{ property.value | json }}</textarea>
</ng-template>

<ng-template #string let-key="key" let-property>
    <input type="text" class="form-control" [value]="property.value" (keyup)="setSetting(key, $event.target.value)" />
</ng-template>

<ng-template #integer let-key="key" let-property>
    <input type="number" class="form-control" [value]="property.value" (keyup)="setSetting(key, $event.target.value)"  />
</ng-template>

<ng-template #boolean let-key="key" let-property>
    <app-nullable-checkbox
        [value]="property.value"
        (valueChange)="setSetting(key, $event)"
        ></app-nullable-checkbox>
</ng-template>

<ng-template #select let-key="key" let-property>
    <select class="form-select" (change)="setSetting(key, property.value)" [(ngModel)]="property.value">
        <option *ngFor="let option of property.options_for_select" [ngValue]="option.id ? option.id : option">{{ option.name ? option.name : option }}</option>
    </select>
</ng-template>

<ng-template #arrayString let-key="key" let-property>
    <ng-select
        [items]="property.options_for_select"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="true"
        [(ngModel)]="property.value"
        placeholder="Select {{property.title}}"
        (change)="setSetting(key, $event)"
        ></ng-select>
</ng-template>

<ng-template #arrayObject let-key="key" let-property>
    <ng-select
        [items]="property.options_for_select"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="true"
        bindLabel="name"
        [(ngModel)]="property.value"
        placeholder="Select {{property.title}}"
        (change)="setSetting(key, $event)"
        ></ng-select>
</ng-template>

<ng-template #object let-key="key" let-property>
    <input type="text" class="form-control" [value]="property.value | json" (keyup)="setSetting(key, $event.target.value)" />
</ng-template>