import { Component, AfterViewInit, OnChanges, Input } from '@angular/core';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { Select, Store } from '@ngxs/store';
import { range, find } from 'lodash-es';

import { SelectedStateModel } from 'src/app/store/states/selected.state';
import { TooltipService } from 'src/app/core/services/tooltip/tooltip.service';
import { chartColors } from 'src/app/core/constants/colors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar-bar-new-chart',
  templateUrl: './sidebar-bar-chart-new.component.html',
  styleUrls: ['./sidebar-bar-chart-new.component.scss']
})
export class SidebarBarChartNewComponent implements AfterViewInit, OnChanges {
  @Input() chartName;
  @Input() data: Array<{day?: number; count?: number; date?; revenue?; clicks?: number; }>;
  @Input() tooltipName;
  @Select(state => state.selected.chosenDates) chosenDates$: Observable<any>;

  rendered = false;
  dates;

  constructor(private store: Store, private tooltipService: TooltipService) { }

  ngOnChanges() {
    if (!this.rendered) { return; }
    this.drawChart();
  }

  ngAfterViewInit() {
    this.rendered = true;
    this.chosenDates$.subscribe( (dates) => {
      this.dates = dates;
      this.drawChart();
    });
  }

  drawChart() {
    d3.select(`#${this.chartName}`).select('svg').remove();

    if (!document.getElementById(this.chartName)) {
      return;
    }

    const chartsOptions = {
      'conversions-chart': {
        valueKeys: 'count',
        addHeight: true,
      },
      'visits-chart': {
        valueKeys: 'count',
        addHeight: true,
      },
      'revenue-chart': {
        valueKeys: 'revenue',
        addHeight: false,
      }
    }

    const chartOptions = chartsOptions[this.chartName];
    const wrapperProperties = document.getElementById(this.chartName).getBoundingClientRect();

    const startDate = this.dates.start || this.store.selectSnapshot(state => state.selected)?.chosenDates?.start;

    if (wrapperProperties) {
      let dataModified = [];
      if (this.chartName == 'conversions-chart') {
        const dateRange = Math.round(DateTime.now().diff(DateTime.fromFormat(startDate, 'y-MM-dd'), 'days').toObject().days) || 1;
        const dateRangeArray = range(dateRange);
        const reducer = (accumulator, currentValue) => {
          let timelineCount = 0;
          const dayFromTimeline = find(this.data, day => day.day === currentValue);
          if (dayFromTimeline) {
            timelineCount = dayFromTimeline.count;
          }
          accumulator.push({ day: currentValue, count: timelineCount});
          return accumulator;
        };
        dataModified = dateRangeArray.reduce(reducer, []);
        this.drawBarChart(dataModified, wrapperProperties.width, wrapperProperties.height, chartOptions.valueKeys, chartOptions.addHeight);
      } else if (this.chartName === 'visits-chart') {
        this.data.forEach( x => {
          let base;
          const excess_clicks = x.clicks > x.count ? x.clicks - x.count : null;
          const excess_visits = x.clicks < x.count ? x.count - x.clicks : null;
          if (excess_clicks != null) {
            base = x.count;
          } else if (excess_visits != null) {
            base = x.clicks;
          }
          dataModified.push({
            day: x.date,
            base: base,
            visits: x.count,
            clicks: x.clicks,
            excess_clicks: excess_clicks,
            excess_visits: excess_visits,
          });
        });
        this.drawStackedChart(dataModified, wrapperProperties.width, wrapperProperties.height, chartOptions.valueKeys, chartOptions.addHeight);
      } else {
        this.data.forEach( x => {
          dataModified.push({day: x.date, count: x.revenue});
        });
        this.drawBarChart(dataModified, wrapperProperties.width, wrapperProperties.height, chartOptions.valueKeys, chartOptions.addHeight);
      }
    }
  }

  drawBarChart(data:Array<any>, width, height, key, addHeight) {
    const x = d3.scaleBand()
      .domain(data.map( d => d.day))
      .range([0, width])
      .paddingInner(0.3)
      .paddingOuter(0.3);

    const y = d3.scaleLinear()
      .domain([1, d3.max(data, d => {
        return d[key];
      })])
      .range([height, 0]);

    const svg = d3.select(`#${this.chartName}`).append('svg')
      .attr('width', width )
      .attr('height', height);

    svg.selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.day))
      .attr('width', x.bandwidth)
      .attr('y', d => y(addHeight ? d.count + 1 : d.count))
      .attr('height', d => {
        const rectHeight = height - (y(addHeight ? d.count + 1 : d.count));
        return rectHeight >= 0 ? rectHeight : 0;
      })
      .on('mouseover', (e, d: any) => {
        this.tooltipService.mouseOver.emit({ name: this.tooltipName, data: {data: d, element: e.srcElement}});
      })
      .on('mouseout', () => this.tooltipService.mouseOut.emit());

    svg.selectAll('.underline')
      .data(data)
      .enter().append('rect')
      .attr('class', 'underline')
      .attr('x', d => x(d.day))
      .attr('width', x.bandwidth)
      .attr('y', d => height-1)
      .attr('height', d => 1);
  }

  drawStackedChart(data:Array<{day:any, base:number, excess_clicks:number, excess_visits:number}>, width, height, key, addHeight) {
    const x = d3.scaleBand()
      .domain(data.map( d => d.day))
      .range([0, width])
      .paddingInner(0.3)
      .paddingOuter(0.3);

    const y = d3.scaleLinear()
      .domain([1, d3.max(data, d => {
        if (d.base || d.excess_clicks || d.excess_visits) return d.base + d.excess_clicks + d.excess_visits;
        else return 0;
      })])
      .range([height, 0]);

    const svg = d3.select(`#${this.chartName}`)
      .append('svg')
      .attr('width', width )
      .attr('height', height);

    const subgroups = ['base', 'excess_clicks', 'excess_visits'];

    const colors = [chartColors.green, chartColors.red, chartColors.blue];

    const stackedData = d3.stack()
      .keys(subgroups)
      (data);

    svg.append('g')
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .enter().append("g")
      .attr("fill", function(d, i) { return colors[i] })
      .attr('class', d => { return d.key;})
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(function(d) { return d; })
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(String(d.data.day)))
      .attr('y', d => { return y(d[1])})
      .attr('width', x.bandwidth)
      .attr('height', d => {
        const first = d[0] || 0;
        const second = d[1] || 0;
        return y(first) - y(second);
      })
      .on('mouseover', (e, d: any) => {
        const tooltipData = { day: d.data.day, 
        data: {
          visits: d.data.visits,
          clicks: d.data.clicks,
          excess_clicks: d.data.excess_clicks,
          excess_visits: d.data.excess_visits
        }
      };

        this.tooltipService.mouseOver.emit({ name: this.tooltipName, data: {data: tooltipData, element: e.srcElement}});
      })
      .on('mouseout', () => this.tooltipService.mouseOut.emit());

  }

}
