export class SetFlow {
  static readonly type = '[onboarding] SetFlow';
  constructor(public payload: any) {}
}

export class SetCurrentStep {
  static readonly type = '[onboarding] SetCurrentStep';
  constructor(public payload: string) {}
}

export class SetLastStep {
  static readonly type = '[onboarding] SetLastStep';
  constructor(public payload: number) {}
}

export class ClearOnboardingState {
  static readonly type = '[onboarding] ClearAccountState';
}