import { Pipe, PipeTransform } from '@angular/core';
import DateTime from 'luxon';

@Pipe({
  name: 'dashboardNeedsDataRefresh'
})
export class DashboardNeedsDataRefreshPipe implements PipeTransform {
  transform(
    id,
    t,
    dataRefreshState?: {
        filter: {
          [id: number]: number;
        };
        group: {
          [id: number]: number;
        };
      },
    reportGeneratedAt?: Date): any {
    const type = t === 'f' ? 'filter' : 'group';
    if (dataRefreshState && reportGeneratedAt) {
        if (dataRefreshState[type][id] && dataRefreshState[type][id] > Number(new Date(reportGeneratedAt))) {
            return true;
        }
    }
    return false;
  }
}