<div class="attr-card mt-4">
  <div class="company_based_attribution" [class.project-option-disabled]="disabled">
    <div class="row mb-4">
      <div class="col-8">
        <p class="title-no-bold" *ngIf="showTitle" >Company Based Attribution <i class="fa-solid fa-lock red me-1" *ngIf="!project?.options?.features?.company_based_attribution"></i></p>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <app-toggle-switch
          [isOn]="project?.company_based_attribution"
          (eventEmitter)="toggleCBA($event)"></app-toggle-switch>
      </div>
    </div>

    <p class="medium-dark">Turn this setting on to enable company or "account" based attribution, instead of individual user attribution. This will aggregate all user information including cost, visits, conversions and revenue at the 'company' or 'account' level. This is particularly important when you have one user sign up, but another user in the org pay for services.</p>
    <p class="medium-dark">NOTE: you need to have the <a href="https://docs.attributionapp.com/docs/identify#special-properties-for-identify" target="_blank">"companyId"</a> trait set for this setting to work properly and the dashboard will currently take slightly longer to load.</p>
  </div>
</div>
